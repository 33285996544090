<template>
  <div
    class="slidein px-7 py-10 fixed z-50 top-20 sm:top-18 right-0 overflow-scroll"
  >
    <div class="flex justify-between">
      <p class="font-bold text-xl">{{ title }}</p>
      <span
        class="flex justify-center items-center cursor-pointer w-7 h-7 rounded-full border-2"
        @click="toggle"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="20" height="20" rx="5" fill="#fff" />
          <path
            d="M10.9391 9.99943L13.803 7.14179C13.9284 7.01635 13.9989 6.84623 13.9989 6.66884C13.9989 6.49146 13.9284 6.32133 13.803 6.1959C13.6776 6.07047 13.5075 6 13.3301 6C13.1528 6 12.9827 6.07047 12.8572 6.1959L10 9.06021L7.14276 6.1959C7.01735 6.07047 6.84725 6 6.66988 6C6.49252 6 6.32242 6.07047 6.19701 6.1959C6.07159 6.32133 6.00114 6.49146 6.00114 6.66884C6.00114 6.84623 6.07159 7.01635 6.19701 7.14179L9.06091 9.99943L6.19701 12.8571C6.13458 12.919 6.08504 12.9927 6.05122 13.0738C6.01741 13.155 6 13.2421 6 13.33C6 13.418 6.01741 13.505 6.05122 13.5862C6.08504 13.6674 6.13458 13.741 6.19701 13.803C6.25892 13.8654 6.33259 13.915 6.41375 13.9488C6.49491 13.9826 6.58196 14 6.66988 14C6.75781 14 6.84486 13.9826 6.92602 13.9488C7.00718 13.915 7.08085 13.8654 7.14276 13.803L10 10.9387L12.8572 13.803C12.9192 13.8654 12.9928 13.915 13.074 13.9488C13.1551 13.9826 13.2422 14 13.3301 14C13.418 14 13.5051 13.9826 13.5863 13.9488C13.6674 13.915 13.7411 13.8654 13.803 13.803C13.8654 13.741 13.915 13.6674 13.9488 13.5862C13.9826 13.505 14 13.418 14 13.33C14 13.2421 13.9826 13.155 13.9488 13.0738C13.915 12.9927 13.8654 12.919 13.803 12.8571L10.9391 9.99943Z"
            fill="#34324F"
          />
        </svg>
      </span>
    </div>
    <div class="mt-12">
      <slot></slot>
      <div
        class="flex items-center justify-end cursor-pointer w-36 space-x-2 ml-auto mt-6 py-1 pl-2"
        @click="restFilter()"
      >
        <p>Réinitialisez</p>
        <div
          class="w-7 h-7 border rounded-full border-promy-green-300 hover:bg-promy-green-300 text-promy-green-200 hover:text-white duration-100 transition-all flex justify-center items-center"
        >
          <resetIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Barre d'outils",
    },
  },
  data() {
    return {
      open: true,
    }
  },

  methods: {
    toggle() {
      this.$emit('close')
    },
    restFilter() {
      this.$emit('restFilter')
    },
  },
}
</script>

<style lang="scss" scoped>
.slidein {
  @apply w-[20rem] bg-white;
  box-shadow: 3px 25px 5px 4px #ddd;
  transition: all 0.5s ease-in-out;

  height: calc(100vh - 5rem);
  @screen md {
    height: calc(100vh - 5rem);
  }
  @screen sm {
    height: calc(100vh - 5rem);
  }
}
@media (max-width: 639px) {
  .slidein {
    width: 375px;
  }
}

.slide-enter,
.slide-leave-active {
  right: -100%;
}
</style>
