<template>
  <div>
    <div v-if="delegationMandats.length" class="mb-20">
      <div class="flex lg:flex-col space-x-4 lg:space-x-0">
        <div
          class="flex items-center"
          v-for="item in Status"
          :key="item.key + '-type'"
        >
          <span
            :class="getStatusColor(item.key)"
            class="rounded-full w-3 h-3 mr-1"
          >
          </span>
          <span class="text-sm text-promy-gray-600">{{ item.label }}</span>
        </div>
      </div>
      <div class="mt-7">
        <header
          class="grid grid-cols-12 gap-x-4 uppercase bg-promy-gray-225 py-3 px-7 rounded-tr-lg rounded-tl-lg"
        >
          <SortBy
            text="Statut"
            classes="col-span-1"
            :sortDirection="sortDirection"
            :currentSortedColumn="currentSortedColumn"
            @sortByClicked="sortByClicked"
          />
          <SortBy
            text="N° de délégation"
            classes="col-span-2"
            :sortDirection="sortDirection"
            :currentSortedColumn="currentSortedColumn"
            @sortByClicked="sortByClicked"
          />
          <SortBy
            text="Département"
            classes="col-span-2"
            :sortDirection="sortDirection"
            :currentSortedColumn="currentSortedColumn"
            @sortByClicked="sortByClicked"
          />
          <SortBy
            text="Date"
            classes="col-span-1"
            :sortDirection="sortDirection"
            :currentSortedColumn="currentSortedColumn"
            @sortByClicked="sortByClicked"
          />
          <SortBy
            text="Date d'expiration"
            classes="col-span-2"
            :sortDirection="sortDirection"
            :currentSortedColumn="currentSortedColumn"
            @sortByClicked="sortByClicked"
          />
          <div
            class="col-span-4 font-arial font-bold text-xs text-promy-gray-650"
          ></div>
        </header>
        <main class="xl:h-50-screen xl:overflow-y-auto liste-dossier">
          <div
            class="grid grid-cols-12 py-3 px-7"
            :class="{ 'bg-promy-gray-background-100': index % 2 == 0 }"
            v-for="(delegation, index) in delegationMandats"
            :key="delegation.id"
          >
            <div
              class="col-span-1 font-arial font-normal text-sm text-promy-gray-650"
            >
              <span
                :class="getStatusColor(delegation.status)"
                class="flex rounded-full w-3 h-3 ml-4"
              >
              </span>
            </div>
            <div
              class="col-span-2 font-arial font-normal text-sm text-promy-gray-650 ml-2"
            >
              {{ delegation.par_mandat_n }}
            </div>
            <div
              class="col-span-2 font-arial font-normal text-sm text-promy-gray-650 ml-2"
            >
              {{ delegation.code_postal }} / {{ delegation.ville }} /
              {{ delegation.rue }}
            </div>
            <div
              class="col-span-1 font-arial font-normal text-sm text-promy-gray-650 ml-2"
            >
              {{ delegation.mandat_date | filterDate }}
            </div>
            <div
              class="col-span-2 font-arial font-normal text-sm text-promy-gray-650 ml-2"
            >
              {{ delegation.expired_at | filterDate }}
            </div>
            <div
              class="col-span-4 font-arial font-bold text-xs text-promy-gray-650"
            >
              <div class="flex space-x-1 justify-end">
                <div
                  v-if="delegation.document_signe"
                  @click="downloadSignedDocument(delegation.id)"
                  class="flex items-center justify-center p-1 w-7 h-7 mr-1 text-white rounded-full cursor-pointer bg-promy-green-300"
                >
                  <img
                    class="cursor-pointer"
                    src="../../components/icons/download.svg"
                    alt="eye"
                  />
                </div>
                <div
                  @click="showMandat(delegation.id)"
                  class="flex items-center justify-center p-1 w-7 h-7 mr-1 text-white rounded-full cursor-pointer bg-promy-green-300"
                  :class="{ 'mr-0': !delegation.document_signe }"
                >
                  <img
                    class="cursor-pointer"
                    src="../../components/icons/eye.svg"
                    alt="eye"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="delegationMandats.length"
            v-observe-visibility="{
              callback: handleInfiniteScroll,
            }"
          ></div>
        </main>
      </div>
    </div>
    <div v-else-if="!delegationMandats.length && isLoading">
      <div class="w-full flex justify-center">
        <loader
          class="absolute z-20 center-element"
          :size_bg_promy="0"
          :color="'#00C9BC'"
        ></loader>
      </div>
    </div>
    <div class="flex justify-center mt-14" v-else>
      <h3 class="font-main text-xl font-extrabold my-4">
        Aucune délégation archivés !
      </h3>
    </div>
  </div>
</template>

<script>
import DelegationMandatMixin from '@/mixins/DelegationMandat'

export default {
  mixins: [DelegationMandatMixin],
  data() {
    return {
      type: 'Archives',
      Status: [
        { key: 'expiré', label: 'Expiré' },
        { key: 'supprimé', label: 'Supprimé' },
        { key: 'refusé', label: 'Refusé' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.liste-dossier::-webkit-scrollbar {
  width: 0.3em;
}

.liste-dossier::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #949494;
}

.liste-dossier::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #f2f2f2;
}
</style>
