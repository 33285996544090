<template>
  <div
    class="grid items-center grid-cols-33 gap-1 mt-2 mb-2 lg:flex lg:flex-col lg:w-full lg:space-y-4 sm:space-y-2"
  >
    <div class="flex justify-center col-span-1 lg:w-full"></div>
    <div class="flex justify-center col-span-2 lg:w-full">
      <ButtonSortBy
        title="statut"
        sortKey="status"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-3 lg:w-full">
      <ButtonSortBy
        title="avancement"
        sortKey="avancement"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-3 lg:w-full">
      <ButtonSortBy
        title="N° Dossier"
        sortKey="dossier_number"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-4 lg:w-full">
      <ButtonSortBy
        title="commune"
        sortKey="commune"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-2 lg:w-full">
      <ButtonSortBy
        title="CP"
        sortKey="code_postal"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-3 lg:w-full">
      <ButtonSortBy
        title="Ca potentiel"
        sortKey="ca_potentiel"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-3 lg:w-full">
      <ButtonSortBy
        title="Sur. terr"
        sortKey="surface_terrain"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-2 lg:w-full">
      <ButtonSortBy
        title="type"
        sortKey="type_de_projet"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-2 lg:w-full">
      <ButtonSortBy
        title="OFFRES"
        sortKey="count_offre"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-2 lg:w-full">
      <ButtonSortBy
        title="VUES"
        sortKey="seens"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-3 lg:w-full">
      <ButtonSortBy
        title="Créateur"
        sortKey="createur"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center col-span-3 lg:w-full">
      <ButtonSortBy
        title="Resp"
        sortKey="responsable"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sortingBy: {
      type: String,
      default: '',
    },
  },
  methods: {
    orderBy(key) {
      this.$emit('setSortingBy', this.sortingBy === key ? '' : key)
      let order = this.sortingBy === key ? 'asc' : 'desc'
      this.$emit('orderBy', key, order)
    },
  },
}
</script>

<style></style>
