<template>
  <div class="relative flex flex-1">
    <OffrePromoteurSeenByPopup />
    <div
      class="fixed z-40 flex-1 w-full h-full parent-layout lg:absolute lg:hidden"
      :class="{
        hidden: collapse,
        'lg:flex': !collapse,
      }"
    ></div>
    <div
      class="z-[6666] flex h-full sidebar xl:transition-all xl:duration-150 sm:w-full lg:shadow-box lg:fixed"
      :class="{
        collapse: collapse,
        'collapse-show': !collapse,
      }"
      style="transition: left 0.3s ease-in-out"
      v-click-outside="closeSidebar"
    >
      <SideBar />
    </div>
    <div
      class="flex flex-col flex-1"
      :class="[resizedWindow ? 'w-full' : 'layout-body ']"
    >
      <Navbar />
      <div
        class="flex flex-col items-center flex-1 bg-gray-100 lg:w-full lg:mt-42 sm:mt-36"
      >
        <main
          class="flex flex-col flex-1 w-full h-full"
          style="max-width: 1585px"
        >
          <router-view />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClickOutside from 'vue-click-outside'
import Navbar from '../components/NavBar'
import SideBar from '../components/SideBar/SideBar'

export default {
  components: {
    Navbar,
    SideBar,
  },
  computed: {
    ...mapGetters({
      resizedWindow: 'sidebar/resizedWindow',
      collapse: 'sidebar/collapse',
    }),
  },
  methods: {
    closeSidebar() {
      this.$store.commit('sidebar/COLLAPSE', true)
    },
  },
  directives: {
    ClickOutside,
  },
}
</script>
<style lang="scss" scoped>
.layout-body {
  // width: calc(100% - 344px);
  @screen lg {
    @apply w-full;
  }
}

@screen lg {
  .collapse {
    left: -100%;
    // transition: all 0.3s ease-in-out;
  }

  .collapse-show {
    left: 0;
  }
}
</style>
