<template>
  <div
    class="flex flex-col relative w-fit"
    v-else
    @mouseover="dataIsHover = hasAbonnement"
    @mouseleave="dataIsHover = false"
  >
    <span
      v-if="hasAbonnement"
      class="text-promy-green-200 lg:text-white lg:border-white border-b-2 border-promy-green-200 w-fit cursor-pointer text-sm sm:text-xs"
      >abonnements
    </span>
    <span
      v-if="!hasAbonnement"
      class="text-red-500 lg:text-white lg:border-white w-fit text-sm sm:text-xs"
      >aucun abonnement
    </span>
    <div
      class="z-50 bg-white flex flex-col flex-1 shadow-box rounded-lg p-4 space-y-5 text-sm absolute w-max top-[1.4rem] 1.5rem; left-0 sm:w-80 sm:left-0 max-h-72 overflow-y-auto"
      v-if="dataIsHover"
    >
      <div
        v-show="abonnements.departements.length > 0"
        v-for="departement in abonnements.departements"
        :key="departement.numerodepartement"
        class="flex justify-between items-center rounded-lg"
      >
        <div class="flex">
          <p class="mr-2">
            <span class="text-black"
              >{{ departement.name }}({{ departement.numerodepartement }})</span
            >
            <span class="text-promy-gray-500">
              - <span class="capitalize">Abonnement</span> départemental
            </span>
          </p>
        </div>
      </div>
      <div
        v-show="abonnements.regions.length > 0"
        v-for="region in abonnements.regions"
        :key="region.numerodepartement"
        class="flex justify-between items-center rounded-lg"
      >
        <div class="flex">
          <p class="mr-2">
            <span class="text-black">{{ region.name }}</span>
            <span class="text-promy-gray-500">
              - <span class="capitalize">Abonnement</span> régional
            </span>
          </p>
        </div>
      </div>
      <div
        v-show="abonnements.nations.length > 0"
        v-for="nation in abonnements.nations"
        :key="nation.id"
        class="flex justify-between items-center rounded-lg"
      >
        <div class="flex">
          <p class="mr-2">
            <span class="text-black">{{ nation.name }}</span>
            <span class="text-promy-gray-500">
              - <span class="capitalize">Abonnement</span> national
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    abonnements: {
      required: true,
    },
    isHover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dataIsHover: this.isHover,
    }
  },
  computed: {
    hasAbonnement() {
      return Object.keys(this.abonnements).some(
        (key) => this.abonnements[key].length > 0,
      )
    },
  },
}
</script>
