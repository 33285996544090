<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar
      ><progress-bar
        section="Localisation"
        :progress_section="progress_section.localisation"
      />
    </template>
    <template #current_page>Localisation</template>
    <template #body>
      <SideBarTools :open="sidebar_tools" @closeModel="toggleSidebarTools">
        <template #body>
          <VFilter
            ref="VFilter"
            v-if="$mq === 'sm' && map_is_style_loaded"
            :allParcelles="allParcelles"
          />
        </template>
      </SideBarTools>
      <div class="flex justify-between sm:justify-end">
        <button class="bg-promy-green-200 p-1 rounded-full hidden sm:flex p-2">
          <img
            src="/images/tool_bar_icon.svg"
            class="w-5"
            alt
            @click.stop="toggleSidebarTools(true)"
          />
        </button>
      </div>
      <div class="flex lg:flex-col relative overflow-hidden xl:-mr-8 mt-4">
        <Map
          class="h-64-screen"
          :allParcelles="allParcelles"
          v-if="renderComponent"
        ></Map>

        <div class="slide xl:w-6/24 sm-ws:w-7/24 xl:pl-6">
          <div
            class="flex flex-col xl:overflow-y-auto h-full relative flex-1"
            style="scroll-behavior: smooth"
            id="scroll_tools"
          >
            <div
              class="xl:absolute xl:top-0 xl:left-0 w-full h-full lg:flex sm:flex-col sm:mt-4 xl:pr-8 xl:pl-2"
            >
              <VFilter
                ref="VFilter"
                :allParcelles="allParcelles"
                v-if="($mq === 'xl' || $mq === 'lg') && map_is_style_loaded"
              />
            </div>
          </div>
        </div>
      </div>
      <infoItineraire class="hidden sm:flex"></infoItineraire>
      <InfosLocalisation />
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import infoItineraire from './infoItineraire'

import Map from './Map'
import VFilter from './VFilter'
import InfosLocalisation from './InfosLocalisation'
import defaultmap from '@/mixins/dossierPromoteurs/defaultmap'
import progress from '@/mixins/dossierPromoteurs/progress'

import { mapGetters } from 'vuex'

export default {
  mixins: [defaultmap, progress],
  data() {
    return {
      allParcelles: [],
      renderComponent: true,
    }
  },
  components: {
    Map,
    VFilter,
    InfosLocalisation,
    infoItineraire,
  },
  computed: {
    ...mapGetters({
      sidebar_tools: 'localisation/sidebar_tools',
      localisation: 'dossierpromoteur/getLocalisation',
      progress_section: 'dossierpromoteur/getProgressSection',
      poi: 'poi/poi',
      types: 'poi/types',
      map_is_style_loaded: 'defaultmap/map_is_style_loaded',
    }),
  },

  created() {
    this.allParcelles = [
      this.informationsStore.current_parcelle,
      ...this.informationsStore.parcelles_voisines,
    ]
    if (!Object.keys(this.localisation).length) {
      this.filterPOI()
    }
    this.$store.commit('dossierpromoteur/setProgressSection', {
      section: 'localisation',
      value: 100,
    })
  },
  methods: {
    saveInfos() {
      // sending requests
      // this.$toaster.success("Le dossier a bien été sauvegardé");
    },

    toggleSidebarTools(value) {
      this.$store.commit('localisation/SIDEBAR_TOOLS', value)
    },

    filterPOI() {
      let filterObjects = {}
      if (this.poi) {
        this.types.forEach((type) => {
          type.keys.forEach((key) => {
            if (this.poi.hasOwnProperty(key)) {
              let poi_key = this.poi[key]
              let subkeys_type = type.subKeys.map((item) => item.key)

              filterObjects[key] = Object.assign(
                {},
                filterObjects[key],
                _.pickBy(poi_key, function (v, k) {
                  return subkeys_type.includes(k)
                }),
              )
              for (let [key, localisations] of Object.entries(
                filterObjects[key],
              )) {
                localisations.forEach((loc) => {
                  if (!Array.isArray(loc.coordonnee_gps)) {
                    loc.coordonnee_gps = loc.coordonnee_gps
                      .split('(')[1]
                      .split(')')[0]
                      .split(' ')
                  }
                  loc.coordonnee_gps = loc.coordonnee_gps.map(Number)
                })
              }
            }
          })
        })
      }
      this.$store.commit('dossierpromoteur/setLocalisation', filterObjects)
    },
  },
  watch: {
    $mq(mq) {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
  },
}
</script>
<style lang="scss"></style>
