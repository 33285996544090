<template>
  <div
    class="flex flex-col items-center h-full px-24 bg-gray-100 lg:justify-center xl:py-32 sm:px-8"
  >
    <img src="/images/logo.svg" alt="" />
    <h1
      class="my-12 text-xl font-bold text-center text-gray-800 font-main sm:text-lg"
    >
      Bienvenu sur l'Espace Administrateur
    </h1>
    <div>
      <img src="/images/avatar.png" class="w-48 h-48" alt="" />
    </div>
    <form @submit.prevent="login" class="w-full md:w-full lg:w-1/2">
      <div v-if="errors.message" class="msg-error">{{ errors.message }}</div>
      <div>
        <label class="text-xs font-bold text-promy-gray-500 font-arial"
          >Email:</label
        >
        <input
          :class="{ 'input-error': errors.email }"
          class="w-full px-4 py-2 mt-1 border-2 border-solid rounded-full text-promy-gray-600 border-promy-gray-300 bg-promy-gray-background-300"
          v-model="form.email"
        />
        <div v-if="errors.email" class="msg-error">
          {{ errors.email[0] }}
        </div>
      </div>
      <div class="mt-4">
        <label class="text-xs font-bold text-promy-gray-500 font-arial"
          >Mot de passe:</label
        >
        <input
          type="password"
          :class="errors.email ? 'border-red-400' : 'border-promy-gray-300'"
          v-model="form.password"
        />
        <div v-if="errors.password" class="msg-error">
          {{ errors.password[0] }}
        </div>
      </div>
      <div class="flex flex-col items-center mt-6">
        <button
          type="submit"
          class="px-16 py-3 text-sm font-bold text-white uppercase transition-colors duration-100 border-2 rounded-full bg-promy-green-200 border-promy-green-300 font-main hover:bg-white hover:border-promy-green-300 hover:text-gray-800"
        >
          connexion
        </button>
        <router-link
          to="sendresetpassword"
          class="mb-3 text-xs underline text-promy-green-200 hover:text-promy-green-300"
          >Mot de passe oublié</router-link
        >
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      errors: [],
    }
  },
  methods: {
    async login() {
      this.errors = []
      try {
        await axios.get(`${process.env.VUE_APP_BASE}sanctum/csrf-cookie`)
        await this.$http
          .post(`login/admin`, this.form)
          .then((response) => {
            this.$store.commit(
              'auth/SET_ADMIN_PERMISSIONS',
              response.data.permissions,
            )
            this.$store.commit('auth/SET_LOGGEDIN', true)
            this.$store.dispatch('auth/me')
            // Redirect user to the appropriate link.
            this.$router.push(this.$route.query.redirect || '/')
          })
          .catch((e) => {
            if ([422, 401].includes(e.response.status)) {
              this.errors = e.response.data.errors
            }
          })
      } catch (e) {
        if ([422, 401].includes(e.response.status)) {
          this.errors = e.response.data.errors
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
input {
  @apply rounded-full px-4 py-2 text-promy-gray-600 border-solid border w-full mt-1 border-promy-gray-300 bg-promy-gray-background-300;
}

input:focus {
  @apply outline-none bg-white border-promy-green-200;
}

input[disabled] {
  @apply border-promy-gray-300 bg-promy-gray-300;
}

.msg-error {
  @apply rounded-lg w-full py-3 px-3 bg-promy-red-200 text-sm text-promy-red mb-4 mt-2 text-opacity-50;
}

.input-error {
  @apply border-promy-red-100;
}
</style>
