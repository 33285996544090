<template>
  <div>
    <h2 class="text-lg font-bold tracking-wide text-gray-800 font-main mt-8">
      Entreprise
    </h2>
    <div
      class="grid grid-cols-3 mt-8 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
    >
      <pro-input
        requirements="required"
        name="nom"
        v-model="form.raison_social"
        label="Nom d'entreprise"
      />
      <pro-input
        requirements="required"
        name="code postal"
        v-model="form.code_postal"
        label="Code postal"
      />
      <pro-input
        requirements="required"
        name="ville"
        v-model="form.ville"
        label="Ville"
      />
      <pro-input
        requirements="required"
        name="site web"
        v-model="form.website"
        label="Site web"
      />
      <pro-input
        requirements="required"
        name="adresse"
        v-model="form.complement_adress"
        label="Adresse"
      />
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'form',
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
