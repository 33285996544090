<template>
  <svg
    class="w-10 h-auto fill-current"
    :class="color"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M319.931,479.545c-16.101-19.933-140.303-173.723-140.399-173.842c56.24,17.289,112.738-25.037,112.738-83.2
			c0-79.119-97.131-116.871-150.706-59.707c-2.833,3.024-2.68,7.773,0.344,10.607c3.024,2.833,7.773,2.679,10.607-0.344
			c44-46.95,124.748-16.924,124.748,49.444c0,37.188-27.951,68.006-65.014,71.683c-54.813,5.448-93.733-49.075-74.218-98.305
			c1.526-3.853-0.358-8.214-4.211-9.741c-3.853-1.526-8.214,0.358-9.741,4.211c-4.836,12.205-6.966,25.49-5.747,39.57
			l-46.326-57.363c29.731-1.565,48.865-32.914,36.226-60.2c-4.4-9.508-12.122-17.02-21.741-21.152
			c-3.807-1.639-8.221,0.125-9.856,3.933c-1.637,3.808,0.124,8.22,3.933,9.856c21.626,9.291,22.186,40.112,0.685,50.069
			c-18.222,8.435-38.947-5.023-38.947-24.858c0-10.431,6.053-20.115,15.42-24.673c3.727-1.814,5.278-6.304,3.465-10.03
			s-6.303-5.277-10.03-3.465c-20.41,9.931-29.743,34.674-19.957,55.902c-4.75-0.194-9.332,0.19-14.029,1.236
			c-9.855,2.193-16.737,10.743-16.737,20.794c0,14.974,0,320.077,0,334.323c0,4.145,3.359,7.504,7.504,7.504h296.559l0.408-0.003
			C321.71,511.578,330.12,492.161,319.931,479.545z M15.443,169.973L15.443,169.973c0.001-2.964,2.053-5.491,4.991-6.146
			c12.464-2.775,26.47,0.504,34.057,10.919c0.198,0.272-3.125-3.859,62.455,77.348c-6.478-3.054-12.472-6.76-25.768-6.76
			c-22.031,0-23.297,10.005-40.829,10.005c-16.159,0-17.904-8.048-34.907-9.724V169.973z M304.74,496.79l-289.296,0.002
			c0-11.632,0-224.984,0-236.069c11.783,1.672,15.331,9.624,34.907,9.624c22.069,0,23.221-10.005,40.829-10.005
			c17.736,0,18.491,9.895,40.505,10c0.083,0.102,177.658,219.299,177.658,221.612C309.341,493.937,307.904,496.749,304.74,496.79z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M402.632,172.303c-13.878,0-25.168,11.29-25.168,25.168s11.29,25.168,25.168,25.168s25.168-11.29,25.168-25.168
			S416.51,172.303,402.632,172.303z M402.632,207.631c-5.602,0-10.16-4.558-10.16-10.16c0-5.602,4.558-10.16,10.16-10.16
			c5.603,0,10.16,4.558,10.16,10.16C412.792,203.072,408.234,207.631,402.632,207.631z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M486.394,171.188c-13.878,0-25.168,11.29-25.168,25.168s11.29,25.168,25.168,25.168s25.168-11.29,25.168-25.168
			S500.272,171.188,486.394,171.188z M486.394,206.516c-5.602,0-10.16-4.558-10.16-10.16s4.557-10.16,10.16-10.16
			s10.16,4.558,10.16,10.16S491.996,206.516,486.394,206.516z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M245.021,0c-13.878,0-25.168,11.29-25.168,25.168s11.29,25.169,25.168,25.169c13.878,0,25.168-11.29,25.168-25.168
			S258.898,0,245.021,0z M245.021,35.328c-5.602,0-10.16-4.558-10.16-10.16c0-5.602,4.557-10.16,10.16-10.16
			c5.603,0,10.16,4.558,10.16,10.16C255.181,30.77,250.622,35.328,245.021,35.328z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M474.683,25.669c-18.523-21.013-53.24-7.76-53.24,20.124c0,7.407,2.698,14.548,7.601,20.109
			c5.773,6.548,14.093,10.304,22.827,10.304C477.94,76.206,491.923,45.229,474.683,25.669z M451.872,61.199
			c-8.498,0-15.421-6.886-15.421-15.405c0-14.129,17.584-20.852,26.975-10.2C472.171,45.517,465.053,61.199,451.872,61.199z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M35.001,0.376c-14.566,0-26.417,11.851-26.417,26.417s11.85,26.419,26.417,26.419s26.418-11.852,26.418-26.418
			S49.567,0.376,35.001,0.376z M35.001,38.204c-6.291,0-11.41-5.119-11.41-11.411c0-6.291,5.119-11.41,11.41-11.41
			c6.292,0,11.411,5.119,11.411,11.41C46.411,33.086,41.293,38.204,35.001,38.204z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M499.861,352.96l-74.588-44.953c-3.252-1.961-6.984-3.012-10.815-3.052h-0.35c-3.842,0.02-7.614,1.071-10.896,3.052
			l-74.588,44.953c-17.397,10.458-13.265,36.842,6.393,41.601c0,13.023,0,87.45,0,103.122c0,8.024,7.734,14.317,17.609,14.317
			c12.314,0,111.222,0,123.232,0c9.875,0,17.609-6.293,17.609-14.317V394.551C513.286,389.753,517.067,363.303,499.861,352.96z
			 M478.461,496.472h-0.001c-2.112,0.996-3.484,0.279-28.204,0.52v-9.194c0-4.145-3.359-7.504-7.504-7.504
			c-4.145,0-7.504,3.359-7.504,7.504v9.194h-42.011v-34.838c0-11.539,9.455-21.001,21.01-21.001
			c10.262,0.001,19.186,7.652,20.757,17.798c0.633,4.094,4.459,6.906,8.564,6.266c4.095-0.634,6.9-4.468,6.266-8.564
			c-2.692-17.39-17.991-30.505-35.586-30.507c-19.829,0-36.018,16.145-36.018,36.008v34.838c-24.739-0.241-26.082,0.48-28.204-0.52
			c0-13.096,0-90.486,0-103.412c2.141-1.026-1.355,0.902,64.212-38.559c65.544,39.452,62.373,37.7,64.222,38.559V496.472z
			 M489.976,379.953c-5.593,1.385-2.464,0.239-71.136-40.21c-2.841-1.701-6.363-1.701-9.195,0.01l-65.273,39.33
			c-3.062,1.851-6.893,1.321-9.355-1.031c-3.712-3.557-2.98-9.624,1.351-12.246c78.004-46.655,74.622-45.843,77.769-45.843h0.18
			c3.221,0-0.122-0.761,77.789,45.843C498.01,369.371,496.657,378.309,489.976,379.953z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M331.382,69.751c-13.878,0-25.168,11.29-25.168,25.168s11.29,25.168,25.168,25.168s25.168-11.29,25.168-25.168
			S345.26,69.751,331.382,69.751z M331.382,105.079c-5.602,0-10.16-4.558-10.16-10.16s4.557-10.16,10.16-10.16
			s10.16,4.558,10.16,10.16S336.984,105.079,331.382,105.079z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M355.914,271.251c-11.139-12.637-30.346-13.799-42.937-2.704c-12.582,11.093-13.795,30.353-2.703,42.935
			c11.107,12.601,30.323,13.823,42.937,2.704C365.792,303.094,367.006,283.833,355.914,271.251z M343.286,302.928
			c-6.359,5.607-16.097,5.047-21.754-1.37v-0.001c-5.62-6.374-5.005-16.133,1.371-21.753c6.37-5.616,16.108-5.035,21.754,1.37
			C350.276,287.549,349.661,297.308,343.286,302.928z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M460.25,251.615c-16.774,0-30.42,13.647-30.42,30.42s13.647,30.42,30.42,30.42s30.42-13.647,30.42-30.42
			S477.023,251.615,460.25,251.615z M460.25,297.449c-8.498,0-15.413-6.914-15.413-15.413s6.914-15.413,15.413-15.413
			s15.413,6.914,15.413,15.413S468.748,297.449,460.25,297.449z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-promy-green-200',
    },
  },
}
</script>
