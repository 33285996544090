<template>
  <div>
    <h2 class="title-components">tableau</h2>
    <div
      class="mt-2"
      v-for="(tableau, index) in form.descriptifProjet.tableau"
      :key="index"
    >
      <div
        class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-8 xl:grid-cols-8 gap-2 mb-5"
      >
        <pro-input
          shouldBeHighlighted
          class="mr-1"
          requirements="required"
          name="Type"
          v-model="tableau.etages"
          label="Type"
        />

        <pro-input
          shouldBeHighlighted
          class="mr-1"
          requirements="required"
          name="sdp"
          v-model="tableau.sdp"
          label="sdp"
        />
        <pro-input
          shouldBeHighlighted
          class="mr-1"
          requirements="required"
          name="SHAB"
          v-model="tableau.surface_interieur"
          label="SHAB"
        />
        <pro-input
          shouldBeHighlighted
          class="mr-1"
          requirements="required"
          name="nombre de stationnements"
          v-model="tableau.surface_stationnements"
          label="nombre de stationnements"
        />
        <pro-input
          shouldBeHighlighted
          class="mr-1"
          name="nombre logements"
          requirements="required"
          type="number"
          v-model.number="tableau.nombre_logements"
          label="Nombre de logements "
        />
        <div class="flex sm:flex-col">
          <pro-input
            shouldBeHighlighted
            class="mr-1 w-full"
            requirements="required"
            name="commentaires"
            v-model="tableau.commentaires"
            label="commentaires"
          />
          <div
            class="flex items-end pb-3 ml-3"
            v-if="form.descriptifProjet.tableau.length > 1"
          >
            <span
              @click="deleteAttribut(index)"
              class="sm:hidden text-promy-red ml-auto mr-1 cursor-pointer"
            >
              <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
            </span>
            <span
              class="xl:hidden lg:hidden sm:block text-promy-red mx-auto cursor-pointer font-extrabold"
              >Supprimer cet élément</span
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm mb-5"
      @click="addNewTable()"
    >
      <p>Ajouter un champ</p>
      <span
        class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
      >
        <i class="fa fa-plus text-xs" />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: ['form'],
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addNewTable() {
      this.form.descriptifProjet.tableau.push({})
    },
    deleteAttribut(key) {
      this.form.descriptifProjet.tableau.splice(key, 1)
    },
  },
}
</script>
