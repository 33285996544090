function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
const authLayout = () => import(/* webpackChunkName: '' */ '../../layouts/auth')
import guest from '../../middleware/guest'

export default [
  {
    path: '/login',
    name: 'login',
    component: page('auth/login'),
    meta: {
      layout: authLayout,
      middleware: [guest],
    },
  },
  {
    path: '/sendresetpassword',
    name: 'SendResetPassword',
    component: page('auth/SendResetPassword'),
    meta: {
      layout: authLayout,
      middleware: [guest],
    },
  },
  {
    path: '/forgetpassword',
    name: 'ResetPassword',
    component: page('auth/ResetPassword'),
    meta: {
      layout: authLayout,
      middleware: [guest],
    },
  },
]
