var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10"},[_c('page-title',[_vm._v("Gestion des agents immobilier ")]),_c('table-title',{staticClass:"flex items-center justify-between sm:flex-col sm:items-start sm:space-y-3",attrs:{"nextPath":"ajouter"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("List des agents immobiliers")]},proxy:true},{key:"button",fn:function(ref){
var nextPath = ref.nextPath;
return [_c('div',{staticClass:"flex sm:flex-col-reverse sm:w-full items-center space-x-3 sm:space-x-0"},[_c('div',{staticClass:"sm:w-full"},[_c('pro-input',{staticClass:"sm:w-full",attrs:{"placeholder":"Rechercher"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(
            _vm.$can('delete,gestion_utilisateur') ||
            _vm.$can('edite,gestion_utilisateur')
          )?_c('div',{staticClass:"sm:w-full text-center px-4 py-2 text-xs font-semibold tracking-wide text-white uppercase transition-colors duration-200 border-2 rounded-full cursor-pointer hover:bg-white hover:text-promy-green-300 border-promy-green-300 bg-promy-green-300 font-main",on:{"click":function($event){$event.preventDefault();return nextPath('list-des-agents-immobilier-create')}}},[_vm._v(" ajouter un agent immobilier ")]):_vm._e()])]}}])}),_c('hr',{staticClass:"mb-6"}),_c('pro-card',[_c('pro-table-row',{attrs:{"isHeader":true}},_vm._l((_vm.sort_list),function(sort,index){return _c('pro-table-header-cell',{key:index,class:sort.classes,attrs:{"sortingBy":_vm.sorting_by,"hasSort":sort.sortKey ? true : false,"title":sort.title,"sortKey":sort.sortKey}})}),1),_c('InfiniteScrollContainer',{attrs:{"isLoading":_vm.isFetching,"containerScroll":false,"fetchingAllowed":_vm.last_page > _vm.current_page},on:{"fetch":function($event){return _vm.getAgentImmobiliers()}}},_vm._l((_vm.agentImmobiliers),function(item,index){return _c('pro-table-row',{key:index,staticClass:"relative"},[_c('pro-table-cell',{staticClass:"w-1/3 lg:w-full",attrs:{"label":"Nom"}},[_vm._v(" "+_vm._s(item.full_name)+" ")]),_c('pro-table-cell',{staticClass:"w-1/3 lg:w-full",attrs:{"label":"email"}},[(_vm.$mq === 'sm')?_c('span',[_vm._v(_vm._s(_vm._f("truncateTerrain")(item.email,19)))]):_c('span',[_vm._v(_vm._s(item.email))])]),_c('pro-table-cell',{staticClass:"w-1/3 lg:w-full",attrs:{"actions_btn":true}},[(
              _vm.$can('delete,gestion_utilisateur') ||
              _vm.$can('edite,gestion_utilisateur')
            )?_c('div',{staticClass:"w-full flex justify-end items-center space-x-3"},[_c('div',{staticClass:"btn-actions",on:{"click":function($event){return _vm.showDeleteModal(item)}}},[_c('i',{staticClass:"fas fa-trash"})]),_c('router-link',{staticClass:"btn-actions",attrs:{"to":{
                name: 'list-des-agents-immobilier-edit',
                params: { id: item.id, userId: item.user_id },
              }}},[_c('i',{staticClass:"fas fa-edit"})])],1):_vm._e()])],1)}),1)],1),(!_vm.agentImmobiliers.length && !_vm.isFetching)?_c('div',{staticClass:"w-full flex justify-center mt-24 text-lg font-main font-extrabold text-promy-gray-650"},[_vm._v(" Il n'y a pas d'agent immpbilier pour \""+_vm._s(_vm.search)+"\" ")]):_vm._e(),(_vm.showModal)?_c('ValidateModal',{on:{"isCancel":function($event){_vm.showModal = false},"isValide":function($event){return _vm.deleteAgentImmobilier()}}},[_vm._t("default",[_vm._v("Êtes-vous sûr de vouloir supprimer cet équipe ?")],{"slot":"message_body"}),_vm._t("default",[_vm._v("Confirmer")],{"slot":"button_valide"}),_vm._t("default",[_vm._v("Annuler")],{"slot":"button_cancel"})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }