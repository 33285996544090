import transport from '@/plugins/axios'

const getDefaultState = () => {
  return {
    delegationMandats: [],
    isLoading: false,
    current_page: 1,
    last_page: null,
  }
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    getDelegationMandats(state) {
      return state.delegationMandats
    },
    isLoading(state) {
      return state.isLoading
    },
    getCurrentPage(state) {
      return state.current_page
    },
    getLastPage(state) {
      return state.last_page
    },
  },
  mutations: {
    SET_DELEGATION_MANDATS(state, payload) {
      state.delegationMandats = payload
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload
    },
    SET_CURRENT_PAGE(state, payload) {
      state.current_page = payload
    },
    SET_LAST_PAGE(state, payload) {
      state.last_page = payload
    },
    RESET_INFINITE_SCROLL(state) {
      state.delegationMandats = []
      state.current_page = 1
      state.last_page = null
    },
    // UPDATE_MANDAT_STATUS(state, payload) {
    //   state.delegationMandats.forEach((delegation) => {
    //     if (delegation.id === payload.id) {
    //       delegation.status = payload.status
    //     }
    //   })
    // },
  },
  actions: {
    async getDelegationMandat({ commit, getters }, payload) {
      commit('SET_IS_LOADING', true)
      let response = await transport.get('admin/delegation-mandats', {
        params: {
          type: payload.type,
          sortBy: payload.sortBy,
          sortOrder: payload.sortOrder,
          page: getters.getCurrentPage,
        },
      })

      commit('SET_DELEGATION_MANDATS', [
        ...getters.getDelegationMandats,
        ...response.data.data,
      ])
      commit('SET_CURRENT_PAGE', response.data.meta.current_page)
      commit('SET_LAST_PAGE', response.data.meta.last_page)
      commit('SET_IS_LOADING', false)
    },
  },
}
