<template>
  <router-view></router-view>
</template>
<script>
export default {
  data() {
    return {
      parentUrl: '/outil-promoteur',
      links: [
        {
          name: 'Pige foncière',
          path: 'outil-promoteur/pige-fonciere/list',
          componentName: 'pige_list',
          gate: [
            'edite,pige_fonciere',
            'delete,pige_fonciere',
            'consultation,pige_fonciere',
          ],
        },
        {
          name: 'Gestion map guichet unique',
          path: 'outil-promoteur/map',
          componentName: 'map-outil-promoteur',
        },
      ],
    }
  },
  created() {
    this.$store.commit('subSidebar/LINKS', this.links)
    this.$store.commit('subSidebar/PARENT_URL', this.parentUrl)
  },
  mounted() {
    this.$store.commit('sidebar/RESIZE_WINDOW', true)
  },
  destroyed() {
    this.$store.commit('sidebar/RESIZE_WINDOW', false)
  },
}
</script>
