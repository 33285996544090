<template>
  <div>
    <router-view></router-view>
    <transition name="fade">
      <div
        id="pagetop"
        class="fixed p-2 right-2 bottom-8 bg-white rounded-full cursor-pointer shadow-md hover:shadow-lg"
        v-show="scY > 300"
        @click="toTop"
      >
        <arrowUp />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
