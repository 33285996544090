<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des admins </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>{{ action }} un admin</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('list-des-admins')"
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <div class="flex lg:flex-wrap">
      <div class="w-1/3 pr-4 md:w-full lg:w-2/5 md:pb-4 md:pr-0">
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <div
            class="mt-8 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
          >
            <div>
              <pro-input
                name="nom"
                requirements="required"
                v-model="form.nom"
                label="Nom"
              />
              <pro-input
                name="prenom"
                requirements="required"
                v-model="form.prenom"
                label="Prénom"
              />
              <InputPhoneNumber
                name="Tél Mobile"
                :rules="'required'"
                v-model="form.telephone_mobile"
                placeholder="+33"
                label="Tél Mobile"
              />
              <InputPhoneNumber
                name="Tél Fax"
                v-model="form.telephone_fix"
                placeholder="+33"
                label="Tél Fax"
              />
            </div>
            <div>
              <pro-input
                requirements="required|email"
                v-model="form.email"
                label="Email"
                name="email"
                :disabled="!isCreate"
              />
              <pro-input
                :requirements="passwordRequirement"
                name="Mot de pass"
                v-model="form.password"
                type="password"
                label="Mot de passe"
              />
              <pro-input
                requirements="required"
                v-model="form.adress"
                name="Adress"
                label="Adresse"
              />
              <h2 class="capitalize text-xs text-gray-600 font-bold font-arial">
                Occupation
              </h2>
              <select
                v-model="form.occupation_id"
                class="rounded-full px-4 py-2 text-gray-600 border-solid border w-full mt-1 text-sm bg-promy-gray-background-300 appearance-none"
              >
                <option
                  :value="occupation.id"
                  v-for="occupation in occupations"
                  :key="occupation.id"
                >
                  {{ occupation.occupation }}
                </option>
              </select>
            </div>
            <div>
              <div>
                <validation-provider rules="required" v-slot="{ errors }">
                  <div class="flex items-center mt-6">
                    <div class="mr-8">
                      <input
                        type="checkbox"
                        id="is_valide"
                        v-model="form.is_valide"
                      />
                      <label for="is_valide"></label>
                    </div>

                    <span class="text-sm text-gray-600" for="is_valide">
                      Validé
                    </span>
                  </div>
                  <span class="mt-1 text-sm text-red-600" v-if="errors[0]">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
                <validation-provider rules="required" v-slot="{ errors }">
                  <div class="flex items-center mt-6">
                    <div class="mr-8">
                      <input
                        type="checkbox"
                        id="email_verified_at"
                        v-model="form.email_verified_at"
                      />
                      <label for="email_verified_at"></label>
                    </div>

                    <span class="text-sm text-gray-600" for="email_verified_at">
                      Vérifier email
                    </span>
                  </div>
                  <span class="mt-1 text-sm text-red-600" v-if="errors[0]">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="my-12" v-if="$can('edite,gestion_utilisateur')">
            <BaseButton @handleClick="handleSubmit(save)" :loading="loading" />
          </div>
        </ValidationObserver>
      </div>
      <div class="w-2/3 pl-6 md:w-full lg:w-3/5 md:pl-0">
        <div class="flex items-center justify-between sm:flex-col">
          <span
            class="text-xl font-extrabold text-promy-purple-400 font-main sm:self-start sm:mb-2"
            >Droits d’utilisateur
          </span>
          <div class="flex items-center space-x-2">
            <span class="text-xl font-extrabold text-promy-purple-400 font-main"
              >Profils prédéfinis
            </span>
            <validation-provider
              class="flex flex-col"
              rules="required"
              v-slot="{ errors }"
            >
              <select
                :class="[errors[0] ? 'border-promy-red-100' : '']"
                class="px-4 py-3 text-sm capitalize border rounded-full"
                @change="roleSelected"
                v-model="form.role"
                name="roles"
                id=""
                v-tooltip="{
                  content: 'Edition profil',
                  classes: classes,
                }"
              >
                <option :value="role.slug" v-for="role in roles" :key="role.id">
                  {{ role.name }}
                </option>
              </select>
              <span v-show="errors[0]" class="msg-error">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <hr class="mt-4 mb-2 border-gray-400" />
        <validation-provider
          class="flex flex-wrap"
          rules="required"
          v-slot="{ errors }"
        >
          <div
            class="w-1/3 mt-4 sm:w-full md:w-1/3 lg:w-1/2"
            v-for="(permission, index) in permissions"
            :key="index"
          >
            <span
              class="text-xs font-bold text-promy-gray-500"
              v-if="permission[0].permissions.length"
            >
              {{ getTitlePermission(index) }}
            </span>
            <ul
              v-for="(permissionName, index) in permission[0].permissions"
              :key="index"
            >
              <li class="flex items-center mt-3">
                <CheckboxPermission
                  @input="checkboxChanged"
                  :id="permissionName.name"
                  :val="permissionName.name"
                  v-model="form.permissions"
                />
                <label
                  for="GU_utilisation_illimitee"
                  class="text-sm capitalize cursor-pointer pl-7 text-promy-gray-650"
                  >{{ permissionName.description }}</label
                >
              </li>
            </ul>
          </div>
          <span v-show="errors[0]" class="msg-error"
            >Choisir une ou plusieurs permissions</span
          >
        </validation-provider>
      </div>
    </div>
  </div>
</template>

<script>
import { toMulipartedForm } from '../../../../utilities/form'
import { sortBy, isEqual } from 'lodash'

export default {
  data() {
    return {
      action: 'Ajouter',
      storeURL: 'admin/admins',
      occupations: [],
      form: {
        nom: '',
        prenom: '',
        email: '',
        telephone_mobile: '',
        telephone_fix: '',
        password: '',
        adress: '',
        is_valide: false,
        type: 'admin',
        email_verified_at: false,
        occupation_id: '',
        role: '',
        permissions: [],
      },
      roles: [],
      permissions: [],
      rolePermissions: [],
      error: [],
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-xs',
        'bg-white',
        'mb-2',
      ],
      loading: false,
    }
  },
  created() {
    this.checkCurrentMode()
    this.fetchOccupations()
    this.getRoleAndPermissions()
  },
  computed: {
    passwordRequirement() {
      return this.isCreate ? 'required' : ''
    },
    isCreate() {
      return this.$route.meta.mode !== 'edit'
    },
  },
  watch: {
    'form.permissions': {
      handler(value) {
        console.log(value)
      },
    },
  },
  methods: {
    async getRoleAndPermissions() {
      const {
        data: { roles, permissions, role_permissions },
      } = await this.$http.get('admin/roles-permissions')

      this.roles = roles
      this.permissions = permissions

      // filter roles
      /*       this.roles = this.$can('delete,gestion_utilisateur')
              ? roles
              : roles.filter((role) => role.slug !== 'gestionnaire_equipe') */

      // filter permissions
      /*       if (this.$can('delete,gestion_utilisateur')) {
              this.permissions = permissions
            } else if (
              !this.$can('delete,gestion_utilisateur') &&
              this.$can('edite,gestion_utilisateur')
            ) {
              let filteredPermissions = { ...permissions }
              for (let permissionKey in filteredPermissions) {
                filteredPermissions[permissionKey][0].permissions =
                  filteredPermissions[permissionKey][0].permissions.filter(
                    (permission) =>
                      permission.name !== 'delete.gestion_utilisateur' &&
                      permission.name !== 'publish.dossier_promoteur' &&
                      permission.name !== 'delete.dossier_promoteur' &&
                      permission.name !== 'full_access.role_permission',
                  )
              }
              this.permissions = filteredPermissions
            } */

      // set roles permissions
      this.rolePermissions = role_permissions
    },
    getTitlePermission(title) {
      switch (title) {
        case 'marketplace':
          return 'MarketPlace'
        case 'mon histoire':
          return 'Mon activité'
        default:
          return title
      }
    },
    checkboxChanged() {
      this.changeRoleBaseOnPermission()
      /*       this.checkIfPersonaliseHasAdminPermissions()
       */
    },
    checkIfPersonaliseHasAdminPermissions() {
      if (
        (this.form.role == 'personalise' || this.form.role == '') &&
        this.form.permissions.includes('delete.gestion_utilisateur')
      ) {
        this.form.permissions = this.form.permissions.filter(function (item) {
          return item !== 'delete.gestion_utilisateur'
        })
        this.form.role = 'gestionnaire_equipe'
        this.roleSelected()
      }

      if (
        (this.form.role == 'personalise' || this.form.role == '') &&
        this.form.permissions.includes('edit.gestion_utilisateur')
      ) {
        this.form.role = 'gestionnaire'
        this.roleSelected()
      }
    },
    changeRoleBaseOnPermission() {
      this.rolePermissions.every((rolePermission) => {
        const permissionNames = rolePermission.permissions.map(
          (permission) => permission.name,
        )
        const isCurrentPermissionsMatchRoleWithPermission = isEqual(
          sortBy(permissionNames),
          sortBy(this.form.permissions),
        )
        if (
          (this.form.role = '' || !isCurrentPermissionsMatchRoleWithPermission)
        ) {
          this.form.role = rolePermission.slug
          return true
        } else if (isCurrentPermissionsMatchRoleWithPermission) {
          this.form.role = rolePermission.slug
          return false
        } else {
          return true
        }
      })
    },
    roleSelected() {
      const { permissions } = this.rolePermissions.find((rolePermission) => {
        return rolePermission.slug === this.form.role
      })
      this.form.permissions = []
      permissions.forEach((permission) =>
        this.form.permissions.push(permission.name),
      )
    },
    checkCurrentMode() {
      if (this.isCreate) return
      this.storeURL = `admin/admins/${this.$route.params.id}?_method=PUT`
      this.action = 'Editer'
      this.editableAdmin(this.$route.params.id)
    },
    fetchOccupations() {
      this.$http.get('admin/occupations').then((res) => {
        this.occupations = res.data.occupations
      })
    },
    editableAdmin(id) {
      this.$http.get(`admin/admins/${id}`).then((res) => {
        const user = { ...res.data.data, ...res.data.data.user }
        Object.keys(this.form).forEach((element) => {
          if (element in user) {
            this.form[element] = user[element]
          }
        })
      })
    },
    save() {
      this.loading = true
      const form = toMulipartedForm(this.form)

      this.$http
        .post(this.storeURL, form)
        .then(() => {
          this.$toaster.success(
            'vous avez ajouté un nouvel utilisateur avec succès',
          )
          this.$router.push({
            name: 'list-des-admins',
          })
          this.loading = false
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors)
          this.loading = false
        })
    },
  },
}
</script>
