<template>
  <div class="w-full">
    <div>
      <table-title>
        <template v-slot:title
          >Comparatif des logements neufs et annexes</template
        >
      </table-title>
      <div
        class="grid grid-cols-3 gap-8 mt-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
      >
        <div class="col-span-2">
          <div
            class="flex space-x-3"
            v-for="(reference_logements_neufs, index) in form
              .comparatif_des_prix.reference_logements_neufs"
            :key="index + '_logementsneufs'"
          >
            <pro-input
              shouldBeHighlighted
              class="8/24"
              :name="`nombre pieces ${index + 1}`"
              requirements="required"
              type="number"
              v-model.number="reference_logements_neufs.nb_piece"
              :label="`nombre pieces `"
            />
            <pro-input
              shouldBeHighlighted
              class="8/24"
              :name="`surface ${index + 1}`"
              requirements="required"
              type="number"
              v-model.number="reference_logements_neufs.surface"
              :label="`surface `"
            />
            <pro-input
              shouldBeHighlighted
              class="8/24"
              :name="`prix ${index + 1}`"
              requirements="required"
              type="number"
              v-model.number="reference_logements_neufs.prix"
              :label="`prix €/m2`"
            />
            <pro-input
              shouldBeHighlighted
              class="8/24"
              :name="`url ${index + 1}`"
              requirements="required"
              type="url"
              v-model.number="reference_logements_neufs.url"
              label="lien programme"
            />
            <div class="flex items-end pb-3 ml-3">
              <span
                @click="
                  deleteAttribut(
                    form.comparatif_des_prix.reference_logements_neufs,
                    index,
                  )
                "
                v-if="
                  form.comparatif_des_prix.reference_logements_neufs.length > 1
                "
                class="text-promy-red ml-auto mr-1 cursor-pointer"
              >
                <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
              </span>
            </div>
          </div>
          <div
            class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
            @click="
              addNewColumn(form.comparatif_des_prix.reference_logements_neufs)
            "
          >
            <p>ajouter un champ</p>
            <div
              class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
            >
              <i class="fa fa-plus text-xs" />
            </div>
          </div>
        </div>

        <div class="w-full">
          <label class="capitalize text-xs text-gray-600 font-bold font-arial"
            >image</label
          >
          <InputImage
            :file="form.comparatif_des_prix.image"
            @upload="saveImage"
            :selfUploader="true"
            @deleteImage="form.comparatif_des_prix.image = ''"
          />
          <HighlightMessage
            classes="relative top-7"
            :show="isEmptyOrNull(form.comparatif_des_prix.image)"
          />
        </div>
      </div>
    </div>
    <!-- Comparatif des parkings aériens -->
    <div>
      <table-title>
        <template v-slot:title>Comparatif des parkings aériens</template>
      </table-title>
      <div
        class="grid grid-cols-3 gap-8 mt-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
      >
        <div class="col-span-2">
          <div
            class="flex space-x-3"
            v-for="(parkings_aeriens, index) in form.comparatif_des_prix
              .parkings_aeriens"
            :key="index + '_parkings'"
          >
            <pro-input
              shouldBeHighlighted
              class="w-[24%]"
              :name="`nombre pieces ${index + 1}`"
              requirements="required"
              type="number"
              v-model.number="parkings_aeriens.nombre_parking"
              :label="`nombre parking aériens `"
            />
            <pro-input
              shouldBeHighlighted
              class="w-[24%]"
              :name="`surface ${index + 1}`"
              requirements="required"
              type="number"
              v-model.number="parkings_aeriens.surface"
              :label="`surface `"
            />
            <pro-input
              shouldBeHighlighted
              class="w-[24%]"
              :name="`prix ${index + 1}`"
              requirements="required"
              type="number"
              v-model.number="parkings_aeriens.prix"
              :label="`prix €/m2`"
            />
            <pro-input
              shouldBeHighlighted
              class="w-[24%]"
              :name="`lien programme ${index + 1}`"
              requirements="required"
              v-model.number="parkings_aeriens.lien_programme"
              :label="`lien programme`"
            />
            <div class="h-9 mt-7 flex items-center justify-center">
              <img
                v-if="!parkings_aeriens.show"
                src="/images/eye-off-outline.svg"
                @click="parkings_aeriens.show = true"
              />
              <img
                v-if="parkings_aeriens.show"
                src="/images/eye-outline.svg"
                @click="parkings_aeriens.show = false"
                v-tooltip.top="{
                  content: 'Affichez',
                  classes: classes,
                }"
              />
            </div>
            <div class="h-10 mt-7 flex items-center justify-center">
              <span
                @click="
                  deleteAttribut(
                    form.comparatif_des_prix.parkings_aeriens,
                    index,
                  )
                "
                v-if="form.comparatif_des_prix.parkings_aeriens.length > 1"
                class="text-promy-red ml-auto mr-1 cursor-pointer"
              >
                <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
              </span>
            </div>
          </div>
          <div
            class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
            @click="addNewColumn(form.comparatif_des_prix.parkings_aeriens)"
          >
            <p>ajouter un champ</p>
            <div
              class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
            >
              <i class="fa fa-plus text-xs" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Comparatif des parkings souterrains -->
    <div>
      <table-title>
        <template v-slot:title>Comparatif des parkings souterrains</template>
      </table-title>
      <div
        class="grid grid-cols-3 gap-8 mt-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
      >
        <div class="col-span-2">
          <div
            class="flex space-x-3"
            v-for="(parkings_souterrains, index) in form.comparatif_des_prix
              .parkings_souterrains"
            :key="index + '_souterrains'"
          >
            <pro-input
              shouldBeHighlighted
              class="w-[24%]"
              :name="`nombre pieces ${index + 1}`"
              requirements="required"
              type="number"
              v-model.number="parkings_souterrains.nombre_parking"
              :label="`nombre parking souterrains `"
            />
            <pro-input
              shouldBeHighlighted
              class="w-[24%]"
              :name="`surface ${index + 1}`"
              requirements="required"
              type="number"
              v-model.number="parkings_souterrains.surface"
              :label="`surface `"
            />
            <pro-input
              shouldBeHighlighted
              class="w-[24%]"
              :name="`prix ${index + 1}`"
              requirements="required"
              type="number"
              v-model.number="parkings_souterrains.prix"
              :label="`prix €/m2`"
            />
            <pro-input
              shouldBeHighlighted
              class="w-[24%]"
              :name="`lien programme ${index + 1}`"
              requirements="required"
              v-model.number="parkings_souterrains.lien_programme"
              :label="`lien programme`"
            />
            <div class="text-black h-9 mt-7 flex items-center justify-center">
              <img
                v-if="!parkings_souterrains.show"
                src="/images/eye-off-outline.svg"
                @click="parkings_souterrains.show = true"
              />
              <img
                v-if="parkings_souterrains.show"
                src="/images/eye-outline.svg"
                @click="parkings_souterrains.show = false"
                v-tooltip.top="{
                  content: 'Affichez',
                  classes: classes,
                }"
              />
            </div>
            <div class="h-10 mt-7 flex items-center justify-center">
              <span
                @click="
                  deleteAttribut(
                    form.comparatif_des_prix.parkings_souterrains,
                    index,
                  )
                "
                v-if="form.comparatif_des_prix.parkings_souterrains.length > 1"
                class="text-promy-red ml-auto mr-1 cursor-pointer"
              >
                <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
              </span>
            </div>
          </div>
          <div
            class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
            @click="addNewColumn(form.comparatif_des_prix.parkings_souterrains)"
          >
            <p>ajouter un champ</p>
            <div
              class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
            >
              <i class="fa fa-plus text-xs" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-6">
      <table-title class="lg:mb-2">
        <template v-slot:title>Synthese</template>
      </table-title>
      <div class="flex space-x-20 lg:flex-col lg:space-x-0 lg:space-y-2">
        <div class="flex w-1/3 lg:w-full">
          <pro-input
            shouldBeHighlighted
            class="mr-2 w-1/2"
            name="prix secteur neuf min "
            :requirements="`required|max_value:${form.synthese.prix_secteur_neuf.max}`"
            v-model="form.synthese.prix_secteur_neuf.min"
            label="prix secteur neuf min"
            type="number"
          />
          <pro-input
            shouldBeHighlighted
            class="w-1/2"
            name="prix secteur neuf max"
            :requirements="`required|min_value:${form.synthese.prix_secteur_neuf.min}`"
            v-model="form.synthese.prix_secteur_neuf.max"
            label="prix secteur neuf max"
            type="number"
          />
        </div>
        <div class="flex w-1/3 lg:w-full">
          <pro-input
            shouldBeHighlighted
            class="mr-2 w-1/2"
            name="prix secteur ancien min"
            :requirements="`required|max_value:${form.synthese.prix_secteur_ancien.max}`"
            v-model="form.synthese.prix_secteur_ancien.min"
            label="prix secteur ancien min"
            type="number"
          />
          <pro-input
            shouldBeHighlighted
            class="w-1/2"
            name="prix secteur ancien max"
            :requirements="`required|min_value:${form.synthese.prix_secteur_ancien.min}`"
            v-model="form.synthese.prix_secteur_ancien.max"
            label="prix secteur ancien max"
            type="number"
          />
        </div>
      </div>
    </div>
    <div class="mt-6">
      <table-title>
        <template v-slot:title>L'avis de la team PROMY</template>
      </table-title>
      <div
        class="grid grid-cols-3 gap-8 mt-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
      >
        <div>
          <div
            class="flex"
            v-for="(point_fort, index) in form.avis_de_promy.point_forts"
            :key="index"
          >
            <pro-input
              shouldBeHighlighted
              class="flex flex-1 w-full"
              :name="`point fort ${index + 1}`"
              requirements="required"
              v-model="point_fort.value"
              :label="`point fort`"
            />
            <div class="flex items-end pb-3 ml-3">
              <span
                @click="deleteAttribut(form.avis_de_promy.point_forts, index)"
                v-if="form.avis_de_promy.point_forts.length > 1"
                class="text-promy-red ml-auto mr-1 cursor-pointer"
              >
                <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
              </span>
            </div>
          </div>
          <div
            class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
            @click="addNewColumn(form.avis_de_promy.point_forts)"
          >
            <p>ajouter un champ</p>
            <div
              class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
            >
              <i class="fa fa-plus text-xs" />
            </div>
          </div>
        </div>
        <div>
          <div
            class="flex"
            v-for="(point_faible, index) in form.avis_de_promy.point_faibles"
            :key="index"
          >
            <pro-input
              shouldBeHighlighted
              class="flex flex-1"
              :name="`point faible ${index + 1}`"
              requirements="required"
              v-model="point_faible.value"
              :label="`point faible`"
            />
            <div class="flex items-end pb-3 ml-3">
              <span
                @click="deleteAttribut(form.avis_de_promy.point_faibles, index)"
                v-if="form.avis_de_promy.point_faibles.length > 1"
                class="text-promy-red ml-auto mr-1 cursor-pointer"
              >
                <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
              </span>
            </div>
          </div>
          <div
            class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
            @click="addNewColumn(form.avis_de_promy.point_faibles)"
          >
            <p>ajouter un champ</p>
            <div
              class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
            >
              <i class="fa fa-plus text-xs" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-6">
      <table-title>
        <template v-slot:title>Suggestion propriétaire</template>
      </table-title>
      <div class="flex flex-col mt-4">
        <div class="flex w-6/10 lg:w-full">
          <quill-editor
            ref="myQuillEditor"
            class="mb-12"
            v-model="form.description"
          />
        </div>
        <HighlightMessage
          classes="relative top-5 w-[46%] mb-6"
          :show="isEmptyOrNull(form.description)"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-xs',
        'bg-white',
        'mt-2',
      ],
    }
  },
  methods: {
    addNewColumn(value) {
      value.push({ show: true })
    },
    deleteAttribut(value, key) {
      value.splice(key, 1)
    },
    saveImage(file) {
      this.form.comparatif_des_prix.image = file
    },
    isEmptyOrNull(data) {
      return data === '' || data === null
    },
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
