export default {
  methods: {
    setRadon(risques, RADON) {
      RADON.definitionbis = risques.definitions_risques.RADON.definitionbis
      let alea_values = ['Faible', 'Moyen', 'Fort']
      RADON.sub_title.forEach((sub) => {
        if (sub.key === 'alea') {
          try {
            sub.value = alea_values[risques.radon[0].classePotentiel - 1]
          } catch {
            sub.value = 'Faible'
          }
        }
      })
    },
  },
}
