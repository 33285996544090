<template>
  <div
    class="flex flex-col rounded-box px-3 py-2 border-3 text-white justify-between"
  >
    <span class="text-xs font-extrabold">{{ titre }}</span
    ><span class="font-bold flex self-end" :class="SizeValeur">{{
      valeur
    }}</span>
  </div>
</template>
<script>
export default {
  props: {
    titre: {
      type: String,
      required: true,
    },
    valeur: {
      type: [Number, String],
      required: true,
    },
    SizeValeur: {
      type: String,
      default: 'text-base',
    },
  },
}
</script>
