<template>
  <div class="flex justify-center items-center*">
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <defs />
      <title />
      <g data-name="360-Document Home" id="_360-Document_Home">
        <polygon
          class="cls-1"
          :style="styleColor"
          points="10 1 4 7 4 31 28 31 28 1 10 1"
        />
        <polyline class="cls-1" :style="styleColor" points="10 1 10 7 4 7" />
        <polyline class="cls-1" :style="styleColor" points="8 18 16 12 24 18" />
        <polyline
          class="cls-1"
          :style="styleColor"
          points="11 16 11 25 21 25 21 16"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['color'],
  computed: {
    styleColor() {
      return 'stroke: ' + this.color
    },
  },
}
</script>

<style lang="scss" scoped>
svg {
  width: 25px;
  .cls-1 {
    fill: none;
    stroke: #000;
    stroke-linejoin: round;
    stroke-width: 1.5px;
  }
}
</style>
