<template>
  <div class="flex">
    <div
      @click="sortList"
      class="w-full border-2 justify-between flex space-x-1 px-2 py-2 rounded-full items-center cursor-pointer text-smaller"
    >
      <p class="text-promy-gray-800 font-bold uppercase select-none">
        {{ displayText }}
      </p>
      <div>
        <!-- noSort image -->
        <img
          class="h-4 w-3"
          src="/images/programme/noSort.svg"
          v-if="noSort === null"
        />

        <!-- up arrow -->
        <img
          v-if="sortAsc && noSort !== null"
          class="h-4 w-3"
          src="/images/programme/up_arrow.svg"
        />

        <!-- Down arrow -->
        <img
          v-if="!sortAsc && noSort !== null"
          class="h-4 w-3"
          src="/images/programme/down_arrow.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayText: {
      type: String,
      required: true,
    },
    SortBy: {
      type: String,
      required: true,
    },
    expand: {
      type: String,
    },
    resetSortIcon: {
      type: String,
    },
  },
  data() {
    return {
      sortAsc: false,
      noSort: null,
    }
  },
  methods: {
    sortList() {
      this.noSort = ''
      this.sortAsc = !this.sortAsc
      this.$emit('sortEvent', {
        sortBy: this.SortBy,
        sortAsc: this.sortAsc,
      })
    },
  },
  watch: {
    resetSortIcon(val) {
      val !== this.SortBy ? (this.noSort = null) : ''
    },
  },
}
</script>
