<template>
  <div v-if="activity_logs.length > 0">
    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :sortingBy="sorting_by"
          @setSortingBy="setSortingBy"
          @orderBy="orderBy"
          :hasSort="true"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <pro-table-row
        v-for="(item, index) in activity_logs"
        :key="index"
        class="relative"
      >
        <pro-table-cell label="Utilisateur" class="w-[16%] lg:w-full">
          {{ item.user ? item.user : 'anonyme' }}
        </pro-table-cell>
        <pro-table-cell label="Type D'utilisateur" class="w-[12%] lg:w-full">
          {{ item.type_user }}
        </pro-table-cell>
        <pro-table-cell label="Section" class="w-[12%] lg:w-full">
          {{ item.section }}
        </pro-table-cell>
        <pro-table-cell label="Description" class="w-[18%] lg:w-full">
          {{ item.description }}
        </pro-table-cell>
        <pro-table-cell label="IP" class="w-[12%] lg:w-full">
          {{ item.ip }}
        </pro-table-cell>
        <pro-table-cell label="Route" class="w-[18%] lg:w-full">
          {{ item.route }}
        </pro-table-cell>
        <pro-table-cell label="Effectué" class="w-[12%] lg:w-full">
          {{ item.created_at }}
        </pro-table-cell>
      </pro-table-row>
    </pro-card>
    <isVisible :isFetching="isFetching" @isVisible="isVisible" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Utilisateur',
          sortKey: 'user_full_name',
          classes: 'w-[16%]',
        },
        {
          title: "Type D'utilisateur",
          sortKey: 'causer_type',
          classes: 'w-[12%]',
        },
        {
          title: 'Section',
          sortKey: 'log_name',
          classes: 'w-[12%]',
        },
        {
          title: 'Description',
          sortKey: 'description',
          classes: 'w-[18%]',
        },
        {
          title: 'IP',
          sortKey: 'ipAddress',
          classes: 'w-[12%]',
        },
        {
          title: 'Route',
          sortKey: 'route',
          classes: 'w-[18%]',
        },
        {
          title: 'Effectué',
          sortKey: 'created_at',
          classes: 'w-[12%]',
        },
      ],
      sorting_by: '',
      activity_logs: [],
      isFetching: false,
      last_page: 1,
      current_page: 1,
      current_item: null,
    }
  },
  created() {
    this.getActivityLog('created_at', 'desc')
  },
  methods: {
    isVisible() {
      if (this.last_page === this.current_page) {
        return
      }
      this.current_page++
      this.getActivityLog(this.sortKey, this.sortOrder)
    },
    getActivityLog(sortBy, sortOrder) {
      this.sortKey = sortBy
      this.sortOrder = sortOrder
      this.isFetching = true
      this.$http
        .get('admin/user-activity', {
          params: {
            page: this.current_page,
            sortBy,
            sortOrder,
          },
        })
        .then((res) => {
          this.isFetching = false
          this.activity_logs.push(...res.data.data)
          this.last_page = res.data.meta.last_page
          this.current_page = res.data.meta.current_page
        })
    },

    orderBy(key, order) {
      this.sortKey = key
      this.sortOrder = order
      this.activity_logs = []
      this.current_page = 1
      this.getActivityLog(key, order)
    },

    setSortingBy(val) {
      this.sorting_by = val
    },
  },
}
</script>
