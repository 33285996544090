<template>
  <div id="lottie"></div>
</template>
<script>
import { colorify } from 'lottie-colorify'
export default {
  props: {
    size_bg_promy: {
      type: Number,
      default: 160,
    },
    color: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.main_color = this.color ? this.color : this.main_color
    var animationData = {
      v: '5.6.10',
      fr: 60,
      ip: 0,
      op: 3600,
      w: 160,
      h: 160,
      nm: 'Comp 1',
      ddd: 0,
      assets: [],
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'loader',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [80, 80, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [160, 160], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'laoder',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [-1, 1, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [80, 80], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Ellipse 1916',
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.48], y: [1] },
                  o: { x: [0.52], y: [0] },
                  t: 0,
                  s: [0],
                },
                { t: 61, s: [360] },
              ],
              ix: 10,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            p: { a: 0, k: [-0.092, 0.019, 0], ix: 2 },
            a: { a: 0, k: [-0.092, 40.019, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-7.294, -9.764],
                      ],
                      o: [
                        [13.125, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 0],
                        [32.067, 16.085],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0, 0.788235294118, 0.737254901961, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1916',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Ellipse 1916 copy',
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 25.882, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [-0.094, 0.016, 0], ix: 2 },
            a: { a: 0, k: [39.906, 40.016, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-22.091, 0],
                        [-7.113, -5.754],
                        [0, -12.57],
                        [22.091, 0],
                        [0, 22.091],
                      ],
                      o: [
                        [9.521, 0],
                        [8.823, 7.133],
                        [0, 22.091],
                        [-22.091, 0],
                        [0, -22.091],
                      ],
                      v: [
                        [40, 0],
                        [65.379, 9.081],
                        [80, 40],
                        [40, 80],
                        [0, 40],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0, 0.788235294118, 0.737254901961, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 4, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1916 copy',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape 1',
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [0.4, -0.342, 0], ix: 2 },
            a: { a: 0, k: [14.147, 17.506, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 7.368],
                        [3.633, 0],
                        [-0.591, -4.38],
                        [-0.083, 0.558],
                        [-8.655, 0],
                        [-0.016, -2.971],
                        [0, 0],
                        [0.695, 1.596],
                        [0.262, -0.438],
                        [-0.451, -1.257],
                        [-0.751, 0.856],
                      ],
                      o: [
                        [1.742, -1.987],
                        [0, 0],
                        [0, 0],
                        [0, -2.379],
                        [-18.805, 0],
                        [0.231, 1.712],
                        [2.147, -14.413],
                        [1.943, 0],
                        [0.029, 5.482],
                        [0, 0],
                        [-0.204, -0.468],
                        [-1.909, 3.185],
                        [0.384, 1.07],
                        [0, 0],
                      ],
                      v: [
                        [12.405, 34.258],
                        [15.152, 21.023],
                        [18.563, 19.087],
                        [28.293, 5.495],
                        [21.739, 0],
                        [0.061, 23.439],
                        [2.128, 24.733],
                        [20.041, 1.677],
                        [23.768, 6.05],
                        [15.356, 18.663],
                        [16.46, 5.822],
                        [14.67, 6.05],
                        [8.437, 34.311],
                        [12.405, 34.258],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0, 0.788235294118, 0.737254901961, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Rounded Rectangle 755',
          parent: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 10.196, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [0, 0, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {
                    a: 0,
                    k: [this.size_bg_promy, this.size_bg_promy],
                    ix: 2,
                  }, // bg-promy size k:
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 10, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.478431372549, 0.807843137255, 0.878431372549, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rounded Rectangle 755',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
      ],
      markers: [],
    }
    var params = {
      container: document.getElementById('lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
      animationData: colorify(
        [this.main_color, this.main_color, this.main_color],
        animationData,
      ),
    }

    var anim

    anim = this.$lottie.loadAnimation(params)
  },
}
</script>
<style lang="scss">
#lottie {
  width: 10rem;
  height: 10rem;

  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1;
  @screen sm-ws {
    width: 8rem;
    height: 8rem;
  }
  @screen sm {
    width: 7rem;
    height: 7rem;
  }
}
</style>
