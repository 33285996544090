<template>
  <router-view></router-view>
</template>
<script>
export default {
  created() {
    this.$store.dispatch('abonnement/getDepartements')
    this.$store.dispatch('abonnement/getRegions')
    this.$store.dispatch('abonnement/getNations')
  },
}
</script>
