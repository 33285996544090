<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.20039 11.01C6.06239 11.01 5.96939 11.0235 5.92139 11.037V11.9205C5.97839 11.934 6.04964 11.9378 6.14789 11.9378C6.50714 11.9378 6.72839 11.7563 6.72839 11.4495C6.72839 11.175 6.53789 11.01 6.20039 11.01V11.01ZM8.81564 11.019C8.66564 11.019 8.56814 11.0325 8.51039 11.046V13.0035C8.56814 13.017 8.66114 13.017 8.74514 13.017C9.35789 13.0215 9.75689 12.684 9.75689 11.97C9.76139 11.3475 9.39764 11.019 8.81564 11.019V11.019Z"
      class="fill-current"
      :class="color"
    />
    <path
      d="M10.5 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V6L10.5 1.5ZM7.1235 12.1425C6.89175 12.36 6.54975 12.4575 6.1515 12.4575C6.07428 12.4583 5.9971 12.4538 5.9205 12.444V13.5135H5.25V10.5615C5.55259 10.5164 5.85834 10.4958 6.16425 10.5C6.582 10.5 6.879 10.5795 7.07925 10.7393C7.26975 10.8908 7.39875 11.139 7.39875 11.4315C7.398 11.7255 7.3005 11.9738 7.1235 12.1425V12.1425ZM9.97875 13.1588C9.66375 13.4205 9.1845 13.545 8.59875 13.545C8.24775 13.545 7.9995 13.5225 7.83075 13.5V10.5623C8.13345 10.5181 8.4391 10.4973 8.745 10.5C9.31275 10.5 9.68175 10.602 9.96975 10.8195C10.281 11.0505 10.476 11.4188 10.476 11.9475C10.476 12.5198 10.2668 12.915 9.97875 13.1588V13.1588ZM12.75 11.0775H11.601V11.7607H12.675V12.3113H11.601V13.5142H10.9215V10.5225H12.75V11.0775ZM10.5 6.75H9.75V3L13.5 6.75H10.5Z"
      class="fill-current"
      :class="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-promy-gray-300',
    },
  },
}
</script>
