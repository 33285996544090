<template>
  <LayoutParametrage title="Boîte à outils">
    <template #current_page>Bibliothèque</template>
    <template #title_table></template>
    <template #add_button>
      <div
        class="w-full flex justify-between items-center sm:flex-col-reverse sm:items-start"
      >
        <div
          v-if="folder"
          class="font-arial -mb-10 text-promy-green-200 sm:-mb-4 sm:mt-4"
        >
          {{ folder.nom }} >
        </div>
        <router-link
          to="ajouter-document"
          class="btn-green flex items-center justify-center text-xs px-6 py-2 sm:w-full md:mt-8 sm:text-center"
        >
          <p class="mr-3">AJOUTER UN DOCUMENT</p>
          <addFileIcon />
        </router-link>
      </div>
    </template>
    <template #modal v-if="folder">
      <div class="flex space-x-4 mb-4">
        <SortBy
          text="NOM"
          sortBy="nom"
          :filter="sort"
          @applyFilterBy="sortBy"
        />
        <SortBy
          text="RECENT"
          sortBy="created_at"
          :filter="sort"
          @applyFilterBy="sortBy"
        />
      </div>
      <div
        v-if="!folder.documents.length"
        class="w-full font-main text-2xl text-promy-gray-500 text-center font-extrabold"
      >
        Aucun document
      </div>
      <div
        v-for="(document, idx) in folder.documents"
        :key="idx"
        class="flex items-center bg-white py-8 rounded-lg mb-4 shadow-md hover:shadow-lg"
      >
        <div class="w-26 sm:w-20 flex justify-center">
          <fileIcon />
        </div>
        <div
          class="flex-grow font-arial font-bold text-2xl sm:text-xl text-promy-gray-700 leading-none"
        >
          <p>{{ document.nom }}</p>
          <span class="text-sm text-promy-gray-400">{{ document.source }}</span>
        </div>
        <div class="w-30 flex justify-center">
          <div
            class="bg-promy-green-300 rounded-full p-2 mr-2 cursor-pointer flex items-center justify-center"
            @click.stop="getToUpdate(document)"
          >
            <img src="/images/update.svg" class="w-4 h-4" />
          </div>
          <div
            @click="
              ;(showModal_remove_doc = true), (remember_document = document)
            "
            class="bg-promy-green-300 rounded-full p-2 cursor-pointer flex items-center justify-center"
          >
            <img src="/images/trash.svg" class="w-4 h-4" />
          </div>
        </div>
        <!-- <isVisible :isFetching="isFetching" @isVisible="isVisible" /> -->
      </div>
      <ValidateModal
        v-if="showModal_remove_doc"
        @isCancel=";(showModal_remove_doc = false), (remember_document = null)"
        @isValide="removeDocument()"
      >
        <slot slot="message_body">
          <p class="w-96">Êtes-vous sûr de vouloir supprimer ce document ?</p>
        </slot>
        <slot slot="button_valide">Supprimer</slot>
        <slot slot="button_cancel">Annuler</slot>
      </ValidateModal>
    </template>
  </LayoutParametrage>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      showModal_remove_doc: false,
      remember_document: null,
      sort: '',
    }
  },

  created() {
    if (!this.folder) {
      this.$store.dispatch('manageDocuments/getFolder', {
        id: this.$route.params.id_folder,
      })
    }
  },

  methods: {
    getToUpdate(document) {
      this.$store.commit('manageDocuments/SET_CURRENT_DOCUMENT', document)
      this.$router.push(`${document.id}`)
    },

    async removeDocument() {
      try {
        await this.$store.dispatch(
          'manageDocuments/deleteDocument',
          this.remember_document,
        )
        this.$toaster.success('Le document est bien été supprimé.')
        this.remember_document = null
        this.showModal_remove_doc = false
      } catch {
        this.$toaster.warning(
          'Un probleme est survenu lors la suppression du document.',
        )
      }
    },
    sortBy(key) {
      this.sort = this.sort == key ? '' : key
      let order = this.sort ? 'asc' : 'desc'
      this.$store.dispatch('manageDocuments/getFolder', {
        id: this.$route.params.id_folder,
        key: key,
        order: order,
      })
    },
  },

  computed: {
    ...mapGetters({
      folder: 'manageDocuments/current_folder',
    }),
  },
}
</script>

<style></style>
