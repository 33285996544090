<template>
  <div>
    <table class="table-auto w-full md:hidden text-sm">
      <thead class="bg-promy-gray-225">
        <tr>
          <th
            v-for="(head, idx) in headers"
            :key="idx"
            :class="{ 'w-32': dateColumn(head) }"
          >
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(obj, idx) in body" :key="'obj' + idx">
          <td v-for="(val, index) in obj" :key="'val' + index">
            <a
              v-if="isValidHttpUrl(val)"
              :href="val"
              target="_blank"
              class="hover:border-promy-green-200"
              >Voir BDHI</a
            >
            <p v-else>{{ convertToString(val) }}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="hidden md:flex">
      <div
        class="flex flex-col w-full mt-4 text-xs text-promy-gray-750 font-extrabold"
      >
        <div
          class="flex flex-col items"
          v-for="(obj, idx) in body"
          :key="'obj' + idx"
        >
          <div
            class="flex justify-between px-3 py-3 border-promy-gray-300 item"
            :class="idx % 2 === 0 ? 'bg-promy-gray-225' : 'bg-promy-gray-125'"
            v-for="(head, key, index) in headers"
            :key="index"
          >
            <div class="w-1/2 pr-1">{{ head }}</div>
            <div class="w-1/2 pl-1">
              <a
                v-if="isValidHttpUrl(obj[key])"
                :href="obj[key]"
                target="_blank"
                class="hover:border-promy-green-200"
                >Voir BDHI</a
              >
              <p v-else>{{ convertToString(obj[key]) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default() {
        return ['PPRN', 'Aléa', 'Prescrit le', 'Approuvé le']
      },
    },
    body: {
      //  type: Array,
      required: true,
    },
    keyTable: {
      type: String,
    },
  },

  methods: {
    convertToString(value) {
      if (typeof value === 'object' && value && this.keyTable != 'ppr') {
        return value.toString()
      } else if (
        typeof value === 'object' &&
        value &&
        this.keyTable === 'ppr'
      ) {
        let res = []
        value.forEach((val) => {
          res.push(' ' + val.libelleRisque)
        })
        return res.toString()
      } else {
        return value
      }
    },

    isValidHttpUrl(val) {
      let url
      try {
        url = new URL(val)
      } catch (_) {
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    },

    dateColumn(val) {
      let keys = ['Prescrit le', 'Approuvé le']
      return keys.includes(val)
    },
  },
}
</script>

<style lang="scss" scoped>
thead {
  tr {
    @apply rounded-t-lg;
  }
}
th,
td {
  @apply py-2 px-3;
}

tbody tr:nth-child(even) {
  @apply bg-promy-gray-125;
}

a {
  @apply border-transparent border-b-2 text-promy-green-300;
}
</style>
