export default {
  methods: {
    setInnodation(risques, INN) {
      INN.sub_title = []
      let pprn_info = []
      this.setDetailRisqueINN(risques, INN)
      INN.sub_title.forEach((sub) => {
        if (sub.key === 'alea') {
          if (sub.id === 'TRI') {
            sub.value = this.notEmptyObject(risques.tri) ? 'Oui' : 'Non'
            sub.bodyTable = this.setValuesTable(
              risques.tri,
              'dateArreteNat',
              'libelleTri',
              'listLibRisqueLong',
              'listLibCoursDeau',
              'dateArretePcbTri',
            )
          } else if (sub.id === 'PAPI') {
            sub.value = this.notEmptyObject(risques.papi) ? 'Oui' : 'Non'
            sub.bodyTable = this.setValuesTable(
              risques.papi,
              ['codeNatPapi', 'libellePapi'],
              'listLibRisqueLong',
              'dateLabellisation',
              'dateSignature',
              'dateFinRealisation',
            )
          }
        } else if (sub.key === 'ppr') {
          if (this.notEmptyObject(risques.pprn)) {
            risques.pprn.forEach((pprn) => {
              pprn.listRisques.forEach((rsq) => {
                if (sub.codes.includes(rsq.numRisque)) {
                  sub.value = 'Oui'
                  pprn_info.push(pprn)
                }
              })
            })
            sub.value = sub.value === 'Oui' ? 'Oui' : 'Non'
            sub.bodyTable = this.setValuesTable(
              pprn_info,
              ['codeNatPpr', 'libellePpr'],
              'listRisques',
              'datePrescription',
              'dateApprobation',
            )
          } else {
            sub.value = 'Non'
          }
        }
      })
    },
    setDetailRisqueINN(risques, INN) {
      risques.definitions_risques.INN.alea.forEach((inn) => {
        let ids = ['TRI', 'BDHI', 'PAPI']
        if (ids.includes(inn.id)) this.setObjINN(INN, 'alea', inn)
      })
      this.setObjINN(INN, 'ppr', risques.definitions_risques.INN.ppr)
    },

    setObjINN(INN, key, obj) {
      let headersArretes = ['PPRN', 'Aléa', 'Prescrit le', 'Approuvé le']
      let alea_obj = {
        key: key,
        id: obj.id,
        label: obj.label,
        title: obj.title,
        tableTitle: obj.tableTitle,
        descrs: this.setDescriptionsINN(obj),
        properties: obj.properties ? obj.properties.toString() : null,
        layer: key === 'ppr' ? obj.layer : obj.layer[0],
        value: '',
        codes: key === 'ppr' ? obj.codes : [],
        tableHeaders: obj.table ? obj.table.headers : headersArretes,
      }
      INN.sub_title.push(alea_obj)
    },

    setDescriptionsINN(obj) {
      let descrs = []
      descrs.push(obj.descr)
      for (let i = 0; i < 5; i++) {
        if (obj['descr' + i]) descrs.push(obj['descr' + i])
      }
      return descrs
    },
  },
}
