export default {
  namespaced: true,
  state: {
    map: null,
    is_street: false,
    saved_sources: [],
    saved_layers: [],
    base_layers: [],
    current_markers: [],
    map_is_style_loaded: false,
  },
  getters: {
    map(state) {
      return state.map
    },
    is_street(state) {
      return state.is_street
    },
    saved_sources(state) {
      return state.saved_sources
    },
    saved_layers(state) {
      return state.saved_layers
    },
    base_layers(state) {
      return state.base_layers
    },
    current_markers(state) {
      return state.current_markers
    },
    map_is_style_loaded(state) {
      return state.map_is_style_loaded
    },
  },
  mutations: {
    SET_MAP(state, payload) {
      state.map = payload
    },
    RESET_MAP(state) {
      state.map = null
      state.is_street = false
      state.saved_sources = []
      state.saved_layers = []
      state.base_layers = []
      state.map_is_style_loaded = false
    },
    IS_STREET(state, payload) {
      state.is_street = payload
    },
    SAVED_SOURCES(state, payload) {
      state.saved_sources = payload
    },
    SAVED_LAYERS(state, payload) {
      state.saved_layers = payload
    },
    BASE_LAYERS(state, payload) {
      state.base_layers = payload
    },
    CURRENT_MARKERS(state, payload) {
      if (Array.isArray(payload) === false) state.current_markers.push(payload)
      else state.current_markers = payload
    },
    MAP_IS_STYLE_LOADED(state, payload) {
      state.map_is_style_loaded = payload
    },
  },
}
