import transport from '@/plugins/axios'
export default {
  namespaced: true,

  state: {
    allQuestionsMatching: null,
    QuestionsType: [],
    QuestionsTypeNumber: [],
  },

  actions: {
    getAllQuestionMatching({ commit }, params) {
      return new Promise((resolve, reject) => {
        transport.get('question_matchings', { params: params }).then(
          (response) => {
            commit('SET_ALL_QUESTIONS_MATCHING', response.data.data)
            commit('SET_QUESTION_TYPES', response.data.meta)

            resolve(response.data)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },
    saveQuestionItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        transport.post('question_matchings', payload.object).then(
          (response) => {
            resolve(response)
            payload.object.id = response.data.saved.id
            commit('SET_QUESTION_ITEM', payload)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },
    updateQuestionItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        transport
          .put(`question_matchings/${payload.object.id}`, payload.object)
          .then(
            (response) => {
              commit('UPDATE_QUESTION_ITEM', payload)
              resolve(response)
            },
            (error) => {
              reject(error)
            },
          )
      })
    },
    updateAllQuestionOrder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        transport
          .put('UpdateallQuestionMatching', {
            questions: payload,
          })
          .then(
            (response) => {
              resolve(response)
            },
            (error) => {
              reject(error)
            },
          )
      })
    },
    deleteQuestionItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        transport.delete(`question_matchings/${id}`).then(
          (response) => {
            resolve(response)
            commit('DELETE_QUESTION_ITEM', id)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },
  },

  mutations: {
    SET_QUESTION_TYPES(state, meta) {
      state.QuestionsType = meta.typesDefinition
      state.QuestionsTypeNumber = meta.types
    },
    SET_ALL_QUESTIONS_MATCHING(state, list) {
      list.forEach(function (element) {
        element.editText = false
        element.new_item = false
        element.type = element.type
      })
      state.allQuestionsMatching = list
    },
    UNSHIFT_NEW_QUESTION(state, payload) {
      state.allQuestionsMatching.unshift(payload)
    },
    SHIFT_NEW_QUESTION(state) {
      state.allQuestionsMatching.shift()
    },
    SET_QUESTION_ITEM(state, payload) {
      state.allQuestionsMatching[payload.idx] = payload.object
      state.allQuestionsMatching[payload.idx].type = payload.object.type
    },
    DELETE_QUESTION_ITEM(state, id) {
      state.allQuestionsMatching = state.allQuestionsMatching.filter(
        (item) => item.id != id,
      )
    },
    SHOW_EDIT(state, payload) {
      state.allQuestionsMatching[payload.idx] = payload.object
    },
    UPDATE_QUESTION_ITEM(state, payload) {
      state.allQuestionsMatching[payload.idx].question = payload.object.question
      state.allQuestionsMatching[payload.idx].editText = false
      state.allQuestionsMatching[payload.idx].type = payload.object.type
    },
  },

  getters: {
    all_questions_matching(state) {
      return state.allQuestionsMatching
    },
    QuestionsType(state) {
      return state.QuestionsType
    },
    QuestionsTypeNumber(state) {
      return state.QuestionsTypeNumber
    },
  },
}
