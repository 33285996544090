<template>
  <div class="relative">
    <div class="swiper-button-prev sm:hidden" slot="button-prev">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        class="fill-current text-promy-gray-500 hover:text-promy-green-300 outile-none border-none sm:w-9 sm:h-9"
      >
        <g id="arrow_group_left" transform="translate(-448 -1134)">
          <g id="arrow" transform="translate(-1042.764 340.578)">
            <path
              id="Tracé_14"
              data-name="Tracé 14"
              d="M1505.927,816.325h0a.59.59,0,0,1,0-.814h0l.558-.576,4.206-4.344a.545.545,0,0,1,.789,0l.557.575a.588.588,0,0,1,0,.814l-1.909,1.973a.578.578,0,0,0,.394.982h9.685a.567.567,0,0,1,.557.576v.814a.567.567,0,0,1-.557.575h-9.685a.578.578,0,0,0-.394.983l1.916,1.979a.59.59,0,0,1,0,.815l-.557.575a.545.545,0,0,1-.789,0l-4.213-4.353Z"
              fill="currentColor"
            />
          </g>
          <g
            id="Ellipse_1689"
            data-name="Ellipse 1689"
            transform="translate(448 1134)"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
          >
            <circle cx="22" cy="22" r="22" stroke="none" />
            <circle cx="22" cy="22" r="21" fill="none" />
          </g>
        </g>
      </svg>
    </div>
    <div class="swiper-button-next sm:hidden" slot="button-next">
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        class="fill-current text-promy-gray-500 hover:text-promy-green-300 sm:w-9 sm:h-9"
      >
        <g id="arrow_group" transform="translate(-1838 -1134)">
          <g id="arrow" transform="matrix(-1, 0, 0, -1, 3372.764, 340.578)">
            <path
              id="Tracé_13"
              data-name="Tracé 13"
              d="M1505.927-816.325h0a.59.59,0,0,0,0,.814h0l.558.576,4.206,4.344a.545.545,0,0,0,.789,0l.557-.575a.588.588,0,0,0,0-.814l-1.909-1.973a.578.578,0,0,1,.394-.982h9.685a.567.567,0,0,0,.557-.576v-.814a.567.567,0,0,0-.557-.575h-9.685a.578.578,0,0,1-.394-.983l1.916-1.979a.59.59,0,0,0,0-.815l-.557-.575a.545.545,0,0,0-.789,0l-4.213,4.353Z"
              fill="currentColor"
            />
          </g>
          <g
            id="Ellipse_1689"
            data-name="Ellipse 1689"
            transform="translate(1838 1134)"
            fill="none"
          >
            <path d="M22,0A22,22,0,1,1,0,22,22,22,0,0,1,22,0Z" stroke="none" />
            <path
              d="M 22 2 C 19.29912948608398 2 16.68012046813965 2.528518676757813 14.2157096862793 3.570880889892578 C 11.83435821533203 4.578098297119141 9.695270538330078 6.020450592041016 7.857860565185547 7.857860565185547 C 6.020450592041016 9.695270538330078 4.578098297119141 11.83435821533203 3.570880889892578 14.2157096862793 C 2.528518676757813 16.68012046813965 2 19.29912948608398 2 22 C 2 24.70085906982422 2.528518676757813 27.31987953186035 3.570880889892578 29.7842903137207 C 4.578098297119141 32.16563034057617 6.020450592041016 34.30471801757813 7.857860565185547 36.14212799072266 C 9.695270538330078 37.97954940795898 11.83435821533203 39.42189788818359 14.2157096862793 40.42911911010742 C 16.68012046813965 41.47148132324219 19.29912948608398 42 22 42 C 24.70085906982422 42 27.31986999511719 41.47148132324219 29.7842903137207 40.42911911010742 C 32.16563034057617 39.42189788818359 34.30471801757813 37.97954940795898 36.14212799072266 36.14212799072266 C 37.97954940795898 34.30471801757813 39.42189788818359 32.16563034057617 40.42911911010742 29.7842903137207 C 41.47148132324219 27.31986999511719 42 24.70085906982422 42 22 C 42 19.29912948608398 41.47148132324219 16.68012046813965 40.42911911010742 14.2157096862793 C 39.42189788818359 11.83435821533203 37.97954940795898 9.695270538330078 36.14212799072266 7.857860565185547 C 34.30471801757813 6.020450592041016 32.16563034057617 4.578098297119141 29.7842903137207 3.570880889892578 C 27.31987953186035 2.528518676757813 24.70085906982422 2 22 2 M 22 0 C 34.15026092529297 0 44 9.8497314453125 44 22 C 44 34.15026092529297 34.15026092529297 44 22 44 C 9.8497314453125 44 0 34.15026092529297 0 22 C 0 9.8497314453125 9.8497314453125 0 22 0 Z"
              stroke="none"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </div>
    <div
      class="swiper-button-prev swiper-button-white"
      slot="button-prev"
    ></div>
    <!-- swiper1 -->
    <swiper
      class="swiper gallery-top"
      :options="swiperOptionTop"
      ref="swiperTop"
    >
      <swiper-slide
        class="slide-1"
        v-for="(image, index) in images"
        :key="index"
        :style="`background-image: url(${$base_file}${image})`"
      >
      </swiper-slide>
    </swiper>
    <!-- swiper2 Thumbs -->
    <swiper
      class="swiper gallery-thumbs"
      :options="swiperOptionThumbs"
      ref="swiperThumbs"
    >
      <swiper-slide
        class="slide-1"
        v-for="(image, index) in images"
        :key="index"
        :style="`background-image: url(${$base_file}${image})`"
      >
      </swiper-slide>
      <swiper-slide
        v-for="n in slidesPerViewThumbs - 1"
        :key="n"
      ></swiper-slide>
    </swiper>
  </div>
</template>
<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    slidesPerViewThumbs: {
      type: Number,
      required: true,
      default: 3,
    },
  },
  data() {
    return {
      swiperOptionTop: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: false,
        loopedSlides: 4,
      },
      swiperOptionThumbs: {
        spaceBetween: 15,
        centeredSlides: false,
        slidesPerView: this.slidesPerViewThumbs,
        touchRatio: 1,
        slideToClickedSlide: true,
        loop: false,
        loopedSlides: 4,
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },
}
</script>
<style lang="scss" scoped>
.swiper-container,
.swiper-slide {
  border-radius: 10px;
}

.gallery-thumbs {
  width: 83%;
}
.gallery-thumbs {
  height: 127px !important;
  .swiper-slide {
    height: 95px !important;
  }
}
.swiper-button-prev,
.swiper-button-next {
  top: 93% !important;
}
.swiper-button-prev {
  left: 1px !important;
}
.swiper-button-next {
  right: 17px !important;
}
.swiper {
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  &.gallery-top {
    height: 80%;
    width: 100%;
  }
  &.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    //padding: $gap 0;
  }
  &.gallery-thumbs .swiper-slide {
    // width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}
.swiper-button-next,
.swiper-button-prev {
  background-image: unset !important;
  outline: none !important;
}
</style>
