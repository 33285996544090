function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
const defaultLayout = () =>
  import(/* webpackChunkName: '' */ '../../layouts/default')

import auth from '../../middleware/auth'

export default [
  {
    path: 'gestion-des-affaires',
    name: 'gestion-des-affaires',
    component: page('dashboard/GestionDesAffaires'),
    meta: {
      layout: defaultLayout,
      middleware: [auth],
    },
  },
  {
    path: 'gestion-des-architectes',
    name: 'gestion-des-architectes',
    component: page('dashboard/GestionDesArchitectes'),
    meta: {
      layout: defaultLayout,
      middleware: [auth],
    },
  },
]
