<template>
  <div class="mt-24">
    <h2 class="text-lg tracking-wide font-main text-gray-800 font-bold">
      Abonnement
    </h2>
    <div class="mt-8">
      <!-- select abonnement -->
      <div>
        <div
          class="flex items-center my-8"
          v-for="(abonnement, index) in abonnements"
          :key="index"
        >
          <div class="mr-8">
            <input
              type="checkbox"
              :id="abonnement.value"
              :value="abonnement.value"
              v-model="geographique"
            />
            <label :for="abonnement.value"></label>
          </div>
          <span class="text-gray-600 capitalize text-sm">
            {{ abonnement.name }}
          </span>
        </div>
      </div>

      <!-- end select abonnement -->
      <!-- selected abonnement -->
      <div
        class="flex md:flex-col items-start space-x-4 md:space-x-0 md:space-y-4"
      >
        <div
          v-if="showDepartement"
          class="w-1/2 md:w-full relative py-3 px-3 bg-gray-200 rounded-large h-64 overflow-auto flex flex-col"
        >
          <div
            class="flex items-center my-3"
            v-for="departement in form.notDepartements"
            :key="departement.id"
          >
            <div class="mr-8">
              <input
                :id="departement.id + departement.name"
                v-model="checked_departements"
                type="checkbox"
                :value="departement"
              />
              <label :for="departement.id + departement.name"></label>
            </div>
            <span class="text-gray-700 text-sm">
              {{ departement.name }}
            </span>
          </div>
        </div>
        <div
          v-if="showRegion"
          class="w-1/2 md:w-full relative py-3 px-3 bg-gray-200 rounded-large h-64 overflow-auto flex flex-col"
        >
          <div
            class="flex items-center my-3"
            v-for="region in form.notRegions"
            :key="region.id + region.name"
          >
            <div class="mr-8">
              <input
                :id="region.id + region.name"
                v-model="checked_regions"
                type="checkbox"
                :value="region"
              />
              <label :for="region.id + region.name"></label>
            </div>
            <span class="text-gray-700 text-sm"> {{ region.name }} </span>
          </div>
        </div>
        <div
          v-if="shownationals"
          class="w-1/2 md:w-full relative py-3 px-3 bg-gray-200 rounded-large h-64 overflow-auto flex flex-col"
        >
          <div
            class="flex items-center my-3"
            v-for="nations in form.notNations"
            :key="nations.id + nations.name"
          >
            <div class="mr-8">
              <input
                :id="nations.id + nations.name"
                v-model="checked_nations"
                type="checkbox"
                :value="nations"
              />
              <label :for="nations.id + nations.name"></label>
            </div>
            <span class="text-gray-700 text-sm"> {{ nations.name }} </span>
          </div>
        </div>
      </div>
      <!-- end selected abonnement -->
    </div>
    <div></div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'form',
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultAbonnementNations: [],
      defaultAbonnementDeparements: [],
      defaultAbonnementRegions: [],
      abonnements: [
        {
          name: 'National',
          value: 'nationals',
        },
        {
          name: 'Régions',
          value: 'regions',
        },
        {
          name: 'Départements',
          value: 'departements',
        },
      ],
      geographique: [],
      nations: [],
      regions: [],
      departementFilter: '',
      regionFilter: '',
      departements: [],
      checked_departements: [],
      checked_regions: [],
      checked_nations: [],
      nationals: [],
      shownationals: false,
      showDepartement: false,
      showRegion: false,
    }
  },
  watch: {
    'form.nations': {
      immediate: true,
      handler(value) {
        if (value.length > 0 && !this.geographique.includes('nationals')) {
          this.geographique.push('nationals')
        }
        value.forEach((element) => {
          delete element.pivot
          this.checked_nations.push(element)
        })
        this.defaultAbonnementNations = this.checked_nations
      },
    },
    'form.departements': {
      immediate: true,
      handler(value) {
        if (value.length > 0 && !this.geographique.includes('departements')) {
          this.geographique.push('departements')
        }
        value.forEach((element) => {
          delete element.pivot
          this.checked_departements.push(element)
        })
        this.defaultAbonnementDeparements = this.checked_departements
      },
    },
    'form.regions': {
      immediate: true,
      handler(value) {
        if (value.length > 0 && !this.geographique.includes('regions')) {
          this.geographique.push('regions')
        }
        value.forEach((element) => {
          delete element.pivot
          this.checked_regions.push(element)
        })
        this.defaultAbonnementRegions = this.checked_regions
      },
    },
    checked_departements(value) {
      if (value.length > 0 && !this.geographique.includes('departements')) {
        this.geographique.push('departements')
      }
      this.form.checked_departements = value
    },
    checked_regions(value) {
      if (value.length > 0 && !this.geographique.includes('regions')) {
        this.geographique.push('regions')
      }
      this.form.checked_regions = value
    },
    checked_nations(value) {
      if (value.length > 0 && !this.geographique.includes('nationals')) {
        this.geographique.push('nationals')
      }
      this.form.checked_nations = value
    },
    geographique(newValue) {
      if (newValue.includes('nationals')) {
        this.shownationals = true
        this.checked_nations = this.defaultAbonnementNations
      } else {
        this.shownationals = false
        this.checked_nations = []
      }
      if (newValue.includes('departements')) {
        this.showDepartement = true
        this.checked_departements = this.defaultAbonnementDeparements
      } else {
        this.showDepartement = false
        this.checked_departements = []
      }
      if (newValue.includes('regions')) {
        this.showRegion = true
        this.checked_regions = this.defaultAbonnementRegions
      } else {
        this.checked_regions = []
        this.showRegion = false
      }
    },
  },
  methods: {
    getnationals() {
      this.$http.get('nations').then((res) => {
        this.nations = res.data.nations
      })
    },
    getchecked_departements() {
      this.$http.get('departements').then((res) => {
        this.departements = res.data.departements
        this.departements.forEach((departement) => {
          delete departement.coordinates
          delete departement.path
          delete departement.lat
          delete departement.long
          delete departement.created_at
          delete departement.updated_at
          delete departement.region_id
          delete departement.numerodepartement
        })
      })
    },
    getchecked_regions() {
      this.$http.get('regions').then((res) => {
        this.regions = res.data.regions
        this.regions.forEach((region) => {
          delete region.coordinates
          delete region.path
          delete region.lat
          delete region.long
          delete region.terrains_count
          delete region.created_at
          delete region.updated_at
        })
      })
    },
    geographiqueChange() {
      if (this.geographique.includes('nationals', 2)) {
        this.region_id = []
        this.departement_id = []
        this.geographique.splice(0, 2)
      }
      if (
        (this.geographique.includes('departements') ||
          this.geographique.includes('regions')) &&
        this.geographique.includes('nationals')
      ) {
        this.nationals = []
        this.geographique.shift()
      }
    },
  },
}
</script>
