export function toMulipartedForm(form) {
  return objectToFormData(form)
}

export function objectToFormData(obj, form, namespace) {
  let fd = form || new FormData()
  let formKey
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']'
      } else {
        formKey = property
      }

      if (
        obj[property] instanceof Array &&
        !['number', 'string'].includes(typeof obj[property][0])
      ) {
        for (var i = 0; i < obj[property].length; i++) {
          objectToFormData(obj[property][i], fd, `${property}[${i}]`)
        }
      } else if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, property)
      } else {
        if (typeof obj[property] == 'boolean') {
          obj[property] = obj[property] == true ? 1 : 0
        }
        fd.append(formKey, obj[property])
      }
    }
  }
  return fd
}
