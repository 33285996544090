<template>
  <svg
    class="w-10 h-auto fill-current"
    :style="`fill :${color}`"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g id="Out_line" data-name="Out line">
      <path
        d="M346.73438,318.24219l-2.33887-39.761A23.99854,23.99854,0,0,0,336,232H176a23.99849,23.99849,0,0,0-8.39526,46.4812l-2.33912,39.761a425.38129,425.38129,0,0,1-44.42089,166.17969A7.99951,7.99951,0,0,0,128,496H384a7.99951,7.99951,0,0,0,7.15527-11.57812A425.38129,425.38129,0,0,1,346.73438,318.24219ZM176,248H336a8,8,0,0,1,0,16H176a8,8,0,0,1,0-16ZM140.74023,480a441.0422,441.0422,0,0,0,40.49805-160.81836L183.54346,280H328.45654l2.30518,39.18164A441.0422,441.0422,0,0,0,371.25977,480Z"
      />
      <circle cx="256" cy="384" r="8" />
      <path
        d="M274.92871,400.78125a7.99977,7.99977,0,0,0-10.94531-2.91895,16.24068,16.24068,0,0,1-15.9668,0,7.99977,7.99977,0,0,0-10.94531,2.91895l-16,27.71973a8,8,0,0,0,2.9209,10.92285,64.01754,64.01754,0,0,0,64.01562,0,8,8,0,0,0,2.9209-10.92285Zm-35.667,28.21094,8.17285-14.15918a32.31749,32.31749,0,0,0,17.13086,0l8.17285,14.15918A48.64094,48.64094,0,0,1,239.26172,428.99219Z"
      />
      <path
        d="M288.00488,328.57422a8.00156,8.00156,0,0,0-10.93359,2.92676l-16,27.71972a8,8,0,0,0,2.9209,10.92285A16.06706,16.06706,0,0,1,272,384a8.00039,8.00039,0,0,0,8,8h32a8.00039,8.00039,0,0,0,8-8A64.21856,64.21856,0,0,0,288.00488,328.57422ZM286.97461,376a32.199,32.199,0,0,0-8.56055-14.81934l8.17481-14.16211A48.27541,48.27541,0,0,1,303.32324,376Z"
      />
      <path
        d="M240,384a16.06706,16.06706,0,0,1,8.00781-13.85645,8,8,0,0,0,2.9209-10.92285l-16-27.71972a8.00156,8.00156,0,0,0-10.93359-2.92676A64.21856,64.21856,0,0,0,192,384a8.00039,8.00039,0,0,0,8,8h32A8.00039,8.00039,0,0,0,240,384Zm-31.32324-8a48.27541,48.27541,0,0,1,16.73437-28.98145l8.17481,14.16211A32.199,32.199,0,0,0,225.02539,376Z"
      />
      <path
        d="M87.88745,320H152V304H80a8,8,0,0,1,0-16h64V272H80a23.99861,23.99861,0,0,0-8.0791,46.59692A295.01042,295.01042,0,0,1,18.39258,482.05957l-.94727,1.35352A8,8,0,0,0,24,496h80V480H39.01465A310.68766,310.68766,0,0,0,87.88745,320Z"
      />
      <path
        d="M493.60645,482.05859A295.01137,295.01137,0,0,1,440.0791,318.59692,23.99861,23.99861,0,0,0,432,272H368v16h64a8,8,0,0,1,0,16H360v16h64.11279a310.69386,310.69386,0,0,0,48.87256,160H408v16h80a8,8,0,0,0,6.55469-12.58691Z"
      />
      <path
        d="M96,208a48.05375,48.05375,0,0,1,48-48,64.07207,64.07207,0,0,0,64-64,87.98644,87.98644,0,0,1,25.94141-62.62695l3.71582-3.71582L226.34277,18.34277,222.627,22.05859A103.88622,103.88622,0,0,0,192,96a48.05375,48.05375,0,0,1-48,48,64.07207,64.07207,0,0,0-64,64v48H96Z"
      />
      <path
        d="M216,216h16a24.0275,24.0275,0,0,1,24-24h36a52.059,52.059,0,0,0,52-52,36.04061,36.04061,0,0,1,36-36h8a52.059,52.059,0,0,0,52-52V16H424V52a36.04061,36.04061,0,0,1-36,36h-8a52.059,52.059,0,0,0-52,52,36.04061,36.04061,0,0,1-36,36H256A40.04584,40.04584,0,0,0,216,216Z"
      />
      <path
        d="M192,196a12.01312,12.01312,0,0,1-12,12H148a28.03146,28.03146,0,0,0-28,28v28h16V236a12.01312,12.01312,0,0,1,12-12h32a28.03146,28.03146,0,0,0,28-28V184H192Z"
      />
      <rect x="192" y="152" width="16" height="16" />
      <path
        d="M464,144a48.05375,48.05375,0,0,0-48,48,32.03667,32.03667,0,0,1-32,32h-8v16h8a48.05375,48.05375,0,0,0,48-48,32.03667,32.03667,0,0,1,32-32h32V144Z"
      />
      <path
        d="M480,200a16.01833,16.01833,0,0,1-16,16,32.03667,32.03667,0,0,0-32,32v8h16v-8a16.01833,16.01833,0,0,1,16-16,32.03667,32.03667,0,0,0,32-32v-8H480Z"
      />
      <path
        d="M384,160a16.01833,16.01833,0,0,1-16,16,32.03667,32.03667,0,0,0-32,32v8h16v-8a16.01833,16.01833,0,0,1,16-16,32.03667,32.03667,0,0,0,32-32v-8H384Z"
      />
      <path
        d="M272,120a16.01833,16.01833,0,0,1,16-16,32.03667,32.03667,0,0,0,32-32V64H304v8a16.01833,16.01833,0,0,1-16,16,32.03667,32.03667,0,0,0-32,32v8h16Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#00C9BC',
    },
  },
}
</script>
