<template>
  <div class="mt-2 space-y-3">
    <label class="slugs_input__label">{{ label }}</label>
    <div
      v-for="(video, index) in videos"
      :key="index"
      class="flex flex-col items-center"
    >
      <div class="flex items-center w-full space-x-2">
        <div class="flex flex-col w-full">
          <input
            class="slugs_input__values"
            type="text"
            disabled
            :value="video.name"
          />
          <input
            class="slugs_input__values mt-2"
            type="text"
            disabled
            :value="video.url"
          />
        </div>
        <div
          class="bg-promy-green-200 rounded-full cursor-pointer w-9 h-9 flex items-center justify-center"
          @click="remove(video, index)"
        >
          <svg
            width="14"
            height="2"
            viewBox="0 0 19 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 0.999999L1 1"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
      <hr class="mt-1 border-black" />
    </div>

    <div class="flex items-center w-full space-x-2">
      <div class="flex flex-col w-full">
        <input
          class="slugs_input__values"
          placeholder="Nom"
          type="text"
          v-model="video.name"
        />
        <validation-provider
          ref="url"
          name="url"
          rules="youtube"
          v-slot="{ errors }"
        >
          <input
            name="url"
            placeholder="Url"
            class="slugs_input__values mt-2"
            type="text"
            v-model="video.url"
          />
          <span class="mt-1 text-red-600 text-sm" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </validation-provider>
      </div>
      <div class="slugs_input__add_cta" @click="add()">
        <svg
          width="13"
          height="13"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5 1V9.5M9.5 9.5V18M9.5 9.5H18M9.5 9.5H1"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videos: {
      required: true,
    },
    label: {
      type: String,
      default: 'Untitled',
    },
    shouldValidate: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      video: {
        name: '',
        url: '',
        id: '',
      },
    }
  },
  watch: {
    shouldValidate: {
      immediate: true,
      handler() {
        this.add()
      },
    },
  },
  methods: {
    async add() {
      await this.$refs.url.validate().then((response) => {
        if (!response.valid || !this.video.name || !this.video.url) return
        this.$emit('videoChange', this.video)
        this.video = {
          name: '',
          url: '',
          id: '',
        }
        this.$nextTick(() => {
          this.$refs.url.reset()
        })
      })
    },
    remove(video, index) {
      this.videos.splice(index, 1)
      if (!video.id) return
      this.$emit('deleteVideo', video.id)
    },
  },
}
</script>

<style scoped>
.slugs_input__label {
  @apply capitalize text-xs text-gray-600 font-bold font-arial;
}
.slugs_input__values {
  @apply w-full pl-8 py-2 border border-gray-300 rounded-full text-gray-600 text-sm;
}
.slugs_input__input {
  @apply w-full pl-8 py-2 border border-gray-300 rounded-full text-gray-600 text-sm;
}
.slugs_input__add_cta {
  @apply px-3 py-[11px] bg-promy-green-200 rounded-full cursor-pointer;
}
</style>
