import transport from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    sidebar_tools: false,
    permis_construire_locaux: [],
    permis_construire_logements: [],
    dvf: [],
    loading_permis_construire_locaux: true,
    loading_permis_construire_logements: true,
    loading_dvf: true,
    is_permis_construction: true,
    is_dvf: true,
    is_marker_clicked: false,
    permis_construire: [],
    list_dvf: [],
  },
  getters: {
    sidebar_tools(state) {
      return state.sidebar_tools
    },
    permis_construire_locaux(state) {
      return state.permis_construire_locaux
    },
    permis_construire_logements(state) {
      return state.permis_construire_logements
    },
    list_dvf(state) {
      return state.list_dvf
    },
    dvf(state) {
      return state.dvf
    },
    loading_permis_construire_locaux(state) {
      return state.loading_permis_construire_locaux
    },
    loading_permis_construire_logements(state) {
      return state.loading_permis_construire_logements
    },
    loading_dvf(state) {
      return state.loading_dvf
    },
    is_permis_construction(state) {
      return state.is_permis_construction
    },
    is_dvf(state) {
      return state.is_dvf
    },

    is_marker_clicked(state) {
      return state.is_marker_clicked
    },
    permis_construire(state) {
      return state.permis_construire
    },
  },
  mutations: {
    SIDEBAR_TOOLS(state, payload) {
      state.sidebar_tools = payload
    },
    PERMIS_CONSTRUIRE_LOCAUX(state, payload) {
      state.permis_construire_locaux = Object.freeze(payload)
    },

    PERMIS_CONSTRUIRE_LOGEMENTS(state, payload) {
      state.permis_construire_logements = Object.freeze(payload)
    },
    LIST_DVF(state, payload) {
      state.list_dvf = payload
    },
    DVF(state, payload) {
      state.dvf = payload
    },
    LOADING_PERMIS_CONSTRUIRE_LOCAUX(state, payload) {
      state.loading_permis_construire_locaux = payload
    },

    LOADING_PERMIS_CONSTRUIRE_LOGEMENTS(state, payload) {
      state.loading_permis_construire_logements = payload
    },
    LOADING_DVF(state, payload) {
      state.loading_dvf = payload
    },
    IS_PERMIS_CONSTRUCTION(state, payload) {
      state.is_permis_construction = payload
    },
    IS_DVF(state, payload) {
      state.is_dvf = payload
    },

    IS_MARKER_CLICKED(state, payload) {
      state.is_marker_clicked = payload
    },
    PERMIS_CONSTRUIRE(state, payload) {
      state.permis_construire = payload
    },
  },
  actions: {
    getPermisConstruireLocaux({ commit }, params) {
      return new Promise((resolve, reject) => {
        transport
          .get('address', {
            params: {
              lon: params[0],
              lat: params[1],
              type: 'permis_construire_locaux',
            },
          })
          .then((response) => {
            commit(
              'PERMIS_CONSTRUIRE_LOCAUX',
              response.data.permis_construire_locaux,
            )
            commit('LOADING_PERMIS_CONSTRUIRE_LOCAUX', false)
            resolve(response)
          })
          .catch((err) => {
            commit('LOADING_PERMIS_CONSTRUIRE_LOCAUX', false)
            reject(err)
          })
      })
    },

    getPermisConstruireLogements({ commit }, params) {
      return new Promise((resolve, reject) => {
        transport
          .get('address', {
            params: {
              lon: params[0],
              lat: params[1],
              type: 'permis_construire_logements',
            },
          })
          .then((response) => {
            commit(
              'PERMIS_CONSTRUIRE_LOGEMENTS',
              response.data.permis_construire_logements,
            )
            commit('LOADING_PERMIS_CONSTRUIRE_LOGEMENTS', false)
            resolve(response)
          })
          .catch((err) => {
            commit('LOADING_PERMIS_CONSTRUIRE_LOGEMENTS', false)
            reject(err)
          })
      })
    },
    getDVF({ commit }, params) {
      return new Promise((resolve, reject) => {
        transport
          .get('address', {
            params: {
              lon: params[0],
              lat: params[1],
              type: 'DVF',
            },
          })
          .then((response) => {
            commit('DVF', response.data.DVF)
            commit('LOADING_DVF', false)
            resolve(response)
          })
          .catch((err) => {
            commit('LOADING_DVF', false)
            reject(err)
          })
      })
    },
  },
}
