<template>
  <div>
    <h2 class="text-lg font-bold tracking-wide text-gray-800 font-main"></h2>
    <div
      class="grid grid-cols-3 mt-8 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
    >
      <pro-select
        requirements="required"
        name="logiciel"
        label="logiciel"
        v-model="form.software_id"
        :selection="list_logiciels"
        display_properties="name"
        key_properties="id"
      />
      <pro-input
        name="identifiant client"
        v-model="form.software_ref"
        label="Identifiant client"
        :disabled="form.software_id != 1"
      />
      <pro-input
        requirements="required"
        name="raison social"
        v-model="form.raison_social"
        label="Raison sociale"
      />
      <pro-select
        requirements="required"
        name="profile"
        label="Profil"
        v-model="form.profile"
        :selection="list_profiles"
      ></pro-select>
      <pro-select
        requirements="required"
        name="type"
        label="type"
        v-model="form.types"
        :selection="list_type"
      ></pro-select>
      <pro-input
        name="siren"
        requirements="required"
        v-model="form.siren"
        label="Siren"
      />
      <pro-input
        requirements="required"
        name="capital"
        v-model="form.capital"
        label="capital"
      />
      <pro-input
        requirements="required"
        name="Adresse"
        v-model="form.adresse"
        label="Adresse"
      />
      <pro-input
        name="code postal"
        v-model="form.code_postal"
        label="code postal"
      />
      <pro-input name="ville" v-model="form.ville" label="ville" />
    </div>
    <div class="mt-4">
      <div class="relative">
        <label class="text-xs font-bold text-gray-600 font-arial">Agents</label>
        <div
          @click="toggleVisibility()"
          class="w-full px-4 py-2 mt-1 text-gray-600 border border-solid rounded-full focus:border-promy-green-300 border-promy-gray-300 bg-promy-gray-background-300"
        >
          <div>
            <template v-if="selectedItems.length > 0">
              <div class="flex">
                <div v-for="item in selectedItems" :key="item.user.email">
                  <div
                    class="flex justify-between px-4 py-1 mr-4 text-sm text-white rounded-full bg-promy-green-300"
                  >
                    <span class="mr-3">{{ item.user.email }}</span>
                    <span @click="removeItem(item)">x</span>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>choisir un élément...</template>
          </div>
        </div>
        <template v-if="openSelected">
          <div
            class="absolute left-0 flex flex-col w-full bg-white border rounded-lg z-50"
          >
            <input v-model="search" type="text" class="px-2 py-1 border" />
            <div
              v-for="item in agentsNoTeam"
              @click="addItem(item)"
              :key="item.user.email"
              class="px-2 py-1 transition-colors duration-200 cursor-pointer hover:bg-promy-green-300 hover:text-white"
            >
              <span>{{ item.user.email }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="mt-4">
      <label class="text-xs font-bold text-gray-600 font-arial">
        Abonnement départementale
      </label>
      <div
        class="relative mt-4 py-3 px-3 bg-gray-200 rounded-large h-64 overflow-auto flex flex-col"
      >
        <div
          class="flex items-center my-3"
          v-for="departement in departements"
          :key="departement.id"
        >
          <div class="mr-8">
            <input
              :id="departement.id + departement.name"
              v-model="checked_departements"
              @change="chosenDepatementsChanged($event, departement)"
              type="checkbox"
              :value="departement"
            />
            <label :for="departement.id + departement.name"></label>
          </div>
          <span class="text-gray-700 text-sm">
            {{ departement.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'form',
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      selectedItems: [],
      openSelected: false,
      agentsNoTeam: [],
      list_type: [
        'SA',
        'SAS',
        'SASU',
        'SARL',
        'EURL',
        'SCS',
        'SCA',
        'SNC',
        'SASP',
        'SE',
        'SCI',
        'SCP',
        'SCM',
        'SEL',
        'SELARL',
        'SELURL',
        'EI',
        'EIRL',
        'Autre',
      ],
      list_profiles: [
        'Constructeur Maison Individuelle (CMI)',
        'Lotisseur Aménageurs (AL)',
        'Agent Régional (PR)',
        'Agent National (PN)',
        'Autre',
      ],
      checked_departements: [],
      departements: [],
      list_logiciels: [],
    }
  },
  created() {
    this.getAgentWhoDoNotHaveTeam()
    this.getLogiciels()
    this.getAbonnementTypes()
  },
  watch: {
    search() {
      this.getAgentWhoDoNotHaveTeam()
    },
    'form.agent_immobilier': {
      immediate: true,
      handler(value) {
        if (value && value.length > 0) {
          this.form.agent_id = []
          value.forEach((item) => {
            this.selectedItems.push(item)
            this.form.agent_id.push(item.id)
          })
        }
      },
    },
    'form.software_id': {
      immediate: true,
      handler(value) {
        if (value && typeof value === 'object') {
          this.form.software_id = value.id
        }
      },
    },
    checked_departements: {
      deep: true,
      handler(departements) {
        this.form.departements = departements.map(
          (departement) => departement.id,
        )
      },
    },
  },
  methods: {
    toggleVisibility() {
      this.openSelected = !this.openSelected
    },
    addItem(item) {
      if (!this.form.agent_id) {
        this.form.agent_id = []
      }
      if (this.selectedItems.includes(item)) return
      this.selectedItems.push(item)
      this.form.agent_id.push(item.id)
      this.agentsNoTeam.splice(this.agentsNoTeam.indexOf(item), 1)
      this.toggleVisibility()
    },
    removeItem(item) {
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
      this.form.agent_id = this.form.agent_id.filter((id) => id != item.id)
      this.agentsNoTeam.push(item)
    },
    getAgentWhoDoNotHaveTeam() {
      this.$http
        .get('admin/agent-immobiliers/noteam', {
          params: {
            q: this.search,
          },
        })
        .then((res) => {
          this.agentsNoTeam = res.data.agents
        })
    },
    getLogiciels() {
      this.$http.get('admin/software-list').then((res) => {
        this.list_logiciels = res.data.softwares
      })
    },
    getAbonnementTypes() {
      this.$http
        .get('admin/teams/abonnement-types')
        .then(({ data: { departements } }) => {
          this.departements = departements
        })
    },
    chosenDepatementsChanged() {},
  },
}
</script>
