<template>
  <div>
    <p
      class="text-promy-gray-700 font-arial font-extrabold text-lg sm:text-sm py-4"
    >
      {{ radon.name }}
    </p>

    <p>
      <span
        class="text-promy-gray-600 sm:text-base font-arial"
        v-for="(sub, idx) in radon.sub_title"
        :key="idx"
      >
        {{ sub.label }}
        <span v-if="sub.displayRayon"> {{ sub.rayon }}m</span> :
        <span class="font-bold capitalize">{{ sub.value }}</span
        ><br />
      </span>
    </p>
    <p
      v-for="(definition, index) in radon.definitions"
      :key="index"
      class="text-promy-gray-600 font-arial sm:text-sm"
    >
      {{ definition }}
      <br />
      {{ radon.definitionbis }}
    </p>
    <div v-for="(sub, idx) in radon.sub_title" :key="'sub' + idx" class="py-6">
      <p class="text-promy-gray-600 font-arial font-bold sm:text-sm py-4">
        {{ sub.descr }}
      </p>
      <div class="w-full flex md:flex-col justify-start items-start">
        <div
          class="w-2/5 md:w-full h-64 border-2 flex justify-center items-center"
        >
          <Map
            :id_map="`map_radon_${idx}`"
            :layer="sub.layer"
            :opacity="0.8"
            ref="map"
          />
        </div>
        <img
          :src="`https://mapsref.brgm.fr/wxs/georisques/risques?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=${sub.layer}`"
          class="ml-6 md:ml-0 md:mt-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Map from './map.vue'

export default {
  components: {
    Map,
  },
  props: {
    rapport_technique: {
      type: Object,
      require: true,
    },
  },
  computed: {
    radon() {
      return this.rapport_technique.details_risques.RADON
    },
  },
}
</script>

<style lang="scss" scoped>
thead {
  tr {
    @apply rounded-t-lg;
  }
}
th,
td {
  @apply py-2 px-3;
}

tbody tr:nth-child(even) {
  @apply bg-promy-gray-125;
}
</style>
