<template>
  <div
    class="grid sm:grid-cols-1 items-center gap-x-2"
    :class="`grid-cols-${calculatedGridCols}`"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    gridCols: {
      type: [Number, String],
      default: 'auto',
    },
  },
  computed: {
    calculatedGridCols() {
      return this.gridCols !== 'auto'
        ? this.gridCols
        : this.$slots.default.length
    },
  },
}
</script>
