<template>
  <component
    :is="baseComponent"
    :to="link.path"
    :class="{
      'pl-3 sm:pl-5 remove-border': isActive && resizedWindow,
      'sm:pl-2': !isActive && resizedWindow,
    }"
    @click.native="closeSideBar"
  >
    <div
      :id="link.slug"
      :class="{
        'text-promy-green-300 pl-3 mb-0 sm-ws:mb-0 sm:my-0':
          isActive && resizedWindow,
        'text-gray-700 pl-6 my-4  sm-ws:my-0 sm:my-1':
          !isActive && resizedWindow,
        'text-promy-green-300 ml-15 lg:ml-14 mb-0 sm-ws:my-0 sm:my-0 ':
          isActive && !resizedWindow,
        'text-gray-700 ml-15 lg:ml-14 my-4 sm-ws:my-0 sm:my-1 ':
          !isActive && !resizedWindow,

        'opacity-100': hover,
      }"
      class="flex items-center item-drive"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div
        class="rounded-full w-12 h-12 border-2 border-solid border-promy-green-150 flex justify-center items-center"
      >
        <img :src="`/images/logo/${current}.png`" alt="" />
      </div>
      <div class="flex h-8 sm-ws:h-auto items-stretch sm:items-center">
        <span
          class="self-end ml-2 mr-2 font-semibold sm:text-sm sm:self-center"
          :class="{
            hidden: resizedWindow,
            '': !resizedWindow && !isActive,
            'ml-2 text-lg font-main font-bold': !resizedWindow,
            'text-promy-purple-300': !resizedWindow && isActive,
          }"
          >{{ link.name }}</span
        >
      </div>
    </div>
  </component>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
      current: this.link.icon,
    }
  },
  methods: {
    closeSideBar() {
      this.$store.commit('sidebar/COLLAPSE', true)
    },
  },
  computed: {
    ...mapGetters({
      resizedWindow: 'sidebar/resizedWindow',
    }),
    baseComponent() {
      return 'router-link'
    },
    isActive() {
      const parentRouteName = this.$route.matched.filter(
        (matched) => matched.name != undefined,
      )
      return [this.$route.name, parentRouteName[0].name].includes(
        this.link.icon,
      )
        ? true
        : false
    },
  },
  watch: {
    isActive() {
      this.$store.commit('sidebar/COLLAPSE', true)
    },
  },
}
</script>
<style scoped>
.remove-border {
  box-sizing: unset !important;
}
</style>
<style lang="scss">
.opacity-100 {
  .icon-sidebar {
    svg {
      .cls-1 {
        stroke-opacity: 1 !important;
      }
      .cls-2 {
        fill-opacity: 1 !important;
      }
    }
  }
}
</style>
