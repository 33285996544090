<template>
  <LayoutParametrage title="Boîte à outils">
    <template #current_page>Bibliothèque</template>
    <template #title_table>
      <div v-if="folder" class="font-arial -mb-10 text-promy-green-200">
        {{ folder.nom }} > {{ nom }}
      </div>
    </template>
    <template #add_button>
      <router-link
        class="text-promy-green-300 cursor-pointer"
        :to="{
          name: 'list-documents',
          params: { id_folder: $route.params.id_folder },
        }"
      >
        &larr; retour
      </router-link>
    </template>
    <template #modal>
      <p class="font-main font-extrabold text-lg text-promy-gray-650 mb-8">
        Document
      </p>
      <div class="flex sm:flex-col space-x-8 sm:space-x-0">
        <div class="w-1/3 lg:w-2/3 sm:w-full space-y-8">
          <div class="space-y-3">
            <label class="text-xs text-promy-gray-400"> Catégorie </label>
            <div class="flex sm:flex-col space-x-8 sm:space-x-0 sm:space-y-2">
              <ProRadio
                value="depertementale"
                @input=";(categorie = $event), (item_id = null)"
                name="pup"
                id="depertementale"
                label="Départemental"
                :checked="categorie === 'depertementale'"
                :required="true"
              />
              <ProRadio
                value="regionale"
                @input=";(categorie = $event), (item_id = null)"
                name="pup"
                id="regionale"
                label="Régionale"
                :checked="categorie === 'regionale'"
                :required="true"
              />
              <ProRadio
                value="nationale"
                @input=";(categorie = $event), (item_id = null)"
                name="pup"
                id="nationale"
                label="National"
                :checked="categorie === 'nationale'"
                :required="true"
              />
            </div>
            <div class="w-5/6 sm:w-full relative mt-2">
              <select
                v-model="item_id"
                class="rounded-full px-4 py-2 text-gray-600 border-solid border w-full mt-1 text-sm bg-promy-gray-background-300 appearance-none"
                :disabled="!list.length"
              >
                <option :value="item.id" v-for="item in list" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-5/6 sm:w-full">
            <label class="text-xs text-promy-gray-400"> Nom du document </label>
            <input-text
              class="w-full"
              :rules="'required'"
              v-model="nom"
              placeholder="Nom du document"
              type="text"
            />
          </div>
          <div class="w-5/6 sm:w-full">
            <label class="text-xs text-promy-gray-400"> Source </label>
            <input-textarea
              class="w-full"
              :rules="'required'"
              v-model="source"
              placeholder="Source du document"
              type="text"
            />
          </div>
        </div>
        <div class="w-1/3 sm:w-full">
          <label class="text-xs text-promy-gray-400"> Fichier </label>
          <div
            class="w-2/3 h-40 relative border border-dashed border-promy-gray-400 rounded flex items-center justify-center mt-2"
          >
            <div class="text-promy-gray-400 text-3xl">
              <i class="fa fa-upload fa-6" aria-hidden="true"></i>
            </div>
            <input
              ref="fileinput"
              @change.prevent="handleFileChange"
              class="w-full absolute opacity-0 h-full inset-0 cursor-pointer"
              type="file"
            />
          </div>
          <div
            v-if="file"
            class="flex justify-between items-center space-x-2 w-3/4 mt-4 px-4 py-2 border rounded-xl bg-white"
          >
            <p>{{ file }}</p>
            <span
              class="leading-none text-2xl cursor-pointer"
              @click="deleteFile()"
              >&#10005;</span
            >
          </div>
          <!-- <InputImage
            :file="file"
            :selfUploader="file ? true : false"
            :isFullPath="true"
            @upload="saveFile"
            @deleteImage="deleteFile()"
          /> -->
        </div>
      </div>
      <div class="mt-8">
        <button
          v-if="$route.name === 'add-document'"
          class="btn-green px-6 py-2 sm:w-full sm:text-center"
          @click="addDocument()"
        >
          Sauvegarder
        </button>
        <button
          v-else
          class="btn-green px-6 py-2 sm:w-full sm:text-center"
          @click="updateDocument()"
        >
          Modifier
        </button>
      </div>
    </template>
  </LayoutParametrage>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      categorie: '',
      item_id: null,
      list: [],
      nom: '',
      source: '',
      file: null,
    }
  },

  created() {
    if (!this.folder && !this.document) {
      this.$router.push({
        name: 'list-documents',
        params: { id_folder: this.$route.params.id_folder },
      })
    }

    if (this.$route.name === 'update-document' && this.document) {
      this.nom = this.document.nom
      this.source = this.document.source
      this.item_id = this.document.categorie_id
      this.file = this.document.file_path
      if (this.document.categorie_type.includes('Region')) {
        this.categorie = 'regionale'
      } else if (this.document.categorie_type.includes('Departement')) {
        this.categorie = 'depertementale'
      } else {
        this.categorie = 'nationale'
      }
    }
  },

  filters: {
    fileName(value) {
      let name
      if (value.name) {
        name = value.name
      } else {
        name = value.split('/')[5]
      }
      if (name.length > 30) return name.slice(1, 28).concat('....')
      else return name
    },
  },

  watch: {
    categorie(value) {
      switch (value) {
        case 'regionale':
          return (this.list = this.regions)
        case 'depertementale':
          return (this.list = this.departements)
        case 'nationale':
          return (this.list = this.nations)
      }
    },
  },

  methods: {
    handleFileChange(e) {
      this.file = e.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(this.file)
    },
    saveFile(inputFile) {
      this.file = inputFile
    },
    deleteFile() {
      this.file = null
    },
    getData(action = 'post') {
      let form_data = new FormData()
      form_data.append('categorie', this.categorie)
      form_data.append('item_id', this.item_id)
      form_data.append('folder_id', this.folder.id)
      form_data.append('nom', this.nom)
      form_data.append('source', this.source)
      form_data.append('file', this.file)
      if (action === 'put') form_data.append('_method', 'PUT')
      return form_data
    },
    async updateDocument() {
      let data = this.getData('put')
      try {
        await this.$store.dispatch('manageDocuments/updateDocument', {
          document: data,
          id: this.document.id,
        })
        this.$toaster.success('Le document est bien été modifié.')
        this.$store.commit('manageDocuments/SET_CURRENT_FOLDER', null)
        this.$router.push({
          name: 'list-documents',
          params: { id_folder: this.$route.params.id_folder },
        })
      } catch {
        this.$toaster.warning(
          'Un probleme est survenu lors la modification du document.',
        )
      }
    },
    async addDocument() {
      let data = this.getData()
      try {
        await this.$store.dispatch('manageDocuments/addDocument', data)
        this.$toaster.success('Le document est bien été ajouté.')
        this.$router.push({
          name: 'list-documents',
          params: { id_folder: this.folder.id },
        })
      } catch {
        this.$toaster.warning(
          "Un probleme est survenu lors l'ajoute du document.",
        )
      }
    },
  },

  computed: {
    ...mapGetters({
      departements: 'abonnement/departements',
      regions: 'abonnement/regions',
      nations: 'abonnement/nations',
      folder: 'manageDocuments/current_folder',
      document: 'manageDocuments/current_document',
    }),
  },
}
</script>

<style lang="scss" scoped>
select.disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #ccc;
}
</style>
