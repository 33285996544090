<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des actualites </page-title>
    <table-title nextPath="ajouter" class="sm:flex-row">
      <template v-slot:title>{{ action }} une actualité</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('actualite-departements')"
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <div
        class="grid grid-cols-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
      >
        <div>
          <pro-textarea
            name="libelle"
            requirements="required"
            v-model="form.libelle"
            label="Libellé"
          />
          <div>
            <label
              class="text-xs font-bold text-gray-600 capitalize font-arial"
              for="departement"
              >Départment</label
            >
            <select
              class="w-full px-4 py-2 mt-1 text-sm text-gray-600 border border-solid rounded-full bg-promy-gray-background-300"
              id="department"
              v-model="form.departement_id"
            >
              <option
                :value="department.id"
                v-for="department in departements"
                :key="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </div>
          <div class="flex flex-col mt-4">
            <label
              class="text-xs font-bold text-gray-600 capitalize font-arial"
              for="departement"
              >publié le</label
            >

            <validation-provider
              class="flex flex-col"
              name="Publié le"
              rules="required"
              v-slot="{ errors }"
            >
              <datepicker
                class="date-input"
                v-model="published_at"
                valueType="DD-MM-YYYY"
                format="DD-MM-YYYY"
              ></datepicker>

              <span class="mt-1 text-sm text-red-600" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </validation-provider>
          </div>
        </div>
      </div>
      <div class="my-6">
        <button @click="handleSubmit(save)" class="green-text-btn">
          valider
        </button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import ProTextarea from '../../../../components/UI/Fileds/ProTextarea.vue'
import { toMulipartedForm } from '../../../../utilities/form'
import moment from 'moment'

export default {
  components: { ProTextarea },
  data() {
    return {
      action: 'Ajouter',
      storeURL: 'admin/actualites',

      form: {
        libelle: '',
        published_at: '',
        departement_id: '',
      },
      published_at: '',
      departements: [],
      error: [],
    }
  },
  created() {
    this.getDepartments()
    this.checkCurrentMode()
  },
  watch: {
    published_at(value) {
      this.form.published_at = moment(value).format('DD-MM-YYYY')
    },
    'form.departement_id': {
      immediate: true,
      handler(value) {
        if (this.$route.meta.mode === 'edit') return
        this.storeURL = 'admin/actualites/departements/' + value
      },
    },
  },
  methods: {
    /**
     * Check current mode (edit, create)
     */
    checkCurrentMode() {
      if (this.$route.meta.mode !== 'edit') return
      this.storeURL = `admin/actualites/${this.$route.params.id}?_method=PUT`
      this.action = 'Editer'
      this.editableActualite(this.$route.params.id)
      this.getDepartments()
    },

    /**
     * get updatable promoteur
     * params ID router id
     */
    editableActualite(id) {
      this.$http.get(`admin/actualites/${id}`).then((res) => {
        this.form = res.data.data
        this.published_at = moment(this.form.published_at).format(
          'DD MMM yyyy HH:mm',
        )
      })
    },
    getDepartments() {
      this.$http.get('departements').then((res) => {
        this.departements = res.data.departements
      })
    },

    /**
     * Presist current promoteur information
     */
    save() {
      this.$http
        .post(this.storeURL, this.form)
        .then(() => {
          this.$toaster.success(
            'vous avez ajouté un nouvel utilisateur avec succès',
          )
          this.$router.push({
            name: 'actualite-departements',
          })
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors)
        })
    },
  },
}
</script>

<style></style>
