<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <div
      class="flex leading-2 text-promy-purple-400 text-promy-2xl font-extrabold font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">{{ title }} :</span>
      <span class="text-promy-gray-625 ml-2 font-normal sm:text-xl">
        <slot name="current_page"></slot>
      </span>
    </div>
    <div
      class="flex sm:flex-col sm:items-start sm:space-y-3 items-center lg:text-base mt-6 xl:text-lg font-main font-extrabold justify-between border-b-2 xl:pb-5 lg:pb-4 xl:border-promy-gray-300"
    >
      <span class="text-promy-gray-650 font-extrabold">
        <slot name="title_table"></slot>
      </span>
      <slot name="add_button"></slot>
    </div>

    <div class="flex w-full my-3 pt-4 text-xs">
      <span class="text-sm text-promy-gray-400 font-extrabold">
        <slot name="hint_table"></slot>
      </span>
    </div>
    <slot name="list_table"> </slot>
    <slot name="modal"> </slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: 'Paramétrage',
    },
  },
}
</script>
