import XLSX from 'xlsx'
import { saveAs } from 'file-saver'

function s2ab(s) {
  var buf = new ArrayBuffer(s.length)
  var view = new Uint8Array(buf)
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
  return buf
}

function xlsName() {
  return `monhistoire_${new Date()
    .toLocaleString()
    .replace(/\s/g, '')
    .replace(/,/g, '_')}.xlsx`
}

function exportCsv(programmes_selected) {
  const ws_data = [
    ['Name'],
    ['Adresse'],
    ['NB Lots'],
    ['Taille parcelle'],
    ['Type de stationnements'],
    ['Stationnements'],
    ['Surface habitable totale (m²)'],
    ['Fiscalité'],
    ['Responsable projet'],
    ['Typologie'],
    ['Note interne'],
    ['Prix moyen/m2 nombre de lots HT (€)'],
    ['Prix moyen/m2 surface habitable HT (€)'],
    ["Chiffre d'affaire"],
    ["Chiffre d'affaire Hors Taxe"],
    ['Date dépôt de permis de construire'],
    ["Date d'obtention du Permis de construire"],
    ['Date de début de commercialisation'],
    ['Date de fin de commercialisation'],
    ['Date de livraison'],
    ['Type'],
    ['Description'],
  ]

  programmes_selected.forEach((programme) => {
    let {
      adresse,
      chiffre_affaire,
      date_depot,
      date_fin_commercialisation,
      description,
      nombre_lots,
      prix_moy_m2_nbre_lots,
      prix_moy_m2_surface_hab,
      type_stationnements,
      name,
      chiffre_affaire_hors_taxe,
      taille_parcelle,
      surface_plancher,
      date_livraison,
      date_obtention,
      stationnement,
      fiscalite,
      date_debut_commercialisation,
      responsable_projet,
      nombre_pieces,
      note_intern,
      typologie,
    } = programme
    ws_data[0].push(name)
    ws_data[1].push(adresse)
    ws_data[2].push(nombre_lots)
    ws_data[3].push(taille_parcelle)
    ws_data[4].push(type_stationnements)
    ws_data[5].push(stationnement)
    ws_data[6].push(surface_plancher)
    ws_data[7].push(fiscalite)
    ws_data[8].push(responsable_projet)
    ws_data[9].push(nombre_pieces)
    ws_data[10].push(note_intern)
    ws_data[11].push(prix_moy_m2_nbre_lots)
    ws_data[12].push(prix_moy_m2_surface_hab)
    ws_data[13].push(chiffre_affaire)
    ws_data[14].push(chiffre_affaire_hors_taxe)
    ws_data[15].push(date_depot)
    ws_data[16].push(date_obtention)
    ws_data[17].push(date_debut_commercialisation)
    ws_data[18].push(date_fin_commercialisation)
    ws_data[19].push(date_livraison)
    ws_data[20].push(typologie)
    ws_data[21].push(description)
  })

  const wb = XLSX.utils.book_new()
  wb.Props = {
    Title: `Mon histoire exporté ${new Date().toDateString()}`,
    Subject: 'Mon histoire',
    Author: 'Promy',
    CreatedDate: new Date(),
  }
  wb.SheetNames.push('Promy Sheet')
  const ws = XLSX.utils.aoa_to_sheet(ws_data)
  wb.Sheets['Promy Sheet'] = ws
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  saveAs(
    new Blob([s2ab(wbout)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    xlsName(),
  )
}

export { exportCsv, s2ab, xlsName }
