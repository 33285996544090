<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.29"
    height="15"
    viewBox="0 0 19.29 15"
  >
    <g id="burger-menu" transform="translate(-2050.86 -404)">
      <rect
        id="Rounded_Rectangle_824"
        data-name="Rounded Rectangle 824"
        width="19.29"
        height="2.86"
        rx="1.43"
        transform="translate(2050.86 404)"
        :fill="color"
      />
      <rect
        id="Rounded_Rectangle_824_copy_2"
        data-name="Rounded Rectangle 824 copy 2"
        width="19.29"
        height="2.86"
        rx="1.43"
        transform="translate(2050.86 416.14)"
        :fill="color"
      />
      <rect
        id="Rounded_Rectangle_824_copy"
        data-name="Rounded Rectangle 824 copy"
        width="12.14"
        height="2.86"
        rx="1.43"
        transform="translate(2050.86 409.71)"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
}
</script>

<style></style>
