<template>
  <div class="w-full flex text-white uppercase font-extrabold pl-1">
    <div class="flex items-end text-sm pb-1" style="margin-right: 5px">
      faible
    </div>
    <div class="flex items-center flex-col pt-0 w-14/24">
      <div class="text-sm mt-1 mb-1 text-center">moyen</div>
      <div class="relative fit-content">
        <span
          class="absolute font-extrabold"
          style="bottom: 35%; left: 50%; transform: translate(-50%, 0%)"
        >
          {{ risquesStatus }}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-full h-auto"
          viewBox="0 0 153.94 124.625"
        >
          <path
            stroke="#89E2DC"
            style="fill: none; stroke-linecap: round; fill-rule: evenodd"
            d="M1037.14,837.862a67.962,67.962,0,1,1,117.53.011"
            transform="translate(-1018.94 -726.719)"
            stroke-width="18"
          />
          <path
            stroke="#008279"
            :style="`stroke-dashoffset: ${deg_risques}; fill: none; stroke-linecap: round; fill-rule: evenodd;`"
            d="M1037.14,837.862a67.962,67.962,0,1,1,117.53.011"
            transform="translate(-1018.94 -726.719)"
            stroke-width="18"
            stroke-dasharray="285 285"
          />
        </svg>
      </div>
    </div>
    <div class="flex items-end text-sm pb-1" style="margin-left: 3px">fort</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      deg_risques: 280,
      risquesStatus: 'faible',
    }
  },
  created() {
    this.getStatutRisqueByValue()
    this.setRisquesStatus()
  },
  props: {
    nbr_risques_exist: {
      type: Number,
      default: 0,
    },
    total_risque_evalue: {
      type: Number,
      required: true,
    },
  },

  methods: {
    getStatutRisqueByValue() {
      if (this.nbr_risques_exist == 0 || this.nbr_risques_exist == 1) {
        this.deg_risques = this.getDegRisques()
      } else if (this.nbr_risques_exist == 2 || this.nbr_risques_exist == 3) {
        this.deg_risques = this.getDegRisques() - 122.14
      } else if (this.nbr_risques_exist > 3) {
        this.deg_risques = this.getDegRisques() - 142.5
      }
    },
    setRisquesStatus() {
      if (this.nbr_risques_exist === 0 || this.nbr_risques_exist === 1) {
        this.risquesStatus = 'faible'
      } else if (this.nbr_risques_exist > 1 && this.nbr_risques_exist < 4) {
        this.risquesStatus = 'moyen'
      } else {
        this.risquesStatus = 'fort'
      }
    },
    getDegRisques() {
      return 285 - (this.nbr_risques_exist * 142.5) / this.total_risque_evalue
    },
  },
}
</script>
