<template>
  <div
    class="flex sm:flex-col flex-row justify-between items-center w-full mt-16"
  >
    <div class="text-base tracking-wide font-main text-gray-800 font-bold">
      <slot name="title"></slot>
    </div>
    <slot :nextPath="nextPath" name="button"></slot>
  </div>
</template>

<script>
export default {
  methods: {
    nextPath(name) {
      this.$router.push({ name: name })
    },
  },
}
</script>

<style></style>
