<template>
  <LayoutParametrage title="Dossiers Promoteurs">
    <template #current_page> Quelques chiffres</template>
    <template #title_table></template>
    <template #add_button> </template>
    <template #hint_table> </template>
    <template #list_table> </template>
    <template #modal v-if="list_chiffres">
      <div class="flex md:flex-col space-x-6 md:space-x-0 md:space-y-8">
        <div
          class="w-1/3 md:w-full h-68 md:h-48"
          v-for="(chiffre, idx) in list_chiffres.content"
          :key="idx"
        >
          <quill-editor
            ref="myQuillEditor"
            class="h-36 mb-20 md:mb-0 pt-2"
            v-model="list_chiffres.content[idx]"
          />
        </div>
      </div>
      <div class="w-full">
        <button
          class="btn-green px-6 py-2 sm:w-full md:mt-8"
          @click="updateChiffre()"
        >
          Modifier
        </button>
      </div>
    </template>
  </LayoutParametrage>
</template>

<script>
export default {
  data() {
    return {
      list_chiffres: [],
    }
  },

  created() {
    this.getQuelquesChiffres()
  },

  methods: {
    getQuelquesChiffres() {
      this.$http.get('admin/quelques-chiffres').then((response) => {
        this.list_chiffres = response.data.data[0]
      })
    },
    updateChiffre() {
      this.$http
        .put(
          'admin/quelques-chiffres/' + this.list_chiffres.id,
          this.list_chiffres,
        )
        .then((response) => {
          if (response.status == 200) this.$toaster.success(response.data)
        })
    },
  },
}
</script>

<style lang="scss">
.cor {
  h1 {
    font-size: 2rem !important;
  }
  h2 {
    font-size: 1.5em !important;
  }
}
</style>
