export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Statut',
          sortKey: 'status',
          classes: 'w-[6%]',
        },
        {
          title: 'Utilisateur',
          sortKey: 'typeable_id',
          classes: 'w-[10%]',
        },
        {
          title: 'commune',
          sortKey: 'commune',
          classes: 'w-[14%]',
        },
        {
          title: 'adresse',
          sortKey: 'adresse',
          classes: 'w-[26%]',
        },
        {
          title: 'Date de demande',
          sortKey: 'created_at',
          classes: 'w-[12%]',
        },
        {
          title: 'Mise a jour',
          sortKey: 'updated_at',
          classes: 'w-[12%]',
        },
      ],
      id_selected: null,
      statut_selected: null,
      raison_selected: null,
      raisons: [
        'La mairie ne l’a pas publié sur son site internet',
        'La commune dépend du RNU (Règlement National d’Urbanisme)',
        'Le site de la mairie est en panne',
        'Le PLU n’est pas valide',
      ],
      isFetching: false,
      showModal: false,
      last_page: 1,
      current_page: 1,
      demandesPLU: [],
      status: [
        {
          label: 'En cours',
          color: 'orange-100',
        },
        {
          label: 'Traité',
          color: 'green-100',
        },
        {
          label: 'Pas de plu',
          color: 'red',
        },
      ],
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-sm',
        'bg-white',
        'mb-2',
      ],
      sorting_by: '',
    }
  },
  created() {
    this.getDemandesPLUList()
  },
  methods: {
    setSortingBy(val) {
      this.sorting_by = val
    },
    orderBy(key, order) {
      this.sortKey = key
      this.sortOrder = order
      this.demandesPLU = []
      this.current_page = 1
      this.getDemandesPLUList(key, order)
    },
    isVisible() {
      if (this.last_page === this.current_page) {
        return
      }
      this.current_page++
      this.getDemandesPLUList(this.sortKey, this.sortOrder)
    },
    getDemandesPLUList(key = null, order = null) {
      this.isFetching = true
      this.$http
        .get('admin/demandes-plu', {
          params: {
            page: this.current_page,
            key: key,
            order: order,
            type: this.type,
          },
        })
        .then((response) => {
          this.demandesPLU.push(...response.data.data)
          this.last_page = response.data.meta.last_page
          this.current_page = response.data.meta.current_page
          this.isFetching = false
        })
    },
    submit() {
      this.$http
        .get(
          `admin/demandes-plu/${this.id_selected}/${this.statut_selected}/${this.type}`,
          {
            params: {
              description: this.raison_selected,
            },
          },
        )
        .then((response) => {
          this.demandesPLU.find((item) => item.id === this.id_selected).status =
            this.statut_selected
          this.$toaster.success(`le statut a bien été mis à jour`)
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.$toaster.warning(error.response.data.message)
          } else {
            this.$toaster.warning('Une erreur est survenue')
          }
          this.isFetching = false
        })
      this.showModal = false
      this.raison_selected = null
    },
    changeDemandePLUStatus(id, status, currentStatus) {
      this.id_selected = id
      this.statut_selected = status
      if (status === 2) {
        this.showModal = true
      } else if (status === currentStatus) {
        this.$toaster.warning(
          `le statut est déjà (${this.status[status].label})`,
        )
        this.isFetching = false
      } else {
        this.submit()
      }
    },
  },
}
