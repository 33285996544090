<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar>
      <progress-bar
        section="Etude commerciale"
        :progress_section="progress_section.etude_commerciale"
      />
    </template>
    <template #current_page>Etude Commerciale</template>
    <template #body>
      <pro-tabs :tabs="tabs">
        <pro-tab slot-scope="{ tabs, selectTab, currentTab }">
          <div class="flex flex-row sm:flex-col">
            <div
              v-for="tab in tabs"
              :key="tab.name"
              class="mr-24 text-sm font-bold capitalize text-gray-800 sm:my-2"
              :class="{
                'border-b-tab text-promy-green-300': tab.selected,
              }"
            >
              <a
                href="#"
                @click="selectTab(tab)"
                class="flex items-center space-x-1"
                ><span>{{ tab.slug }}</span>
                <span
                  v-if="tab.show_pastille"
                  class="bg-promy-green-50 w-2 h-2 rounded-full self-start animate-pulse"
                ></span
              ></a>
            </div>
          </div>
          <hr class="mt-2" />
          <div>
            <component :is="currentTab" :form="etude_commerciale" />
          </div>
        </pro-tab>
      </pro-tabs>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>

<script>
import { mapGetters } from 'vuex'
import Synthese from './page1'
import estimationprix from './EstimationPrix/index'
import Carte from './Map/index'
import progress from '@/mixins/dossierPromoteurs/progress'
import etudeCommerciale from '@/mixins/dossierPromoteurs/etudeCommerciale'
import helpers from '@/mixins/helpers'
export default {
  mixins: [progress, etudeCommerciale, helpers],

  components: {
    Synthese,
    Carte,
    estimationprix,
  },
  computed: {
    ...mapGetters({
      EtudeStore: 'dossierpromoteur/getEtudeCommerciale',
      progress_section: 'dossierpromoteur/getProgressSection',
      poi: 'poi/poi',
    }),
  },
  data() {
    return {
      tabs: [
        {
          name: 'Synthese',
          slug: 'Synthèse',
          selected: true,
        },
        {
          name: 'Carte',
          slug: 'Carte',
          selected: false,
        },
        {
          name: 'EstimationPrix',
          slug: 'Evolution des prix',
          selected: false,
          show_pastille: false,
        },
      ],
      etude_commerciale: {
        comparatif_des_prix: {
          reference_logements_neufs: [{ surface: null, prix: null }],
          image: '',
          parkings_aeriens: [
            {
              surface: null,
              prix: null,
              nombre_parking: null,
              lien_programme: null,
              show: true,
            },
          ],
          parkings_souterrains: [
            {
              surface: null,
              prix: null,
              nombre_parking: null,
              lien_programme: null,
              show: true,
            },
          ],
        },
        synthese: {
          prix_secteur_neuf: { min: null, max: null },
          prix_secteur_ancien: { min: null, max: null },
        },
        avis_de_promy: {
          point_forts: [{ value: '' }],
          point_faibles: [{ value: '' }],
        },
        description: '',
        permis_construire: [],
        list_dvf: [],
      },
    }
  },
  created() {
    this.etude_commerciale.comparatif_des_prix.parkings_aeriens.map((item) => ({
      ...item,
      show: true,
    }))
    this.etude_commerciale.comparatif_des_prix.parkings_souterrains.map(
      (item) => ({
        ...item,
        show: true,
      }),
    )

    if (
      Array.isArray(this.EtudeStore.evolution_prix) &&
      !this.EtudeStore.evolution_prix.length
    ) {
      this.tabs[2].show_pastille = true
      this.$store.commit('dossierpromoteur/setEtudeCommerciale', {
        evolution_prix: this.poi.prix,
      })
    }

    this.etude_commerciale = _.merge(
      {},
      this.etude_commerciale,
      this.EtudeStore,
    )
    if (
      !this.etude_commerciale.permis_construire.length &&
      !this.etude_commerciale.list_dvf.length
    ) {
      this.setDvf()
      this.setPermisConstruire()
    }
  },
  watch: {
    etude_commerciale: {
      deep: true,
      handler(value) {
        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'etude_commerciale',
          value: this.progressSection(
            value,
            'comparatif_des_prix',
            'synthese',
            'avis_de_promy',
            'description',
          ),
        })
      },
    },
  },
  methods: {
    saveInfos() {
      this.$store.commit(
        'dossierpromoteur/setEtudeCommerciale',
        this.etude_commerciale,
      )
    },
    setPermisConstruire() {
      let vm = this
      if (!this.etude_commerciale.permis_construire.length) {
        let list_pc = []
        let temp_list_pc = [
          ...this.permis_construire_locaux,
          ...this.permis_construire_logements,
        ]
        temp_list_pc.forEach((items, index) => {
          if (items.length) {
            list_pc.push({
              adresse: {
                libelle_voie: items[0].adr_libellevoie_t,
                code_postal: items[0].adr_cp_t,
                localisation: items[0].adr_localite_t,
                coordinates: [
                  items[0].location.coordinates[1],
                  items[0].location.coordinates[0],
                ],
              },
              sub_list: [],
              idx: index,
            })
            items.forEach((item, sub_idx) => {
              list_pc[index].sub_list.push(
                _.merge(
                  {},
                  {
                    total_logements: item.nombre_logements,
                    total_sdp: item.sdp,
                    surface_terrain: item.superficie_t,
                    type_evt: item.type_evt,
                    annee_depot: item.annee_depot,
                    i_garage: item.i_garage,
                    coordinates: [
                      item.location.coordinates[1],
                      item.location.coordinates[0],
                    ],
                    editText: false,
                    idx: sub_idx + '_' + index,
                  },
                  item,
                ),
              )
            })
          }
        })
        this.etude_commerciale.permis_construire = list_pc
      }
    },
    setDvf() {
      if (!this.etude_commerciale.list_dvf.length) {
        let list_dvf = []
        let temp_list_dvf = this.isExist(this.dvf) ? this.dvf.resultat : []
        temp_list_dvf.forEach((items, index) => {
          if (items.length) {
            list_dvf.push({
              adresse: {
                adresse_nom_voie: this.hasProperty(items[0], 'adresse')
                  ? items[0].adresse
                  : items[0].adresse_nom_voie,
                code_postal: this.hasProperty(items[0], 'adresse')
                  ? items[0].code_postal
                  : '',
                nom_commune: this.hasProperty(items[0], 'adresse')
                  ? items[0].nom_commune
                  : '',
                coordinates: this.hasProperty(items[0], 'location')
                  ? items[0].location.coordinates
                  : [items[0].longitude, items[0].latitude],
              },
              sub_list: [],
              idx: index,
            })
            items.forEach((item, idx) => {
              list_dvf[index].sub_list.push({
                type_local: item.type_local,
                surface_terrain: item.surface_terrain,
                surface_bati: item.surface_reelle_bati,
                date_mutation: item.date_mutation,
                valeur_fonciere: item.valeur_fonciere,
                coordinates: this.hasProperty(item, 'location')
                  ? item.location.coordinates
                  : [item.longitude, item.latitude],
                editText: false,
                idx: index + '_' + idx,
              })
            })
          }
        })
        this.etude_commerciale.list_dvf = list_dvf
      }
    },
    hasProperty(obj, key) {
      return Object.prototype.hasOwnProperty.call(obj, key)
    },
    getNbrLogements(item) {
      return this.getNestedObject(item, 'nb_lgt_tot_crees')
        ? item.nb_lgt_tot_crees
        : 0
    },
    getTotalSDP(item) {
      let sdp = 0
      for (const [key, value] of Object.entries(item)) {
        if (key.split('_')[0] === 'sdp') {
          sdp += !isNaN(parseFloat(value)) ? !parseFloat(value) : 0
        }
      }
      return sdp
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveInfos()
    next()
  },
}
</script>
<style scoped>
.border-b-tab {
  position: relative;
}

.border-b-tab::after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #00b7ab;
  width: 100%;
  bottom: -0.5rem;
  right: 0px;
}
</style>
