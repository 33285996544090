<template>
  <div>
    <pro-card v-if="courriers_type.length > 0">
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <pro-table-row
        v-for="(item, index) in courriers_type"
        :key="index"
        class="relative"
      >
        <pro-table-cell label="Nom" class="w-1/3 lg:w-full">
          {{ item.nom }}
        </pro-table-cell>
        <pro-table-cell label="Objet" class="w-1/3 lg:w-full">
          <span>{{ item.objet }}</span>
        </pro-table-cell>

        <pro-table-cell :actions_btn="true" class="w-1/3 lg:w-full">
          <div class="w-full flex justify-end items-center space-x-3">
            <template v-if="$can('delete,gestion_utilisateur')">
              <div class="btn-actions" @click="displayModal(item)">
                <i class="fas fa-trash"></i>
              </div>
            </template>
            <router-link
              v-if="$can('edite,gestion_utilisateur')"
              :to="{
                name: 'courrier-type-form',
                params: { id: item.id },
              }"
              class="btn-actions"
            >
              <i class="fas fa-edit"></i>
            </router-link>
          </div>
        </pro-table-cell>
      </pro-table-row>
    </pro-card>
    <div
      v-if="!courriers_type.length && !isFetching"
      class="w-full flex justify-center mt-24 text-lg font-main font-extrabold text-promy-gray-650"
    >
      Aucun courrier
    </div>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteCourrierType()"
    >
      <slot slot="message_body">
        Êtes-vous sûr de vouloir supprimer ce compte ?
      </slot>
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Nom',
          sortKey: '',
          classes: 'w-1/3',
        },
        {
          title: 'Objet',
          sortKey: '',
          classes: 'w-1/3',
        },
      ],
      courriers_type: [],
      isFetching: false,
      current_item: null,
      showModal: false,
    }
  },
  created() {
    this.getCourrierType()
  },
  methods: {
    getCourrierType() {
      this.isFetching = true
      this.$http.get('admin/type_courriers_wordings').then((res) => {
        this.isFetching = false
        this.courriers_type.push(...res.data.data)
      })
    },
    deleteCourrierType() {
      this.$http
        .delete(`admin/type_courriers_wordings/${this.current_item.id}`)
        .then(() => {
          this.courriers_type.splice(
            this.courriers_type.indexOf(this.current_item),
            1,
          )
          this.$toaster.success('vous avez supprimé courrier avec succès')
          this.showModal = false
        })
    },
    displayModal(item) {
      this.current_item = item
      this.showModal = true
    },
  },
}
</script>
