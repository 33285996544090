<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Révélation potentiel</page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>Liste des révélations potentiels </template>
    </table-title>
    <div class="flex justify-end">
      <div class="flex justify-end flex-1 w-full">
        <pro-input v-model="search" placeholder="Search" />
      </div>
    </div>

    <hr class="my-6" />
    <div>
      <div>
        <div
          class="grid items-center grid-cols-8 gap-4 px-6 mb-8 sm:flex sm:flex-col"
        >
          <div class="flex justify-start">
            <div
              class="max-w-full py-2 px-4 flex items-center text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670"
              @click="sortBy('nom', arrowNom)"
            >
              Nom
              <i
                class="fas ml-2"
                :class="`fa-chevron-${arrowNom ? 'up' : 'down'}`"
              ></i>
            </div>
          </div>

          <div class="flex justify-center">
            <div
              class="max-w-full py-2 px-4 text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670"
              @click="sortBy('prenom', arrowPrenom)"
            >
              Prénom
              <i
                class="fas ml-2"
                :class="`fa-chevron-${arrowPrenom ? 'up' : 'down'}`"
              ></i>
            </div>
          </div>
          <div class="flex justify-center">
            <div
              class="max-w-full py-2 px-4 text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670"
              @click="sortBy('email', arrowEmail)"
            >
              E-mail
              <i
                class="fas ml-2"
                :class="`fa-chevron-${arrowEmail ? 'up' : 'down'}`"
              ></i>
            </div>
          </div>
          <div class="flex justify-center">
            <div
              class="max-w-full py-2 px-4 text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670"
              @click="sortBy('tel', arrowTel)"
            >
              Tél
              <i
                class="fas ml-2"
                :class="`fa-chevron-${arrowTel ? 'up' : 'down'}`"
              ></i>
            </div>
          </div>
          <div class="flex justify-center">
            <div
              class="max-w-full py-2 px-4 text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670"
            >
              Adresse
            </div>
          </div>
          <div class="flex justify-center">
            <div
              class="max-w-full py-2 px-4 text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670"
              @click="sortBy('updated_at', arrowUpdatedAt)"
            >
              Créé le
              <i
                class="fas ml-2"
                :class="`fa-chevron-${arrowUpdatedAt ? 'up' : 'down'}`"
              ></i>
            </div>
          </div>
          <div class="flex justify-center">
            <div
              class="max-w-full py-2 px-4 text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670"
              @click="sortBy('typeable_type', arrowSource)"
            >
              Source
              <i
                class="fas ml-2"
                :class="`fa-chevron-${arrowSource ? 'up' : 'down'}`"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="h-60-screen overflow-y-auto">
        <template v-if="revelations.length">
          <div
            class="px-6 my-4 transition-shadow duration-200 bg-white border-none shadow-xs rounded-box py-7 hover:shadow-lg procard"
            v-for="item in revelations"
            :key="item.name"
          >
            <div
              class="grid items-center grid-cols-8 gap-4 sm:flex sm:flex-col"
            >
              <span class="text-base font-semibold text-gray-700 capitalize"
                >{{ item.nom }}
              </span>
              <span
                class="text-base font-semibold text-center text-gray-700 capitalize"
                >{{ item.prenom }}
              </span>
              <span
                class="text-base font-semibold text-center text-gray-700 truncate"
                :title="item.email"
                >{{ item.email }}
              </span>
              <span class="text-base font-semibold text-center text-gray-700"
                >{{ item.tel }}
              </span>
              <span class="text-base font-semibold text-center text-gray-700"
                >{{ item.adresse }}
              </span>
              <span class="text-base font-semibold text-center text-gray-700"
                >{{ item.created_at | filterDate }}
              </span>
              <span class="text-base font-semibold text-center text-gray-700"
                >{{ item.source }}
              </span>
              <div class="flex justify-center gap-x-1">
                <div
                  class="flex items-center justify-center w-10 h-10 mr-2 text-white rounded-full cursor-pointer bg-promy-green-300"
                  @click="goToDetails(item.id)"
                >
                  <i class="fas fa-eye"></i>
                </div>
                <div
                  class="flex items-center justify-center w-10 h-10 mr-2 text-white rounded-full cursor-pointer bg-promy-green-300"
                  @click="displayModal(item)"
                >
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex justify-center h-10"
            v-if="revelations.length"
            v-observe-visibility="{
              callback: handleScrolledToBottomOfList,
            }"
          >
            <i v-if="isFetching" class="fas fa-spinner fa-pulse"></i>
          </div>
        </template>
        <template v-else>
          <p
            class="flex justify-center w-full mt-24 text-lg font-extrabold text-promy-gray-650 font-main"
          >
            Il n'y a pas de dossier pour "{{ search }}"
          </p>
        </template>
      </div>
    </div>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteRevelation()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer cette révélation ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      page: 1,
      lastPage: 1,
      current_page: 1,
      revelations: [],
      current_item: null,
      showModal: false,
      arrowNom: false,
      arrowPrenom: false,
      arrowEmail: false,
      arrowTel: false,
      arrowUpdatedAt: true,
      arrowSource: false,
      isFetching: false,
      current_attribut: null,
      current_attributType: 'desc',
    }
  },
  created() {
    this.getRevelations()
  },
  computed: {
    urlWithPage() {
      return `admin/reveler-potentiel?page=${this.current_page}`
    },
  },
  methods: {
    searchAfterDebounce: _.debounce(function () {
      this.revelations = []
      this.current_page = 1
      this.getRevelations()
    }, 800),
    getRevelations() {
      this.isFetching = true
      this.$http
        .get(this.urlWithPage, {
          params: {
            attribut: this.current_attribut,
            type: this.current_attributType,
            ...(this.search ? { search: this.search } : {}),
          },
        })
        .then((res) => {
          this.revelations = [...this.revelations, ...res.data.data]
          this.last_page = res.data.meta.last_page
          this.current_page = res.data.meta.current_page
          this.isFetching = false
        })
    },
    handleScrolledToBottomOfList(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.last_page === this.current_page) {
        return
      }

      ++this.current_page

      this.getRevelations()
    },

    sortBy(attr, type) {
      this.current_attribut = attr
      this.current_attributType = !type ? 'asc' : 'desc'
      if (attr === 'nom') {
        this.arrowNom = !type
      }
      if (attr === 'prenom') {
        this.arrowPrenom = !type
      }
      if (attr === 'email') {
        this.arrowEmail = !type
      }
      if (attr === 'tel') {
        this.arrowTel = !type
      }
      if (attr === 'updated_at') {
        this.arrowUpdatedAt = !type
      }
      if (attr === 'typeable_type') {
        this.arrowSource = !type
      }
      this.$http
        .get('admin/reveler-potentiel', {
          params: {
            attribut: attr,
            type: !type ? 'asc' : 'desc',
          },
        })
        .then((res) => {
          this.revelations = res.data.data
          this.last_page = res.data.meta.last_page
          this.current_page = res.data.meta.current_page
        })
    },
    deleteRevelation(id) {
      this.$http
        .delete('/admin/reveler-potentiel/' + this.current_item.id)
        .then(() => {
          this.revelations.splice(
            this.revelations.indexOf(this.current_item),
            1,
          )
          this.showModal = false
          this.current_item = null
        })
    },
    goToDetails(id) {
      this.$router.push('/revelation-potentiel/details/' + id)
    },
    displayModal(item) {
      this.current_item = item
      this.showModal = true
    },
  },
  watch: {
    search() {
      this.searchAfterDebounce()
    },
  },
}
</script>

<style scoped></style>
