<template>
  <div class="mt-10 m-auto w-5/6">
    <pro-tabs :tabs="list_estimated_price" v-if="is_estimated_price_exist">
      <pro-tab slot-scope="{ tabs, selectTab, currentTab }">
        <div class="flex flex-row sm:flex-col">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            class="mr-24 text-sm font-bold capitalize text-gray-800 sm:my-2"
            :class="{
              'border-b-tab text-promy-green-300': tab.selected,
            }"
          >
            <button class="font-semibold" @click="selectTab(tab)">
              {{ tab.label }}
            </button>
          </div>
        </div>
        <hr class="mt-2" />
        <div
          v-for="(item, indice) in list_estimated_price"
          :key="`item-${indice}`"
          v-show="item.name === currentTab"
        >
          <div class="text-xl font-main font-extrabold text-promy-purple-400">
            {{ item.title }}
          </div>
          <div>
            <div v-for="(cat, index) in item.categories" :key="index">
              <div class="pb-12">
                <div
                  class="flex items-center justify-between pt-4 mb-4 sm:space-x-2"
                >
                  <span
                    class="text-promy-purple-400 font-bold text-base sm:text-sm"
                  >
                    {{ cat.title }}</span
                  >
                  <span
                    class="rounded-md px-2 py-4 font-bold sm:text-sm"
                    :style="`background-color: ${cat.bg_color_confidence};color:${cat.text_color_confidence}`"
                  >
                    Indice de fiabilité : {{ cat.statut_confidence }}
                  </span>
                </div>
                <div class="relative mb-4" style="width: 99%">
                  <canvas
                    :id="cat.id_chart"
                    :height="getHeightCanvas()"
                  ></canvas>
                </div>
                <div class="border bg-promy-gray-240 rounded-md">
                  <div
                    class="flex justify-between items-center p-3 sm:flex-col sm:items-start sm:justify-start sm:space-y-3"
                  >
                    <div
                      class="flex items-center lg:flex-col lg:space-y-3 lg:items-start lg:space-x-0"
                    >
                      <div
                        class="flex flex-col px-4 py-2 rounded-md bg-promy-purple-400 text-white"
                      >
                        <span>Prix minimum </span>
                        <span>{{ cat.priceMinValue }}</span>
                      </div>
                      <div
                        class="flex flex-col px-4 py-2 rounded-md ml-2 bg-promy-purple-400 text-white"
                      >
                        <span>Prix Maximum </span>
                        <span> {{ cat.priceMaxValue }}</span>
                      </div>
                      <div
                        class="flex flex-col px-4 py-2 rounded-md ml-2 bg-promy-purple-400 text-white"
                      >
                        <span>Évolution en pourcentage </span>
                        <span> {{ getPercentageChange(cat.values) }}</span>
                      </div>
                    </div>
                    <div class="flex flex-col justify-center sm:ml-auto">
                      <div class="flex flex-col">
                        <span class="text-right text-sm">Prix Moyen </span>
                        <span class="font-bold text-xl">
                          {{ cat.priceMeanValue }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pro-tab>
    </pro-tabs>
    <div v-else class="flex justify-center min-h-[30vh]">
      <h3 class="font-semibold">
        Informations d'évolution des prix non disponibles.
      </h3>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      current_tab: 'prixancien',
      is_estimated_price_exist: true,
      list_estimated_price: [
        {
          selected: true,
          label: 'Prix ancien',
          name: 'prixancien',
          title: "Analyse du marché dans l'immobilier ancien",
          categories: [
            {
              id: 16210,
              title: 'Évolution globale des prix',
              id_chart: 'prix-ancien-global',
            },
            {
              id: 16211,
              title: 'Évolution du prix des appartements',
              id_chart: 'prix-ancien-appartement',
            },
            {
              id: 16212,
              title: 'Évolution du prix des maisons',
              id_chart: 'prix-ancien-maison',
            },
          ],
        },
        {
          selected: false,
          label: 'Prix neuf',
          name: 'prixaeuf',
          title: "Analyse du marché dans l'immobilier neuf",
          categories: [
            {
              id: 16220,
              title: 'Évolution globale des prix',
              id_chart: 'prix-neuf-global',
            },
            {
              id: 16221,
              title: 'Évolution du prix des appartements',
              id_chart: 'prix-neuf-appartement',
            },
            {
              id: 16222,
              title: 'Évolution du prix des maisons',
              id_chart: 'prix-neuf-maison',
            },
          ],
        },
        {
          selected: false,
          label: 'Prix location',
          name: 'prixlocation',
          title: 'Analyse du marché de la location',
          categories: [
            {
              id: 16230,
              title: 'Évolution globale des loyers',
              id_chart: 'prix-loyers-global',
            },
            {
              id: 16231,
              title: 'Évolution des loyers en appartement',
              id_chart: 'prix-loyers-appartement',
            },
            {
              id: 16232,
              title: 'Évolution des loyers en maison',
              id_chart: 'prix-loyers-maison',
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      etudeStore: 'dossierpromoteur/getEtudeCommerciale',
    }),
  },
  created() {
    this.is_estimated_price_exist =
      this.etudeStore.evolution_prix && this.etudeStore.evolution_prix.length

    if (this.is_estimated_price_exist) {
      this.list_estimated_price.forEach((item) => {
        item.categories.forEach((cat) => {
          cat.labels = []
          cat.values = []
          let temp_cat = this.etudeStore.evolution_prix.find(
            (elem) => elem.id === cat.id,
          )
          if (temp_cat) {
            cat.priceMaxValue = this.formatEUR(
              temp_cat.characteristics.priceMaxValue,
              '/㎡',
            )
            cat.priceMinValue = this.formatEUR(
              temp_cat.characteristics.priceMinValue,
              '/㎡',
            )
            cat.priceMeanValue = this.formatEUR(
              temp_cat.characteristics.priceMeanValue,
              '/㎡',
            )
            if (parseFloat(temp_cat.characteristics.confidence) >= 0.5) {
              cat.statut_confidence = 'Bon'
              cat.text_color_confidence = '#37C633'
              cat.bg_color_confidence = '#DFFFDE'
            } else {
              cat.statut_confidence = 'Faible'
              cat.text_color_confidence = '#C63333'
              cat.bg_color_confidence = '#FFDEDE'
            }
            Object.keys(temp_cat.characteristics.priceTrends)
              .sort(function (a, b) {
                return (
                  moment(a, 'YYYY-MM-DD').toDate() -
                  moment(b, 'YYYY-MM-DD').toDate()
                )
              })
              .forEach(function (key) {
                cat.labels.push(key)
                cat.values.push(temp_cat.characteristics.priceTrends[key].value)
              })
          }
        })
      })
    }
  },
  mounted() {
    if (this.is_estimated_price_exist) {
      this.addLinesChart()
    }
  },
  methods: {
    getPercentageChange(values) {
      try {
        return (
          Math.round(
            ((values[values.length - 1] - values[0]) / values[0]) * 100,
          ) + '%'
        )
      } catch (err) {
        return 'N.C'
      }
    },
    getHeightCanvas() {
      return window.innerWidth >= 768 ? 100 : 150
    },
    formatEUR(value, prefix = '') {
      const euroformatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })

      return euroformatter.format(parseFloat(value)) + prefix
    },
    addLinesChart() {
      this.list_estimated_price.forEach((item) => {
        item.categories.forEach((cat) => {
          let canvas = document.getElementById(cat.id_chart).getContext('2d')
          new Chart(canvas, {
            type: 'line',
            data: {
              labels: cat.labels,
              datasets: [
                {
                  backgroundColor: '#46317E',
                  borderColor: '#46317E',
                  fill: false,
                  data: cat.values,
                },
              ],
            },
            options: {
              animation: {
                duration: 0,
              },
              devicePixelRatio: 4,
              responsive: true,
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    ticks: {
                      fontSize: 12,
                      maxTicksLimit: window.innerWidth >= 768 ? 10 : 3,
                      maxRotation: 0,
                      minRotation: 0,
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                    },
                  },
                ],
              },
            },
          })
        })
      })
    },
  },
}
</script>

<style scoped>
.border-b-tab {
  position: relative;
}
.border-b-tab::after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #00b7ab;
  width: 100%;
  bottom: -0.5rem;
  right: 0px;
}
</style>
