<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="50"
    viewBox="0 0 38 50"
  >
    <path
      id="delete"
      class="cls-1"
      d="M1037,555h-10v-2a4,4,0,0,0-4-4h-10a4,4,0,0,0-4,4v2H999v2h2.06l2,38a4,4,0,0,0,4,4h22a4,4,0,0,0,4-4l1.97-38H1037v-2Zm-26-2a2,2,0,0,1,2-2h10a2,2,0,0,1,2,2v2h-14v-2Zm20.07,41.9-0.01.05V595a2,2,0,0,1-2,2h-22a2,2,0,0,1-2-2V594.9l-2-37.895h29.97ZM1019,561h-2v32h2V561Zm-5.96,31.936L1011,561l-2,.127,2.05,31.938Zm13.97-31.873-1.99-.125-2.02,32,2,0.125Z"
      transform="translate(-999 -549)"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #fff;
  fill-rule: evenodd;
}
</style>
