<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {
      parentUrl: '/guichet-unique',
      links: [
        {
          name: 'Dossier synthèse du Guichet Unique',
          path: 'dossier-synthese-du-guichet-unique',
          componentName: 'dossier-synthese-du-guichet-unique',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },
        {
          name: 'Demandes PLU',
          path: 'demandes-plu',
          componentName: 'demandes-plu',
        },
      ],
    }
  },
  created() {
    this.$store.commit('subSidebar/LINKS', this.links)
    this.$store.commit('subSidebar/PARENT_URL', this.parentUrl)
  },
  mounted() {
    this.$store.commit('sidebar/RESIZE_WINDOW', true)
  },
  destroyed() {
    this.$store.commit('sidebar/RESIZE_WINDOW', false)
  },
}
</script>

<style></style>
