<template>
  <LayoutParametrage>
    <template #current_page>
      <span v-if="$route.name != 'update-CGU'">Ajouter des CGU</span>
      <span v-else>Modifier des CGU</span>
    </template>
    <template #add_button>
      <router-link
        class="text-promy-green-300 cursor-pointer"
        to="/parametrage/CGU/liste"
      >
        &larr; Retour
      </router-link>
    </template>
    <template #modal>
      <ValidationObserver v-slot="{ invalid }" ref="validationObserver">
        <div class="w-1/3 md:w-2/3 sm:w-full">
          <pro-input
            name="version"
            requirements="required"
            v-model="form.version"
            label="Version"
            disabled
          />
        </div>
        <div class="relative w-2/3 md:w-full mt-4">
          <label class="capitalize text-xs text-gray-600 font-bold font-arial">
            Destinés à la platforme:
          </label>
          <pro-input
            v-if="
              current_cgu &&
              $route.name === 'update-CGU' &&
              current_cgu.status != 'Publier'
            "
            requirements="required"
            v-model="form.platform"
            disabled
          />

          <pro-select
            v-else
            class="w-fit"
            requirements="required"
            v-model="form.platform"
            :selection="platforms"
            :args="['name']"
          ></pro-select>
        </div>
        <div class="relative w-2/3 md:w-full h-64 mt-4">
          <label class="capitalize text-xs text-gray-600 font-bold font-arial">
            Contenu
          </label>
          <quill-editor
            class="mt-2 h-full"
            ref="myQuillEditor"
            v-model="form.context"
          />
        </div>

        <div class="w-full my-8 mt-24">
          <button
            v-if="$route.name === 'update-CGU'"
            class="btn-green px-6 py-2 sm:w-full mr-4"
            @click="update('Brouillon')"
          >
            Modifier
          </button>
          <button
            v-if="
              current_cgu &&
              $route.name === 'update-CGU' &&
              current_cgu.status != 'Publier'
            "
            class="font-extrabold text-white uppercase border-2 border-solid rounded-full mr-4 font-main hover:bg-promy-red hover:border-promy-red border-promy-red-100 bg-promy-red-100 px-6 py-2 sm:w-full sm:mt-4"
            @click="show_modal = 'remove'"
          >
            Supprimer
          </button>
          <button
            v-if="$route.name === 'update-CGU'"
            class="btn-green px-6 py-2 sm:w-full md:mt-8"
            @click="update('Publier')"
          >
            Publier
          </button>
          <button
            v-if="$route.name === 'add-CGU'"
            class="btn-green px-6 py-2 sm:w-full md:mt-8 mr-4"
            @click="add('Brouillon')"
          >
            Sauvegarder
          </button>
          <button
            v-if="$route.name === 'add-CGU'"
            class="btn-green px-6 py-2 sm:w-full md:mt-8"
            @click="add('Publier')"
          >
            Publier
          </button>
        </div>
      </ValidationObserver>
      <Modal v-if="show_modal">
        <slot slot="body">
          <p class="text-promy-gray-700 text-sm font-arial py-4">
            {{ content_modal[show_modal].question }}
          </p>
        </slot>
        <slot slot="footer">
          <button
            class="btn-green px-6 py-2 sm:w-full md:mt-8 mr-4"
            @click="closeAndLeave()"
          >
            {{ show_modal === 'remove' ? 'Non' : 'Quitter' }}
          </button>
          <button
            v-if="show_modal === 'remove'"
            class="font-extrabold text-white uppercase border-2 border-solid rounded-full font-main hover:bg-promy-red hover:border-promy-red border-promy-red-100 bg-promy-red-100 px-6 py-2 sm:w-full md:mt-8"
            @click="remove()"
          >
            Oui
          </button>
          <button
            v-else
            class="btn-green px-6 py-2 sm:w-full md:mt-8 mr-4"
            @click="
              $route.name === 'update-CGU'
                ? update('Brouillon')
                : add('Brouillon')
            "
          >
            Sauvegarder
          </button>
        </slot>
      </Modal>
    </template>
  </LayoutParametrage>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      show_modal: null,
      is_updated: false,

      content_modal: {
        remove: {
          question: 'Êtes-vous sûr de vouloir supprimer les CGU ?',
        },
        leave: {
          question:
            'Si vous quittez la page, les CGU ne seront pas sauvegardées. Sauvegarder / Quitter ?',
        },
      },
      form: {
        version: '',
        context: '',
        status: '',
        platform: '',
      },
      platforms: [
        { name: 'promoteurs', slug: 'promoteurs' },
        { name: 'gugp', slug: 'gugp' },
        { name: 'gugp mobile', slug: 'gugp_mobile' },
      ],
    }
  },
  created() {
    if (this.$route.name === 'add-CGU') {
      this.form.platform = 'promoteurs'
      this.$store.dispatch('cgu/getAllCGU')
    }
    if (this.$route.name === 'update-CGU' && !this.current_cgu) {
      this.$store.dispatch('cgu/getCGUById', this.$route.params.id)
    }
  },
  watch: {
    current_cgu: {
      immediate: true,
      handler(value) {
        if (this.$route.name === 'update-CGU' && value) {
          this.form.version = value.version
          this.form.context = value.context
          this.form.platform = value.platform
        }
      },
    },
    list_versions: {
      immediate: true,
      handler(value) {
        if (this.$route.name === 'add-CGU' && value.length) {
          let filteredList = value.filter(
            (item) => item.platform === this.form.platform,
          )
          this.form.version = `v${filteredList.length + 1}`
        }
      },
    },
    form: {
      deep: true,
      handler(value) {
        this.is_updated = true

        if (value.platform) {
          if (this.$route.name === 'add-CGU' && this.list_versions.length) {
            let filteredList = this.list_versions.filter(
              (item) => item.platform === value.platform,
            )

            this.form.version = `v${filteredList.length + 1}`
          }
        }
      },
    },
  },
  methods: {
    async add(status) {
      if (await this.checkFields()) {
        this.form.status = status
        try {
          this.form.platform = this.platforms.find(
            (platform) => platform.name === this.form.platform.name,
          ).slug
          await this.$store.dispatch('cgu/addCGU', this.form)
          this.$toaster.success('Les CGU ont bien été sauvegardées!')
          this.show_modal = null
          this.is_updated = false
          this.$router.push('/parametrage/CGU/liste')
        } catch {
          this.$toaster.warning(
            "Un probleme est survenu lors l'ajoute d'une CGU.",
          )
        }
      }
    },
    async update(status) {
      if (await this.checkFields()) {
        this.form.status = status
        try {
          await this.$store.dispatch('cgu/updateCGU', {
            form: this.form,
            id: this.$route.params.id,
          })
          this.$toaster.success('Les CGU ont bien été modifié')
          this.show_modal = null
          this.is_updated = false
          this.$router.push('/parametrage/CGU/liste')
        } catch {
          this.$toaster.warning(
            "Un probleme est survenu lors l'ajoute d'une CGU.",
          )
        }
      }
    },
    async checkFields() {
      let res = await this.$refs.validationObserver.validate()
      if (!res) {
        this.$toaster.warning('Le Contenu est obligatoire.')
        return false
      }
      return true
    },
    async remove() {
      try {
        await this.$store.dispatch('cgu/removeCGU', this.current_cgu.id)
        this.$toaster.success('LES CGU a bien été supprimées.')
        this.show_modal = null
        this.is_updated = false
        this.$router.push('/parametrage/CGU/liste')
        this.$store.commit('cgu/SET_CURRENT_CGU', null)
      } catch {
        this.$toaster.warning(
          "Un probleme est survenu lors la supprission d'une CGU.",
        )
      }
    },
    closeAndLeave() {
      this.show_modal = null
      this.is_updated = false
      this.$router.push('/parametrage/CGU/liste')
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.is_updated) this.show_modal = 'leave'
    else next()
  },
  computed: {
    ...mapGetters({
      current_cgu: 'cgu/getCurrentCGU',
      list_versions: 'cgu/getCGU',
    }),
  },
}
</script>

<style></style>
