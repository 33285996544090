function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'

export default [
  {
    path: 'outil-promoteur',
    name: 'outil-promoteur',
    component: page('dashboard/OutilPromoteur'),
    redirect: 'outil-promoteur/pige-fonciere',
    children: [
      {
        path: 'map',
        name: 'map-outil-promoteur',
        component: page('dashboard/OutilPromoteur/Map/map.vue'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'pige-fonciere',
        name: 'pige',
        component: page('dashboard/OutilPromoteur/Pige'),
        redirect: 'pige-fonciere/list',
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: 'list',
            name: 'pige_list',
            component: page('dashboard/OutilPromoteur/Pige/List.vue'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: ':id',
            name: 'pige_demande_item',
            component: page('dashboard/OutilPromoteur/Pige/document.vue'),
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
    ],
  },
]
