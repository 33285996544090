<template>
  <div>
    <label class="text-xs text-gray-600 font-bold font-arial">{{
      label
    }}</label>
    <validation-provider rules="required" v-slot="{ errors }">
      <div class="flex items-center mt-6" v-for="sel in selection" :key="sel">
        <input :type="type" />
      </div>
      <span class="mt-1 text-red-600 text-sm" v-if="errors[0]">
        {{ errors[0] }}
      </span>
    </validation-provider>
  </div>
</template>
<script>
export default {
  props: {
    selection: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: String,
    },
    value: {
      required: true,
    },
    type: {
      type: String,
      default: 'radio',
    },
  },
  methods: {
    handleInput() {},
  },
}
</script>
