<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des utilisateurs </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>{{ action }} un utilisateur</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('list-des-promoteurs')"
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <div class="flex lg:flex-wrap">
      <div class="w-1/3 pr-4 md:w-full lg:w-2/5 md:pb-4 md:pr-0">
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <div>
            <pro-input
              name="nom"
              requirements="required"
              v-model="form.nom"
              label="Nom"
            />
            <pro-input
              name="prenom"
              requirements="required"
              v-model="form.prenom"
              label="Prénom"
            />
            <InputPhoneNumber
              name="Tél Mobile"
              :rules="'required'"
              v-model="form.telephone_mobile"
              placeholder="+33"
              label="Tél Mobile"
            />
            <InputPhoneNumber
              name="Tél Fax"
              v-model="form.telephone_fix"
              placeholder="+33"
              label="Tél Fax"
            />
          </div>
          <div>
            <pro-input
              requirements="required|email"
              v-model="form.email"
              label="Email"
              name="email"
              :disabled="!isCreate"
            />
            <pro-input
              :requirements="passwordRequirement"
              name="mot de passe"
              v-model="form.password"
              type="password"
              label="Mot de passe"
            />
            <pro-input
              :requirements="passwordConfirmRequirement"
              name="confirmer mot de passe"
              v-model="form.password_confirmation"
              type="password"
              label="Confirmation de mot de passe"
            />
            <pro-input
              requirements="required"
              v-model="form.qualite_societe"
              name="Qualité social"
              label="Qualité sociale"
            />
          </div>
          <div>
            <div class="text-xs font-bold text-gray-600 capitalize font-arial">
              Type
            </div>
            <validation-provider rules="required" v-slot="{ errors }">
              <div class="flex items-center my-3">
                <div class="mr-8">
                  <input
                    name="is admin"
                    requirements="required"
                    type="checkbox"
                    id="isadmin"
                    v-model="form.is_team_admin"
                  />
                  <label for="isadmin"></label>
                </div>
                <span class="text-sm text-gray-600"> Admin de la team </span>
              </div>
              <span class="mt-1 text-sm text-red-600" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </validation-provider>
            <validation-provider rules="required" v-slot="{ errors }">
              <div class="flex items-center my-3">
                <div class="mr-8">
                  <input
                    name="is admin"
                    requirements="required"
                    type="checkbox"
                    id="email_verified_at"
                    v-model="form.email_verified_at"
                  />
                  <label for="email_verified_at"></label>
                </div>
                <span class="text-sm text-gray-600">
                  verification de l'email
                </span>
              </div>
              <span class="mt-1 text-sm text-red-600" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </validation-provider>
            <validation-provider rules="required" v-slot="{ errors }">
              <div class="flex items-center my-3">
                <div class="mr-8">
                  <input
                    name="is first"
                    requirements="required"
                    type="checkbox"
                    id="is_first"
                    v-model="form.is_first"
                  />
                  <label for="is_first"></label>
                </div>
                <span class="text-sm text-gray-600">
                  1er promoteur de la team
                </span>
              </div>
              <span class="mt-1 text-sm text-red-600" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </validation-provider>

            <div class="text-xs font-bold text-gray-600 capitalize font-arial">
              Statut
            </div>
            <div>
              <validation-provider rules="required" v-slot="{ errors }">
                <div class="flex items-center my-3">
                  <div class="mr-8">
                    <input
                      name="is validé"
                      type="checkbox"
                      id="is_valide"
                      v-model="form.is_valide"
                    />
                    <label for="is_valide"></label>
                  </div>
                  <span class="text-sm text-gray-600 capitalize"> Validé </span>
                </div>
                <span class="mt-1 text-sm text-red-600" v-if="errors[0]">
                  {{ errors[0] }}
                </span>
              </validation-provider>
            </div>
          </div>
          <div class="my-12">
            <BaseButton @handleClick="handleSubmit(save)" :loading="loading" />
          </div>
        </ValidationObserver>
      </div>
      <div class="w-2/3 pl-6 md:w-full lg:w-3/5 md:pl-0">
        <div class="flex items-center justify-between sm:flex-col">
          <span
            class="text-xl font-extrabold text-promy-purple-400 font-main sm:self-start sm:mb-2"
            >Droits d’utilisateur
          </span>
          <div class="flex items-center space-x-2">
            <span class="text-xl font-extrabold text-promy-purple-400 font-main"
              >Profils prédéfinis
            </span>
            <validation-provider
              class="flex flex-col"
              rules="required"
              v-slot="{ errors }"
            >
              <select
                :class="[errors[0] ? 'border-promy-red-100' : '']"
                class="px-4 py-3 text-sm capitalize border rounded-full"
                @change="roleSelected"
                v-model="form.role"
                name="roles"
                id=""
                v-tooltip="{
                  content: 'Edition profil',
                  classes: classes,
                }"
              >
                <option :value="role.slug" v-for="role in roles" :key="role.id">
                  {{ role.name }}
                </option>
              </select>
              <span v-show="errors[0]" class="msg-error">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <hr class="mt-4 mb-2 border-gray-400" />
        <validation-provider
          class="flex flex-wrap"
          rules="required"
          v-slot="{ errors }"
        >
          <div
            class="w-1/3 mt-4 sm:w-full md:w-1/3 lg:w-1/2"
            v-for="(permission, index) in permissions"
            :key="index"
          >
            <span class="text-xs font-bold text-promy-gray-500">{{
              getTitlePermission(index)
            }}</span>
            <ul
              v-for="(permissionName, index) in permission[0].permissions"
              :key="index"
            >
              <li class="flex items-center mt-3">
                <CheckboxPermission
                  @input="checkboxChanged"
                  :id="permissionName.name"
                  :val="permissionName.name"
                  v-model="form.permissions"
                />
                <label
                  for="GU_utilisation_illimitee"
                  class="text-sm capitalize cursor-pointer pl-7 text-promy-gray-650"
                  >{{ permissionName.description }}</label
                >
              </li>
            </ul>
          </div>
          <span v-show="errors[0]" class="msg-error">
            Choisir une ou plusieurs permissions
          </span>
        </validation-provider>
      </div>
    </div>
    <div v-if="activity_logs.length > 0">
      <pro-card>
        <pro-table-row :isHeader="true">
          <pro-table-header-cell
            v-for="(sort, index) in sort_list"
            :key="index"
            :class="sort.classes"
            :sortingBy="sorting_by"
            @setSortingBy="setSortingBy"
            @orderBy="orderBy"
            :hasSort="true"
            :title="sort.title"
            :sortKey="sort.sortKey"
          />
        </pro-table-row>
        <pro-table-row
          v-for="(item, index) in activity_logs"
          :key="index"
          class="relative"
        >
          <pro-table-cell label="Utilisateur" class="w-[16%] lg:w-full">
            {{ item.type_user ? item.type_user : 'anonyme' }}
          </pro-table-cell>
          <pro-table-cell label="Type D'utilisateur" class="w-[12%] lg:w-full">
            {{ item.type_user }}
          </pro-table-cell>
          <pro-table-cell label="Section" class="w-[12%] lg:w-full">
            {{ item.section }}
          </pro-table-cell>
          <pro-table-cell label="Description" class="w-[18%] lg:w-full">
            {{ item.description }}
          </pro-table-cell>
          <pro-table-cell label="IP" class="w-[12%] lg:w-full">
            {{ item.ip }}
          </pro-table-cell>
          <pro-table-cell label="Route" class="w-[18%] lg:w-full">
            {{ item.route }}
          </pro-table-cell>
          <pro-table-cell label="Effectué" class="w-[12%] lg:w-full">
            {{ item.created_at }}
          </pro-table-cell>
        </pro-table-row>
      </pro-card>
      <isVisible :isFetching="isFetching" @isVisible="isVisible" />
    </div>
  </div>
</template>

<script>
import { toMulipartedForm } from '../../../../utilities/form'
import { sortBy, isEqual } from 'lodash'

export default {
  data() {
    return {
      action: 'Ajouter',
      storeURL: 'admin/promoteurs',
      form: {
        nom: '',
        prenom: '',
        email: '',
        qualite_societe: '',
        telephone_mobile: '',
        telephone_fix: '',
        password: '',
        password_confirmation: '',
        email_verified_at: '',
        raison_social: '',
        is_team_admin: false,
        is_valide: false,
        is_first: false,
        type: 'promoteur',
        role: '',
        permissions: [],
      },
      permissions: [],
      roles: [],
      rolePermissions: [],
      error: [],
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-xs',
        'bg-white',
        'mb-2',
      ],
      loading: false,
      sort_list: [
        {
          title: 'Utilisateur',
          sortKey: 'user_full_name',
          classes: 'w-[16%]',
        },
        {
          title: "Type D'utilisateur",
          sortKey: 'causer_type',
          classes: 'w-[12%]',
        },
        {
          title: 'Section',
          sortKey: 'log_name',
          classes: 'w-[12%]',
        },
        {
          title: 'Description',
          sortKey: 'description',
          classes: 'w-[18%]',
        },
        {
          title: 'IP',
          sortKey: 'ipAddress',
          classes: 'w-[12%]',
        },
        {
          title: 'Route',
          sortKey: 'route',
          classes: 'w-[18%]',
        },
        {
          title: 'Effectué',
          sortKey: 'created_at',
          classes: 'w-[12%]',
        },
      ],
      sorting_by: '',
      activity_logs: [],
      isFetching: false,
      last_page: 1,
      current_page: 1,
      current_item: null,
    }
  },
  computed: {
    passwordRequirement() {
      return this.isCreate
        ? 'min:8|required|password:@confirmer mot de passe'
        : ''
    },
    passwordConfirmRequirement() {
      return this.isCreate ? 'min:8|required' : ''
    },
    isCreate() {
      return this.$route.meta.mode !== 'edit'
    },
  },
  created() {
    this.checkCurrentMode()
    this.getRolesAndpermissions()
    if (!this.isCreate) {
      this.getActivityLog('created_at', 'desc')
    }
  },
  methods: {
    checkboxChanged() {
      this.changeRoleBaseOnPermission()
    },
    async getRolesAndpermissions() {
      const {
        data: { roles, permissions, role_permissions },
      } = await this.$http.get('/roles-permissions')
      this.roles = roles
      this.permissions = permissions
      this.rolePermissions = role_permissions
    },
    changeRoleBaseOnPermission() {
      this.rolePermissions.every((rolePermission) => {
        const permissionNames = rolePermission.permissions.map(
          (permission) => permission.name,
        )

        const isCurrentPermissionsMatchRoleWithPermission = isEqual(
          sortBy(permissionNames),
          sortBy(this.form.permissions),
        )

        if (
          (this.form.role = '' || !isCurrentPermissionsMatchRoleWithPermission)
        ) {
          this.form.role = 'personalise'

          return true
        } else if (isCurrentPermissionsMatchRoleWithPermission) {
          this.form.role = rolePermission.slug
          return false
        } else {
          return true
        }
      })
    },
    roleSelected() {
      const { permissions } = this.rolePermissions.find((rolePermission) => {
        return rolePermission.slug === this.form.role
      })
      this.form.permissions = []
      permissions.forEach((permission) =>
        this.form.permissions.push(permission.name),
      )
    },
    getTitlePermission(title) {
      switch (title) {
        case 'marketplace':
          return 'MarketPlace'
        case 'mon histoire':
          return 'Mon activité'
        default:
          return title
      }
    },
    checkCurrentMode() {
      if (this.$route.meta.mode !== 'edit') return
      this.storeURL = `admin/promoteurs/${this.$route.params.id}?_method=PUT`
      this.action = 'Editer'
      this.editablePromoteur(this.$route.params.id)
    },
    editablePromoteur(id) {
      this.$http.get(`admin/promoteurs/${id}`).then((res) => {
        const user = {
          ...res.data.promoteur,
          ...res.data.promoteur.user,
        }
        Object.keys(this.form).forEach((element) => {
          if (element in user) {
            this.form[element] = user[element]
          }
        })

        this.form.role = res.data.role
        this.form.permissions = res.data.permissions
      })
    },
    save() {
      const form = toMulipartedForm(this.form)

      if (
        this.action === 'Editer' &&
        (this.form.password || this.form.password_confirmation)
      ) {
        if (
          this.form.password.length < 8 ||
          this.form.password !== this.form.password_confirmation
        ) {
          this.$toaster.warning(
            'le mot de passe et la confirmation du mot de passe doivent correspondre et contenir au moins 8 caractères.',
          )
          return
        }
      }

      this.loading = true

      this.$http
        .post(this.storeURL, form)
        .then(() => {
          this.$toaster.success(
            'vous avez ajouté un nouvel utilisateur avec succès',
          )
          this.$router.push({
            name: 'list-des-promoteurs',
          })
          this.loading = false
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors)
          this.loading = false
        })
    },
    isVisible() {
      if (this.last_page === this.current_page) {
        return
      }
      this.current_page++
      this.getActivityLog(this.sortKey, this.sortOrder)
    },
    getActivityLog(sortBy, sortOrder) {
      this.isFetching = true
      this.$http
        .get(`admin/promoteurs/${this.$route.params.id}/logs`, {
          params: {
            page: this.current_page,
            sortBy,
            sortOrder,
          },
        })
        .then((res) => {
          this.isFetching = false
          this.activity_logs.push(...res.data.data)
          this.last_page = res.data.meta.last_page
          this.current_page = res.data.meta.current_page
        })
    },

    orderBy(key, order) {
      this.sortKey = key
      this.sortOrder = order
      this.activity_logs = []
      this.current_page = 1
      this.getActivityLog(key, order)
    },

    setSortingBy(val) {
      this.sorting_by = val
    },
  },
}
</script>
