import { render, staticRenderFns } from "./SwiperThumbs.vue?vue&type=template&id=9c8b490c&scoped=true&"
import script from "./SwiperThumbs.vue?vue&type=script&lang=js&"
export * from "./SwiperThumbs.vue?vue&type=script&lang=js&"
import style0 from "./SwiperThumbs.vue?vue&type=style&index=0&id=9c8b490c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c8b490c",
  null
  
)

export default component.exports