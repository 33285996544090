export default {
  methods: {
    createIconForCluster(point_count, coords, clusterID) {
      let vm = this
      var el = document.createElement('div')
      el.className = 'mapCluster'
      el.innerText = point_count
      let marker = new this.$mapboxgl.Marker(el).setLngLat(coords)
      marker.addTo(this.map)
      this.allMarkersLocalisations.push(marker)
      el.addEventListener('click', function (e) {
        vm.map
          .getSource(vm.source_and_layers_cluster[0])
          .getClusterExpansionZoom(clusterID, function (err, zoom) {
            if (err) return

            vm.map.easeTo({
              center: coords,
              zoom: zoom,
            })
          })
      })
      return el
    },
    createIconForLocalisation(properties, coords) {
      let vm = this
      var el = document.createElement('div')
      el.className = 'marker_cat'
      el.id = coords
      el.setAttribute('coords_poi', properties.coords)
      el.setAttribute('marker', properties.marker)
      el.setAttribute('key', properties.key)
      el.setAttribute('label', properties.localisation_name)
      el.setAttribute('subCat', properties.subCat)
      el.style.backgroundImage = `url(/images/markers/commodite/${properties.subMarker}.svg)`

      let popup = new this.$mapboxgl.Popup({
        anchor: 'bottom',
      })

      let template = this.templatePopup(
        this.parseStringtoArray(properties.subCat),
        this.parseStringtoArray(properties.localisation_name),
        false,
      )
      popup.on('open', function (e) {
        vm.customPopupButtonClose(e)
      })
      let marker = this.addCustomMarkerWithPopupInMap(
        template,
        coords,
        el,
        popup,
      ).marker

      this.allMarkersLocalisations.push(marker)
      return el
    },
    getPolygonForMaxZoom(feature_collection_markers_parcelles, distanceByKm) {
      let vm = this
      let enveloped = this.$turf.envelope(feature_collection_markers_parcelles)
      let union_parcelles = this.$turf.union(...this.allParcelles)

      var center_parcelles = this.$turf.pointOnFeature(union_parcelles)
      let buffered_center_parcelles = this.$turf.buffer(
        center_parcelles,
        distanceByKm,
      )

      let intersect_martinez = vm.$martinez.intersection(
        enveloped.geometry.coordinates,
        buffered_center_parcelles.geometry.coordinates,
      )
      let polyToZoom = vm.$turf.polygon(vm.getCoordinates(intersect_martinez))
      return polyToZoom
    },
  },
}
