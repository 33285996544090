<template>
  <div class="gamme-items">
    <div
      @click="select(item)"
      v-for="item in data"
      :key="item"
      class="gamme-item"
      :class="{
        'gamme-item--active': isSelected(item),
      }"
    >
      <span>{{ item }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    value: String,
  },
  methods: {
    isSelected(value) {
      return this.value === value
    },
    select(value) {
      this.$emit('valueSelect', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.gamme-items {
  @apply md:w-fit sm:w-auto
    md:mx-auto 
    flex space-x-5 md:space-x-0 
    md:grid 
    md:grid-cols-2 xs:grid-cols-1
    md:gap-y-6 md:gap-x-24 sm:gap-x-6 xs:gap-y-4
    md:text-sm;
}

.gamme-item {
  @apply w-48 px-4 py-2
    text-gray-600 hover:text-promy-green-300 font-semibold capitalize
    border-2 border-promy-gray-300 hover:border-promy-green-300 rounded-full
    cursor-pointer 
    bg-gray-50 
    focus:outline-none
    flex justify-center;
}

.gamme-item--active {
  @apply text-promy-green-300 border-promy-green-300;
}
</style>
