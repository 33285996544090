<template>
  <div
    class="flex items-center grid-cols-3 lg:relative bg-white rounded-box px-2 py-3 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
  >
    <div class="flex flex-1">
      <p class="text-xs text-promy-green-200 underline">{{ label }}</p>
    </div>
    <div class="flex">
      <div class="flex">
        <div
          @click="viewFile"
          class="flex items-center justify-center p-1 mr-1 text-white rounded-full cursor-pointer bg-promy-green-300 w-6 h-6"
        >
          <img class="cursor-pointer" src="./icons/eye.svg" alt="eye" />
        </div>
        <div
          @click="downloadFile"
          class="flex items-center justify-center p-1 w-6 h-6 mr-1 text-white rounded-full cursor-pointer bg-promy-green-300"
        >
          <img
            class="cursor-pointer"
            src="./icons/download.svg"
            alt="download"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    viewFile() {
      window.open(this.$base_file + this.link)
    },
    downloadFile() {
      this.$http
        .get(`admin/delegation-mandats/${this.id}/${this.type}/download-file`, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = this.label
          link.click()
        })
    },
  },
}
</script>
