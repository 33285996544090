<template>
  <validation-provider
    class="flex flex-col"
    rules="required"
    v-slot="{ errors }"
  >
    <input
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      @input="handleInput($event.target.value)"
      :disabled="disabled"
      :class="{ 'input-error': errors[0] }"
    />
    <span v-show="errors[0] || bError" class="msg-error">{{
      errors[0] || bError
    }}</span>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    rules: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    bError: {
      type: Array,
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  @apply rounded-full px-4 py-2  text-promy-gray-600 border-solid border-2  w-full mt-1 border-promy-gray-300 bg-promy-gray-background-300;
}
input:focus {
  @apply outline-none bg-white;
}
input[disabled] {
  @apply border-promy-gray-300 bg-promy-gray-300;
}
.msg-error {
  @apply rounded-lg w-full py-3 px-3  bg-promy-red-200 text-sm text-promy-red mb-4 mt-2 text-opacity-50;
}
.input-error {
  @apply border-promy-red-100;
}
</style>
