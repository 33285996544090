<template>
  <div class="flex flex-col w-full mt-6 xl:px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des promoteurs</page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>Abonnement Temporaire</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('list-des-teams')"
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <template v-if="form.raison_socials.length > 0">
      <h2 class="text-base tracking-wide font-main text-gray-800 font-bold">
        Teams sélectionnées :
      </h2>
      <p class="text-sm font-semibold text-gray-600">
        {{ form.raison_socials }}
      </p>
    </template>
    <div class="flex space-x-4 mt-8">
      <div class="w-1/2 bg-white rounded-lg">
        <div class="px-4 py-4 border-b border-gray-300">
          <p class="text-lg font-semibold text-gray-700">Départements</p>
        </div>
        <div class="px-4 py-4 grid gap-x-4 grid-cols-2 w-full">
          <div
            v-for="(departement, index) in form.departements"
            :key="departement.name + index"
          >
            <div>
              <div class="flex items-center my-4">
                <div class="mr-8">
                  <input
                    :id="departement.id + departement.name + index"
                    v-model="form.checked_departements"
                    type="checkbox"
                    :value="departement"
                  />
                  <label
                    :for="departement.id + departement.name + index"
                  ></label>
                </div>
                <span class="text-sm text-gray-700">
                  {{ departement.name }}
                </span>
              </div>
              <div v-if="isChecked(form.checked_departements, departement.id)">
                <div class="pl-8">
                  <div
                    class="flex items-center mt-3"
                    v-for="(hasAccessTo, indexx) in hasAccessesTo"
                    :key="hasAccessTo + indexx"
                  >
                    <div class="mr-8">
                      <input
                        :id="
                          hasAccessTo.value +
                          departement.id +
                          departement.name +
                          indexx
                        "
                        v-model="departement.has_access_to"
                        @change="here()"
                        type="checkbox"
                        :value="hasAccessTo.value"
                      />
                      <label
                        :for="
                          hasAccessTo.value +
                          departement.id +
                          departement.name +
                          indexx
                        "
                      ></label>
                    </div>
                    <span class="capitalize text-sm text-gray-700">
                      {{ hasAccessTo.name }}
                    </span>
                  </div>
                </div>
                <datepicker
                  placeholder="Date d'expiration"
                  class="date-input mt-4"
                  v-model="departement.expire_at"
                  valueType="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                ></datepicker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2 space-y-4">
        <div class="w-full bg-white rounded-lg">
          <div class="px-4 py-4 border-b border-gray-300">
            <p class="text-lg font-semibold text-gray-700">National</p>
          </div>
          <div class="px-4 py-4 grid gap-x-4 grid-cols-2 w-full">
            <div
              v-show="form.nations.length"
              v-for="(nation, index) in form.nations"
              :key="nation.name + index"
            >
              <div>
                <div class="flex items-center my-4">
                  <div class="mr-8">
                    <input
                      :id="nation.id + nation.name + index"
                      v-model="form.checked_nations"
                      type="checkbox"
                      :value="nation"
                    />
                    <label :for="nation.id + nation.name + index"></label>
                  </div>
                  <span class="text-sm text-gray-700">
                    {{ nation.name }}
                  </span>
                </div>
                <div v-if="isChecked(form.checked_nations, nation.id)">
                  <div class="pl-8">
                    <div
                      class="flex items-center mt-3"
                      v-for="(hasAccessTo, indexx) in hasAccessesTo"
                      :key="hasAccessTo + indexx + nation.id"
                    >
                      <div class="mr-8">
                        <input
                          :id="
                            hasAccessTo.value + nation.id + nation.name + indexx
                          "
                          v-model="nation.has_access_to"
                          @change="here()"
                          type="checkbox"
                          :value="hasAccessTo.value"
                        />
                        <label
                          :for="
                            hasAccessTo.value + nation.id + nation.name + indexx
                          "
                        ></label>
                      </div>
                      <span class="capitalize text-sm text-gray-700">
                        {{ hasAccessTo.name }}
                      </span>
                    </div>
                  </div>
                  <datepicker
                    placeholder="Date d'expiration"
                    class="date-input mt-4"
                    v-model="nation.expire_at"
                    valueType="DD-MM-YYYY"
                    format="DD-MM-YYYY"
                  ></datepicker>
                </div>
              </div>
            </div>
            <div v-show="!form.nations.length" class="text-sm text-gray-700">
              Cet promoteur à un abonnement national
            </div>
          </div>
        </div>
        <div class="w-full bg-white rounded-lg">
          <div class="px-4 py-4 border-b border-gray-300">
            <p class="text-lg font-semibold text-gray-700">Régions</p>
          </div>
          <div class="px-4 py-4 grid gap-x-4 grid-cols-2 w-full">
            <div
              v-for="(region, index) in form.regions"
              :key="region.name + index"
            >
              <div>
                <div class="flex items-center my-4">
                  <div class="mr-8">
                    <input
                      :id="region.id + region.name + index"
                      v-model="form.checked_regions"
                      type="checkbox"
                      :value="region"
                    />
                    <label :for="region.id + region.name + index"></label>
                  </div>
                  <span class="text-sm text-gray-700">
                    {{ region.name }}
                  </span>
                </div>
                <div v-if="isChecked(form.checked_regions, region.id)">
                  <div class="pl-8">
                    <div
                      class="flex items-center mt-3"
                      v-for="(hasAccessTo, indexx) in hasAccessesTo"
                      :key="hasAccessTo + indexx + region.id"
                    >
                      <div class="mr-8">
                        <input
                          :id="hasAccessTo + region.id + region.name + indexx"
                          v-model="region.has_access_to"
                          @change="here()"
                          type="checkbox"
                          :value="hasAccessTo.value"
                        />
                        <label
                          :for="hasAccessTo + region.id + region.name + indexx"
                        ></label>
                      </div>
                      <span class="capitalize text-sm text-gray-700">
                        {{ hasAccessTo.name }}
                      </span>
                    </div>
                  </div>
                  <datepicker
                    class="date-input mt-4"
                    v-model="region.expire_at"
                    valueType="DD-MM-YYYY"
                    format="DD-MM-YYYY"
                  ></datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex my-12 gap-x-6" v-if="$can('edite,gestion_utilisateur')">
      <button
        @click="handleSubmit()"
        class="flex justify-between w-40 px-6 py-3 text-sm font-bold text-white uppercase transition-colors duration-200 border rounded-full bg-promy-green-300 hover:text-promy-green-300 hover:border-promy-green-300 hover:bg-white font-main"
      >
        <span> valider </span>
      </button>
      <button
        @click="removeGrandfathered()"
        class="flex justify-between px-6 py-3 text-sm font-bold text-white uppercase transition-colors duration-200 bg-red-500 border rounded-full gap-x-4 hover:text-red-500 hover:border-red-500 hover:bg-white font-main"
      >
        <span> Réinitialiser </span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      teamId: this.$route.params.id,
      hasAccessesTo: [
        {
          name: 'marketplace',
          value: 'marketplace',
        },
        {
          name: 'guichet unique',
          value: 'guichet unique',
        },
        {
          name: 'carte de prospection',
          value: 'carte procepection',
        },
        {
          name: 'pige foncière',
          value: 'pige',
        },
      ],
      form: {
        nations: [],
        grandfatheredNations: [],
        departements: [],
        regions: [],
        grandfatheredDepartements: [],
        grandfatheredRegions: [],
        checked_departements: [],
        checked_regions: [],
        checked_nations: [],
        raison_socials: [],
      },
    }
  },
  created() {
    this.grandfatheredTeam()
  },
  watch: {
    'form.grandfatheredNations': {
      immediate: true,
      handler(value) {
        value.forEach((element) => {
          this.form.nations.forEach((nation) => {
            if (element.id == nation.id) {
              this.form.checked_nations.push(nation)
            }
          })
        })
      },
    },
    'form.grandfatheredDepartements': {
      immediate: true,
      handler(value) {
        value.forEach((element) => {
          this.form.departements.forEach((departement) => {
            if (element.id == departement.id) {
              this.form.checked_departements.push(departement)
            }
          })
        })
      },
    },
    'form.grandfatheredRegions': {
      immediate: true,
      handler(value) {
        value.forEach((element) => {
          this.form.regions.forEach((region) => {
            if (element.id == region.id) {
              this.form.checked_regions.push(region)
            }
          })
        })
      },
    },
  },
  methods: {
    isChecked(collections, id) {
      return collections.some((collection) => collection.id == id)
    },
    async removeGrandfathered() {
      await this.$http.delete(
        `/admin/grandfathered-abonnements?teamIds=${this.teamId}`,
      )
      this.$toaster.success(
        'Les abonnements temporaires ont bien été réinitialisés pour cette team.',
      )
      this.$router.push({
        name: 'list-des-teams',
      })
    },
    async handleSubmit() {
      await this.$http.post('/admin/grandfathered-abonnements', {
        teamIds: this.teamId,
        checked_departements: this.form.checked_departements,
        checked_regions: this.form.checked_regions,
        checked_nations: this.form.checked_nations,
      })
      this.$toaster.success(
        'Les abonnements temporaires ont bien été ajoutés pour cette team.',
      )
      this.$router.push({
        name: 'list-des-teams',
      })
    },
    async grandfatheredTeam() {
      const {
        data: {
          raison_socials,
          merged_nations,
          grandfathered_nation,
          merged_regions,
          merged_departements,
          grandfathered_departement,
          grandfathered_region,
          hasAccessTo,
        },
      } = await this.$http.get('admin/grandfathered-abonnements', {
        params: {
          teamIds: this.teamId,
        },
      })
      this.form.nations = merged_nations
      this.form.grandfatheredNations = grandfathered_nation
      this.form.departements = merged_departements
      this.form.grandfatheredDepartements = grandfathered_departement
      this.form.regions = merged_regions
      this.form.grandfatheredRegions = grandfathered_region
      this.form.raison_socials = raison_socials
    },
  },
}
</script>
