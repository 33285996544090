import transport from '@/plugins/axios'
export default {
  namespaced: true,

  state: {
    partenaires: [],
    current_partenaire: null,
    loading: false,
    pagination: {},
  },

  actions: {
    async getAllPartenaires({ commit }, current_page) {
      commit('SET_LOADING', true)
      let list = await transport.get(`admin/partenaire?page=${current_page}`)
      commit('SET_PAGINATION', {
        current_page: list.data.current_page,
        last_page: list.data.last_page,
      })
      commit(
        'SET_PARTENAIRES',
        list.data.data.map((partenaire) => ({
          ...partenaire,
          logo_path: process.env.VUE_APP_BASE_STORAGE + partenaire.logo_path,
        })),
      )
      commit('SET_LOADING', false)
    },

    async getPartenaire({ commit }, id) {
      let partner = await transport.get(`admin/partenaire/${id}`)
      commit('SET_CURRENT_PARTENAIRE', partner.data.data)
    },

    async addPartenaire({ commit }, params) {
      commit('SET_LOADING', true)
      try {
        await transport.post('admin/partenaire', params)
        commit('SET_LOADING', false)
        return 'Ok'
      } catch (e) {
        commit('SET_LOADING', false)
        return e
      }
    },

    async updatePartenaire({ commit }, params) {
      commit('SET_LOADING', true)
      try {
        await transport.post(`admin/partenaire/${params.id}`, params.data)
        commit('SET_LOADING', false)
        return 'Ok'
      } catch (e) {
        commit('SET_LOADING', false)
        return e
      }
    },

    async deletePartenaire({ commit }, id) {
      commit('SET_LOADING', true)
      try {
        await transport.delete(`admin/partenaire/${id}`)
        commit('REMOVE_PARTENAIRE', id)
        commit('SET_LOADING', false)
        return 'Ok'
      } catch (e) {
        commit('SET_LOADING', false)
        return e
      }
    },
  },

  mutations: {
    SET_PARTENAIRES(state, list) {
      if (list) {
        state.partenaires.push(...list)
      } else {
        state.partenaires = []
      }
    },
    SET_CURRENT_PARTENAIRE(state, obj) {
      state.current_partenaire = obj
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_PAGINATION(state, obj) {
      state.pagination = obj
    },
    REMOVE_PARTENAIRE(state, id) {
      state.partenaires = state.partenaires.filter(
        (partenaire) => partenaire.id !== id,
      )
    },
  },

  getters: {
    allPartenaires(state) {
      return state.partenaires
    },
    currentPartenaire(state) {
      return state.current_partenaire
    },
    pagination(state) {
      return state.pagination
    },
    isLoading(state) {
      return state.loading
    },
  },
}
