<template>
  <Popup
    max_width_model="max-w-5xl sm:max-w-xs"
    v-if="showPopup"
    @close="closePopup"
  >
    {{ offres }}
    <h2
      class="font-bold font-main text-promy-primary text-lg pb-10"
      slot="title"
    >
      Offre de {{ promoteurName }}
    </h2>
    <div class="text-base font-normal text-promy-gray-250 mt-3" slot="body">
      <pro-card v-if="offres && offres.length > 0">
        <pro-table-row :isHeader="true">
          <pro-table-header-cell
            v-for="(sort, index) in sort_list"
            :key="index"
            :class="sort.classes"
            :sortingBy="sorting_by"
            :hasSort="sort.sortKey ? true : false"
            :title="sort.title"
            :sortKey="sort.sortKey"
          />
        </pro-table-row>
        <pro-table-row
          v-for="(item, index) in offres"
          :key="index"
          class="relative rounded-lg my-2 py-2 lg:py-0 lg:pt-7"
        >
          <pro-table-cell label="Status" class="w-2/4 lg:w-full">
            <span :class="getStatus(item.status)"></span>
          </pro-table-cell>
          <pro-table-cell label="ID Offre" class="w-1/4 lg:w-full">
            {{ item.hash_id }}
          </pro-table-cell>
          <pro-table-cell label="Nom de terrain" class="w-1/4 lg:w-full">
            {{ item.terrain.commune }}
          </pro-table-cell>
          <pro-table-cell label="Utilisateur" class="w-1/4 lg:w-full">
            {{ item.created_at | filterDate }}
          </pro-table-cell>
          <pro-table-cell label="Date" class="w-1/4 lg:w-full">
            {{ item.prix | formatEUR(0) }}
          </pro-table-cell>
          <pro-table-cell label="Prix" class="w-1/4 lg:w-full">
            {{ item.terrain.end_at | closingTime }}
          </pro-table-cell>
          <pro-table-cell label="Disponibilé" class="w-1/4 lg:w-full">
            <span
              class="text-sm font-normal font-arial cursor-pointer text-promy-green-200 border-b-2 border-promy-green-200"
              @click="voirOffre(item)"
            >
              voir
            </span>
          </pro-table-cell>
        </pro-table-row>
      </pro-card>
      <p class="text-black" v-else>Aucune note pour ce dossier promoteur.</p>
    </div>
  </Popup>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Status',
          sortKey: '',
          classes: 'w-2/4',
        },
        {
          title: 'ID Offre',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Nom de terrain',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Utilisateur',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Date',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Prix',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Disponibilé',
          sortKey: '',
          classes: 'w-1/4',
        },
      ],
      sorting_by: '',
    }
  },
  computed: {
    ...mapGetters({
      offres: 'offre/offres',
      showPopup: 'offre/showPopup',
      promoteurName: 'offre/promoteurName',
    }),
  },
  methods: {
    getStatus(status) {
      if (status === 'Gagnée') return 'Gagnee'
      if (status === 'En cours') return 'Encours'
      if (status === 'Perdu') return status
      return 'Encours'
    },
    closePopup() {
      this.$store.commit('offre/CLOSE_POPUP')
    },
    voirOffre(offre) {
      this.$router.push({
        name: 'dossier-promoteur-offres-offre',
        params: {
          dossier_promoteur_id: offre.terrain.id,
          offre_id: offre.hash_id,
        },
      })
      this.$store.commit('offre/CLOSE_POPUP')
    },
  },
}
</script>
<style lang="scss" scoped>
.Gagnee {
  @apply border-8 border-promy-green-200 rounded-full ml-1 h-1;
}
.Encours {
  @apply border-8 border-promy-yellow rounded-full ml-1 h-1;
}
.Perdu {
  @apply border-8 border-promy-red rounded-full ml-1 h-1;
}
</style>
