export default {
  namespaced: true,
  state: {
    resized: false,
    collapse: true,
  },
  getters: {
    resizedWindow(state) {
      return state.resized
    },
    collapse(state) {
      return state.collapse
    },
  },
  mutations: {
    RESIZE_WINDOW(state, payload) {
      state.resized = payload
    },
    COLLAPSE(state, collapse) {
      state.collapse = collapse
    },
  },
}
