<template>
  <div>
    <p class="title-text-filter">{{ items.name }}</p>
    <div class="mt-2 flex">
      <div
        class="flex items-center mr-1 w-full rounded-full overflow-hidden h-6"
        :class="activeMin ? 'border border-promy-green-300' : 'border'"
        @click="activeMin = true"
        v-on-clickaway="awayMin"
      >
        <div
          class="px-3 h-full flex items-center"
          :class="activeMin ? 'bg-promy-green-300' : ' bg-promy-gray-200'"
        >
          <p
            class="text-xs font-medium"
            :class="activeMin ? 'text-white' : 'text-promy-gray-500'"
          >
            Min
          </p>
        </div>
        <div class="flex flex-1 ml-2 text-sm text-promy-gray-700">
          <money
            v-bind="money"
            class="text-xs outline-none font-bold w-full"
            :class="activeMin ? 'text-promy-gray-600' : 'text-promy-green-300'"
            v-model="typedValue.min"
            ref="min"
            type="text"
            placeholder="symbol"
          ></money>
        </div>
      </div>
      <div
        class="flex items-center w-full rounded-full overflow-hidden h-6"
        :class="activeMax ? 'border border-promy-green-300' : 'border'"
        @click="activeMax = true"
        v-on-clickaway="awayMax"
      >
        <div
          class="px-3 h-full flex items-center"
          :class="activeMax ? 'bg-promy-green-300' : ' bg-promy-gray-200'"
        >
          <p
            class="text-xs font-medium"
            :class="activeMax ? 'text-white' : 'text-promy-gray-500'"
          >
            Max
          </p>
        </div>
        <div class="flex flex-1 ml-2 text-sm text-promy-gray-700">
          <money
            v-bind="money"
            class="text-xs outline-none font-bold w-full"
            :class="activeMax ? 'text-promy-gray-600' : 'text-promy-green-300'"
            v-model="typedValue.max"
            type="text"
            placeholder="symbol"
          ></money>
        </div>
      </div>
    </div>

    <vue-slider
      v-model="changeSlide"
      :useKeyboard="true"
      :tooltip="'none'"
      v-bind="options"
      class="mt-2"
    ></vue-slider>
    <div class="flex justify-between">
      <span class="slider-text">
        {{ limits[0] | formatNumber }} {{ items.sign }}
      </span>
      <span class="slider-text">
        {{ limits[1] | formatNumber }} {{ items.sign }}
      </span>
    </div>
  </div>
</template>
<script>
import { Money } from 'v-money'
import { directive as onClickaway } from 'vue-clickaway'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

export default {
  props: {
    items: {
      required: true,
    },
    limits: {
      required: true,
    },
    keyItem: {
      required: false,
      default: null,
    },
    thousandsSeparator: {
      type: String,
      default: ' ',
    },
  },
  data() {
    return {
      changeSlide: [0, 0],
      activeMin: false,
      activeMax: false,
      waiting: false,
      shouldTrigger: true,
      options: {},
      typedValue: {
        min: 0,
        max: 0,
      },
      money: {
        suffix: ` ${this.items.sign}`,
        precision: 0,
        masked: false,
        thousands: this.thousandsSeparator,
      },
    }
  },
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    Money,
  },
  watch: {
    items: {
      deep: true,
      handler(value) {
        this.typedValue.min = value.value[0]
        this.typedValue.max = value.value[1]
      },
    },
    limits: {
      deep: true,
      handler(value) {
        this.options.min = value[0]
        this.options.max = value[1]
      },
    },
    filter: {
      deep: true,
      handler(value) {
        if (
          Object.keys(value).length === 0 ||
          (Object.keys(value).length === 2 &&
            isEqual(Object.keys(value), ['statuts', 'hasReset']))
        ) {
          this.changeSlide = [0, 0]
          this.typedValue.min = 0
          this.typedValue.max = 0
        }
      },
    },
    changeSlide: {
      deep: true,
      handler(value) {
        this.typedValue.min = value[0]
        this.typedValue.max = value[1]
      },
    },
    typedValue: {
      deep: true,
      handler(value) {
        if ((value.min > 0 && value.max == 0) || value.min > value.max) return
        this.changeSlide = [value.min, value.max]
        if (!this.waiting && value.max > 0) {
          this.triggerDebounce()
          this.waiting = true
        }
        if (!this.shouldTrigger) {
          this.shouldTrigger = true
        }
      },
    },
  },
  methods: {
    triggerDebounce: debounce(function () {
      if (this.shouldTrigger) {
        this.changeRangeValue()
      } else {
        this.shouldTrigger = true
      }
      this.waiting = false
    }, 1000),
    awayMin() {
      this.activeMin = false
    },
    awayMax() {
      this.activeMax = false
    },
    changeRangeValue() {
      this.$emit('sliderChanged', this.changeSlide, this.keyItem)
    },
    resetFilter() {
      this.changeSlide = [0, 0]
    },
  },
}
</script>
