export default {
  namespaced: true,
  state: {
    openPopup: false,
    team: {},
  },
  getters: {
    showPopup(state) {
      return state.openPopup
    },
    team(state) {
      return state.team
    },
  },
  mutations: {
    OPEN_POPUP(state, team) {
      state.team = team
      state.openPopup = true
    },
    CLOSE_POPUP(state) {
      state.openPopup = false
    },
  },
}
