import transport from '@/plugins/axios'
import axios from 'axios'

export default {
  namespaced: true,

  state: {
    user: {},
    isLoggedIn: false,
    redirect_url: null,
    permissions: [],
  },

  getters: {
    user: (state) => state.user,
    isLoggedIn: (state) => state.isLoggedIn,
    redirect_url: (state) => state.redirect_url,
    countryCode: (state) => state.country_code,
    permissions: (state) => state.permissions,
  },

  mutations: {
    UPDATE_AVATAR(state, avatar) {
      state.user.avatar = avatar
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_LOGGEDIN(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },
    LOGOUT_USER(state) {
      state.user = {}
      state.isLoggedIn = false
    },
    REDIRECT_URL(state, url) {
      state.redirect_url = url
    },
    SET_COUNTRY_CODE(state, payload) {
      state.country_code = payload
    },
    SET_ADMIN_PERMISSIONS(state, payload) {
      state.permissions = payload
    },
  },

  actions: {
    async signIn({ commit }, form) {
      try {
        await axios.get(`${process.env.VUE_APP_BASE}sanctum/csrf-cookie`)
        const response = await this.$http.post(`login/admin`, form)
        commit('SET_LOGGEDIN', true)
        commit('SET_ADMIN_PERMISSIONS', response.data.permissions)
        dispatch('me')
      } catch (e) {
        throw e
      }
    },

    me({ commit, dispatch }) {
      dispatch('getCountryCode')
      return transport
        .get('me')
        .then((response) => {
          commit('SET_LOGGEDIN', true)
          commit('SET_ADMIN_PERMISSIONS', response.data.permissions)
          commit('auth/SET_USER', response.data, {
            root: true,
          })
        })
        .catch(() => {
          commit('SET_LOGGEDIN', false)
        })
    },

    //get the country code via https://ip2c.org/s
    async getCountryCode({ commit }) {
      const response = await axios.get('https://ip2c.org/s')
      commit('SET_COUNTRY_CODE', response.data)
    },

    async logoutUser({ commit }) {
      await transport.post('logout')
      commit('LOGOUT_USER')
    },
  },
}
