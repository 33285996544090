<template>
  <div class="mt-24">
    <h3>Mode de paiement</h3>

    <div class="flex flex-col my-2" v-if="form.payement_method">
      <div class="flex space-x-4 pt-3">
        <ProRadio
          value="paiement_bancaire"
          @input="form.payement_method.methode_paiement = $event"
          name="methode_paiement"
          id="paiement_bancaire"
          label="Prélèvement bancaire"
          :checked="
            form.payement_method.methode_paiement === 'paiement_bancaire'
          "
          :required="true"
        ></ProRadio>
        <ProRadio
          value="paiement_facture"
          @input="form.payement_method.methode_paiement = $event"
          name="methode_paiement"
          id="paiement_facture"
          label="Paiement sur facture"
          :checked="
            form.payement_method.methode_paiement === 'paiement_facture'
          "
          :required="true"
        ></ProRadio>
      </div>
    </div>

    <div
      class="mt-4 mb-6"
      v-if="
        form.payement_method &&
        form.payement_method.methode_paiement === 'paiement_bancaire'
      "
    >
      <h2 class="text-lg font-bold tracking-wide text-gray-800 font-main">
        Compte bancaire
      </h2>
      <div
        class="grid grid-cols-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
      >
        <pro-input
          name="Iban"
          requirements="iban"
          v-model="form.payement_method.paiement_bancaire.rib_iban"
          label="Iban"
        />

        <pro-input
          name="BIC"
          v-model="form.payement_method.paiement_bancaire.rib_bic"
          label="BIC"
        />
        <pro-input
          name="Domiciliation"
          v-model="form.payement_method.paiement_bancaire.rib_domiciliation"
          label="Domiciliation Bancaire"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { extend } from 'vee-validate'
import IBAN from 'iban'
export default {
  model: {
    prop: 'form',
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: {},
      exact_rib: '',
    }
  },
  mounted() {
    this.checkRib()
    this.calculIban()
  },
  methods: {
    calculIban() {
      extend('iban', {
        getMessage() {
          return "IBAN n'est pas correct"
        },
        validate(value) {
          return IBAN.isValid(value)
        },
      })
    },
    checkRib() {
      const vm = this
      extend('rib', {
        getMessage() {
          return "RIB n'est pas correct"
        },
        validate(value) {
          let calculatedRib = vm.calculRib()
          return calculatedRib === parseInt(value, 10)
        },
      })
    },

    calculRib() {
      let a = ''
      const num = 'a' + this.form.rib_numero_compte.toUpperCase()
      for (var i = 2; i <= 12; i++) {
        if (num.substring(i - 1, i) / 1 == num.substring(i - 1, i)) {
          a = a + '' + num.substring(i - 1, i)
        } else {
          if (num.substring(i - 1, i) == 'A' || num.substring(i - 1, i) == 'J')
            a = a + '1'
          if (
            num.substring(i - 1, i) == 'B' ||
            num.substring(i - 1, i) == 'K' ||
            num.substring(i - 1, i) == 'S'
          )
            a = a + '2'
          if (
            num.substring(i - 1, i) == 'C' ||
            num.substring(i - 1, i) == 'L' ||
            num.substring(i - 1, i) == 'T'
          )
            a = a + '3'
          if (
            num.substring(i - 1, i) == 'D' ||
            num.substring(i - 1, i) == 'M' ||
            num.substring(i - 1, i) == 'U'
          )
            a = a + '4'
          if (
            num.substring(i - 1, i) == 'E' ||
            num.substring(i - 1, i) == 'N' ||
            num.substring(i - 1, i) == 'V'
          )
            a = a + '5'
          if (
            num.substring(i - 1, i) == 'F' ||
            num.substring(i - 1, i) == 'O' ||
            num.substring(i - 1, i) == 'W'
          )
            a = a + '6'
          if (
            num.substring(i - 1, i) == 'G' ||
            num.substring(i - 1, i) == 'P' ||
            num.substring(i - 1, i) == 'X'
          )
            a = a + '7'
          if (
            num.substring(i - 1, i) == 'H' ||
            num.substring(i - 1, i) == 'Q' ||
            num.substring(i - 1, i) == 'Y'
          )
            a = a + '8'
          if (
            num.substring(i - 1, i) == 'I' ||
            num.substring(i - 1, i) == 'R' ||
            num.substring(i - 1, i) == 'Z'
          )
            a = a + '9'
        }
      }

      this.exact_rib =
        97 -
        ((89 * this.form.rib_code_banque +
          15 * this.form.rib_code_guichet +
          3 * a) %
          97)
      return this.exact_rib
    },
  },
}
</script>
