import axios from 'axios'
export default {
  data() {
    return {
      is_cavites_souterraines_loaded: false,
    }
  },
  methods: {
    setCavitesSouterraines(risques, CAV) {
      let pprn_info = []
      CAV.sub_title.forEach((sub) => {
        if (sub.key === 'alea') {
          axios
            .get(
              `https://mapsref.brgm.fr/wxs/georisques/rapport?version=1.0.0&service=wfs&request=getfeature&typename=${sub.layer}&propertyname=${sub.properties}&X=${this.lat}&Y=${this.long}&rayon=${sub.rayon}&resultType=results`,
            )
            .then(
              (response) => {
                let data_json = this.xmlToJson(response.data)
                sub.value = data_json['wfs:FeatureCollection']['gml:boundedBy'][
                  'gml:null'
                ]
                  ? 'Informations non connues'
                  : 'Oui'
                sub.displayRayon = true
                this.is_cavites_souterraines_loaded = true
              },
              (error) => {
                console.log(error)
                this.is_cavites_souterraines_loaded = true
              },
            )
        }

        if (sub.key === 'ppr') {
          if (this.notEmptyObject(risques.pprn)) {
            risques.pprn.forEach((pprn) => {
              pprn.listRisques.forEach((rsq) => {
                if (sub.codes.includes(rsq.numRisque)) {
                  sub.value = 'Oui'
                  pprn_info.push(pprn)
                }
              })
            })
            sub.value = sub.value === 'Oui' ? 'Oui' : 'Non'
            sub.bodyTable = this.setValuesTable(
              pprn_info,
              ['codeNatPpr', 'libellePpr'],
              'listRisques',
              'datePrescription',
              'dateApprobation',
            )
          } else {
            sub.value = 'Non'
          }
        }
      })
    },
  },
}
