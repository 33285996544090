<template>
  <button
    class="font-extrabold text-base flex justify-center items-center py-1 w-1/2 mr-3"
    :class="!is_parcelle_aggregate ? 'btn-green' : ' btn-white'"
    @click="addListenerClickAggregateParcelle"
  >
    <template v-if="!is_parcelle_aggregate">
      <span>AJOUTER</span>
      <img src="/images/plus.svg" class="h-3 w-3 ml-2" />
    </template>

    <template v-else>Valider</template>
  </button>
</template>
<script>
import Mapbox from '@/mixins/mapbox'
import parcelle from '@/mixins/cadastre/parcelle'
import batiment from '@/mixins/cadastre/batiment'
import informations_map from '@/mixins/dossierPromoteurs/informations/map'
import helpers from '@/mixins/helpers'
import calculeHelpers from '@/mixins/cadastre/calculeHelpers'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'
import urbanisme from '@/mixins/dossierPromoteurs/urbanisme'
import { mapGetters } from 'vuex'

export default {
  mixins: [
    helpers,
    calculeHelpers,
    Mapbox,
    parcelle,
    batiment,
    sourcesAndLayers,
    informations_map,
    urbanisme,
  ],
  props: {
    informations: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      urbanismeStore: 'dossierpromoteur/getUrbanisme',
    }),
  },
  methods: {
    addListenerClickAggregateParcelle() {
      if (this.is_parcelle_changed) {
        this.$toaster.warning('Veuillez valider votre parcelle ')
      } else if (this.is_corner_changed) {
        this.$toaster.warning('Veuillez valider vos choix des points ')
      } else if (this.is_parcelle_aggregate) {
        this.map.off('click', this.selectOrUnselectParcelleVoisines)
        this.resetParcelleVoisines(true)
        this.$store.commit('parcelle/IS_PARCELLE_AGGREGATE', false)
        this.$toaster.success('Les parcelles ont bien été ajoutées')
        this.$emit('addCornersWithElevation')
        this.updateZonesUrbaine(this.informations)
      } else {
        this.map.on('click', this.selectOrUnselectParcelleVoisines)
        this.$store.commit('parcelle/IS_PARCELLE_AGGREGATE', true)
      }
    },

    selectParcelleVoisines(
      ref_layer_parcelle_selected,
      selected_is_not_current_parcelle,
      reference_cadastrale_selected,
      parcelle_selected,
    ) {
      if (
        !this.layerExist(ref_layer_parcelle_selected) &&
        selected_is_not_current_parcelle
      ) {
        let options = this.copyObject(this.options_polygone)
        options.color = this.blue
        options.is_dash = true
        this.addPolygonsToMap(
          parcelle_selected,
          this.concatSourceAndLayersWithRef(
            ...this.source_and_layers_parcelles_voisines,
            reference_cadastrale_selected,
          ),
          options,
        )
        return true
      }
      return false
    },
    getParcelleVoisinesWithoutParcellesRemoved(
      parcelles_voisines,
      removed_ref_cadastrale_parcelles,
    ) {
      return [
        ...parcelles_voisines.filter(
          (parcelle_voisines) =>
            !removed_ref_cadastrale_parcelles.includes(
              parcelle_voisines.properties.id,
            ),
        ),
      ]
    },

    propertieNumExist(feature) {
      return this.isExist(this.getNestedObject(feature, 'properties', 'numero'))
    },

    selectOrUnselectParcelleVoisines(e) {
      let data = this.getAroundAndCurrentParcelles()
      let features = this.map.queryRenderedFeatures(e.point)
      if (this.propertieNumExist(features[0])) {
        let find_parcelle_voisine = data.parcelles_voisines.find(
          (parcelle) => parcelle.properties.id === features[0].properties.id,
        )
        var parcelle_selected = find_parcelle_voisine
          ? find_parcelle_voisine
          : data.parcelles_autour[features[0].id]
        parcelle_selected.id = features[0].id // to know index parcelle in parcelle autour

        let reference_cadastrale_selected = parcelle_selected.properties.id
        let reference_cadastrale_current = data.current_parcelle.properties.id

        let ref_layer_parcelle_selected =
          this.source_and_layers_parcelles_voisines[1] +
          '-' +
          reference_cadastrale_selected
        let selected_is_not_current_parcelle =
          reference_cadastrale_selected != reference_cadastrale_current

        if (
          this.layerExist(ref_layer_parcelle_selected) &&
          selected_is_not_current_parcelle
        ) {
          this.setInfosParcellesVoisines(
            this.unSelectParcelleVoisines(
              data.parcelles_voisines,
              parcelle_selected,
              reference_cadastrale_selected,
            ),
          )
        } else if (
          data.parcelles_voisines.length < this.max_parcelles_aggregate
        ) {
          this.selectParcelleVoisines(
            ref_layer_parcelle_selected,
            selected_is_not_current_parcelle,
            reference_cadastrale_selected,
            parcelle_selected,
          )
            ? this.setInfosParcellesVoisines(parcelle_selected)
            : ''
        } else if (
          data.parcelles_voisines.length >= this.max_parcelles_aggregate
        ) {
          this.$toaster.warning(
            `vous avez le droit de sélectionner ${this.max_parcelles_aggregate} parcelles voisines maximum ! `,
          )
        }
      }
    },
    unSelectParcelleVoisines(
      parcelles_voisines,
      parcelle_selected,
      reference_cadastrale_selected,
    ) {
      this.removeSourceAndLayers(
        ...this.concatSourceAndLayersWithRef(
          ...this.source_and_layers_parcelles_voisines,
          reference_cadastrale_selected,
        ),
      )
      return parcelles_voisines.filter(
        (parcelle) =>
          parcelle.properties.id !== parcelle_selected.properties.id,
      )
    },
    setInfosParcellesVoisines(parcelle_selected) {
      if (Array.isArray(parcelle_selected) === false)
        this.informations.parcelles_voisines.push(parcelle_selected)
      else this.informations.parcelles_voisines = parcelle_selected

      if (
        this.informations.parcelles_voisines &&
        this.informations.parcelles_voisines.length
      ) {
        if (!Array.isArray(parcelle_selected)) {
          let center =
            this.$turf.pointOnFeature(parcelle_selected).geometry.coordinates
          this.$http
            .get('admin/reverse-address', {
              params: {
                lon: center[0],
                lat: center[1],
              },
            })
            .then(
              (response) => {
                let features = response.data.url_gouv_reverse.features
                if (response.data.url_gouv_reverse.features.length) {
                  parcelle_selected.properties.adresse =
                    features[0].properties.label
                } else {
                  parcelle_selected.properties.adresse =
                    this.informations.adresse
                }
                this.setInfosParcelles([
                  this.informations.current_parcelle,
                  ...this.informations.parcelles_voisines,
                ])
              },
              (error) => {
                console.error(error)
              },
            )
        }
      } else {
        this.setInfosParcelles([
          this.informations.current_parcelle,
          ...this.informations.parcelles_voisines,
        ])
      }
    },
    getAroundAndCurrentParcelles() {
      return {
        current_parcelle: this.informations.current_parcelle,
        parcelles_voisines: this.informations.parcelles_voisines,
        parcelles_autour: this.parcelles_autour,
      }
    },
  },
}
</script>
