<template>
  <div class="flex flex-col">
    <div class="flex flex-col mt-8 sm-ws:mt-6">
      <div class="text-promy-gray-600 font-bold text-xs mb-3">Itinéraire:</div>
      <div class="flex w-full">
        <p class="text-promy-gray-350">Cliquez sur un point d'intérêt</p>
      </div>
    </div>
    <div class="flex flex-col mt-8 sm-ws:mt-6">
      <table class="text-xs text-promy-gray-700">
        <tr
          class="border-t border-solid border-gray-300 h-20"
          v-if="walking.duration"
        >
          <td>
            <span
              class="bg-promy-orange-100 flex rounded-full justify-center h-10 w-10"
            >
              <img src="/images//markers/stickman.svg" />
            </span>
          </td>
          <td>{{ (walking.duration / 60) | roundDouble(2) }}min à pied</td>
          <td>{{ (walking.distance / 1000) | roundDouble(2) }}km</td>
        </tr>
        <tr
          class="border-t border-solid border-gray-300 h-16"
          v-if="driving.duration"
        >
          <td>
            <span
              class="bg-promy-green-100 flex rounded-full justify-center h-10 w-10"
            >
              <img src="/images/markers/voiture.svg" />
            </span>
          </td>
          <td>{{ (driving.duration / 60) | roundDouble(2) }}min via voiture</td>
          <td>{{ (driving.distance / 1000) | roundDouble(2) }}km</td>
        </tr>
      </table>
    </div>
    <div
      class="flex flex-col mt-8 sm-ws:mt-6 sm:mb-6"
      v-if="position_active.key"
    >
      <div
        class="text-promy-gray-600 font-bold text-xs mb-3"
        id="positions-selectionnee"
      >
        Positions sélectionnées
      </div>
      <div class="flex w-full flex-col flex-wrap">
        <span class="text-base text-promy-gray-600"
          >{{ parseStringtoArray(position_active.subCat).join(' , ') }} :
        </span>
        <div class="flex flex-col">
          <span
            class="text-base text-promy-gray-700"
            v-for="(label, idx) in parseStringtoArray(position_active.label)"
            :key="idx"
            >{{ label }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helpers from '@/mixins/helpers'

import { mapGetters } from 'vuex'

export default {
  mixins: [helpers],
  computed: {
    ...mapGetters({
      walking: 'localisation/walking',
      driving: 'localisation/driving',
      position_active: 'localisation/position_active',
    }),
  },
}
</script>
