<template>
  <div class="flex flex-col w-full mt-6 xl:px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des teams </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>grandfathered une team</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('list-des-teams')"
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <div class="xl:pr-24">
      <!-- selected abonnement -->
      <div class="grid grid-cols-2 gap-6">
        <div>
          <p
            class="text-base capitalize tracking-wide mb-4 font-main text-gray-800 font-bold"
          >
            Département
          </p>
          <div
            class="relative flex flex-col h-64 px-3 py-3 overflow-auto bg-gray-300 rounded-large"
          >
            <div
              class="flex items-center my-3"
              v-for="(departement, index) in form.departements"
              :key="departement.id"
            >
              <div class="mr-8">
                <input
                  :id="departement.id + departement.name + index + 'her'"
                  v-model="form.checked_departements"
                  type="checkbox"
                  :value="departement"
                />
                <label
                  :for="departement.id + departement.name + index + 'her'"
                ></label>
              </div>
              <span class="text-sm text-gray-700">
                {{ departement.name }}
              </span>
              <div v-if="isChecked(form.checked_departements, departement.id)">
                <div
                  v-for="(hasAccessTo, indexx) in hasAccessesTo"
                  :key="hasAccessTo"
                >
                  <div class="mr-8">
                    <input
                      :id="
                        hasAccessTo + departement.id + departement.name + indexx
                      "
                      v-model="departement.has_access_to"
                      @change="here()"
                      type="checkbox"
                      :value="hasAccessTo"
                    />
                    <label
                      :for="
                        hasAccessTo + departement.id + departement.name + indexx
                      "
                    ></label>
                  </div>
                  <span class="text-sm text-gray-700">
                    {{ hasAccessTo }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p
            class="text-base capitalize tracking-wide mb-4 font-main text-gray-800 font-bold"
          >
            regions
          </p>
          <div
            class="relative flex flex-col h-64 px-3 py-3 overflow-auto bg-gray-300 rounded-large"
          >
            <div
              class="flex items-center my-3"
              v-for="region in form.regions"
              :key="region.id + region.name"
            >
              <div class="mr-8">
                <input
                  :id="region.id + region.name"
                  v-model="form.checked_regions"
                  type="checkbox"
                  :value="region"
                />
                <label :for="region.id + region.name"></label>
              </div>
              <span class="text-sm text-gray-700"> {{ region.name }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex my-12 gap-x-6">
      <button
        @click="handleSubmit()"
        class="flex justify-between w-40 px-6 py-3 text-sm font-bold text-white uppercase transition-colors duration-200 border rounded-full bg-promy-green-300 hover:text-promy-green-300 hover:border-promy-green-300 hover:bg-white font-main"
      >
        <span> valider </span>
      </button>
      <button
        @click="removeGrandfathered()"
        class="flex justify-between px-6 py-3 text-sm font-bold text-white uppercase transition-colors duration-200 bg-red-500 border rounded-full gap-x-4 hover:text-red-500 hover:border-red-500 hover:bg-white font-main"
      >
        <span> remove grandfathered </span>
      </button>
    </div>
  </div>
</template>

<script>
import { merge } from 'lodash'
export default {
  data() {
    return {
      teamId: this.$route.params.id,
      hasAccessesTo: ['marketplace', 'gu', 'cart'],
      form: {
        departements: [],
        regions: [],
        grandfatheredDepartements: [],
        grandfatheredRegions: [],
        checked_departements: [],
        checked_regions: [],
      },
    }
  },
  created() {
    this.grandfatheredTeam()
  },
  watch: {
    'form.grandfatheredDepartements': {
      immediate: true,
      handler(value) {
        value.forEach((element) => {
          this.form.departements.forEach((departement) => {
            if (element.id == departement.id) {
              this.form.checked_departements.push(departement)
            }
          })
        })
      },
    },
    'form.grandfatheredRegions': {
      immediate: true,
      handler(value) {
        value.forEach((element) => {
          this.form.regions.forEach((region) => {
            if (element.id == region.id) {
              this.form.checked_regions.push(region)
            }
          })
        })
      },
    },
  },
  methods: {
    isChecked(collections, id) {
      return collections.some((collection) => collection.id == id)
    },
    here() {},
    async removeGrandfathered() {
      await this.$http.delete(`/admin/grandfathered-abonnements/${this.teamId}`)
      /*       this.$toaster.success("vous avez supprimé grandfathered avec succès");
      this.$router.push({
        name: "list-des-teams",
      }); */
    },
    async handleSubmit() {
      await this.$http.post(`/admin/grandfathered-abonnements/${this.teamId}`, {
        checked_departements: this.form.checked_departements,
        checked_regions: this.form.checked_regions,
      })
      /*       this.$toaster.success("vous avez ajouté grandfathered avec succès");
      this.$router.push({
        name: "list-des-teams",
      }); */
    },
    async grandfatheredTeam() {
      const {
        data: {
          departements,
          merged_departements,
          grandfathered_departement,
          regions,
          grandfathered_region,
        },
      } = await this.$http.get(
        `/admin/grandfathered-abonnements/${this.teamId}`,
      )
      this.form.departements = merged_departements
      this.form.grandfatheredDepartements = grandfathered_departement
      this.form.regions = merge(regions, grandfathered_region)
      this.form.grandfatheredRegions = grandfathered_region
    },
  },
}
</script>
