var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"font-bold text-xl font-main"},[_vm._v("Listes des demandes")]),_c('div',{staticClass:"flex items-center justify-between my-2 border-b-2 lg:items-start xl:pb-5 lg:pb-4 xl:border-promy-gray-300 sm:flex-col"},[_c('div',{staticClass:"flex lg:flex-col space-x-4 lg:space-x-0"},_vm._l((_vm.status),function(item){return _c('div',{key:item + '-type',staticClass:"flex items-center"},[_c('span',{class:("rounded-full w-3 h-3 bg-promy-" + (_vm.getStatusColor(
            item
          )) + " mr-1")}),_c('span',{staticClass:"text-sm text-promy-gray-600"},[_vm._v(" "+_vm._s(_vm.getStatusColorName(item))+" ")])])}),0)]),_c('div',{staticClass:"xl:h-50-screen xl:overflow-y-auto liste-dossier"},[(_vm.isFetching && _vm.current_page == 1)?[_vm._m(0)]:[(_vm.demandeBienPiges.length > 0)?[_c('pro-card',[_c('pro-table-row',{attrs:{"isHeader":true}},_vm._l((_vm.sort_list),function(sort,index){return _c('pro-table-header-cell',{key:index,class:sort.classes,attrs:{"sortingBy":_vm.sorting_by,"hasSort":sort.sortKey ? true : false,"title":sort.title,"sortKey":sort.sortKey}})}),1),_vm._l((_vm.demandeBienPiges),function(item,index){return _c('pro-table-row',{key:index,staticClass:"relative"},[_c('pro-table-cell',{staticClass:"w-1/6 lg:w-full",attrs:{"label":"Status"}},[_c('div',{class:("rounded-full w-3 h-3 ml-4 bg-promy-" + (_vm.getStatusColor(
                  item.status
                )))})]),_c('pro-table-cell',{staticClass:"w-1/6 lg:w-full",attrs:{"label":"Promoteur"}},[_vm._v(" "+_vm._s(item.promoteur.full_name)+" ")]),_c('pro-table-cell',{staticClass:"w-1/6 lg:w-full",attrs:{"label":"Date de demande"}},[_vm._v(" "+_vm._s(item.created_at)+" ")]),_c('pro-table-cell',{staticClass:"w-1/6 lg:w-full",attrs:{"label":"Mise a jour"}},[_vm._v(" "+_vm._s(item.updated_at)+" ")]),_c('pro-table-cell',{staticClass:"w-1/6 lg:w-full",attrs:{"label":"Annonce"}},[_c('a',{staticClass:"font-arial text-base font-bold text-promy-green-150",attrs:{"href":item.pige_announce_url}},[_vm._v(" Annonce "+_vm._s(item.announce_number)+" ")])]),_c('pro-table-cell',{staticClass:"w-1/6 lg:w-full",attrs:{"actions_btn":true}},[_c('div',{staticClass:"w-full flex justify-end items-center space-x-3"},[(_vm.$can('delete,pige_fonciere'))?_c('div',{staticClass:"btn-actions",on:{"click":function($event){return _vm.displayModal(item)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e(),(
                    _vm.$can('edite,pige_fonciere') ||
                    _vm.$can('consultation,pige_fonciere')
                  )?_c('div',[_c('router-link',{staticClass:"btn-actions",attrs:{"to":{
                      name: 'pige_demande_item',
                      params: { id: item.id },
                    }}},[_c('i',{staticClass:"fas fa-edit"})])],1):_vm._e()])])],1)})],2),(_vm.demandeBienPiges.length > 0)?[_c('isVisible',{attrs:{"isFetching":_vm.isFetching},on:{"isVisible":_vm.isVisible}})]:_vm._e()]:_vm._e()]],2),(_vm.showModal)?_c('ValidateModal',{on:{"isCancel":function($event){_vm.showModal = false},"isValide":function($event){return _vm.deleteDemandeBienPige()}}},[_vm._t("default",[_vm._v("Êtes-vous sûr de vouloir supprimer cet demande ?")],{"slot":"message_body"}),_vm._t("default",[_vm._v("Confirmer")],{"slot":"button_valide"}),_vm._t("default",[_vm._v("Annuler")],{"slot":"button_cancel"})],2):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center w-full mt-24"},[_c('div',{staticClass:"lds-ring"},[_c('div'),_c('div'),_c('div'),_c('div')])])}]

export { render, staticRenderFns }