import axios from 'axios'
export default {
  data() {
    return {
      is_mouvement_terrain_loaded: false,
    }
  },
  methods: {
    setMouvementTerrain(risques, MVMT) {
      let pprn_info = []
      MVMT.sub_title = []
      this.setDetailRisqueMVMT(risques, MVMT)
      MVMT.sub_title.forEach((sub) => {
        if (sub.key === 'alea') {
          axios
            .get(
              `https://mapsref.brgm.fr/wxs/georisques/rapport?version=1.0.0&service=wfs&request=getfeature&typename=${sub.layer}&propertyname=${sub.properties}&X=${this.lat}&Y=${this.long}&rayon=500&resultType=results`,
            )
            .then(
              (response) => {
                let data_json = this.xmlToJson(response.data)
                sub.value = data_json['wfs:FeatureCollection']['gml:boundedBy'][
                  'gml:null'
                ]
                  ? 'Non'
                  : 'Oui'
                this.is_mouvement_terrain_loaded = true
              },
              (error) => {
                console.log(error)
                this.is_mouvement_terrain_loaded = true
              },
            )
        }

        if (sub.key === 'ppr') {
          if (this.notEmptyObject(risques.pprn)) {
            risques.pprn.forEach((pprn) => {
              pprn.listRisques.forEach((rsq) => {
                if (sub.codes.includes(rsq.numRisque)) {
                  sub.value = 'Oui'
                  pprn_info.push(pprn)
                }
              })
            })
            sub.value = sub.value === 'Oui' ? 'Oui' : 'Non'
            sub.bodyTable = this.setValuesTable(
              pprn_info,
              ['codeNatPpr', 'libellePpr'],
              'listRisques',
              'datePrescription',
              'dateApprobation',
            )
          } else {
            sub.value = 'Non'
          }
        }
      })
    },
    setDetailRisqueMVMT(risques, MVMT) {
      risques.definitions_risques.MVMT.alea.forEach((mvmt) => {
        this.setObjMVMT(MVMT, 'alea', mvmt)
      })
      this.setObjMVMT(MVMT, 'ppr', risques.definitions_risques.MVMT.ppr)
    },
    setObjMVMT(MVMT, key, obj) {
      let alea_obj = {
        key: key,
        title: obj.title,
        label: obj.label,
        rayon: obj.rayon,
        layer: key === 'ppr' ? obj.layerGaspar : obj.layer[0],
        properties: obj.properties.toString(),
        value: '',
        descr: obj.descr,
        codes: key === 'ppr' ? obj.codes : [],
        displayRayon: key != 'ppr',
      }
      MVMT.sub_title.push(alea_obj)
    },
  },
}
