<template>
  <div>
    <div
      v-show="numbreTabs < tabs.length"
      @click="open = true"
      class="three-dots cursor-pointer"
    >
      <div class="dot" />
      <div class="dot" />
      <div class="dot" />
    </div>
    <div v-if="open" class="arrow-up mt-8 z-10 absolute right-0 mr-4"></div>
    <div
      v-on-clickaway="away"
      v-if="open"
      class="rounded-box overflow-hidden mt-12 absolute bg-white right-0 mx-auto shadow-box z-10"
    >
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        v-show="index >= numbreTabs"
        :class="isActive ? 'bg-purple-200' : 'bg-transparent'"
        class="text-gray-700 capitalize text-sm px-3 w-53 h-10 flex justify-between items-center hover:bg-purple-200 cursor-pointer transition-all duration-100"
        @click="changeTab(tab.component), (open = false)"
      >
        <span>{{ tab.label }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway'
export default {
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    numbreTabs: {
      type: Number,
      equired: true,
    },
    tabs: {
      type: Array,
      require: true,
    },
    is_router_link: {
      type: Boolean,
      default: false,
    },
    current_component: {
      type: String,
      required: true,
    },
  },
  computed: {
    isActive(tab) {
      return this.is_router_link
        ? this.$route.name === tab.component
        : this.current_component === tab.component
    },
  },
  data() {
    return { open: false }
  },
  methods: {
    away() {
      this.open = false
    },
    changeTab(tab) {
      this.$emit('changeTab', tab)
    },
  },
}
</script>
<style lang="scss" scoped>
.three-dots {
  @apply w-8 absolute flex justify-between items-center;
  top: 10px;
  right: 10px;
}

.dot {
  @apply bg-promy-green-200 w-2 h-2 rounded-full;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
}
</style>
