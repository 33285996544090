export default {
  namespaced: true,
  state: {
    current_position: null,
    sidebar_tools: false,
    position_active: {
      subCat: null,
      marker: null,
      label: null,
      key: null,
    },
    driving: {},
    walking: {},
  },
  getters: {
    current_position(state) {
      return state.current_position
    },
    sidebar_tools(state) {
      return state.sidebar_tools
    },
    position_active(state) {
      return state.position_active
    },
    driving(state) {
      return state.driving
    },
    walking(state) {
      return state.walking
    },
  },
  mutations: {
    RESET_LOCALISATION(state) {
      state.current_position = null
      state.sidebar_tools = false
      state.driving = {}
      state.walking = {}
      state.position_active = {
        subCat: null,
        marker: null,
        label: null,
        key: null,
      }
    },
    CURRENT_POSITION(state, payload) {
      state.current_position = payload
    },
    SIDEBAR_TOOLS(state, payload) {
      state.sidebar_tools = payload
    },
    POSITION_ACTIVE(state, payload) {
      state.position_active = payload
    },
    DRIVING(state, payload) {
      state.driving = payload
    },
    WALKING(state, payload) {
      state.walking = payload
    },
  },
}
