function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'

export default [
  {
    name: 'boite-a-outils',
    path: 'boite-a-outils',
    meta: {
      middleware: [auth],
    },
    component: page('dashboard/BoiteAOutils'),
    redirect: 'boite-a-outils/bibliotheque-documents',
    children: [
      {
        path: 'bibliotheque-documents',
        component: page('dashboard/Parametrage/Bibliotheque'),
        meta: {
          middleware: [auth],
        },
        name: 'bibliotheque-documents',
        redirect: 'bibliotheque-documents/dossiers',
        children: [
          {
            path: 'dossiers',
            name: 'list-folders',
            component: page('dashboard/Parametrage/Bibliotheque/listFolders'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: ':id_folder/documents',
            name: 'list-documents',
            component: page('dashboard/Parametrage/Bibliotheque/listDocuments'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: ':id_folder/ajouter-document',
            name: 'add-document',
            component: page('dashboard/Parametrage/Bibliotheque/formDocument'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: ':id_folder/:id_document',
            name: 'update-document',
            component: page('dashboard/Parametrage/Bibliotheque/formDocument'),
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: 'courrier-type',
        component: page('dashboard/Parametrage/CourrierTypeWording'),
        meta: {
          middleware: [auth],
        },
        name: 'courriers-type',
        redirect: 'courrier-type/list',
        children: [
          {
            path: 'list',
            name: 'courrier-type-list',
            component: page('dashboard/Parametrage/CourrierTypeWording/list'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'form/:id?',
            name: 'courrier-type-form',
            component: page('dashboard/Parametrage/CourrierTypeWording/form'),
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
    ],
  },
]
