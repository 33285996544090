<template>
  <div
    class="flex flex-col relative w-fit"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <span
      class="lg:text-white lg:border-white w-fit sm:text-xs"
      :class="{
        'cursor-pointer text-promy-green-200 border-b-2 border-promy-green-200':
          seensBy.length > 0,
      }"
      >{{ vueDossierPromoteur }}
    </span>
    <div
      v-show="seensBy.length > 0"
      v-if="hover"
      class="z-50 bg-white flex flex-col flex-1 shadow-box rounded-lg p-4 space-y-5 text-sm absolute w-max right-0 sm:w-80 sm:right-0 max-h-72 overflow-y-auto"
    >
      <div
        v-for="(seenBy, index) in seensBy"
        :key="index"
        class="flex justify-between items-center rounded-lg"
      >
        <PromoteurSeen :seenBy="seenBy" />
      </div>
    </div>
  </div>
</template>
<script>
import PromoteurSeen from './PromoteurSeen'
export default {
  components: {
    PromoteurSeen,
  },
  props: {
    seensBy: {
      type: Array,
      required: true,
    },
    vueDossierPromoteur: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
}
</script>
