<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <div
      class="flex leading-2 text-promy-purple-400 text-promy-2xl font-extrabold font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Gestion des roles</span>
    </div>
    <div
      class="flex items-center lg:text-base mt-6 xl:text-lg font-main font-extrabold justify-between border-b-2 xl:pb-5 lg:pb-4 xl:border-promy-gray-300"
    >
      <span class="text-promy-gray-650 font-extrabold">Liste des rôles</span>
      <span
        class="flex justify-center items-center uppercase cursor-pointer text-white rounded-full border-2 bg-promy-green-300 px-4 py-2 border-promy-green-300 font-main text-sm font-extrabold"
        @click="toggleAddForm"
      >
        <span class="">Ajouter un role </span>
        <i class="fa fa-plus pl-2 text-sm"></i>
      </span>
    </div>

    <!-- add form -->
    <div
      class="lg:relative bg-white my-4 rounded-box px-6 py-7 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
      v-show="showAddForm"
    >
      <div class="sm:flex sm:flex-col grid gap-4 items-center grid-cols-2">
        <div class="flex justify-start">
          <span class="flex flex-col w-full">
            <input-text
              class="w-full"
              :rules="'required'"
              placeholder="Rôle"
              v-model="role"
              type="text"
              name="role"
            />
            <span class="msg-error" style="display: none"></span>
          </span>
        </div>
        <div class="flex justify-end items-start">
          <button
            class="bg-promy-green-300 rounded-full py-2 px-4 font-extrabold text-white mr-3 cursor-pointer disabled:opacity-50 capitalize"
            @click="storeRole"
            :disabled="role == null"
          >
            ajouter
          </button>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" @click="resetEdit">
      <div
        class="lg:relative bg-white my-4 rounded-box px-6 py-7 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
        v-for="role_item in roles"
        :key="role_item.id"
      >
        <div class="sm:flex sm:flex-col grid gap-4 items-center grid-cols-2">
          <div class="flex justify-start" @click="stopEventPropagationInput">
            <span
              class="text-gray-700 text-base capitalize font-semibold"
              v-if="!editMode || editedItem != role_item.id"
            >
              {{ role_item.name }}
            </span>
            <span
              class="flex flex-col w-full"
              v-if="editMode && editedItem === role_item.id"
            >
              <input-text
                class="w-full"
                :rules="'required'"
                placeholder="Role"
                v-model="role"
                type="text"
                name="role"
              />
              <span class="msg-error" style="display: none"></span>
            </span>
          </div>
          <div class="flex justify-end">
            <div class="flex">
              <!-- edit div -->
              <div
                v-if="!editMode || editedItem != role_item.id"
                @click="editItem($event, role_item)"
                class="rounded-full p-2 mr-3 cursor-pointer bg-promy-green-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18.12"
                  viewBox="0 0 18 18.12"
                  class="w-4 h-4"
                >
                  <path
                    d="M1761.46,1049.3a1.159,1.159,0,0,0-1.66,0l-3.46,3.47v-0.66h-12.52v14.94h14.87v-12.58h-0.72l3.49-3.51A1.171,1.171,0,0,0,1761.46,1049.3Zm-3.55,16.96H1744.6V1052.9h11.62l-6.14,6.16v1.7h1.63l6.2-6.23v11.73Zm3-15.85-9.52,9.56h-0.53v-0.59l9.49-9.53A0.4,0.4,0,0,1,1760.91,1050.41Z"
                    transform="translate(-1743.81 -1048.94)"
                    class="fill-current text-white"
                  ></path>
                </svg>
              </div>
              <!-- update button -->
              <div
                v-if="editMode && editedItem === role_item.id"
                class="rounded-full p-2 mr-3 cursor-pointer bg-white border-promy-green-300 border-2"
                @click="updateRole($event)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18.12"
                  viewBox="0 0 18 18.12"
                  class="w-4 h-4"
                >
                  <path
                    d="M1761.46,1049.3a1.159,1.159,0,0,0-1.66,0l-3.46,3.47v-0.66h-12.52v14.94h14.87v-12.58h-0.72l3.49-3.51A1.171,1.171,0,0,0,1761.46,1049.3Zm-3.55,16.96H1744.6V1052.9h11.62l-6.14,6.16v1.7h1.63l6.2-6.23v11.73Zm3-15.85-9.52,9.56h-0.53v-0.59l9.49-9.53A0.4,0.4,0,0,1,1760.91,1050.41Z"
                    transform="translate(-1743.81 -1048.94)"
                    class="fill-current text-promy-green-300"
                  ></path>
                </svg>
              </div>

              <div
                class="bg-promy-green-300 rounded-full p-2 mr-3 cursor-pointer flex items-center justify-center"
                @click="displayModal(role_item.id)"
              >
                <img src="/images/trash.svg" alt="" class="w-4 h-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="lg:text-base mt-10 xl:text-lg font-main font-extrabold xl:pb-5 lg:pb-4 text-center"
      >
        <span class="text-promy-gray-650 font-extrabold">
          Chargement en cours...
        </span>
      </div>
    </div>
    <div v-if="!roles.length">
      <div
        class="lg:text-base mt-10 xl:text-lg font-main font-extrabold xl:pb-5 lg:pb-4 text-center"
      >
        <span class="text-promy-gray-650 font-extrabold">
          La liste des roles est vide
        </span>
      </div>
    </div>

    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteRole()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer ce rôle ?</slot
      >
      <slot slot="button_valide">Continuer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showModal: false,
      role: '',
      showAddForm: false,
      editMode: false,
      editedItem: 0,
      id_to_be_deleted: null,
    }
  },
  computed: {
    ...mapGetters({
      roles: 'roleAndPermission/getRoles',
      isLoading: 'roleAndPermission/isLoading',
    }),
  },
  created() {
    this.$store.dispatch('roleAndPermission/getAllroles', {
      type: 'promoteur',
    })
  },
  methods: {
    storeRole() {
      this.$store.dispatch('roleAndPermission/addRole', {
        name: this.role,
        type: 'promoteur',
      })
      this.role = null
      this.showAddForm = false
      this.$toaster.success('Le rôle a été ajouté avec succès')
    },
    deleteRole() {
      this.$store
        .dispatch('roleAndPermission/deleteRole', {
          id: this.id_to_be_deleted,
          type: 'promoteur',
        })
        .then((response) => {
          this.showModal = false
          this.id_to_be_deleted = null
          this.$toaster.warning('Le rôle a été supprimé avec succès')
        })
        .catch((error) => {
          this.showModal = false
          this.id_to_be_deleted = null
          this.$toaster.error(error.response.data.error)
        })
    },
    displayModal(id) {
      this.showModal = true
      this.id_to_be_deleted = id
    },
    updateRole(event) {
      event.stopPropagation()
      if (this.role.length === 0) {
        this.$toaster.error('le champ role ne peut pas être vide')
        return
      }
      this.$store.dispatch('roleAndPermission/updateRole', {
        id: this.editedItem,
        data: this.role,
        type: 'promoteur',
      })
      this.editMode = false
      this.role = null
      this.editedItem = 0
      this.$toaster.info('Le rôle a été mis à jour avec succès')
    },
    toggleAddForm() {
      this.role = null
      this.editMode = false
      this.showAddForm = !this.showAddForm
    },
    editItem(event, role) {
      event.stopPropagation()
      if (this.showAddForm) this.showAddForm = !this.showAddForm
      this.editMode = true
      this.editedItem = role.id
      this.role = role.name
    },
    resetEdit() {
      this.editMode = false
    },
    stopEventPropagationInput(event) {
      event.stopPropagation()
    },
  },
}
</script>
