export default {
  namespaced: true,

  state: {
    api_is_loading: true,
    api_zones_urbanisme_loaded: false,
  },
  getters: {
    is_loading(state) {
      return state.api_is_loading
    },
    api_zones_urbanisme_loaded(state) {
      return state.api_zones_urbanisme_loaded
    },
  },

  mutations: {
    set_loading(state, payload) {
      state.api_is_loading = payload
    },
    SET_API_ZONES_URBANISME_LOADED(state, payload) {
      state.api_zones_urbanisme_loaded = payload
    },
  },
}
