<template>
  <Popup max_width_model="max-w-5xl sm:max-w-xs" @close="$emit('closePopup')">
    <h2 class="font-bold font-main text-promy-primary text-lg" slot="title">
      Sections actives
    </h2>
    <div class="text-base font-normal text-promy-gray-250 mt-3" slot="body">
      <div class="grid grid-cols-3">
        <div
          class="flex items-stretch"
          style="margin: 20px 0 !important"
          v-for="(value, name, index) in sections"
          :key="'section-' + index"
        >
          <Checkbox
            :id="name"
            :val="value.is_active"
            v-model="value.is_active"
            class="pr-10"
            :disabled="name === 'informations'"
          />
          <label
            :for="name"
            class="cursor-pointer text-sm flex items-center mt-1"
            :class="[
              name === 'informations'
                ? 'text-promy-gray-300'
                : 'text-promy-gray-650',
            ]"
            >{{ value.name }}</label
          >
        </div>
      </div>
      <button
        class="px-8 mt-3 w-fit flex justify-between items-center py-2 rounded-full text-center border-2 bg-promy-green-300 border-promy-green-300 px-4 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
        @click="valider"
      >
        Valider
      </button>
    </div>
  </Popup>
</template>

<script>
import { mapGetters } from 'vuex'
import progress from '@/mixins/dossierPromoteurs/progress'

export default {
  mixins: [progress],
  props: {
    showPopupSections: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sections: {},
    }
  },
  computed: {
    ...mapGetters({
      parametrageSections: 'dossierpromoteur/getActiveSections',
      progress_section: 'dossierpromoteur/getProgressSection',
    }),
  },
  created() {
    this.sections = _.merge({}, this.sections, this.parametrageSections)
  },
  methods: {
    valider() {
      this.$store.commit('dossierpromoteur/setActiveSections', this.sections)
      this.$store.commit(
        'dossierpromoteur/setProgressDossier',
        this.progressDossier(this.progress_section),
      )
      this.$emit('closePopup')
    },
  },
}
</script>
