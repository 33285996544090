<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <div
      class="flex leading-2 text-promy-purple-400 text-promy-2xl font-extrabold font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Gestion des catégories</span>
    </div>
    <div
      class="flex sm:flex-col items-center sm:items-start sm:space-y-3 lg:text-base mt-6 xl:text-lg font-main font-extrabold justify-between border-b-2 xl:pb-5 lg:pb-4 xl:border-promy-gray-300"
    >
      <span class="text-promy-gray-650 font-extrabold">
        Liste des catégories
      </span>
      <span
        class="sm:w-full flex justify-center items-center uppercase cursor-pointer text-white rounded-full border-2 bg-promy-green-300 px-4 py-2 border-promy-green-300 font-main text-sm font-extrabold"
        @click="toggleAddForm"
      >
        <span class="">Ajouter une categorie </span>
        <i class="fa fa-plus pl-2 text-sm"></i>
      </span>
    </div>

    <!-- add form -->
    <div
      class="lg:relative bg-white my-4 rounded-box px-6 py-7 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
      v-show="showAddForm"
    >
      <div class="sm:flex sm:flex-col grid gap-4 items-center grid-cols-2">
        <div class="flex justify-start">
          <span class="flex flex-col w-full">
            <input-text
              class="w-full"
              :rules="'required'"
              placeholder="Catégorie"
              v-model="category"
              type="text"
              name="categorie"
            />
            <span class="msg-error" style="display: none"></span>
          </span>
        </div>
        <div class="flex justify-end items-start">
          <button
            class="bg-promy-green-300 rounded-full py-2 px-4 font-extrabold text-white mr-3 cursor-pointer disabled:opacity-50 capitalize"
            @click="storeCategory"
            :disabled="category == null"
          >
            ajouter
          </button>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" @click="resetEdit">
      <div
        class="lg:relative bg-white my-4 rounded-box px-6 py-7 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
        v-for="category_item in categories"
        :key="category_item.id"
      >
        <div
          class="sm:flex sm:flex-col grid gap-4 items-center sm:items-start grid-cols-2"
        >
          <div class="flex justify-start" @click="stopEventPropagationInput">
            <span
              class="text-gray-700 text-base capitalize font-semibold"
              v-if="!editMode || editedItem != category_item.id"
            >
              {{ category_item.name }}
            </span>
            <span
              class="flex flex-col w-full"
              v-if="editMode && editedItem === category_item.id"
            >
              <input-text
                class="w-full"
                :rules="'required'"
                placeholder="Role"
                v-model="category"
                type="text"
                name="categorie"
              />
              <span class="msg-error" style="display: none"></span>
            </span>
          </div>
          <div class="flex justify-end sm:absolute sm:top-5 sm:right-4">
            <button
              @click="showActions(category_item.id)"
              class="hidden sm:block w-2 h-5 border-l-4 border-dotted border-promy-green-300"
            />
            <div
              v-on-clickaway="away"
              v-if="show_actions_id === category_item.id || $mq != 'sm'"
              class="flex space-x-2 sm:absolute sm:-top-16 sm:right-0 sm:bg-white sm:px-3 sm:py-2 sm:rounded-lg"
            >
              <!-- edit div -->
              <div
                v-if="!editMode || editedItem != category_item.id"
                @click="editItem($event, category_item)"
                class="green-icon-btn"
              >
                <i class="fas fa-edit" />
              </div>
              <!-- update button -->
              <div
                v-if="editMode && editedItem === category_item.id"
                class="white-icon-btn"
                @click="updateCategory($event)"
              >
                <i class="fas fa-edit" />
              </div>
              <div
                class="green-icon-btn"
                @click="displayModal(category_item.id)"
              >
                <i class="fas fa-trash" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="lg:text-base mt-10 xl:text-lg font-main font-extrabold xl:pb-5 lg:pb-4 text-center"
      >
        <span class="text-promy-gray-650 font-extrabold">
          Chargement en cours...
        </span>
      </div>
    </div>
    <div v-if="!categories.length">
      <div
        class="lg:text-base mt-10 xl:text-lg font-main font-extrabold xl:pb-5 lg:pb-4 text-center"
      >
        <span class="text-promy-gray-650 font-extrabold">
          La liste des catégories est vide
        </span>
      </div>
    </div>

    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteCategory()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer cette catégorie ?</slot
      >
      <slot slot="button_valide">Continuer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      showModal: false,
      category: '',
      showAddForm: false,
      editMode: false,
      editedItem: 0,
      id_to_be_deleted: null,
      show_actions_id: -1,
    }
  },
  computed: {
    ...mapGetters({
      categories: 'roleAndPermission/getCategories',
      isLoading: 'roleAndPermission/isLoading',
    }),
  },
  created() {
    this.$store.dispatch('roleAndPermission/getAllcategories', {
      type: 'admin',
    })
  },
  methods: {
    showActions(id) {
      this.show_actions_id = id
    },
    away() {
      this.show_actions_id = -1
    },
    storeCategory() {
      this.$store.dispatch('roleAndPermission/addCategory', {
        name: this.category,
        type: 'admin',
      })
      this.category = null
      this.showAddForm = false
      this.$toaster.success('La catégorie a été ajoutée avec succès')
    },
    deleteCategory() {
      this.$store.dispatch('roleAndPermission/deleteCategory', {
        id: this.id_to_be_deleted,
        type: 'admin',
      })
      this.showModal = false
      this.id_to_be_deleted = null
      this.$toaster.warning('La catégorie a été supprimée avec succès')
    },
    displayModal(id) {
      this.showModal = true
      this.id_to_be_deleted = id
    },
    updateCategory(event) {
      event.stopPropagation()
      if (this.category.length === 0) {
        this.$toaster.error('Le champ catégorie est obligatoire')
        return
      }
      this.$store.dispatch('roleAndPermission/updateCategory', {
        id: this.editedItem,
        data: this.category,
        type: 'admin',
      })
      this.editMode = false
      this.category = null
      this.editedItem = 0
      this.$toaster.info('La catégorie a été mise à jour avec succès')
    },
    toggleAddForm() {
      this.category = null
      this.editMode = false
      this.showAddForm = !this.showAddForm
    },
    editItem(event, category_item) {
      event.stopPropagation()
      if (this.showAddForm) this.showAddForm = !this.showAddForm
      this.editMode = true
      this.editedItem = category_item.id
      this.category = category_item.name
    },
    resetEdit() {
      this.editMode = false
    },
    stopEventPropagationInput(event) {
      event.stopPropagation()
    },
  },
}
</script>

<style scoped></style>
