function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'

export default [
  {
    path: 'gestion-des-roles-et-permissions',
    name: 'gestion-des-roles-et-permissions',
    component: page('dashboard/GestionDesRolesEtPermissions'),
    redirect: 'gestion-des-roles-et-permissions/categories',
    children: [
      {
        path: 'roles',
        name: 'liste-des-roles-component',
        redirect: 'roles/type',
        component: page('dashboard/GestionDesRolesEtPermissions'),
        children: [
          {
            path: 'type',
            name: 'liste-des-roles',
            meta: {
              middleware: [auth],
            },
            component: page('dashboard/GestionDesRolesEtPermissions/Role/type'),
          },
          {
            path: 'admin/edit',
            name: 'liste-des-roles-admin',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Role/Admin/edit',
            ),
          },
          {
            path: 'promoteur/edit',
            name: 'liste-des-roles-promoteur',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Role/Promoteur/edit',
            ),
          },
        ],
      },
      {
        path: 'categories',
        name: 'liste-des-categories-component',
        redirect: 'categories/type',
        component: page('dashboard/GestionDesRolesEtPermissions'),
        children: [
          {
            path: 'type',
            name: 'liste-des-categories',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Category/type',
            ),
          },
          {
            path: 'admin/edit',
            name: 'liste-des-categories-admin',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Category/Admin/edit',
            ),
          },
          {
            path: 'promoteur/edit',
            name: 'liste-des-categories-promoteur',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Category/Promoteur/edit',
            ),
          },
        ],
      },
      {
        path: 'permissions',
        name: 'list-des-permissions-component',
        redirect: 'permissions/type',
        component: page('dashboard/GestionDesRolesEtPermissions'),
        children: [
          {
            path: 'type',
            name: 'list-des-permissions-type',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Permission/type',
            ),
          },
          {
            path: 'promoteur/edit',
            name: 'list-des-permissions-promoteur',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Permission/Promoteur/edit',
            ),
          },
          {
            path: 'admin/edit',
            name: 'list-des-permissions-admin',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Permission/Admin/edit',
            ),
          },
          ,
          {
            path: 'admin/create',
            name: 'list-des-permissions-create-admin',
            meta: {
              middleware: [auth],
              mode: 'create',
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Permission/Admin/create',
            ),
          },
          {
            path: 'promoteur/create',
            name: 'list-des-permissions-create-promoteur',
            meta: {
              middleware: [auth],
              mode: 'create',
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Permission/Promoteur/create',
            ),
          },
          {
            path: ':id/promoteur/edit',
            name: 'list-des-permissions-edit-promoteur',
            meta: {
              middleware: [auth],
              mode: 'edit',
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Permission/Promoteur/create',
            ),
          },
          {
            path: ':id/admin/edit',
            name: 'list-des-permissions-edit-admin',
            meta: {
              middleware: [auth],
              mode: 'edit',
            },
            component: page(
              'dashboard/GestionDesRolesEtPermissions/Permission/Admin/create',
            ),
          },
        ],
      },
    ],
  },
]
