<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="11pt"
    viewBox="0 0 101.963 62.552"
    :class="`fill-current ${color}`"
  >
    <g id="Groupe_2" data-name="Groupe 2" transform="translate(0.025 -0.023)">
      <path
        :class="`stroke-current ${color}`"
        id="Tracé_1"
        data-name="Tracé 1"
        d="M17.3,1.1A11.865,11.865,0,0,0,5.4,13c0,10.3,11,22.1,11,22.1s.4.9.9.9.9-.9.9-.9,11-11.8,11-22.1A11.865,11.865,0,0,0,17.3,1.1ZM17,18.7a5.5,5.5,0,1,1,5.5-5.5A5.422,5.422,0,0,1,17,18.7Z"
        :stroke="color"
        stroke-miterlimit="10"
        stroke-width="2.155"
      ></path>
      <g id="Groupe_1" data-name="Groupe 1">
        <path
          id="Tracé_2"
          data-name="Tracé 2"
          d="M71.1,23.5,51.4,13.7c-.1-.1-.2-.1-.4,0L29.5,24.4,49.9,34.9Z"
          fill="none"
        ></path>
        <path
          id="Tracé_3"
          data-name="Tracé 3"
          d="M27.8,25.2,3.6,37.4a.385.385,0,0,0,0,.7L25,48.3,48.5,35.7Z"
          fill="none"
        ></path>
        <path
          id="Tracé_4"
          data-name="Tracé 4"
          d="M26.3,49,51,60.8a.188.188,0,0,0,.3,0l24-11.5-25.6-13Z"
          fill="none"
        ></path>
        <path
          id="Tracé_5"
          data-name="Tracé 5"
          d="M98.8,37.4,72.4,24.2,51.2,35.6,76.7,48.7l22-10.6C99.1,37.9,99.1,37.5,98.8,37.4Z"
          fill="none"
        ></path>
        <path
          id="Soustraction_1"
          data-name="Soustraction 1"
          d="M-282.85-430.25a.217.217,0,0,1-.15-.075l-50.8-24.4a.353.353,0,0,1-.225-.35.353.353,0,0,1,.225-.35l50.8-25.4a.557.557,0,0,1,.237-.075.219.219,0,0,1,.162.075l25.7,12.875,0,.011.271,1.748-4.866-2.433.8-.4-1.4-.7-.8.4-19.7-9.8a.557.557,0,0,0-.237-.075.219.219,0,0,0-.162.075l-21.5,10.8-1.3-.7-.7,1.3.3.2-24.2,12.1a.353.353,0,0,0-.225.35.353.353,0,0,0,.225.35l21.3,10.2-.8.4,1.4.7.8-.4,24.9,11.9a.217.217,0,0,0,.15.075.217.217,0,0,0,.15-.075l24.194-11.581.607.481,1.3-.7-.8-.4,22-10.6a.517.517,0,0,0,.238-.456.282.282,0,0,0-.138-.244l-10.829-5.414.853-1.186,12.577,6.3a.6.6,0,0,1,.437.556.792.792,0,0,1-.537.744l-50.1,24.1A.217.217,0,0,1-282.85-430.25Z"
          transform="translate(334 492.825)"
        ></path>
        <path
          id="Tracé_7"
          data-name="Tracé 7"
          d="M48.4,35.7,25,48.3l-1.931.113,2.688,1.531L26.3,49,49.8,36.4Z"
        ></path>
        <path
          id="Tracé_8"
          data-name="Tracé 8"
          d="M51.2,35.6,72.4,24.2l1.825-.131-2.641-1.828L71.1,23.5,49.9,34.9Z"
        ></path>
        <path
          id="Tracé_9"
          data-name="Tracé 9"
          d="M49.9,34.9,29.5,24.4l-1.3-.7-1.491,2.088,1.234-.531L48.4,35.7l1.4.7L75.4,49.3l1.028.6,1.438-.781L76.7,48.6l-25.5-13Z"
        ></path>
      </g>
      <path
        :class="`stroke-current ${color}`"
        id="Tracé_10"
        data-name="Tracé 10"
        d="M84.3,1.1A11.865,11.865,0,0,0,72.4,13c0,10.3,11,22.1,11,22.1s.4.9.9.9.9-.9.9-.9,11-11.8,11-22.1A11.865,11.865,0,0,0,84.3,1.1ZM84,17.8a4.5,4.5,0,1,1,4.5-4.5A4.481,4.481,0,0,1,84,17.8Z"
        fill="none"
        stroke-miterlimit="10"
        stroke-width="1.78"
      ></path>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-white',
    },
  },
}
</script>
