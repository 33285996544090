<template>
  <div>
    <component :is="icon_type"></component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      icon_type: '',
      all_type_bien: [
        {
          name: 'Collectif',
          component: 'IconCollectif',
        },
        {
          name: 'Lots à bâtir',
          component: 'IconLotsABatir',
        },
        {
          name: 'Individuel',
          component: 'IconIndividuel',
        },
        {
          name: 'Division avec existant',
          component: 'IconDivisionAvecExistant',
        },
      ],
    }
  },
  props: {
    current_type_bien: {
      type: String,
    },
  },
  created() {
    let find_type = this.all_type_bien.find(
      (type) => type.name === this.current_type_bien,
    )
    this.icon_type = find_type ? find_type.component : ''
  },
}
</script>
