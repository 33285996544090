<template>
  <svg
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="`fill :${color}`"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M490.818 113.54C470.768 101.96 445.028 108.86 433.448 128.91L396.188 193.46V140C396.188 134.48 391.708 130 386.188 130C380.658 130 376.188 134.48 376.188 140V228V228.09L266.358 418.32C265.658 419.52 265.228 420.86 265.078 422.25L257.558 492H20.188V20H376.188V50C376.188 55.52 380.658 60 386.188 60C391.708 60 396.188 55.52 396.188 50V10C396.188 4.48 391.708 0 386.188 0H10.188C4.65799 0 0.187988 4.48 0.187988 10V502C0.187988 507.52 4.65799 512 10.188 512H386.188C391.708 512 396.188 507.52 396.188 502V361.45L506.198 170.91C517.768 150.86 510.878 125.12 490.818 113.54V113.54ZM283.318 439.66L312.148 456.3L278.908 480.58L283.318 439.66ZM376.188 492H297.188L336.348 463.39C337.468 462.57 338.408 461.52 339.108 460.32L376.188 396.1V492ZM326.788 441.66L288.678 419.66L435.048 166.15L473.148 188.15L326.788 441.66ZM488.868 160.91L483.148 170.83L445.048 148.83L450.768 138.91C456.828 128.41 470.318 124.8 480.818 130.86C491.328 136.93 494.938 150.41 488.868 160.91Z"
        fill="white"
      />
      <path
        d="M386.188 85C383.548 85 380.978 86.07 379.118 87.93C377.258 89.79 376.188 92.37 376.188 95C376.188 97.64 377.258 100.21 379.118 102.07C380.978 103.93 383.558 105 386.188 105C388.818 105 391.398 103.93 393.258 102.07C395.118 100.21 396.188 97.63 396.188 95C396.188 92.37 395.118 89.79 393.258 87.93C391.398 86.07 388.828 85 386.188 85Z"
        fill="white"
      />
      <path
        d="M299.188 106H97.188C91.658 106 87.188 110.48 87.188 116C87.188 121.52 91.658 126 97.188 126H299.188C304.708 126 309.188 121.52 309.188 116C309.188 110.48 304.708 106 299.188 106Z"
        fill="white"
      />
      <path
        d="M299.188 162H97.188C91.658 162 87.188 166.48 87.188 172C87.188 177.52 91.658 182 97.188 182H299.188C304.708 182 309.188 177.52 309.188 172C309.188 166.48 304.708 162 299.188 162Z"
        fill="white"
      />
      <path
        d="M299.188 218H224.938C219.408 218 214.938 222.48 214.938 228C214.938 233.52 219.408 238 224.938 238H299.188C304.708 238 309.188 233.52 309.188 228C309.188 222.48 304.708 218 299.188 218Z"
        fill="white"
      />
      <path
        d="M309.188 284C309.188 278.48 304.708 274 299.188 274H97.188C91.658 274 87.188 278.48 87.188 284C87.188 289.52 91.658 294 97.188 294H299.188C304.708 294 309.188 289.52 309.188 284Z"
        fill="white"
      />
      <path
        d="M266.188 340C266.188 334.48 261.708 330 256.188 330H193.688C188.158 330 183.688 334.48 183.688 340C183.688 345.52 188.158 350 193.688 350H256.188C261.708 350 266.188 345.52 266.188 340Z"
        fill="white"
      />
      <path
        d="M97.188 406H185.188C190.708 406 195.188 401.52 195.188 396C195.188 390.48 190.708 386 185.188 386H97.188C91.658 386 87.188 390.48 87.188 396C87.188 401.52 91.658 406 97.188 406V406Z"
        fill="white"
      />
      <path
        d="M97.188 238H163.848C169.378 238 173.848 233.52 173.848 228C173.848 222.48 169.378 218 163.848 218H97.188C91.658 218 87.188 222.48 87.188 228C87.188 233.52 91.658 238 97.188 238V238Z"
        fill="white"
      />
      <path
        d="M97.188 350H148.688C154.208 350 158.688 345.52 158.688 340C158.688 334.48 154.208 330 148.688 330H97.188C91.658 330 87.188 334.48 87.188 340C87.188 345.52 91.658 350 97.188 350V350Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="512" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
}
</script>

<style></style>
