<template>
  <div
    v-click-outside="closeModel"
    :class="open ? 'filter-show bg-white' : 'bg-white filter'"
    class="hidden lg:flex lg:fixed shadow-box lg:top-0 sm:px-10 sm:pb-10 md:px-10 md:pb-10 lg:px-12 sm:w-full md:w-3/7 xl:w-5/24 flex-col h-full flex-auto overflow-y-auto"
    style="z-index: 5555; transition: right 0.3s ease-in-out"
  >
    <div
      @click="closeModel"
      class="sm:mt-6 md:mt-6 lg:mt-6 lg:mb-6 w-full lg:block xl:hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 25 25"
        fill="#47337f"
        class="ml-auto"
      >
        <path
          id="x"
          class="cls-1"
          d="M6359.5,3521a12.5,12.5,0,1,0,12.5,12.5A12.517,12.517,0,0,0,6359.5,3521Zm0,22.78a10.28,10.28,0,1,1,10.28-10.28A10.29,10.29,0,0,1,6359.5,3543.78Zm5.36-15.64a1.1,1.1,0,0,0-1.57,0l-3.79,3.79-3.79-3.79a1.11,1.11,0,0,0-1.57,1.57l3.79,3.79-3.79,3.79a1.11,1.11,0,0,0,1.57,1.57l3.79-3.79,3.79,3.79a1.11,1.11,0,0,0,1.57-1.57l-3.79-3.79,3.79-3.79A1.1,1.1,0,0,0,6364.86,3528.14Z"
          transform="translate(-6347 -3521)"
        />
      </svg>
    </div>
    <div class="px-2 lg:flex lg:flex-col">
      <div class="flex justify-between items-end">
        <p class="text-purple-900 text-xl capitalize font-extrabold font-main">
          filters
        </p>
      </div>
      <div
        class="flex lg:flex-col w-full mt-3 mb-6 text-sm filters-keys text-gray-600"
      >
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'
import ClickOutside from 'vue-click-outside'

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    ClickOutside,
    onClickaway: onClickaway,
  },
  methods: {
    closeModel() {
      this.$emit('closeModel', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  right: -100%;
}

.filter-show {
  right: 0;
}
</style>
