<template>
  <div class="mt-24 section-team-reference">
    <h2 class="text-lg font-bold tracking-wide text-gray-800 font-main">
      Scoring
    </h2>
    <div class="mt-8">
      <div class="flex flex-col">
        <pro-input name="scoring" v-model="form.scoring" label="Scoring" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'form',
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
