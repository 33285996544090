function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}

//layouts
const defaultLayout = () =>
  import(/* webpackChunkName: '' */ '../../layouts/default')

import GestionUtilisateur from './gestionUtilisateur'
import Profile from './profile'
import DossierPromoteur from './dossierPromoteur'
import Parametrage from './parametrage'
import Gestion from './gestion'
import UtilPromoteur from './outilPromoteur'
import TableauBoard from './tableauBoard'
import RevelationPotentiel from './revelationPotentiel'
import gestionRolePermission from './gestionRolePermission'
import delegationMandat from './delegationMandat'
import guichetUnique from './guichetUnique'
import partenaires from './partenaires'
import boiteAoutils from './boiteAoutils'

export default [
  {
    path: '/',
    component: page('dashboard'),
    meta: {
      layout: defaultLayout,
    },
    redirect: '',
    children: [
      ...TableauBoard,
      ...Profile,
      ...GestionUtilisateur,
      ...gestionRolePermission,
      ...Gestion,
      ...UtilPromoteur,
      ...Parametrage,
      ...DossierPromoteur,
      ...RevelationPotentiel,
      ...delegationMandat,
      ...guichetUnique,
      ...partenaires,
      ...boiteAoutils,
    ],
  },
]
