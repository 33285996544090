<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des agents immobilier </page-title>
    <table-title
      nextPath="ajouter"
      class="flex items-center justify-between sm:flex-col sm:items-start sm:space-y-3"
    >
      <template v-slot:title>List des agents immobiliers</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          class="flex sm:flex-col-reverse sm:w-full items-center space-x-3 sm:space-x-0"
        >
          <div class="sm:w-full">
            <pro-input
              class="sm:w-full"
              v-model="search"
              placeholder="Rechercher"
            />
          </div>
          <div
            v-if="
              $can('delete,gestion_utilisateur') ||
              $can('edite,gestion_utilisateur')
            "
            class="sm:w-full text-center px-4 py-2 text-xs font-semibold tracking-wide text-white uppercase transition-colors duration-200 border-2 rounded-full cursor-pointer hover:bg-white hover:text-promy-green-300 border-promy-green-300 bg-promy-green-300 font-main"
            @click.prevent="nextPath('list-des-agents-immobilier-create')"
          >
            ajouter un agent immobilier
          </div>
        </div>
      </template>
    </table-title>

    <hr class="mb-6" />
    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :sortingBy="sorting_by"
          :hasSort="sort.sortKey ? true : false"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <InfiniteScrollContainer
        :isLoading="isFetching"
        :containerScroll="false"
        :fetchingAllowed="last_page > current_page"
        @fetch="getAgentImmobiliers()"
      >
        <pro-table-row
          v-for="(item, index) in agentImmobiliers"
          :key="index"
          class="relative"
        >
          <pro-table-cell label="Nom" class="w-1/3 lg:w-full">
            {{ item.full_name }}
          </pro-table-cell>
          <pro-table-cell label="email" class="w-1/3 lg:w-full">
            <span v-if="$mq === 'sm'">{{
              item.email | truncateTerrain(19)
            }}</span>
            <span v-else>{{ item.email }}</span>
          </pro-table-cell>

          <pro-table-cell :actions_btn="true" class="w-1/3 lg:w-full">
            <div
              class="w-full flex justify-end items-center space-x-3"
              v-if="
                $can('delete,gestion_utilisateur') ||
                $can('edite,gestion_utilisateur')
              "
            >
              <div class="btn-actions" @click="showDeleteModal(item)">
                <i class="fas fa-trash"></i>
              </div>
              <router-link
                :to="{
                  name: 'list-des-agents-immobilier-edit',
                  params: { id: item.id, userId: item.user_id },
                }"
                class="btn-actions"
              >
                <i class="fas fa-edit"></i>
              </router-link>
            </div>
          </pro-table-cell>
        </pro-table-row>
      </InfiniteScrollContainer>
    </pro-card>
    <div
      v-if="!agentImmobiliers.length && !isFetching"
      class="w-full flex justify-center mt-24 text-lg font-main font-extrabold text-promy-gray-650"
    >
      Il n'y a pas d'agent immpbilier pour "{{ search }}"
    </div>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteAgentImmobilier()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer cet équipe ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Nom',
          sortKey: '',
          classes: 'w-1/3',
        },
        {
          title: 'Email',
          sortKey: '',
          classes: 'w-1/3',
        },
      ],
      sorting_by: '',
      agentImmobiliers: [],
      isFetching: false,
      last_page: 1,
      current_page: 0,
      search: '',
      showModal: false,
      team_to_deleted: null,
    }
  },
  watch: {
    search: {
      handler() {
        this.searchAfterDebounce()
      },
    },
  },

  methods: {
    searchAfterDebounce: debounce(function () {
      this.agentImmobiliers = []
      this.current_page = 0
      this.getAgentImmobiliers()
    }, 800),
    getAgentImmobiliers() {
      this.current_page++
      this.isFetching = true
      this.$http
        .get(`admin/agent-immobiliers`, {
          params: {
            page: this.currentPage,
            search: this.search,
          },
        })
        .then((res) => {
          this.isFetching = false
          this.agentImmobiliers.push(...res.data.data)
          this.lastPage = res.data.last_page
          this.currentPage = res.data.current_page
        })
    },
    showDeleteModal(item) {
      this.showModal = true
      this.team_to_deleted = item
    },
    deleteAgentImmobilier() {
      this.$http
        .delete(`admin/agent-immobiliers/${this.team_to_deleted.id}`)
        .then(() => {
          this.agentImmobiliers.splice(
            this.agentImmobiliers.indexOf(this.team_to_deleted),
            1,
          )
          this.team_to_deleted = null
          this.showModal = false
        })
    },
  },
}
</script>
