var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-full sm:w-full md:w-2/3 lg:w-1/3 xl:w-1/3"},[_c('div',{staticClass:"flex items-center"},[_c('h2',{staticClass:"title-components"},[_vm._v("Descriptif")]),_c('div',{staticClass:"pl-2 flex items-center justify-center"},[(!_vm.form.descriptifProjet.show_description_remarque)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: 'Affichez',
            classes: _vm.classes,
          }),expression:"{\n            content: 'Affichez',\n            classes: classes,\n          }",modifiers:{"top":true}}],attrs:{"src":"/images/eye-off-outline.svg"},on:{"click":function($event){_vm.form.descriptifProjet.show_description_remarque = true}}}):_vm._e(),(_vm.form.descriptifProjet.show_description_remarque)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: 'Masquez',
            classes: _vm.classes,
          }),expression:"{\n            content: 'Masquez',\n            classes: classes,\n          }",modifiers:{"top":true}}],attrs:{"src":"/images/eye-outline.svg"},on:{"click":function($event){_vm.form.descriptifProjet.show_description_remarque = false}}}):_vm._e()])]),_c('quill-editor',{ref:"myQuillEditor",staticClass:"mb-7 sm:mb-2",model:{value:(_vm.form.descriptifProjet.description_remarque),callback:function ($$v) {_vm.$set(_vm.form.descriptifProjet, "description_remarque", $$v)},expression:"form.descriptifProjet.description_remarque"}}),_c('HighlightMessage',{attrs:{"classes":"mb-10","show":_vm.isEmptyOrNull(_vm.form.descriptifProjet.description_remarque) &&
        _vm.form.descriptifProjet.show_description_remarque}})],1),_c('div',{staticClass:"my-6"},[_c('table-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Les documents de description de projet (image / pdf) ")]},proxy:true}])}),_c('div',[_c('div',{staticClass:"w-50 h-33 relative border border-dashed border-promy-gray-400 rounded flex items-center justify-center mt-2"},[_vm._m(0),_c('input',{ref:"fileinput",staticClass:"w-full absolute opacity-0 h-full inset-0 cursor-pointer",attrs:{"type":"file"},on:{"change":function($event){$event.preventDefault();return _vm.handleFileChange($event)}}})])]),_c('div',{staticClass:"flex flex-col w-2/3 sm:w-full md:w-2/3 mt-6"},[_vm._m(1),(_vm.form.descriptifProjet.documents_descriptif.length)?_vm._l((_vm.form.descriptifProjet
            .documents_descriptif),function(pieceEcrite,index){return _c('div',{key:index,staticClass:"flex items-center bg-white py-3 border-t odd:bg-promy-gray-200"},[_c('div',{staticClass:"w-1/4 px-2"},[(!pieceEcrite.editText)?_c('p',{staticClass:"text-sm font-extrabold capitalize text-center cursor-pointer text-promy-green-300 underline"},[(pieceEcrite.type === 'pdf')?_c('span',{on:{"click":function($event){return _vm.openPdfNewWindow(pieceEcrite.value)}}},[_vm._v(" "+_vm._s(pieceEcrite.name)+" ")]):_c('span',{on:{"click":function($event){return _vm.openImagePreview(pieceEcrite.value)}}},[_vm._v(" "+_vm._s(pieceEcrite.name)+" ")])]):(pieceEcrite.editText)?_c('pro-input',{attrs:{"requirements":"required","name":"Nom fichier"},model:{value:(pieceEcrite.name),callback:function ($$v) {_vm.$set(pieceEcrite, "name", $$v)},expression:"pieceEcrite.name"}}):_vm._e()],1),_c('div',{staticClass:"w-1/4 px-2"},[(!pieceEcrite.editText)?_c('p',{staticClass:"text-sm text-gray-700 capitalize text-center"},[_vm._v(" "+_vm._s(pieceEcrite.date)+" ")]):(pieceEcrite.editText)?_c('datepicker',{staticClass:"date-input",attrs:{"valueType":"DD-MM-YYYY","format":"DD-MM-YYYY"},model:{value:(pieceEcrite.date),callback:function ($$v) {_vm.$set(pieceEcrite, "date", $$v)},expression:"pieceEcrite.date"}}):_vm._e()],1),_c('div',{staticClass:"w-1/4 flex justify-center items-start"},[_c('p',{staticClass:"text-sm text-gray-700 capitalize text-center"},[_vm._v(" "+_vm._s(pieceEcrite.type)+" ")])]),_c('div',{staticClass:"w-1/4 flex justify-end pr-4"},[_c('div',{staticClass:"rounded-full p-2 mr-3 cursor-pointer",class:pieceEcrite.editText
                  ? 'bg-white border-promy-green-300 border-2'
                  : 'bg-promy-green-300',on:{"click":function($event){!pieceEcrite.editText
                  ? _vm.showFileUpdate(pieceEcrite, index)
                  : _vm.updateFile(pieceEcrite, index)}}},[_c('icon-update',{staticClass:"w-4 h-4",attrs:{"color":pieceEcrite.editText ? 'text-promy-green-300' : 'text-white'}})],1),(pieceEcrite.isUploaded)?_c('div',{staticClass:"bg-promy-green-300 rounded-full p-2 mr-3 cursor-pointer flex items-center justify-center",on:{"click":function($event){return _vm.displayModal(index)}}},[_c('img',{staticClass:"w-4 h-4",attrs:{"src":"/images/trash.svg","alt":""}})]):_vm._e()])])}):[_c('div',{staticClass:"text-center mt-5 text-base tracking-wide font-main text-gray-800 font-bold"},[_vm._v(" La liste est vide ")])]],2)],1),(_vm.showModal)?_c('ValidateModal',{on:{"isCancel":function($event){_vm.showModal = false},"isValide":function($event){return _vm.deleteFile()}}},[_vm._t("default",[_vm._v("Êtes-vous sûr de vouloir supprimer ce pdf ?")],{"slot":"message_body"}),_vm._t("default",[_vm._v("Continuer")],{"slot":"button_valide"}),_vm._t("default",[_vm._v("Annuler")],{"slot":"button_cancel"})],2):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-promy-gray-400 text-3xl"},[_c('i',{staticClass:"fa fa-upload fa-6",attrs:{"aria-hidden":"true"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex bg-promy-gray-200 py-2 rounded-t-lg"},[_c('div',{staticClass:"w-1/4"},[_c('p',{staticClass:"text-sm text-gray-700 capitalize text-center"},[_vm._v(" nom de fichier ")])]),_c('div',{staticClass:"w-1/4"},[_c('p',{staticClass:"text-sm text-gray-700 capitalize text-center"},[_vm._v(" Dernière mise à jour ")])]),_c('div',{staticClass:"w-1/4"},[_c('p',{staticClass:"text-sm text-gray-700 capitalize text-center"},[_vm._v("type")])])])}]

export { render, staticRenderFns }