<template>
  <validation-provider
    class="flex flex-col my-2"
    :rules="rules"
    v-slot="{ errors }"
  >
    <label
      v-if="label"
      class="capitalize text-xs text-gray-600 font-bold font-arial"
    >
      {{ label }} <span v-if="label_value">{{ label_value }}</span>
    </label>
    <input
      :name="name ? name : label"
      :placeholder="placeholder ? placeholder : label"
      v-model="local_data"
      :disabled="disabled"
      class="text-sm text-gray-600 mt-1"
      :class="[
        errors[0] || !is_valid ? 'input-error' : '',
        !clearBG ? 'bg-promy-gray-background-300' : 'bg-white',
        classes,
      ]"
      autocomplete="off"
    />
    <span v-show="local_data && !is_valid" class="msg-error">
      Numéro invalide
    </span>
    <span v-show="errors[0]" class="msg-error">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    label_value: {
      type: String,
    },
    classes: {
      type: String,
      default: 'py-2',
    },
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    rules: {
      type: String,
    },
    clearBG: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_valid: true,
      local_data: '',
      countries: [
        { name: 'FR', code: '+33' },
        { name: 'MA', code: '+212' },
      ],
      country: null,
    }
  },
  async mounted() {
    try {
      this.country = this.countries.find(
        (el) => el.name === this.country_code.split(';')[1],
      )
    } catch {
      this.country = { name: 'FR', code: '+33' }
    }
  },
  watch: {
    local_data: {
      immediate: true,
      handler(value) {
        value = value.replace(/[\s-._()]/g, '')
        this.$emit('input', value)
        if (!value || !this.country) {
          this.is_valid = true
          return
        }
        if (value.split('')[0] != '+' && value) {
          this.addCodeCountry(value)
        }
        this.is_valid = this.checkNumber(value)
      },
    },
    value: {
      immediate: true,
      handler(value) {
        this.local_data = value
      },
    },
  },
  methods: {
    addCodeCountry(value) {
      this.local_data = this.country.code + '' + value
    },
    checkNumber(value) {
      if (value.slice(0, this.country.code.length) === this.country.code) {
        let number = value.slice(this.country.code.length)
        if (!this.isNumeric(number)) return false
        if (
          (number.split('')[0] == 0 && number.length == 10) ||
          (number.split('')[0] != 0 && number.length == 9)
        )
          return true
      }
      return false
    },
    isNumeric(value) {
      return /^-?\d+$/.test(value)
    },
  },
  computed: {
    ...mapGetters({
      country_code: 'auth/countryCode',
    }),
  },
}
</script>

<style lang="scss" scoped>
input {
  @apply rounded-full px-4 text-promy-gray-600 border-solid border  w-full border-promy-gray-300;
}
input:focus {
  @apply outline-none border-promy-green-200;
}
input[disabled] {
  @apply border-promy-gray-300 bg-promy-gray-300;
}
.msg-error {
  @apply rounded-lg w-full pt-1 text-sm text-promy-red;
}
.input-error {
  @apply border-promy-red;
}
.padding-unit {
  padding-right: 2.8rem !important;
}
</style>
