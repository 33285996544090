<template>
  <div
    class="modal-mask flex flex-col"
    :class="classes_root_container"
    v-if="showModal || disable_remove_popup"
  >
    <div
      class="modal-wrapper"
      :class="[classes, !is_header_outside_box ? 'shadow_modal' : '']"
    >
      <div class="flex justify-between mb-4" v-if="is_header_outside_box">
        <span
          class="text-xl font-extrabold sm:text-base text-promy-primary font-main"
        >
          {{ title }}
        </span>
        <svg
          @click="close"
          class="cursor-pointer"
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5 26C20.4036 26 26 20.4036 26 13.5C26 6.59644 20.4036 1 13.5 1C6.59644 1 1 6.59644 1 13.5C1 20.4036 6.59644 26 13.5 26Z"
            class="stroke-current text-promy-primary"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.25 9.75L9.75 17.25"
            class="stroke-current text-promy-primary"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.75 9.75L17.25 17.25"
            class="stroke-current text-promy-primary"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        class="flex-col flex modal-container relative"
        :class="[classes_container, is_header_outside_box ? ' rounded-lg' : '']"
      >
        <svg
          v-if="!is_header_outside_box"
          @click.stop="close"
          class="cursor-pointer absolute right-0 mr-6 top-0 mt-6"
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5 26C20.4036 26 26 20.4036 26 13.5C26 6.59644 20.4036 1 13.5 1C6.59644 1 1 6.59644 1 13.5C1 20.4036 6.59644 26 13.5 26Z"
            class="stroke-current text-promy-primary"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.25 9.75L9.75 17.25"
            class="stroke-current text-promy-primary"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.75 9.75L17.25 17.25"
            class="stroke-current text-promy-primary"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <slot></slot>
      </div>
      <slot name="action_outside_box"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    classes: {
      type: String,
      default: 'w-fit',
    },
    is_header_outside_box: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    classes_container: {
      type: String,
      default: 'py-8 px-10',
    },
    classes_root_container: {
      type: String,
      default: 'sm:px-2',
    },
    disable_remove_popup: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('closeModel')
    },
  },
}
</script>
<style lang="scss" scoped>
.shadow_modal {
  box-shadow: 1px 0px 21px 3px rgb(0 0 0 / 6%);
}
.modal-mask {
  @apply fixed top-0 left-0 w-full h-full  flex justify-center items-center mt-0  bg-promy-modal-mask-color;
  z-index: 9998;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    @apply rounded-box;
    display: table-cell;
    vertical-align: middle;
    .modal-container {
      @apply bg-white m-auto shadow-box;
      transition: all 0.1s ease;
    }
  }
  .w-fit {
    width: fit-content;
  }
}
</style>
