<template>
  <div class="font-sans-Arial">
    <h3 class="mt-13 pl-7 font-bold text-promy-blue text-xl">
      Outil Recherche Foncière
    </h3>
    <div class="container w-full mt-10">
      <div class="bg-white w-4/24 h-18 p-9/2 rounded-7/8">
        <div class="space-x-4 p-11/16">
          <div class="inline-block pl-1 pt-2">
            <input
              id="streets-v11"
              type="radio"
              name="rtoggle"
              value="streets"
              v-on:change="initMapView('streets-v11')"
              checked="checked"
            />
            <label for="streets-v11" class="pt-7/8 text-promy-blue">
              streets
            </label>
          </div>
          <div class="inline-block pl-1 pl-8">
            <input
              id="satellite-v9"
              type="radio"
              name="rtoggle"
              value="satellite"
              v-on:change="initMapView('satellite-v9')"
            />
            <label for="satellite-v9" class="pt-7/8 text-promy-blue">
              satellite
            </label>
          </div>
        </div>
      </div>
      <div class="flex space-x-4">
        <div class="block-1 w-4/5">
          <div id="map" class="h-148"></div>
        </div>
        <div class="flex-1 h-100 w-2/12">
          <div
            class="bg-white h-15 hidden text-promy-blue text-sm w-4/5"
            id="calculated-line"
          ></div>
          <div
            class="bg-white h-15 hidden text-promy-blue text-sm mt-5/8 w-4/5"
            id="calculated-area"
          ></div>
          <div
            class="form-check mt-5/8 w-4/5"
            v-for="layer in layers"
            :key="layer.id"
          >
            <div class="flow-root bg-white rounded">
              <div
                class="my-2 block text-gray-700 text-cente px-4 py-2"
                v-bind:style="style(layer.color)"
              >
                <input
                  type="checkbox"
                  class="form-checkbox inline visible"
                  v-model="layer.active"
                  @change="layerChanged(layer.id, layer.active)"
                />
                <span class="ml-3 text-sm inline-block">
                  {{ layer.name }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="my-2 block text-gray-700 text-cente px-4 py-2 bg-white h-15 pt-9/2 mt-5/8 w-4/5"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              @change="addLayer3D(vue3D)"
            />
            <span class="ml-3 text-sm inline-block"> &nbsp; &nbsp; vue3D </span>
          </div>
          <button
            class="mt-7 font-main font-extrabold bg-promy-blue text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <svg
              class="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
            </svg>
            <span @click="getCadastre"
              >Téléchargement <br />
              plan cadastral</span
            >
          </button>
        </div>
      </div>
    </div>

    <hr class="h-1/2 bg-promy-blue w-full" />

    <div class="grid gap-2 grid-cols-2 text-promy-blue text-lg mt-24 ml-5">
      <div class="text-base">
        <h3 class="font-bold text-promy-blue text-xl">Description :</h3>
        <br />
        En phase avec les besoins des célibataires, des jeunes couples ou des
        familles, venez découvrir votre future maison 3 pièces. L'intérieur se
        dessine au gré de volumes bien pensés, mettant à profit le moindre mètre
        carré habitable. Les pièces sont généreusement baignées de clarté, à
        l'image de la cuisine ouverte sur le séjour dans un esprit de
        convivialité. L'orientation a été réfléchie pour tirer avantage de la
        lumière naturelle et des apports solaires gratuits : gages d'un confort
        thermique optimal en toutes saisons et d'une facture énergétique
        allégée. La décoration est sobre et actuelle, aisément personnalisable.
        Les pièces de jour sont séparées de l'espace nuit pour respecter les
        besoins d'intimité de chacun. Tout un univers de bien-être dont profiter
        jour après jour ! Profitez, par ailleurs, de l'agrément d'un jardin
        privatif clos venant prolonger l'horizon du séjour. Dissimulés derrière
        des haies de Fusains et de Charmilles, ce sont de véritables havres de
        quiétude pour les plus grands ainsi que des espaces de jeux rêvés pour
        les plus petits. Réseau Immobilier Neuf Selectneuf. Votre Conseiller
        Immobilier Neuf / Zouhaier GHERIBI Pour plus d'infos, Merci de me
        contacter AU 06.50.06.03.91
      </div>
      <div v-if="biens.nbImage">
        <img :src="biens.image" class="h-100 ml-1/10" />
      </div>
      <div v-else>
        <carousel :per-page="1" :mouse-drag="false">
          <slide v-for="(item, index) in biens.image" :key="index">
            <img
              :src="item"
              alt="https://media.immo-facile.com/office12/import_leemo_poliris/catalog/images/pr_p/2/8/8/9/7/9/6/2/28897962a.jpg?DATEMAJ=04/11/2018-12:46:04"
              class="h-100 ml-1/10 w-9/10"
            />
          </slide>
        </carousel>
      </div>
    </div>

    <div class="flex bg-gray-200 mt-13">
      <h3 class="text-promy-blue mt-9 ml-164 font-bold text-xl">
        {{ this.biens.adresse }}
      </h3>
    </div>

    <div class="flex bg-gray-200">
      <div class="flex-1 px-4 py-2 m-2">
        <span class="inline-grid grid-cols-3 gap-x-30">
          <span
            class="text-white bg-promy-bleu-200 rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Type de mandat : <br />
            <span class="text-4xl float-right">
              {{ this.biens.type_de_mandat }}</span
            ></span
          >
          <span
            class="text-white bg-promy-bleu-200 rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Prix : <br /><span class="value">
              {{ this.biens.prix }} $</span
            ></span
          >
          <span
            class="text-white bg-promy-bleu-200 rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Loyer : <br />
            <span class="text-4xl float-right"
              >{{ this.biens.loyer }}
            </span></span
          >
          <span
            class="text-white bg-promy-bleu-200 rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Garantie de vente : <br />
            <span class="text-4xl float-right">{{
              this.biens.garentie_vente
            }}</span></span
          >
          <span
            class="text-white bg-promy-bleu-200 rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Viager : <br />
            <span class="text-4xl float-right">{{
              this.biens.viager
            }}</span></span
          >
          <span
            class="text-white bg-promy-bleu-200 rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            SS Type : <br /><span class="text-4xl float-right">
              {{ this.biens.ssType }}</span
            ></span
          >
        </span>
      </div>

      <div class="flex-1 px-4 py-2 m-2 ml-20">
        <span class="inline-grid grid-cols-2 gap-x-20">
          <span
            class="text-white bg-promy-yellow rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Année de construction : <br />
            <span class="text-4xl float-right">{{
              this.biens.annee_construction
            }}</span></span
          >
          <span
            class="text-white bg-promy-yellow rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Gaz à effet de serre : <br /><span class="text-4xl float-right">{{
              this.biens.gaz_effet_serre
            }}</span></span
          >
          <span
            class="text-white bg-promy-yellow rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Consommation Energetique : <br /><span class="text-4xl float-right">
              {{ this.biens.consommation_Energetique }}</span
            ></span
          >
          <span
            class="text-white bg-promy-yellow rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Valeur GES : <br />
            <span class="text-4xl float-right">
              {{ this.biens.valeurGes }}</span
            ></span
          >
        </span>
      </div>
    </div>

    <div class="flex bg-gray-200">
      <div class="flex-1 px-4 py-2 m-2">
        <span class="inline-grid grid-cols-3 gap-x-30">
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Nombre de piece : <br /><span class="text-4xl float-right">
              {{ this.biens.nbPiece }}</span
            ></span
          >
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Nombre de chambre : <br />
            <span class="text-4xl float-right">{{
              this.biens.nbChambre
            }}</span></span
          >
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Surface Habitable : <br /><span class="text-4xl float-right">
              {{ this.biens.surfaceHabitable }}</span
            ></span
          >
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Surface sejour : <br /><span class="text-4xl float-right">{{
              this.biens.surfaceSejour
            }}</span></span
          >
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Surface terrain : <br />
            <span class="text-4xl float-right">{{
              this.biens.surfaceTerrrain
            }}</span></span
          >
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Nombre de niveaux : <br /><span class="text-4xl float-right">
              {{ this.biens.nbNiveau }}</span
            ></span
          >
        </span>
      </div>

      <div class="flex-1 px-4 py-2 m-2 ml-20">
        <span class="inline-grid grid-cols-2 gap-x-20">
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Nombre de salle de bain :<br /><span class="text-4xl float-right">
              {{ this.biens.nbSalleBien }}</span
            ></span
          >
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Nombre de WC : <br />
            <span class="text-4xl float-right">{{
              this.biens.nbWC
            }}</span></span
          >
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Nombre de garage : <br /><span class="text-4xl float-right">{{
              this.biens.nbGarage
            }}</span></span
          >
          <span
            class="text-white bg-promy-blue rounded-xl text-xs font-bold mt-2 pt-3 h-24 w-60 p-5/8"
          >
            Region : <br /><span class="text-base float-right mt-5">
              {{ this.biens.region }}</span
            >
          </span>
        </span>
      </div>
    </div>

    <div class="grid gap-2 grid-cols-2 mt-13 ml-56">
      <button
        @click="
          opened = visible = true
          setTrue('true')
        "
        class="rounded-full w-56 bg-promy-green-200 hover:bg-promy-green-50 border-promy-green-200 py-2"
      >
        <p class="uppercase test-sm text-white font-main font-bold">Oui</p>
      </button>
      <button
        @click="
          opened = visible = true
          setTrue('false')
        "
        class="rounded-full w-56 bg-promy-red hover:bg-promy-red-100 border-promy-green-200 py-2"
      >
        <p class="uppercase test-sm text-white font-main font-bold">Non</p>
      </button>
    </div>
    <div class="mt-26">
      <overlay
        :opened="opened"
        :visible="visible"
        @closed="opened = visible = false"
      >
        <div>
          <span class="text-promy-blue text-lg"
            >Merci de votre réponse veuillez expliquer votre choix avec le plus
            détaille possible:</span
          >
          <p id="checkMessage" class="text-promy-blue mt-4"></p>
          <textarea
            id="valueText"
            v-model="message"
            class="rounded-2xl border-2 border-double border-promy-blue w-full mt-8 h-40"
          ></textarea>
          <button
            @click="traitementReponse()"
            class="ml-64 font-main bg-promy-blue text-white font-bold py-2 px-4 rounded inline-flex mt-3"
          >
            <span> Validation de la réponse</span>
          </button>
        </div>
      </overlay>
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import Mapbox from 'mapbox-gl-vue'
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: {
    Carousel,
    Slide,
    Mapbox,
  },

  data() {
    return {
      message: '',
      opened: false,
      visible: false,
      accessToken:
        'pk.eyJ1Ijoib21hcmJha3JpbSIsImEiOiJjazVoOTlmcXowMWIwM29veTE2eDU5eDY5In0.WPc0yc-gsnu8aU4fDzVYww',
      text: '',
      vue3D: false,
      tabSourceLine: [],
      tabSourcePolygon: [],
      biens: {
        accepte: null,
        idBien: null,
        adresse: 'Inconnue',
        image: null,
        type_de_mandat: 'Inconnue',
        prix: 'Inconnue',
        loyer: 'Inconnue',
        nbImage: null,
        annee_construction: 'Inconnue',
        gaz_effet_serre: 'Inconnue',
        consommation_Energetique: 'Inconnue',
        garentie_vente: 'Inconnue',
        viager: 'Inconnue',
        ssType: 'Inconnue',
        cae: 'Inconnue',
        valeurGes: 'Inconnue',
        region: 'Inconnue',
        nbPiece: 'Inconnue',
        nbChambre: 'Inconnue',
        surfaceHabitable: 'Inconnue',
        nbSalleBien: 'Inconnue',
        nbWC: 'Inconnue',
        nbGarage: 'Inconnue',
        surfaceSejour: 'Inconnue',
        surfaceTerrrain: 'Inconnue',
        nbNiveau: 'Inconnue',
        nbTerasse: 'Inconnue',
        accesHandicape: 'Inconnue',
        alarme: 'Inconnue',
      },
      draw: null,
      addresses: [],
      map: null,
      tileLayer: null,
      layers: [
        {
          id: 30,
          name: 'Espace vert',
          active: false,
          color: '#3CDE3C',
          features: [],
        },
        {
          id: 6,
          name: 'Cadastre',
          color: '#5B106B',
          active: false,
          features: [],
        },
        // {
        //   id: 1,
        //   name: "PLU",
        //   active: false,
        //   color: "#53C4E0",
        //   features: [
        //     {
        //       id: 0,
        //       name: "PLU",
        //       type: "polygon",
        //       color: "#53C4E0",
        //       coords: [
        //         [4.7989347, 45.6396956],
        //         [4.7986644, 45.639947],
        //         [4.7983387, 45.6397747],
        //         [4.7983237, 45.6397106],
        //         [4.798492, 45.6395063],
        //         [4.7989347, 45.6396956],
        //       ],
        //     },
        //   ],
        // }
        // {
        //   id: 2,
        //   name: "Prescription urbanisme",
        //   active: false,
        //   color: "#FFB624",
        //   features: [
        //     {
        //       id: 0,
        //       name: "Prescription urbanisme",
        //       type: "polygon",
        //       color: "#5B106B",
        //       coords: [
        //         [4.7989347, 45.6396956],
        //         [4.7986644, 45.639947],
        //         [4.7983387, 45.6397747],
        //         [4.7983237, 45.6397106],
        //         [4.798492, 45.6395063],
        //         [4.7989347, 45.6396956],
        //       ],
        //     },
        //   ],
        // },
        // {
        //   id: 3,
        //   name: "Batiment ABF",
        //   active: false,
        //   color: "#33628F",
        //   features: [
        //     {
        //       id: 0,
        //       name: "Batiment ABF",
        //       type: "polygon",
        //       color: "#33628F",
        //       coords: [
        //         [4.7989347, 45.6396956],
        //         [4.7986644, 45.639947],
        //         [4.7983387, 45.6397747],
        //         [4.7983237, 45.6397106],
        //         [4.798492, 45.6395063],
        //         [4.7989347, 45.6396956],
        //       ],
        //     },
        //   ],
        // },
      ],
    }
  },
  created() {
    this.getPige()
  },
  methods: {
    setTrue(info) {
      this.biens.accepte = info
    },
    traitementReponse() {
      if (this.message == '') {
        var answer = document.getElementById('checkMessage')
        answer.innerHTML = 'Veuillez rentrer une description'
      } else {
        console.log(this.message)
        this.$http
          .post('pige/acceptation', {
            id: this.biens.idBien,
            accepte: this.biens.accepte,
            description: this.message,
          })
          .then((response) => {
            this.onClosed()
            location.reload()
          })
      }
    },
    style(k) {
      return {
        color: k,
      }
    },
    addLayer3D(active) {
      if (!active) {
        var layers = this.map.getStyle().layers
        var labelLayerId
        for (var i = 0; i < layers.length; i++) {
          if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
            labelLayerId = layers[i].id
            break
          }
        }
        this.map.addLayer(
          {
            id: '3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 15,
            paint: {
              'fill-extrusion-color': '#aaa',

              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height'],
              ],
              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height'],
              ],
              'fill-extrusion-opacity': 0.6,
            },
          },
          labelLayerId,
        )
        this.vue3D = true
      } else {
        this.map.removeLayer('3d-buildings')
        this.vue3D = false
      }
    },
    getPige() {
      this.$http.get('pige', {}).then((response) => {
        this.setInfoPige(response)
      })
    },
    setInfoPige(response) {
      var newBiens = response.data.resultat.data[0]
      console.log('LOLOLOLO')
      console.log(newBiens)
      if (newBiens.VENTE) {
        this.biens.prix = newBiens.VENTE.PRIX
      }
      if (newBiens.LOCALISATION) {
        this.biens.adresse =
          newBiens.LOCALISATION.ADRESSE +
          ' ' +
          newBiens.LOCALISATION.CODE_POSTAL
      }
      if (newBiens.INFOS_BIEN.SURFACE_SEJOUR) {
        this.biens.surfaceSejour = newBiens.INFOS_BIEN.SURFACE_SEJOUR
      }
      if (newBiens.INFOS_BIEN.SURFACE_HABITABLE) {
        this.biens.surfaceHabitable = newBiens.INFOS_BIEN.SURFACE_HABITABLE
      }
      if (newBiens.INFOS_BIEN.SURFACE_TERRAIN) {
        this.biens.surfaceTerrrain = newBiens.INFOS_BIEN.SURFACE_TERRAIN
      }
      if (newBiens.informationsCadastre) {
        this.biens.region = newBiens.informationsCadastre.informations.region
      }
      if (newBiens.VIAGER) {
        this.biens.viager = newBiens.VIAGER.EN_VIAGER
      }
      if (newBiens.INFOS_BIEN.SS_TYPE) {
        this.biens.ssType = newBiens.INFOS_BIEN.SS_TYPE
      }
      if (newBiens.VENTE) {
        this.biens.type_de_mandat = newBiens.VENTE.TYPE_MANDAT
      }
      if (newBiens.INFOS_BIEN.ANNEE_CONSTRUCTION) {
        this.biens.annee_construction = newBiens.INFOS_BIEN.ANNEE_CONSTRUCTION
      }
      if (newBiens.INFOS_BIEN.GAZEFFETDESERRE) {
        this.biens.gaz_effet_serre = newBiens.INFOS_BIEN.GAZEFFETDESERRE
      }
      if (newBiens.INFOS_BIEN.CONSOMMATIONENERGETIQUE) {
        this.biens.consommation_Energetique =
          newBiens.INFOS_BIEN.CONSOMMATIONENERGETIQUE
      }
      if (newBiens.INFOS_BIEN.NBRE_PIECES) {
        this.biens.nbPiece = newBiens.INFOS_BIEN.NBRE_PIECES
      }
      if (newBiens.INFOS_BIEN.NBRE_CHAMBRES) {
        this.biens.nbChambre = newBiens.INFOS_BIEN.NBRE_CHAMBRES
      }
      if (newBiens.INFOS_BIEN.NBRE_SALLE_BAIN) {
        this.biens.nbSalleBien = newBiens.INFOS_BIEN.NBRE_SALLE_BAIN
      }
      if (newBiens.INFOS_BIEN.NBRE_WC) {
        this.biens.nbWC = newBiens.INFOS_BIEN.NBRE_WC
      }
      if (newBiens.INFOS_BIEN.NBRE_NIVEAUX) {
        this.biens.nbNiveau = newBiens.INFOS_BIEN.NBRE_NIVEAUX
      }
      if (newBiens.INFOS_BIEN.NBRE_GARAGE) {
        this.biens.nbGarage = newBiens.INFOS_BIEN.NBRE_GARAGE
      }
      if (newBiens.INFOS_BIEN.VALEUR_GES) {
        this.biens.valeurGes = newBiens.INFOS_BIEN.VALEUR_GES
      }
      if (newBiens.LOCATION) {
        this.biens.loyer = newBiens.LOCATION.LOYER
      }
      this.biens.idBien = newBiens.id
      if (newBiens.IMAGES.IMG != null) {
        this.biens.image = newBiens.IMAGES.IMG
        console.log("ICI c'est les image")
        console.log(this.biens.image)
        if (this.biens.image.length == 1) {
          this.biens.nbImage == false
        } else {
          this.biens.nbImage == true
        }
      }
      this.getParcelleCadastre([
        newBiens.LOCALISATION.LATITUDE,
        newBiens.LOCALISATION.LONGITUDE,
      ])
    },
    getParcelleCadastre(geoloc) {
      this.$http
        .get('cadastre', {
          params: {
            lat: geoloc[0],
            lng: geoloc[1],
          },
        })
        .then((response) => {
          this.setParcelleCadastre(response, geoloc)
        })
    },
    setParcelleCadastre(response, geoloc) {
      response.data.geospatial_autour.features.forEach((item, i) => {
        var newFeature = {
          id: i,
          name: item.parcelle.id,
          type: 'polygon',
          color: '#5B106B',
          text:
            item.parcelle.properties.numero +
            ' ' +
            item.parcelle.properties.section,
          coords: item.parcelle.geometry.coordinates,
        }
        this.layers[1].features.push(newFeature)
      })
      this.initMap(geoloc)
    },
    getCadastre() {
      this.$http
        .get('url_api_map_snapshot', {
          params: {
            code_insee: 69046,
            lat: 45.6398191,
            lng: 4.7988018,
            echelle: '1/1000',
            size: 'A4',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'Cadastre.pdf'
          link.click()
        })
    },
    layerChanged(layerId, active) {
      const layer = this.layers.find((layer) => layer.id === layerId)
      layer.features.forEach((feature) => {
        if (active) {
          this.map.addLayer({
            id: feature.name,
            type: 'fill',
            source: feature.name,
            paint: {
              'fill-color': feature.color,
              'fill-opacity': 0.2,
            },
          })

          this.map.addLayer({
            id: feature.name + 'value',
            type: 'symbol',
            source: feature.name,
            layout: {
              'text-field': feature.text,
              'text-font': ['Arial Unicode MS Bold', 'DIN Offc Pro Medium'],
              'text-size': 12,
            },
          })
        } else {
          this.map.removeLayer(feature.name)
          this.map.removeLayer(feature.name + 'value')
        }
      })
    },
    initLayers() {
      this.layers.forEach((layer) => {
        const polygonFeatures = layer.features.filter(
          (feature) => feature.type === 'polygon',
        )
        polygonFeatures.forEach((feature) => {
          this.map.on('load', () => {
            this.map.addSource(feature.name, {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: feature.coords,
                },
              },
            })
          })
        })
      })
    },
    initMapView(styleView) {
      this.map.remove()
      mapboxgl.accessToken = this.accessToken
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/' + styleView,
        center: [4.7989347, 45.6396956],
        zoom: 18,
      })

      this.draw = new MapboxDraw({
        displayControlsDefault: true,
        controls: {
          polygon: true,
          trash: true,
        },
        paint: {
          'fill-color': ['#ff0000'],
          'fill-outline-color': '#3bb2d0',
          'fill-opacity': 0.5,
        },
      })

      this.map.addControl(this.draw)
      this.map.on('draw.create', this.updateArea)
      this.map.on('draw.delete', this.updateArea)
      this.map.on('draw.update', this.updateArea)

      this.initLayers()
    },
    initMap(geoloc) {
      mapboxgl.accessToken = this.accessToken

      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [geoloc[1], geoloc[0]],
        zoom: 18,
      })

      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          line_string: true,
          polygon: true,
          trash: true,
        },
      })

      this.map.addControl(new mapboxgl.NavigationControl())
      this.map.addControl(this.draw)
      this.map.on('draw.create', this.updateArea)
      this.map.on('draw.delete', this.updateArea)
      this.map.on('draw.update', this.updateArea)
      this.initLayers()
    },
    clearSourceLayer(source) {
      source.forEach((item, i) => {
        this.map.removeLayer(this.map.style.sourceCaches[item].id)
        this.map.removeSource(this.map.style.sourceCaches[item].id)
      })
    },
    updateArea(e) {
      this.clearSourceLayer(this.tabSourceLine)
      this.clearSourceLayer(this.tabSourcePolygon)

      var data = this.draw.getAll()
      var answer = document.getElementById('calculated-area')
      var answerLine = document.getElementById('calculated-line')
      if (data.features.length > 0) {
        this.tabSourceLine = []
        this.tabSourcePolygon = []
        var nbPolygon = 0
        var nbLine = 0
        answer.innerHTML = ''
        answerLine.innerHTML = ''
        data.features.forEach((item, i) => {
          if (item.geometry.type == 'Polygon') {
            var area = turf.area(data)
            var polygon = turf.polygon(item.geometry.coordinates)
            var centroid = turf.centroid(polygon)
            nbPolygon++
            var rounded_area = Math.round(area * 100) / 100
            answer.style.display = 'block'
            if (nbPolygon > 2) {
              answer.style.height = 20 + nbPolygon * 23 + 'px'
            }
            answer.innerHTML +=
              "<p class='pt-1'>&nbsp; &nbsp;" +
              nbPolygon +
              '  Polygon : ' +
              rounded_area +
              ' m2'
            this.addSourceDraw(nbPolygon, centroid, 'Polygon')
          } else if (item.geometry.type == 'LineString') {
            nbLine++
            var setcolor = this.getRandomColor()
            var from = turf.point(item.geometry.coordinates[0])
            var to = turf.point(item.geometry.coordinates[1])
            var featuresCenter = turf.featureCollection([to, from])
            var newCenter = turf.center(featuresCenter)
            var distance = turf.distance(from, to)
            var rounded_distance = Math.round((distance * 1000 * 100) / 100)
            if (nbLine > 2) {
              answerLine.style.height = 20 + nbLine * 23 + 'px'
            }
            answerLine.style.display = 'block'
            answerLine.innerHTML +=
              "<p class='pt-1'>&nbsp; &nbsp;" +
              nbLine.toString() +
              '   Distance : ' +
              rounded_distance +
              ' m</p>'
            this.addSourceDraw(nbLine, newCenter, 'Line')
          }
        })
      } else {
        this.tabSourcePolygon = []
        this.tabSourceLine = []
        answerLine.style.display = 'none'
        answerLine.innerHTML = ''
        answer.style.display = 'none'
        answer.innerHTML = ''
      }
    },

    addSourceDraw(nb, newCenter, type) {
      this.map.addSource(type + nb.toString(), {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            description: nb,
          },
          geometry: {
            type: 'Point',
            coordinates: newCenter.geometry.coordinates,
          },
        },
      })
      this.map.addLayer({
        id: type + nb.toString(),
        type: 'symbol',
        source: type + nb.toString(),
        layout: {
          'text-field': ['get', 'description'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
        },
      })
      if (type == 'Line') {
        this.tabSourceLine.push(type + nb.toString())
      } else {
        this.tabSourcePolygon.push(type + nb.toString())
      }
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF'
      var color = '#'
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    openHandle() {
      this.overlayOpened = true
      this.visible = true
    },
    onClosed() {
      this.opened = false
      this.visible = false
    },

    fetchFoncier() {
      this.$http
        .get('pige-foncier', {
          params: {
            page: 1,
          },
        })
        .then((response) => {
          console.log(response)
        })
    },
  },
}
</script>

<style>
input[type='checkbox']:not(:checked) {
  display: inline-block;
}

input[type='checkbox']:checked {
  display: inline-block;
}
</style>
