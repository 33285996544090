<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <div v-for="(component, index) in components" :key="index" class="mt-12">
      <component :is="component" :form="form" />
      <div
        v-if="index !== components.length - 1"
        class="border-promy-gray-325 border-t mt-8"
      ></div>
    </div>
  </ValidationObserver>
</template>

<script>
import InfosParcelle from './InfosParcelle'
import RatioDivers from './RatioDivers'
import DescriptifProjet from './DescriptifProjet'
import Tableau from './Tableau'
import RemarqueObservation from './RemarqueObservation'
import Preconisation from './Preconisation'

export default {
  components: {
    InfosParcelle,
    RatioDivers,
    DescriptifProjet,
    Tableau,
    RemarqueObservation,
    Preconisation,
  },
  data() {
    return {
      components: [
        'InfosParcelle',
        'RatioDivers',
        'DescriptifProjet',
        'Tableau',
        'RemarqueObservation',
        //"Preconisation",
      ],
    }
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
