<template>
  <div>
    <p
      class="text-promy-gray-700 font-arial font-extrabold text-lg sm:text-sm py-4"
    >
      {{ pollution_sols.name }}
    </p>
    <p>
      <span
        class="text-promy-gray-600 sm:text-base font-arial"
        v-for="(sub, idx) in pollution_sols.sub_title"
        :key="idx"
      >
        {{ sub.label }}
        <span v-if="sub.displayRayon"> {{ sub.rayon }}m</span> :
        <span class="font-bold capitalize">{{ sub.value }}</span
        ><br />
      </span>
    </p>
    <p
      v-for="(definition, index) in pollution_sols.definitions"
      :key="index"
      class="text-promy-gray-600 font-arial sm:text-sm"
    >
      {{ definition }}
    </p>
    <div
      v-for="(sub, idx) in pollution_sols.sub_title"
      :key="'sub' + idx"
      class="py-6"
    >
      <p
        v-if="sub.value != 'Non'"
        class="text-promy-gray-700 font-arial font-extrabold text-lg sm:text-sm py-4"
      >
        {{ sub.title }}
      </p>
      <p
        v-if="sub.value != 'Non'"
        class="text-promy-gray-600 font-arial font-bold sm:text-sm py-4"
      >
        {{ sub.descr }}
      </p>
      <div
        v-if="sub.value != 'Non' && sub.value != ''"
        class="w-full flex md:flex-col justify-start items-start"
      >
        <div
          class="w-2/5 md:w-full h-64 border-2 flex justify-center items-center"
        >
          <Map
            :id_map="`map_pol_${idx}`"
            :layer="sub.layer"
            :rayon="sub.rayon"
            geo_value="georisques"
            ref="map"
          />
        </div>
        <div class="flex flex-col">
          <img
            v-if="sub.id === 'BASIAS'"
            :src="`${sub.service}version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=BASIAS_ADRESSE`"
            class="ml-6 md:ml-0 md:mt-4"
          />
          <div
            class="flex space-x-3 items-end ml-6"
            v-else-if="sub.id === 'CASIAS'"
          >
            <img src="/images/marker-blue.svg" class="w-6 h-6 sm:mt-4" />
            <span class="text-xs text-promy-gray-600"
              >Localisation des anciens sites industriels et activités de
              service
            </span>
          </div>
          <img
            v-else
            :src="`${sub.service}version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=${sub.layer}`"
            class="ml-6 md:ml-0 md:mt-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Map from './map.vue'

export default {
  components: {
    Map,
  },
  props: {
    rapport_technique: {
      type: Object,
      require: true,
    },
  },
  computed: {
    pollution_sols() {
      return this.rapport_technique.details_risques.POL
    },
  },
}
</script>

<style lang="scss" scoped>
thead {
  tr {
    @apply rounded-t-lg;
  }
}
th,
td {
  @apply py-2 px-3;
}

tbody tr:nth-child(even) {
  @apply bg-promy-gray-125;
}
</style>
