<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <page-title>Gestion des permissions </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>Liste des permissions</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          class="hover:bg-white cursor-pointer hover:text-promy-green-300 transition-colors duration-200 border-2 border-promy-green-300 w-42 px-4 py-2 bg-promy-green-300 rounded-full text-white text-xs tracking-wide font-semibold font-main uppercase flex justify-between items-center"
          @click.prevent="nextPath('list-des-permissions-create-admin')"
        >
          Ajouter une permission
        </div>
      </template>
    </table-title>

    <hr class="my-6" />
    <pro-card v-if="permissions.length > 0">
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <pro-table-row
        v-for="(item, index) in permissions"
        :key="index"
        class="relative"
      >
        <pro-table-cell label="Permission" class="w-1/4 lg:w-full">
          {{ item.name }}
        </pro-table-cell>
        <pro-table-cell label="Catégorie" class="w-1/4 lg:w-full">
          <span>{{ item.category.name }}</span>
        </pro-table-cell>
        <pro-table-cell label="Description" class="w-1/4 lg:w-full">
          <span>{{ item.description }}</span>
        </pro-table-cell>

        <pro-table-cell :actions_btn="true" class="w-1/4 lg:w-full">
          <div class="w-full flex justify-end items-center space-x-3">
            <template v-if="$can('delete,gestion_utilisateur')">
              <div class="btn-actions" @click="displayModal(item)">
                <i class="fas fa-trash"></i>
              </div>
            </template>
            <router-link
              v-if="$can('edite,gestion_utilisateur')"
              :to="{
                name: 'list-des-permissions-edit-promoteur',
                params: { id: item.id },
              }"
              class="btn-actions"
            >
              <i class="fas fa-edit"></i>
            </router-link>
          </div>
        </pro-table-cell>
      </pro-table-row>
    </pro-card>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deletePermission()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer cette permission ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Permission',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Catégorie',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Description',
          sortKey: '',
          classes: 'w-1/4',
        },
      ],
      permissions: [],
      isFetching: false,
      showModal: false,
      current_item: '',
    }
  },
  created() {
    this.getPermissions()
  },
  methods: {
    getPermissions() {
      this.$http.get('admin/admin/permissions').then((res) => {
        this.permissions = res.data
      })
    },
    deletePermission() {
      this.$http
        .delete(`admin/admin/permissions/${this.current_item.id}`)
        .then(() => {
          this.permissions.splice(
            this.permissions.indexOf(this.current_item),
            1,
          )
          this.$toaster.warning('Permission a été supprimée avec succès')
          this.showModal = false
        })
    },
    displayModal(item) {
      this.current_item = item
      this.showModal = true
    },
  },
}
</script>
