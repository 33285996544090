<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.9992 22.5C15.2479 22.5 15.4863 22.4013 15.6621 22.2254C15.838 22.0496 15.9367 21.8112 15.9367 21.5625V10.7007L19.9605 14.7263C20.0476 14.8134 20.1511 14.8826 20.265 14.9298C20.3789 14.9769 20.501 15.0012 20.6242 15.0012C20.7475 15.0012 20.8696 14.9769 20.9835 14.9298C21.0973 14.8826 21.2008 14.8134 21.288 14.7263C21.3751 14.6391 21.4443 14.5356 21.4915 14.4217C21.5386 14.3079 21.5629 14.1858 21.5629 14.0625C21.5629 13.9393 21.5386 13.8172 21.4915 13.7033C21.4443 13.5894 21.3751 13.4859 21.288 13.3988L15.663 7.77378C15.5759 7.68647 15.4724 7.6172 15.3585 7.56994C15.2446 7.52268 15.1225 7.49835 14.9992 7.49835C14.8759 7.49835 14.7538 7.52268 14.6399 7.56994C14.526 7.6172 14.4226 7.68647 14.3355 7.77378L8.71048 13.3988C8.53444 13.5748 8.43555 13.8136 8.43555 14.0625C8.43555 14.3115 8.53444 14.5502 8.71048 14.7263C8.88652 14.9023 9.12528 15.0012 9.37423 15.0012C9.62319 15.0012 9.86194 14.9023 10.038 14.7263L14.0617 10.7007V21.5625C14.0617 21.8112 14.1605 22.0496 14.3363 22.2254C14.5121 22.4013 14.7506 22.5 14.9992 22.5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#00C9BC',
    },
  },
}
</script>
