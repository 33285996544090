<template>
  <div class="flex flex-col">
    <div
      class="flex w-full lg:w-full sm:pr-0 space-x-4 mb-6 lg:flex-col lg:space-x-0"
    >
      <div
        class="flex w-1/2 shadow-box py-6 px-6 rounded-box bg-promy-purple-260 text-white sm:flex-col lg:w-full lg:mb-6 bg-promy-green-300"
      >
        <div class="flex flex-col w-1/2 sm:w-full">
          <div class="flex font-extrabold text-xl font-main">
            Exposition aux risque
          </div>
          <div class="flex w-full pt-10 pr-2 sm:w-full sm:pt-6 text-sm">
            La commune se situe dans une zone à risque fort
          </div>
        </div>

        <div class="flex w-1/2 pl-2 justify-center sm:w-full sm:mt-4">
          <Speedometer
            :nbr_risques_exist="
              rapport_technique.resume.total_risques_commune_exist
            "
            :total_risque_evalue="
              rapport_technique.resume.list_risques_commune.length
            "
          ></Speedometer>
        </div>
      </div>
      <div
        class="flex w-1/2 text-white sm:flex-col space-x-4 lg:w-full sm:space-x-0"
      >
        <div class="flex w-1/2 sm:pr-0 sm:w-full">
          <div
            class="flex flex-col shadow-box py-6 px-6 rounded-box lg:w-full justify-between"
          >
            <div class="flex text-promy-gray-400">
              Les risques majeurs recensés sur la commune
            </div>
            <div class="flex justify-between">
              <div
                class="flex font-extrabold text-6xl items-end text-promy-gray-750"
                style="line-height: initial"
              >
                {{ rapport_technique.resume.total_risques_commune_exist }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-1/2 sm:pl-0 sm:w-full sm:mt-4">
          <div
            class="flex flex-col shadow-box py-6 px-6 rounded-box lg:w-full justify-between"
          >
            <div class="flex text-promy-gray-400">
              Reconnaissances de l'état de catastrophes naturelles
            </div>
            <div class="flex justify-between">
              <div
                class="flex font-extrabold text-6xl items-end text-promy-gray-750"
                style="line-height: initial"
              >
                {{ rapport_technique.resume.total_catastrophes_naturelles }}
              </div>
              <div
                class="flex items-end text-sm font-extrabold pb-1 text-promy-green-200 cursor-pointer"
                @click="scrollToCatastropheNaturelle"
              >
                VOIR LE DETAIL
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden flex-col shadow-box py-7 px-6 rounded-box mt-8 flex-1">
        <div class="flex flex-col">
          <div
            class="flex font-extrabold text-xl font-main text-promy-gray-750"
          >
            Indice de qualité de l'air
          </div>
          <div class="relative w-7/24 mx-auto mb-8 mt-6 sm:w-3/4">
            <svg
              class="w-full h-auto"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="15.4941"
                cy="15.2305"
                r="14.5"
                class="stroke-current text-promy-green-200"
                fill="white"
              />
            </svg>
            <span
              class="center-element text-promy-green-200 text-3xl font-bold"
            >
              FAIBLE
            </span>
          </div>
        </div>
        <div
          class="flex font-extrabold text-xl font-main text-promy-gray-750 mb-10 hidden"
        >
          Qualité de l'air
        </div>
        <div class="flex w-full text-sm hidden">
          L'indice AQI mesure quotidiennement la qualité de l'air. L'indicateur
          varie de 1, très bonne qualité de l'air à 500, très mauvaise qualité
          de l'air. La qualité de l'air est considérée nocive pour la santé à
          partir de 100. En France, les valeurs varient entre 0 et 200.
        </div>
        <div class="hidden flex-col my-8 text-sm font-bold text-white">
          <div
            class="flex bg-gradient rounded-l-full rounded-r-full py-3 text-center"
          >
            <div class="w-1/4">BONNE</div>
            <div class="w-1/4">MOYENNE</div>
            <div class="w-1/4">MAUVAISE</div>
            <div class="w-1/4">NOVICE</div>
          </div>
          <div class="flex">
            <div class="text-promy-gray-550 text-center mt-2 w-1/4">0 - 50</div>
            <div class="text-promy-gray-550 text-center mt-2 w-1/4">
              50 - 100
            </div>
            <div class="text-promy-gray-550 text-center mt-2 w-1/4">
              100 - 150
            </div>
            <div class="text-promy-gray-550 text-center mt-2 w-1/4">
              150 ET PLUS
            </div>
          </div>
        </div>

        <div class="flex mt-8 flex-1 items-end sm:flex-col">
          <div class="flex w-9/24 pr-2 sm:w-full sm:pr-0">
            <SmallCard
              :titre="'pollution automobile'"
              :valeur="'14'"
              :SizeValeur="'text-3xl'"
              class="bg-promy-purple-400 border-promy-purple-400 h-24 w-full"
            ></SmallCard>
          </div>
          <div class="flex w-9/24 px-2 sm:w-full sm:px-0 sm:my-4">
            <SmallCard
              :titre="'pollution Industrielle'"
              :valeur="'84'"
              :SizeValeur="'text-3xl'"
              class="bg-promy-purple-400 border-promy-purple-400 h-24 w-full"
            ></SmallCard>
          </div>
          <div class="flex w-9/24 pl-2 sm:w-full sm:pl-0">
            <SmallCard
              :titre="'pollution à l\'ozone'"
              :valeur="'1,3'"
              :SizeValeur="'text-3xl'"
              class="bg-promy-purple-400 border-promy-purple-400 h-24 w-full"
            ></SmallCard>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-1 w-full sm:pl-0">
      <div class="flex flex-col shadow-box py-7 px-6 rounded-box flex-1">
        <div
          class="flex font-extrabold text-xl font-main text-promy-gray-750 mb-2"
        >
          {{ rapport_technique.resume.total_risques_commune_exist }} risques
          présents ( sur 14 risque évalués )
        </div>

        <div class="grid grid-cols-2 gap-y-4 gap-x-12 text-left lg:grid-cols-1">
          <DescRisque
            v-for="(risque, index) in rapport_technique.resume
              .list_risques_commune"
            :key="index"
            class="mt-8"
            :risque="risque"
          ></DescRisque>
        </div>
      </div>
    </div>
    <CatastrophesNaturelles
      :catastrophes_naturelles="
        rapport_technique.resume.catastrophes_naturelles
      "
    >
    </CatastrophesNaturelles>
  </div>
</template>
<script>
import _ from 'lodash'
import CatastrophesNaturelles from './CatastrophesNaturelles'
import DescRisque from './DescRisque'
import Speedometer from './Speedometer'
export default {
  components: {
    CatastrophesNaturelles,
    DescRisque,
    Speedometer,
  },

  props: {
    rapport_technique: {
      type: Object,
      required: true,
    },
  },
  methods: {
    scrollToCatastropheNaturelle() {
      let scroll_el = document.querySelector(`#catasrophesNaturelles`)
      if (scroll_el) {
        setTimeout(function () {
          scroll_el.scrollIntoView()
        }, 500)
        if (
          !document.querySelector(
            `#catasrophesNaturelles .accordion__trigger.accordion__trigger_active`,
          )
        )
          document
            .querySelector(`#catasrophesNaturelles .accordion__trigger`)
            .click()
      }
    },

    scrollToElement(id) {
      let el = document.querySelector(`#${id}`)
      if (el) el.scrollIntoView()
    },
  },
}
</script>
<style lang="scss" scoped>
.bg-gradient {
  background: linear-gradient(
    90deg,
    rgba(72, 202, 93, 1) 0%,
    rgba(245, 56, 56, 1) 100%
  );
}
.center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
