import Vue from 'vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import VueQuillEditor from 'vue-quill-editor'

const options = {
  modules: {
    toolbar: {
      container: [
        [
          {
            placeholder: [
              '[Nom Destinataire]',
              '[Prénom Destinataire]',
              '[Adresse Destinataire]',
              '[Tel Destinataire]',
              '[Code postal Destinataire]',
              '[Ville Destinataire]',
              '[Commune Destinataire]',
              '[Réfs cadastrales]',
            ],
          },
        ],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [
          {
            header: 1,
          },
          {
            header: 2,
          },
        ],
        [
          {
            list: 'ordered',
          },
          {
            list: 'bullet',
          },
        ],
        [
          {
            script: 'sub',
          },
          {
            script: 'super',
          },
        ],
        [
          {
            indent: '-1',
          },
          {
            indent: '+1',
          },
        ],
        [
          {
            direction: 'rtl',
          },
        ],
        [
          {
            color: [
              '#000000',
              '#89E2DC',
              '#0BE096',
              '#00C9BC',
              '#00b7ab',
              '#008279',
              '#321E6A',
              '#A891E5',
              '#f5bb14',
            ],
          },
        ],
      ],
      handlers: {
        placeholder: function (value) {
          if (value) {
            const cursorPosition = this.quill.getSelection().index
            this.quill.insertText(cursorPosition, value)
            this.quill.setSelection(cursorPosition + value.length)
          }
        },
      },
    },
  },
  placeholder: '',
}
Vue.use(VueQuillEditor, options)
