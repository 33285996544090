import transport from '@/plugins/axios'
export default {
  namespaced: true,

  state: {
    allConditionSuspensive: null,
  },

  actions: {
    getAllConditionSuspensive({ commit }, params) {
      return new Promise((resolve, reject) => {
        transport.get('allConditionSuspensive', { params: params }).then(
          (response) => {
            commit(
              'SET_ALL_CONDITION_SUSPENSIVE',
              response.data.conditionsSuspensives,
            )
            resolve(response.data)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },
    saveConditionItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        transport.post('condition_suspensives', payload.object).then(
          (response) => {
            resolve(response)
            payload.object.id = response.data.saved.id
            commit('SET_CONDITION_ITEM', payload)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },
    updateConditionItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        transport
          .put(`condition_suspensives/${payload.object.id}`, payload.object)
          .then(
            (response) => {
              commit('UPDATE_CONDITION_ITEM', payload)
              resolve(response)
            },
            (error) => {
              reject(error)
            },
          )
      })
    },
    updateAllConditionOrder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        transport
          .put('UpdateallConditionSuspensive', {
            conditionsSuspensives: payload,
          })
          .then(
            (response) => {
              resolve(response)
            },
            (error) => {
              reject(error)
            },
          )
      })
    },
    deleteConditionItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        transport.delete(`condition_suspensives/${id}`).then(
          (response) => {
            resolve(response)
            commit('DELETE_CONDITION_ITEM', id)
          },
          (error) => {
            reject(error)
          },
        )
      })
    },
  },

  mutations: {
    SET_ALL_CONDITION_SUSPENSIVE(state, list) {
      list.forEach(function (element) {
        element.editText = false
        element.new_item = false
      })
      state.allConditionSuspensive = list
    },
    UNSHIFT_NEW_CONDITION(state, payload) {
      state.allConditionSuspensive.unshift(payload)
    },
    SHIFT_NEW_CONDITION(state) {
      state.allConditionSuspensive.shift()
    },
    SET_CONDITION_ITEM(state, payload) {
      state.allConditionSuspensive[payload.idx] = payload.object
    },
    DELETE_CONDITION_ITEM(state, id) {
      state.allConditionSuspensive = state.allConditionSuspensive.filter(
        (item) => item.id != id,
      )
    },
    SHOW_EDIT(state, payload) {
      state.allConditionSuspensive[payload.idx] = payload.object
    },
    UPDATE_CONDITION_ITEM(state, payload) {
      state.allConditionSuspensive[payload.idx].nom = payload.object.nom
      state.allConditionSuspensive[payload.idx].editText = false
    },
  },

  getters: {
    all_condition_suspensive(state) {
      return state.allConditionSuspensive
    },
  },
}
