import Vue from 'vue'
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  localize,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import fr from 'vee-validate/dist/locale/fr.json'

extend('website', {
  validate(value) {
    let pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ) // fragment locator
    return !!pattern.test(value)
  },
  message:
    'Veuillez entrer une URL valide (par exemple, https://www.example.com)',
})

extend('youtube', {
  validate(value) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/[^#\&\?]*).*/
    return value.match(regExp)
  },
  message:
    'Veuillez entrer une URL valide (par exemple, https://www.youtube.com/embed/xxxxxxxx)',
})

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'La confirmation du mot de passe ne correspond pas',
})

extend('url', {
  validate(value) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    )

    return pattern.test(value)
  },
  message: 'Please enter a valid link.',
})

localize('fr', fr)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
