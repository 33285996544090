export default {
  data() {
    return {
      green: '#00C9BC',
      green_light: '#0BE096',
      orange: '#FFB624',
      grey: '#A0A0A0',
      blue: '#356390',
      purple: '#321E6A',
      options_polygone: {
        color: '#321E6A',
        is_dash: false,
        is_multi_geojson: false,
        opacity: 0.3,
      },
    }
  },

  methods: {
    createMap(coordinates) {
      let temp_map = {}
      temp_map = new this.$mapboxgl.Map({
        container: 'mapbox',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: coordinates,
        zoom: 18,
      })

      temp_map.addControl(
        new this.$mapboxgl.NavigationControl({
          showZoom: true,
          showCompass: false,
          visualizePitch: false,
        }),
        'top-left',
      )
      return temp_map
    },
    fitBoundsGeojsonInMap(geojson, padding = 40) {
      var bbox = this.$turf.bbox(geojson)
      this.map.fitBounds(bbox, {
        padding: padding,
      })
    },
    addGeojsonToMap(name_source, geojson) {
      this.map.addSource(name_source, {
        type: 'geojson',

        data: geojson,
      })
    },
    addMultiGeojsonToMap(name_source, geojson) {
      this.map.addSource(name_source, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: geojson,
        },
        generateId: true,
      })
    },
    addSourceLayersMultiGeojsonToMap(multi_geojson, color, source_layers) {
      let options = this.copyObject(this.options_polygone)
      options.color = color
      options.is_multi_geojson = true

      this.addPolygonsToMap(multi_geojson, source_layers, options)
    },
    addBackgroundLayerToGeojson(id_layer, source_layer, color, opacity = 0.3) {
      this.map.addLayer({
        id: id_layer,
        type: 'fill',
        source: source_layer,
        paint: {
          'fill-color': color,
          'fill-opacity': opacity,
        },
      })
    },
    addOutlineLayerToGeojson(id_layer, source_layer, color, is_dash = false) {
      let layout = {}
      let line_dasharray = [1]
      if (is_dash) {
        layout = {
          'line-join': 'round',
          'line-cap': 'round',
        }
        line_dasharray = [0.1, 1.8]
      }
      this.map.addLayer({
        id: id_layer,
        type: 'line',
        source: source_layer,
        layout: layout,
        paint: {
          'line-color': color,
          'line-width': 4,
          'line-dasharray': line_dasharray,
          'line-opacity': 1,
        },
      })
    },
    removeSourceAndLayers(
      id_source,
      id_background_layer,
      id_outline_layer = null,
    ) {
      if (this.map) {
        if (this.isExist(this.map.getLayer(id_background_layer)))
          this.map.removeLayer(id_background_layer)
        if (this.isExist(this.map.getLayer(id_outline_layer))) {
          this.map.removeLayer(id_outline_layer)
        }
        if (this.map.getSource(id_source)) {
          this.map.removeSource(id_source)
        }
      }
    },
    layerExist(id_layer) {
      return typeof this.map.getLayer(id_layer) !== 'undefined'
    },
    concatSourceAndLayersWithRef(
      id_source,
      id_background_layer,
      id_outline_layer,
      ref,
    ) {
      return [
        id_source + '-' + ref,
        id_background_layer + '-' + ref,
        id_outline_layer + '-' + ref,
      ]
    },
    addPolygonsToMap(
      polygons,
      id_source_and_layers,
      options = this.options_polygone,
    ) {
      let source_and_layers = {
        id_source: id_source_and_layers[0],
        id_background_layer: id_source_and_layers[1],
        id_outline_layer: id_source_and_layers[2],
      }
      if (options.is_multi_geojson)
        this.addMultiGeojsonToMap(source_and_layers.id_source, polygons)
      else this.addGeojsonToMap(source_and_layers.id_source, polygons)
      this.addBackgroundLayerToGeojson(
        source_and_layers.id_background_layer,
        source_and_layers.id_source,
        options.color,
        options.opacity,
      )
      this.addOutlineLayerToGeojson(
        source_and_layers.id_outline_layer,
        source_and_layers.id_source,
        options.color,
        options.is_dash,
      )
    },
    addSourceLayerToSelectedPolygone(
      parcelle,
      source_and_layers_parcelles_aggregate,
    ) {
      let options = this.copyObject(this.options_polygone)
      options.color = this.green
      this.addPolygonsToMap(
        parcelle,
        source_and_layers_parcelles_aggregate,
        options,
      )
    },
    switchLayer(moduleStore, style, layers) {
      let vm = this
      const savedLayers = []
      const savedSources = {}
      layers.forEach((layerGroup) => {
        vm.forEachLayer(layerGroup, (layer) => {
          savedSources[layer.source] = vm.map
            .getSource(layer.source)
            .serialize()
          savedLayers.push(layer)
        })
      })
      this.$store.commit(`${moduleStore}/SAVED_SOURCES`, savedSources)
      this.$store.commit(`${moduleStore}/SAVED_LAYERS`, savedLayers)
      vm.map.setStyle(`mapbox://styles/mapbox/${style}`)
    },
    setBaseLayers() {
      this.$store.commit(
        `${this.moduleStore}/BASE_LAYERS`,
        this.map.getStyle().layers.map((layer) => {
          return {
            id: layer.id,
            source: layer.source,
          }
        }),
      )
    },
    preserveSourceAndLayers() {
      Object.entries(this.saved_sources).forEach(([id, source]) => {
        if (!this.isExist(this.map.getSource(id)))
          this.map.addSource(id, source)
      })
      this.saved_layers.forEach((layer) => {
        if (!this.isExist(this.map.getLayer(layer.id))) this.map.addLayer(layer)
      })
    },
    forEachLayer(text, cb) {
      this.map.getStyle().layers.forEach((layer) => {
        if (layer.id.includes(text)) cb(layer)
      })
    },

    removeMarkersAndAdditionalLayers(markers = []) {
      markers.forEach((marker) => {
        marker.remove()
      })
      let vm = this
      let all_layers = vm.map.getStyle().layers.map((layer) => {
        return {
          id: layer.id,
          source: layer.source,
        }
      })
      let additional_layer = all_layers.filter((layer) => {
        return layer.id.split('-')[0] === 'promy'
      })

      additional_layer.forEach((layer) => {
        if (vm.map.getLayer(layer.id)) {
          vm.map.removeLayer(layer.id)
        }
      })
      additional_layer.forEach((layer) => {
        if (vm.map.getSource(layer.source)) {
          vm.map.removeSource(layer.source)
        }
      })
    },

    preserveLayers() {
      this.setBaseLayers()
      this.preserveSourceAndLayers()
    },
    addMarkerCircleToMap(coords, color = 'bg-promy-green-300') {
      var el = document.createElement('div')

      el.innerHTML = `<div  class=' icon-circle ${color} text-center flex  text-xs font-bold  justify-center text-white'></div>`
      let marker = new this.$mapboxgl.Marker(el).setLngLat(coords)
      marker.addTo(this.map)
      return {
        el,
        marker,
      }
    },
    addCustomMarkerWithPopupInMap(template, latLong, custom_marker, popup) {
      popup.setHTML(template)
      let marker = new this.$mapboxgl.Marker(custom_marker, {
        offset: [0, -15],
      })
        .setLngLat(latLong)
        .setPopup(popup)
        .addTo(this.map)

      this.$store.commit('defaultmap/CURRENT_MARKERS', marker)
      return {
        marker: marker,
        popup: popup,
      }
    },
    getCornersGeojson(geojson) {
      let corners = this.$turf
        .explode(this.$turf.simplify(geojson, this.options_simplify))
        .features.map((feature) => feature.geometry.coordinates)
      corners.pop()
      return corners
    },
    removeMarkers(currentMarkers) {
      currentMarkers.forEach(function (marker) {
        marker.remove()
      })
    },
    customPopupButtonClose(event) {
      let btn_close = event.target._container.querySelector(
        '.mapboxgl-popup-close-button',
      )
      btn_close.innerHTML = ''
      btn_close.style.backgroundImage = "url('/images/close-white.svg')"
      btn_close.classList.add('btn-close')
    },
    setLayoutVisibility(layers, visibility) {
      this.map.setLayoutProperty(layers[1], 'visibility', visibility)
      this.map.setLayoutProperty(layers[2], 'visibility', visibility)
    },
  },
}
