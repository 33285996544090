<template>
  <span
    :class="classes"
    class="py-4 px-5 rounded-xl mt-2 text-[#DDC029] bg-[#FFF5C0] text-sm"
    v-if="show"
  >
    {{ message }}
  </span>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
    classes: {
      type: String,
    },
    message: {
      type: String,
      default: 'Remplissez ce champ pour finaliser votre dossier',
    },
  },
}
</script>
