import Vue from 'vue'

Vue.filter('truncateTerrain', function (value, numberOfWords) {
  let words = value.split('').splice(0, numberOfWords).join('')
  if (words !== value) {
    words = words.concat('....')
  }

  return words
})
