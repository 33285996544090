<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <page-title>Gestion des synthèses du marché</page-title>
    <table-title
      nextPath="ajouter"
      class="flex items-center justify-between sm:flex-col sm:items-start sm:space-y-3"
    >
      <template v-slot:title>Liste des synthèses du marché</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          v-if="$can('edite,dossier_promoteur')"
          @click.prevent="nextPath('synthese-form')"
          class="hover:bg-white cursor-pointer hover:text-promy-green-300 transition-colors duration-200 border-2 border-promy-green-300 w-fit sm:w-full text-center px-4 py-2 bg-promy-green-300 rounded-full text-white text-xs tracking-wide font-semibold font-main uppercase"
        >
          ajouter une synthèse
        </div>
      </template>
    </table-title>

    <hr class="my-6" />
    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :sortingBy="sorting_by"
          :hasSort="sort.sortKey ? true : false"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <pro-table-row
        v-for="(item, index) in syntheses"
        :key="index"
        class="relative"
      >
        <pro-table-cell label="Région" class="w-1/4 lg:w-full">
          <span v-if="$mq === 'sm'">{{
            item.region_name | truncateTerrain(16)
          }}</span>
          <span v-else>{{ item.region_name }}</span>
        </pro-table-cell>
        <pro-table-cell label="Titre" class="w-1/4 lg:w-full">
          {{ item.title }}
        </pro-table-cell>
        <pro-table-cell label="Nombre d'images" class="w-1/4 lg:w-full">
          {{ item.images.length }}
        </pro-table-cell>

        <pro-table-cell :actions_btn="true" class="w-1/4 lg:w-full">
          <div class="w-full flex justify-end items-center space-x-3">
            <div
              v-if="$can('delete,dossier_promoteur')"
              class="btn-actions"
              @click="displayModal(item)"
            >
              <i class="fas fa-trash"></i>
            </div>
            <router-link
              v-if="$can('edite,dossier_promoteur')"
              :to="{
                name: 'synthese-edit-form',
                params: { id: item.id },
              }"
              class="btn-actions"
            >
              <i class="fas fa-edit"></i>
            </router-link>
          </div>
        </pro-table-cell>
      </pro-table-row>
    </pro-card>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteSynthese()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer la synthèse de marche pour cette
        région ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Région',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Titre',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: "Nombre d'images",
          sortKey: '',
          classes: 'w-1/4',
        },
      ],
      sorting_by: '',
      syntheses: [],
      showModal: false,
      current_item: null,
    }
  },
  created() {
    this.getSyntheses()
  },
  methods: {
    getSyntheses() {
      this.$http.get('admin/syntheses').then((res) => {
        this.syntheses = res.data.data
      })
    },
    deleteSynthese() {
      this.$http.delete(`admin/syntheses/${this.current_item.id}`).then(() => {
        this.syntheses.splice(this.syntheses.indexOf(this.current_item), 1)
      })
      this.showModal = false
    },
    displayModal(idx) {
      this.current_item = idx
      this.showModal = true
    },
  },
}
</script>

<style scoped></style>
