var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full"},[(_vm.is_router_link)?[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"container-tabs",class:_vm.classTabs},[_vm._l((_vm.mutatedTabs),function(tab,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < _vm.numbreTabs),expression:"index < numbreTabs"}],key:index,staticClass:"flex flex-col justify-between"},[_c('div',{staticClass:"label-tab",class:[
              index != _vm.currentTabIndex ? 'text-promy-gray-500' : '',
              _vm.classHeaders ],on:{"click":function($event){return _vm.changeTab(tab, index)}}},[_vm._v(" "+_vm._s(tab.label)+" ")]),_c('hr',{staticClass:"hr-active",class:{
              'opacity-0': index != _vm.currentTabIndex,
            }})])}),_c('PTabsMobile',{attrs:{"numbreTabs":_vm.numbreTabs,"tabs":_vm.mutatedTabs,"is_router_link":_vm.is_router_link,"current_component":_vm.current_component},on:{"changeTab":_vm.changeTab}})],2)]),(_vm.with_hr_tabs)?_c('hr',{staticClass:"hr-tabs"}):_vm._e()]:[(!_vm.is_buttons)?_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"container-tabs",class:_vm.classTabs},[_vm._l((_vm.mutatedTabs),function(tab,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < _vm.numbreTabs),expression:"index < numbreTabs"}],key:index,staticClass:"flex flex-col justify-between"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"label-tab",class:[
                index != _vm.currentTabIndex ? 'text-promy-gray-500' : '',
                _vm.classHeaders ],attrs:{"id":tab.component + '_' + index},on:{"click":function($event){return _vm.changeTab(tab, index)}}},[_vm._v(" "+_vm._s(tab.label)+" ")]),(_vm.canPastilleBeShown(tab.component))?_c('div',{staticClass:"ml-px flex h-2 w-2 relative"},[_c('span',{staticClass:"outer-pastille"}),_c('span',{staticClass:"inner-pastille"})]):_vm._e()]),_c('hr',{staticClass:"hr-active",class:{ 'opacity-0': index != _vm.currentTabIndex }})])}),_c('PTabsMobile',{attrs:{"numbreTabs":_vm.numbreTabs,"tabs":_vm.tabs,"is_router_link":_vm.is_router_link,"current_component":_vm.current_component},on:{"changeTab":_vm.changeTab}})],2),(_vm.with_hr_tabs)?_c('hr',{staticClass:"hr-tabs"}):_vm._e()]):_c('div',{staticClass:"flex font-main space-x-3"},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index},[_c('button',{staticClass:"w-43 h-10 sm:w-36 text-base",class:[
            _vm.current_component !== tab.component
              ? 'btn-white    !border-promy-gray-250'
              : 'btn-green' ],on:{"click":function($event){return _vm.changeTab(tab, index)}}},[_vm._v(" "+_vm._s(tab.label)+" ")])])}),0),_c('div',{class:_vm.classes_slot},[_vm._t("default",null,{"current_component":_vm.current_component})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }