<template>
  <div
    class="flex flex-col flex-1 w-86 ws:w-full sm:w-full h-screen sticky top-0"
  >
    <div
      class="bg-promy-green-200 sm-ws:h-17 h-30 sm:h-18 lg-ws:h-25 flex xl:justify-center xl:items-center"
    >
      <div
        class="flex lg:justify-between justify-center lg:pl-4 sm:pr-2 sm:py-4 w-full"
        :class="{ 'sm:pl-0': resizedWindow, 'sm:pl-10': !resizedWindow }"
      >
        <div
          class="flex items-center justify-center lg:justify-start sm:pl-0 sm:h-auto"
          :class="{
            'lg:pl-0 sm:pl-8': resizedWindow,
            'lg:pl-8': !resizedWindow,
          }"
        >
          <img
            src="/images/logo_promy-white.svg"
            class="w-26 lg:w-20 sm:w-16 sm-ws:w-17"
            alt
          />
        </div>
        <div class="flex items-center sm:pr-5 lg:pr-4">
          <button class="text-white xl:hidden" @click="closeSideBar">
            <img
              src="/images/close-white.svg"
              class="sm:w-8 sm:h-8 lg:w-10 lg:h-10"
              alt
            />
          </button>
        </div>
      </div>
    </div>
    <div
      class="lg:overflow-y-auto overflow-x-hidden xl:flex xl:flex-1 lg:flex lg:flex-1 bg-gray-100"
      :class="{ 'flex flex-1 ': !resizedWindow, 'sm:flex': resizedWindow }"
    >
      <div class="flex flex-col flex-1">
        <div class="flex w-full flex-1">
          <div
            class="bg-promy-purple-300 flex sm-ws:flex-1 lg-ws:flex-1"
            :class="{
              'w-86 ws:w-full lg:overflow-y-auto overflow-x-hidden sm:flex-1':
                !resizedWindow,
              'w-109/4 sm:w-24 sm:flex-grow-0 sm:flex-shrink-0 sidebar-resized xl:w-109/4':
                resizedWindow,
            }"
          >
            <div
              class="flex flex-col flex-1"
              :class="{ ' sm-ws:pt-0': resizedWindow }"
              @mouseenter="hover = false"
              @mouseleave="hover = true"
            >
              <SideBarItem
                v-for="link in gatedLinks"
                :key="link.name"
                class="w-full"
                :link="link"
              />
            </div>
          </div>
          <div
            class="sm:flex sm:flex-1 sub-sidebar lg:flex-1 full-hd:flex-1"
            v-if="resizedWindow"
          >
            <SubSideBar :links="sublinks" :parentUrl="parent_url" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      hover: true,
      links: [
        {
          name: 'Tableau de bord',
          path: '/',
          icon: 'proTableauBoard',
          slug: 'tableau-de-bord',
        },
        {
          name: 'Gestions des utilisateurs',
          path: '/gestion-des-utilisateurs',
          icon: 'GestionUsers',
          slug: 'gestion-des-utilisateur',
          gate: [
            'consultation,gestion_utilisateur',
            'edite,gestion_utilisateur',
            'delete,gestion_utilisateur',
          ],
        },
        {
          name: 'Gestion des catégories',
          path: '/gestion-des-roles-et-permissions',
          icon: 'GestionUsers',
          slug: 'gestion-des-roles-et-permissions',
          gate: ['full_access,role_permission'],
        },
        {
          name: 'Guichet Unique',
          path: '/guichet-unique',
          icon: 'GuichetUnique',
          slug: 'guichet-unique',
        },
        {
          name: 'Dossiers promoteur',
          path: '/dossiers-promoteurs',
          icon: 'DossierPromoteur',
          slug: 'dossiers-promoteurs',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'publish,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },
        {
          name: 'Délégation de mandats',
          path: '/gestion-des-delegations-de-mandat',
          icon: 'DossierPromoteur',
          slug: 'gestion-des-delegation',
        },
        {
          name: 'Outils Promoteur',
          path: '/outil-promoteur',
          icon: 'OutilPromoteur',
          slug: 'outil-promoteur',
        },
        {
          name: 'Paramétrage',
          path: '/parametrage',
          icon: 'Parametrage',
          slug: 'parametrage',
        },
        {
          name: 'Partenaires',
          path: '/partenaires',
          icon: 'Partenaires',
          slug: 'partenaires',
        },
        {
          name: 'Boîte à outils',
          path: '/boite-a-outils',
          icon: 'BoiteAOutils',
          slug: 'boite-a-outils',
        },
        {
          name: 'Gestion des affaires',
          path: '/gestion-des-affaires',
          icon: 'GestionDesAffaires',
          slug: 'gestion-des-affaires',
        },
        {
          name: 'Gestion des architectes',
          path: '/gestion-des-architectes',
          icon: 'GestionDesArchitectes',
          slug: 'gestion-des-architectes',
        },
        {
          name: 'Révélation potentiel',
          path: '/revelation-potentiel',
          icon: 'proTableauBoard',
          slug: 'revelation-potentiel',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      resizedWindow: 'sidebar/resizedWindow',
      parent_url: 'subSidebar/parent_url',
      sublinks: 'subSidebar/links',
    }),
    gatedLinks() {
      return this.filterWithGate()
    },
  },

  methods: {
    filterWithGate() {
      return this.links.filter((link) => {
        if (
          link.gate == undefined ||
          link.gate.some((gate) => this.$can(gate))
        ) {
          return link
        }
      })
    },
    closeSideBar() {
      this.$store.commit('sidebar/COLLAPSE', true)
    },
  },
  watch: {
    hover(value) {
      if (this.parent_url == this.$route.matched[1].path) {
        this.$store.commit('sidebar/RESIZE_WINDOW', value)
      }
    },
  },
}
</script>
<style lang="scss">
.driver-popover-title {
  @apply text-5xl #{!important};
}
.driver-popover-description {
  @apply text-lg #{!important};
}

@screen ws {
  .header-title,
  .text-xl {
    font-size: 1.1vw;
  }
  .text-xs {
    font-size: 0.65vw;
  }
  body,
  .text-base,
  .text-box-title-number {
    font-size: 0.9vw !important;
  }
  .text-lg,
  .driver-popover-description {
    font-size: 0.97vw !important;
  }
  .text-promy-4xl {
    font-size: 1.885vw !important;
  }
  .text-promy-2xl {
    font-size: 1.3vw !important;
  }
  .text-smaller {
    font-size: 0.53vw !important;
  }
  .text-sm {
    font-size: 0.73vw !important;
  }
  .text-3xl {
    font-size: 1.56vw !important;
  }
  .text-2xl {
    font-size: 1.26vw !important;
  }
  .text-5xl,
  .driver-popover-title {
    font-size: 2vw !important;
  }
  .text-promy-5xl {
    font-size: 2.1vw;
  }
}

@screen sm-ws {
  .sub-sidebar {
    width: inherit;
  }
  .sidebar {
    width: 21%;
    min-width: 280px;

    .icon-sidebar {
      @apply py-2;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
  .sidebar-resized {
    min-width: 5.8125rem;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 4px !important;
  }
  .swiper-wrapper {
    @apply mt-2 mb-4;
  }
}

@screen lg-ws {
  .sub-sidebar {
    width: inherit;
  }
  .sidebar {
    min-width: 280px;
    min-width: 19%;
  }
  .sidebar-resized {
    min-width: 6.8125rem;
  }
  .swiper-wrapper {
    @apply mt-4 mb-8;
  }
}

::-webkit-scrollbar {
  width: 0.3em;
}

::-webkit-scrollbar-thumb {
  background-color: #01d5c7;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}
</style>
