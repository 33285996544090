<template>
  <v-select
    :options="items"
    :label="optionLabel"
    :placeholder="placeholder"
    @input="select"
    :value="value"
    class="v-select"
  >
    <span slot="no-options"></span>
  </v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
export default {
  components: {
    vSelect,
  },
  props: {
    placeholder: {
      type: String,
      default: 'test',
    },
    options: {
      type: [Array, Object],
      default: () => [],
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
  },
  methods: {
    select(value) {
      this.$emit('input', value)
    },
  },
  computed: {
    items() {
      return Array.isArray(this.options)
        ? this.options
        : Object.values(this.options)
    },
  },
}
</script>

<style>
.v-select .vs__dropdown-toggle {
  @apply w-full h-10 pr-4 pl-2 text-xs font-semibold rounded-full focus:outline-none focus:bg-white bg-promy-gray-background-300 text-promy-gray-600 sm-ws:h-10;
}
.v-select .vs__search::placeholder {
  @apply font-bold w-full;
}

.v-select .vs__dropdown-menu {
  @apply text-xs font-semibold;
}

.v-select .vs__dropdown-option {
  @apply px-4 py-2;
}

.v-select .vs__dropdown-option--highlight {
  @apply bg-promy-green-200;
}

.v-select .vs__clear,
.v-select .vs__open-indicator {
  fill: #394066;
}
</style>
