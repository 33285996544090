<template>
  <div>
    <label class="capitalize text-xs text-gray-600 font-bold font-arial">{{
      label
    }}</label>
    <select
      :name="name"
      :value="value"
      @input="handleInput($event.target.value)"
      class="focus:border-promy-green-300 text-sm appearance-none rounded-full px-4 py-2 text-gray-600 border-solid border w-full mt-1 border-promy-gray-300 bg-promy-gray-background-300"
    >
      <option v-for="item in items" :key="item" :value="item">
        {{ item }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [Array, String, Boolean],
      required: true,
    },
    requirements: {
      type: String,
    },
    name: {
      type: String,
    },
  },

  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
