function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'

export default [
  {
    path: 'profile',
    meta: {
      middleware: [auth],
    },
    component: page('dashboard/Profile'),
    redirect: '',
    children: [
      {
        path: '',
        name: 'user-profile-index',
        component: page('dashboard/Profile/profile'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'modifier-mot-de-passe',
        name: 'user-profile-password',
        component: page('dashboard/Profile/modifier-mot-de-passe'),
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
]
