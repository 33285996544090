<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-4 sm-ws:px-10">
    <div
      class="flex font-extrabold leading-2 text-promy-purple-400 text-promy-2xl font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Offres pour ce dossier</span>
    </div>
    <div class="flex justify-end lg:text-base xl:text-lg">
      <router-link
        :to="{ name: 'list-dossier-promoteur' }"
        class="flex items-center justify-center px-4 py-2 text-sm font-extrabold text-white uppercase border-2 rounded-full cursor-pointer bg-promy-green-300 border-promy-green-300 font-main"
      >
        <i class="pr-2 text-base fas fa-arrow-circle-left"></i>
        <span> Retour</span>
      </router-link>
    </div>
    <div
      class="flex sm:flex-col items-center my-2 border-b-2 lg:flex-col xl:pb-5 lg:pb-4 xl:border-promy-gray-300"
    >
      <div
        class="flex items-center mr-4"
        v-for="item in Status"
        :key="item + '-type'"
      >
        <span
          :class="`rounded-full w-3 h-3 bg-promy-${getStatusColor(item)} mr-1`"
        >
        </span>
        <span class="text-sm text-promy-gray-600">{{ item }} </span>
      </div>
    </div>

    <!-- table -->
    <div class="xl:h-50-screen xl:overflow-y-auto liste-dossier">
      <template v-if="isFetching && current_page == 1">
        <div class="flex justify-center w-full mt-24">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="offresDossierPromoteurs.length > 0">
          <pro-card>
            <pro-table-row :isHeader="true">
              <pro-table-header-cell
                v-for="(sort, index) in sort_list"
                :key="index"
                :class="sort.classes"
                :sortingBy="sorting_by"
                :hasSort="sort.sortKey ? true : false"
                :title="sort.title"
                :sortKey="sort.sortKey"
              />
            </pro-table-row>
            <pro-table-row
              v-for="(item, index) in offresDossierPromoteurs"
              :key="index"
              class="relative"
            >
              <pro-table-cell label="Statut" class="w-1/12 lg:w-full">
                <div
                  :class="`rounded-full ml-3 w-3 h-3 mr-1 bg-promy-${getStatusColor(
                    item.status,
                  )}`"
                ></div>
              </pro-table-cell>
              <pro-table-cell label="ID Offre" class="w-3/12 lg:w-full">
                {{ item.uuid }}
              </pro-table-cell>
              <pro-table-cell label="Offre" class="w-1/6 lg:w-full">
                € {{ item.offre | formatEUR }}
              </pro-table-cell>
              <pro-table-cell label="Date" class="w-1/6 lg:w-full">
                {{ item.created_at | filterDate }}
              </pro-table-cell>
              <pro-table-cell label="Utilisateur" class="w-1/6 lg:w-full">
                {{ item.promoteur.user.nom }} {{ item.promoteur.user.prenom }}
              </pro-table-cell>

              <pro-table-cell :actions_btn="true" class="w-1/6 lg:w-full">
                <div class="w-full flex justify-end items-center space-x-3">
                  <router-link
                    class="btn-action bg-promy-green-200"
                    :to="{
                      name: 'dossier-promoteur-offres-offre',
                      params: {
                        dossier_promoteur_id: $route.params.id,
                        offre_id: item.uuid,
                      },
                    }"
                  >
                    <i class="fas fa-eye"></i>
                  </router-link>
                  <div
                    class="btn-action bg-promy-green-200"
                    @click="changeOffreStatus(item.id, Status[0], item.status)"
                  >
                    <i class="fas fa-check-square"></i>
                  </div>
                  <div
                    class="btn-action bg-promy-orange-100"
                    @click="changeOffreStatus(item.id, Status[1], item.status)"
                  >
                    <i class="fas fa-check-square"></i>
                  </div>
                  <div
                    class="btn-action bg-promy-red"
                    @click="changeOffreStatus(item.id, Status[2], item.status)"
                  >
                    <i class="fas fa-check-square"></i>
                  </div>
                </div>
              </pro-table-cell>
            </pro-table-row>
          </pro-card>
          <template v-if="offresDossierPromoteurs.length > 0">
            <isVisible :isFetching="isFetching" @isVisible="isVisible" />
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getOffresDossieurPromoteur()
  },
  data() {
    return {
      sort_list: [
        {
          title: 'statut',
          sortKey: '',
          classes: 'w-1/12',
        },
        {
          title: 'ID Offre',
          sortKey: '',
          classes: 'w-3/12',
        },
        {
          title: 'OFFRE',
          sortKey: '',
          classes: 'w-1/6',
        },
        {
          title: 'DATE',
          sortKey: '',
          classes: 'w-1/6',
        },
        {
          title: 'UTILISATEUR',
          sortKey: '',
          classes: 'w-1/6',
        },
      ],
      sorting_by: '',
      offresDossierPromoteurs: [],
      Status: ['Gagnée', 'En cours', 'Perdu'],
      current_page: 1,
      last_page: 1,
      total_page: 1,
      isFetching: false,
    }
  },
  methods: {
    changeOffreStatus(id, status, currentStatus) {
      this.isFetching = true
      if (status === currentStatus) {
        this.$toaster.warning(`le statut est déjà ${status}`)
        this.isFetching = false
      } else {
        this.$http
          .get(`admin/dossier-promoteurs/offres/${id}/${status}`)
          .then((response) => {
            Array.isArray(response.data)
              ? (this.offresDossierPromoteurs = response.data)
              : this.$toaster.warning(response.data.message)
            this.isFetching = false
          })
          .catch((error) => {
            this.$toaster.warning('Une erreur est survenue')
            this.isFetching = false
          })
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'Gagnée':
          return 'green-200'
        case 'En cours':
          return 'orange-100'
        case 'Perdu':
          return 'red'
      }
    },
    getOffresDossieurPromoteur() {
      this.isFetching = true
      this.$http
        .get(
          `admin/dossier-promoteurs/${this.$route.params.id}/offres?page=${this.current_page}`,
        )
        .then((response) => {
          this.offresDossierPromoteurs.push(...response.data.data)
          this.current_page = response.data.current_page
          this.last_page = response.data.last_page
          this.isFetching = false
        })
    },
    isVisible() {
      if (this.last_page === this.current_page) {
        return
      }
      this.current_page++
      this.getOffresDossieurPromoteur()
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-action {
  @apply flex items-center justify-center w-10 h-10 mr-2 text-white rounded-full cursor-pointer;
}
</style>
