<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des occupations </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>{{ action }} occupation</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('occupation-list')"
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <div
        class="grid grid-cols-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
      >
        <div>
          <pro-textarea
            name="occupation"
            requirements="required"
            v-model="form.occupation"
            label="occupation"
          />
        </div>
      </div>
      <div class="my-6">
        <BaseButton
          v-if="$can('edite,gestion_utilisateur')"
          @handleClick="handleSubmit(save)"
          classes="!py-2 !w-35"
          :loading="loading"
        />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      action: 'Ajouter',
      storeURL: 'admin/occupations',
      form: {
        occupation: '',
      },
      loading: false,
    }
  },
  created() {
    this.checkCurrentMode()
  },
  methods: {
    checkCurrentMode() {
      if (this.$route.meta.mode !== 'edit') return
      this.storeURL = `admin/occupations/${this.$route.params.id}?_method=PUT`
      this.action = 'Editer'
      this.editableOccupation(this.$route.params.id)
    },
    editableOccupation(id) {
      this.$http.get(`admin/occupations/${id}`).then((res) => {
        this.form = res.data
      })
    },
    save() {
      this.loading = true
      this.$http
        .post(this.storeURL, this.form)
        .then(() => {
          this.$toaster.success('vous avez ajouté occupation avec succès')
          this.$router.push({
            name: 'occupation-list',
          })
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$refs.form.setErrors(err.response.data.errors)
        })
    },
  },
}
</script>
