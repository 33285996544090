<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="23"
    viewBox="0 0 12 23"
    :class="`fill-current ${color}`"
  >
    <g
      id="Groupe_128"
      data-name="Groupe 128"
      transform="translate(-202.364 -131.971)"
    >
      <path
        id="Tracé_1"
        data-name="Tracé 1"
        d="M213.164,154.971h-3.273v-.67h3.273a.553.553,0,0,0,.545-.558V133.2a.553.553,0,0,0-.545-.558h-9.6a.553.553,0,0,0-.545.558v20.544a.553.553,0,0,0,.545.558h3.273v.67h-3.273a1.215,1.215,0,0,1-1.2-1.228V133.2a1.215,1.215,0,0,1,1.2-1.228h9.6a1.215,1.215,0,0,1,1.2,1.228v20.544A1.215,1.215,0,0,1,213.164,154.971Z"
        class="fill-icon"
      ></path>
      <line
        :class="`stroke-current ${color}`"
        id="Ligne_1"
        data-name="Ligne 1"
        x2="7.418"
        transform="translate(204.655 136.214)"
        fill="none"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.655"
      ></line>
      <line
        :class="`stroke-current ${color}`"
        id="Ligne_2"
        data-name="Ligne 2"
        x2="7.418"
        transform="translate(204.655 140.233)"
        fill="none"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.655"
      ></line>
      <line
        :class="`stroke-current ${color}`"
        id="Ligne_3"
        data-name="Ligne 3"
        x2="7.418"
        transform="translate(204.655 143.806)"
        fill="none"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.655"
      ></line>
      <line
        :class="`stroke-current ${color}`"
        id="Ligne_4"
        data-name="Ligne 4"
        x2="7.418"
        transform="translate(204.655 147.379)"
        fill="none"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="0.655"
      ></line>
      <path
        id="Tracé_2"
        data-name="Tracé 2"
        d="M209.782,154.954v-2.493a1.434,1.434,0,1,0-2.836,0v2.509l-.655,0v-2.505a2.1,2.1,0,1,1,4.145,0v2.486"
        class="fill-icon"
      ></path>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-white',
    },
  },
}
</script>
