<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.4375 9.19531H4.53906C4.50177 9.19531 4.466 9.21013 4.43963 9.2365C4.41325 9.26287 4.39844 9.29864 4.39844 9.33594V10.1797C4.39844 10.217 4.41325 10.2528 4.43963 10.2791C4.466 10.3055 4.50177 10.3203 4.53906 10.3203H6.4375V12.2188C6.4375 12.256 6.45232 12.2918 6.47869 12.3182C6.50506 12.3446 6.54083 12.3594 6.57812 12.3594H7.42188C7.45917 12.3594 7.49494 12.3446 7.52131 12.3182C7.54768 12.2918 7.5625 12.256 7.5625 12.2188V10.3203H9.46094C9.49823 10.3203 9.534 10.3055 9.56037 10.2791C9.58675 10.2528 9.60156 10.217 9.60156 10.1797V9.33594C9.60156 9.29864 9.58675 9.26287 9.56037 9.2365C9.534 9.21013 9.49823 9.19531 9.46094 9.19531H7.5625V7.29688C7.5625 7.25958 7.54768 7.22381 7.52131 7.19744C7.49494 7.17107 7.45917 7.15625 7.42188 7.15625H6.57812C6.54083 7.15625 6.50506 7.17107 6.47869 7.19744C6.45232 7.22381 6.4375 7.25958 6.4375 7.29688V9.19531ZM13.0223 4.0748C13.1277 4.18027 13.1875 4.32266 13.1875 4.47207V15.3125C13.1875 15.6236 12.9361 15.875 12.625 15.875H1.375C1.06387 15.875 0.8125 15.6236 0.8125 15.3125V0.6875C0.8125 0.376367 1.06387 0.125 1.375 0.125H8.84043C8.98984 0.125 9.13398 0.184766 9.23945 0.290234L13.0223 4.0748ZM11.8902 4.73047L8.58203 1.42227V4.73047H11.8902Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
}
</script>

<style></style>
