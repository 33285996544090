<template>
  <div class="mt-24">
    <h2 class="text-lg tracking-wide font-main text-gray-800 font-bold">
      Images
    </h2>
    <div
      class="grid md:grid-cols-1 grid-cols-3 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34 mt-8"
    >
      <div>
        <p class="capitalize text-xs text-gray-600 font-bold font-arial">
          Société
        </p>
        <pro-uploader name="societe" v-model="form.societe" />
      </div>
      <div>
        <p class="text-xs text-gray-600 font-bold font-arial">
          Logo de la société
        </p>

        <pro-uploader name="logo de la société" v-model="form.logo_path" />
      </div>
      <div class="sm:w-full flex flex-col">
        <label class="text-xs text-promy-gray-400"> KBIS </label>
        <div
          class="w-2/3 lg:w-full h-45 relative border-2 border-dashed border-promy-gray-400 rounded-lg flex items-center justify-center mt-2"
        >
          <div class="text-promy-gray-400 text-3xl">
            <i class="fa fa-upload fa-6" aria-hidden="true"></i>
          </div>
          <input
            ref="fileinput"
            accept="image/jpeg, image/jpg, image/png,application/pdf"
            @change.prevent="handleFileChange"
            class="w-full absolute opacity-0 h-full inset-0 cursor-pointer"
            type="file"
            @click="
              (event) => {
                event.target.value = null
              }
            "
          />
        </div>
        <div
          v-if="form.kbis_path !== null"
          class="flex justify-between items-center space-x-2 w-3/4 mt-4 px-4 py-2 border rounded-xl bg-white"
        >
          <p v-if="typeof form.kbis_path === 'object'">
            {{ form.kbis_path | fileName }}
          </p>
          <div v-else>
            <a
              :href="$base_file + '' + form.kbis_path"
              target="_blank"
              class="underline"
            >
              KBIS.pdf
            </a>
          </div>
          <span
            class="leading-none text-2xl cursor-pointer"
            @click="deleteFile()"
            >&#10005;</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProUploader from '../../../../../components/UI/File/ProUploader.vue'
export default {
  components: { ProUploader },
  model: {
    prop: 'form',
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleFileChange(e) {
      this.form.kbis_path = e.target.files[0]
    },
    deleteFile() {
      this.form.kbis_path = null
    },
    isBase64(str) {
      return str.hasOwnProperty('name')
    },
  },
  filters: {
    fileName(value) {
      let name
      if (value.name) {
        name = value.name
      } else {
        name = value.split('/').slice(-1)
      }
      if (name.length > 30) return name.slice(1, 20).concat('....')
      else return name
    },
  },
}
</script>
