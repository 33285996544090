<template>
  <div>
    <div
      class="flex mt-6"
      v-if="Object.keys(catastrophes_naturelles).length"
      id="catasrophesNaturelles"
    >
      <accordion class="w-full rounded-lg accordion-ipad">
        <accordion-item class="shadow-box accordion-item">
          <template slot="accordion-trigger">
            <a
              class="flex justify-between md:flex-wrap w-full py-3 px-5 cursor-pointer"
              @click="activingAccordionInfo(0)"
            >
              <div class="md:w-full md:flex md:justify-center">
                <div
                  class="bg-promy-green-200 rounded-full my-4 mx-6 flex justify-center items-center"
                >
                  <component
                    is="CatastrophenNaturelIcon"
                    class="w-26 md:w-18 h-auto p-5 md:m-0"
                  ></component>
                </div>
              </div>
              <div class="w-9/10 mt-2 sm:w-8/10 flex items-center">
                <span
                  class="text-promy-green-400 font-extrabold text-xl sm:text-base font-main flex items-center pb-2"
                >
                  Arrêtés portant reconnaissance de catastrophes naturelles sur
                  la commune
                </span>
              </div>
              <div
                class="h-8 w-8 rounded-full flex justify-center items-center self-center"
                :class="{
                  'bg-promy-green-200': active_accordion_info === 0,
                }"
              >
                <img
                  src="/images/DossierPromoteur/up-arrow.svg"
                  v-if="active_accordion_info === 0"
                  class="h-3 w-3 down-arrow"
                />
                <img
                  src="/images/DossierPromoteur/up-arrow-gray.svg"
                  class="h-3 w-3 up-arrow"
                  v-else
                />
              </div>
            </a>
          </template>
          <template slot="accordion-content">
            <div class="p-6 px-6 border-t-2 cursor-auto">
              <div
                v-for="(historiques, name, index) in catastrophes_naturelles"
                :key="name"
                class="mb-8"
              >
                <div class="mb-4 text-sm font-extrabold flex items-center">
                  <div class="text-promy-gray-400">{{ name }} :</div>
                  <div
                    class="bg-promy-green-200 rounded-full text-white flex justify-center items-center ml-2"
                    style="min-width: 1.75rem; min-height: 1.75rem"
                  >
                    {{ historiques.length }}
                  </div>
                </div>

                <ProCollapseTable
                  class="hidden lg:flex"
                  :keys="keys_CTN"
                  :items="historiques"
                ></ProCollapseTable>
                <table class="table-auto w-full lg:hidden text-sm">
                  <thead class="bg-promy-gray-225">
                    <tr class="bg-promy-gray-100 rounded-t-box text-left">
                      <th class="rounded-tl-box">Code national CATNAT</th>
                      <th>Début le</th>
                      <th>Fin le</th>
                      <th>Arrêté du</th>
                      <th class="rounded-tr-box">Sur le Journal Officiel du</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(historique, index) in historiques" :key="index">
                      <td>
                        {{ historique.codeNatCatnat }}
                      </td>
                      <td>
                        {{ historique.dateDeb }}
                      </td>
                      <td>
                        {{ historique.dateFin }}
                      </td>
                      <td>
                        {{ historique.datePubliArrete }}
                      </td>
                      <td>
                        {{ historique.datePubliJO }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </accordion-item>
      </accordion>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active_accordion: -1,
      active_accordion_info: -1,
      keys_CTN: {
        codeNatCatnat: 'Code national CATNAT',
        dateDeb: 'Début le',
        dateFin: 'Fin le',
        datePubliArrete: 'Arrêté du',
        datePubliJO: 'Sur le Journal Officiel du',
      },
    }
  },
  props: {
    catastrophes_naturelles: {
      type: Object,
      required: true,
    },
  },

  methods: {
    activingAccordionInfo(idx) {
      this.active_accordion_info = this.active_accordion_info === idx ? -1 : idx
    },
  },
}
</script>
<style lang="scss" scoped>
thead {
  tr {
    @apply rounded-t-lg;
  }
}
th,
td {
  @apply py-2 px-3;
}

tbody tr:nth-child(even) {
  @apply bg-promy-gray-125;
}
</style>
