<template>
  <button
    class="font-extrabold text-base flex justify-center items-center py-1 mr-3 w-1/2"
    :class="!is_parcelle_changed ? 'btn-green' : ' btn-white'"
    @click="addListenerClickChangeParcelle"
  >
    <template v-if="!is_parcelle_changed">
      <span>Changer</span>
    </template>

    <template v-else>Valider</template>
  </button>
</template>

<script>
import Mapbox from '@/mixins/mapbox'
import parcelle from '@/mixins/cadastre/parcelle'
import batiment from '@/mixins/cadastre/batiment'
import informations_map from '@/mixins/dossierPromoteurs/informations/map'
import helpers from '@/mixins/helpers'
import calculeHelpers from '@/mixins/cadastre/calculeHelpers'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'

export default {
  mixins: [
    helpers,
    calculeHelpers,
    Mapbox,
    parcelle,
    batiment,
    sourcesAndLayers,
    informations_map,
  ],
  props: {
    informations: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addListenerClickChangeParcelle() {
      if (this.is_parcelle_aggregate) {
        this.$toaster.warning('Veuillez valider vos choix de parcelles ')
      } else if (this.is_corner_changed) {
        this.$toaster.warning('Veuillez valider vos choix des points ')
      } else if (!this.is_parcelle_changed) {
        this.$store.commit('parcelle/IS_PARCELLE_CHANGED', true)
        this.map.on('click', this.selectParcelle)
      } else {
        this.$emit('checkConfirmationForSelectParcelle')
      }
    },
    validateSelectParcelle(is_confirm = true) {
      this.map.off('click', this.selectParcelle)
      if (is_confirm) this.$toaster.success('La parcelle a bien été modifiée')
      this.$store.commit('parcelle/IS_PARCELLE_CHANGED', false)
    },
    selectParcelle(e) {
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: [this.layer_background_parcelle_autour],
      })
      var parcelle_selected = this.parcelles_autour[features[0].id]
      parcelle_selected.id = features[0].id
      if (
        parcelle_selected.properties.id !==
        this.informations.current_parcelle.properties.id
      ) {
        this.resetParcelleVoisines()
        this.setInfosParcelles([
          parcelle_selected,
          ...this.informations.parcelles_voisines,
        ])
      }

      this.informations.current_parcelle = parcelle_selected
      this.removeSourceAndLayers(...this.source_layers_selected_parcelle)
      this.removeSourceAndLayers(...this.first_source_layers_parcelle)
      this.addPolygonsToMap(
        this.informations.current_parcelle,
        this.source_layers_selected_parcelle,
      )
    },
  },
}
</script>
