import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      moduleStore: 'defaultmap',
    }
  },

  methods: {
    addLayersParcelles(allParcelles) {
      this.addSourceLayersMultiGeojsonToMap(
        allParcelles,
        this.purple,
        this.source_layers_all_parcelle,
      )
    },
    fitBoundsParcelles(allParcelles) {
      let fc = this.$turf.featureCollection(allParcelles)
      this.fitBoundsGeojsonInMap(fc)
    },
  },
  computed: {
    ...mapGetters({
      informationsStore: 'dossierpromoteur/getinfos',
      base_layers: 'defaultmap/base_layers',
      saved_sources: 'defaultmap/saved_sources',
      saved_layers: 'defaultmap/saved_layers',
      map_is_style_loaded: 'defaultmap/map_is_style_loaded',
    }),
  },
}
