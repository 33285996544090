<template>
  <div>
    <h2 class="text-lg font-bold tracking-wide text-gray-800 font-main"></h2>
    <div
      class="grid grid-cols-3 mt-8 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
    >
      <pro-select
        requirements="required"
        name="profile"
        label="Profil"
        v-model="form.profile"
        :selection="list_profiles"
      ></pro-select>
      <div>
        <pro-select
          requirements="required"
          name="type"
          label="type"
          v-model="form.types"
          :selection="list_type"
        ></pro-select>
      </div>
      <pro-input
        name="siren"
        requirements="required"
        v-model="form.siren"
        label="Siren"
      />
      <pro-input
        requirements="required"
        name="capital social"
        v-model="form.capital_social"
        label="capital social"
      />
      <pro-input name="n voie" v-model="form.n_voie" label="N voie" />
      <pro-input name="commune" v-model="form.commune" label="commune" />
      <pro-input
        name="comptabilite"
        v-model="form.comptabilite"
        label="Comptabilité"
      />
    </div>
    <div class="mt-4">
      <div class="relative">
        <label class="text-xs font-bold text-gray-600 font-arial">
          Promoteur
        </label>
        <div
          class="flex items-center justify-between sm:flex-col sm:items-start space-x-4 sm:space-x-0 sm:space-y-4"
        >
          <div class="relative w-3/4 sm:w-full">
            <ProInput
              name="Nom@gmail.com"
              v-click-outside="closeSelected"
              v-model="selected_user.user.email"
              @click.native="toggleVisibility()"
            />
            <div class="absolute w-full z-50">
              <div
                v-show="openSelected"
                v-for="item in promoteursNoTeam"
                @click="selected_user = item"
                :key="item.user.email"
                class="p-2 -mt-2 mx-2 bg-white border rounded-lg duration-200 cursor-pointer hover:bg-promy-green-300 hover:text-white"
              >
                <span>{{ item.user.email }}</span>
              </div>
            </div>
          </div>
          <button
            @click="addItem(selected_user)"
            class="flex justify-center w-1/4 sm:w-full py-2 text-sm font-bold text-white uppercase transition-colors duration-200 border rounded-full bg-promy-green-300 hover:text-promy-green-300 hover:border-promy-green-300 hover:bg-white font-main"
          >
            Ajouter a la liste
          </button>
        </div>
        <div class="my-8">
          <accordion :active_first="true">
            <accordion-item class="bg-promy-gray-225">
              <template slot="accordion-trigger">
                <div
                  class="w-full flex items-baseline space-x-3 py-4 px-6 font-bold text-xs text-promy-gray-650"
                  @click="active_accordion = !active_accordion"
                >
                  <p>EMAIL</p>
                  <i
                    :class="[
                      !active_accordion
                        ? 'fas fa-chevron-up'
                        : 'fas fa-chevron-down',
                    ]"
                  />
                </div>
              </template>
              <template slot="accordion-content">
                <div class="">
                  <div
                    v-for="item in selectedItems"
                    :key="item.user.email"
                    class="selected-user flex justify-between items-center px-6 py-2 bg-promy-gray-125"
                  >
                    <div class="text-sm text-promy-gray-650">
                      {{ item.user.email }}
                    </div>
                    <div class="flex justify-between items-center space-x-2">
                      <div class="btn-actions" @click="rememberUser(item)">
                        <i class="fas fa-edit"></i>
                      </div>
                      <div
                        v-if="item.id != $store.state.auth.user.id"
                        class="btn-actions"
                        @click="removeItem(item)"
                      >
                        <i class="fas fa-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </accordion-item>
          </accordion>
        </div>
      </div>
    </div>

    <Modal v-if="showModalInfosPromoteur">
      <slot slot="header">
        <div class="text-base tracking-wide font-main text-gray-800 font-bold">
          Infos promoteur
        </div>
      </slot>
      <slot slot="body">
        <div>
          <pro-input
            name="nom"
            requirements="required"
            v-model="selected_user_to_update.nom"
            label="Nom"
          />
          <pro-input
            name="prenom"
            requirements="required"
            v-model="selected_user_to_update.prenom"
            label="Prénom"
          />
          <InputPhoneNumber
            name="Tél Mobile"
            :rules="'required'"
            v-model="selected_user_to_update.telephone_mobile"
            placeholder="+33"
            label="Tél Mobile"
          />
          <InputPhoneNumber
            name="Tél Fax"
            v-model="selected_user_to_update.telephone_fix"
            placeholder="+33"
            label="Tél Fax"
          />
          <pro-input
            requirements="required|email"
            v-model="selected_user_to_update.email"
            label="Email"
            name="email"
            :disabled="true"
          />
          <pro-input
            name="mot de passe"
            v-model="selected_user_to_update.password"
            type="password"
            label="Mot de passe"
          />
          <pro-input
            name="confirmer mot de passe"
            v-model="selected_user_to_update.password_confirmation"
            type="password"
            label="Confirmation de mot de passe"
          />
          <pro-input
            requirements="required"
            v-model="selected_user_to_update.qualite_societe"
            name="Qualité social"
            label="Qualité sociale"
          />
        </div>
      </slot>
      <slot slot="footer">
        <div class="flex items-center space-x-4">
          <button
            @click="updateUser()"
            class="px-12 py-2 text-sm font-bold text-white uppercase transition-colors duration-200 border rounded-full bg-promy-green-300 hover:text-promy-green-300 hover:border-promy-green-300 hover:bg-white font-main"
          >
            valider
          </button>
          <button
            @click="showModalInfosPromoteur = false"
            class="px-12 py-2 text-sm font-bold uppercase transition-colors duration-200 border bg-white rounded-full text-promy-green-300 hover:bg-promy-green-300 border-promy-green-300 hover:text-white font-main"
          >
            Annuler
          </button>
        </div>
      </slot>
    </Modal>
  </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
    onClickaway: onClickaway,
  },

  data() {
    return {
      selected_user_to_update: null,
      selected_user: {
        user: {
          email: '',
        },
      },
      showModalInfosPromoteur: false,
      selectedItems: [],
      openSelected: false,
      promoteursNoTeam: [],
      list_profiles: [
        'Constructeur Maison Individuelle (CMI)',
        'Lotisseur Aménageurs (AL)',
        'Promoteur Régional (PR)',
        'Promoteur National (PN)',
        'Autre',
      ],
      list_type: [
        'SA',
        'SAS',
        'SASU',
        'SARL',
        'EURL',
        'SCS',
        'SCA',
        'SNC',
        'SASP',
        'SE',
        'SCI',
        'SCP',
        'SCM',
        'SEL',
        'SELARL',
        'SELURL',
        'EI',
        'EIRL',
        'Autre',
      ],
      active_accordion: true,
    }
  },

  created() {
    this.getPromoteurWhoDoNotHaveTeam()
  },
  watch: {
    selected_user: {
      deep: true,
      handler(value) {
        if (value.user.email)
          this.getPromoteurWhoDoNotHaveTeam(value.user.email)
      },
    },
    'form.promoteurs': {
      immediate: true,
      handler(value) {
        if (value && value.length > 0) {
          this.form.promoteur_id = []
          value.forEach((item) => {
            this.selectedItems.push(item)
            this.form.promoteur_id.push(item.id)
          })
        }
      },
    },
  },
  methods: {
    updateUser() {
      this.$http
        .put(
          `admin/promoteurs/${this.$route.params.id}`,
          this.selected_user_to_update,
        )
        .then(() => {
          this.$toaster.success("vous avez modifié l'utilisateur avec succès")
        })
      this.showModalInfosPromoteur = false
      this.selected_user_to_update = null
    },
    rememberUser(item) {
      this.selected_user_to_update = item.user
      this.selected_user_to_update.qualite_societe = item.qualite_societe
      this.showModalInfosPromoteur = true
    },
    toggleVisibility() {
      this.openSelected = !this.openSelected
    },
    closeSelected() {
      this.openSelected = false
    },
    addItem(item) {
      if (!this.form.promoteur_id) {
        this.form.promoteur_id = []
      }
      const idx = this.selectedItems.findIndex(
        (selected_item) => selected_item.id === item.id,
      )
      if (idx === -1) {
        this.selectedItems.push(item)
        this.form.promoteur_id.push(item.id)
        this.promoteursNoTeam.splice(this.promoteursNoTeam.indexOf(item), 1)
      }
      this.selected_user = {
        user: {
          email: '',
        },
      }
    },
    removeItem(item) {
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
      this.form.promoteur_id = this.form.promoteur_id.filter(
        (id) => id != item.id,
      )
      const idx = this.promoteursNoTeam.findIndex(
        (selected_item) => selected_item.id === item.id,
      )
      if (idx === -1) this.promoteursNoTeam.push(item)
    },
    getPromoteurWhoDoNotHaveTeam(search = '') {
      this.$http
        .get('promoteurs/noteam', {
          params: {
            q: search,
          },
        })
        .then((res) => {
          this.promoteursNoTeam = res.data.promoteurs.filter(
            (promoteur) => promoteur.user,
          )
        })
    },
  },
  model: {
    prop: 'form',
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-actions {
  @apply flex items-center justify-center w-8 h-8 text-white rounded-full cursor-pointer bg-promy-green-300;
}
.selected-user:nth-child(odd) {
  background: white !important;
}
.selected-user:last-child {
  @apply rounded-b-lg;
}
</style>
