import { render, staticRenderFns } from "./TablesRisques.vue?vue&type=template&id=5309eb52&scoped=true&"
import script from "./TablesRisques.vue?vue&type=script&lang=js&"
export * from "./TablesRisques.vue?vue&type=script&lang=js&"
import style0 from "./TablesRisques.vue?vue&type=style&index=0&id=5309eb52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5309eb52",
  null
  
)

export default component.exports