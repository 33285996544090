<template>
  <div class="font-arial font-normal" :class="classes">
    <p class="text-xs text-promy-gray-500">
      {{ label }}
    </p>
    <p class="text-base text-ellipsis overflow-hidden" v-if="!noValue">
      {{ value }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    classes: {
      type: String,
      default: '',
    },
    noValue: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
