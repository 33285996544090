<template>
  <div>
    <label
      v-if="label"
      :for="uniqueId"
      class="text-xs flex justify-between mb-1 text-promy-gray-500"
      >{{ label }} <span v-if="label_value">{{ label_value }}</span></label
    >
    <div class="relative">
      <img
        :src="[
          input_type === 'password'
            ? '/images/icons/eye-off-outline.svg'
            : '/images/icons/eye-outline.svg',
        ]"
        class="absolute top-2 right-4 cursor-pointer"
        @click.stop="showPassword()"
      />
      <input
        class="disabled:border-promy-gray-300 disabled:bg-promy-gray-300 focus:outline-none focus:bg-white focus:border-promy-green-200 rounded-full pr-12 text-promy-gray-600 border-solid border w-full border-promy-gray-300"
        @click="showRule = true"
        v-on-clickaway="away"
        :id="uniqueId"
        :ref="uniqueId"
        :dir="dir"
        :name="name ? name : label"
        :type="input_type"
        :placeholder="placeholder ? placeholder : label"
        :value="value"
        @input="handleInput($event.target.value)"
        :disabled="disabled"
        :class="[
          errors[0] ? 'border-promy-red-100' : '',
          !clearBG ? 'bg-promy-gray-background-300' : 'bg-white',
          placeholderAnimation ? 'hide-placeholder' : '',
          $route.name === 'Login' ? 'pl-8' : 'pl-4',
          classes,
        ]"
        autocomplete="off"
      />
      <span
        v-if="placeholderAnimation"
        @click="autoFocus()"
        class="new-placeholder absolute left-4 top-[9px] text-promy-gray-500 transition duration-200 ease-in-out"
      >
        {{ placeholder }}
      </span>
    </div>
    <div
      v-if="with_rule && showRule && !password_is_valid"
      class="absolute w-full z-20 mt-1 rounded-md border bg-promy-gray-background-300 px-2 py-3 text-xs"
    >
      <p>
        Votre mot de passe doit contenir 8 caractères 1 minuscule 1 majuscule 1
        nombre et 1 caractère spécial
      </p>
      <div class="mt-1">
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errors.eightcharacter
                ? 'text-promy-gray-570'
                : 'text-promy-green-200'
            "
          ></i>
          8 caractères
        </div>
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errors.uppercase ? 'text-promy-gray-570' : 'text-promy-green-200'
            "
          ></i>
          Majuscule
        </div>
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errors.lowercase ? 'text-promy-gray-570' : 'text-promy-green-200'
            "
          ></i>
          Minuscule
        </div>
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errors.number ? 'text-promy-gray-570' : 'text-promy-green-200'
            "
          ></i>
          Nombre
        </div>
        <div class="mt-1">
          <i
            class="fas fa-check-circle"
            :class="
              errors.specialcase
                ? 'text-promy-gray-570'
                : 'text-promy-green-200'
            "
          ></i>
          Caractères non alphanumériques
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    with_rule: {
      type: Boolean,
      default: true,
    },
    dir: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: 'py-2',
    },
    label: {
      type: String,
      default: null,
    },
    label_value: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    rules: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    clearBG: {
      type: Boolean,
      default: false,
    },
    placeholderAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input_type: 'password',
      showRule: false,
      errors: {
        uppercase: true,
        lowercase: true,
        specialcase: true,
        number: true,
        eightcharacter: true,
      },
      password_is_valid: false,
    }
  },
  methods: {
    showPassword() {
      this.input_type = this.input_type === 'password' ? 'text' : 'password'
    },
    away() {
      this.showRule = false
    },
    handleInput(value) {
      this.inputValidation(value)
      this.shouldSubmitForm()
      this.$emit('input', value)
    },
    shouldSubmitForm() {
      this.password_is_valid = Object.values(this.errors).every(
        (value) => value === false,
      )
      this.$emit('passwordInputIsValidated', this.password_is_valid)
    },
    inputValidation(value) {
      this.errors.uppercase = /[A-Z]/.test(value) ? false : true
      this.errors.lowercase = /[a-z]/.test(value) ? false : true
      this.errors.number = /\d/.test(value) ? false : true
      this.errors.specialcase = /[#?!@$%^&*-]/.test(value) ? false : true
      this.errors.eightcharacter = value.length >= 8 ? false : true
    },
    autoFocus() {
      this.$refs[this.uniqueId].focus()
    },
  },
  computed: {
    uniqueId() {
      return `input-${this._uid}`
    },
  },
}
</script>

<style lang="scss" scoped>
// animation for placeholder
.hide-placeholder::placeholder {
  color: transparent !important;
}

.new-placeholder {
  transform-origin: 0 -5px;
}

.hide-placeholder:not(:placeholder-shown) + span {
  transform: translateY(2.1rem) scale(0.9);
}
</style>
