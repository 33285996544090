<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des agent immobiliers </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>{{ action }} un agent immobilier</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('list-des-agents-immobilier')"
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <div
        class="grid grid-cols-3 mt-8 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
      >
        <div>
          <pro-input
            name="nom"
            requirements="required"
            v-model="form.nom"
            label="Nom"
          />
          <pro-input
            name="prenom"
            requirements="required"
            v-model="form.prenom"
            label="Prénom"
          />
          <InputPhoneNumber
            name="Tél Mobile"
            :rules="'required'"
            v-model="form.telephone_mobile"
            placeholder="+33"
            label="Tél Mobile"
          />
          <InputPhoneNumber
            name="Tél Fax"
            v-model="form.telephone_fix"
            placeholder="+33"
            label="Tél Fax"
          />
        </div>
        <div>
          <pro-input
            requirements="required|email"
            v-model="form.email"
            label="Email"
            name="email"
            :disabled="!isCreate"
          />
          <pro-input
            v-model="form.password"
            type="password"
            label="Mot de pass"
          />
          <div>
            <pro-input
              :requirements="passwordConfirmationRequirement"
              name="Confirmation de mot de passe"
              v-model="form.password_confirmation"
              type="password"
              label="Confirmation de mot de passe"
            />
            <span
              class="text-promy-red text-sm"
              v-if="passwordConfirmationError"
              >Les mots de passe doivent être identiques</span
            >
          </div>
          <pro-input
            requirements="required"
            v-model="form.qualite_societe"
            name="Qualité social"
            label="Qualité social"
          />
        </div>
        <div>
          <div class="text-xs font-bold text-gray-600 capitalize font-arial">
            titre
          </div>
          <validation-provider rules="required" v-slot="{ errors }">
            <div class="flex items-center my-3">
              <div class="mr-8">
                <input
                  name="is admin"
                  requirements="required"
                  type="checkbox"
                  id="isadmin"
                  v-model="form.is_team_admin"
                />
                <label for="isadmin"></label>
              </div>
              <span class="text-sm text-gray-600 capitalize"> admin </span>
            </div>
            <span class="mt-1 text-sm text-red-600" v-if="errors[0]">
              {{ errors[0] }}
            </span>
          </validation-provider>
          <div class="text-xs font-bold text-gray-600 capitalize font-arial">
            titre
          </div>
          <div>
            <validation-provider rules="required" v-slot="{ errors }">
              <div class="flex items-center my-3">
                <div class="mr-8">
                  <input
                    name="is validé"
                    type="checkbox"
                    id="is_valide"
                    v-model="form.is_valide"
                  />
                  <label for="is_valide"></label>
                </div>
                <span class="text-sm text-gray-600 capitalize"> Validé </span>
              </div>
              <span class="mt-1 text-sm text-red-600" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </validation-provider>
          </div>
        </div>
        <div v-on-clickaway="away" class="mt-4">
          <div class="relative">
            <label class="text-xs font-bold text-gray-600 font-arial"
              >Team</label
            >
            <div
              @click="toggleVisibility()"
              class="w-full px-4 py-2 mt-1 text-gray-600 border border-solid rounded-full focus:border-promy-green-300 border-promy-gray-300 bg-promy-gray-background-300"
            >
              <div>
                <template v-if="selectedItems.length > 0">
                  <div class="flex">
                    <div v-for="item in selectedItems" :key="item.id">
                      <div
                        class="flex justify-between px-4 py-1 mr-4 text-sm text-white rounded-full bg-promy-green-300"
                      >
                        <span class="mr-3">{{ item.raison_social }}</span>
                        <span @click="removeItem(item)" class="cursor-pointer"
                          >x</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>choisir un élément...</template>
              </div>
            </div>
            <template v-if="openSelected">
              <div
                class="absolute left-0 flex flex-col w-full bg-white border rounded-lg"
              >
                <input v-model="search" type="text" class="px-2 py-1 border" />
                <div
                  v-for="item in teams"
                  @click="addItem(item)"
                  :key="item.id"
                  class="px-2 py-1 transition-colors duration-200 cursor-pointer hover:bg-promy-green-300 hover:text-white"
                >
                  <span>{{ item.raison_social }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="my-12 flex gap-x-4">
        <BaseButton @handleClick="handleSubmit(save)" :loading="loading" />
        <button
          v-if="!isCreate"
          @click="sendEmailPassword()"
          class="px-12 py-2 text-sm font-bold text-promy-green-300 uppercase transition-colors duration-200 border rounded-full bg-white hover:text-white border-promy-green-300 hover:bg-promy-green-300 font-main"
        >
          Envoyer Email d'invitation
        </button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { toMulipartedForm } from '../../../../utilities/form'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      search: '',
      action: 'Ajouter',
      storeURL: 'admin/agent-immobiliers',
      openSelected: false,
      selectedItems: [],
      teams: [],
      form: {
        nom: '',
        prenom: '',
        email: '',
        qualite_societe: '',
        telephone_mobile: '',
        telephone_fix: '',
        password: '',
        password_confirmation: null,
        raison_social: '',
        is_team_admin: false,
        is_valide: false,
        is_first: false,
        type: 'agent-immobilier',
        team_id: [],
        team_agent_immobilier: [],
      },
      error: [],
      loading: false,
    }
  },
  created() {
    this.checkCurrentMode()
    this.getTeams()
  },
  computed: {
    passwordRequirement() {
      return this.isCreate ? `required` : ''
    },
    passwordConfirmationRequirement() {
      return this.form.password ? 'required' : ''
    },
    passwordConfirmationError() {
      return (
        this.form.password_confirmation &&
        this.form.password !== this.form.password_confirmation
      )
    },
    isCreate() {
      return this.$route.meta.mode !== 'edit'
    },
  },
  watch: {
    search(value) {
      this.getTeams()
    },
  },
  methods: {
    toggleVisibility() {
      this.openSelected = !this.openSelected
    },
    checkSelectedTeam() {
      if (!this.form.team_id.length) {
        this.$toaster.error('Le champ team est obligatoire')
        return false
      }
      return true
    },
    sendEmailPassword() {
      this.$http
        .post(
          `admin/agent-immobiliers/${this.$route.params.id}/email-invitation`,
        )
        .then(() =>
          this.$toaster.success(
            "vous avez envoyé l'email d'invitation avec succès",
          ),
        )
    },
    away() {
      this.openSelected = false
    },
    /**
     * Check current mode (edit, create)
     */
    checkCurrentMode() {
      if (this.isCreate) return
      this.storeURL = `admin/agent-immobiliers/${this.$route.params.id}?_method=PUT`
      this.action = 'Editer'
      this.editablePromoteur(this.$route.params.id)
    },
    editablePromoteur(id) {
      this.$http.get(`admin/agent-immobiliers/${id}`).then((res) => {
        const user = { ...res.data, ...res.data.user }
        Object.keys(this.form).forEach((element) => {
          if (element in user) {
            this.form[element] = user[element]
          }
        })
        if (res.data.teams && res.data.teams.length > 0) {
          this.form.team_id = []
          res.data.teams.forEach((item) => {
            this.selectedItems.push(item)
            this.form.team_id.push(item.id)
            this.teams.splice(this.teams.map((e) => e.id).indexOf(item.id), 1)
          })
        }
      })
    },
    save() {
      if (this.passwordConfirmationError || !this.checkSelectedTeam()) return
      this.loading = true
      const form = toMulipartedForm(this.form)

      this.$http
        .post(this.storeURL, form)
        .then(() => {
          this.$toaster.success(
            'vous avez ajouté un nouvel utilisateur avec succès',
          )
          this.$router.push({
            name: 'list-des-agents-immobilier',
          })
          this.loading = false
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors)
          this.loading = false
        })
    },
    addItem(item) {
      if (!this.form.team_id) {
        this.form.team_id = []
      }

      if (this.selectedItems.findIndex((it) => it.id == item.id) != -1) return
      this.selectedItems.push(item)
      this.form.team_id.push(item.id)
      this.teams.splice(this.teams.indexOf(item), 1)
      this.openSelected = false
    },
    removeItem(item) {
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
      this.form.team_id = this.form.team_id.filter((id) => id != item.id)
      if (this.teams.findIndex((it) => it.id == item.id) == -1)
        this.teams.push(item)
    },
    getTeams() {
      this.$http
        .get('admin/agent-immobiliers/teams', {
          params: {
            q: this.search,
          },
        })
        .then((res) => {
          this.teams = res.data.teams
          this.selectedItems.forEach((item) => {
            this.teams.splice(this.teams.map((e) => e.id).indexOf(item.id), 1)
          })
        })
    },
  },
}
</script>
