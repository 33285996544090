<template>
  <transition name="modal-fade">
    <div
      class="min-w-screen h-screen fixed left-0 top-0 flex justify-center items-center inset-0 outline-none focus:outline-none z-[10000]"
    >
      <div class="absolute bg-white inset-0 z-0 backdrop" @click="close"></div>
      <div
        class="w-full relative mx-auto my-auto rounded-lg shadow-lg bg-white"
        :class="max_width_model"
      >
        <!--content-->
        <div class="p-8 h-full">
          <!--Header-->
          <div class="flex-auto justify-center">
            <div class="flex justify-between items-center">
              <div>
                <slot name="title"></slot>
              </div>
              <span class="cursor-pointer" @click="close">
                <svg
                  class="cursor-pointer"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 26C20.4036 26 26 20.4036 26 13.5C26 6.59644 20.4036 1 13.5 1C6.59644 1 1 6.59644 1 13.5C1 20.4036 6.59644 26 13.5 26Z"
                    class="stroke-current text-promy-purple-400"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.25 9.75L9.75 17.25"
                    class="stroke-current text-promy-purple-400"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.75 9.75L17.25 17.25"
                    class="stroke-current text-promy-purple-400"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
          <!-- Body -->
          <div
            id="body_content"
            class="flex-auto justify-center z-50 py-3 overflow-y-auto pr-5 -mr-5"
            :class="max_height_body"
          >
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    max_height_body: {
      type: String,
      default: 'max-h-[70vh]',
    },
    max_width_model: {
      type: String,
      default: ' max-w-2xl  sm:max-w-xs',
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.1s ease;
}

.backdrop {
  opacity: 0.8;
}

#body_content::-webkit-scrollbar {
  width: 0.3em;
}

#body_content::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #6c6c6c;
}

#body_content::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #c0c0c0;
}
</style>
