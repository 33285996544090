<template>
  <div>
    <div class="flex items-center">
      <h2 class="title-components">Ratio Divers</h2>
      <div class="pl-2 flex items-center justify-center">
        <img
          v-if="!show_ratio_divers"
          src="/images/eye-off-outline.svg"
          @click="
            form.descriptifProjet.ratiodivers.show = show_ratio_divers = true
          "
          v-tooltip.top="{
            content: 'Affichez',
            classes: classes,
          }"
        />
        <img
          v-if="show_ratio_divers"
          src="/images/eye-outline.svg"
          @click="
            form.descriptifProjet.ratiodivers.show = show_ratio_divers = false
          "
          v-tooltip.top="{
            content: 'Masquez',
            classes: classes,
          }"
        />
      </div>
    </div>
    <div
      class="grid grid-cols-3 gap-8 mt-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
    >
      <div>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="ces"
          v-model="form.descriptifProjet.ratiodivers.hauteur_plafond"
          label="Hauteur plafond"
          type="number"
          :showed="show_ratio_divers"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="hauteur"
          v-model="form.descriptifProjet.ratiodivers.ratio_stationnement"
          label="Ratio stationnement"
          :showed="show_ratio_divers"
        />
      </div>
      <div>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="impl-voies"
          v-model="form.descriptifProjet.ratiodivers.shab_moyen_logt"
          label="Shab moy/logt"
          :showed="show_ratio_divers"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="impl-bati"
          v-model="form.descriptifProjet.ratiodivers.ration_SHAB"
          label="Ratio shab"
          :showed="show_ratio_divers"
        />
      </div>
      <div>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="impl-limit"
          v-model="form.descriptifProjet.ratiodivers.epaisseur_moyenne_bat"
          label="Epaisseur moy bati"
          :showed="show_ratio_divers"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="espace vert"
          v-model="form.descriptifProjet.ratiodivers.espace_vert"
          label="espace vert"
          :showed="show_ratio_divers"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_ratio_divers: this.form.descriptifProjet.ratiodivers.show,
      local_obj: {
        hauteur_plafond: null,
        ratio_stationnement: null,
        shab_moyen_logt: null,
        ration_SHAB: null,
        epaisseur_moyenne_bat: null,
        espace_vert: null,
        show: this.form.descriptifProjet.ratiodivers.show,
      },
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-xs',
        'bg-white',
        'mt-2',
      ],
    }
  },
  created() {
    this.form.descriptifProjet.ratiodivers = Object.assign(
      this.local_obj,
      this.form.descriptifProjet.ratiodivers,
    )
  },
  model: {
    prop: ['form'],
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
