<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar
      ><progress-bar
        section="Synthese du marche "
        :progress_section="progress_section.synthese_du_marche"
      />
    </template>
    <template #current_page>Synthese du marche</template>
    <template #body>
      <div
        v-if="loading_syntheses"
        class="mt-8 relative h-60 flex justify-center"
      >
        <loader
          class="absolute z-20"
          :size_bg_promy="0"
          :color="'#008279'"
        ></loader>
      </div>
      <div v-else class="w-full">
        <h2 class="title-components">Synthese de la region</h2>
        <div>
          <div class="w-1/3">
            <div class="relative mt-2">
              <select
                v-model="currentRegion"
                class="rounded-full px-4 py-2 text-gray-600 border-solid border w-full mt-1 text-sm bg-promy-gray-background-300 appearance-none"
              >
                <option
                  :value="region.id"
                  v-for="(region, index) in regions"
                  :key="index"
                  :selected="currentRegion"
                >
                  {{ region.region_name }}
                  <template v-if="region.title"
                    >( {{ region.title }} )</template
                  >
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
            <div
              v-if="!currentRegion"
              class="rounded-lg w-full py-3 px-3 bg-promy-red-200 text-sm text-promy-red mb-4 mt-2 text-opacity-50"
            >
              Veuillez choisir une région
            </div>
          </div>
          <div v-if="Object.keys(synthese_du_marche).length > 0">
            <div class="xl:pr-24">
              <div>
                <div class="relative h-64">
                  <quill-editor
                    :disabled="!currentRegion"
                    class="mt-8 h-full"
                    ref="myQuillEditor"
                    v-model="synthese_du_marche.content"
                  />
                </div>
                <HighlightMessage
                  :show="isEmptyOrNull(synthese_du_marche.content)"
                  classes="relative top-17"
                />
                <div class="mt-24">
                  <h2 class="title-components">Images</h2>
                  <div
                    class="grid grid-cols-4 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-16"
                    :class="{ 'mb-5': noImagesSelected }"
                  >
                    <div
                      :key="index"
                      v-for="(image, index) in synthese_du_marche.images"
                    >
                      <InputImage
                        :file="image"
                        :selfUploader="true"
                        @upload="updateImage($event, index)"
                        @deleteImage="deleteImage(index)"
                      />
                    </div>
                    <InputImage @upload="saveImage" />
                  </div>
                  <HighlightMessage :show="noImagesSelected" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>

<script>
import { mapGetters } from 'vuex'
import progress from '@/mixins/dossierPromoteurs/progress'
export default {
  mixins: [progress],
  data() {
    return {
      loading_syntheses: false,
      regions: [],
      currentRegion: '',
      synthese_du_marche: {
        region_name: null,
        region_id: null,
        content: null,
        images: [],
      },
    }
  },
  watch: {
    currentRegion: {
      immediate: true,
      handler(value) {
        if (!value) return
        if (this.syntheseDuMarcheStore.id != value) {
          let synthese_du_marche = this.regions.find(
            (region) => region.id == value,
          )
          synthese_du_marche.images = synthese_du_marche.images.map((image) =>
            image.path ? image.path : image,
          )
          this.synthese_du_marche = synthese_du_marche
        } else {
          this.synthese_du_marche = this.syntheseDuMarcheStore
        }
      },
    },
    synthese_du_marche: {
      deep: true,
      handler(value) {
        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'synthese_du_marche',
          value: this.progressSection(
            value,
            'content',
            'id',
            'images',
            'numero_dept',
            'region_id',
            'region_name',
          ),
        })
      },
    },
  },
  computed: {
    ...mapGetters({
      progress_section: 'dossierpromoteur/getProgressSection',
      syntheseDuMarcheStore: 'dossierpromoteur/getSyntheseDuMarche',
      informationsStore: 'dossierpromoteur/getinfos',
      getSyntheseMarcheId: 'dossierpromoteur/getSyntheseMarcheId',
    }),
    noImagesSelected() {
      return this.synthese_du_marche.images.length === 0
    },
  },
  mounted() {
    this.getRegionsWithSynthese()
  },
  methods: {
    saveImage(file) {
      this.synthese_du_marche.images.push(file)
    },
    deleteImage(index) {
      this.synthese_du_marche.images.splice(index, 1)
    },
    updateImage(file, index) {
      this.synthese_du_marche.images.splice(index, 1, file)
    },
    getRegionsWithSynthese() {
      this.loading_syntheses = true
      this.$http.get('admin/syntheses').then((res) => {
        this.regions = res.data.data
        if (
          Object.keys(this.syntheseDuMarcheStore).length === 0 &&
          this.getSyntheseMarcheId
        ) {
          let synthese_du_marche = this.regions.find(
            (region) => region.id == this.getSyntheseMarcheId,
          )
          synthese_du_marche.images = synthese_du_marche.images.map((image) =>
            image.path ? image.path : image,
          )
          this.synthese_du_marche = synthese_du_marche
          this.currentRegion = this.getSyntheseMarcheId
        } else {
          this.synthese_du_marche = Object.assign(
            {},
            this.synthese_du_marche,
            this.syntheseDuMarcheStore,
          )
          this.currentRegion = this.getSyntheseMarcheId
        }
        this.loading_syntheses = false
      })
    },
    saveInfos() {
      this.$store.commit(
        'dossierpromoteur/setSyntheseDuMarche',
        this.synthese_du_marche,
      )
    },
    isEmptyOrNull(data) {
      return data === '' || data === null
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveInfos()
    next()
  },
}
</script>
