<template>
  <svg
    width="34"
    height="27"
    viewBox="0 0 34 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.280487 26.2423C0.596672 26.7148 1.12875 27 1.69992 27H27.1987C27.8787 27 28.4941 26.5984 28.761 25.9774L33.8607 14.1649C33.9725 13.9083 34.0181 13.6281 33.9935 13.3496C33.9689 13.0711 33.8749 12.8031 33.7199 12.5697C33.5649 12.3363 33.3538 12.1448 33.1056 12.0126C32.8575 11.8805 32.5801 11.8117 32.2985 11.8125H30.5986V6.75C30.5986 4.88869 29.0737 3.375 27.1987 3.375H15.8858L11.5408 0H3.39984C1.52483 0 0 1.51369 0 3.375V25.3125H0.0118994C0.00811046 25.6418 0.101476 25.965 0.280487 26.2423ZM27.1987 6.75V11.8125H6.79968C6.11972 11.8125 5.50434 12.2141 5.23746 12.8351L3.39984 17.0927V6.75H27.1987Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#00C9BC',
    },
  },
}
</script>

<style></style>
