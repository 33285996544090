import transport from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    cgu_list: [],
    current_cgu: null,
    all_versions_is_published: true,
  },
  getters: {
    getCGU(state) {
      return state.cgu_list
    },
    getCurrentCGU(state) {
      return state.current_cgu
    },
    allVersionsIsPublished: (state) => state.all_versions_is_published,
  },
  mutations: {
    SET_LIST_CGU(state, payload) {
      if (Array.isArray(payload)) {
        state.cgu_list = payload
        if (state.cgu_list.filter((e) => e.status != 'Publier').length > 0)
          state.all_versions_is_published = false
        else state.all_versions_is_published = true
      }
    },
    SET_CURRENT_CGU(state, payload) {
      state.current_cgu = payload
    },
  },

  actions: {
    async addCGU({ commit }, form) {
      try {
        let response = await transport.post(`admin/CGU`, form)
        return response
      } catch (e) {
        throw e
      }
    },

    async updateCGU({ commit }, payload) {
      try {
        let response = await transport.put(
          `admin/CGU/${payload.id}`,
          payload.form,
        )
        return response
      } catch (e) {
        throw e
      }
    },

    async getAllCGU({ commit }) {
      try {
        let response = await transport.get(`admin/CGU`)
        commit('SET_LIST_CGU', response.data)
      } catch (e) {
        throw e
      }
    },

    async getCGUById({ commit }, id) {
      try {
        let response = await transport.get(`admin/CGU/${id}`)
        commit('SET_CURRENT_CGU', response.data)
      } catch (e) {
        throw e
      }
    },

    async removeCGU({ dispatch }, id) {
      try {
        await transport.delete(`admin/CGU/${id}`)
      } catch (e) {
        throw e
      }
    },
  },
}
