<template>
  <validation-provider :rules="requirements" v-slot="{ errors }">
    <div class="my-2">
      <label
        class="capitalize text-xs text-gray-600 font-bold font-arial"
        for="grid-state"
      >
        {{ label }}
      </label>
      <div class="relative">
        <select
          v-model="selected"
          class="rounded-full px-4 py-2 text-gray-600 border-solid border w-full mt-1 text-sm bg-promy-gray-background-300 appearance-none"
          :name="label"
        >
          <option
            :value="select"
            v-for="(select, idx) in selection"
            :key="select + idx"
          >
            <div v-if="display_properties">
              {{ select[display_properties] }}
            </div>
            <div v-else>{{ getNestedObject(select) }}</div>
          </option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
      <span class="mt-1 text-red-600 text-sm" v-if="errors[0]">
        {{ errors[0] }}
      </span>
    </div>
  </validation-provider>
</template>
<script>
export default {
  props: {
    args: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    requirements: {
      type: String,
    },
    value: {
      required: true,
    },
    name: {
      type: String,
    },
    selection: {
      required: true,
      type: Array,
    },
    display_properties: {
      type: String,
      default: null,
    },
    key_properties: {
      type: String,
      default: null,
      args: {
        type: Array,
        default: function () {
          return []
        },
      },
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  methods: {
    getNestedObject(obj) {
      if (typeof obj === 'object' && obj !== null) {
        return this.args.reduce((obj, level) => obj && obj[level], obj)
      } else {
        return obj
      }
    },
  },
  watch: {
    selection: {
      immediate: true,
      handler() {
        if (this.key_properties) {
          this.selected = this.selection.find(
            (el) => el[this.key_properties] === this.value,
          )
        }
      },
    },
    value: {
      immediate: true,
      handler() {
        this.selected = this.key_properties
          ? this.selection.find((el) => el[this.key_properties] === this.value)
          : this.value
      },
    },
    selected: {
      immediate: true,
      handler() {
        if (this.selected) this.$emit('input', this.selected)
      },
    },
  },
}
</script>
