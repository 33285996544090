<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55.998"
    height="53.002"
    viewBox="0 0 55.998 53.002"
    :style="`fill :${color}`"
  >
    <path
      id="Subtraction_2"
      data-name="Subtraction 2"
      d="M23.685,53,23.5,53c-.068,0-.139,0-.207,0a11.061,11.061,0,0,1-6.582-2.157A9.2,9.2,0,0,0,11.2,49L11,49a1,1,0,1,1,0-2l.205,0a11.066,11.066,0,0,1,6.58,2.158l.213.136V43.63a15.412,15.412,0,0,1-4.231-1.757A12.085,12.085,0,0,0,8,40.025L8,46.56V38.028c.04,0,.084,0,.13,0a13.921,13.921,0,0,1,6.6,2.088A14.485,14.485,0,0,0,18,41.565V30.858a3.982,3.982,0,0,1,0-7.718V7.854A3.992,3.992,0,0,1,19,0H53a3.993,3.993,0,0,1,1,7.858V23.14a3.983,3.983,0,0,1,0,7.718V41.565a14.52,14.52,0,0,0,3.265-1.444,13.939,13.939,0,0,1,6.6-2.088c.047,0,.091,0,.131,0v2a12.06,12.06,0,0,0-5.77,1.843A15.479,15.479,0,0,1,54,43.63v5.657l.209-.131A11.209,11.209,0,0,1,60.789,47l.2,0a1,1,0,1,1,0,2l-.2,0a9.178,9.178,0,0,0-5.51,1.846,11.771,11.771,0,0,1-13.582,0A9.175,9.175,0,0,0,36.194,49l-.2,0-.2,0a9.16,9.16,0,0,0-5.5,1.846A11.1,11.1,0,0,1,23.685,53Zm24.808-2h0l.205,0a8.955,8.955,0,0,0,3.3-.623V31H20V50.379a8.956,8.956,0,0,0,3.3.623l.205,0,.2,0A9.2,9.2,0,0,0,29.2,49.156,11.187,11.187,0,0,1,35.783,47l.207,0,.205,0a11.247,11.247,0,0,1,6.588,2.158A9.2,9.2,0,0,0,48.293,51ZM19,25a2,2,0,1,0,0,4H53a2,2,0,0,0,0-4ZM20,8V23H52V8ZM19,2a2,2,0,1,0,0,4H53a2,2,0,0,0,0-4Z"
      transform="translate(-8 0.003)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#00C9BC',
    },
  },
}
</script>

<style></style>
