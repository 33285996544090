<script>
import { head } from 'lodash'
export default {
  render() {
    return this.$scopedSlots.default({
      tabs: this.tabs,
      selectTab: this.selectTab,
      currentTab: this.currentTab,
    })
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currentTab() {
      let name = _.head(this.tabs.filter((tab) => tab.selected == true))
        .name.toLowerCase()
        .replace(/ /g, '-')
      return _.deburr(name)
    },
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.selected = tab.name == selectedTab.name
      })
    },
  },
}
</script>
