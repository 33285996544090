<template>
  <div
    class="flex w-3/4 space-x-8 pt-8 bg-promy-gray-background-100 -ml-14 pl-14 lg:ml-0 lg:px-5 lg:w-full sm:w-full sm:flex-col sm:space-x-0"
  >
    <div class="flex flex-col w-1/2 lg:pr-2 lg:w-1/2 sm:w-full sm:pr-0">
      <span class="font-main font-extrabold text-promy-orange-100 mb-4">
        Les dernières transactions à proximité
      </span>
      <accordion class="w-full rounded-lg text-xs font-extrabold">
        <transition-group name="list" tag="div">
          <accordion-item
            class="shadow-box mb-4"
            v-for="(items, index) in listDvf"
            :key="items.idx"
            :indice="index"
            :bgColor="'rgba(255, 182, 36, 0.48)'"
            :id="`header-dvf-${index}`"
          >
            <template slot="accordion-trigger">
              <div
                class="flex w-full justify-between items-center text-white pl-6 sm:pl-3 relative pr-9 py-2"
                style="min-height: 30px"
                :id="`dvf-${index}`"
                @click="activeDvf(items.idx, items)"
              >
                <span class="font-extrabold">
                  {{
                    getAdresse(
                      items.adresse.adresse_nom_voie,
                      items.adresse.nom_commune,
                      items.adresse.code_postal,
                    ) | truncate(46, '...')
                  }}
                </span>
                <span
                  class="bg-promy-orange-100 flex justify-center items-center rounded-full w-5 h-5 font-extrabold absolute right-0 mr-5/8"
                >
                  <i v-if="dvf_active !== items.idx" class="fa fa-plus" />
                  <i v-else class="fa fa-minus" />
                </span>
              </div>
            </template>
            <template slot="accordion-content">
              <transition-group name="list" tag="div">
                <div
                  class="flex bg-white py-4 pl-4 pr-3 body-accordion relative"
                  :class="{ 'bg-promy-gray-200': index % 2 !== 0 }"
                  v-for="(item, subIndex) in items.sub_list"
                  :key="item.idx"
                >
                  <div
                    class="flex flex-col w-2/4 text-promy-gray-600 font-medium"
                  >
                    <span>
                      Type de bien:
                      <span v-if="!item.editText">
                        {{ item.type_local | isExist }}
                      </span>
                    </span>
                    <pro-input
                      v-if="item.editText"
                      name="Type de bien"
                      v-model="item.type_local"
                    ></pro-input>
                    <!-- <span>
                  SDP: {{ getValueByRegex(item, regex_sdp) | isExist("m²") }}
                </span> -->
                    <span>
                      Surface terrain:
                      <span v-if="!item.editText">
                        {{ item.surface_terrain | isExist('m²') }}
                      </span>
                    </span>
                    <pro-input
                      v-if="item.editText"
                      name="Surface terrain"
                      v-model="item.surface_terrain"
                    ></pro-input>

                    <span>
                      Surface:
                      <span v-if="!item.editText">
                        {{ item.surface_bati | isExist('m²') }}
                      </span>
                    </span>
                    <pro-input
                      v-if="item.editText"
                      name="Surface"
                      v-model="item.surface_bati"
                    ></pro-input>
                  </div>
                  <div
                    class="flex w-2/4 flex-col justify-end items-end"
                    :class="[item.editText ? 'justify-between' : 'justify-end']"
                  >
                    <div class="flex flex-col items-end mb-2">
                      <span
                        class="text-promy-gray-600 font-medium"
                        v-if="!item.editText"
                      >
                        {{ item.date_mutation | isExist }}
                      </span>
                      <div class="flex flex-col" v-if="item.editText">
                        <span class="text-promy-gray-600 font-normal">
                          Date :</span
                        >
                        <pro-input
                          name="date"
                          v-model="item.date_mutation"
                        ></pro-input>
                      </div>
                      <span
                        class="text-promy-gray-600 text-lg font-bold"
                        v-if="!item.editText"
                      >
                        {{
                          round(item.valeur_fonciere) | formatEUR | isExist('€')
                        }}
                      </span>
                      <div class="flex flex-col" v-if="item.editText">
                        <span class="text-promy-gray-600 font-normal">
                          Prix :</span
                        >
                        <pro-input
                          v-if="item.editText"
                          name="Prix"
                          v-model="item.valeur_fonciere"
                        ></pro-input>
                      </div>
                    </div>
                    <div class="flex items-end">
                      <button
                        class="rounded-full mr-3 cursor-pointer h-7 w-7 flex justify-center items-center"
                        :class="
                          item.editText
                            ? 'bg-white border-promy-green-300 border-2'
                            : 'bg-promy-green-300'
                        "
                        @click="updateItem(item)"
                      >
                        <i
                          class="fa fa-edit"
                          :class="
                            item.editText
                              ? 'text-promy-green-300'
                              : 'text-white'
                          "
                        />
                      </button>
                      <button
                        @click="removeLocalisation(listDvf, index, subIndex)"
                        type="button"
                        class="rounded-full h-7 w-7 bg-promy-green-300 text-white px-2 py-1 text-sm cursor-pointer hover:bg-promy-green-200 z-10 mt-0"
                      >
                        <i class="fa fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </transition-group>
            </template>
          </accordion-item>
        </transition-group>
      </accordion>
    </div>
    <div class="flex flex-col w-1/2 lg:pl-2 lg:pr-0 lg:w-1/2 sm:w-full sm:pl-0">
      <span class="font-main font-extrabold text-promy-green-200 mb-4">
        Les derniers permis à proximité
      </span>
      <accordion class="w-full rounded-lg text-xs">
        <transition-group name="list" tag="div">
          <accordion-item
            class="shadow-box mb-4"
            v-for="(items, index) in permis_construire"
            :key="items.idx"
            :indice="index"
            :bgColor="'rgba(0, 201, 188, 0.48)'"
            :id="`header-pc-${index}`"
          >
            <template slot="accordion-trigger">
              <div
                class="flex w-full justify-between items-center text-white pl-6 sm:pl-3 relative pr-9 py-2"
                style="min-height: 30px"
                :id="`pc-${index}`"
                @click="activePC(items.idx, items)"
              >
                <span class="font-extrabold">
                  {{
                    getAdresse(
                      items.adresse.libelle_voie,
                      items.adresse.code_postal,
                      items.adresse.localisation,
                    ) | truncate(46, '...')
                  }}
                </span>
                <span
                  class="bg-promy-green-200 flex justify-center items-center rounded-full w-5 h-5 font-extrabold absolute right-0 mr-5/8"
                >
                  <i
                    v-if="permis_construire_active !== items.idx"
                    class="fa fa-plus"
                  />

                  <i v-else class="fa fa-minus" />
                </span>
              </div>
            </template>
            <template slot="accordion-content">
              <transition-group name="list" tag="div">
                <div
                  class="flex bg-white py-4 pl-4 pr-3 body-accordion relative"
                  :class="{ 'bg-promy-gray-200': index % 2 !== 0 }"
                  v-for="(item, subIndex) in items.sub_list"
                  :key="item.idx"
                >
                  <div
                    class="flex flex-col w-3/4 text-promy-gray-600 font-medium"
                  >
                    <div v-for="(key_pc, index) in keys_pc" :key="index">
                      <span>
                        {{ key_pc.label }}:
                        <span v-if="!item.editText">
                          {{
                            item[key_pc.key]
                              | isExist(key_pc.unit === 'metre' ? 'm²' : '')
                          }}
                        </span>
                      </span>
                      <pro-input
                        class="w-3/4"
                        v-if="item.editText"
                        :name="key_pc.label"
                        v-model="item[key_pc.key]"
                      ></pro-input>
                    </div>

                    <div class="flex items-end">
                      <button
                        class="rounded-full mr-3 cursor-pointer h-7 w-7 flex justify-center items-center"
                        :class="
                          item.editText
                            ? 'bg-white border-promy-green-300 border-2'
                            : 'bg-promy-green-300'
                        "
                        @click="updateItem(item)"
                      >
                        <i
                          class="fa fa-edit"
                          :class="
                            item.editText
                              ? 'text-promy-green-300'
                              : 'text-white'
                          "
                        />
                      </button>
                      <button
                        @click="
                          removeLocalisation(permis_construire, index, subIndex)
                        "
                        type="button"
                        class="rounded-full bg-promy-green-300 h-7 w-7 text-white px-2 py-1 text-sm cursor-pointer hover:bg-promy-green-200 z-10 mt-4"
                      >
                        <i class="fa fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </transition-group>
            </template>
          </accordion-item>
        </transition-group>
      </accordion>
    </div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/mapbox'
import helpers from '@/mixins/helpers'

export default {
  data() {
    return {
      regex_sdp: /^sdp_[a-z][0-9]/,
      regex_nb_lgt: /^nb_lgt_[0-9]p/,
      permis_construire_active: null,
      dvf_active: null,
      keys_pc: [
        { key: 'total_logements', unit: 'nbr', label: 'Nbr Lgt' },
        { key: 'total_sdp', unit: 'metre', label: 'SDP' },
        { key: 'surface_terrain', unit: 'metre', label: 'Surface terrain' },
        { key: 'type_evt', unit: 'nbr', label: 'Type' },
        { key: 'annee_depot', unit: 'nbr', label: 'Année dépôt' },
        {
          key: 'date_reelle_autorisation',
          unit: 'nbr',
          label: "Date réelle d'autorisation",
        },
        { key: 'date_reelle_doc', unit: 'nbr', label: 'Date réelle document ' },
        {
          key: 'date_reelle_daact',
          unit: 'nbr',
          label: "Date réelle d'achèvement des travaux",
        },
        {
          key: 'cat_dem',
          unit: 'nbr',
          label: "Catégorie du demandeur (maître d'ouvrage) selon Sitadel",
        },
        {
          key: 'ape_dem',
          unit: 'nbr',
          label:
            "Code d'activité principale de l'établissement d'un demandeur avéré en tant que personne morale",
        },
        {
          key: 'nature_projet_declaree',
          unit: 'nbr',
          label: 'Nature du projet déclarée par le demandeur',
        },
        {
          key: 'nature_projet_completee',
          unit: 'nbr',
          label: 'Nature de projet détaillée',
        },
        {
          key: 'destination_principale',
          unit: 'nbr',
          label: 'Destination principale',
        },
        {
          key: 'type_princip_logts_crees',
          unit: 'nbr',
          label: 'Type principal des logements créés',
        },
        {
          key: 'type_transfo_principal',
          unit: 'nbr',
          label: 'Type principal de transformation',
        },
        {
          key: 'type_princip_locaux_transformes',
          unit: 'nbr',
          label: "Type principal des locaux d'origine transformés",
        },
        {
          key: 'utilisation',
          unit: 'nbr',
          label: 'Utilisation visée des locaux à construire',
        },
        {
          key: 'res_princip_ou_second',
          unit: 'nbr',
          label: "Type d'habitation principale ou secondaire",
        },
        { key: 'type_annexe', unit: 'nbr', label: "Type d'annexe" },
        { key: 'residence', unit: 'nbr', label: 'Type de résidence' },
      ],
    }
  },

  props: {
    listDvf: {
      type: Array,
      required: true,
    },
    permis_construire: {
      type: Array,
      required: true,
    },
  },

  mixins: [Mapbox, helpers],
  created() {
    this.sortDataByDate()
    this.addIndexToLists()
  },
  methods: {
    addIndexToLists() {
      this.listDvf.forEach((items, idx) => {
        items['idx'] = idx
        items.sub_list.forEach((item, index) => {
          item['idx'] = idx + '_' + index
        })
      })
      this.permis_construire.forEach((items, idx) => {
        items['idx'] = idx
        items.sub_list.forEach((item, index) => {
          item['idx'] = idx + '_' + index
        })
      })
    },
    updateItem(item) {
      item.editText = !item.editText
    },
    removeLocalisation(array, index, subIndex) {
      array.forEach((item, indice) => {
        if (indice === index) {
          if (item.sub_list.length === 1) {
            array.splice(index, 1)
            this.$toaster.success('Les informations ont bien été supprimées')
          } else {
            item.sub_list.splice(subIndex, 1)
            this.$toaster.success('Les informations ont bien été supprimées')
          }
        }
      })
    },
    sortDataByDate() {
      this.listDvf.forEach((group_dvf) => {
        group_dvf.sub_list.sort(
          (a, b) => new Date(b.date_mutation) - new Date(a.date_mutation),
        )
      })
      this.permis_construire.forEach((group_permis_construction) => {
        group_permis_construction.sub_list.sort(
          (a, b) => new Date(b.annee_depot) - new Date(a.annee_depot),
        )
      })
    },
    activeDvf(index, items) {
      if (!this.is_marker_clicked) {
        if (this.dvf_active !== null && this.dvf_active == index)
          this.dvf_active = null
        else {
          this.dvf_active = index
          let point = this.$turf.point(items.sub_list[0].coordinates)
          this.fitBoundsPoint(point)
        }
      }
      this.$store.commit('etudeCommerciale/IS_MARKER_CLICKED', false)
    },
    fitBoundsPoint(point) {
      var buffered = this.$turf.buffer(point, 0.04, { units: 'miles' })
      this.fitBoundsGeojsonInMap(buffered)
    },
    activePC(index, items) {
      if (!this.is_marker_clicked) {
        if (
          this.permis_construire_active !== null &&
          this.permis_construire_active == index
        )
          this.permis_construire_active = null
        else {
          this.permis_construire_active = index
          let point = this.$turf.point(items.sub_list[0].coordinates)
          this.fitBoundsPoint(point)
        }
      }
      this.$store.commit('etudeCommerciale/IS_MARKER_CLICKED', false)
    },
    getAdresse(...args) {
      return args.join(' ')
    },
    getValueByRegex(item, regex) {
      let vm = this
      let total = 0

      for (let [key, value] of Object.entries(item)) {
        if (regex.test(key)) {
          total += parseInt(value)
        }
      }
      return total
    },
  },
  computed: {
    is_marker_clicked() {
      return this.$store.getters['etudeCommerciale/is_marker_clicked']
    },
    map() {
      return this.$store.getters['defaultmap/map']
    },
  },
}
</script>
<style lang="scss" scoped>
.body-accordion:last-child {
  @apply rounded-b-box;
}

.list-leave-active {
  transition: all 1s;
}
.list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0.1;
  background: rgb(255, 126, 126);
  pointer-events: none;
}
</style>
