export default {
  namespaced: true,
  state: {
    sortDirection: 'noSort',
    currentSortedColumn: null,
  },
  getters: {
    getSortDirection(state) {
      return state.sortDirection
    },
    getCurrentSortedColumn(state) {
      return state.currentSortedColumn
    },
  },
  mutations: {
    SET_SORT_DIRECTION(state, payload) {
      state.currentSortedColumn = payload
      if (state.sortDirection === 'noSort') state.sortDirection = 'up_arrow'
      else if (state.sortDirection === 'up_arrow')
        state.sortDirection = 'down_arrow'
      else if (state.sortDirection === 'down_arrow')
        state.sortDirection = 'up_arrow'
      else {
        state.sortDirection = 'noSort'
      }
    },
    RESET_STATE(state) {
      state.sortDirection = 'noSort'
      state.currentSortedColumn = null
    },
  },
}
