export default {
  namespaced: true,
  state: {
    // state related to Dossiers promoteur
    dossiers_promoteur_filter: {
      search: '',
      departement: '',
      region: '',
      commune: '',
      surface_terrain: null,
      ca_potentiel: null,
      availabe_when_brouillon: null,
      vendu: null,
      creator: '',
    },
    dossiers_promoteur: null,
    dossiers_promoteur_scroll_position: null,
    dossiers_promoteur_tab: null,
  },
  getters: {
    // getters related to Dossiers promoteur
    getDossiersPromoteurFilter(state) {
      return state.dossiers_promoteur_filter
    },
    getDossiersPromoteur(state) {
      return state.dossiers_promoteur
    },
    isDossiersPromoteurSaved(state) {
      return state.dossiers_promoteur !== null
    },
    getDossiersPromoteurScrollPosition(state) {
      return state.dossiers_promoteur_scroll_position
    },
    isDossiersPromoteurScrollPosition(state) {
      return state.dossiers_promoteur_scroll_position !== null
    },
    getDossiersPromoteurTab: (state) => state.dossiers_promoteur_tab,
  },
  mutations: {
    // mutations related to GDossiers promoteur
    SET_DOSSIERS_PROMOTEUR_FILTER(state, filter) {
      state.dossiers_promoteur_filter = filter
    },
    SET_DOSSIERS_PROMOTEUR(state, current_page) {
      state.dossiers_promoteur = current_page
    },
    SET_DOSSIERS_PROMOTEUR_SCROLL_POSITION(state, position) {
      state.dossiers_promoteur_scroll_position = position
    },
    SET_DOSSIERS_PROMOTEUR_TAB(state, tab) {
      state.dossiers_promoteur_tab = tab
    },
  },
}
