export default {
  data() {
    return {
      options_simplify: {
        tolerance: 0.00001,
        highQuality: true,
      },
      option_length_line: {
        units: 'kilometers',
      },
    }
  },
  methods: {
    getSurfaceMesurer(geojson) {
      return this.roundDouble(this.$turf.area(geojson))
    },
    roundDouble(number) {
      return Math.round((number * 10) / 10)
    },
    getTailleMurs(geojson) {
      let vm = this
      let tabWall = []
      let simplify_geojson = this.$turf.simplify(geojson, vm.options_simplify)
      var simplify_geojson_line = this.$turf.polygonToLine(simplify_geojson)
      let geometry =
        simplify_geojson_line.type === 'Feature'
          ? simplify_geojson_line.geometry
          : simplify_geojson_line.features[0].geometry
      if (geometry.type === 'LineString') {
        geometry.coordinates = [geometry.coordinates]
      }
      geometry.coordinates.forEach(function (coordinate) {
        let lines = vm.splitArrayByTwoValues(coordinate)
        lines.forEach(function (line) {
          var line_string = vm.$turf.lineString(line)
          var sizeWall = vm.roundDouble(
            vm.$turf.length(line_string, vm.option_length_line) * 1000,
          )
          var middleWall = vm.$turf.center(line_string).geometry.coordinates
          tabWall.push({
            sizeWall: sizeWall,
            middleWall: middleWall,
          })
        })
      })
      return tabWall
    },
    // get array of two point
    splitArrayByTwoValues(array) {
      let i,
        new_array = [],
        chunk = 2
      for (i = 0; i < array.length - 1; i++) {
        new_array.push(array.slice(i, i + chunk))
      }
      return new_array
    },
    roundDouble(number) {
      return Math.round((number * 10) / 10)
    },
  },
}
