function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
const defaultLayout = () =>
  import(/* webpackChunkName: '' */ '../../layouts/default')

import auth from '../../middleware/auth'

export default [
  {
    path: 'gestion-des-delegations-de-mandat',
    name: 'gestion-des-delegation',
    component: page('dashboard/GestionDesDelegation'),
    redirect: 'gestion-des-delegations-de-mandat/list',
    children: [
      {
        path: 'list',
        name: 'gestion-des-delegation-list',
        component: page('dashboard/GestionDesDelegation/list'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'details/:id',
        name: 'gestion-des-delegation-details',
        component: page('dashboard/GestionDesDelegation/details'),
        meta: {
          layout: defaultLayout,
          middleware: [auth],
        },
      },
    ],
  },
]
