<template>
  <div
    class="flex flex-col w-full mt-4 text-xs text-promy-gray-750 font-extrabold"
  >
    <div
      class="flex flex-col items"
      v-for="(item, index) in items"
      :key="index"
    >
      <div
        class="flex justify-between px-6 py-3 border-promy-gray-300 item"
        :class="index % 2 === 0 ? 'bg-promy-gray-225' : 'bg-promy-gray-125'"
        v-for="(value, key, child_index) in item"
        :key="child_index"
      >
        <div class="flex">{{ keys[child_index] }}</div>
        <div class="flex">{{ value }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    keys: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
<style scoped>
.items:first-child .item:first-child {
  @apply rounded-t-box border-b-2;
}
.item {
  @apply border-b-2;
}

.items:last-child .item:last-child {
  @apply rounded-b-box border-b-0;
}
</style>
