export default {
  namespaced: true,

  state: {
    offres: [],
    showPopup: null,
    promoteurName: null,
  },
  getters: {
    offres(state) {
      return state.offres
    },
    showPopup(state) {
      return state.showPopup
    },
    promoteurName(state) {
      return state.promoteurName
    },
  },

  mutations: {
    SET_OFFRES_PROMOTEUR_SEEN_BY(state, { offres, showPopup, promoteurName }) {
      state.offres = offres
      state.showPopup = showPopup
      state.promoteurName = promoteurName
    },
    CLOSE_POPUP(state) {
      state.showPopup = false
    },
  },
}
