import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      permis_construire_locaux: 'etudeCommerciale/permis_construire_locaux',
      permis_construire_logements:
        'etudeCommerciale/permis_construire_logements',
      dvf: 'etudeCommerciale/dvf',
      loading_permis_construire_locaux:
        'etudeCommerciale/loading_permis_construire_locaux',
      loading_permis_construire_logements:
        'etudeCommerciale/loading_permis_construire_logements',
      loading_dvf: 'etudeCommerciale/loading_dvf',
    }),
    is_permis_construction: {
      get: function () {
        return this.$store.getters['etudeCommerciale/is_permis_construction']
      },
      set: function (val) {
        this.$store.commit('etudeCommerciale/IS_PERMIS_CONSTRUCTION', val)
      },
    },
    is_dvf: {
      get: function () {
        return this.$store.getters['etudeCommerciale/is_dvf']
      },
      set: function (val) {
        this.$store.commit('etudeCommerciale/IS_DVF', val)
      },
    },
  },
}
