<template>
  <div>
    <p
      class="text-promy-gray-700 font-arial font-extrabold text-lg sm:text-sm py-4"
    >
      {{ movement_terrain.name }}
    </p>
    <p>
      <span
        class="text-promy-gray-600 sm:text-base font-arial"
        v-for="(sub, idx) in movement_terrain.sub_title"
        :key="idx"
      >
        {{ sub.label }}
        <span v-if="sub.displayRayon"> {{ sub.rayon }}m</span> :
        <span class="font-bold capitalize">{{ sub.value }}</span
        ><br />
      </span>
    </p>
    <p
      v-for="(definition, index) in movement_terrain.definitions"
      :key="index"
      class="text-promy-gray-600 font-arial sm:text-sm"
    >
      {{ definition }}
    </p>
    <div
      v-for="(sub, idx) in movement_terrain.sub_title"
      :key="'sub' + idx"
      class="py-6"
    >
      <p
        v-if="sub.key === 'ppr' && sub.value != 'Non'"
        class="text-promy-gray-700 font-arial font-extrabold text-lg sm:text-sm py-4"
      >
        Réglementations
      </p>

      <p
        v-if="sub.value != 'Non'"
        class="text-promy-gray-600 font-arial sm:text-sm py-4"
      >
        {{ sub.descr }}
      </p>

      <div
        v-if="sub.value != 'Non' && sub.value != ''"
        class="w-full flex md:flex-col justify-start items-start"
      >
        <div
          class="w-2/5 md:w-full h-64 border-2 flex justify-center items-center"
        >
          <Map
            :id_map="`map_mvmt_${idx}`"
            :layer="sub.layer"
            :geo_value="sub.key == 'ppr' ? 'georisques' : 'geoservices'"
            ref="map"
          />
        </div>
        <img
          v-if="sub.key === 'ppr'"
          :src="`https://mapsref.brgm.fr/wxs/georisques/risques?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=${sub.layer}`"
          class="ml-6 md:ml-0 md:mt-4"
        />
        <img
          v-else
          :src="`http://geoservices.brgm.fr/risques?language=fre&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=${sub.layer}&format=image/png&STYLE=inspire_common:DEFAULT`"
          class="ml-6 md:ml-0 md:mt-4"
        />
      </div>

      <div v-if="sub.key === 'ppr' && sub.value != 'Non'" class="w-full">
        <p
          class="text-promy-gray-700 font-arial font-extrabold text-lg sm:text-sm pt-6 pb-4"
        >
          Arrêtés
        </p>
        <TablesRisques :keyTable="sub.key" :body="sub.bodyTable" />
      </div>
    </div>
  </div>
</template>

<script>
import Map from './map.vue'

export default {
  components: {
    Map,
  },
  props: {
    rapport_technique: {
      type: Object,
      require: true,
    },
  },
  computed: {
    movement_terrain() {
      return this.rapport_technique.details_risques.MVMT
    },
  },
}
</script>

<style lang="scss" scoped>
thead {
  tr {
    @apply rounded-t-lg;
  }
}
th,
td {
  @apply py-2 px-3;
}

tbody tr:nth-child(even) {
  @apply bg-promy-gray-125;
}
</style>
