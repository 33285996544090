<template>
  <div class="custom-radio">
    <input
      type="radio"
      :name="name"
      :class="className"
      :id="id"
      :checked="checked"
      :value="value"
      :required="required"
      v-on:change="updateInput"
    />
    <label
      :for="id"
      class="capitalize text-xs text-gray-600 font-bold font-arial"
      >{{ label }}
    </label>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Boolean],
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    checked: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  methods: {
    updateInput: function (event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-radio {
  input[type='radio'],
  input {
    display: none;
  }

  input[type='checkbox'],
  input[type='radio'] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    transition: all 0.2s ease;
    // Better Font Rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:focus {
      outline: 0;
    }
    &:active {
      outline: none;
    }
    &:hover {
      outline: none;
    }
  }
  input[type='radio'] + label {
    cursor: pointer;
    padding-left: 28px;
    position: relative;
  }
  input[type='radio']:checked + label {
    font-weight: bolder;
  }
  input[type='radio'] + label:before {
    content: '';
    transform: translate(0, -50%);
    top: 50%;
    left: 0;
    width: 21px;
    height: 21px;
    position: absolute;
    background: #00c9bc;
    border-radius: 100%;
    transition: all 0.3s ease;
    box-shadow: inset 0 0 0 18px #e0e0e0;
  }
  input[type='radio']:checked + label:before {
    border: 1px solid #00c9bc;
    box-shadow: inset 0 0 0 4px #fff;
  }
}
</style>
