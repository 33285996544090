<template>
  <div>
    <h2 class="title-components">reglementation urbanisme</h2>
    <div
      class="grid grid-cols-3 gap-8 mt-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
    >
      <div>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="ces"
          v-model="form.descriptifProjet.reglementationurbanisme.ces"
          label="ces"
        >
        </pro-input>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="hauteur"
          v-model="form.descriptifProjet.reglementationurbanisme.hauteur"
          label="hauteur"
          type="number"
        >
        </pro-input>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="impl-limit"
          v-model="form.descriptifProjet.reglementationurbanisme.impl_limit"
          label="IMPL/Limite séparative"
        >
        </pro-input>
      </div>
      <div>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="impl-voies"
          v-model="form.descriptifProjet.reglementationurbanisme.impl_voie"
          label="impl/voie"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="impl-bati"
          v-model="form.descriptifProjet.reglementationurbanisme.impl_bati"
          label="IMPL/Bâtiment"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="espace vert"
          v-model="form.descriptifProjet.reglementationurbanisme.espace_vert"
          label="espace vert"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      local_obj: {
        ces: null,
        hauteur: null,
        impl_limit: null,
        impl_voie: null,
        impl_bati: null,
        espace_vert: null,
      },
    }
  },
  created() {
    Object.keys(this.local_obj).map((key) => {
      this.local_obj[key] = this.form.descriptifProjet.reglementationurbanisme[
        key
      ]
        ? this.form.descriptifProjet.reglementationurbanisme[key]
        : null
    })
    this.form.descriptifProjet.reglementationurbanisme = this.local_obj
  },
  watch: {
    'local_obj.ces': {
      handler(value) {
        this.$store.commit('dossierpromoteur/setCesUrbanisme', value)
      },
    },
    'local_obj.hauteur': {
      handler(value) {
        this.$store.commit('dossierpromoteur/setHauteurUrbanisme', value)
      },
    },
    'local_obj.impl_limit': {
      handler(value) {
        this.$store.commit('dossierpromoteur/setImplLimitUrbanisme', value)
      },
    },
    'local_obj.impl_voie': {
      handler(value) {
        this.$store.commit('dossierpromoteur/setImplVoieUrbanisme', value)
      },
    },
    'local_obj.impl_bati': {
      handler(value) {
        this.$store.commit('dossierpromoteur/setImplBatiUrbanisme', value)
      },
    },
  },
  model: {
    prop: ['form'],
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
