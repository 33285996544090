<template>
  <div
    class="h-full flex pt-8 justify-center font-main sm:flex-1 bg-gray-200 sm-ws:pt-4 sm:pt-4"
  >
    <div class="flex flex-col items-center w-full">
      <SubSideBarItem
        v-for="link in gatedLinks"
        :key="link.name"
        class="w-full"
        :parentUrl="parentUrl"
        :link="link"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      gatedLinks: [],
    }
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    parentUrl: {
      required: true,
    },
  },
  created() {
    this.filterWithGateAndActive()
  },
  computed: {
    ...mapGetters({
      parametrageSections: 'dossierpromoteur/getActiveSections',
    }),
  },
  watch: {
    parametrageSections: {
      immediate: true,
      handler(sections) {
        this.filterWithGateAndActive()
      },
    },
    links: {
      immediate: true,
      handler(sections) {
        this.filterWithGateAndActive()
      },
    },
  },

  methods: {
    filterWithGateAndActive() {
      this.gatedLinks = this.links.filter((link) => {
        if (
          (this.parentUrl !== '/dossier-promoteurs' ||
            (this.parentUrl === '/dossier-promoteurs' &&
              this.parametrageSections[link.key] &&
              this.parametrageSections[link.key].is_active)) &&
          (link.gate == undefined || link.gate.some((gate) => this.$can(gate)))
        ) {
          link.is_exist = true
          return link
        } else link.is_exist = false
      })
    },
  },
}
</script>
