<template>
  <div>
    <div
      class="relative border-dashed border-promy-gray-400 mt-4 mr-auto"
      :class="[
        {
          'bg-promy-gray-225 border-promy-gray-600': dragging,
          'bg-promy-gray-225 ': !dragging,
          'border-2': !isUploaded,
        },
        isCircle
          ? 'rounded-full h-full w-full'
          : 'rounded-large lg:h-42 sm-ws:h-34 xl:h-45 w-full xl:w-6/7',
      ]"
      @dragleave.prevent="dragging = false"
      @dragover.prevent="dragging = true"
      novalidate
    >
      <div
        v-if="!isUploaded"
        class="absolute flex flex-col justify-center items-center inset-0"
      >
        <div class="text-promy-gray-400 text-3xl">
          <i class="fa fa-upload fa-6" aria-hidden="true"></i>
        </div>
        <div class="uppercase text-promy-green-300 text-xl font-bold font-main">
          upload
        </div>
        <div class="uppercase text-promy-gray-350 text-xl font-main">
          jpg, jpeg, png
        </div>
      </div>
      <img
        :src="imageSource"
        class="transition duration-500 ease-in-out w-full h-full object-cover overflow-hidden"
        :class="isCircle ? 'rounded-full' : 'rounded-large'"
        v-if="imageSource"
      />
      <input
        :ref="'fileinput' + _uid"
        @change.prevent="handleFilesChosen"
        class="input-file absolute opacity-0 w-full h-full inset-0 cursor-pointer"
        type="file"
        accept="image/jpeg,
      image/jpg, image/png"
      />
      <button
        v-if="isUploaded"
        @click="deleteImage()"
        type="button"
        class="absolute rounded-full bg-promy-green-300 text-white px-2 py-1 text-sm cursor-pointer hover:bg-promy-green-200 top-0 right-0"
        :class="isCircle ? 'mt-3 mr-3' : '-mt-3 -mr-2'"
      >
        <i class="fa fa-trash-alt" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
      type: [String, File],
      default: '',
    },
    shape: {
      type: String,
    },
    name: {
      type: [String, Number],
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      dragging: false,
      imageSource: null,
      isUploaded: false,
      isImage: true,
      file: null,
    }
  },
  created() {
    this.imageSource = this.value
    if (this.imageSource) {
      this.readFile(this.imageSource)
    }
  },
  computed: {
    isCircle() {
      return this.shape == 'circle'
    },
  },
  methods: {
    handleFilesChosen() {
      this.file = this.$refs['fileinput' + this._uid].files[0]
      this.readFile(this.file)
      this.$emit('input', this.file)
    },
    deleteImage() {
      this.$emit('deleteImage')
      this.imageSource = ''
      this.isUploaded = false
      this.dragging = false

      this.file = ''
      this.resetFile()
    },
    readFile(file) {
      let typefile = file['type']

      if (['image/jpeg', 'image/jpg', 'image/png'].includes(typefile)) {
        let reader = new FileReader()
        let vm = this
        reader.onload = (f) => {
          vm.imageSource = f.target.result
        }
        this.isUploaded = true
        reader.readAsDataURL(file)
      } else {
        this.isImage = false
        this.resetFile()
      }
    },
    resetFile() {
      let input = this.$refs['fileinput' + this._uid]
      input.type = 'text'
      input.type = 'file'
    },
  },
}
</script>
<style lang="scss" scoped>
.input-file {
  width: 100% !important;
}
</style>
