<template>
  <validation-provider :rules="rules" v-slot="{ errors }">
    <textarea
      :rows="rows"
      :placeholder="placeholder"
      :value="value"
      @input="handleInput($event.target.value)"
      :disabled="disabled"
      :class="{ 'input-error': errors[0] }"
    />
    <span v-show="errors[0]" class="msg-error">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    rows: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  @apply rounded-lg px-4 py-2  text-promy-gray-600 border-solid border-2  w-full mt-1 border-promy-gray-300 bg-promy-gray-background-300;
}
textarea:focus {
  @apply outline-none bg-white;
}
textarea[disabled] {
  @apply border-promy-gray-300 bg-promy-gray-300;
}
.msg-error {
  @apply rounded-lg w-full py-3 px-3  bg-promy-red-200 inline-block text-sm text-promy-red mb-4 mt-2 text-opacity-50;
}
.input-error {
  @apply border-promy-red-100;
}
</style>
