import axios from 'axios'
export default {
  data() {
    return {
      is_installation_industrielles_loaded: false,
    }
  },
  methods: {
    setInstallationIndustrielles(risques, INST) {
      let coords = this.informationsStore.center_parcelle_principale
      INST.sub_title = []
      this.setDetailRisqueINST(risques, INST)
      INST.sub_title.forEach((sub) => {
        if (sub.key === 'alea') {
          axios
            .get(
              `https://mapsref.brgm.fr/wxs/georisques/rapport?version=1.0.0&service=wfs&request=getfeature&typename=${sub.layer}&propertyname=${sub.properties}&X=${coords[0]}&Y=${coords[1]}&rayon=${sub.rayon}&resultType=results`,
            )
            .then(
              (response) => {
                let data_json = this.xmlToJson(response.data)
                sub.value = data_json['wfs:FeatureCollection']['gml:boundedBy'][
                  'gml:null'
                ]
                  ? 0
                  : Object.keys(
                      data_json['wfs:FeatureCollection']['gml:featureMember'],
                    ).length
                this.is_installation_industrielles_loaded = true
              },
              (error) => {
                this.is_installation_industrielles_loaded = true
                console.log(error)
              },
            )
        }

        if (sub.key === 'ppr') {
          if (this.notEmptyObject(risques.pprt)) {
            risques.pprt.forEach((pprt) => {
              let found = pprt.listRisques.find((rsq) => {
                return sub.codes.includes(rsq.numRisque)
              })
              if (found) {
                sub.value = 'Oui'
                this.pprt_info.push(pprt)
              }
            })
            sub.value = sub.value === 'Oui' ? 'Oui' : 'Non'
            sub.bodyTable = this.setValuesTable(
              this.pprt_info,
              ['codeNatPpr', 'libellePpr'],
              'listRisques',
              'datePrescription',
              'dateApprobation',
            )
          } else {
            sub.value = 'Non'
          }
        }
      })
    },
    setDetailRisqueINST(risques, INST) {
      risques.definitions_risques.INST.alea.forEach((inst) => {
        this.setObjINST(INST, 'alea', inst)
      })
      this.setObjINST(INST, 'ppr', risques.definitions_risques.INST.ppr)
    },

    setObjINST(INST, key, obj) {
      let alea_obj = {
        key: key,
        title: obj.title,
        label: obj.label,
        rayon: obj.rayon,
        layer: key === 'ppr' ? obj.layerGaspar : obj.layer[0],
        properties: obj.properties.toString(),
        value: '',
        descr: obj.descr,
        displayRayon: key != 'ppr',
        codes: key === 'ppr' ? obj.codes : [],
      }
      INST.sub_title.push(alea_obj)
    },
  },
}
