<template>
  <div>
    <h2 class="title-components">Descriptif Projet</h2>
    <div
      class="grid grid-cols-3 gap-8 mt-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
    >
      <div>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="ces"
          v-model="form.descriptifProjet.descriptifprojet.nombre_LGT_accession"
          label="NB logement accession"
          type="number"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="hauteur"
          v-model="form.descriptifProjet.descriptifprojet.nombre_LGT_social"
          label="NB logement social"
          type="number"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="espace vert"
          v-model="form.descriptifProjet.descriptifprojet.total_logements"
          label="NB logement total"
          type="number"
        />
      </div>
      <div>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="impl-limit"
          v-model="
            form.descriptifProjet.descriptifprojet.stationnement_accession
          "
          label="NB stationnement accession"
          type="number"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="impl-voies"
          v-model="form.descriptifProjet.descriptifprojet.stationnement_social"
          label="NB stationnement social"
          type="number"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="impl-bati"
          v-model="
            form.descriptifProjet.descriptifprojet.stationnement_visiteur
          "
          label="NB stationnement visiteur / Pmr"
          type="number"
        />
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="cos"
          v-model="form.descriptifProjet.descriptifprojet.total_stationnements"
          label="NB stationnement total"
          type="number"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      local_obj: {
        nombre_LGT_accession: null,
        nombre_LGT_social: null,
        stationnement_accession: null,
        stationnement_social: null,
        stationnement_visiteur: null,
        total_logements: null,
        total_stationnements: null,
      },
    }
  },
  created() {
    this.form.descriptifProjet.descriptifprojet = Object.assign(
      this.local_obj,
      this.form.descriptifProjet.descriptifprojet,
    )
  },
  model: {
    prop: ['form'],
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
