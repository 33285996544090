import transport from '@/plugins/axios'

export default {
  namespaced: true,

  state: {
    folders: [],
    current_folder: null,
    current_document: null,
  },
  getters: {
    folders(state) {
      return state.folders
    },
    current_folder(state) {
      return state.current_folder
    },
    current_document(state) {
      return state.current_document
    },
  },

  mutations: {
    SET_FOLDERS(state, folders) {
      if (folders) {
        state.folders.push(...folders)
      } else {
        state.folders = []
      }
    },

    SET_CURRENT_FOLDER(state, folder) {
      state.current_folder = folder
    },

    SET_CURRENT_DOCUMENT(state, document) {
      state.current_document = document
    },

    SET_FOLDER(state, params) {
      const idx = state.folders.indexOf(params.folder)
      if (idx === -1) {
        state.folders.push(params.folder)
      } else {
        state.folders.splice(idx, 1, params.new_folder)
      }
    },

    REMOVE_FOLDER(state, folder) {
      const idx = state.folders.indexOf(folder)
      if (idx != -1) {
        state.folders.splice(idx, 1)
      }
    },

    SET_DOCUMENT(state, document) {
      state.current_folder.documents.push(document)
    },

    REMOVE_DOCUMENT(state, document) {
      const idx = state.current_folder.documents.indexOf(document)
      if (idx != -1) {
        state.current_folder.documents.splice(idx, 1)
      }
    },
  },

  actions: {
    async getAllFolders({ commit }, params) {
      try {
        let response = await transport.get('admin/folder', { params: params })
        commit('SET_FOLDERS')
        commit('SET_FOLDERS', response.data)
      } catch (e) {
        throw e
      }
    },

    async getFolder({ commit }, params) {
      try {
        let response = await transport.get(`admin/folder/${params.id}`, {
          params: params,
        })
        commit('SET_CURRENT_FOLDER', null)
        commit('SET_CURRENT_FOLDER', response.data.data)
      } catch (e) {
        throw e
      }
    },

    async addFolder({ commit }, folder) {
      try {
        let response = await transport.post('admin/folder', folder)
        commit('SET_FOLDER', { folder: response.data })
      } catch (e) {
        throw e
      }
    },

    async updateFolder({ commit }, params) {
      try {
        let response = await transport.put(
          `admin/folder/${params.folder.id}`,
          params.new_folder,
        )
        commit('SET_FOLDER', {
          folder: params.folder,
          new_folder: response.data,
        })
      } catch (e) {
        throw e
      }
    },

    async addDocument({ commit }, document) {
      try {
        let response = await transport.post(`admin/documents`, document)
        commit('SET_DOCUMENT', response.data)
      } catch (e) {
        throw e
      }
    },

    async updateDocument({ commit }, params) {
      try {
        let response = await transport.post(
          `admin/documents/${params.id}`,
          params.document,
        )
        commit('SET_DOCUMENT', response.data)
      } catch (e) {
        throw e
      }
    },

    async deleteFolder({ commit }, folder) {
      try {
        await transport.delete(`admin/folder/${folder.id}`)
        commit('REMOVE_FOLDER', folder)
      } catch (e) {
        throw e
      }
    },

    async deleteDocument({ commit }, document) {
      try {
        await transport.delete(`admin/documents/${document.id}`)
        commit('REMOVE_DOCUMENT', document)
      } catch (e) {
        throw e
      }
    },
  },
}
