<template>
  <svg
    class="w-10 h-auto fill-current"
    :class="color"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M511.469,492.246l-41.118-121.654c15.562-34.078,24.323-68.067,26.006-101.087c1.404-27.557-2.075-54.472-10.342-79.995
			c-14.138-43.654-37.337-68.049-38.317-69.066c-2.44-2.531-6.021-3.609-9.454-2.843c-3.431,0.765-6.216,3.263-7.35,6.59
			c-10.416,30.58-31.718,43.094-44.093,47.94c-9.042-40.809-32.807-81.209-69.246-117.5c-29.783-29.661-57.277-46.093-58.432-46.779
			c-3.305-1.958-7.435-1.868-10.651,0.231c-3.217,2.099-4.961,5.843-4.499,9.657c9.406,77.596-64.203,158.789-89.972,184.572
			l-18.626-29.798c-1.541-2.465-4.07-4.145-6.939-4.61c-2.868-0.465-5.799,0.332-8.038,2.185
			c-1.181,0.976-29.181,24.333-52.903,60.689c-31.277,47.935-40.107,96.007-25.667,139.286L0.531,492.246
			c-1.041,3.077-0.533,6.468,1.36,9.107s4.944,4.205,8.193,4.205h122.963H256h122.953h122.963c3.249,0,6.298-1.565,8.193-4.205
			C512.004,498.713,512.51,495.323,511.469,492.246z M84.109,242.22c14.38-22.14,30.816-39.398,40.568-48.774l18.805,30.088
			c1.576,2.521,4.183,4.219,7.127,4.638c2.942,0.417,5.922-0.481,8.139-2.461c1.198-1.071,29.671-26.655,57.012-65.072
			c31.547-44.326,48.024-86.11,49.158-124.526c10.267,7.51,24.322,18.734,38.771,33.171c25.452,25.432,57.44,66.403,65.298,117.174
			c0.41,2.65,1.859,5.028,4.027,6.607c2.167,1.579,4.874,2.229,7.523,1.808c1.834-0.292,40.655-6.959,62.423-47.875
			c7.259,10.518,17.081,27.386,24.175,49.678c14.538,45.677,12.302,94.335-6.575,144.952l-10.569-31.272
			c-1.385-4.097-5.228-6.854-9.552-6.854c-4.325,0-8.167,2.758-9.552,6.854l-24.86,73.54l-17.733-43.664
			c-1.545-3.802-5.239-6.29-9.343-6.289c-4.104,0.001-7.798,2.488-9.342,6.291l-17.725,43.666l-13.008-38.488
			c17.926-29.371,15.829-64.715-6.211-102.587c-16.685-28.669-38.87-48.666-39.806-49.503c-3.088-2.763-7.551-3.348-11.252-1.485
			c-3.701,1.861-5.885,5.804-5.51,9.929c0.049,0.544,4.364,51.682-22.401,66.57c-2.819-2.967-6.535-8.167-8.275-16.129
			c-0.666-3.043-2.7-5.608-5.512-6.948c-2.811-1.341-6.085-1.305-8.868,0.094c-1.914,0.963-42.836,22.052-45.025,62.823
			c-0.429,0.674-0.782,1.405-1.047,2.185l-24.857,73.543l-17.725-43.666c-1.544-3.802-5.237-6.29-9.342-6.291c0,0,0,0-0.001,0
			c-4.104,0-7.798,2.487-9.342,6.289l-17.733,43.664l-24.86-73.54c-1.382-4.099-5.226-6.856-9.551-6.856s-8.167,2.758-9.552,6.854
			l-6.849,20.264C54.495,302.531,64.159,272.935,84.109,242.22z M329.135,316.587l-2.106-6.232
			c-1.385-4.097-5.229-6.854-9.553-6.852c-4.324,0.001-8.166,2.758-9.552,6.854L283.067,383.9l-17.725-43.666
			c-1.544-3.802-5.238-6.291-9.343-6.291c-4.104,0-7.799,2.488-9.343,6.291l-17.726,43.668l-22.448-66.412
			c-2.953-23.29,12.866-39.535,23.83-47.814c6.276,12.618,15.312,18.3,16.594,19.059c2.542,1.506,5.617,1.821,8.411,0.863
			c29.34-10.062,38.099-41.134,40.46-63.918C313.11,246.226,336.963,282.047,329.135,316.587z M24.134,485.392L24.134,485.392
			L71.56,345.075l23.914,70.743c0.025,0.083,0.052,0.164,0.08,0.246l23.439,69.328H24.134z M133.045,463.989l-17.189-50.839
			l17.188-42.32l17.182,42.328L133.045,463.989z M147.098,485.391l47.425-140.315l47.426,140.315H147.098z M255.999,463.985
			l-17.18-50.827l17.18-42.323l17.18,42.322L255.999,463.985z M270.051,485.391l47.428-140.324l47.423,140.324H270.051z
			 M361.774,413.156l17.182-42.328l17.188,42.32l-17.189,50.84L361.774,413.156z M393.005,485.391l23.439-69.328
			c0.027-0.081,0.053-0.162,0.079-0.244l23.915-70.745l47.427,140.317H393.005z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M452.55,220.515c-1.115-5.456-6.443-8.977-11.897-7.862c-5.457,1.113-8.977,6.439-7.863,11.895
			c3.231,15.832,4.077,32.424,2.515,49.315c-0.513,5.546,3.566,10.456,9.112,10.968c0.316,0.029,0.629,0.043,0.941,0.043
			c5.149,0,9.545-3.926,10.029-9.155C457.13,256.858,456.176,238.285,452.55,220.515z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M434.806,185.252c-12.976,0-12.997,20.166,0,20.166C447.782,205.418,447.804,185.252,434.806,185.252z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-promy-green-200',
    },
  },
}
</script>
