<template>
  <div ref="infiniteScrollItemsContainer">
    <slot></slot>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    fetchingAllowed: {
      type: Boolean,
      required: true,
    },
    containerScroll: {
      // False => rely on full document scroll
      type: Boolean,
      default: true,
    },
    directUse: {
      type: Boolean,
      default: true,
    },
    isDynamicComponent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useScrollObject: null,
      infiniteScrollItemsContainer: null,
      slotObserver: null,
      observer: null,
    }
  },
  mounted() {
    if (!this.isLoading && this.fetchingAllowed) this.$emit('fetch')
    if (this.containerScroll) {
      this.$refs.infiniteScrollItemsContainer.parentElement.addEventListener(
        'scroll',
        this.scrollFetch,
      )
    } else {
      window.addEventListener('scroll', this.scrollFetch)
    }
    this.infiniteScrollItemsContainer = this.$refs.infiniteScrollItemsContainer
    if (!this.directUse && !this.isDynamicComponent) {
      this.infiniteScrollItemsContainer =
        this.$refs.infiniteScrollItemsContainer.children.item(0)
    }

    this.observer = new MutationObserver((mutations, observer) => {
      mutations.forEach((mutation) => {
        if (!this.isDynamicComponent) {
          this.emitFetchCondition()
        } else if (this.isDynamicComponent) {
          this.slotObserver = new MutationObserver(
            (slotMutations, slotObserver) => {
              this.emitFetchCondition()
            },
          )
          this.slotObserver.observe(
            this.infiniteScrollItemsContainer.children.item(0),
            {
              childList: true,
            },
          )
        }
      })
    })

    this.observer.observe(this.infiniteScrollItemsContainer, {
      childList: true,
    })
  },
  methods: {
    disconnectObservers() {
      if (this.observer && this.observer instanceof MutationObserver)
        this.observer.disconnect()
      if (this.slotObserver && this.slotObserver instanceof MutationObserver)
        this.slotObserver.disconnect()
      this.$refs.infiniteScrollItemsContainer.parentElement.removeEventListener(
        'scroll',
        this.scrollFetch,
      )
      window.removeEventListener('scroll', this.scrollFetch)
    },
    emitFetchCondition: debounce(function () {
      if (
        (!this.containerScroll &&
          !this.isLoading &&
          this.fetchingAllowed &&
          document.body.scrollHeight <= window.innerHeight) ||
        (this.containerScroll &&
          !this.isLoading &&
          this.fetchingAllowed &&
          this.infiniteScrollItemsContainer.scrollHeight <=
            this.infiniteScrollItemsContainer.parentElement.clientHeight)
      ) {
        this.$emit('fetch')
      }
    }, 100),
    scrollFetch() {
      const container = !this.containerScroll
        ? document.documentElement
        : this.$refs.infiniteScrollItemsContainer.parentElement
      const arrivedBottom =
        container.clientHeight + container.scrollTop === container.scrollHeight
      if (!this.isLoading && this.fetchingAllowed && arrivedBottom)
        this.$emit('fetch')
    },
  },
  beforeDestroy() {
    this.disconnectObservers()
  },
}
</script>
