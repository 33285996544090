<template>
  <div class="relative z-10" v-if="item.has_pdf || item.has_pdf === 0">
    <PDFIcon
      class="cursor-pointer"
      :color="status_pdf[item.has_pdf].color"
      @click.native="toggleDropUp(item.id)"
    />
    <div
      v-if="open == item.id"
      v-on-clickaway="closeDropUp"
      class="absolute left-3 transform -translate-x-full translate-y-full bottom-0 bg-white shadow-box rounded-box p-3"
    >
      <template v-if="status_pdf[item.has_pdf].is_generate_pdf">
        <button
          class="bg-promy-green-300 rounded-full py-2 px-4 font-extrabold text-white cursor-pointer text-sm w-max"
          @click="generatePdf(item)"
        >
          Régénérer PDF
        </button>
      </template>
      <template v-else>
        <div class="w-max">PDF - {{ status_pdf[item.has_pdf].name }}</div>
      </template>
    </div>
  </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  props: {
    item: {
      type: Object,
    },
  },

  directives: {
    onClickaway: onClickaway,
  },

  data() {
    return {
      open: null,
      status_pdf: [
        {
          name: 'En cours',
          color: 'text-promy-yellow',
          is_generate_pdf: false,
        },
        {
          name: 'Erreur',
          color: 'text-promy-red',
          is_generate_pdf: true,
        },
        {
          name: 'Généré',
          color: 'text-promy-green-100 ',
          is_generate_pdf: false,
        },
      ],
    }
  },
  methods: {
    toggleDropUp(index) {
      this.open = index
    },
    closeDropUp() {
      this.open = null
    },
    generatePdf(item) {
      this.$http
        .get(`admin/dossier-promoteurs/${item.id}/regenerate-pdf`)
        .then((response) => {
          item.has_pdf = 0
        })
    },
  },
}
</script>

<style></style>
