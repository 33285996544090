<template>
  <LayoutParametrage>
    <template #current_page>
      <span>Liste des CGU</span>
    </template>
    <template #add_button>
      <router-link
        class="btn-green px-6 py-2 sm:w-full text-center md:mt-8 mr-4 btn-new-cgu"
        :class="{
          'opacity-50 cursor-not-allowed hover:bg-promy-green-300 hover:border-promy-green-300':
            !all_versions_is_published,
        }"
        to="/parametrage/CGU/ajouter"
        :event="all_versions_is_published ? 'click' : ''"
      >
        Ajouter des CGU
      </router-link>
    </template>
    <template #modal>
      <PTabs
        :tabs="tabs"
        :init_tab="tabs[0]"
        :with_hr_tabs="true"
        @changeTab="tabChanged"
      >
        <template v-slot="{ current_component }">
          <component
            :is="current_component"
            :list_versions="dataList"
          ></component>
        </template>
      </PTabs>
    </template>
  </LayoutParametrage>
</template>

<script>
import { mapGetters } from 'vuex'
import PTabs from '../../../dashboard/GuichetUnique/DemandesPLU/components/Tab/PTabs.vue'
import CguTable from './CguTable.vue'

export default {
  data() {
    return {
      tabs: [
        {
          component: 'CguTable',
          array_filter: 'promoteurs',
          label: 'Promoteurs',
        },
        {
          component: 'CguTable',
          array_filter: 'gugp',
          label: 'Guichet unique Grand Public',
        },
        {
          component: 'CguTable',
          array_filter: 'gugp_mobile',
          label: 'Guichet unique mobile',
        },
      ],
      current_tab: 'CguTable',
      sort_list: [
        {
          title: 'Version',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Statut',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Date de création',
          sortKey: '',
          classes: 'w-1/4',
        },
      ],
      sorting_by: '',
      show_modal: false,
      remember_cgu: null,
      dataList: [],
    }
  },
  components: {
    PTabs,
    CguTable,
  },
  created() {
    this.$store.dispatch('cgu/getAllCGU')
    this.dataList = this.list_versions.filter(
      (lv) => lv.platform == 'promoteurs',
    )
  },
  filters: {
    status(status) {
      return status === 'Publier' ? 'Publié' : 'Brouillon'
    },
  },
  methods: {
    tabChanged(tab) {
      this.dataList = this.list_versions.filter(
        (lv) => tab.array_filter == lv.platform,
      )
    },
    filterListVersions(platform) {
      return this.list_versions.filter(
        (item) => item.platform === platform.replace('-', '_'),
      )
    },
    update(version) {
      this.$store.commit('cgu/SET_CURRENT_CGU', version)
      this.$router.push(`/parametrage/CGU/modifier/${version.id}`)
    },
    async publierCGU(version) {
      version.status = 'Publier'
      try {
        await this.$store.dispatch('cgu/updateCGU', {
          form: version,
          id: version.id,
        })
        this.$toaster.success('Les CGU ont bien été publiées.')
        this.$store.dispatch('cgu/getAllCGU')
      } catch {
        this.$toaster.warning(
          "Un probleme est survenu lors la publication d'une CGU.",
        )
      }
    },
    async remove() {
      try {
        await this.$store.dispatch('cgu/removeCGU', this.remember_cgu.id)
        this.$toaster.success('Les CGU ont bien été supprimées.')
        await this.$store.dispatch('cgu/getAllCGU')
        this.show_modal = false
        this.remember_cgu = null
      } catch {
        this.$toaster.warning(
          "Un probleme est survenu lors la supprission d'une CGU.",
        )
      }
    },
    rememberCGU(item = null) {
      this.remember_cgu = item
      this.show_modal = item ? true : false
    },
  },
  computed: {
    ...mapGetters({
      list_versions: 'cgu/getCGU',
      all_versions_is_published: 'cgu/allVersionsIsPublished',
    }),
  },
  watch: {
    list_versions() {
      this.dataList = this.list_versions.filter(
        (lv) => lv.platform == 'promoteurs',
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-action {
  @apply py-2 px-3 cursor-pointer text-white font-extrabold;
}
</style>
