<template>
  <div
    class="flex flex-col items-center h-full px-24 bg-gray-100 lg:justify-center xl:py-32 sm:px-8"
  >
    <img src="/images/logo.svg" alt="" />
    <h1
      class="my-12 text-xl font-bold text-center text-gray-800 font-main sm:text-lg"
    >
      Bienvenu sur l'Espace Administrateur
    </h1>
    <div>
      <img src="/images/avatar.png" class="w-48 h-48" alt="" />
    </div>
    <form @submit.prevent="resetPassword" class="w-full md:w-full lg:w-1/2">
      <div class="flex flex-col mb-4">
        <label class="mb-1 text-promy-gray-500 text-promy-sm font-bold"
          >Email</label
        >
        <input-text
          :type="'email'"
          :rules="'required'"
          v-model="form.email"
          :disabled="true"
        />
        <span
          v-if="errors.email"
          class="rounded-lg w-full py-3 px-3 bg-promy-red-200 text-sm text-promy-red-50 mt-2 text-opacity-50"
          >{{ errors.email[0] }}</span
        >
      </div>
      <div class="flex flex-col mb-4">
        <label class="mb-1 text-promy-gray-500 text-promy-sm font-bold"
          >Mot de passe</label
        >
        <div class="relative">
          <InputTextPassword
            @passwordInputIsValidated="checkPasswordValidation"
            v-model="form.password"
          />
        </div>
        <span
          v-if="errors.password"
          class="rounded-lg w-full py-3 px-3 bg-promy-red-200 text-sm text-promy-red-50 mt-2 text-opacity-50"
        >
          <ul class="list-disc list-inside">
            <li
              v-for="password in errors.password"
              :key="password"
              class="mt-1"
            >
              {{ password }}
            </li>
          </ul>
        </span>
      </div>
      <div class="flex flex-col mb-4">
        <label class="mb-1 text-promy-gray-500 text-promy-sm font-bold"
          >Confirmer le mot de passe</label
        >
        <InputTextPassword
          :with_rule="false"
          v-model="form.password_confirmation"
        />
        <span
          v-if="!isRepeatedPasswordPassedEightChars"
          class="rounded-lg w-full py-3 px-3 bg-promy-red-200 text-sm text-promy-red-50 mt-2 text-opacity-50"
          >Le mot de passe doit contenir au moins 8 caractères</span
        >
        <span
          v-if="errors.password_confirmation"
          class="rounded-lg w-full py-3 px-3 bg-promy-red-200 text-sm text-promy-red-50 mt-2 text-opacity-50"
          >{{ errors.password_confirmation[0] }}</span
        >
      </div>

      <div class="flex flex-col items-center mt-8">
        <button
          type="submit"
          class="btn-green h-12 w-50 text-base font-main font-extrabold sm-ws:h-9"
        >
          Validez
        </button>
        <a
          :href="$router.resolve('login').href"
          class="btn-white h-12 w-50 text-base font-main font-extrabold mt-4 flex justify-center items-center sm-ws:h-9"
        >
          Connexion
        </a>
      </div>
    </form>
  </div>
</template>
<script>
import helpers from '@/mixins/helpers'

export default {
  data() {
    return {
      form: {
        email: this.$route.query.email || null,
        token: this.$route.query.token || null,
        password: '',
        password_confirmation: '',
      },
      errors: [],
      isPasswordPassedEightChars: true,
      isRepeatedPasswordPassedEightChars: true,
      password_is_valid: false,
    }
  },
  mixins: [helpers],
  methods: {
    checkPasswordValidation(value) {
      this.password_is_valid = value
    },
    resetPassword() {
      this.isPasswordPassedEightChars = this.form.password.length >= 8
      this.isRepeatedPasswordPassedEightChars =
        this.form.password_confirmation.length >= 8

      this.password_is_valid &&
        this.isPasswordPassedEightChars &&
        this.isRepeatedPasswordPassedEightChars &&
        this.$http
          .post('password/reset', this.form)
          .then((res) => {
            this.$toaster.success(res.data.message)
            this.$router.push('/')
          })
          .catch((err) => {
            if (this.isExist(err.response.data.error)) {
              this.errors = []
              this.$toaster.warning(err.response.data.error)
              err.response.data.shouldRedirect &&
                this.$router.push('/sendresetpassword')
            } else if (err.response.status == 422) {
              this.errors = err.response.data.errors
            }
          })
    },
  },
}
</script>
