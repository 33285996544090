<template>
  <div>
    <template>
      <SideBarTools :open="sidebar_tools" @closeModel="toggleSidebarTools">
        <template #body>
          <VFilter
            :permis_construire="form.permis_construire"
            :listDvf="form.list_dvf"
            ref="VFilter"
            v-if="$mq === 'sm'"
            :allParcelles="allParcelles"
          />
        </template>
      </SideBarTools>
      <div class="flex justify-between sm:justify-end">
        <button class="bg-promy-green-200 p-1 rounded-full hidden sm:flex p-2">
          <img
            src="/images/tool_bar_icon.svg"
            class="w-5"
            alt
            @click.stop="toggleSidebarTools(true)"
          />
        </button>
      </div>
      <div class="flex lg:flex-col relative overflow-hidden xl:-mr-8 mt-4">
        <Map
          class="h-64-screen"
          :allParcelles="allParcelles"
          :permis_construire="form.permis_construire"
          :listDvf="form.list_dvf"
        ></Map>

        <div class="slide xl:w-6/24 sm-ws:w-7/24 xl:pl-6">
          <div
            class="flex flex-col xl:overflow-y-auto h-full relative flex-1"
            style="scroll-behavior: smooth"
          >
            <div
              class="xl:absolute xl:top-0 xl:left-0 w-full h-full lg:flex sm:flex-col sm:mt-4 xl:pr-8 xl:pl-2"
            >
              <VFilter
                :permis_construire="form.permis_construire"
                :listDvf="form.list_dvf"
                ref="VFilter"
                v-if="$mq === 'xl' || $mq === 'lg'"
              />
            </div>
          </div>
        </div>
      </div>
      <Details
        id="details-etude-commerciale"
        :permis_construire="form.permis_construire"
        :listDvf="form.list_dvf"
      ></Details>
    </template>
  </div>
</template>
<script>
import Details from './Details'
import Map from './Map'
import VFilter from './VFilter'
import defaultmap from '@/mixins/dossierPromoteurs/defaultmap'
import etudeCommerciale from '@/mixins/dossierPromoteurs/etudeCommerciale'
import helpers from '@/mixins/helpers'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      allParcelles: [],
    }
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  mixins: [defaultmap, etudeCommerciale, helpers],
  components: {
    Map,
    VFilter,
    Details,
  },
  computed: {
    ...mapGetters({
      sidebar_tools: 'etudeCommerciale/sidebar_tools',
    }),
  },

  created() {
    this.allParcelles = [
      this.informationsStore.current_parcelle,
      ...this.informationsStore.parcelles_voisines,
    ]
  },
  methods: {
    toggleSidebarTools(value) {
      this.$store.commit('etudeCommerciale/SIDEBAR_TOOLS', value)
    },
  },
}
</script>
<style lang="scss"></style>
