<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <div class="flex flex-col w-fit">
      <div>
        <pro-input
          name="Nom"
          requirements="required"
          v-model="form.nom"
          label="Nom"
        />
      </div>
      <div>
        <pro-input
          name="objet"
          requirements="required"
          v-model="form.objet"
          label="objet"
        />
      </div>
      <div>
        <pro-select
          requirements="required"
          name="type"
          label="Type"
          v-model="form.type"
          :selection="list_types"
        ></pro-select>
      </div>
      <div class="w-full mt-8 mb-20">
        <label class="text-xs font-bold font-arial">Corps</label>
        <quill-editor
          ref="myQuillEditor"
          class="w-full h-48"
          v-model="form.corps"
        />
      </div>
    </div>
    <div class="my-6">
      <button
        @click="handleSubmit(save)"
        class="px-12 py-2 text-sm font-bold text-white uppercase transition-colors duration-200 border rounded-full bg-promy-green-300 hover:text-promy-green-300 hover:border-promy-green-300 hover:bg-white font-main"
      >
        Enregistrer
      </button>
    </div>
  </ValidationObserver>
</template>
<script>
export default {
  data() {
    return {
      form: {
        nom: null,
        objet: null,
        corps: null,
        type: null,
      },
      list_types: ['agent immobilier', 'promoteur'],
    }
  },
  mounted() {
    this.displayVariableOnQuillEditor()
    if (this.$route.params.id) {
      this.$http
        .get(`/admin/type_courriers_wordings/${this.$route.params.id}`)
        .then((response) => {
          this.form = response.data
        })
    }
  },

  methods: {
    displayVariableOnQuillEditor() {
      const placeholderPickerItems = Array.prototype.slice.call(
        document.querySelectorAll('.ql-placeholder .ql-picker-item'),
      )

      placeholderPickerItems.forEach(
        (item) => (item.textContent = item.dataset.value),
      )

      document.querySelector('.ql-placeholder .ql-picker-label').innerHTML =
        "<span style='margin-right: 17px;'> Ajouter des variables </span> " +
        document.querySelector('.ql-placeholder .ql-picker-label').innerHTML
    },
    save() {
      if (this.$route.params.id) {
        this.$http
          .put(
            `/admin/type_courriers_wordings/${this.$route.params.id}`,
            this.form,
          )
          .then(() => {
            this.$toaster.success('vous avez modifié courrier avec succès')
            this.$router.push({
              name: 'courrier-type-list',
            })
          })
          .catch((err) => {
            this.$refs.form.setErrors(err.response.data.errors)
          })
      } else {
        this.$http
          .post('/admin/type_courriers_wordings', this.form)
          .then(() => {
            this.$toaster.success('vous avez ajouté courrier avec succès')
            this.$router.push({
              name: 'courrier-type-list',
            })
          })
          .catch((err) => {
            this.$refs.form.setErrors(err.response.data.errors)
          })
      }
    },
  },
}
</script>
