<template>
  <div class="flex-1 flex w-full relative flex-col map-parcelle">
    <StylesMap class="z-10" :map="map" :moduleStore="moduleStore" />
    <div class="w-full h-full lg:h-60-screen" id="mapbox"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Mapbox from '@/mixins/mapbox'
import defaultmap from '@/mixins/dossierPromoteurs/defaultmap'
import parcelle from '@/mixins/cadastre/parcelle'
import helpers from '@/mixins/helpers'
import calculeHelpers from '@/mixins/cadastre/calculeHelpers'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'

export default {
  mixins: [helpers, defaultmap, calculeHelpers, Mapbox, sourcesAndLayers],
  data() {
    return {
      allParcelles: [],
    }
  },
  props: {
    is_zone_plu: {
      type: Boolean,
      required: true,
    },
    opacity_layers_plu: {
      type: Number,
      required: true,
    },
    all_zones_urba: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      infos_cadastre: 'parcelle/infos_cadastre',
      map: 'defaultmap/map',
    }),
  },

  mounted() {
    this.allParcelles = [
      this.informationsStore.current_parcelle,
      ...this.informationsStore.parcelles_voisines,
    ]
    this.initMap()
  },
  methods: {
    initMap() {
      if (this.map) {
        this.map.remove()
      }
      let vm = this
      this.$store.commit(
        'defaultmap/SET_MAP',
        this.createMap(this.informationsStore.center_parcelle_principale),
      )
      this.map.on('load', function () {
        vm.addLayersParcelles(vm.allParcelles)
        if (vm.all_zones_urba.length) vm.addLayersUrbanisme()
        vm.addCustomMarkerOnParcelles()
      })
      vm.map.on('style.load', vm.preserveLayers)
    },

    addLayersUrbanisme() {
      this.all_zones_urba.forEach((zone_urba, index) => {
        let options = this.copyObject(this.options_polygone)
        let color = this.blue
        options.is_dash = true

        color = this.isExist(this.palettes_color[index])
          ? this.palettes_color[index]
          : color

        options.color = color

        this.addPolygonsToMap(
          zone_urba,
          this.concatSourceAndLayersWithRef(
            ...this.source_and_layers_urbanisme,
            index,
          ),
          options,
        )
      })

      this.fitBoundsGeojsonInMap(this.getfeatureCollection(this.all_zones_urba))

      this.map.addSource(this.source_and_layers_libelle_urbanisme_autour[0], {
        type: 'geojson',
        data: this.$turf.featureCollection(this.all_zones_urba),
      })

      this.map.addLayer({
        id: this.source_and_layers_libelle_urbanisme_autour[1],
        type: 'symbol',
        source: this.source_and_layers_libelle_urbanisme_autour[0],
        minzoom: 14,
        layout: {
          'text-field': ['get', 'libelle'],
          'text-justify': 'auto',
        },
        paint: {
          'text-color': '#fff',
          'text-halo-color': '#fff',
          'text-halo-width': 0.7,
        },
      })
    },
    getfeatureCollection(list_geojson) {
      return this.$turf.featureCollection(list_geojson)
    },
    addCustomMarkerOnParcelles() {
      let center_parcelle = this.$turf.pointOnFeature(
        this.getfeatureCollection(this.allParcelles),
      ).geometry.coordinates
      var el = document.createElement('div')
      el.className = 'marker_cat'
      el.style.backgroundImage = `url(/images/markers/terrain.svg)`
      let marker = new this.$mapboxgl.Marker(el, {
        offset: [0, -15],
      })
        .setLngLat(center_parcelle)
        .addTo(this.map)
    },
  },
  watch: {
    is_zone_plu(newValue) {
      let vm = this
      if (!newValue) {
        this.all_zones_urba.forEach(function (zone, index) {
          vm.setLayoutVisibility(
            vm.concatSourceAndLayersWithRef(
              ...vm.source_and_layers_urbanisme,
              index,
            ),
            'none',
          )
        })
      } else {
        this.all_zones_urba.forEach(function (zone, index) {
          vm.setLayoutVisibility(
            vm.concatSourceAndLayersWithRef(
              ...vm.source_and_layers_urbanisme,
              index,
            ),
            'visible',
          )
        })
      }
    },
    opacity_layers_plu(newValue) {
      let vm = this
      this.all_zones_urba.forEach(function (zone, index) {
        vm.setOpacityLayer(
          vm.concatSourceAndLayersWithRef(
            ...vm.source_and_layers_urbanisme,
            index,
          ),
          newValue,
        )
      })
    },
  },
  beforeDestroy() {
    this.map.remove()
    this.$store.commit('defaultmap/RESET_MAP')
  },
}
</script>
<style lang="scss">
@import '../../../../assets/styles/Mapbox/control-zoom.scss';
.map-parcelle {
  .mapboxgl-ctrl-group {
    border-radius: 10px;
    margin-top: 24px !important;
    margin-left: 24px !important;
  }
  .hidden-loader {
    display: none !important;
  }
  .translate-center {
    transform: translate(-50%, -50%);
  }
  .center-element {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
</style>
<style lang="scss">
.icon-circle {
  @apply flex text-black justify-center text-base items-center font-bold  font-main w-5 h-5 border-solid  border-white border-2;
  border-radius: 50%;
}
.marker {
  background-color: #ffffff78 !important;
  color: black !important;
  font-weight: bold;
  font-size: 12px !important;
  border-radius: 15px;
  padding: 1px 4px 1px 4px;
}
.map-parcelle {
  .mapboxgl-popup-content {
    border-radius: 13px !important;
  }
}
.marker_cat {
  @apply bg-cover cursor-pointer;
  width: 30px;
  height: 43px;
}
</style>
