<template>
  <LayoutParametrage title="Dossiers Promoteurs">
    <template #current_page> Contexte général</template>
    <template #title_table> Modifier contexte général </template>

    <template #list_table>
      <div class="flex flex-col">
        <div class="flex flex-col w-full">
          <ValidationObserver
            v-slot="{ handleSubmit }"
            class="w-full"
            ref="formInformations"
          >
            <form
              @submit.prevent="handleSubmit(saveContextGeneral)"
              class="w-full flex flex-col space-y-20"
            >
              <div
                class="flex w-full space-x-5 sm:space-x-0 sm:flex-col sm:space-y-20"
              >
                <div class="flex flex-col w-1/2 sm:w-full">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-4"
                  >
                    Contexte général
                  </div>
                  <div class="flex w-full">
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="form.context_content"
                    />
                  </div>
                </div>

                <div class="flex flex-col w-1/2 sm:w-full">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-4"
                  >
                    Descriptif bati existant
                  </div>
                  <div class="flex w-full">
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="form.descriptif_bati_existant"
                    />
                  </div>
                </div>
              </div>
              <div class="flex w-full">
                <div class="flex flex-col">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-4"
                  >
                    Situation juridique
                  </div>
                  <div class="flex w-full">
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="form.situation_juridique"
                    />
                  </div>
                </div>
              </div>
              <div class="flex w-full">
                <div class="flex w-full flex-col">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-4"
                  >
                    Conditions particulières
                  </div>
                  <div class="flex w-full">
                    <quill-editor
                      class="w-full h-56"
                      ref="myQuillEditor"
                      v-model="form.conditions_promoteurs"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  v-if="$can('edite,dossier_promoteur')"
                  type="submit"
                  class="px-16 w-max rounded-full text-center sm:w-full border-2 bg-promy-green-300 border-promy-green-300 py-3 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors mt-6"
                >
                  Sauvegarder
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </LayoutParametrage>
</template>
<script>
export default {
  data() {
    return {
      form: {
        id: null,
        descriptif_bati_existant: null,
        context_content: null,
        situation_juridique: null,
        conditions_promoteurs: null,
      },
    }
  },
  created() {
    this.getContextGeneral()
  },
  methods: {
    getContextGeneral() {
      this.$http.get('admin/context_general').then((response) => {
        Object.assign(this.form, response.data.context_general)
      })
    },
    saveContextGeneral() {
      this.$http.post('admin/context_general', this.form).then((response) => {
        Object.assign(this.form, response.data)

        this.$toaster.success(' context général sauvegardée avec succès')
      })
    },
  },
}
</script>
