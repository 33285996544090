<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des teams des agents immobilier </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>List des teams agents immobilier</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div class="flex items-center space-x-3">
          <div class="sm:w-full">
            <pro-input
              class="sm:w-full"
              v-model="search"
              placeholder="Rechercher"
            />
          </div>
          <div
            class="px-4 py-2 text-xs font-semibold tracking-wide text-white uppercase transition-colors duration-200 border-2 rounded-full cursor-pointer hover:bg-white hover:text-promy-green-300 border-promy-green-300 bg-promy-green-300 font-main"
            @click.prevent="nextPath('list-des-teams-agents-immobilier-create')"
          >
            ajouter une team agent immobilier
          </div>
        </div>
      </template>
    </table-title>

    <hr class="mb-6" />

    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :hasSort="sort.sortKey ? true : false"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <InfiniteScrollContainer
        :isLoading="isFetching"
        :containerScroll="false"
        :fetchingAllowed="lastPage > currentPage"
        @fetch="getTeamAgentsImmobiliers()"
      >
        <pro-table-row
          v-for="(item, index) in teamAgentsImmobiliers"
          :key="index"
          class="relative"
        >
          <pro-table-cell label="Nom" class="w-1/3 lg:w-full">
            {{ item.name }}
          </pro-table-cell>
          <pro-table-cell label="Nombre De Membres" class="w-1/3 lg:w-full">
            {{ item.team_number }}
          </pro-table-cell>
          <pro-table-cell label="Date De Création" class="w-1/3 lg:w-full">
            {{ item.created_at }}
          </pro-table-cell>

          <pro-table-cell :actions_btn="true" class="w-1/3 lg:w-full">
            <div class="w-full flex justify-end items-center space-x-3">
              <div class="btn-actions" @click="displayModal(item)">
                <i class="fas fa-trash"></i>
              </div>
              <router-link
                :to="{
                  name: 'list-des-teams-agents-immobilier-edit',
                  params: { id: item.id },
                }"
                class="btn-actions"
              >
                <i class="fas fa-edit"></i>
              </router-link>
            </div>
          </pro-table-cell>
        </pro-table-row>
      </InfiniteScrollContainer>
    </pro-card>

    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteTeamAgentImmobilier()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer cette équipe ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Nom',
          sortKey: '',
          classes: 'w-1/3',
        },
        {
          title: 'Nombre de membres',
          sortKey: '',
          classes: 'w-1/3',
        },
        {
          title: 'Date de création',
          sortKey: '',
          classes: 'w-1/3',
        },
        {
          title: '',
          sortKey: '',
          classes: 'w-1/3',
        },
      ],
      teamAgentsImmobiliers: [],
      isFetching: false,
      lastPage: 1,
      currentPage: 0,
      current_item: null,
      showModal: false,
      sortQueryMapper: {
        nom: 'raison_social',
        nombre_de_membres: 'team_number',
        date_de_creation: 'created_at',
      },
      search: '',
    }
  },
  watch: {
    search: {
      handler() {
        this.searchAfterDebounce()
      },
    },
  },

  methods: {
    searchAfterDebounce: debounce(function () {
      this.teamAgentsImmobiliers = []
      this.currentPage = 0
      this.getTeamAgentsImmobiliers()
    }, 800),
    getTeamAgentsImmobiliers() {
      this.isFetching = true
      this.currentPage++
      this.$http
        .get(`admin/team-agents-immobiliers`, {
          params: {
            page: this.currentPage,
            search: this.search,
          },
        })
        .then((res) => {
          this.isFetching = false
          this.teamAgentsImmobiliers.push(...res.data.data)
          this.lastPage = res.data.meta.last_page
          this.currentPage = res.data.meta.current_page
        })
    },
    deleteTeamAgentImmobilier() {
      this.$http
        .delete(`admin/team-agents-immobiliers/${this.current_item.id}`)
        .then(() => {
          this.teamAgentsImmobiliers.splice(
            this.teamAgentsImmobiliers.indexOf(this.current_item),
            1,
          )
          this.showModal = false
          this.current_item = null
        })
    },
    sortByClicked(sortColumn) {
      this.$store.commit('sortBy/SET_SORT_DIRECTION', sortColumn)
      this.teamAgentsImmobiliers = []
      this.current_page = 1
      this.getTeamAgentsImmobiliers(
        this.sortQueryMapper[sortColumn],
        this.sortDirection === 'down_arrow' ? 'desc' : 'asc',
      )
    },
    displayModal(item) {
      this.current_item = item
      this.showModal = true
    },
  },
  computed: {
    sortDirection() {
      return this.$store.getters['sortBy/getSortDirection']
    },
    currentSortedColumn() {
      return this.$store.getters['sortBy/getCurrentSortedColumn']
    },
  },
}
</script>

<style scoped></style>
