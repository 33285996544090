var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-start items-center text-sm font-arial font-normal h-11 px-1 lg:h-auto"},[_c('div',{staticClass:"lg:hidden w-full leading-none"},[_vm._t("default")],2),_c('div',{staticClass:"w-full hidden lg:flex lg:items-center px-3",class:[
      _vm.is_checkbox
        ? 'lg:flex-row-reverse lg:justify-end'
        : 'lg:flex-row lg:justify-center' ]},[(_vm.label)?_c('div',{staticClass:"w-1/2 py-3 font-bold",class:[_vm.is_checkbox ? 'ml-8' : '']},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{class:{
        'w-8': _vm.is_checkbox && !_vm.label,
        'w-1/2': !_vm.is_checkbox && _vm.label,
        'w-full': !_vm.is_checkbox && !_vm.label,
      }},[(_vm.actions_btn)?_c('div',[_c('div',{staticClass:"absolute top-3 right-4"},[_c('button',{staticClass:"w-2 h-5 border-l-4 border-dotted border-promy-green-300",on:{"click":function($event){return _vm.showActions(_vm.show_actions)}}}),(_vm.show_actions)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.away),expression:"away"}],staticClass:"bg-white absolute -top-16 right-0 px-3 py-2 rounded-lg"},[_vm._t("default")],2):_vm._e()])]):_c('div',{staticClass:"py-3"},[_vm._t("default")],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }