<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-4 sm-ws:px-10">
    <div
      class="flex font-extrabold text-promy-gray-650 leading-2 text-promy-2xl font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span>Outils promoteur :</span>
      <span class="font-normal ml-1">Demandes PLU</span>
    </div>
    <h3 class="font-bold text-xl font-main">Listes des demandes</h3>

    <div class="space-x-2 relative mt-8">
      <PTabs :tabs="tabs" :init_tab="tabs[0]" :with_hr_tabs="true">
        <template v-slot="{ current_component }">
          <component :is="current_component"></component>
        </template>
      </PTabs>
    </div>
    <div></div>
  </div>
</template>

<script>
import Promoteur from './Tabs/Promoteur.vue'
import GUGP from './Tabs/GUGP.vue'
import PTabs from './components/Tab/PTabs.vue'

export default {
  components: {
    Promoteur,
    GUGP,
    PTabs,
  },
  data() {
    return {
      tabs: [
        {
          component: 'Promoteur',
          label: 'Promoteur',
        },
        {
          component: 'GUGP',
          label: 'Guichet unique unified',
        },
      ],
      current_tab: 'Promoteur',
    }
  },
}
</script>
