<template>
  <div class="w-full">
    <div class="flex flex-row-reverse justify-between">
      <button
        @click="toggleDropUp(item.id)"
        class="w-2 h-5 border-l-4 border-dotted border-promy-green-300"
      />
    </div>
    <div
      v-if="open == item.id"
      v-on-clickaway="closeDropUp"
      class="absolute flex items-start justify-center w-auto p-3 right-0 bg-white shadow-box rounded-box z-10"
      :style="possition"
    >
      <router-link
        :to="{
          name: 'dossier-promoteur-vues',
          params: {
            id: item.id,
          },
        }"
        class="flex items-center justify-center w-10 h-10 mr-2 text-white rounded-full cursor-pointer bg-promy-green-300"
        v-tooltip.bottom="{
          content: 'les vues',
          classes: classes,
        }"
      >
        <i class="fas fa-eye"></i>
      </router-link>
      <router-link
        v-if="item.nombre_offre != 0"
        :to="{
          name: 'dossier-promoteur-offres',
          params: {
            id: item.id,
          },
        }"
        class="flex items-center justify-center w-10 h-10 mr-2 text-white rounded-full cursor-pointer bg-promy-green-300"
        v-tooltip.bottom="{
          content: 'les offres',
          classes: classes,
        }"
      >
        <i class="fas fa-eye"></i>
      </router-link>
      <div
        v-if="
          $can('delete,dossier_promoteur') && shouldDisplayArchive(item.status)
        "
        class="flex items-center justify-center w-10 h-10 mr-2 text-white rounded-full cursor-pointer bg-promy-green-300"
        @click="displayArchiveModal(item.id)"
        v-tooltip.bottom="{
          content: 'Archiver le terrain',
          classes: classes,
        }"
      >
        <i class="fas fa-trash"></i>
      </div>
      <div
        v-if="$can('edite,dossier_promoteur')"
        class="flex items-center justify-center w-10 h-10 mr-2 text-white rounded-full cursor-pointer bg-promy-green-300"
        @click="displayModalTransfer(item.id)"
        v-tooltip.bottom="{
          content: 'Transférer',
          classes: classes,
        }"
      >
        <img src="/images/DossierPromoteur/transfer.svg" class="p-2" />
      </div>
      <router-link
        v-if="$can('edite,dossier_promoteur')"
        :to="`/dossier-promoteurs/informations/${item.id}`"
        class="flex items-center justify-center w-10 h-10 text-white rounded-full cursor-pointer bg-promy-green-300"
        v-tooltip.bottom="{
          content: 'Modifier terrain',
          classes: classes,
        }"
      >
        <i class="fas fa-edit"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'
export default {
  props: {
    archivedStatus: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
    },
    possition: {
      type: String,
      default: 'bottom: 100%',
    },
  },

  directives: {
    onClickaway: onClickaway,
  },

  data() {
    return {
      open: null,
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-sm',
        'bg-white',
        'mb-2',
      ],
    }
  },
  methods: {
    shouldDisplayArchive(status) {
      return this.archivedStatus.includes(status)
    },
    toggleDropUp(index) {
      this.open = index
    },

    closeDropUp() {
      this.open = null
    },
    displayArchiveModal(id) {
      this.$emit('displayArchiveModal', id)
    },

    // feature add responsable to terrain
    // displayModalTransfer(id) {
    //   this.$emit('displayModalTransfer', id)
    // },
  },
}
</script>
