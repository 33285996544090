<template>
  <div class="bg-promy-gray-200 rounded-lg p-3 w-full">
    <div
      class="bg-white rounded-lg px-4 py-2 font-extrabold text-xs font-arial"
    >
      <div class="text-promy-gray-800 rounded-t-lg">
        <div class="flex justify-between items-center">
          <p>Dossier</p>
          <p>{{ progress_dossier }}%</p>
        </div>
        <div class="w-full h-5/4 bg-promy-gray-300 rounded-full my-1">
          <div
            class="bg-promy-gray-800 h-5/4 rounded-full"
            :style="`width:${progress_dossier}%`"
          ></div>
        </div>
      </div>

      <div class="text-promy-green-300 mt-2 rounded-b -lg">
        <div class="flex justify-between items-center">
          <p>{{ section }}</p>
          <p>{{ progress_section }}%</p>
        </div>
        <div class="w-full h-5/4 bg-promy-gray-300 rounded-full my-1">
          <div
            class="bg-promy-green-300 h-5/4 rounded-full"
            :style="`width:${progress_section}%`"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    section: {
      type: String,
      default: 'Information',
    },
    progress_section: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters({
      progress_dossier: 'dossierpromoteur/getProgressDossir',
    }),
  },
}
</script>

<style></style>
