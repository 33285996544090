<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <page-title>Paramétrage</page-title>
    <table-title
      nextPath="ajouter"
      class="sm:flex-col sm:items-start sm:space-y-3"
    >
      <template v-slot:title>Liste actualités {{ departement }}</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div @click.prevent="nextPath('actualite-form')" class="green-text-btn">
          ajouter une actualité
        </div>
      </template>
    </table-title>

    <hr class="my-6" />
    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :sortingBy="sorting_by"
          :hasSort="sort.sortKey ? true : false"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <draggable @update="draggedToNewIndex">
        <pro-table-row
          v-for="(item, index) in orderActualite"
          :key="index"
          class="relative rounded-lg my-2 py-2 lg:py-0 lg:pt-7"
        >
          <pro-table-cell label="Libelle" class="w-2/4 lg:w-full">
            {{ item.libelle }}
          </pro-table-cell>
          <pro-table-cell label="Date" class="w-1/4 lg:w-full">
            {{ item.created }}
          </pro-table-cell>
          <pro-table-cell :actions_btn="true" class="w-1/4 lg:w-full">
            <div class="w-full flex justify-end items-center space-x-3">
              <router-link
                :to="{
                  name: 'actualite-edit-form',
                  params: { id: item.id },
                }"
                class="green-icon-btn"
              >
                <i class="fas fa-edit" />
              </router-link>
              <div class="green-icon-btn" @click="displayModal(item)">
                <i class="fas fa-trash" />
              </div>
            </div>
          </pro-table-cell>
        </pro-table-row>
      </draggable>
    </pro-card>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteActualite()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer cet actualite ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      actualites: [],
      departement: '',
      sort_list: [
        {
          title: 'Libelle',
          sortKey: '',
          classes: 'w-2/4',
        },
        {
          title: 'Date',
          sortKey: '',
          classes: 'w-1/4',
        },
      ],
      sorting_by: '',
      current_item: null,
      showModal: false,
    }
  },
  created() {
    this.getActualites()
  },
  computed: {
    orderActualite() {
      return orderBy(this.actualites, 'order', 'asc')
    },
  },
  methods: {
    after(position) {
      return {
        type: 'after',
        id: this.actualites.find((actialite, index) => index == position).id,
      }
    },
    before(position) {
      return {
        type: 'before',
        id: this.actualites.find((actialite, index) => index == position).id,
      }
    },
    draggedToNewIndex({ newIndex, oldIndex }) {
      const id = this.actualites.find(
        (actialite, index) => index == oldIndex,
      ).id
      const position =
        oldIndex > newIndex ? this.before(newIndex) : this.after(newIndex)
      this.$http
        .put(
          `admin/actualites/update-order/${id}/departements/${this.$route.params.id}`,
          position,
        )
        .then((res) => {
          this.rearrangeActualite(oldIndex, newIndex)
        })
    },
    rearrangeActualite(from, to) {
      this.actualites.splice(to, 0, this.actualites.splice(from, 1)[0])
    },
    getActualites() {
      this.$http
        .get(`admin/actualites/departements/${this.$route.params.id}`)
        .then((res) => {
          this.actualites = res.data.data
          this.departement = res.data.departement
        })
    },
    deleteActualite() {
      this.$http.delete(`admin/actualites/${this.current_item.id}`).then(() => {
        this.actualites.splice(this.actualites.indexOf(this.current_item), 1)
        this.current_item = null
        this.showModal = false
      })
    },
    displayModal(item) {
      this.current_item = item
      this.showModal = true
    },
  },
}
</script>
