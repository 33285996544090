var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.baseComponent,{tag:"component",class:{
    'pl-3 sm:pl-5 remove-border': _vm.isActive && _vm.resizedWindow,
    'sm:pl-2': !_vm.isActive && _vm.resizedWindow,
  },attrs:{"to":_vm.link.path},nativeOn:{"click":function($event){return _vm.closeSideBar($event)}}},[_c('div',{staticClass:"flex items-center item-drive",class:{
      'text-promy-green-300 pl-3 mb-0 sm-ws:mb-0 sm:my-0':
        _vm.isActive && _vm.resizedWindow,
      'text-gray-700 pl-6 my-4  sm-ws:my-0 sm:my-1':
        !_vm.isActive && _vm.resizedWindow,
      'text-promy-green-300 ml-15 lg:ml-14 mb-0 sm-ws:my-0 sm:my-0 ':
        _vm.isActive && !_vm.resizedWindow,
      'text-gray-700 ml-15 lg:ml-14 my-4 sm-ws:my-0 sm:my-1 ':
        !_vm.isActive && !_vm.resizedWindow,

      'opacity-100': _vm.hover,
    },attrs:{"id":_vm.link.slug},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('div',{staticClass:"rounded-full w-12 h-12 border-2 border-solid border-promy-green-150 flex justify-center items-center"},[_c('img',{attrs:{"src":("/images/logo/" + _vm.current + ".png"),"alt":""}})]),_c('div',{staticClass:"flex h-8 sm-ws:h-auto items-stretch sm:items-center"},[_c('span',{staticClass:"self-end ml-2 mr-2 font-semibold sm:text-sm sm:self-center",class:{
          hidden: _vm.resizedWindow,
          '': !_vm.resizedWindow && !_vm.isActive,
          'ml-2 text-lg font-main font-bold': !_vm.resizedWindow,
          'text-promy-purple-300': !_vm.resizedWindow && _vm.isActive,
        }},[_vm._v(_vm._s(_vm.link.name))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }