<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {
      parentUrl: '/boite-a-outils',
      links: [
        {
          name: 'Bibliothèque',
          path: 'bibliotheque-documents',
          componentName: 'list-folders',
        },
        {
          name: 'Courriers type',
          path: 'courrier-type',
          componentName: 'courrier-type-list',
        },
      ],
    }
  },
  created() {
    this.$store.commit('subSidebar/LINKS', this.links)
    this.$store.commit('subSidebar/PARENT_URL', this.parentUrl)
  },
  mounted() {
    this.$store.commit('sidebar/RESIZE_WINDOW', true)
  },
  destroyed() {
    this.$store.commit('sidebar/RESIZE_WINDOW', false)
  },
}
</script>

<style></style>
