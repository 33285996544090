<template>
  <svg
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    viewBox="0 0 12 14"
  >
    <path
      id="arrow"
      class="cls-1"
      d="M2124,586.167a4.667,4.667,0,1,1,0-9.334V579.3l5.02-2.9-5.02-2.9v2a6,6,0,1,0,6,6h-1.33A4.677,4.677,0,0,1,2124,586.167Z"
      transform="translate(-2118 -573.5)"
      :class="classes"
    />
  </svg>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: 'fill-current',
    },
  },
}
</script>

<style></style>
