<template>
  <div>
    <h3 class="font-bold text-xl font-main">
      Listes des demandes
      <span class="font-normal">
        : demande offre: {{ demandeBienPige.announce_number }}
      </span>
    </h3>
    <div
      class="flex items-center justify-between my-2 border-b-2 lg:items-start xl:pb-5 lg:pb-4 xl:border-promy-gray-300 sm:flex-col"
    ></div>
    <div class="mt-6 mb-20">
      <div
        class="grid grid-cols-5 lg:flex lg:flex-col-reverse md:flex md:flex-col-reverse sm:flex sm:flex-col-reverse"
      >
        <ValidationObserver
          v-slot="{ handleSubmit }"
          ref="form"
          class="col-span-3 mt-5"
        >
          <div class="flex flex-col mr-10 sm:mr-0">
            <p class="font-bold font-main text-xl mb-8">
              Traitement de la demande
            </p>
            <div class="grid grid-cols-2 gap-10">
              <div class="flex flex-col space-y-2">
                <pro-input name="zone" v-model="form.zone" label="Zone" />
                <pro-input
                  type="number"
                  name="Taille parcelle"
                  v-model="form.taille_parcelle"
                  label="Taille parcelle"
                />
              </div>
              <div class="flex flex-col space-y-2">
                <pro-input
                  type="number"
                  name="Surface batie"
                  v-model="form.surface_bati"
                  label="Surface bâtie"
                />
                <pro-input
                  type="number"
                  name="Surface terrain"
                  v-model="form.surface_terrain"
                  label="Surface terrain"
                />
              </div>
            </div>
            <p class="font-bold font-main text-xl my-6">
              Contact du responsable de l’offre
            </p>
            <div class="grid grid-cols-2 gap-x-10">
              <div class="flex flex-col space-y-2">
                <div>
                  <label
                    for="input-264"
                    class="text-xs text-gray-600 font-bold font-arial"
                  >
                    Type de contact
                  </label>
                  <searchable-select
                    v-model="form.contact_type"
                    option-label="label"
                    option-value="id"
                    :options="contanctType"
                    placeholder="Type de contact"
                    class="mb-5 mt-1 sm:mb-2"
                  />
                </div>
                <pro-input
                  name="Nom du contact"
                  v-model="form.nom_agence"
                  label="Nom du contact"
                />
              </div>
              <div class="flex flex-col space-y-2">
                <pro-input
                  name="Num Tel"
                  v-model="form.num_tel"
                  label="Num tél"
                />
                <pro-input
                  requirements="website"
                  name="Site Web"
                  v-model="form.siteweb"
                  label="Site Web"
                />
              </div>
              <div class="flex flex-col space-y-2">
                <pro-input
                  requirements="email"
                  name="Email"
                  v-model="form.email"
                  label="Email"
                />
              </div>
              <div class="flex flex-col space-y-2">
                <pro-input
                  name="Adresse"
                  v-model="form.address"
                  label="Adresse"
                />
              </div>
            </div>
            <div class="flex sm:flex-col space-x-3 mt-10">
              <button
                v-if="$can('edite,pige_fonciere')"
                @click="updateDemandeBienPige"
                class="btn-green mt-8 sm:mt-6 uppercase py-2 text-base font-bold w-3/12 sm:w-full sm:ml-0"
              >
                Sauvegarder
              </button>
              <button
                v-if="$can('edite,pige_fonciere')"
                @click="handleSubmit(envoyerDemandeBienPige)"
                class="btn-green mt-8 sm:mt-6 uppercase py-2 text-base font-bold w-3/12 sm:w-full sm:!ml-0"
              >
                envoyer par email
              </button>
              <button
                v-if="$can('edite,pige_fonciere')"
                @click="markDemandeBienPigeAsAbanded"
                class="btn-tomato mt-8 sm:mt-6 uppercase py-2 text-base font-bold w-3/12 sm:w-full sm:!ml-0"
              >
                abandonner
              </button>
            </div>
          </div>
        </ValidationObserver>

        <div class="col-span-2 bg-[#e9e9e9] px-10 py-6">
          <div>
            <p class="font-bold font-main text-xl mb-8">Contact du promoteur</p>
            <div class="grid grid-cols-3 gap-x-10">
              <div class="flex flex-col space-y-7">
                <div class="flex flex-col">
                  <span class="font-arial font-bold text-xs">Nom</span>
                  <span class="font-arial text-base font-normal">
                    {{ demandeBienPige.promoteur.full_name }}
                  </span>
                </div>
                <div class="flex flex-col">
                  <span class="font-arial font-bold text-xs">Num tél</span>
                  <span class="font-arial text-base font-normal">
                    {{ demandeBienPige.promoteur.telephone }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col space-y-7">
                <div class="flex flex-col">
                  <span class="font-arial font-bold text-xs">
                    Raison sociale
                  </span>
                  <span class="font-arial text-base font-normal">
                    {{ demandeBienPige.promoteur.raison_social }}
                  </span>
                </div>
                <div class="flex flex-col !mt-auto">
                  <span class="font-arial font-bold text-xs">Email</span>
                  <span class="font-arial text-base font-normal">
                    {{ demandeBienPige.promoteur.email }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col space-y-7">
                <div class="flex flex-col">
                  <span class="font-arial font-bold text-xs">Qualité</span>
                  <span class="font-arial text-base font-normal">{{
                    demandeBienPige.promoteur.qualite_societe
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-15">
            <p class="font-bold font-main text-xl mb-8">
              Informations de la demande
            </p>
            <div class="grid grid-cols-3 gap-x-10 mb-10">
              <div class="flex flex-col space-y-7">
                <div class="flex flex-col">
                  <span class="font-arial font-bold text-xs">Statut</span>
                  <span class="font-arial text-base font-normal">
                    En cours de traitement
                  </span>
                </div>
                <div class="flex flex-col">
                  <span class="font-arial font-bold text-xs">
                    Nom de l’offre
                  </span>
                  <span class="font-arial text-base font-normal">
                    {{ demandeBienPige.announce_number }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col space-y-7">
                <div class="flex flex-col">
                  <span class="font-arial font-bold text-xs">
                    Date de demande
                  </span>
                  <span class="font-arial text-base font-normal">
                    {{ demandeBienPige.created_at }}
                  </span>
                </div>
                <div class="flex flex-col !mt-auto">
                  <span class="font-arial font-bold text-xs">
                    Lien de l’annonce
                  </span>
                  <template v-if="form.lien_annonce">
                    <a
                      :href="form.lien_annonce"
                      target="_blank"
                      class="font-arial text-base font-bold text-promy-green-150"
                    >
                      Annonce
                    </a>
                  </template>
                  <template v-else> - </template>
                </div>
              </div>
              <div class="flex flex-col space-y-7">
                <div class="flex flex-col">
                  <span class="font-arial font-bold text-xs">
                    Date mise à jour
                  </span>
                  <span class="font-arial text-base font-normal">
                    {{ demandeBienPige.updated_at }}
                  </span>
                </div>
              </div>
            </div>
            <p class="font-arial font-bold text-xs mb-3">Demande</p>
            <span class="font-arial text-base font-normal">
              {{ demandeBienPige.description }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['status', 'getStatusColor'],
  data() {
    return {
      contanctType: ['professionnel', 'particulier'],
      form: {
        status: '0',
        zone: '',
        taille_parcelle: '',
        surface_bati: '',
        surface_terrain: '',
        email: '',
        siteweb: '',
        num_tel: '',
        adresse: '',
        contact_type: 'professionnel',
        nom_agence: '',
        address: '',
      },
      isRequired: false,
      demandeBienPige: {
        promoteur: {},
      },
    }
  },
  created() {
    this.getListDemandeBienPige()
  },
  computed: {
    shouldBerRequired() {
      /* return this.isRequired ? 'required' : '' */
      return
    },
  },
  methods: {
    getStatusColorName(status) {
      switch (status) {
        case '3':
          return 'abandoné'
        case '1':
          return 'encours de traitement'
        case '2':
          return 'traité'
      }
    },
    async getListDemandeBienPige() {
      const { data: data } = await this.$http.get(
        `admin/demande-bien-pige/${this.$route.params.id}`,
      )

      this.form = { ...data.data.terrain_info, ...data.data.agence }

      this.demandeBienPige = data.data.demandeBienPige
    },
    async updateDemandeBienPige() {
      this.isRequired = false
      this.$http.put(
        `admin/demande-bien-pige/${this.$route.params.id}`,
        this.form,
      )
      this.$toaster.success(
        'Les informations saisies ont bien été sauvegardées pour cette demande',
      )
    },
    async envoyerDemandeBienPige() {
      this.isRequired = true
      const valid = await this.$refs.form.validate()
      if (!this.form.contact_type) {
        this.$toaster.warning('le type de contact est obligatoire ')
        return
      }

      if (!valid) return
      await this.$http.patch(
        `admin/demande-bien-pige/envoyer-par-mail/${this.$route.params.id}`,
        this.form,
      )
      this.$toaster.success(
        'Une confirmation par email a été envoyé au promoteur ayant soumis la demande',
      )
    },
    async markDemandeBienPigeAsAbanded() {
      await this.$http.post(
        `admin/demande-bien-pige/mark-as-abandoned/${this.$route.params.id}`,
      )
      this.$toaster.success(
        "Cette demande a bien été abandonnée en raison d'un manque d'informations. Un email a été envoyé au promoteur",
      )
    },
  },
}
</script>
