export default {
  methods: {
    InitRoundedDoughnut() {
      Chart.defaults.RoundedDoughnut = Chart.helpers.clone(
        Chart.defaults.doughnut,
      )
      Chart.controllers.RoundedDoughnut = Chart.controllers.doughnut.extend({
        draw: function (ease) {
          var ctx = this.chart.ctx
          var easingDecimal = ease || 1
          var arcs = this.getMeta().data
          Chart.helpers.each(arcs, function (arc) {
            arc.transition(easingDecimal).draw()

            var pArc = arcs[0]
            var pColor = pArc._view.backgroundColor

            var vm = arc._view
            var radius = (vm.outerRadius + vm.innerRadius) / 2
            var thickness = (vm.outerRadius - vm.innerRadius) / 2
            var startAngle = Math.PI - vm.startAngle - Math.PI / 2

            var angle = Math.PI - vm.endAngle - Math.PI / 2
            ctx.save()
            ctx.translate(vm.x, vm.y)
            ctx.fillStyle = vm.backgroundColor
            ctx.beginPath()
            ctx.arc(
              radius * Math.sin(startAngle),
              radius * Math.cos(startAngle),
              thickness,
              0,
              2 * Math.PI,
            )
            ctx.fill()
            ctx.fillStyle = pColor
            ctx.beginPath()
            ctx.arc(
              radius * Math.sin(angle),
              radius * Math.cos(angle),
              thickness,
              0,
              2 * Math.PI,
            )
            ctx.fill()
            ctx.restore()
          })
        },
      })
    },
  },
}
