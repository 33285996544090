<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des agent immobiliers </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>{{ action }} une team agent immobilier</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="
            nextPath('list-des-teams-agents-immobilier-component')
          "
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <Team :form="form" ref="team"></Team>
      <div class="my-12">
        <button
          @click="handleSubmit(save)"
          class="px-12 py-2 text-sm font-bold text-white uppercase transition-colors duration-200 border rounded-full bg-promy-green-300 hover:text-promy-green-300 hover:border-promy-green-300 hover:bg-white font-main"
        >
          valider
        </button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Team from './components/Team'

export default {
  components: {
    Team,
  },
  data() {
    return {
      action: 'Ajouter',
      storeURL: 'admin/team-agents-immobiliers',
      form: {
        software_id: '',
        software_ref: '',
        profile: '',
        raison_social: '',
        types: '',
        adresse: '',
        siren: '',
        capital: '',
        code_postal: '',
        ville: '',
        agent_id: [],
        departements: [],
      },
      error: [],
    }
  },
  created() {
    this.checkCurrentMode()
  },
  computed: {
    passwordRequirement() {
      return this.isCreate ? 'required' : ''
    },
    isCreate() {
      return this.$route.meta.mode !== 'edit'
    },
  },
  methods: {
    /**
     * Check current mode (edit, create)
     */
    checkCurrentMode() {
      if (this.isCreate) return
      this.storeURL = `admin/team-agents-immobiliers/${this.$route.params.id}`
      this.action = 'Editer'
      this.editableTeamAgent(this.$route.params.id)
    },

    /**
     * get updatable agent
     * params ID router id
     */
    editableTeamAgent(id) {
      this.$http.get(`admin/team-agents-immobiliers/${id}`).then((res) => {
        this.form = _.merge({}, this.form, res.data.team)
        this.$refs.team.checked_departements = res.data.team.departements.map(
          (dep) => ({ id: dep.id, name: dep.name }),
        )
      })
    },

    /**
     * Presist current agent information
     */
    save() {
      if (this.action == 'Editer') {
        this.$http
          .put(this.storeURL, this.form)
          .then(() => {
            this.$toaster.success(
              'vous avez modifié la team des agents immobiliers avec succès',
            )
            this.$router.push({
              name: 'list-des-teams-agents-immobilier',
            })
          })
          .catch((err) => {
            this.$refs.form.setErrors(err.response.data.errors)
          })
      } else {
        this.$http
          .post(this.storeURL, this.form)
          .then(() => {
            this.$toaster.success(
              'vous avez ajouté une nouvelle team des agents immobiliers avec succès',
            )
            this.$router.push({
              name: 'list-des-teams-agents-immobilier',
            })
          })
          .catch((err) => {
            this.$refs.form.setErrors(err.response.data.errors)
          })
      }
    },
  },
}
</script>

<style></style>
