<template>
  <div class="flex sm:flex-col justify-between sm:space-y-4 my-6">
    <button
      v-if="is_previous_exist"
      @click="handlebtn(-1)"
      class="rounded-full text-center w-50 sm:w-full mr-4 sm:mr-0 border-2 bg-promy-green-300 px-6 border-promy-green-300 py-3 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
    >
      Précédent
    </button>

    <button
      type="submit"
      v-if="is_next_exist"
      @click="handlebtn(1)"
      class="rounded-full text-center w-50 sm:w-full border-2 bg-promy-green-300 px-6 border-promy-green-300 py-3 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
    >
      Suivant
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      is_next_exist: true,
      is_previous_exist: true,
    }
  },
  computed: {
    ...mapGetters({
      ContexteGeneraleStore: 'dossierpromoteur/getContexteGenerale',
      progress_section: 'dossierpromoteur/getProgressSection',
      sublinks: 'subSidebar/links',
      parametrageSections: 'dossierpromoteur/getActiveSections',
    }),
  },
  created() {
    this.is_next_exist = this.getLink(1) ? true : false
    this.is_previous_exist = this.getLink(-1) ? true : false
  },
  methods: {
    getLink(incrementOrDecrement) {
      let sublinks_exist = this.sublinks.filter(
        (link) => link.is_exist === true,
      )
      let index_link = sublinks_exist.findIndex(
        (link) => link.path_route === this.$route.path.split('/')[2],
      )
      if (sublinks_exist[index_link + incrementOrDecrement]) {
        return `/dossier-promoteurs/${
          sublinks_exist[index_link + incrementOrDecrement].path_route
        }`
      } else return null
    },
    handlebtn(incrementOrDecrement) {
      let link = this.getLink(incrementOrDecrement)
      let url = this.$route.params.id
        ? link + '/' + this.$route.params.id
        : link
      this.$router.push(url)
    },
  },
}
</script>
