<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Paramétrage</page-title>
    <table-title
      nextPath="ajouter"
      class="sm:flex-col sm:items-start sm:space-y-3"
    >
      <template v-slot:title>Liste des départements avec actualités</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          v-if="$can('edite,gestion_utilisateur')"
          @click.prevent="nextPath('actualite-form')"
          class="green-text-btn"
        >
          ajouter une actualité
        </div>
      </template>
    </table-title>

    <hr class="my-6" />
    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :sortingBy="sorting_by"
          :hasSort="sort.sortKey ? true : false"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <pro-table-row
        v-for="(item, index) in departements"
        :key="index"
        class="relative"
      >
        <pro-table-cell label="Nom département" class="w-1/4 lg:w-full">
          {{ item.name }}
        </pro-table-cell>
        <pro-table-cell label="Numéro département" class="w-1/4 lg:w-full">
          {{ item.numerodepartement }}
        </pro-table-cell>
        <pro-table-cell label="Nombre d'actualités" class="w-1/4 lg:w-full">
          {{ item.actualites_count }}
        </pro-table-cell>
        <pro-table-cell :actions_btn="true" class="w-1/4 lg:w-full">
          <div class="w-full flex justify-end items-center space-x-3">
            <router-link
              :to="{ name: 'actualite-edit', params: { id: item.id } }"
              class="btn-actions"
            >
              <i class="fas fa-eye"></i>
            </router-link>
          </div>
        </pro-table-cell>
      </pro-table-row>
    </pro-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Nom département',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Numéro département',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: "Nombre d'actualités",
          sortKey: '',
          classes: 'w-1/4',
        },
      ],
      sorting_by: '',
      departements: [],
    }
  },
  created() {
    this.getDepartements()
  },
  methods: {
    getDepartements() {
      this.$http.get('admin/actualites/departements').then((res) => {
        this.departements = res.data.data
      })
    },
  },
}
</script>

<style scoped></style>
