import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      max_parcelles_aggregate: 20,
    }
  },

  methods: {
    getUnionParcelles() {
      return this.$turf.union(
        this.informations.current_parcelle,
        ...this.informations.parcelles_voisines,
      )
    },
  },
  computed: {
    ...mapGetters({
      map: 'defaultmap/map',
      informationsStore: 'dossierpromoteur/getinfos',
      features_autour: 'parcelle/features_autour',
      parcelles_autour: 'parcelle/parcelles_autour',
      base_layers: 'parcelle/base_layers',
      is_street: 'parcelle/is_street',
      saved_sources: 'parcelle/saved_sources',
      saved_layers: 'parcelle/saved_layers',
      error_parcelle_not_found: 'parcelle/error_parcelle_not_found',
      markers_altimetrie: 'parcelle/markers_altimetrie',
      markers_walls: 'parcelle/markers_walls',
      is_parcelle_aggregate: 'parcelle/is_parcelle_aggregate',
      is_parcelle_changed: 'parcelle/is_parcelle_changed',
      is_corner_changed: 'parcelle/is_corner_changed',
    }),
  },
}
