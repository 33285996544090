import axios from 'axios'
export default {
  data() {
    return {
      is_retrait_gonflements_sols_argileux_loaded: false,
    }
  },
  methods: {
    setRetraitGonflementsSolsArgileux(risques, RETRAIT) {
      let alea_values = ['Aléa faible', 'Aléa moyen', 'Aléa fort']
      let pprn_info = []
      RETRAIT.sub_title.forEach((sub) => {
        if (sub.key === 'alea') {
          axios
            .get(
              `https://mapsref.brgm.fr/wxs/georisques/rapport?version=1.0.0&service=wfs&request=getfeature&typename=ALEARG_XY&propertyname=id&X=${this.lat}&Y=${this.long}&rayon=25&resultType=results`,
            )
            .then(
              (response) => {
                let data_json = this.xmlToJson(response.data)
                if (
                  data_json['wfs:FeatureCollection']['gml:boundedBy'][
                    'gml:null'
                  ]
                ) {
                  sub.value = 'Non'
                } else {
                  let idx =
                    data_json['wfs:FeatureCollection']['gml:featureMember'][
                      'ms:ALEARG_XY'
                    ]['ms:id']['_text']
                  sub.value = alea_values[idx - 1]
                }
                this.is_retrait_gonflements_sols_argileux_loaded = true
              },
              (error) => {
                console.log(error)
                this.is_retrait_gonflements_sols_argileux_loaded = true
              },
            )
        }

        if (sub.key === 'ppr') {
          if (this.notEmptyObject(risques.pprn)) {
            risques.pprn.forEach((pprn) => {
              pprn.listRisques.forEach((rsq) => {
                if (sub.codes.includes(rsq.numRisque)) {
                  sub.value = 'Oui'
                  pprn_info.push(pprn)
                }
              })
            })
            sub.value = sub.value === 'Oui' ? 'Oui' : 'Non'
            sub.bodyTable = this.setValuesTable(
              pprn_info,
              ['codeNatPpr', 'libellePpr'],
              'listRisques',
              'datePrescription',
              'dateApprobation',
            )
          } else {
            sub.value = 'Non'
          }
        }
      })
    },
  },
}
