<template>
  <div id="app" class="flex flex-1 relative h-full">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  computed: {
    ...mapGetters({
      permissions: 'auth/permissions',
    }),
    layout() {
      if (this.$route.matched.some((route) => route.meta.layout)) {
        return this.$route.matched.filter((element) => element.meta.layout)[0]
          .meta.layout
      }
      return 'div'
    },
  },
  watch: {
    permissions: {
      immediate: true,
      handler(permissions) {
        this.$ability.update([
          {
            subject: 'all',
            actions: this.permissions,
          },
        ])
      },
    },
  },
}
</script>
<style src="./assets/styles/tailwind.css"></style>
<style src="./assets/styles/extra.css"></style>

<style lang="scss">
@import './assets/styles/datepicker.scss';
html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  overflow: auto;
  font-family: 'Arial', sans-serif;
  min-height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.ql-editor {
  background-color: rgba(255, 255, 255, 0.712) !important;
  min-height: 4rem;
}
</style>
