<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title
      >Gestion des utilisateurs guichet unique grand public
    </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>{{ action }} un utilisateur</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('list-des-grand-public')"
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <div class="flex lg:flex-wrap">
      <div class="w-full pr-4 md:pb-4 md:pr-0">
        <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
          <div class="grid grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-4">
            <pro-input
              name="nom"
              requirements="required"
              v-model="form.nom"
              label="Nom"
            />
            <pro-input
              name="prenom"
              requirements="required"
              v-model="form.prenom"
              label="Prénom"
            />
            <pro-input
              requirements="required|email"
              v-model="form.email"
              label="Email"
              name="email"
              :disabled="!isCreate"
            />
            <InputPhoneNumber
              name="Tél Mobile"
              :rules="'required'"
              v-model="form.telephone"
              placeholder="+33"
              label="Tél Mobile"
            />
            <pro-input
              :requirements="passwordRequirement"
              name="Mot de passe"
              v-model="form.password"
              type="password"
              label="Mot de passe"
            />
            <div>
              <pro-input
                :requirements="passwordConfirmationRequirement"
                name="Confirmation de mot de passe"
                v-model="form.password_confirmation"
                type="password"
                label="Confirmation de mot de passe"
              />
              <span
                class="text-promy-red text-sm"
                v-if="passwordConfirmationError"
                >Les mots de passe doivent être identiques</span
              >
            </div>
          </div>
          <div
            class="flex flex-col space-y-4 mt-8 pt-8 border-t-2 border-promy-gray-300"
          >
            <div
              class="text-base tracking-wide font-main text-gray-800 font-bold"
            >
              Informations à propos de l'utilisateur
            </div>
            <div class="grid grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-4">
              <div class="flex flex-col">
                <span
                  class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
                >
                  Date de création du compte
                </span>
                <span>{{ form.created_at | filterDate }}</span>
              </div>
              <div class="flex flex-col">
                <span
                  class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
                >
                  Nombre des révélations réalisées
                </span>
                <span>{{ form.reveler_counts }}</span>
              </div>
              <div class="flex flex-col">
                <span
                  class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
                >
                  Nombre des adresses recherchées dans guiche unique
                </span>
                <span>{{ form.adress_counts }}</span>
              </div>
            </div>
          </div>
          <div class="my-12" v-if="$can('edite,gestion_utilisateur')">
            <BaseButton
              @handleClick="handleSubmit(save)"
              :disabled="!saveBtnDisabled(invalid)"
              :loading="loading"
            />
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { toMulipartedForm } from '../../../../utilities/form'

export default {
  data() {
    return {
      action: 'Ajouter',
      storeURL: 'admin/gugp',
      occupations: [],
      form: {
        nom: null,
        prenom: null,
        email: null,
        telephone: null,
        password: null,
        password_confirmation: null,
      },
      error: [],
      loading: false,
    }
  },
  created() {
    this.checkCurrentMode()
  },
  computed: {
    passwordRequirement() {
      return this.isCreate ? `required` : ''
    },
    passwordConfirmationRequirement() {
      return this.form.password ? 'required' : ''
    },
    passwordConfirmationError() {
      return (
        this.form.password_confirmation &&
        this.form.password !== this.form.password_confirmation
      )
    },
    isCreate() {
      return this.$route.meta.mode !== 'edit'
    },
  },
  methods: {
    saveBtnDisabled(invalid = false) {
      if (this.form.password && this.form.password_confirmation) {
        return (
          this.form.password === this.form.password_confirmation && !invalid
        )
      }
      return !invalid
    },
    checkCurrentMode() {
      if (this.isCreate) return
      this.storeURL = `admin/gugp/${this.$route.params.id}?_method=PUT`
      this.action = 'Editer'
      this.editableAdmin(this.$route.params.id)
    },

    editableAdmin(id) {
      this.$http.get(`admin/gugp/${id}`).then((res) => {
        this.form = Object.assign(this.form, res.data)
      })
    },
    save() {
      this.loading = true
      const form = toMulipartedForm(this.form)

      this.$http
        .post(this.storeURL, form)
        .then((response) => {
          this.$toaster.success(response.data.message)
          this.$router.push({
            name: 'list-des-grand-public',
          })
          this.loading = false
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors)
          this.loading = false
        })
    },
  },
}
</script>
