<template>
  <div
    class="cursor-pointer bg-promy-gray-225 relative border-dashed border-promy-gray-400 mt-4 lg:h-42 sm-ws:h-34 xl:h-45 w-full xl:w-6/7 mr-auto rounded-large flex justify-center items-center"
    @click="addNewImageBlock()"
    novalidate
  >
    <p class="uppercase text-sm font-bold text-gray-500 font-main">
      ajouter une image
    </p>
    <span
      class="absolute w-8 h-8 rounded-full bg-promy-green-300 top-0 right-0 -mr-3 -mt-3 flex justify-center items-center text-white font-bold text-lg"
      >+</span
    >
  </div>
</template>
<script>
export default {
  methods: {
    addNewImageBlock() {
      this.$emit('addNewImageBlock')
    },
  },
}
</script>
