<template>
  <validation-provider :name="name" :rules="requirements" v-slot="{ errors }">
    <div
      :class="[
        {
          'bg-promy-gray-225 border-promy-gray-600': dragging,
          'bg-promy-gray-225 ': !dragging,
          'border-2': !imageSource,
        },
        isCircle
          ? 'rounded-full h-full w-full'
          : 'rounded-large lg:h-42 sm-ws:h-34 xl:h-45 w-full xl:w-6/7',
      ]"
      @dragleave.prevent="dragging = false"
      @dragover.prevent="dragging = true"
      class="relative border-dashed border-promy-gray-400 mt-4 mr-auto"
      novalidate
    >
      <div
        v-if="!imageSource"
        class="absolute flex flex-col justify-center items-center inset-0"
      >
        <div class="text-promy-gray-400 text-3xl">
          <i class="fa fa-upload fa-6" aria-hidden="true"></i>
        </div>
        <div class="uppercase text-promy-green-300 text-xl font-bold font-main">
          upload
        </div>
        <div class="uppercase text-promy-gray-350 text-xl font-main">
          jpg, jpeg, png
        </div>
        <template v-if="isGroup">
          <button
            @click="deleteComponent()"
            type="button"
            class="absolute rounded-full bg-promy-green-300 text-white px-2 py-1 text-sm cursor-pointer hover:bg-promy-green-200 top-0 left-0"
            :class="'-mt-3 -ml-2'"
          >
            <i class="fa fa-trash-alt" aria-hidden="true"></i>
          </button>
        </template>
      </div>
      <img
        :src="getImageSource"
        class="transition duration-500 ease-in-out w-full h-full object-cover overflow-hidden"
        :class="isCircle ? 'rounded-full' : 'rounded-large'"
        v-if="imageSource"
      />
      <input
        ref="fileinput"
        @change.prevent="handleFilesChosen"
        class="input-file absolute opacity-0 w-full h-full inset-0 cursor-pointer"
        type="file"
        accept="image/jpeg, image/jpg, image/png"
      />
      <button
        v-if="imageSource"
        @click="deleteImage()"
        type="button"
        class="absolute rounded-full bg-promy-green-300 text-white px-2 py-1 text-sm cursor-pointer hover:bg-promy-green-200 top-0 right-0"
        :class="isCircle ? 'mt-3 mr-3' : '-mt-3 -mr-2'"
      >
        <i class="fa fa-trash-alt" aria-hidden="true"></i>
      </button>
    </div>
    <span class="mt-1 text-red-600 text-sm" v-if="errors[0]">
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>
<script>
import { constant } from 'lodash'
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    requirements: {
      type: String,
    },
    value: {
      required: true,
    },
    shape: {
      type: String,
    },
    isGroup: {
      type: Boolean,
    },
    is_storage_path: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) this.imageSource = value
      },
    },
  },
  data() {
    return {
      dragging: false,
      imageSource: '',
      isUploaded: false,
      isImage: true,
      file: null,
    }
  },
  computed: {
    isCircle() {
      return this.shape == 'circle'
    },
    getImageSource() {
      return this.isBase64(this.imageSource) || !this.is_storage_path
        ? this.imageSource
        : this.$base_file + '' + this.imageSource
    },
  },
  methods: {
    isBase64(str) {
      if (typeof str === 'string') return str.includes('data:image/')
      else false
    },
    handleFilesChosen(e) {
      this.file = e.target.files[0]
      this.readFile(this.file)
      this.$emit('input', this.file)
    },
    deleteImage() {
      this.$emit('deleteImage', '')
      this.$emit('input', null)
      this.imageSource = ''
    },
    readFile(file) {
      let typefile = file['type']

      if (['image/jpeg', 'image/jpg', 'image/png'].includes(typefile)) {
        let reader = new FileReader()
        let vm = this
        reader.onload = (f) => {
          vm.imageSource = f.target.result
        }
        this.isUploaded = true
        reader.readAsDataURL(file)
      } else {
        this.isImage = false
        this.resetFile()
      }
    },
    resetFile() {
      let input = this.$refs.fileinput
      input.type = 'text'
      input.type = 'file'
    },
  },
}
</script>
<style lang="scss" scoped>
.input-file {
  width: 100% !important;
}
</style>
