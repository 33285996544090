<script>
import { constant } from 'lodash'
export default {
  model: {
    prop: ['items'],
    event: 'update',
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItemsPro: {
      type: Array,
    },
  },
  watch: {
    selectedItemsPro: {
      immediate: true,
      handler(value) {
        if (value & (value.lenght > 0)) {
          value.forEach((item) => this.selectedItems.push(value))
        }
      },
    },
  },
  render() {
    return this.$scopedSlots.default({
      items: this.items,
      selectedItems: this.selectedItems,
      removeItem: this.removeItem,
      addItem: this.addItem,
      openSelected: this.openSelected,
      toggleVisibility: this.toggleVisibility,
      searchable: this.searchable,
      searchProps: {
        value: this.searchable,
      },
      inputEvents: {
        input: (e) => (this.searchable = e.target.value),
        keydown: (e) => {
          if (e.key == 'Enter') {
            e.preventDefault()
            this.filterItems()
          }
        },
      },
    })
  },
  data() {
    return {
      selectedItems: [],
      openSelected: false,
      searchable: '',
    }
  },
  methods: {
    toggleVisibility() {
      this.openSelected = !this.openSelected
    },
    filterItems() {},
    addItem(item) {
      if (this.selectedItems.includes(item)) return
      this.selectedItems.push(item)
      this.items.shift()
    },
    removeItem(item) {
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
    },
  },
}
</script>
