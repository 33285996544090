<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <div
      class="flex leading-2 text-promy-purple-400 text-promy-2xl font-extrabold font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Gestion des roles</span>
    </div>
    <div
      class="flex items-center lg:text-base mt-6 xl:text-lg font-main font-extrabold justify-between border-b-2 xl:pb-5 lg:pb-4 xl:border-promy-gray-300"
    >
      <span class="text-promy-gray-650 font-extrabold">Type d'utlisateur</span>
    </div>

    <div>
      <div
        class="lg:relative bg-white my-4 rounded-box px-6 py-7 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
        v-for="user in users"
        :key="user.type"
      >
        <router-link
          :to="user.route"
          class="sm:flex sm:flex-col grid gap-4 items-center grid-cols-2"
        >
          <div class="flex justify-start">
            <span class="text-gray-700 text-base capitalize font-semibold">
              {{ user.type }}
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      users: [
        {
          type: 'promoteur',
          route: 'promoteur/edit',
        },
        {
          type: 'admin',
          route: 'admin/edit',
        },
      ],
    }
  },
}
</script>
