import axios from 'axios'
export default {
  data() {
    return {
      is_pollution_sols_loaded: false,
    }
  },
  methods: {
    setPollutionSols(risques, POL) {
      let coords = this.informationsStore.center_parcelle_principale
      POL.definitions = [
        risques.definitions_risques.POL.definitionPol +
          risques.definitions_risques.POL.definition2Lien +
          risques.definitions_risques.POL.definition3,
      ]
      POL.sub_title = []
      this.setDetailRisquePOL(risques, POL)

      POL.sub_title.forEach((sub) => {
        axios
          .get(
            `https://mapsref.brgm.fr/wxs/georisques/rapport?version=1.0.0&service=wfs&request=getfeature&typename=${sub.layer}&X=${coords[0]}&Y=${coords[1]}&rayon=${sub.rayon}&resultType=results`,
          )
          .then(
            (response) => {
              let data_json = this.xmlToJson(response.data)
              sub.value = data_json['wfs:FeatureCollection']['gml:boundedBy'][
                'gml:null'
              ]
                ? 'Non'
                : Object.keys(
                    data_json['wfs:FeatureCollection']['gml:featureMember'],
                  ).length
              this.is_pollution_sols_loaded = true
            },
            (error) => {
              this.is_pollution_sols_loaded = true
              console.log(error)
            },
          )
      })
    },
    setDetailRisquePOL(risques, POL) {
      risques.definitions_risques.POL.alea.forEach((pol) => {
        this.setObjPOL(POL, 'alea', pol)
      })
    },
    setObjPOL(POL, key, obj) {
      let alea_obj = {
        key: key,
        id: obj.id,
        label: obj.label,
        title: obj.title,
        descr: obj.descr,
        rayon: obj.rayon,
        properties: obj.properties,
        layer: obj.id != 'BASIAS' ? obj.layer.join(',') : 'BASIAS_LOCALISE',
        value: '',
        service: obj.service,
        displayRayon: true,
      }
      POL.sub_title.push(alea_obj)
    },
  },
}
