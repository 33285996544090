<template>
  <div
    @click="pushNavigator()"
    class="flex justify-between items-center pl-5 cursor-pointer mb-5 sm-ws:mb-3 sm:mb-1 font-bold text-base relative h-11 sm-ws:h-9 sm:text-sm"
    :class="
      isActive
        ? 'text-promy-green-300'
        : 'text-gray-800 hover:text-promy-green-300'
    "
  >
    <span class="capitalize">{{ link.name }} </span>
    <p
      v-if="parentUrl === '/dossier-promoteurs'"
      class="flex justify-center items-center text-sm rounded-full w-7 h-7 bg-gray-100 mr-2"
    >
      {{ progress(link) }}%
    </p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
    parentUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      progress_section: 'dossierpromoteur/getProgressSection',
    }),
    isActive() {
      return this.$route.name == this.link.componentName
    },
  },
  methods: {
    pushNavigator() {
      this.$router
        .push({
          name: this.link.componentName,
          params: { id: this.$route.params.id },
        })
        .catch((err) => {})
      this.$store.commit('sidebar/COLLAPSE', true)
    },
    progress(section) {
      var regex = /-/g
      var key = section.path.replace(regex, '_')
      return this.progress_section[key]
    },
  },
}
</script>
