<template>
  <div
    class="flex-1 custom-popup flex w-3/4 lg:w-full relative flex-col map-parcelle"
    id="map_localisation"
  >
    <StylesMap class="z-10" :map="map" :moduleStore="moduleStore" />
    <div class="w-full h-full lg:h-60-screen" id="mapbox"></div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/mapbox'
import defaultmap from '@/mixins/dossierPromoteurs/defaultmap'
import parcelle from '@/mixins/cadastre/parcelle'
import batiment from '@/mixins/cadastre/batiment'
import helpers from '@/mixins/helpers'
import calculeHelpers from '@/mixins/cadastre/calculeHelpers'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'

export default {
  props: {
    allParcelles: {
      type: Array,
      required: true,
    },
  },
  mixins: [
    helpers,
    defaultmap,
    calculeHelpers,
    Mapbox,
    parcelle,
    batiment,
    sourcesAndLayers,
  ],
  mounted() {
    this.initMap()
  },
  computed: {
    map() {
      return this.$store.getters['defaultmap/map']
    },
  },
  methods: {
    initMap() {
      let vm = this
      this.$store.commit(
        'defaultmap/SET_MAP',
        this.createMap(this.informationsStore.center_parcelle_principale),
      )
      this.map.on('load', function () {
        vm.fitBoundsParcelles(vm.allParcelles)
        vm.addLayersParcelles(vm.allParcelles)
        vm.$store.commit('defaultmap/MAP_IS_STYLE_LOADED', true)
      })
      vm.map.on('style.load', vm.preserveLayers)
    },
  },
  beforeDestroy() {
    this.map.remove()
    this.$store.commit('defaultmap/RESET_MAP')
  },
}
</script>
<style lang="scss">
@import '../../../assets/styles/Mapbox/control-zoom.scss';
.map-parcelle {
  .mapboxgl-ctrl-group {
    border-radius: 10px;
    margin-top: 24px !important;
    margin-left: 24px !important;
  }
  .hidden-loader {
    display: none !important;
  }
  .translate-center {
    transform: translate(-50%, -50%);
  }
  .center-element {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
</style>
<style lang="scss">
.icon-circle {
  @apply flex text-black justify-center text-base items-center font-bold  font-main w-5 h-5 border-solid  border-white border-2;
  border-radius: 50%;
}
.marker {
  background-color: #ffffff78 !important;
  color: black !important;
  font-weight: bold;
  font-size: 12px !important;
  border-radius: 15px;
  padding: 1px 4px 1px 4px;
}
.map-parcelle {
  .mapboxgl-popup-content {
    border-radius: 13px !important;
  }
}
@import '../../../assets/styles/Mapbox/popup.scss';
</style>
