var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutDossierPromoteurs',{scopedSlots:_vm._u([{key:"progress_bar",fn:function(){return [_c('progress-bar',{attrs:{"section":"Le projet by promy","progress_section":_vm.progress_section.le_projet_by_promy}})]},proxy:true},{key:"current_page",fn:function(){return [_vm._v("Le projet by promy")]},proxy:true},{key:"body",fn:function(){return [(!_vm.default_data_is_loading)?_c('pro-tabs',{staticClass:"mt-8",attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tabs = ref.tabs;
var selectTab = ref.selectTab;
var currentTab = ref.currentTab;
return _c('pro-tab',{},[_c('div',{staticClass:"flex flex-row sm:flex-col"},_vm._l((tabs),function(tab){return _c('div',{key:tab.name,staticClass:"flex items-center mr-24 text-sm font-bold capitalize text-gray-800 sm:my-2",class:{
              'border-b-tab text-promy-green-300': tab.selected,
            }},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return selectTab(tab)}}},[_vm._v(_vm._s(tab.name))]),(tab.with_eye)?_c('div',{staticClass:"pl-2 flex items-center justify-center"},[(!_vm.form[tab.ref].show)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                  content: 'Affichez',
                  classes: _vm.classes,
                }),expression:"{\n                  content: 'Affichez',\n                  classes: classes,\n                }",modifiers:{"top":true}}],attrs:{"src":"/images/eye-off-outline.svg"},on:{"click":function($event){_vm.form[tab.ref].show = true}}}):_vm._e(),(_vm.form[tab.ref].show)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                  content: 'Masquez',
                  classes: _vm.classes,
                }),expression:"{\n                  content: 'Masquez',\n                  classes: classes,\n                }",modifiers:{"top":true}}],attrs:{"src":"/images/eye-outline.svg"},on:{"click":function($event){_vm.form[tab.ref].show = false}}}):_vm._e()]):_vm._e()])}),0),_c('hr',{staticClass:"mt-2"}),_c('div',[_c(currentTab,{ref:currentTab,tag:"component",attrs:{"form":_vm.form}})],1)])}}],null,false,42171456)}):_vm._e()]},proxy:true},{key:"btn-actions",fn:function(){return [_c('sauvgard-publier-buttons',{on:{"saveInfos":function($event){return _vm.saveInfos()}}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }