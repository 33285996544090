<template>
  <div class="flex flex-col">
    <PopupParametrageSectionsDossierPromoteur
      v-if="show_popup_sections"
      :showPopupSections="show_popup_sections"
      @closePopup="show_popup_sections = false"
    ></PopupParametrageSectionsDossierPromoteur>
    <Popup
      max_width_model="max-w-5xl sm:max-w-xs"
      v-show="showPublicationNotes"
      @close="showPublicationNotes = false"
    >
      <h2
        class="font-bold font-main text-promy-primary text-lg pb-10"
        slot="title"
      >
        Raison de republication
      </h2>
      <div class="text-base font-normal text-promy-gray-250 mt-3" slot="body">
        <pro-table
          v-if="notes && notes.length > 0"
          v-model="notes"
          itemsStyle="box-shadow: 0px 0px 18px 0px #6C6C6C26; margin-left: 10px;"
        >
          <pro-table-th slot="pro-table-th">
            <div
              class="grid items-center grid-cols-2 gap-4 px-6 mb-8 sm:flex sm:flex-col"
            >
              <div class="flex justify-start">
                <p
                  class="flex justify-between items-center max-w-full py-2 pl-4 pr-5 text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670 cursor-pointer"
                >
                  Date
                </p>
              </div>

              <div class="flex">
                <p
                  class="flex justify-between items-center max-w-full py-2 pl-4 pr-5 text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670 cursor-pointer"
                >
                  Commentraire
                </p>
              </div>
            </div>
          </pro-table-th>
          <pro-table-row slot="pro-table-row" slot-scope="{ item }">
            <pro-table-cell>
              <span class="text-sm font-normal text-gray-700 font-arial">
                {{ item.created_at | filterDate }}
              </span>
            </pro-table-cell>
            <pro-table-cell>
              <span class="text-sm font-normal text-gray-700 font-arial">
                {{ item.description }}
              </span>
            </pro-table-cell>
          </pro-table-row>
        </pro-table>
        <p class="text-black" v-else>Aucune note pour ce dossier promoteur.</p>
      </div>
    </Popup>
    <ValidateModal
      v-if="showRegeneratePDFPopup"
      @isCancel="generatePdf"
      @isValide="showRegeneratePDFPopup = false"
    >
      <slot slot="message_body"
        >Attention, cette action permet uniquement de régénérer le PDF, elle ne
        permettra pas la sauvegarde d'éventuelles modifications.</slot
      >
      <slot slot="button_valide">Quitter</slot>
      <slot slot="button_cancel">Continuer</slot>
    </ValidateModal>
    <div
      class="flex sm:flex-col-reverse justify-between items-start mb-11 lg:mb-8 sm-ws:mb-6 sm:mb-4"
    >
      <div class="flex flex-col w-full">
        <div
          class="flex lg:flex-col-reverse justify-between leading-2 text-promy-purple-400 text-promy-2xl font-extrabold font-main sm:text-lg xl:mb-4 lg:mb-4"
          :class="
            currentStatus !== 2 && notes.length ? 'items-end' : 'items-start'
          "
        >
          <span>
            <span class="text-promy-gray-650">Dossier Promoteur:</span>
            <span class="text-promy-gray-625 ml-2 font-normal sm:text-xl">
              <slot name="adresse">{{ addressDP }}</slot>
            </span>
            <template v-if="currentStatus !== 2 && notes.length">
              <div class="flex">
                <div
                  v-if="$route.params.id"
                  class="flex sm:flex-col sm:space-y-3 items-center space-x-5 py-6 px-9 w-23/24 sm-ws:py-4 sm-ws:px-4 bg-[#FFF5C0] rounded-lg mt-5"
                >
                  <p class="text-sm font-normal font-arial text-[#A48B0C]">
                    Ce dossier a déjà été republié plusieurs fois, vous pouvez
                    consulter les motifs de republication en cliquant sur le
                    bouton
                  </p>
                  <button
                    @click="showPublicationNotes = true"
                    class="flex justify-between items-center py-2 rounded-full text-center border-2 bg-promy-green-300 border-promy-green-300 px-4 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
                  >
                    Raison de republication
                  </button>
                </div>
              </div>
            </template>
          </span>
          <div class="w-84 lg:mb-4 self-end">
            <div
              @click="navigateBack"
              class="text-lg tracking-wide capitalize cursor-pointer text-promy-green-300 mb-9 text-right"
            >
              ← retour
            </div>
            <div class="flex flex-col items-end space-y-2">
              <button
                class="w-fit flex justify-between items-center py-2 rounded-full text-center border-2 bg-promy-green-300 border-promy-green-300 px-4 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
                @click="show_popup_sections = true"
              >
                Sections actives
              </button>

              <slot name="progress_bar" class="w-full"></slot>
            </div>
          </div>
        </div>
        <div
          class="flex justify-between lg:text-base xl:text-lg font-main font-extrabold border-t-2 xl:pt-8 lg:pb-4 xl:border-promy-gray-325"
        >
          <p class="text-promy-gray-650">
            <span>
              <slot name="current_page"></slot>
            </span>
            <span> - Ajouter/Editer un terrain</span>
          </p>
          <div class="flex flex-col">
            <numberSeenDossierPromoteur />
            <button
              v-if="hasPDF"
              @click="showRegeneratePDFPopup = true"
              class="flex items-center justify-center px-4 py-2 text-sm font-extrabold text-white uppercase border-2 rounded-full cursor-pointer bg-promy-green-300 border-promy-green-300 font-main sm:w-full mt-3"
            >
              Régénérer PDF
            </button>
          </div>
        </div>
        <div class="flex flex-1 w-full flex-col pt-4">
          <slot name="body"></slot>
        </div>
        <div class="flex justify-between sm:flex-col">
          <slot name="btn-actions"></slot>
          <prev-next-buttons />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import numberSeenDossierPromoteur from './numberSeenDossierPromoteur'
export default {
  data() {
    return {
      showPublicationNotes: false,
      showRegeneratePDFPopup: false,
      show_popup_sections: false,
    }
  },
  components: {
    numberSeenDossierPromoteur,
  },
  computed: {
    ...mapGetters({
      informationsStore: 'dossierpromoteur/getinfos',
      notes: 'dossierpromoteur/getNotes',
      currentStatus: 'dossierpromoteur/getCurrentStatus',
    }),

    addressDP() {
      return this.informationsStore.title ? this.informationsStore.title : ''
    },
    hasPDF() {
      return this.informationsStore.has_pdf === 1 ? true : false
    },
  },
  methods: {
    generatePdf() {
      this.$http
        .get(`admin/dossier-promoteurs/${this.$route.params.id}/regenerate-pdf`)
        .then(() => {
          this.informationsStore.has_pdf = 0
          this.showRegeneratePDFPopup = false
        })
    },
    navigateBack() {
      this.$router.go(-1)
    },
  },
}
</script>
