import transport from '@/plugins/axios'
export default {
  namespaced: true,

  state: {
    categories: [],
    roles: [],
    loading: false,
  },

  actions: {
    //categories
    async getAllcategories({ commit }, params) {
      commit('SET_LOADING', true)
      let list = await transport.get(
        `admin/${params.type}/categories/permissions`,
      )
      commit('SET_CATEGORIES', list.data)
      commit('SET_LOADING', false)
    },
    async addCategory({ commit, state }, params) {
      commit('SET_LOADING', true)
      try {
        const createCategory = await transport.post(
          `admin/${params.type}/categories/permissions`,
          params,
        )
        commit('SET_LOADING', false)
        state.categories.unshift(createCategory.data)
        return 'Ok'
      } catch (e) {
        commit('SET_LOADING', false)
        return e
      }
    },
    async updateCategory({ commit, dispatch }, params) {
      commit('SET_LOADING', true)
      try {
        await transport.put(
          `admin/${params.type}/categories/permissions/${params.id}`,
          { name: params.data },
        )
        dispatch('getAllcategories')
        commit('SET_LOADING', false)
        return 'Ok'
      } catch (e) {
        commit('SET_LOADING', false)
        return e
      }
    },
    async deleteCategory({ commit, state }, params) {
      commit('SET_LOADING', true)
      try {
        await transport.delete(
          `admin/${params.type}/categories/permissions/${params.id}`,
        )
        state.categories = [
          ...state.categories.filter((category) => category.id != id),
        ]
        commit('SET_LOADING', false)
        return 'Ok'
      } catch (e) {
        commit('SET_LOADING', false)
        return e
      }
    },
    //roles
    async getAllroles({ commit }, params) {
      commit('SET_LOADING', true)
      let list = await transport.get(`admin/${params.type}/roles`)
      commit('SET_ROLES', list.data)
      commit('SET_LOADING', false)
    },
    async addRole({ commit, state }, params) {
      commit('SET_LOADING', true)
      try {
        const createdRole = await transport.post(
          `admin/${params.type}/roles`,
          params,
        )
        commit('SET_LOADING', false)
        state.roles.unshift(createdRole.data)
        return 'Ok'
      } catch (e) {
        commit('SET_LOADING', false)
        return e
      }
    },
    async updateRole({ commit, dispatch }, params) {
      commit('SET_LOADING', true)
      try {
        await transport.put(`admin/${params.type}/roles/${params.id}`, {
          name: params.data,
        })
        dispatch('getAllroles')
        commit('SET_LOADING', false)
        return 'Ok'
      } catch (e) {
        commit('SET_LOADING', false)
        return e
      }
    },
    deleteRole({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        transport
          .delete(`admin/${params.type}/roles/${params.id}`)
          .then((response) => {
            state.roles = [...state.roles.filter((role) => role.id != id)]
            commit('SET_LOADING', false)
            resolve('Ok')
          })
          .catch((error) => {
            commit('SET_LOADING', false)
            reject(error)
          })
      })
    },
  },

  mutations: {
    SET_CATEGORIES(state, list) {
      state.categories = list
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_ROLES(state, list) {
      state.roles = list
    },
  },

  getters: {
    getCategories(state) {
      return state.categories
    },
    getRoles(state) {
      return state.roles
    },
    isLoading(state) {
      return state.loading
    },
  },
}
