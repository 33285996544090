<template>
  <Popup max_width_model="max-w-xl max-w-xs" @close="closePopup()">
    <h2 class="font-bold font-main text-promy-primary text-lg" slot="title">
      <template v-if="team.deactivated_at">Réactiver</template
      ><template v-else>Désactiver </template> la team {{ team.name }}
      <template v-if="team.deactivated_at"
        >(Désactivée le {{ team.deactivated_at }})</template
      >
    </h2>
    <div class="text-base font-normal mt-3" slot="body">
      <template v-if="team.deactivated_at">
        <button
          class="px-8 mt-3 w-fit flex justify-between items-center py-2 rounded-full text-center border-2 bg-promy-green-300 border-promy-green-300 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
          @click="reactivateTeam()"
        >
          Réactivation
        </button>
      </template>
      <template v-else>
        <div class="flex justify-between">
          <div>
            <button
              class="px-8 mt-3 w-fit flex justify-between items-center py-2 rounded-full text-center border-2 bg-promy-green-300 border-promy-green-300 text-white uppercase font-main font-extrabold text-sm hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
              @click="deactivateImmediate()"
            >
              Désactivation immédiate
            </button>
          </div>
          <div class="flex flex-col w-auto">
            <div class="w-auto">
              <div class="absolute w-max">
                <datepicker
                  name="date_obtention"
                  :append-to-body="false"
                  :popup-style="{
                    left: 0,
                    top: ' 100%',
                    zIndex: 50,
                  }"
                  class="date-input"
                  v-model="deactivated_at"
                  valueType="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                >
                </datepicker>
              </div>
            </div>
            <div class="mt-10 w-full">
              <button
                class="px-8 mt-3 w-fit flex justify-between items-center py-2 rounded-full text-center border-2 bg-promy-green-300 border-promy-green-300 text-white uppercase font-main font-extrabold text-xs hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
                @click="activatedValidation()"
              >
                <template v-if="deactivated_at">
                  desactivation a {{ deactivated_at }}
                </template>
                <template v-else>Désactivation à partir du xx-xx-xx</template>
              </button>
            </div>
          </div>
        </div>
      </template>
      <ValidateModal
        contentClasses="w-[700px]"
        v-if="showConfirmationModal"
        @isValide="validated"
        @isCancel="quit"
      >
        <slot slot="message_body"> En êtes-vous vraiment sur ? </slot>
        <slot slot="button_valide">Oui</slot>
        <slot slot="button_cancel">Non</slot>
      </ValidateModal>
    </div>
  </Popup>
</template>

<script>
import { mapGetters } from 'vuex'
import deactivateTeam from '../../../../../store/modules/deactivateTeam'

export default {
  data() {
    return {
      deactivated_at: null,
      showConfirmationModal: false,
      isDeactivateImmediate: false,
    }
  },
  computed: {
    ...mapGetters({
      team: 'deactivateTeam/team',
    }),
  },
  methods: {
    closePopup() {
      this.$emit('closePopup')
    },
    deactivateImmediate() {
      this.isDeactivateImmediate = true
      this.activatedValidation()
    },
    activatedValidation() {
      this.showConfirmationModal = true
    },
    validated() {
      this.deactivateTeam()
      this.showConfirmationModal = false
      this.closePopup()
    },
    quit() {
      this.showConfirmationModal = false
    },
    async reactivateTeam() {
      const response = await this.$http.put(
        `/admin/teams/${this.team.id}/active`,
      )

      this.team.deactivated_at = null
    },
    async deactivateTeam() {
      if (!this.deactivateImmediate && !this.deactivated_at) {
        this.$toaster.warning('Veuillez choisir une date')
      }

      const response = await this.$http.put(
        `/admin/teams/${this.team.id}/deactivate`,
        {
          deactivated_at: this.deactivated_at,
        },
      )

      if (!this.deactivated_at) {
        const date = new Date()

        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        this.deactivated_at = `${day}-${month}-${year}`
      }

      this.team.deactivated_at = this.deactivated_at
    },
  },
}
</script>
