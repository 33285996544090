<template>
  <div>
    <div
      v-for="(info, index) in form.descriptifProjet.infosparcelle"
      :key="`infosparcelle-${index}`"
    >
      <h2 class="title-components">
        Info parcelle {{ info.section + info.parcelle }}
      </h2>
      <div
        class="grid grid-cols-2 gap-8 mt-1 mb-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
      >
        <div>
          <pro-input
            shouldBeHighlighted
            requirements="required"
            name="zonage"
            v-model="info.zonage"
            label="zonage"
          >
          </pro-input>
          <pro-input
            shouldBeHighlighted
            requirements="required"
            name="section"
            v-model="info.section"
            label="section"
          >
          </pro-input>
          <pro-input
            shouldBeHighlighted
            requirements="required"
            name="parcelle"
            v-model="info.parcelle"
            label="parcelle"
            type="number"
          >
          </pro-input>
          <pro-input
            shouldBeHighlighted
            requirements="required"
            name="parcelle"
            v-model="info.adresse"
            label="adresse"
          >
          </pro-input>
        </div>
        <div>
          <pro-input
            shouldBeHighlighted
            requirements="required"
            name="surface mesuree"
            v-model="info.surface_mesure"
            label="Surface mesurée"
            type="number"
          />
          <pro-input
            shouldBeHighlighted
            requirements="required"
            name="surface mesuree"
            v-model="info.surface_cadastrale"
            label="surface cadastrale"
            type="number"
          />
          <pro-input
            shouldBeHighlighted
            requirements="required"
            name="Nb bâtis existants"
            v-model="info.nb_bati"
            label="Nb bâtis existants"
            type="number"
          />
        </div>
      </div>
    </div>
    <div v-if="form.descriptifProjet.infosparcelle.length > 1">
      <h2 class="title-components mb-4">Total parcelles</h2>
      <div
        class="flex space-x-12 capitalize text-gray-600 font-bold font-arial"
      >
        <div class="flex flex-col">
          <span>
            Zonage :
            {{ getAllAttributs('zonage') }}
          </span>
          <span> section : {{ getAllAttributs('section') }} </span>
          <span> parcelle : {{ getAllAttributs('parcelle') }} </span>
        </div>
        <div class="flex flex-col">
          <span>
            surface mesurée :
            {{ getAllAttributs('surface_mesure', true) + ' m2' }}
          </span>
          <span>
            surface cadastrale :{{
              getAllAttributs('surface_cadastrale', true) + ' m2'
            }}
          </span>
          <span>
            nb bâtis existants : {{ getAllAttributs('nb_bati', true) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getAllAttributs(key, is_calculate) {
      if (is_calculate) {
        return this.form.descriptifProjet.infosparcelle
          .map((item) => item[key])
          .reduce(
            (accumulator, currentValue) =>
              parseFloat(accumulator) + parseFloat(currentValue),
          )
      }
      return _.uniq(
        this.form.descriptifProjet.infosparcelle.map((item) => item[key]),
      ).join(', ')
    },
  },
}
</script>
