function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
const defaultLayout = () =>
  import(/* webpackChunkName: '' */ '../../layouts/default')

import auth from '../../middleware/auth'

export default [
  {
    path: 'guichet-unique',
    name: 'guichet-unique',
    component: page('dashboard/GuichetUnique'),
    redirect: 'guichet-unique/dossier-synthese-du-guichet-unique',
    meta: {
      layout: defaultLayout,
      middleware: [auth],
    },
    children: [
      {
        path: 'demandes-plu',
        name: 'demandes-plu',
        component: page('dashboard/GuichetUnique/DemandesPLU/'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'dossier-synthese-du-guichet-unique',
        name: 'dossier-synthese-du-guichet-unique',
        component: page(
          'dashboard/GuichetUnique/DossierSyntheseDuGuichetUnique',
        ),
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
]
