<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <page-title>Mon Profile </page-title>
    <hr class="mt-6" />
    <h2
      class="text-base tracking-wide font-main text-gray-800 font-bold capitalize mt-8"
    >
      mes informations
    </h2>
    <div class="flex flex-row lg:flex-col mt-8">
      <div
        class="lg:w-full w-3/12 flex flex-col items-center lg:border-transparent border-r mr-12"
      >
        <Avatar :avatar="user.avatar" />
        <p class="mt-4 capitalize font-semibold text-gray-800">
          {{ user.fullName }}
        </p>
      </div>
      <div class="lg:w-full w-9/12 lg:pr-0 pr-24 mt-0 lg:mt-12">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Avatar from './components/avatar'
export default {
  components: {
    Avatar,
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
}
</script>
