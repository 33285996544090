<template>
  <div
    class="flex flex-col w-full mt-6 px-15 lg:px-4 sm-ws:px-10 mb-30"
    v-if="delegationMandat"
  >
    <div
      class="flex justify-between items-center font-extrabold leading-2 text-promy-purple-400 text-promy-2xl font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Gestion des délégations </span>
      <div
        @click="$router.push({ name: 'gestion-des-delegation-list' })"
        class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
      >
        ← retour
      </div>
    </div>
    <div
      class="flex justify-between border-gray-300 border-b-2 border-solid pb-5"
    >
      <div>
        <p class="font-main font-extrabold text-xl">Délégation</p>
        <p class="font-arial text-sm font-normal">
          {{ delegationMandat.created_at | filterDate }}
        </p>
        <span class="font-arial text-sm font-normal mr-2">
          {{ delegationMandat.numero_siret }}
        </span>
        <span
          :class="getStatusColor(getCurrentDelegationStatusColor)"
          class="text-white font-arial text-xs py-1 px-2 rounded-full"
        >
          {{ getCurrentDelegationStatusLabel }}
        </span>
      </div>
      <div style="width: 250px" v-if="notArchived">
        <searchable-select
          v-model="selectedStatus"
          option-label="label"
          :options="status"
          placeholder="Statut"
          class="w-full mb-5 mt-1 sm:mb-2"
        />
      </div>
    </div>
    <div class="grid grid-cols-3 md:grid-cols-1 gap-4 mt-18">
      <div>
        <p class="font-main font-extrabold text-xl mb-6">
          Informations de votre agence
        </p>
        <div class="grid grid-cols-2 gap-y-7">
          <BaseText
            label="Nom de votre société"
            :value="delegationMandat.nom_societe"
          />
          <BaseText label="N° Siret" :value="delegationMandat.numero_siret" />
          <BaseText
            label="Représenté par"
            :value="delegationMandat.represente_par"
          />
          <BaseText label="Tél" :value="delegationMandat.tel" />
          <BaseText label="Email" :value="delegationMandat.email" />
          <BaseText
            label="N° rue"
            :value="delegationMandat.adresse_bien_n_rue"
          />
          <BaseText label="Rue" :value="delegationMandat.adresse_bien_rue" />
          <BaseText
            label="Ville"
            :value="delegationMandat.adresse_bien_ville"
          />
          <BaseText
            label="Code postal"
            :value="delegationMandat.adresse_bien_code_postal"
          />
          <BaseText
            label="Agent commercial, inscrit au RSAC de"
            :value="delegationMandat.agen_comm_rsac"
          />
          <BaseText
            label="N° d'identification"
            :value="delegationMandat.agen_comm_sous_num"
          />
          <BaseText label="Fonction" :value="delegationMandat.fonction" />
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <div>
          <p class="font-main font-extrabold text-xl mb-6">Délégation</p>
          <div class="grid grid-cols-2 gap-y-7">
            <BaseText
              label="N° de délégation de mandat"
              :value="delegationMandat.par_mandat_n"
            />
            <BaseText
              label="Date"
              :value="delegationMandat.mandat_date | filterDate"
            />
          </div>
        </div>
        <div>
          <p class="font-main font-extrabold text-xl mb-6">Mandant</p>
          <div class="grid grid-cols-2 gap-y-7">
            <BaseText
              label="N° de mandat délégué"
              :value="delegationMandat.n_mandat_delegue"
            />
            <BaseText
              label="Date du mandat initial"
              :value="delegationMandat.date_mandat_initiale | filterDate"
            />
            <BaseText
              label="Propriétaire(s) du bien"
              :value="delegationMandat.proprietaires_biens.join(', ')"
            />
          </div>
        </div>
      </div>
      <div>
        <p class="font-main font-extrabold text-xl mb-6">
          Désignation et situation des biens à vendre
        </p>
        <div class="grid grid-cols-2 gap-y-7">
          <BaseText label="Type" :value="delegationMandat.nature" />
          <BaseText label="Adresse" :value="delegationMandat.adresse" />
          <BaseText
            classes="col-span-2"
            label="Désignation succincte du bien"
            :value="delegationMandat.designation_succincte"
          />
        </div>
      </div>
    </div>

    <div class="grid grid-cols-3 md:grid-cols-1 gap-4 mt-18">
      <div class="flex flex-col space-y-4">
        <div>
          <p class="font-main font-extrabold text-xl mb-6">
            Documents originaux
          </p>
          <div class="grid grid-cols-1 gap-y-5">
            <div v-if="delegationMandat.mandat_initiale">
              <BaseText
                noValue
                label="L'exemplaire de votre mandat de vente"
                classes="mb-2"
              />
              <BaseFile
                :id="delegationMandat.id"
                type="mandat_initiale"
                label="Mandat_initial.pdf"
                :link="delegationMandat.mandat_initiale"
              />
            </div>
            <div v-if="delegationMandat.fiche_technique_bien">
              <BaseText
                noValue
                label="Fiche technique du bien"
                classes="mb-2"
              />
              <BaseFile
                :id="delegationMandat.id"
                type="fiche_technique_bien"
                label="Fiche_technique_du_bien.pdf"
                :link="delegationMandat.fiche_technique_bien"
              />
            </div>
          </div>
        </div>
        <div>
          <p class="font-main font-extrabold text-xl mb-6">Document signé</p>
          <div v-if="delegationMandat.document_signe">
            <BaseText noValue label="Document signé" classes="mb-2" />
            <BaseFile
              :id="delegationMandat.id"
              type="document_signe"
              label="Document_signé.pdf"
              :link="delegationMandat.document_signe"
            />
          </div>
        </div>
      </div>
      <div class="pt-10">
        <p class="font-main font-extrabold text-xl mb-6">
          Clauses particulières
        </p>
        <div class="grid grid-cols-1 gap-y-7">
          <div>
            <p v-html="delegationMandat.clause_particuliere"></p>
          </div>
          <BaseText
            label="Durée de la délégation"
            :value="delegationMandat.duree_mandat"
          />
        </div>
      </div>
      <div
        class="bg-promy-gray-200 px-6 pb-8 pt-10 relative rounded-lg"
        v-if="delegationMandat.demande_suppression_description"
      >
        <p class="font-main font-extrabold text-xl mb-6">
          Raison de suppression
        </p>
        <div>
          {{ delegationMandat.demande_suppression_description }}
        </div>
      </div>
    </div>

    <div
      class="flex justify-between items-center mt-15 rounded-lg bg-green-100 py-9 px-10"
    >
      <p class="font-arial font-bold text-base text-promy-green-300">Prix</p>
      <p class="font-arial font-bold text-3xl text-promy-green-300">
        {{ delegationMandat.prix | formatEUR }} €
      </p>
    </div>

    <button
      v-if="notArchived"
      @click="changeStatus"
      class="btn-green mt-20 sm:mt-6 uppercase py-2 text-base font-bold w-3/12 sm:w-full sm:ml-0"
    >
      Sauvegarder
    </button>
  </div>
</template>

<script>
import BaseText from '../components/BaseText.vue'
import BaseFile from '../components/BaseFile.vue'

export default {
  components: { BaseText, BaseFile },
  data() {
    return {
      status: [
        { key: 'en_attente', label: 'En attente' },
        { key: 'accepté', label: 'Accepté' },
        { key: 'en_attente_supression', label: 'En attente de suppression' },
        { key: 'expiré', label: 'Expiré' },
        { key: 'supprimé', label: 'Supprimé' },
        { key: 'refusé', label: 'Refusé' },
      ],
      selectedStatus: null,
      delegationMandat: null,
    }
  },
  created() {
    this.fetchDelegation()
  },
  computed: {
    delegationMandatStatus() {
      return this.status.find((el) => el.key === this.delegationMandat.status)
    },
    getCurrentDelegationStatusLabel() {
      if (this.delegationMandat) return this.delegationMandatStatus.label
    },
    getCurrentDelegationStatusColor() {
      if (this.delegationMandat) return this.delegationMandatStatus.key
    },
    notArchived() {
      return (
        this.delegationMandat.status !== 'refusé' &&
        this.delegationMandat.status !== 'supprimé' &&
        this.delegationMandat.status !== 'expiré'
      )
    },
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case 'en_attente':
          return 'bg-promy-yellow'
        case 'accepté':
          return 'bg-promy-green-100'
        case 'en_attente_supression':
          return 'bg-promy-bleu-100'
        case 'expiré':
          return 'bg-promy-orange-100'
        case 'supprimé':
          return 'bg-promy-red'
        case 'refusé':
          return 'bg-promy-gray-625'
      }
    },
    fetchDelegation() {
      this.$http
        .get(`admin/delegation-mandats/${this.$route.params.id}`)
        .then(({ data }) => (this.delegationMandat = data))
    },
    changeStatus() {
      if (!this.selectedStatus) {
        this.$toaster.warning('veuillez choisir un statut valide')
        return
      }
      this.$http
        .post(`admin/delegation-mandats/${this.delegationMandat.id}`, {
          status: this.selectedStatus.key,
        })
        .then((res) =>
          this.$router.push({ name: 'gestion-des-delegation-list' }),
        )
        .catch((error) => console.log(error))
    },
  },
}
</script>
