<template>
  <validation-provider :rules="requirements" v-slot="{ errors }">
    <div class="flex items-center my-3">
      <div class="mr-8">
        <input
          requirements="required"
          type="checkbox"
          :id="uniqueId"
          :value="value"
          @click="handleInput()"
        />
        <label :for="uniqueId"></label>
      </div>
      <span class="text-gray-600 text-sm capitalize"> {{ label }} </span>
    </div>
    <span class="mt-1 text-red-600 text-sm" v-if="errors[0]">
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      required: true,
      type: String,
    },
    requirements: {
      type: String,
    },
  },

  computed: {
    uniqueId() {
      return `textarea-${this._uid}`
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', !this.value)
    },
  },
}
</script>
