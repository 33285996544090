<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar
      ><progress-bar
        section="Dossier photo"
        :progress_section="progress_section.dossier_photo"
      />
    </template>
    <template #current_page>Dossier photo</template>
    <template #body>
      <div class="dossier">
        <ValidateModal
          style="z-index: 9999999 !important"
          v-if="showModal"
          @isCancel="showModal = false"
          :with_actions="false"
        >
          <slot slot="message_title"> {{ current_link.title }}</slot>
          <slot slot="message_body">
            <iframe :src="current_link.link" style="height: 90vh; width: 95vw">
            </iframe>
          </slot>
        </ValidateModal>

        <div class="grid grid-cols-2 gap-x-4 sm:grid-cols-1 sm:gap-y-4 mb-4">
          <div class="flex flex-col">
            <span class="font-main font-extrabold text-promy-gray-650 mb-2">
              Photos
            </span>
            <div class="flex">
              <div class="w-full">
                <div class="grid grid-cols-3 items-end md:grid-cols-1 gap-y-4">
                  <div v-for="(image, index) in dossierPhotos" :key="index">
                    <InputImage
                      class="w-full"
                      :file="image"
                      :selfUploader="true"
                      @upload="updateImage($event, index)"
                      @deleteImage="deleteImage(index)"
                    />
                  </div>
                  <InputImage @upload="saveImage" />
                </div>
              </div>
              <!-- <HighlightMessage classes="w-fit" :show="noImageSelected" /> -->
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col">
              <span class="font-main font-extrabold text-promy-gray-650 mb-2">
                Visites virtuelle
              </span>
              <div>
                <div
                  class="flex flex-col space-x-3"
                  v-for="(vue_panoramique, index) in vues_panoramique"
                  :key="index + '_vue_panoramique'"
                >
                  <div class="flex space-x-4 items-end">
                    <div class="flex flex-1 space-x-4">
                      <pro-input
                        class="w-1/2"
                        :name="`Titre ${index + 1}`"
                        v-model.number="vue_panoramique.title"
                        :label="`Titre `"
                      />
                      <pro-input
                        class="w-1/2"
                        :name="`Lien ${index + 1}`"
                        v-model.number="vue_panoramique.link"
                        :label="`Lien `"
                      />
                    </div>

                    <div
                      @click="showVuePanoramique(vue_panoramique)"
                      class="bg-promy-green-300 rounded-full w-7 cursor-pointer h-7 flex justify-center items-center mb-3"
                    >
                      <i class="fas fa-eye text-white text-xs sm:mr-1"></i>
                    </div>
                  </div>

                  <div class="flex items-end">
                    <span
                      @click="removeVuePanoramique(index)"
                      v-if="vues_panoramique.length > 1"
                      class="text-promy-red ml-auto mr-1 cursor-pointer"
                    >
                      <i
                        class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"
                      ></i>
                    </span>
                  </div>
                </div>
                <div
                  class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
                  @click="pushVuePanoramique()"
                >
                  <p>ajouter un champ</p>
                  <div
                    class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
                  >
                    <i class="fa fa-plus text-xs" />
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-6" />
            <div class="flex flex-col">
              <span class="font-main font-extrabold text-promy-gray-650 mb-2">
                Vidéos
              </span>
              <div>
                <div
                  class="flex flex-col space-x-3"
                  v-for="(video, index) in dossier_fichiers.videos"
                  :key="index + '_vue_panoramique'"
                >
                  <div class="flex space-x-4 items-end">
                    <div class="flex flex-1 space-x-4">
                      <pro-input
                        class="w-1/2"
                        :name="`Titre ${index + 1}`"
                        v-model.number="video.title"
                        :label="`Titre `"
                      />
                      <pro-input
                        class="w-1/2"
                        :name="`Lien ${index + 1}`"
                        v-model.number="video.link"
                        :label="`Lien `"
                      />
                    </div>

                    <div
                      @click="showVideo(video)"
                      class="bg-promy-green-300 rounded-full w-7 cursor-pointer h-7 flex justify-center items-center mb-3"
                    >
                      <i class="fas fa-eye text-white text-xs sm:mr-1"></i>
                    </div>
                  </div>

                  <div class="flex items-end">
                    <span
                      @click="removeVideo(index)"
                      v-if="dossier_fichiers.videos.length > 1"
                      class="text-promy-red ml-auto mr-1 cursor-pointer"
                    >
                      <i
                        class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"
                      ></i>
                    </span>
                  </div>
                </div>
                <div
                  class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
                  @click="pushVideo()"
                >
                  <p>ajouter un champ</p>
                  <div
                    class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
                  >
                    <i class="fa fa-plus text-xs" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import progress from '@/mixins/dossierPromoteurs/progress'
import { mapGetters } from 'vuex'

export default {
  mixins: [progress],

  data() {
    return {
      dossierPhotos: [],
      showModal: false,
      dossier_fichiers: {
        videos: [
          {
            title: null,
            link: null,
          },
        ],
      },
      vues_panoramique: [
        {
          title: null,
          link: null,
        },
      ],

      current_link: {
        title: null,
        link: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      dossierPhotosStore: 'dossierpromoteur/getDossierPhotos',
      vuesPanoramiqueStore: 'dossierpromoteur/getVuesPanoramique',
      dossierFichiersStore: 'dossierpromoteur/getDossierFichiers',
      progress_section: 'dossierpromoteur/getProgressSection',
    }),
    noImageSelected() {
      return this.dossierPhotos.length === 0
    },
  },
  methods: {
    saveImage(file) {
      this.dossierPhotos.push(file)
    },
    updateImage(file, idx) {
      this.dossierPhotos.splice(idx, 1, file)
    },
    deleteImage(idx) {
      this.dossierPhotos.splice(idx, 1)
    },
    saveInfos() {
      this.$store.commit(
        'dossierpromoteur/setDossierPhotos',
        this.dossierPhotos,
      )
      this.$store.commit(
        'dossierpromoteur/setVuesPanoramique',
        this.vues_panoramique.filter((item) => item.link),
      )
      this.dossier_fichiers.videos = this.dossier_fichiers.videos.filter(
        (item) => item.link,
      )
      this.$store.commit(
        'dossierpromoteur/setDossierFichiers',
        this.dossier_fichiers,
      )
    },
    showVuePanoramique(vue_panoramique) {
      this.current_link = vue_panoramique
      this.showModal = true
    },
    pushVuePanoramique() {
      this.vues_panoramique.push({
        title: null,
        link: null,
      })
    },
    removeVuePanoramique(index) {
      this.vues_panoramique.splice(index, 1)
    },

    showVideo(video) {
      this.current_link = video
      this.showModal = true
    },
    pushVideo() {
      this.dossier_fichiers.videos.push({
        title: null,
        link: null,
      })
    },
    removeVideo(index) {
      this.dossier_fichiers.videos.splice(index, 1)
    },
    setProgressSection(is_photos = false) {
      let value =
        is_photos && this.dossierPhotos.length
          ? this.dossierPhotos
          : this.vues_panoramique.filter((item) => item.link)
      this.$store.commit('dossierpromoteur/setProgressSection', {
        section: 'dossier_photo',
        value: this.progressSection(value),
      })
    },
  },
  watch: {
    dossierPhotos: {
      deep: true,
      handler() {
        this.setProgressSection(true)
      },
    },
    vues_panoramique: {
      deep: true,
      handler() {
        this.setProgressSection()
      },
    },
  },
  created() {
    this.dossierPhotos =
      this.dossierPhotosStore && this.dossierPhotosStore.length
        ? this.dossierPhotosStore
        : this.dossierPhotos
    this.vues_panoramique =
      this.vuesPanoramiqueStore && this.vuesPanoramiqueStore.length
        ? this.vuesPanoramiqueStore
        : this.vues_panoramique
    this.dossier_fichiers = _.merge(
      {},
      this.dossier_fichiers,
      this.dossierFichiersStore,
    )
  },
  beforeRouteLeave(to, from, next) {
    this.saveInfos()
    next()
  },
}
</script>
