<template>
  <div class="w-full xl:px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <page-title
      >Gestion des messages pour la plateforme des promoteurs</page-title
    >
    <table-title nextPath="ajouter">
      <template v-slot:title>{{ action }} un message</template>
      <template slot="button" slot-scope="{ nextPath }">
        <router-link
          to="/parametrage/messages-platefrome-promoteurs/"
          class="capitalize text-promy-green-300 text-xs tracking-wide cursor-pointer"
        >
          &larr; retour
        </router-link>
      </template>
    </table-title>
    <hr class="my-6" />
    <div class="xl:pr-24">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <div>
          <div class="w-full flex justify-between">
            <div class="py-2">
              <label
                class="capitalize text-sm text-gray-700 font-bold font-arial"
              >
                Date de début de diffusion
              </label>

              <datepicker
                class="date-input py-1"
                v-model="form.start_at"
                valueType="DD-MM-YYYY"
                format="DD-MM-YYYY"
              ></datepicker>
            </div>
            <div class="my-2">
              <label
                class="capitalize text-sm text-gray-700 font-bold font-arial"
              >
                Date de fin de diffusion
              </label>
              <datepicker
                class="date-input py-1"
                v-model="form.end_at"
                valueType="DD-MM-YYYY"
                format="DD-MM-YYYY"
              ></datepicker>
            </div>
          </div>

          <div class="relative">
            <h2 class="title-components">Contenu</h2>
            <quill-editor
              class="mt-2"
              ref="myQuillEditor"
              v-model="form.content"
            />
          </div>
          <div class="my-6">
            <button
              @click="handleSubmit(save)"
              class="px-12 py-2 bg-promy-green-300 text-white hover:text-promy-green-300 border transition-colors duration-200 hover:border-promy-green-300 hover:bg-white rounded-full uppercase font-bold font-main text-sm"
            >
              valider
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { toMulipartedForm } from '../../../../utilities/form'

export default {
  data() {
    return {
      action: 'Ajouter',
      syntheseUrl: 'admin/messages-promoteurs',
      form: {
        content: '',
        start_at: null,
        end_at: null,
      },
      regions: [],
    }
  },
  created() {
    this.checkMode()
  },
  computed: {
    isCreateMode() {
      return this.$route.meta.mode !== 'edit'
    },
  },
  methods: {
    getImage(image) {
      return process.env.VUE_APP_BASE_STORAGE + image
    },
    checkMode() {
      if (this.isCreateMode) return
      this.action = 'Editer'
      this.getMessage(this.$route.params.id)
    },
    getMessage(id) {
      this.$http.get(`admin/messages-promoteurs/${id}`).then((res) => {
        this.form = res.data.data
      })
    },
    save() {
      this.$http.post(this.getSyntheseFullUrl(), this.form).then(() => {
        let toasterMsg = ''
        if (this.action === 'Editer') {
          toasterMsg = 'Vous avez édités le message avec succès'
        } else {
          toasterMsg = 'Vous avez ajoutés le message avec succès'
        }
        this.$toaster.success(toasterMsg)
        this.$router.push('/parametrage/messages-platefrome-promoteurs')
      })
    },
    getSyntheseFullUrl() {
      return this.isCreateMode
        ? `${this.syntheseUrl}`
        : `${this.syntheseUrl}/${this.$route.params.id}?_method=PUT`
    },
    deleteImage(index) {
      this.form.images.splice(index, 1)
    },
    updateImage(file, index) {
      this.form.images.splice(index, 1, file)
    },
    saveImage(file) {
      this.form.images.push({ path: file })
    },
  },
}
</script>
