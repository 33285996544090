<template>
  <div style="z-index: 999999">
    <div
      class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex sm:mx-6"
      style="z-index: 9999999"
    >
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div
          :class="contentClasses"
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <!--header-->
          <div class="flex justify-between mt-4 mx-6">
            <h1 class="text-promy-purple-400 font-black text-xl font-main">
              <slot name="message_title">Confirmation</slot>
            </h1>
            <closeIcon
              class="cursor-pointer"
              @click.native="toggleModal(false)"
            />
          </div>

          <!--body-->
          <div class="relative px-6 flex-auto">
            <p class="my-4 text-gray-600 text-lg font-arial leading-relaxed">
              <slot name="message_body"></slot>
            </p>
          </div>
          <!--footer-->
          <div
            class="flex items-center justify-center w-full my-4 rounded-b"
            v-if="with_actions"
          >
            <button
              class="btn-white uppercase px-5 py-2 text-base font-extrabold w-2/6 sm:w-2/5 mx-2"
              type="button"
              style="transition: all 0.15s ease"
              v-on:click.stop="toggleModal(true)"
            >
              <slot name="button_valide">Oui</slot>
            </button>
            <button
              class="btn-white uppercase px-5 py-2 text-base font-extrabold w-2/6 sm:w-2/5 mx-2"
              type="button"
              style="transition: all 0.15s ease"
              v-on:click.stop="toggleModal(false)"
            >
              <slot name="button_cancel">Non</slot>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
export default {
  props: {
    with_actions: {
      type: Boolean,
      default: true,
    },
    contentClasses: {
      type: String,
      default: '',
    },
  },
  methods: {
    toggleModal(is_valide) {
      is_valide ? this.$emit('isValide') : this.$emit('isCancel')
    },
  },
}
</script>
