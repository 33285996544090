export default {
  namespaced: true,
  state: {
    links: [],
    parent_url: null,
  },
  getters: {
    links(state) {
      return state.links
    },
    parent_url(state) {
      return state.parent_url
    },
  },
  mutations: {
    LINKS(state, links) {
      state.links = links
    },
    PARENT_URL(state, parent_url) {
      state.parent_url = parent_url
    },
  },
}
