<template>
  <div class="flex items-start" :class="{ 'opacity-50': !risque.is_exist }">
    <div class="flex w-14">
      <component :is="risque.icon"> </component>
    </div>
    <div class="flex flex-col flex-1">
      <div
        class="flex text-promy-purple-275 font-main font-extrabold text-promy-green-200"
      >
        {{ risque.name }}
      </div>
      <div class="text-sm">{{ risque.desc }}</div>
      <template v-if="risque.existing_subRisques.length">
        <ul class="list-inside dashed mt-1 text-sm">
          <li
            v-for="(sub_risque, idx) in risque.existing_subRisques"
            :key="idx"
          >
            - {{ sub_risque }}.
          </li>
        </ul>
      </template>

      <!-- <div
        v-if="risque.key != ''"
        class="flex items-end text-xs font-bold text-promy-green-200 cursor-pointer mt-1"
        @click="scrollToSingleRisque(risque.key)"
      >
        VOIR LE DETAIL
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    risque: {
      type: Object,
      required: true,
    },
  },
  methods: {
    scrollToSingleRisque(id) {
      let scroll_el = document.querySelector(`#${id}`)
      if (scroll_el) {
        setTimeout(function () {
          scroll_el.scrollIntoView()
        }, 500)
        if (
          !document.querySelector(
            `#accordion-item-${id} .accordion__trigger.accordion__trigger_active`,
          )
        )
          document.querySelector(`#${id}`).click()
      }
    },
  },
}
</script>
