<template>
  <div class="flex sm:flex-col justify-between sm:space-y-4 my-6">
    <ValidateModal
      contentClasses="w-[700px]"
      v-if="showConfirmationModal"
      @isCancel="updateAndQuit"
      @isValide="updateAndStay"
    >
      <slot slot="message_body">
        souhaitez-vous quitter ou poursuivre l'édition?
      </slot>
      <slot slot="button_valide">mettre à jour et rester</slot>
      <slot slot="button_cancel">mettre à jour et quitter</slot>
    </ValidateModal>
    <button
      :disabled="loading"
      v-if="$can('edite,dossier_promoteur')"
      class="flex justify-between items-center rounded-full text-center w-50 mr-4 sm: mr-0 sm:w-full border-2 bg-promy-green-300 border-promy-green-300 px-6 py-3 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors disabled:bg-promy-gray-325 disabled:border-promy-gray-325 disabled:hover:bg-promy-gray-300 disabled:hover:border-promy-gray-300"
      @click="confirmation"
    >
      <div>
        <template v-if="!$route.params.id">sauvegarder</template>
        <template v-else>mettre à jour</template>
      </div>
      <template v-if="loading">
        <div class="loader"></div>
      </template>
    </button>

    <button
      v-if="$can('publish,dossier_promoteur')"
      type="submit"
      @click="showModal = !showModal"
      class="flex items-center justify-between rounded-full w-50 px-6 sm: w-full border-2 bg-promy-green-300 border-promy-green-300 py-3 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
      :class="{
        'bg-promy-gray-325 border-promy-gray-325 hover: bg-promy-gray-300 hover:border-promy-gray-300':
          progress_dossier < 100,
      }"
    >
      <span>Publier</span>
      <span>{{ progress_dossier }}%</span>
    </button>

    <button
      type="button"
      @click="previewPdf"
      class="flex justify-between items-center rounded-full text-center ml-4 sm: ml-0 sm:w-full border-2 bg-promy-green-300 border-promy-green-300 px-6 py-3 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors"
      :class="{
        'bg-promy-gray-325 border-promy-gray-325 hover: bg-promy-gray-300 hover:border-promy-gray-300':
          !$route.params.id,
      }"
    >
      <span>previsualisation du PDF</span>
      <template v-if="previewPdfLoading">
        <div class="ml-2 loader"></div>
      </template>
    </button>

    <Modal v-if="showModal">
      <slot slot="header">
        <div class="flex justify-between w-full">
          <h1
            v-if="progress_dossier < 100"
            class="text-promy-gray-800 font-black text-xl font-main"
          >
            Action interrompu
          </h1>
          <h1 v-else class="text-promy-gray-800 font-black text-xl font-main">
            Date de commercialisation
          </h1>
          <p class="text-promy-green-300 font-black text-lg font-main">
            Dossier {{ progress_dossier }}%
          </p>
        </div>
      </slot>
      <slot slot="body">
        <p
          v-if="progress_dossier < 100"
          class="text-promy-gray-700 text-sm font-arial py-4"
        >
          Vous ne pouvez pas publier ce dossier, vous devez completer le dossier
          pour pouvoir le publier sur la platform
        </p>
        <div>
          <p class="text-promy-gray-700 text-sm font-arial py-4">
            Veuillez indiquer la date de début et de fin de commercialisation
          </p>
          <div class="w-full flex justify-between">
            <div class="flex flex-col">
              <div class="my-2">
                <label
                  class="capitalize text-sm text-gray-700 font-bold font-arial"
                >
                  Date de début de commercialisation
                </label>

                <datepicker
                  class="date-input py-1"
                  v-model="informations.date_debut"
                  @input="dateDebutChange"
                  valueType="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                ></datepicker>
              </div>
              <div v-if="dateDebutTimeVisible">
                <label
                  class="capitalize text-sm text-gray-700 font-bold font-arial"
                >
                  Heure de début de commercialisation
                </label>

                <datepicker
                  class="date-input py-1"
                  v-model="dateDebutTime"
                  @input="timeDebutChange"
                  format="HH:mm"
                  valueType="HH:mm"
                  placeholder="HH:mm"
                  type="time"
                ></datepicker>
              </div>
            </div>
            <div class="my-2">
              <label
                class="capitalize text-sm text-gray-700 font-bold font-arial"
              >
                Date de fin de commercialisation
              </label>
              <datepicker
                class="date-input py-1"
                v-model="informations.date_fin"
                valueType="DD-MM-YYYY"
                format="DD-MM-YYYY"
              ></datepicker>
            </div>
          </div>
          <div class="w-full">
            <div class="relative mt-2">
              <select
                v-model="informations.is_vente_privee"
                class="rounded-full px-4 py-2 text-gray-600 border-solid border w-full mt-1 text-sm bg-promy-gray-background-300 appearance-none"
              >
                <option :value="true">Vente Privées</option>
                <option :value="false">Market Place</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <div class="bg-promy-gray-125 w-full p-3 mb-3">
              <Checkbox id="sendMails" :val="sendMails" v-model="sendMails" />
              <span
                @click="sendMails = !sendMails"
                class="ml-8 text-base text-gray-650 font-bold font-arial cursor-pointer"
              >
                Ne pas envoyer de mail aux promoteurs
              </span>
            </div>
            <pro-textarea
              v-if="sendMails"
              label="description"
              row="4"
              v-model="description"
            ></pro-textarea>
            <pro-textarea
              v-if="!sendMails"
              label="Description à afficher dans l'email"
              row="4"
              v-model="description_send_mail"
            ></pro-textarea>
          </div>
        </div>
      </slot>
      <slot slot="footer">
        <div class="w-full">
          <button
            :disabled="loading"
            class="btn-green uppercase px-5 py-2 mr-4 text-base font-extrabold w-2/6 sm:w-2/5 disabled:bg-promy-gray-325 disabled:border-promy-gray-325 disabled:hover:bg-promy-gray-300 disabled:hover:border-promy-gray-300"
            @click="publish()"
          >
            <div class="flex justify-between">
              <span> Publier </span>
              <template v-if="loading">
                <div class="loader"></div>
              </template>
            </div>
          </button>
          <button
            :class="{
              'btn-green': progress_dossier < 100,
              'btn-white': progress_dossier == 100,
            }"
            class="uppercase px-5 py-2 text-base font-extrabold w-2/6 sm:w-2/5"
            @click="showModal = !showModal"
          >
            <p v-if="progress_dossier < 100">Continuer</p>
            <p v-if="progress_dossier == 100">Annuler</p>
          </button>
        </div>
      </slot>
    </Modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      previewPdfLoading: false,
      showModal: false,
      dateDebutTime: '13:00',
      firstTime: true,
      showConfirmationModal: false,
    }
  },
  methods: {
    dateDebutChange(date) {
      if (this.dateDebutTimeVisible) {
        this.$store.commit(
          'dossierpromoteur/setDateDebut',
          this.formatDateAndTime(date, this.dateDebutTime),
        )
      }
    },
    timeDebutChange(time) {
      this.$store.commit(
        'dossierpromoteur/setDateDebut',
        this.formatDateAndTime(this.informations.date_debut, time),
      )
    },
    formatDateAndTime(date, time) {
      const dateDebut = moment(date, 'DD-MM-YYYY').toDate()
      const hoursAndMins = time.split(':').map((n) => parseInt(n))
      dateDebut.setHours(hoursAndMins[0], hoursAndMins[1])
      return moment(dateDebut).format('DD-MM-YYYY HH:mm')
    },
    process(date) {
      var parts = date.split('-')
      return new Date(parts[2], parts[1] - 1, parts[0])
    },
    publish() {
      if (this.progress_dossier < 100) {
        this.$toaster.warning(
          "Vous ne pouvez pas encore publier ce dossier. Vous devez d'abord le compléter avant de le publier sur la plateforme.",
        )
      } else {
        if (
          !this.informations.date_debut ||
          !this.informations.date_fin ||
          this.process(this.informations.date_debut) >=
            this.process(this.informations.date_fin)
        ) {
          this.$toaster.warning(
            'Faut mettre les dates, La date de fin doit etre supérieur de la date de début!',
          )
          return
        }
        if (this.sendMails && !this.description) {
          this.$toaster.warning('La description est obligatoire')
          return
        }
        if (!this.sendMails && !this.description_send_mail) {
          this.$toaster.warning(
            "La Description à afficher dans l'email est obligatoire",
          )
          return
        }
        this.publishDossierPromoteur()
      }
    },
    async publishDossierPromoteur() {
      this.$store.commit('dossierpromoteur/setIsSavingOrUpdatingDP', true)
      this.$store.commit('dossierpromoteur/SET_PUBLISHING', true)
      this.loading = true
      this.$emit('saveInfos')
      await this.$store
        .dispatch(
          'dossierpromoteur/publishDossierPromoteur',
          this.$route.params.id ? this.$route.params.id : '',
        )
        .then(() => this.resetData(true))
        .catch((error) => this.dataError())
    },
    dossierPromoteur() {
      return !this.$route.params.id
        ? this.$store.dispatch('dossierpromoteur/saveDossierPromoteur')
        : this.$store.dispatch(
            'dossierpromoteur/updateDossierPromoteur',
            this.$route.params.id,
          )
    },
    async persistDossierPromoteur(publish = false, redirectAfterSave = true) {
      this.$store.commit('dossierpromoteur/setIsSavingOrUpdatingDP', true)
      this.$store.commit('dossierpromoteur/SET_PUBLISHING', publish)
      this.loading = true
      this.$emit('saveInfos')
      await this.dossierPromoteur()
        .then(() => this.resetData(redirectAfterSave))
        .catch((error) => this.dataError())
    },
    previewPdf() {
      if (!this.$route.params.id) {
        this.$toaster.warning(
          "Vous ne pouvez pas encore prévisualiser le pdf de ce dossier. Vous devez d'abord ajouter le dossier avant.",
        )
      } else if (this.previewPdfLoading) {
        this.$toaster.warning(
          'Votre demande de prévisualisation est en cours de traitement.',
        )
      } else {
        this.previewDossierPromoteurPdf()
      }
    },
    async previewDossierPromoteurPdf() {
      this.$store.commit('dossierpromoteur/setIsSavingOrUpdatingDP', true)
      this.previewPdfLoading = true
      await this.$store
        .dispatch(
          'dossierpromoteur/previewDossierPromoteurPdf',
          this.$route.params.id ? this.$route.params.id : '',
        )
        .then((pdfString) => {
          this.previewPdfLoading = false
          var byteCharacters = atob(pdfString)
          var byteNumbers = new Array(byteCharacters.length)
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          var byteArray = new Uint8Array(byteNumbers)
          var file = new Blob([byteArray], { type: 'application/pdf;base64' })
          var fileURL = URL.createObjectURL(file)
          window.open(fileURL)
        })
        .catch((error) => this.dataError())
    },
    dataError() {
      this.loading = false
      this.$toaster.warning(
        'Un probleme est survenu lors la sauvgarde du dossier promoteur',
      )
    },
    resetData(redirectAfterSave) {
      this.loading = false
      this.$toaster.success('Le dossier a bien été sauvegardé')
      this.$store.commit('parcelle/resetParcelle')
      if (redirectAfterSave) {
        this.$router.push('/dossiers-promoteurs/list')
      }
    },
    confirmation() {
      if (this.$route.params.id) {
        this.showConfirmationModal = true
      } else {
        this.persistDossierPromoteur()
      }
    },
    updateAndStay() {
      this.persistDossierPromoteur(false, false)
      this.showConfirmationModal = false
    },
    updateAndQuit() {
      this.persistDossierPromoteur()
    },
  },
  computed: {
    ...mapGetters({
      map: 'defaultmap/map',
      informations: 'dossierpromoteur/getinfos',
      progress_dossier: 'dossierpromoteur/getProgressDossir',
    }),
    dateDebutTimeVisible: {
      get: function () {
        return moment(this.informations.date_debut, 'DD-MM-YYYY').isAfter(
          new Date(),
        )
      },
    },
    sendMails: {
      get: function () {
        return this.$store.getters['dossierpromoteur/getShouldntSendMails']
      },
      set: function (newValue) {
        this.$store.commit('dossierpromoteur/SET_SHOULDNT_SEND_MAILS', newValue)
      },
    },
    description: {
      get: function () {
        return this.$store.getters[
          'dossierpromoteur/getShouldntSendMailsDescription'
        ]
      },
      set: function (newValue) {
        this.$store.commit(
          'dossierpromoteur/SET_SHOULDNT_SEND_MAILS_DESCRIPTION',
          newValue,
        )
      },
    },
    description_send_mail: {
      get: function () {
        return this.$store.getters[
          'dossierpromoteur/getShouldSendMailDescription'
        ]
      },
      set: function (newValue) {
        this.$store.commit(
          'dossierpromoteur/SET_SHOULD_SEND_MAIL_DESCRIPTION',
          newValue,
        )
      },
    },
  },
  watch: {
    'informations.date_debut': {
      handler: function () {
        if (this.firstTime) {
          this.firstTime = false
          this.dateDebutTime = moment(
            this.informations.date_debut,
            'DD-MM-YYYY HH:mm',
          ).format('HH:mm')
        }
      },
    },
  },
}
</script>
<style lang="scss">
.loader {
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
