<template>
  <div class="flex flex-col w-full">
    <template v-if="is_router_link">
      <div class="flex flex-col">
        <div class="container-tabs" :class="classTabs">
          <div
            v-for="(tab, index) in mutatedTabs"
            :key="index"
            v-show="index < numbreTabs"
            class="flex flex-col justify-between"
          >
            <div
              @click="changeTab(tab.component, index)"
              class="label-tab"
              :class="[
                $route.name !== tab.component ? 'text-promy-gray-500' : '',
                classHeaders,
              ]"
            >
              {{ tab.label }}
            </div>
            <hr
              class="hr-active"
              :class="{ 'opacity-0': $route.name !== tab.component }"
            />
          </div>
          <PTabsMobile
            :numbreTabs="numbreTabs"
            :tabs="mutatedTabs"
            :is_router_link="is_router_link"
            :current_component="current_component"
            @changeTab="changeTab"
          />
        </div>
      </div>
      <hr class="hr-tabs" v-if="with_hr_tabs" />
    </template>
    <template v-else>
      <div class="flex flex-col" v-if="!is_buttons">
        <div class="container-tabs" :class="classTabs">
          <div
            v-for="(tab, index) in mutatedTabs"
            :key="index"
            v-show="index < numbreTabs"
            class="flex flex-col justify-between"
          >
            <div class="flex">
              <div
                :id="tab.component + '_' + index"
                class="label-tab"
                @click="changeTab(tab.component, index)"
                :class="[
                  current_component !== tab.component
                    ? 'text-promy-gray-500'
                    : '',
                  classHeaders,
                ]"
              >
                {{ tab.label }}
              </div>
              <div
                v-if="canPastilleBeShown(tab.component)"
                class="ml-px flex h-2 w-2 relative"
              >
                <span class="outer-pastille"></span>
                <span class="inner-pastille"></span>
              </div>
            </div>
            <hr
              class="hr-active"
              :class="{ 'opacity-0': current_component !== tab.component }"
            />
          </div>
          <PTabsMobile
            :numbreTabs="numbreTabs"
            :tabs="tabs"
            :is_router_link="is_router_link"
            :current_component="current_component"
            @changeTab="changeTab"
          />
        </div>
        <hr class="hr-tabs" v-if="with_hr_tabs" />
      </div>

      <div class="flex font-main space-x-3" v-else>
        <div v-for="(tab, index) in tabs" :key="index">
          <button
            @click="changeTab(tab.component, index)"
            class="w-43 h-10 sm:w-36 text-base"
            :class="[
              current_component !== tab.component
                ? 'btn-white    !border-promy-gray-250'
                : 'btn-green',
            ]"
          >
            {{ tab.label }}
          </button>
        </div>
      </div>

      <div :class="classes_slot">
        <slot v-bind:current_component="current_component"></slot>
      </div>
    </template>
  </div>
</template>

<script>
import PTabsMobile from './PTabsMobile.vue'

export default {
  components: {
    PTabsMobile,
  },
  props: {
    tabs: {
      type: Array,
      require: true,
    },
    tabs_with_pastille: {
      type: Array,
      default: () => [],
    },
    init_component: {
      type: String,
    },
    classes_slot: {
      type: String,
      default: 'mt-8',
    },
    classHeaders: {
      type: String,
      default: 'text-base',
    },
    classTabs: {
      type: String,
      default: 'space-x-16',
    },
    is_buttons: {
      type: Boolean,
      default: false,
    },
    is_router_link: {
      type: Boolean,
      default: false,
    },
    with_hr_tabs: {
      type: Boolean,
      default: true,
    },
    numberTabsMobile: {
      type: Number,
      default: 3,
    },
    is_change_tab_valid: {
      type: Boolean,
      default: true,
    },
    error_validation_message: {
      type: String,
      default: 'Veuillez remplir les champs obligatoires.',
    },
    type_tabs: {
      type: String,
      default: '',
    },
    with_pastille: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current_component: this.init_component,
    }
  },
  computed: {
    mutatedTabs() {
      return this.tabs.filter(function (tab) {
        if (
          !tab.hasOwnProperty('hasAccess') ||
          (tab.hasOwnProperty('hasAccess') && tab.hasAccess)
        ) {
          return tab
        }
      })
    },
    numbreTabs() {
      switch (this.$mq) {
        case 'sm':
          return this.numberTabsMobile
        case 'md':
          return 4
        case 'lg':
          return 5
        default: {
          return this.tabs.length
        }
      }
    },
    currentTabIndex() {
      return this.tabs.findIndex(
        (item) => item.component === this.current_component,
      )
    },
    hasPastille() {
      return this.$store.state.dossier.consulted
    },
  },
  methods: {
    canPastilleBeShown(tab_slug) {
      return (
        this.with_pastille &&
        this.tabs_with_pastille.includes(tab_slug) &&
        this.hasPastille
      )
    },
    changeTab(tab, index) {
      if (this.type_tabs === 'courrier') {
        this.tabs[this.currentTabIndex].is_validated = this.is_change_tab_valid
        if (
          (this.currentTabIndex < index &&
            this.tabs[this.currentTabIndex].is_validated &&
            this.tabs[index - 1].is_validated) ||
          this.currentTabIndex >= index
        ) {
          this.$emit('changeTab', tab)
          this.current_component = tab
          if (this.is_router_link) {
            this.$router.push({
              name: tab,
            })
          }
        } else {
          this.$toaster.warning(this.error_validation_message)
        }
      } else {
        this.$emit('changeTab', tab)
        this.current_component = tab
        if (this.is_router_link) {
          this.$router.push({
            name: tab,
          })
        }
      }
    },
  },
}
</script>
<style lng="scss" scoped>
.hr-active {
  @apply bg-promy-green-150 h-1 border-transparent rounded-full;
}
.container-tabs {
  @apply flex  relative font-main  sm:space-x-6;
}
.hr-tabs {
  @apply text-promy-gray-800;
  margin-top: -3px;
}
.label-tab {
  @apply cursor-pointer  font-extrabold sm:text-sm uppercase mb-[10px] sm-ws:mb-2 flex-1;
}
</style>
