<template>
  <div>
    <p class="font-arial mt-6">Aperçu</p>
    <div class="my-5 text-xs">
      <div
        class="flex bg-promy-gray-100 border-b border-promy-gray-300 font-extrabold rounded-t-lg"
      >
        <div class="w-4/10 py-2 px-3">Type</div>
        <div class="w-2/10 py-2 px-3">Prix /M2 H.T.</div>
        <div class="w-2/10 py-2 px-3">SHAB M2</div>
        <div class="w-2/10 py-2 px-3">Total H.T.</div>
      </div>
      <div
        class="flex"
        v-for="(item, index) in data"
        :key="index + 'preview_t_cout_de_contruction'"
      >
        <div class="w-4/10 py-2 px-3 bg-promy-gray-100">{{ item.type }}</div>
        <div class="w-2/10 py-2 px-3">{{ item.prix | formatEUR }} €</div>
        <div class="w-2/10 py-2 px-3">{{ item.sdp }}</div>
        <div class="w-2/10 py-2 px-3">
          {{ (item.prix * item.sdp) | formatEUR }} €
        </div>
      </div>
      <div class="flex rounded-b-lg">
        <div class="w-4/10 py-2 px-3 bg-promy-gray-100 rounded-bl-lg"></div>
        <div class="w-2/10 py-2 px-3 bg-promy-gray-300">TOTAL</div>
        <div class="w-2/10 py-2 px-3 bg-promy-gray-300"></div>
        <div class="w-2/10 py-2 px-3 bg-promy-gray-300 rounded-br-lg">
          {{ calcTotal(data) | formatEUR }} € H.T.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  methods: {
    calcTotal(data) {
      let total = 0
      data.map((item) => {
        total += item.prix * item.sdp
      })
      return total
    },
  },
}
</script>

<style></style>
