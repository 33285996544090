<template>
  <div>
    <div class="flex items-end">
      <p class="mr-2">
        <template v-if="seenBy.offre_count > 0">
          <span
            @click="fetchData"
            class="cursor-pointer text-promy-green-200 border-b-2 border-promy-green-200"
          >
            {{ getValue(seenBy.promoteur, 'full_name') }}</span
          >
        </template>
        <template v-else>
          <span class="lg:text-promy-gray-500 lg:border-white w-fit sm:text-xs">
            {{ getValue(seenBy.promoteur, 'full_name') }}</span
          >
        </template>
        <span class="text-promy-gray-500">
          -
          <span class="capitalize">{{
            getValue(seenBy.promoteur, 'raison_social')
          }}</span>
        </span>
        <span> ({{ seenBy.seen }}) - {{ seenBy.last_consultation_at }} </span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    seenBy: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showPopup: false,
      loading: false,
      offres: [],
    }
  },
  methods: {
    getValue(object, key) {
      if (
        typeof object === 'object' &&
        object !== null &&
        object.hasOwnProperty(key)
      ) {
        return object[key]
      }

      return '--'
    },
    fetchData() {
      this.showPopup = true
      this.loading = true
      this.$http
        .get(
          `admin/offres/promoteurs/${this.seenBy.promoteur.id}/dossier_promoteurs/${this.seenBy.dossier_promoteur_id}`,
        )
        .then((res) => {
          this.$store.commit('offre/SET_OFFRES_PROMOTEUR_SEEN_BY', {
            offres: res.data.data,
            showPopup: this.showPopup,
            promoteurName: this.seenBy.promoteur.full_name,
          })
        })
    },
  },
}
</script>
