<template>
  <div>
    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :sortingBy="sorting_by"
          :hasSort="sort.sortKey ? true : false"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <pro-table-row
        v-for="(item, index) in list_versions"
        :key="index"
        class="relative"
      >
        <pro-table-cell label="Version" class="w-1/4 lg:w-full">
          {{ item.version }}
        </pro-table-cell>
        <pro-table-cell label="Statut" class="w-1/4 lg:w-full">
          {{ item.status | status }}
        </pro-table-cell>
        <pro-table-cell label="Date de création" class="w-1/4 lg:w-full">
          {{ item.created_at | filterDate }}
        </pro-table-cell>

        <pro-table-cell
          v-if="item.status != 'Publier'"
          :actions_btn="true"
          class="w-1/4 lg:w-full"
        >
          <div class="w-full flex justify-end items-center space-x-3">
            <div
              @click="update(item)"
              class="btn-action btn-green bg-promy-bleu-100"
            >
              Modifier
            </div>
            <div
              @click="rememberCGU(item)"
              class="btn-action btn-green bg-promy-red"
            >
              Supprimer
            </div>
            <div
              @click="publierCGU(item)"
              class="btn-action btn-green bg-promy-green-100"
            >
              Publier
            </div>
          </div>
        </pro-table-cell>
      </pro-table-row>
    </pro-card>
    <Modal v-if="show_modal">
      <slot slot="body">
        <p class="text-promy-gray-700 text-sm font-arial py-4">
          Êtes-vous sûr de vouloir supprimer les CGU ?
        </p>
      </slot>
      <slot slot="footer">
        <button
          class="btn-green px-6 py-2 sm:w-full md:mt-8 mr-4"
          @click="rememberCGU()"
        >
          Non
        </button>
        <button
          class="font-extrabold text-white uppercase border-2 border-solid rounded-full font-main hover:bg-promy-red hover:border-promy-red border-promy-red-100 bg-promy-red-100 px-6 py-2 sm:w-full md:mt-8"
          @click="remove()"
        >
          Oui
        </button>
      </slot>
    </Modal>
  </div>
</template>
<script>
export default {
  props: {
    list_versions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sort_list: [
        {
          title: 'Version',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Statut',
          sortKey: '',
          classes: 'w-1/4',
        },
        {
          title: 'Date de création',
          sortKey: '',
          classes: 'w-1/4',
        },
      ],
      sorting_by: '',
      show_modal: false,
      remember_cgu: null,
    }
  },
  filters: {
    status(status) {
      return status === 'Publier' ? 'Publié' : 'Brouillon'
    },
  },
  methods: {
    filterListVersions(platform) {
      return this.list_versions.filter(
        (item) => item.platform === platform.replace('-', '_'),
      )
    },
    update(version) {
      this.$store.commit('cgu/SET_CURRENT_CGU', version)
      this.$router.push(`/parametrage/CGU/modifier/${version.id}`)
    },
    async publierCGU(version) {
      version.status = 'Publier'
      try {
        await this.$store.dispatch('cgu/updateCGU', {
          form: version,
          id: version.id,
        })
        this.$toaster.success('Les CGU ont bien été publiées.')
        this.$store.dispatch('cgu/getAllCGU')
      } catch {
        this.$toaster.warning(
          "Un probleme est survenu lors la publication d'une CGU.",
        )
      }
    },
    async remove() {
      try {
        await this.$store.dispatch('cgu/removeCGU', this.remember_cgu.id)
        this.$toaster.success('Les CGU ont bien été supprimées.')
        await this.$store.dispatch('cgu/getAllCGU')
        this.show_modal = false
        this.remember_cgu = null
      } catch {
        this.$toaster.warning(
          "Un probleme est survenu lors la supprission d'une CGU.",
        )
      }
    },
    rememberCGU(item = null) {
      this.remember_cgu = item
      this.show_modal = item ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-action {
  @apply py-2 px-3 cursor-pointer text-white font-extrabold;
}
</style>
