<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <path
      id="x"
      :fill="color"
      class="cls-1"
      d="M6359.5,3521a12.5,12.5,0,1,0,12.5,12.5A12.517,12.517,0,0,0,6359.5,3521Zm0,22.78a10.28,10.28,0,1,1,10.28-10.28A10.29,10.29,0,0,1,6359.5,3543.78Zm5.36-15.64a1.1,1.1,0,0,0-1.57,0l-3.79,3.79-3.79-3.79a1.11,1.11,0,0,0-1.57,1.57l3.79,3.79-3.79,3.79a1.11,1.11,0,0,0,1.57,1.57l3.79-3.79,3.79,3.79a1.11,1.11,0,0,0,1.57-1.57l-3.79-3.79,3.79-3.79A1.1,1.1,0,0,0,6364.86,3528.14Z"
      transform="translate(-6347 -3521)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#321E6A',
    },
  },
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill-rule: evenodd;
}
</style>
