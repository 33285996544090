<template>
  <div class="flex flex-col w-full">
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="submit"
    >
      <slot slot="message_title"> Raison</slot>
      <slot slot="message_body">
        <div class="flex flex-col">
          <span>Veuillez indiquer la raison de non disponibilité du PLU: </span>
          <vue-select
            class="mb-8 w-[26rem]"
            :data="raisons"
            v-model="raison_selected"
            :withTruncate="false"
            @valueSelect="(value) => (raison_selected = value)"
          >
          </vue-select>
        </div>
      </slot>
      <slot slot="button_valide">Valider</slot>
      <slot slot="button_cancel">Quitter</slot>
    </ValidateModal>
    <div
      class="flex items-center justify-between my-2 border-b-2 lg:items-start xl:pb-5 lg:pb-4 xl:border-promy-gray-300 sm:flex-col"
    >
      <div class="flex lg:flex-col space-x-4 lg:space-x-0">
        <div
          class="flex items-center"
          v-for="(item, index) in status"
          :key="index + '-type'"
        >
          <span :class="`rounded-full w-3 h-3 bg-promy-${item.color} mr-1`">
          </span>
          <span class="text-sm text-promy-gray-600">
            {{ item.label }}
          </span>
        </div>
      </div>
    </div>
    <div class="xl:h-50-screen xl:overflow-y-auto liste-dossier">
      <template v-if="isFetching && current_page == 1">
        <div class="flex justify-center w-full mt-24">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="demandesPLU.length > 0">
          <pro-card>
            <pro-table-row :isHeader="true">
              <pro-table-header-cell
                v-for="(sort, index) in sort_list"
                :key="index"
                :class="sort.classes"
                :sortingBy="sorting_by"
                @setSortingBy="setSortingBy"
                @orderBy="orderBy"
                :hasSort="true"
                :title="sort.title"
                :sortKey="sort.sortKey"
              />
            </pro-table-row>
            <pro-table-row
              v-for="(item, index) in demandesPLU"
              :key="index"
              class="relative"
            >
              <pro-table-cell class="w-[6%]">
                <div
                  :class="`rounded-full w-3 h-3 ml-5 bg-promy-${
                    status[item.status].color
                  }`"
                ></div>
              </pro-table-cell>
              <pro-table-cell class="w-[10%]">
                {{ item.promoteur }}
              </pro-table-cell>
              <pro-table-cell class="w-[14%]">
                {{ item.commune }}
              </pro-table-cell>
              <pro-table-cell class="w-[26%]">
                {{ item.adresse }}
              </pro-table-cell>
              <pro-table-cell class="w-[12%]">
                {{ item.created_at }}
              </pro-table-cell>
              <pro-table-cell class="w-[12%]">
                {{ item.updated_at }}
              </pro-table-cell>

              <pro-table-cell class="w-[20%]">
                <div class="w-full flex justify-end items-center space-x-3">
                  <div
                    v-for="(statusItem, sIdx) in status"
                    :key="'status-' + sIdx"
                    class="flex items-center justify-center w-10 h-10 mr-2 text-white rounded-full cursor-pointer"
                    v-tooltip="{
                      content: statusItem.label,
                      classes: classes,
                    }"
                    :class="`bg-promy-${statusItem.color}`"
                    @click="changeDemandePLUStatus(item.id, sIdx, item.status)"
                  >
                    <i class="fas fa-check-square"></i>
                  </div>
                </div>
              </pro-table-cell>
            </pro-table-row>
          </pro-card>
          <template v-if="demandesPLU.length > 0">
            <isVisible :isFetching="isFetching" @isVisible="isVisible" />
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import DemandePLU from '@/mixins/demandePLU'

export default {
  mixins: [DemandePLU],
  data() {
    return {
      type: 'promoteur',
    }
  },
}
</script>
