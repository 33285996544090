<template>
  <div class="flex flex-col lg:fixed lg:w-full lg:z-30">
    <div class="flex h-30 sm:h-24 lg:bg-white sm-ws:h-17 lg-ws:h-25">
      <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex items-center">
        <div class="flex w-full justify-between items-center">
          <div class="lg:flex items-center hidden">
            <button @click.stop="toggleSidebar" class="lg:block hidden w-6">
              <img src="/images/burger-menu.svg" class="w-30" alt />
            </button>
            <div class="lg:flex flex items-center ml-5 sm:ml-2 justify-start">
              <img
                src="/images/logo_promy-purple.svg"
                class="w-22 sm:w-18"
                alt
              />
            </div>
          </div>

          <div class="flex items-stretch sm-ws:flex sm-ws:items-center">
            <h1
              class="capitalize text-4xl text-gray-800 tracking-wide font-extrabold font-main mr-2 lg:hidden sm-ws:pt-0"
            >
              espace administrateur
            </h1>
          </div>

          <div class="flex items-center">
            <span
              class="capitalize text-promy-green-300 font-medium mr-3 text-base sm:hidden"
              >{{ user.fullName }}</span
            >
            <div class="relative mr-4">
              <img
                @click="open = !open"
                :src="user.avatar"
                class="w-12 h-12 border-2 border-promy-green-300 rounded-full object-cover sm-ws:w-8 sm-ws:h-8 cursor-pointer"
                alt
              />
              <div
                v-on-clickaway="away"
                v-if="open"
                class="rounded overflow-hidden mt-2 absolute bg-white w-56 right-0 mx-auto z-10"
              >
                <button
                  @click="profile()"
                  class="w-full text-gray-700 text-sm py-2 px-3 flex justify-between hover:bg-promy-green-200 hover:text-gray-100 cursor-pointer transition-all duration-100"
                >
                  <span>Mon profil</span>
                </button>
                <div
                  @click="logout()"
                  class="text-gray-700 capitalize text-sm py-2 px-3 flex justify-between hover:bg-promy-green-200 hover:text-gray-100 cursor-pointer transition-all duration-100"
                >
                  <span>déconnexion</span>
                </div>
              </div>
            </div>

            <div
              class="w-12 h-12 rounded-full xl:bg-promy-green-200 flex justify-center items-center relative sm-ws:w-8 sm-ws:h-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.03"
                height="24"
                viewBox="0 0 20.03 24"
                class="fill-current lg:text-promy-green-300 xl:text-white sm-ws:w-4 sm-ws:h-4"
              >
                <path
                  id="bell"
                  d="M1134.93,579.246l-1.71-2.861a8.436,8.436,0,0,1-1.22-4.373v-2.506a7,7,0,0,0-14,0v2.506a8.522,8.522,0,0,1-1.21,4.373l-1.72,2.861a0.48,0.48,0,0,0-.01.5,0.509,0.509,0,0,0,.44.254h19.01a0.493,0.493,0,0,0,.43-0.254A0.48,0.48,0,0,0,1134.93,579.246ZM1116.38,579l1.26-2.1a9.462,9.462,0,0,0,1.36-4.887v-2.506a6,6,0,0,1,12,0v2.506a9.462,9.462,0,0,0,1.36,4.887l1.26,2.1h-17.24ZM1125,559.01a2,2,0,0,0-2,2v2a0.5,0.5,0,0,0,1,0v-2a1,1,0,0,1,2,0v2a0.5,0.5,0,0,0,.5.5,0.5,0.5,0,0,0,.5-0.5v-2A2,2,0,0,0,1125,559.01Zm2.17,20.24a0.509,0.509,0,0,0-.69-0.179,0.5,0.5,0,0,0-.18.685,1.5,1.5,0,1,1-2.6,0,0.5,0.5,0,1,0-.86-0.506A2.5,2.5,0,1,0,1127.17,579.25Z"
                  transform="translate(-1115 -559)"
                />
              </svg>

              <span
                style="font-size: 10px"
                class="font-bold flex justify-center items-center w-4 h-4 bg-red-600 rounded-full absolute text-white top-0 right-0 mr-2 mt-2 sm-ws:mr-1 sm-ws:mt-1 sm-ws:w-3 sm-ws:h-3"
                >3</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'

export default {
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    ...mapGetters({
      collapse: 'sidebar/collapse',
      user: 'auth/user',
    }),
  },
  methods: {
    profile() {
      this.$router.push('/profile')
      this.away()
    },
    logout() {
      this.$store.dispatch('auth/logoutUser').then(() => {
        this.$router.push('/login')
        this.$toaster.success('Vous vous êtes déconnecté avec succès')
      })
    },
    away() {
      this.open = false
    },
    toggleSidebar() {
      this.$store.commit('sidebar/COLLAPSE', false)
    },
  },
}
</script>
