<template>
  <div>
    <slot name="pro-table-th"></slot>
    <dashboard-card v-for="(item, index) in value" :key="index">
      <slot name="pro-table-row" :item="item" />
    </dashboard-card>
    <div
      class="col-span-3 lg:col-span-2 sm:col-span-1"
      v-if="value.length"
      v-observe-visibility="{
        callback: isVisible,
        throttle: 300,
        throttleOptions: {
          leading: 'visible',
        },
      }"
    >
      <div class="mt-6" v-if="last_page != current_page">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [Array, Object],
    url: {
      type: String,
      default: 'admin/dashboard/get-list',
    },
    type: String,
    last_page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      current_page: 1,
      isFetching: false,
    }
  },
  methods: {
    isVisible() {
      if (this.last_page === this.current_page) {
        return
      }
      this.current_page++

      this.getList()
    },
    getList() {
      this.isFetching = true
      this.$store
        .dispatch('dashboard/getList', {
          url: this.url + '?page=' + this.current_page,
          type: this.type,
        })
        .then(() => (this.isFetching = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.lds-ring {
  display: flex;
  justify-items: center;
  margin: auto;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 8px;
  border: 4px solid #00c9bc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #00c9bc transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.up-arrow {
  transform: rotate(180deg);
}
.down-arrow {
  transform: rotate(0deg);
}
</style>
