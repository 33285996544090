<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class="w-10 h-auto"
    :style="`fill :${color}`"
  >
    <g>
      <g>
        <path
          d="M501.957,313.939H391.57l24.842-107.701c4.018,4.632,9.269,7.822,15.299,9.224c2.18,0.502,4.378,0.749,6.557,0.749
			c6.527,0,12.888-2.216,18.111-6.424c6.01-4.849,9.773-11.751,10.595-19.433c0.822-7.685-1.395-15.227-6.245-21.237L332.953,10.772
			C324.62,0.44,310.376-2.893,298.311,2.671L114.413,87.642c-7.011,3.242-12.343,9.02-15.012,16.269
			c-2.671,7.254-2.356,15.111,0.887,22.123c6.56,14.187,23.242,20.546,37.54,14.493l-4.941,21.422
			c-0.842,3.653,0.421,7.471,3.277,9.902l21.505,18.3l-26.938,6.226c-3.74,0.864-6.658,3.786-7.521,7.524L111.699,253.8
			l-101.624-0.316c-0.011,0-0.021,0-0.031,0c-2.656,0-5.206,1.053-7.088,2.929c-1.889,1.883-2.952,4.442-2.952,7.11v238.438
			c0,5.544,4.495,10.039,10.039,10.039h491.914c5.545,0,10.039-4.495,10.039-10.039V323.978
			C511.996,318.435,507.501,313.939,501.957,313.939z M147.613,113.919l-17.35,8.012c-4.433,2.048-9.704,0.105-11.751-4.323
			c-0.991-2.142-1.086-4.542-0.27-6.759c0.817-2.22,2.45-3.99,4.595-4.98l183.887-84.968c3.691-1.703,8.051-0.685,10.602,2.479
			l127.777,158.346c1.481,1.835,2.157,4.14,1.906,6.49c-0.251,2.351-1.401,4.462-3.234,5.94c-2.135,1.72-4.893,2.352-7.542,1.744
			c-1.359-0.316-3.313-1.105-4.9-3.074L312.803,45.947c-1.955-2.421-4.852-3.736-7.816-3.736c-1.419,0-2.854,0.302-4.206,0.927
			l-152.784,70.595C147.866,113.793,147.739,113.855,147.613,113.919z M141.364,214.529l40.269-9.308
			c3.696-0.855,6.595-3.719,7.492-7.406c0.898-3.685-0.358-7.562-3.248-10.021l-32.055-27.276l7.063-30.621l141.209-65.247
			l98.28,121.786l-17.995,78.017l-12.046-4.339c-5.217-1.879-10.968,0.827-12.847,6.043c-1.879,5.216,0.827,10.969,6.044,12.847
			l14.306,5.152l-6.342,27.495l-65.09-15.015l27.284-118.244c0.598-2.594,0.143-5.321-1.27-7.578
			c-1.411-2.258-3.661-3.862-6.255-4.46l-90.04-20.771c-5.406-1.245-10.793,2.122-12.039,7.525l-27.279,118.246l-65.084-15.013
			L141.364,214.529z M279.24,338.738l5.621,39.43l-26.637,19.287c-2.524,1.827-4.056,4.722-4.147,7.837l-0.906,30.862l-25.77-33.257
			l19.932-27.3c2.138-2.929,2.531-6.785,1.025-10.085l-23.322-51.13c-0.008-0.017-0.016-0.035-0.024-0.052l-8.086-17.729
			l108.925,25.126l-38.146,5.664C282.243,328.202,278.46,333.271,279.24,338.738z M216.368,275.865l25.021-108.463l70.475,16.257
			l-25.027,108.462L216.368,275.865z M491.919,491.922h-0.001H20.082V273.594l98.44,0.306l73.74,17.01l10.034,21.997l-24.972,8.938
			c-5.22,1.868-7.937,7.615-6.069,12.835c1.468,4.102,5.332,6.659,9.453,6.659c1.122,0,2.265-0.19,3.383-0.59l26.567-9.509
			l16.996,37.261l-20.922,28.656c-2.637,3.612-2.567,8.533,0.173,12.068l47.545,61.359c1.938,2.5,4.889,3.891,7.937,3.891
			c1.037,0,2.085-0.162,3.108-0.495c4.03-1.313,6.8-5.014,6.925-9.251l1.582-53.91l22.926-16.6l23.017,9.88
			c1.291,0.554,2.634,0.816,3.955,0.816c3.892,0,7.596-2.279,9.23-6.083c2.187-5.094-0.17-10.998-5.265-13.185l-23.068-9.903
			l-4.271-29.963l79.234-11.767h112.157V491.922z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M124.184,449.088H38.181c-5.545,0-10.039,4.495-10.039,10.039c0,5.544,4.495,10.039,10.039,10.039h86.003
			c5.545,0,10.039-4.495,10.039-10.039C134.223,453.584,129.728,449.088,124.184,449.088z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M155.917,449.089c-12.919,0-12.94,20.078,0,20.078C168.837,469.167,168.858,449.089,155.917,449.089z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#00C9BC',
    },
  },
}
</script>
