<template>
  <div>
    <h3 class="font-bold text-xl font-main">Listes des demandes</h3>
    <div
      class="flex items-center justify-between my-2 border-b-2 lg:items-start xl:pb-5 lg:pb-4 xl:border-promy-gray-300 sm:flex-col"
    >
      <div class="flex lg:flex-col space-x-4 lg:space-x-0">
        <div
          class="flex items-center"
          v-for="item in status"
          :key="item + '-type'"
        >
          <span
            :class="`rounded-full w-3 h-3 bg-promy-${getStatusColor(
              item,
            )} mr-1`"
          >
          </span>
          <span class="text-sm text-promy-gray-600">
            {{ getStatusColorName(item) }}
          </span>
        </div>
      </div>
    </div>
    <div class="xl:h-50-screen xl:overflow-y-auto liste-dossier">
      <template v-if="isFetching && current_page == 1">
        <div class="flex justify-center w-full mt-24">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="demandeBienPiges.length > 0">
          <pro-card>
            <pro-table-row :isHeader="true">
              <pro-table-header-cell
                v-for="(sort, index) in sort_list"
                :key="index"
                :class="sort.classes"
                :sortingBy="sorting_by"
                :hasSort="sort.sortKey ? true : false"
                :title="sort.title"
                :sortKey="sort.sortKey"
              />
            </pro-table-row>
            <pro-table-row
              v-for="(item, index) in demandeBienPiges"
              :key="index"
              class="relative"
            >
              <pro-table-cell label="Status" class="w-1/6 lg:w-full">
                <div
                  :class="`rounded-full w-3 h-3 ml-4 bg-promy-${getStatusColor(
                    item.status,
                  )}`"
                ></div>
              </pro-table-cell>
              <pro-table-cell label="Promoteur" class="w-1/6 lg:w-full">
                {{ item.promoteur.full_name }}
              </pro-table-cell>
              <pro-table-cell label="Date de demande" class="w-1/6 lg:w-full">
                {{ item.created_at }}
              </pro-table-cell>
              <pro-table-cell label="Mise a jour" class="w-1/6 lg:w-full">
                {{ item.updated_at }}
              </pro-table-cell>
              <pro-table-cell label="Annonce" class="w-1/6 lg:w-full">
                <a
                  :href="item.pige_announce_url"
                  class="font-arial text-base font-bold text-promy-green-150"
                >
                  Annonce {{ item.announce_number }}
                </a>
              </pro-table-cell>

              <pro-table-cell :actions_btn="true" class="w-1/6 lg:w-full">
                <div class="w-full flex justify-end items-center space-x-3">
                  <div
                    v-if="$can('delete,pige_fonciere')"
                    class="btn-actions"
                    @click="displayModal(item)"
                  >
                    <i class="fas fa-trash"></i>
                  </div>
                  <div
                    v-if="
                      $can('edite,pige_fonciere') ||
                      $can('consultation,pige_fonciere')
                    "
                  >
                    <router-link
                      :to="{
                        name: 'pige_demande_item',
                        params: { id: item.id },
                      }"
                      class="btn-actions"
                    >
                      <i class="fas fa-edit"></i>
                    </router-link>
                  </div>
                </div>
              </pro-table-cell>
            </pro-table-row>
          </pro-card>
          <template v-if="demandeBienPiges.length > 0">
            <isVisible :isFetching="isFetching" @isVisible="isVisible" />
          </template>
        </template>
      </template>
    </div>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteDemandeBienPige()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer cet demande ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
export default {
  props: ['status', 'getStatusColor'],
  data() {
    return {
      sort_list: [
        {
          title: 'Status',
          sortKey: '',
          classes: 'w-1/6',
        },
        {
          title: 'PROMOTEUR',
          sortKey: '',
          classes: 'w-1/6',
        },
        {
          title: 'DATE DE DEMANDE',
          sortKey: '',
          classes: 'w-1/6',
        },
        {
          title: 'MISE A JOUR',
          sortKey: '',
          classes: 'w-1/6',
        },
        {
          title: 'ANNONCE',
          sortKey: '',
          classes: 'w-1/6',
        },
      ],
      sorting_by: '',
      isFetching: false,
      last_page: 1,
      current_page: 1,
      demandeBienPiges: [],
      current_item: null,
      showModal: false,
    }
  },
  created() {
    this.getListDemandeBienPiges()
  },
  methods: {
    getStatusColorName(status) {
      switch (status) {
        case '3':
          return 'Abandonné'
        case '1':
          return 'En cours de traitement'
        case '2':
          return 'Traité'
      }
    },
    isVisible() {
      if (this.last_page === this.current_page) {
        return
      }

      this.current_page++
      this.getListDemandeBienPiges()
    },
    async getListDemandeBienPiges() {
      this.isFetching = true
      const { data: data } = await this.$http.get('admin/demande-bien-pige', {
        params: {
          page: this.current_page,
        },
      })
      this.demandeBienPiges.push(...data.data)
      this.last_page = data.meta.last_page
      this.current_page = data.meta.current_page
      this.isFetching = false
    },
    async deleteDemandeBienPige() {
      await this.$http.delete(`admin/demande-bien-pige/${this.current_item.id}`)
      this.demandeBienPiges.splice(
        this.demandeBienPiges.indexOf(this.current_item),
        1,
      )
      this.current_item = null
      this.showModal = false
    },
    displayModal(item) {
      this.current_item = item
      this.showModal = true
    },
    setSortingBy(val) {
      this.sorting_by = val
    },
  },
}
</script>
