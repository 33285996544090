<template>
  <div>
    <div class="grid grid-cols-4 items-end md:grid-cols-1 gap-y-4 my-8">
      <div :key="index" v-for="(image, index) in form.projet3d.images">
        <InputImage
          :file="image"
          :selfUploader="true"
          @upload="updateImage($event, index)"
          @deleteImage="deleteImage(index)"
        />
      </div>
      <InputImage @upload="saveImage" />
    </div>
    <HighlightMessage
      :show="form.projet3d && form.projet3d.images.length === 0"
    />
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteImage(index) {
      this.form.projet3d.images.splice(index, 1)
    },
    updateImage(file, index) {
      this.form.projet3d.images.splice(index, 1, file)
    },
    saveImage(file) {
      this.form.projet3d.images.push(file)
    },
  },
}
</script>
