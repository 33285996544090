<template>
  <div class="section-team">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      parentUrl: '/gestion-des-utilisateurs',
      links: [
        {
          name: 'Liste Des promoteurs',
          path: 'gestion-des-utilisateurs/teams/edit',
          componentName: 'list-des-teams',
        },
        {
          name: 'Liste Des Utilisateurs',
          path: 'gestion-des-utilisateurs/promoteurs/edit',
          componentName: 'list-des-promoteurs',
        },
        {
          name: 'Liste Des Admin',
          path: 'gestion-des-utilisateurs/admins/edit',
          componentName: 'list-des-admins',
        },
        {
          name: 'Liste Des Agents Immobilier',
          path: 'gestion-des-utilisateurs/agent-immobilier/edit',
          componentName: 'list-des-agents-immobilier',
        },
        {
          name: 'liste des Teams agents immobilier',
          path: 'gestion-des-utilisateurs/team-agent-immobilier/list',
          componentName: 'list-des-teams-agents-immobilier',
        },
        {
          name: "suivi de l'activité",
          name: 'Liste Des Utilisateurs GUGP',
          path: 'gestion-des-utilisateurs/grand-public/list',
          componentName: 'list-des-grand-public',
        },
        {
          name: "Suivi de l'activité",
          path: 'users-log/edit',
          componentName: 'users-log-edit',
        },
        {
          name: 'occupation',
          path: 'occupation',
          componentName: 'occupation',
          gate: [
            'consultation,dossier_promoteur',
            'edite,dossier_promoteur',
            'delete,dossier_promoteur',
          ],
        },
      ],
    }
  },
  created() {
    this.$store.commit('subSidebar/LINKS', this.links)
    this.$store.commit('subSidebar/PARENT_URL', this.parentUrl)
  },
  mounted() {
    this.$store.commit('sidebar/RESIZE_WINDOW', true)
  },
  destroyed() {
    this.$store.commit('sidebar/RESIZE_WINDOW', false)
  },
}
</script>

<style lang="scss">
.section-team {
  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    display: none;
  }
  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label {
    position: relative;
    cursor: pointer;
    color: #273869;
  }
  [type='checkbox']:checked + label:before,
  [type='checkbox']:not(:checked) + label:before {
    margin-top: -12px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #00c9bc;
    background: white;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 0px;
  }
  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    content: '';
    background: #00c9bc;
    margin-top: -12px;

    height: 11px;
    width: 11px;
    position: absolute;
    top: 7px;
    left: 7px;
    border: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='checkbox']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
