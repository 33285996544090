<template>
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 2.01094H7.18945L5.12754 0.0386718C5.1013 0.0141113 5.0668 0.000308139 5.03086 0H0.5625C0.251367 0 0 0.251367 0 0.5625V10.9688C0 11.2799 0.251367 11.5312 0.5625 11.5312H13.5C13.8111 11.5312 14.0625 11.2799 14.0625 10.9688V2.57344C14.0625 2.2623 13.8111 2.01094 13.5 2.01094ZM9.14062 6.9082C9.14062 6.975 9.08086 7.03125 9.00879 7.03125H7.52344V8.52363C7.52344 8.59219 7.46719 8.64844 7.40039 8.64844H6.66211C6.59531 8.64844 6.53906 8.59219 6.53906 8.52363V7.03125H5.05371C4.98164 7.03125 4.92188 6.975 4.92188 6.9082V6.16992C4.92188 6.10313 4.98164 6.04688 5.05371 6.04688H6.53906V4.55449C6.53906 4.48594 6.59531 4.42969 6.66211 4.42969H7.40039C7.46719 4.42969 7.52344 4.48594 7.52344 4.55449V6.04688H9.00879C9.08086 6.04688 9.14062 6.10313 9.14062 6.16992V6.9082Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
}
</script>

<style></style>
