<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Offre dossier promoteur</page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>
        Offre dossier promoteur {{ offre.titre }}
      </template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('list-dossier-promoteur')"
          class="flex items-center justify-between px-4 py-2 text-xs font-semibold tracking-wide text-white uppercase transition-colors duration-200 border-2 rounded-full cursor-pointer hover:bg-white hover:text-promy-green-300 border-promy-green-300 w-42 bg-promy-green-300 font-main"
        >
          Retour à la liste
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <div v-if="offre" class="grid grid-cols-3 lg:grid-cols-1 gap-4">
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Statut :
        </div>
        <div class="text-promy-gray-750">
          {{ offre.status }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          ID de l'offre :
        </div>
        <div class="text-promy-gray-750">
          {{ offre.hash_id }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Nom du terrain :
        </div>
        <div class="text-promy-gray-750">
          {{ offre.titre }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Utilisateur :
        </div>
        <div class="text-promy-gray-750">{{ offre.full_name }}</div>
      </div>

      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Date :
        </div>
        <div class="text-promy-gray-750">{{ offre.created_at }}</div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Disponibilité :
        </div>
        <div class="text-promy-gray-750">
          {{ offre.disponibilite | closingTime }}
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Raison sociale :
        </div>
        <div class="text-promy-gray-750">{{ offre.raison_social }}</div>
      </div>
      <div class="flex flex-col space-y-1">
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          Conditions Suspensives souhaitées :
        </div>
        <ul class="text-promy-gray-750">
          <template v-if="offre.conditions.length > 0">
            <li
              class="list-disc list-inside"
              v-for="condition in offre.conditions"
              :key="condition.id"
            >
              {{ condition.nom }}
              {{
                condition.pivot.other_condition
                  ? `(${condition.pivot.other_condition})`
                  : ''
              }}
            </li>
          </template>
          <template v-else><li>Non</li></template>
        </ul>
      </div>
      <div
        class="flex flex-col space-y-1"
        v-for="question in offre.questions"
        :key="question.id"
      >
        <div class="font-main font-extrabold text-lg text-promy-gray-700">
          {{ question.question }}
        </div>
        <div class="text-promy-gray-750">
          <template v-if="Array.isArray(question.answer)">
            <ul class="text-promy-gray-750 flex">
              <li
                class="cursor-pointer"
                v-for="answer in question.answer"
                :key="answer.name"
                @click="downloadAnswerQuestionDocument(answer)"
                v-tooltip="{
                  content: answer.name,
                  classes: tooltipeClasses,
                }"
              >
                <svg
                  width="50"
                  height="46"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.6183 10.5013L15.1667 4.04964C15.0027 3.88549 14.7803 3.79317 14.5483 3.79297H9.33333C8.48243 3.79297 7.66638 4.13099 7.0647 4.73267C6.46302 5.33435 6.125 6.1504 6.125 7.0013V21.0013C6.125 21.8522 6.46302 22.6683 7.0647 23.2699C7.66638 23.8716 8.48243 24.2096 9.33333 24.2096H18.6667C19.5176 24.2096 20.3336 23.8716 20.9353 23.2699C21.537 22.6683 21.875 21.8522 21.875 21.0013V11.0846C21.8658 10.8648 21.7742 10.6566 21.6183 10.5013ZM15.4583 6.77964L18.8883 10.2096H15.4583V6.77964ZM18.6667 22.4596H9.33333C8.94656 22.4596 8.57563 22.306 8.30214 22.0325C8.02865 21.759 7.875 21.3881 7.875 21.0013V7.0013C7.875 6.61453 8.02865 6.2436 8.30214 5.9701C8.57563 5.69661 8.94656 5.54297 9.33333 5.54297H13.7083V11.0846C13.7114 11.3158 13.8045 11.5366 13.968 11.7C14.1314 11.8635 14.3522 11.9566 14.5833 11.9596H20.125V21.0013C20.125 21.3881 19.9714 21.759 19.6979 22.0325C19.4244 22.306 19.0534 22.4596 18.6667 22.4596Z"
                    fill="#00C9BC"
                  />
                  <path
                    d="M15.7401 17.3241C15.0227 16.8739 14.4828 16.1898 14.2117 15.3874C14.4629 14.6368 14.5387 13.8386 14.4334 13.0541C14.3997 12.8564 14.3034 12.6749 14.1585 12.5363C14.0136 12.3977 13.828 12.3094 13.6291 12.2846C13.4301 12.2597 13.2285 12.2995 13.0539 12.3982C12.8794 12.4969 12.7413 12.6491 12.6601 12.8324C12.5272 13.7755 12.6274 14.7369 12.9517 15.6324C12.5086 16.6675 12.0141 17.6799 11.4701 18.6657C10.6417 19.1324 9.51006 19.8324 9.33506 20.6374C9.19506 21.2907 10.4201 22.9707 12.5084 19.3307C13.437 18.986 14.3881 18.7054 15.3551 18.4907C16.0698 18.8994 16.8672 19.1426 17.6884 19.2024C17.8769 19.2073 18.0627 19.1563 18.2224 19.0559C18.3821 18.9555 18.5085 18.8102 18.5858 18.6381C18.6631 18.466 18.6878 18.275 18.6569 18.0889C18.6259 17.9029 18.5406 17.7301 18.4117 17.5924C17.9217 17.0907 16.4634 17.2307 15.7401 17.3241ZM10.1634 20.8241C10.4903 20.2646 10.9221 19.7734 11.4351 19.3774C10.6417 20.6374 10.1634 20.8591 10.1634 20.8357V20.8241ZM13.5701 12.8791C13.8734 12.8791 13.8501 14.2207 13.6401 14.5824C13.4819 14.0322 13.4579 13.4521 13.5701 12.8907V12.8791ZM12.5551 18.5724C12.9503 17.8512 13.2934 17.1026 13.5817 16.3324C13.8908 16.9075 14.3206 17.409 14.8417 17.8024C14.059 17.9985 13.294 18.2599 12.5551 18.5841V18.5724ZM18.0384 18.3624C18.0384 18.3624 17.8284 18.6191 16.4867 18.0357C17.9451 17.9424 18.1901 18.2807 18.0384 18.3741V18.3624Z"
                    fill="#00C9BC"
                  />
                </svg>
              </li>
            </ul>
          </template>
          <template v-else>{{ question.answer }}</template>
        </div>
      </div>
    </div>
    <div class="flex space-x-3">
      <div
        @click.prevent="getPdfOffre"
        class="flex mt-4 items-center justify-between px-4 py-2 text-xs font-semibold tracking-wide text-white uppercase transition-colors duration-200 border-2 rounded-full cursor-pointer hover:bg-white hover:text-promy-green-300 border-promy-green-300 w-42 bg-promy-green-300 font-main"
      >
        <span>Télécharger PDF</span>
        <template v-if="isFetching">
          <div class="loader"></div>
        </template>
      </div>
      <div
        @click="isDeleteModalOpened = true"
        class="flex mt-4 items-center justify-between text-center px-4 py-2 text-xs font-semibold tracking-wide text-white uppercase transition-colors duration-200 border-2 rounded-full cursor-pointer hover:bg-white hover:text-promy-green-300 border-promy-green-300 w-42 bg-promy-green-300 font-main"
      >
        <span class="m-auto">Supprimer</span>
      </div>
    </div>
    <ValidateModal
      v-if="isDeleteModalOpened"
      @isCancel="isDeleteModalOpened = false"
      @isValide="deleteOffer"
    >
      <slot slot="message_body">
        êtes-vous sûr de vouloir supprimer cette offre.
      </slot>
      <slot slot="button_valide">Supprimer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tooltipeClasses: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-xs',
        'bg-white',
        'mb-2',
      ],
      offre: {},
      isFetching: false,
      isDeleteModalOpened: false,
    }
  },
  created() {
    this.getOffre()
  },
  methods: {
    downloadAnswerQuestionDocument(answer) {
      this.$http
        .get(`admin/dossier-promoteurs/offre/${this.offre.id}/questions`, {
          params: {
            path: answer.value,
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `offre_${answer.name}.pdf`
          link.click()
          this.isFetching = false
        })
    },
    getOffre() {
      this.$http
        .get(
          `admin/dossier-promoteurs/${this.$route.params.dossier_promoteur_id}/offres/${this.$route.params.offre_id}`,
        )
        .then((res) => {
          this.offre = res.data.data
        })
    },
    getPdfOffre() {
      this.isFetching = true
      this.$http
        .get(`admin/dossier-promoteurs/pdf-offre/${this.offre.id}`, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `offre_${this.offre.hash_id}.pdf`
          link.click()
          this.isFetching = false
        })
        .catch((err) => {
          var enc = new TextDecoder('utf-8')
          var arr = new Uint8Array(err.response.data)
          let error = JSON.parse(enc.decode(arr)).error
          if (err.response.status === 429) {
            this.$toaster.warning(error)
          }
          if (err.response.status === 404) {
            this.$toaster.warning('Veuillez réessayer ultérieurement')
          }
        })
    },
    deleteOffer() {
      this.$http
        .delete(`admin/dossier-promoteurs/offre/${this.offre.id}`)
        .then((response) => {
          this.$toaster.success("L'offre a été supprimée avec succès")
          this.$router.push({
            name: 'dossier-promoteur-offres',
            params: { id: this.offre.terrain_id },
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
</style>
