<template>
  <LayoutParametrage title="Dossiers Promoteurs">
    <template #current_page> Informations </template>
    <template #title_table> Modifier Informations </template>

    <template #list_table>
      <div class="flex flex-col">
        <div class="flex flex-col w-full">
          <ValidationObserver
            v-slot="{ handleSubmit }"
            class="w-full"
            ref="formInformations"
          >
            <form
              @submit.prevent="handleSubmit(saveInfosAltimetrie)"
              class="w-full flex flex-col space-y-20"
            >
              <div
                class="flex w-full space-x-5 sm:space-x-0 sm:flex-col sm:space-y-20"
              >
                <div class="flex flex-col w-1/2 sm:w-full">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-4"
                  >
                    Description Altimétrie
                  </div>
                  <div class="flex w-full">
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="form.altimetrie_desc"
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  v-if="$can('edite,dossier_promoteur')"
                  type="submit"
                  class="px-16 w-max rounded-full text-center sm:w-full border-2 bg-promy-green-300 border-promy-green-300 py-3 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors mt-6"
                >
                  Sauvegarder
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </LayoutParametrage>
</template>
<script>
export default {
  data() {
    return {
      form: {
        id: null,
        altimetrie_desc: null,
      },
    }
  },
  created() {
    this.getInfosAltimetrie()
  },
  methods: {
    getInfosAltimetrie() {
      this.$http.get('admin/informations-altimetrie').then((response) => {
        Object.assign(this.form, response.data.default_informations)
      })
    },
    saveInfosAltimetrie() {
      this.$http
        .post('admin/informations-altimetrie', this.form)
        .then((response) => {
          Object.assign(this.form, response.data)

          this.$toaster.success(
            "Description de l'altimétrie sauvegardée avec succès",
          )
        })
    },
  },
}
</script>
