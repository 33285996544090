<template>
  <div class="flex flex-col lg:w-1/2 sm:w-full">
    <CadastreOfficiel :map="map"></CadastreOfficiel>
    <ul class="w-full">
      <li
        v-if="listDvf.length"
        class="flex justify-between items-start py-3 my-2 bg-white rounded-lg"
      >
        <div class="flex items-center">
          <div class="legend-circle bg-promy-orange-100 mr-3 ml-4"></div>
          <label
            for="dvf"
            class="cursor-pointer text-base text-promy-orange-100 flex items-center"
            style="min-height: 24px"
            >Ventes à Proximité</label
          >
        </div>
        <Checkbox id="dvf" :val="is_dvf" v-model="is_dvf" class="pr-10" />
      </li>
      <li
        v-if="permis_construire.length"
        class="flex justify-between items-start py-3 my-2 bg-white rounded-lg"
      >
        <div class="flex items-center">
          <div class="legend-circle bg-promy-green-200 mr-3 ml-4"></div>
          <label
            for="permis_construction"
            class="cursor-pointer text-base text-promy-green-200 flex items-center"
            style="min-height: 24px"
            >Permis de construire</label
          >
        </div>
        <Checkbox
          id="permis_construction"
          :val="is_permis_construction"
          v-model="is_permis_construction"
          class="pr-10"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Mapbox from '@/mixins/mapbox'
import etudeCommerciale from '@/mixins/dossierPromoteurs/etudeCommerciale'
import helpers from '@/mixins/helpers'

export default {
  data() {
    return {
      is_street: true,
    }
  },
  props: {
    permis_construire: {
      type: Array,
      required: true,
    },
    listDvf: {
      type: Array,
      required: true,
    },
  },
  mixins: [Mapbox, etudeCommerciale, helpers],
  computed: {
    ...mapGetters({
      map: 'defaultmap/map',
    }),
  },
}
</script>
<style lang="scss" scoped>
.legend-circle {
  @apply w-3 h-3;
  border-radius: 50%;
}
</style>
