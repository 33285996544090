<template>
  <div class="relative flex flex-col">
    <div class="relative">
      <div class="absolute inset-y-0 right-0 flex items-center pr-4">
        <img src="/images/search.svg" class="h-4 w-4" />
      </div>
      <input
        :id="`#search-data-${_uid}`"
        style="width: 100% !important"
        class="rounded-full px-4 py-2 text-gray-600 border-solid border w-full mt-1 text-sm bg-promy-gray-background-300 focus:border-promy-green-300 border-promy-gray-300"
        placeholder="Recherche"
        autocomplete="off"
        :value="value"
        @input="handleInput($event.target.value)"
        @focus="open = true"
      />
    </div>
    <div class="flex z-10" v-show="open && list.length">
      <div
        class="absolute bg-promy-gray-120 w-full px-4"
        v-click-outside="away"
        style="background-color: transparent"
      >
        <ul class="py-4 shadow-md max-h-43 overflow-y-auto bg-white">
          <li
            v-for="(item, index) in list"
            :key="index"
            class="py-2 px-4 cursor-pointer hover:bg-promy-green-50 text-sm"
            @click="setItem(item)"
          >
            {{ getNestedObject(item) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    args: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    away() {
      if (`#search-data-${this._uid}` !== document.activeElement.id) {
        this.open = false
      }
    },
    setItem(item) {
      this.open = false
      this.$emit('setItem', item)
    },
    handleInput(value) {
      this.$emit('input', value)
    },
    getNestedObject(obj, ...args) {
      return this.args.reduce((obj, level) => obj && obj[level], obj)
    },
  },
}
</script>
