<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title
      >Gestion des utilisateurs guichet unique grand public
    </page-title>
    <table-title nextPath="ajouter" class="sm:items-start sm:space-y-4">
      <template v-slot:title>Liste des utilisateurs grand public</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          class="sm:w-full flex sm:flex-col-reverse items-center space-x-3 sm:space-x-0"
        >
          <div class="sm:w-full">
            <pro-input
              class="sm:w-full"
              v-model="search"
              placeholder="Rechercher"
            />
          </div>
        </div>
      </template>
    </table-title>

    <hr class="mb-6" />
    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :sortingBy="sorting_by"
          @setSortingBy="setSortingBy"
          @orderBy="orderBy"
          :hasSort="true"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <InfiniteScrollContainer
        :isLoading="isFetching"
        :containerScroll="false"
        :fetchingAllowed="last_page > current_page"
        @fetch="getGUGPUsers()"
      >
        <pro-table-row
          v-for="(item, index) in GUGPUsers"
          :key="index"
          class="relative"
        >
          <pro-table-cell label="Nom" class="w-1/3 lg:w-full">
            {{ item.full_name }}
          </pro-table-cell>
          <pro-table-cell label="Email" class="w-1/3 lg:w-full">
            <span v-if="$mq === 'sm'">{{
              item.email | truncateTerrain(19)
            }}</span>
            <span v-else>{{ item.email }}</span>
          </pro-table-cell>

          <pro-table-cell :actions_btn="true" class="w-1/3 lg:w-full">
            <div class="w-full flex justify-end items-center space-x-3">
              <template v-if="$can('delete,gestion_utilisateur')">
                <div class="btn-actions" @click="displayModal(item)">
                  <i class="fas fa-trash"></i>
                </div>
              </template>
              <router-link
                v-if="$can('edite,gestion_utilisateur')"
                :to="{
                  name: 'list-des-grand-public-edit',
                  params: { id: item.id },
                }"
                class="btn-actions"
              >
                <i class="fas fa-edit"></i>
              </router-link>
            </div>
          </pro-table-cell>
        </pro-table-row>
      </InfiniteScrollContainer>
    </pro-card>
    <div
      v-if="!GUGPUsers.length && !isFetching"
      class="w-full flex justify-center mt-24 text-lg font-main font-extrabold text-promy-gray-650"
    >
      Il n'y a pas d'admin pour "{{ search }}"
    </div>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteUser()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer ce compte ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Nom',
          sortKey: 'nom',
          classes: 'w-1/3',
        },
        {
          title: 'Email',
          sortKey: 'email',
          classes: 'w-1/3',
        },
      ],
      sorting_by: '',
      sortBy: '',
      sortOrder: '',
      GUGPUsers: [],
      isFetching: false,
      last_page: 1,
      current_page: 0,
      current_item: null,
      showModal: false,
      search: '',
    }
  },
  created() {
    this.$store.commit('sortBy/RESET_STATE')
  },
  watch: {
    search: {
      handler() {
        this.searchAfterDebounce()
      },
    },
  },
  methods: {
    searchAfterDebounce: debounce(function () {
      this.GUGPUsers = []
      this.current_page = 0
      this.getGUGPUsers()
    }, 800),
    getGUGPUsers() {
      this.current_page++
      this.isFetching = true
      this.$http
        .get('admin/gugp', {
          params: {
            page: this.current_page,
            search: this.search,
            sort_by: this.sortBy,
            order: this.sortOrder,
          },
        })
        .then((res) => {
          this.isFetching = false
          this.GUGPUsers.push(...res.data.data)
          this.last_page = res.data.meta.last_page
          this.current_page = res.data.meta.current_page
        })
    },
    deleteUser() {
      this.$http
        .delete(`admin/gugp/${this.current_item.id}`)
        .then((response) => {
          this.GUGPUsers.splice(this.GUGPUsers.indexOf(this.current_item), 1)
          this.$toaster.success(response.data.message)
          this.showModal = false
        })
    },
    displayModal(item) {
      this.current_item = item
      this.showModal = true
    },
    orderBy(key, order) {
      this.sortBy = key
      this.sortOrder = order
      this.GUGPUsers = []
      this.current_page = 0
      this.getGUGPUsers(key, order)
    },

    setSortingBy(val) {
      this.sorting_by = val
    },
  },
}
</script>
