<template>
  <p class="text-2xl tracking-wide font-main text-gray-800 font-bold">
    <slot />
  </p>
</template>

<script>
export default {}
</script>

<style></style>
