<template>
  <div>
    <template v-if="isFetching">
      <div class="mt-6">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </template>
    <div
      class="h-2"
      v-observe-visibility="{
        callback: handleScrolledToBottomOfTimeLine,
      }"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    isFetching: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleScrolledToBottomOfTimeLine(isVisible) {
      if (this.isFetching) return
      if (!isVisible) return
      this.$emit('isVisible', isVisible)
    },
  },
}
</script>
