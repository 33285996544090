<template>
  <div class="w-full xl:px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <page-title>Gestion des synthèses du marché </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>{{ action }} une synthèse</template>
      <template slot="button" slot-scope="{ nextPath }">
        <router-link
          to="/dossiers-promoteurs/synthese/edit"
          class="capitalize text-promy-green-300 text-xs tracking-wide cursor-pointer"
        >
          &larr; retour
        </router-link>
      </template>
    </table-title>
    <hr class="my-6" />
    <div class="xl:pr-24">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <div>
          <div class="w-1/3">
            <h2 class="title-components">régions</h2>
            <div class="relative mt-2">
              <select
                :disabled="!isCreateMode"
                v-model="form.region_id"
                class="rounded-full px-4 py-2 text-gray-600 border-solid border w-full mt-1 text-sm bg-promy-gray-background-300 appearance-none"
              >
                <template v-if="!isCreateMode && form.region_id">
                  <option selected :value="form.region_id">
                    {{ form.region_name }}
                  </option>
                </template>
                <option
                  :value="region.id"
                  v-for="region in regions"
                  :key="region.id"
                >
                  {{ region.name }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-1/3 mt-4">
            <h2 class="title-components">Titre</h2>
            <div class="relative mt-2">
              <pro-input type="text" v-model="form.title" />
            </div>
          </div>
          <div class="relative h-64">
            <quill-editor
              class="mt-8 h-full"
              ref="myQuillEditor"
              v-model="form.content"
            />
          </div>
          <div class="mt-24">
            <h2 class="title-components">Images</h2>
            <div
              class="grid grid-cols-4 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-16"
            >
              <div :key="index" v-for="(image, index) in form.images">
                <InputImage
                  :file="image.path"
                  :selfUploader="true"
                  @upload="updateImage($event, index)"
                  @deleteImage="deleteImage(index)"
                />
              </div>
              <InputImage @upload="saveImage" />
            </div>
          </div>
          <div class="my-6">
            <button
              v-if="$can('edite,dossier_promoteur')"
              @click="handleSubmit(save)"
              class="px-12 py-2 bg-promy-green-300 text-white hover:text-promy-green-300 border transition-colors duration-200 hover:border-promy-green-300 hover:bg-white rounded-full uppercase font-bold font-main text-sm"
            >
              valider
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { toMulipartedForm } from '../../../../utilities/form'

export default {
  data() {
    return {
      action: 'Ajouter',
      syntheseUrl: 'admin/syntheses',
      form: {
        title: '',
        content: '',
        region_id: '',
        images: [],
      },
      regions: [],
    }
  },
  created() {
    this.getRegions()
    this.checkMode()
  },
  computed: {
    isCreateMode() {
      return this.$route.meta.mode !== 'edit'
    },
  },
  methods: {
    getImage(image) {
      return process.env.VUE_APP_BASE_STORAGE + image
    },
    checkMode() {
      if (this.isCreateMode) return
      this.action = 'Editer'
      this.getSynthese(this.$route.params.id)
    },
    getSynthese(id) {
      this.$http.get(`admin/syntheses/${id}`).then((res) => {
        this.form = res.data.data
      })
    },
    getRegions() {
      this.$http.get('/regions').then((res) => {
        this.regions = res.data.regions
      })
    },
    save() {
      const form = toMulipartedForm(this.form)
      this.$http.post(this.getSyntheseFullUrl(), form).then(() => {
        let toasterMsg = ''
        if (this.action === 'Editer') {
          toasterMsg = 'Vous avez édités les informations avec succès'
        } else {
          toasterMsg = 'Vous avez ajoutés les informations avec succès'
        }
        this.$toaster.success(toasterMsg)
        this.$router.push('/dossiers-promoteurs/synthese/edit')
      })
    },
    getSyntheseFullUrl() {
      return this.isCreateMode
        ? `${this.syntheseUrl}/${this.form.region_id}`
        : `${this.syntheseUrl}/${this.$route.params.id}?_method=PUT`
    },
    deleteImage(index) {
      this.form.images.splice(index, 1)
    },
    updateImage(file, index) {
      this.form.images.splice(index, 1, file)
    },
    saveImage(file) {
      this.form.images.push({ path: file })
    },
  },
}
</script>
