<template>
  <div
    class="flex-1 custom-popup flex w-3/4 lg:w-full relative flex-col map-parcelle"
    id="map_localisation"
  >
    <StylesMap class="z-10" :map="map" :moduleStore="moduleStore" />
    <div class="w-full h-full lg:h-60-screen" id="mapbox"></div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/mapbox'
import defaultmap from '@/mixins/dossierPromoteurs/defaultmap'
import parcelle from '@/mixins/cadastre/parcelle'
import batiment from '@/mixins/cadastre/batiment'
import helpers from '@/mixins/helpers'
import calculeHelpers from '@/mixins/cadastre/calculeHelpers'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'
import etudeCommerciale from '@/mixins/dossierPromoteurs/etudeCommerciale'

export default {
  data() {
    return {
      allMarkersCircle: [],
      allPoints: [],
    }
  },
  props: {
    allParcelles: {
      type: Array,
      required: true,
    },
    permis_construire: {
      type: Array,
      required: true,
    },
    listDvf: {
      type: Array,
      required: true,
    },
  },
  mixins: [
    helpers,
    defaultmap,
    calculeHelpers,
    Mapbox,
    parcelle,
    batiment,
    sourcesAndLayers,
    etudeCommerciale,
  ],
  mounted() {
    this.initMap()
  },
  computed: {
    map() {
      return this.$store.getters['defaultmap/map']
    },
  },
  methods: {
    initMap() {
      let vm = this
      this.$store.commit(
        'defaultmap/SET_MAP',
        this.createMap(this.informationsStore.center_parcelle_principale),
      )
      this.map.on('load', function () {
        vm.fitBoundsParcelles(vm.allParcelles)
        vm.addLayersParcelles(vm.allParcelles)
        vm.$store.commit('defaultmap/MAP_IS_STYLE_LOADED', true)
        vm.addAllMarkersCircle()
        var collection = vm.$turf.featureCollection([
          ...vm.allPoints,
          ...vm.allParcelles,
        ])
        vm.fitBoundsGeojsonInMap(vm.$turf.envelope(collection), 80)
      })
      vm.map.on('style.load', vm.preserveLayers)
    },
    addAllMarkersCircle() {
      if (this.is_permis_construction) this.addPermisConstruire()
      if (this.is_dvf) this.addDvf()
    },
    addPermisConstruire() {
      let vm = this
      this.permis_construire.forEach((items, index) => {
        items.sub_list.forEach((item) => {
          vm.addMarkerCircle(
            item.coordinates,
            'bg-promy-green-200',
            index,
            'pc',
          )
        })
      })
    },
    addDvf() {
      let vm = this
      this.listDvf.forEach((items, index) => {
        items.sub_list.forEach((item) => {
          vm.addMarkerCircle(
            item.coordinates,
            'bg-promy-orange-100',
            index,
            'dvf',
          )
        })
      })
    },
    addMarkerCircle(coords, color, index, type) {
      if (coords[0] && coords[1]) {
        let vm = this
        var el = document.createElement('div')
        el.className = `icon-circle ${color} `
        let marker = new this.$mapboxgl.Marker(el).setLngLat(coords)
        el.addEventListener('click', function (e) {
          vm.$store.commit('etudeCommerciale/IS_MARKER_CLICKED', true)

          document
            .querySelector(`#details-etude-commerciale #${type}-${index}`)
            .click()

          let scroll_el = document.querySelector(
            `#details-etude-commerciale #header-${type}-${index}`,
          )

          setTimeout(function () {
            var topPos = scroll_el.offsetTop
            window.scrollTo(0, topPos)
          }, 500)
        })

        marker.addTo(this.map)
        let point = this.$turf.point(coords)
        this.allPoints.push(point)
        this.allMarkersCircle.push(marker)
      }
    },
    resetMarkerLocalisation(not_reset = false) {
      let vm = this
      if (not_reset) {
        this.addAllMarkersCircle()
      } else {
        this.allMarkersCircle.forEach(function (marker) {
          marker.remove()
        })
        vm.addAllMarkersCircle()
      }
    },
  },
  watch: {
    is_permis_construction(newValue) {
      this.$store.commit('etudeCommerciale/SIDEBAR_TOOLS', false)
      this.resetMarkerLocalisation(newValue)
    },
    is_dvf(newValue) {
      this.$store.commit('etudeCommerciale/SIDEBAR_TOOLS', false)
      this.resetMarkerLocalisation(newValue)
    },
    listDvf() {
      this.resetMarkerLocalisation()
    },
    permis_construire() {
      this.resetMarkerLocalisation()
    },
  },
  beforeDestroy() {
    this.allMarkersCircle.forEach(function (marker) {
      marker.remove()
    })
    this.map.remove()

    this.$store.commit('defaultmap/RESET_MAP')
  },
}
</script>
<style lang="scss">
@import '../../../../assets/styles/Mapbox/control-zoom.scss';
.map-parcelle {
  .mapboxgl-ctrl-group {
    border-radius: 10px;
    margin-top: 24px !important;
    margin-left: 24px !important;
  }
  .hidden-loader {
    display: none !important;
  }
  .translate-center {
    transform: translate(-50%, -50%);
  }
  .center-element {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
</style>
<style lang="scss">
.my-div-icon {
  background-color: #ffffff78;
  color: black;
  font-weight: bold;
  font-size: 12px !important;
}
.marker {
  background-color: #ffffff78 !important;
  color: black !important;
  font-weight: bold;
  font-size: 12px !important;
  border-radius: 15px;
  padding: 1px 4px 1px 4px;
}
.icon-circle {
  @apply flex text-black justify-center text-base items-center font-bold  font-main w-5 h-5 border-solid  border-white border-3;
  border-radius: 50%;
}
</style>
