<template>
  <div class="mt-5">
    <div
      class="flex lg:flex-col items-start space-x-4 lg:space-x-0 lg:space-y-4"
    >
      <div class="w-1/2 lg:w-full">
        <Contracts />
      </div>
      <div class="w-1/2 lg:w-full">
        <Factures />
      </div>
    </div>
  </div>
</template>
<script>
import Contracts from './Contracts'
import Factures from './Factures'

export default {
  components: {
    Contracts,
    Factures,
  },
}
</script>
