<template>
  <div>
    <div
      class="grid gap-8 mt-12 grid-cols-3 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
    >
      <div>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="projet"
          v-model="form.implantationProjet.projet"
          label="projet"
        ></pro-input>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="adresse"
          v-model="form.implantationProjet.adresse"
          label="adresse"
        ></pro-input>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="architect"
          v-model="form.implantationProjet.architect"
          label="architect"
        ></pro-input>
      </div>
      <div>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="adresse"
          v-model="form.implantationProjet.echelle"
          label="echelle"
        ></pro-input>
        <pro-input
          shouldBeHighlighted
          requirements="required"
          name="plan"
          v-model="form.implantationProjet.plan"
          label="plan"
        ></pro-input>
        <div>
          <label
            class="capitalize text-xs -mt-1 text-gray-600 font-bold font-arial"
          >
            Date
          </label>
          <datepicker
            class="date-input"
            v-model="form.implantationProjet.date"
            valueType="DD-MM-YYYY"
            format="DD-MM-YYYY"
          />
          <HighlightMessage
            classes="relative top-5"
            :show="isEmptyOrNull(form.implantationProjet.date)"
          />
        </div>
      </div>
      <div class="flex flex-col">
        <span class="font-main font-extrabold text-promy-gray-650 mb-2">
          Photos
        </span>
        <div class="flex">
          <div class="w-full">
            <div class="grid grid-cols-2 items-end md:grid-cols-1 gap-y-4">
              <div
                v-for="(image, index) in form.implantationProjet.images"
                :key="index"
              >
                <InputImage
                  class="w-full"
                  :file="image"
                  :selfUploader="true"
                  @upload="updateImage($event, index)"
                  @deleteImage="deleteImage(index)"
                />
                <HighlightMessage
                  :show="isEmptyOrNull(form.implantationProjet.images[index])"
                />
              </div>
              <div class="flex flex-col">
                <InputImage @upload="saveImage" />
                <HighlightMessage
                  :show="isEmptyOrNull(form.implantationProjet.images)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.form.implantationProjet.image) {
      this.form.implantationProjet.images.push(
        this.form.implantationProjet.image,
      )
      this.form.implantationProjet.image = null
    }
  },
  methods: {
    saveImage(file) {
      this.form.implantationProjet.images.push(file)
    },
    updateImage(file, idx) {
      this.form.implantationProjet.images.splice(idx, 1, file)
    },
    deleteImage(idx) {
      this.form.implantationProjet.images.splice(idx, 1)
    },
    isEmptyOrNull(data) {
      return (
        data === '' ||
        (Array.isArray(data) && data.length == 0) ||
        data === null ||
        data === undefined
      )
    },
  },
}
</script>
