<template>
  <div class="px-15 w-full lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <div
      class="flex leading-2 text-promy-purple-400 text-promy-2xl font-extrabold font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Paramétrage:</span>
      <span class="text-promy-gray-625 ml-2 font-normal sm:text-xl">
        Logiciels
      </span>
    </div>
    <div
      class="flex items-center lg:text-base mt-6 xl:text-lg font-main font-extrabold justify-between border-b-2 xl:pb-5 lg:pb-4 xl:border-promy-gray-300"
    >
      <span class="text-promy-gray-650 font-extrabold">
        Liste des Logiciels
      </span>
      <span
        class="flex justify-center items-center uppercase cursor-pointer text-white rounded-full border-2 bg-promy-green-300 px-4 py-2 border-promy-green-300 font-main text-sm font-extrabold"
        @click="toggleAddForm"
      >
        <span class="">Ajouter un logiciel </span>
        <i class="fa fa-plus pl-2 text-sm"></i>
      </span>
    </div>

    <!-- add form -->
    <div
      class="lg:relative bg-white my-4 rounded-box px-6 py-7 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
      v-show="showAddForm"
    >
      <div class="sm:flex sm:flex-col grid gap-4 items-center grid-cols-2">
        <div class="flex justify-start">
          <span class="flex flex-col w-full">
            <input-text
              class="w-full"
              :rules="'required'"
              placeholder="Logiciel"
              v-model="software"
              type="text"
              name="software"
            />
            <span class="msg-error" style="display: none"></span>
          </span>
        </div>
        <div class="flex justify-end items-start">
          <button
            class="bg-promy-green-300 rounded-full py-2 px-4 font-extrabold text-white mr-3 cursor-pointer disabled:opacity-50 capitalize"
            @click="storeSoftware"
            :disabled="software == null"
          >
            ajouter
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="softwares.length"
      @click="resetEdit"
      class="mb-25 h-60-screen overflow-y-scroll"
    >
      <div
        class="lg:relative bg-white my-4 rounded-box px-6 py-7 sm:p-4 shadow-xs border-none hover:shadow-lg transition-shadow duration-200 procard"
        v-for="(software_item, index) in softwares"
        :key="index"
      >
        <div class="sm:flex sm:flex-col grid gap-4 items-center grid-cols-2">
          <div class="flex justify-start" @click="stopEventPropagationInput">
            <span
              class="text-gray-700 text-base capitalize font-semibold"
              v-if="!editMode || editedItem != software_item.id"
            >
              {{ software_item.name }}
            </span>
            <span
              class="flex flex-col w-full"
              v-if="editMode && editedItem === software_item.id"
            >
              <input-text
                class="w-full"
                :rules="'required'"
                placeholder="Software"
                v-model="software"
                type="text"
                name="software"
              />
              <span class="msg-error" style="display: none"></span>
            </span>
          </div>
          <div class="flex justify-end">
            <div class="flex">
              <!-- edit div -->
              <div
                v-if="!editMode || editedItem != software_item.id"
                @click="editItem($event, software_item)"
                class="rounded-full p-2 mr-3 cursor-pointer bg-promy-green-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18.12"
                  viewBox="0 0 18 18.12"
                  class="w-4 h-4"
                >
                  <path
                    d="M1761.46,1049.3a1.159,1.159,0,0,0-1.66,0l-3.46,3.47v-0.66h-12.52v14.94h14.87v-12.58h-0.72l3.49-3.51A1.171,1.171,0,0,0,1761.46,1049.3Zm-3.55,16.96H1744.6V1052.9h11.62l-6.14,6.16v1.7h1.63l6.2-6.23v11.73Zm3-15.85-9.52,9.56h-0.53v-0.59l9.49-9.53A0.4,0.4,0,0,1,1760.91,1050.41Z"
                    transform="translate(-1743.81 -1048.94)"
                    class="fill-current text-white"
                  ></path>
                </svg>
              </div>
              <!-- update button -->
              <div
                v-if="editMode && editedItem === software_item.id"
                class="rounded-full p-2 mr-3 cursor-pointer bg-white border-promy-green-300 border-2"
                @click="updateSoftware($event)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18.12"
                  viewBox="0 0 18 18.12"
                  class="w-4 h-4"
                >
                  <path
                    d="M1761.46,1049.3a1.159,1.159,0,0,0-1.66,0l-3.46,3.47v-0.66h-12.52v14.94h14.87v-12.58h-0.72l3.49-3.51A1.171,1.171,0,0,0,1761.46,1049.3Zm-3.55,16.96H1744.6V1052.9h11.62l-6.14,6.16v1.7h1.63l6.2-6.23v11.73Zm3-15.85-9.52,9.56h-0.53v-0.59l9.49-9.53A0.4,0.4,0,0,1,1760.91,1050.41Z"
                    transform="translate(-1743.81 -1048.94)"
                    class="fill-current text-promy-green-300"
                  ></path>
                </svg>
              </div>

              <div
                class="bg-promy-green-300 rounded-full p-2 mr-3 cursor-pointer flex items-center justify-center"
                @click="displayModal(software_item.id)"
              >
                <img src="/images/trash.svg" alt="" class="w-4 h-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-observe-visibility="{
          callback: handleInfiniteScroll,
        }"
      ></div>
    </div>
    <div v-else-if="isLoading">
      <div
        class="lg:text-base mt-10 xl:text-lg font-main font-extrabold xl:pb-5 lg:pb-4 text-center"
      >
        <span class="text-promy-gray-650 font-extrabold">
          Chargement en cours...
        </span>
      </div>
    </div>
    <div v-else>
      <div
        class="lg:text-base mt-10 xl:text-lg font-main font-extrabold xl:pb-5 lg:pb-4 text-center"
      >
        <span class="text-promy-gray-650 font-extrabold">
          La liste des logiciels est vide
        </span>
      </div>
    </div>

    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteSoftware()"
    >
      <slot slot="message_body">
        Êtes-vous sûr de vouloir supprimer ce logiciel ?
      </slot>
      <slot slot="button_valide">Continuer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      software: '',
      softwares: [],
      isLoading: false,
      showAddForm: false,
      editMode: false,
      editedItem: 0,
      id_to_be_deleted: null,
      current_page: 1,
      last_page: null,
    }
  },
  created() {
    this.getSoftwares()
  },
  methods: {
    getSoftwares() {
      this.isLoading = true
      this.$http
        .get(`/admin/softwares?page=${this.current_page}`)
        .then(({ data }) => {
          this.softwares =
            this.current_page > 1
              ? [...this.softwares, ...data.data]
              : data.data
          this.last_page = data.last_page
          this.isLoading = false
        })
    },
    storeSoftware() {
      this.$http
        .post('/admin/softwares', { name: this.software })
        .then(({ data }) => {
          this.softwares.unshift(data)
          this.software = null
          this.showAddForm = false
          this.$toaster.success('Le logiciel a été ajouté avec succès')
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.$toaster.error('Le nom du logiciel existe déjà')
          }
        })
    },
    deleteSoftware() {
      this.$http
        .delete(`admin/softwares/${this.id_to_be_deleted}`)
        .then((response) => {
          this.softwares = [
            ...this.softwares.filter(
              (software) => software.id != this.id_to_be_deleted,
            ),
          ]
          this.showModal = false
          this.id_to_be_deleted = null
          this.$toaster.warning('Le logiciel a été supprimé avec succès')
        })
        .catch(
          ({
            response: {
              data: { error },
            },
          }) => {
            this.showModal = false
            this.id_to_be_deleted = null
            this.$toaster.error(error)
          },
        )
    },
    displayModal(id) {
      this.showModal = true
      this.id_to_be_deleted = id
    },
    updateSoftware(event) {
      event.stopPropagation()
      if (this.software.length === 0) {
        this.$toaster.error('le champ nom ne peut pas être vide')
        return
      }
      this.$http
        .put(`admin/softwares/${this.editedItem}`, {
          name: this.software,
        })
        .then(({ data }) => {
          let updatedItemIndex = this.softwares.findIndex(
            (software) => software.id === this.editedItem,
          )
          this.softwares[updatedItemIndex].name = data.name
          this.softwares[updatedItemIndex].updated_at = data.updated_at
          this.editMode = false
          this.software = null
          this.editedItem = 0
          this.$toaster.info('Le logiciel a été mis à jour avec succès')
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.$toaster.error('Le nom du logiciel existe déjà')
          }
        })
    },
    toggleAddForm() {
      this.software = null
      this.editMode = false
      this.showAddForm = !this.showAddForm
    },
    editItem(event, software) {
      event.stopPropagation()
      if (this.showAddForm) this.showAddForm = !this.showAddForm
      this.editMode = true
      this.editedItem = software.id
      this.software = software.name
    },
    resetEdit() {
      this.editMode = false
    },
    stopEventPropagationInput(event) {
      event.stopPropagation()
    },
    handleInfiniteScroll(isVisible) {
      if (!isVisible || this.last_page === this.current_page) {
        return
      }
      this.current_page++
      this.getSoftwares()
    },
  },
}
</script>
