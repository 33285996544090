import { render, staticRenderFns } from "./CheckboxPermission.vue?vue&type=template&id=3c946f92&scoped=true&"
import script from "./CheckboxPermission.vue?vue&type=script&lang=js&"
export * from "./CheckboxPermission.vue?vue&type=script&lang=js&"
import style0 from "./CheckboxPermission.vue?vue&type=style&index=0&id=3c946f92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c946f92",
  null
  
)

export default component.exports