<template>
  <button
    class="save__button save__button--disabled"
    :class="{ 'save__button--disabled': loading || disabled, [classes]: true }"
    :disabled="loading || disabled"
    @click="$emit('handleClick')"
  >
    <span>{{ text }}</span>
    <span v-if="loading" class="loader ml-4"></span>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Valider',
    },
    classes: {
      type: String,
    },
  },
}
</script>

<style scoped>
.save__button--disabled {
  @apply disabled:bg-promy-gray-325 disabled:border-promy-gray-325 disabled:hover:bg-promy-gray-300 disabled:hover:border-promy-gray-300;
}

.save__button {
  @apply flex justify-evenly items-center w-40 py-3 sm:w-full text-sm font-bold text-white uppercase transition-colors duration-200 border rounded-full bg-promy-green-300 hover:text-promy-green-300 hover:border-promy-green-300 hover:bg-white font-main;
}
</style>
