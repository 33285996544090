<template>
  <div class="rounded-lg mb-8 lg:space-y-6" :style="styles">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    styles: {
      required: false,
    },
  },
}
</script>
<style lang="scss" scoped>
div:first-child {
  @apply rounded-t-lg;
}
div:nth-child(odd) {
  background: #f9f9f9;
}
div:last-child {
  @apply rounded-b-lg;
}
</style>
