<template>
  <validation-provider
    :name="name"
    :rules="requirementsRules"
    v-slot="{ errors }"
  >
    <div class="flex flex-col my-2 w-full">
      <label
        :for="uniqueId"
        class="first-letter:uppercase text-xs text-gray-600 font-bold font-arial"
      >
        {{ label }}
      </label>
      <div class="input-pro relative mt-1">
        <div class="absolute inset-y-0 right-0 z-10" v-if="unit">
          <span
            class="h-full rounded-r-full flex items-center bg-promy-gray-background-500 text-xs justify-center font-bold text-promy-gray-400"
            :class="class_unit"
          >
            {{ unit }}
          </span>
        </div>
        <input
          :disabled="disabled"
          :id="uniqueId"
          :name="name"
          :type="type"
          :value="value"
          v-mask="mask"
          @input="handleInput($event.target.value)"
          :placeholder="placeholder ? placeholder : label"
          class="rounded-full px-4 py-2 text-gray-600 border-solid border w-full text-sm bg-promy-gray-background-300"
          :class="[
            errors[0]
              ? 'border-red-600'
              : 'focus:border-promy-green-300 border-promy-gray-300',
            unit ? 'pr-12' : 'pl-4',
            disabled
              ? 'bg-promy-gray-background-500'
              : 'bg-promy-gray-background-300',
            hightlightInput ? 'border-[#DDC029]' : '',
            classesInput,
          ]"
          autocomplete="off"
        />
      </div>
      <span class="mt-1 text-red-600 text-sm" v-if="errors[0]">
        {{ errors[0] }}
      </span>
      <HighlightMessage :show="hightlightInput" />
    </div>
  </validation-provider>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    requirements: {
      type: String,
    },
    bError: {
      type: Array,
    },
    name: {
      type: String,
    },
    unit: {
      type: String,
    },
    class_unit: {
      type: String,
      default: 'w-12',
    },
    mask: {
      type: String,
    },
    shouldBeHighlighted: {
      type: Boolean,
      default: false,
    },
    classesInput: {
      type: String,
    },
    showed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    uniqueId() {
      return `input-${this._uid}`
    },
    hightlightInput() {
      return (
        this.shouldBeHighlighted &&
        this.showed &&
        (this.label != 'Surface bâti'
          ? !this.value
          : this.value == null || this.value === '' || this.value < 0)
      )
    },
    requirementsRules() {
      return !this.showed
        ? this.requirements.replace('required', '')
        : this.requirements
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
