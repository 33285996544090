import transport from '@/plugins/axios'

export default {
  namespaced: true,

  state: {
    departements: [],
    regions: [],
    nations: [],
  },
  getters: {
    departements(state) {
      return state.departements
    },
    regions(state) {
      return state.regions
    },
    nations(state) {
      return state.nations
    },
  },

  mutations: {
    SET_DEPARTEMENT(state, departements) {
      state.departements = departements
    },

    SET_REGION(state, regions) {
      state.regions = regions
    },

    SET_NATION(state, nations) {
      state.nations = nations
    },
  },

  actions: {
    async getDepartements({ commit }) {
      try {
        let response = await transport.get('departements')
        commit('SET_DEPARTEMENT', response.data.departements)
      } catch (e) {
        throw e
      }
    },

    async getRegions({ commit }) {
      try {
        let response = await transport.get('regions')
        commit('SET_REGION', response.data.regions)
      } catch (e) {
        throw e
      }
    },

    async getNations({ commit }) {
      try {
        let response = await transport.get('nations')
        commit('SET_NATION', response.data.nations)
      } catch (e) {
        throw e
      }
    },
  },
}
