<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <page-title
      >Gestion des messages pour la plateforme des promoteurs</page-title
    >
    <table-title nextPath="ajouter">
      <template v-slot:title
        >Liste des messages pour la plateforme des promoteurs</template
      >
      <template slot="button" slot-scope="{ nextPath }">
        <div
          v-if="$can('edite,gestion_utilisateur')"
          @click.prevent="nextPath('messages-platefrome-promoteurs-create')"
          class="hover:bg-white cursor-pointer hover:text-promy-green-300 transition-colors duration-200 border-2 border-promy-green-300 w-42 px-4 py-2 bg-promy-green-300 rounded-full text-white text-xs tracking-wide font-semibold font-main uppercase flex justify-between items-center"
        >
          ajouter un message
        </div>
      </template>
    </table-title>

    <hr class="my-6" />
    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :sortingBy="sorting_by"
          :hasSort="sort.sortKey ? true : false"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <pro-table-row
        v-for="(item, index) in messages"
        :key="index"
        class="relative"
      >
        <pro-table-cell class="w-1/4">
          <div v-html="item.content"></div>
        </pro-table-cell>
        <pro-table-cell class="w-1/4">
          {{ item.start_at }}
        </pro-table-cell>
        <pro-table-cell class="w-1/4">
          {{ item.end_at }}
        </pro-table-cell>
        <pro-table-cell class="w-1/4">
          {{ status(item.start_at, item.end_at) }}
        </pro-table-cell>

        <pro-table-cell class="w-1/4">
          <div class="w-full flex justify-end items-center space-x-3">
            <div class="btn-actions" @click="displayModal(item)">
              <i class="fas fa-trash"></i>
            </div>
            <router-link
              :to="{
                name: 'messages-platefrome-promoteurs-edit',
                params: { id: item.id },
              }"
              class="btn-actions"
            >
              <i class="fas fa-edit"></i>
            </router-link>
          </div>
        </pro-table-cell>
      </pro-table-row>
    </pro-card>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteMessage()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer ce message ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      sort_list: [
        {
          title: 'Contenu',
          sortKey: 'content',
          classes: 'w-1/5',
        },
        {
          title: 'Date début',
          sortKey: 'start_at',
          classes: 'w-1/5',
        },
        {
          title: 'Date fin',
          sortKey: 'end_at',
          classes: 'w-1/5',
        },
        {
          title: 'Status',
          sortKey: '',
          classes: 'w-1/5',
        },
      ],
      sorting_by: '',
      messages: [],
      showModal: false,
      current_item: null,
    }
  },
  created() {
    this.getMessages()
  },
  methods: {
    getMessages() {
      this.$http.get('admin/messages-promoteurs').then((res) => {
        this.messages = res.data.data
      })
    },
    deleteMessage() {
      this.$http
        .delete(`admin/messages-promoteurs/${this.current_item.id}`)
        .then(() => {
          this.messages.splice(this.messages.indexOf(this.current_item), 1)
        })
      this.showModal = false
    },
    displayModal(idx) {
      this.current_item = idx
      this.showModal = true
    },
    status(start, end) {
      end = moment(end.split('-').reverse().join('-'))
      return end.isSameOrBefore(new Date())
        ? 'Expiré'
        : start == null || moment(start).isSameOrBefore(new Date())
        ? 'En cours de diffusion'
        : 'à venir'
    },
  },
}
</script>

<style scoped></style>
