var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"relative border-dashed border-promy-gray-400 mt-4 mr-auto",class:[
      {
        'bg-promy-gray-225 border-promy-gray-600': _vm.dragging,
        'bg-promy-gray-225 ': !_vm.dragging,
        'border-2': !_vm.isUploaded,
      },
      _vm.isCircle
        ? 'rounded-full h-full w-full'
        : 'rounded-large lg:h-42 sm-ws:h-34 xl:h-45 w-full xl:w-6/7' ],attrs:{"novalidate":""},on:{"dragleave":function($event){$event.preventDefault();_vm.dragging = false},"dragover":function($event){$event.preventDefault();_vm.dragging = true}}},[(!_vm.isUploaded)?_c('div',{staticClass:"absolute flex flex-col justify-center items-center inset-0"},[_vm._m(0),_c('div',{staticClass:"uppercase text-promy-green-300 text-xl font-bold font-main"},[_vm._v(" upload ")]),_c('div',{staticClass:"uppercase text-promy-gray-350 text-xl font-main"},[_vm._v(" jpg, jpeg, png ")])]):_vm._e(),(_vm.imageSource)?_c('img',{staticClass:"transition duration-500 ease-in-out w-full h-full object-cover overflow-hidden",class:_vm.isCircle ? 'rounded-full' : 'rounded-large',attrs:{"src":_vm.imageSource}}):_vm._e(),_c('input',{ref:'fileinput' + _vm._uid,staticClass:"input-file absolute opacity-0 w-full h-full inset-0 cursor-pointer",attrs:{"type":"file","accept":"image/jpeg,\n    image/jpg, image/png"},on:{"change":function($event){$event.preventDefault();return _vm.handleFilesChosen($event)}}}),(_vm.isUploaded)?_c('button',{staticClass:"absolute rounded-full bg-promy-green-300 text-white px-2 py-1 text-sm cursor-pointer hover:bg-promy-green-200 top-0 right-0",class:_vm.isCircle ? 'mt-3 mr-3' : '-mt-3 -mr-2',attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteImage()}}},[_c('i',{staticClass:"fa fa-trash-alt",attrs:{"aria-hidden":"true"}})]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-promy-gray-400 text-3xl"},[_c('i',{staticClass:"fa fa-upload fa-6",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }