<template>
  <div class="flex flex-col mt-12">
    <div class="flex sm:flex-col">
      <div class="lg:w-full w-1/2 flex flex-col">
        <h2 class="title-components">Coût de construction</h2>
        <div class="mt-8 text-xs text-gray-600 font-bold font-arial mb-2">
          Description
        </div>
        <quill-editor
          ref="myQuillEditor"
          class="mb-12 w-10/12"
          v-model="form.lesGammes.desc_cout_de_contruction"
        />
        <HighlightMessage
          classes="relative top-5 w-10/12"
          :show="
            isEmptyOrNull(form.lesGammes.desc_cout_de_contruction) &&
            form.lesGammes.show
          "
        />
      </div>
      <div class="flex flex-col lg:w-full w-1/2 space-y-4">
        <h2 class="title-components">Préconisation</h2>

        <pro-input
          shouldBeHighlighted
          class="w-1/2"
          name="Nom architecte"
          v-model="form.lesGammes.preconisations.nom_architect"
          label="Nom architecte"
          :showed="form.lesGammes.show"
        />

        <div class="flex flex-col w-1/2">
          <vue-select
            :data="gammesName"
            v-model="form.lesGammes.preconisations.gamme"
            v-on:valueSelect="onValueSelect($event)"
          >
            <template v-slot:SelectLabel>Gamme préconisée</template>
          </vue-select>
          <HighlightMessage
            classes="relative w-10/12"
            :show="
              isEmptyOrNull(form.lesGammes.preconisations.gamme) &&
              form.lesGammes.show
            "
          />
        </div>
        <div>
          <div class="text-xs text-gray-600 font-bold font-arial mb-2">
            Description
          </div>
          <quill-editor
            ref="myQuillEditor"
            class="w-10/12"
            v-model="form.lesGammes.preconisations.description"
          />
          <HighlightMessage
            classes="relative top-5 w-10/12"
            :show="
              isEmptyOrNull(form.lesGammes.preconisations.description) &&
              form.lesGammes.show
            "
          />
        </div>
      </div>
    </div>

    <div class="flex mt-16 flex-col">
      <div class="flex flex-col w-full border-b border-promy-gray-300 pb-6">
        <div class="flex items-end text-promy-green-300 font-bold font-main">
          Choisissez une gamme
        </div>
        <pro-switch
          class="mt-4"
          :data="gammesName"
          v-model="selected_gamme.name"
          @valueSelect="changeCurrentGamme($event)"
        />
      </div>
      <hr class="h-1/2 bg-promy-gray-300 border-0 w-full mt-2" />
      <div class="flex w-full mt-10 sm:flex-col">
        <div class="flex flex-col w-1/2 sm:w-full">
          <span class="mb-4">Programmes</span>
          <div class="grid grid-cols-2 sm:grid-cols-1 gap-4">
            <pro-input
              shouldBeHighlighted
              class="w-3/4"
              name="logement accession"
              v-model="current_gamme.logement_accession"
              label="logement accession"
              type="number"
              :showed="form.lesGammes.show"
            />
            <pro-input
              shouldBeHighlighted
              class="w-3/4"
              name="logement social"
              v-model="current_gamme.logement_social"
              label="logement social"
              type="number"
              :showed="form.lesGammes.show"
            />
            <pro-input
              shouldBeHighlighted
              class="w-3/4"
              name="stationement accession"
              v-model="current_gamme.stationement_accession"
              label="stationement accession"
              type="number"
              :showed="form.lesGammes.show"
            />
            <pro-input
              shouldBeHighlighted
              class="w-3/4"
              name="stationement social"
              v-model="current_gamme.stationement_social"
              label="stationement social"
              type="number"
              :showed="form.lesGammes.show"
            />
            <pro-input
              shouldBeHighlighted
              class="w-3/4"
              name="prix_construction_m2"
              v-model="current_gamme.prix_cout_construction_m2"
              label="Coût de construction (€/m2)"
              type="number"
              :showed="form.lesGammes.show"
            />
          </div>
        </div>
        <div class="flex flex-col w-1/2 sm:w-full">
          <div class="text-xs text-gray-600 font-bold font-arial mb-2">
            Description indicatif
          </div>
          <quill-editor
            ref="myQuillEditor"
            class="mb-12 w-10/12"
            v-model="current_gamme.description"
          />
          <HighlightMessage
            classes="relative top-5 w-10/12"
            :show="
              isEmptyOrNull(current_gamme.description) && form.lesGammes.show
            "
          />
        </div>
      </div>
      <div class="flex mt-10 sm:flex-col space-x-2 sm:space-x-0">
        <div class="flex w-1/2 sm:w-full">
          <div class="flex flex-col">
            <span class="mb-6">Tableau Coûts de construction</span>
            <div
              class="flex"
              v-for="(item, index) in current_gamme.t_cout_de_contruction"
              :key="index + '' + selected_gamme.slug + 't_cout_de_contruction'"
            >
              <pro-input
                shouldBeHighlighted
                class="9/24"
                :name="`type ${index + 1}`"
                v-model="item.type"
                :label="`type`"
                :showed="form.lesGammes.show"
              />
              <pro-input
                shouldBeHighlighted
                class="9/24 px-3"
                :name="`prix ${index + 1}`"
                v-model.number="item.prix"
                :label="`prix HT`"
                type="number"
                :showed="form.lesGammes.show"
              />
              <pro-input
                shouldBeHighlighted
                class="9/24"
                :name="`sdp ${index + 1}`"
                v-model.number="item.sdp"
                :label="`SDP `"
                type="number"
                :showed="form.lesGammes.show"
              />
              <div class="flex items-end pb-3 ml-3">
                <span
                  @click="
                    deleteAttribut(current_gamme.t_cout_de_contruction, index)
                  "
                  v-if="current_gamme.t_cout_de_contruction.length > 1"
                  class="text-promy-red ml-auto mr-1 cursor-pointer"
                >
                  <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
                </span>
              </div>
            </div>
            <div
              class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
              @click="addNewColumn(current_gamme.t_cout_de_contruction, true)"
            >
              <p>Ajouter une ligne</p>
              <span
                class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
              >
                <i class="fa fa-plus text-xs" />
              </span>
            </div>
            <PreviewGammeTable :data="current_gamme.t_cout_de_contruction" />
          </div>
        </div>
        <div class="flex w-1/2 sm:w-full">
          <div class="flex flex-col">
            <span class="mb-6">Recettes</span>
            <div
              class="flex"
              v-for="(item, index) in current_gamme.recettes"
              :key="index + '' + selected_gamme.slug + 'recettes'"
            >
              <pro-input
                shouldBeHighlighted
                class="9/24"
                :name="`type ${index + 1}`"
                v-model="item.type"
                :label="`type`"
                :showed="form.lesGammes.show"
              />
              <pro-input
                shouldBeHighlighted
                class="9/24 px-3"
                :name="`prix ${index + 1}`"
                v-model.number="item.prix"
                :label="`prix HT`"
                type="number"
                :showed="form.lesGammes.show"
              />
              <pro-input
                shouldBeHighlighted
                class="9/24"
                :name="`SHAB ${index + 1}`"
                v-model.number="item.sdp"
                :label="`SHAB `"
                type="number"
                :showed="form.lesGammes.show"
              />
              <div class="flex items-end pb-3 ml-3">
                <span
                  @click="deleteAttribut(current_gamme.recettes, index)"
                  v-if="current_gamme.recettes.length > 1"
                  class="text-promy-red ml-auto mr-1 cursor-pointer"
                >
                  <i class="fa fa-minus lg:text-base xl:pb-1 xl:text-xl"></i>
                </span>
              </div>
            </div>
            <div
              class="flex items-center justify-end cursor-pointer text-promy-green-300 uppercase font-main font-extrabold ml-auto text-sm"
              @click="addNewColumn(current_gamme.recettes)"
            >
              <p>Ajouter une ligne</p>
              <div
                class="flex justify-center items-center text-white rounded-full bg-promy-green-300 ml-1 px-1 py-1"
              >
                <i class="fa fa-plus text-xs" />
              </div>
            </div>
            <PreviewGammeTable :data="current_gamme.recettes" />
          </div>
        </div>
      </div>
      <div class="flex mt-10 mb-6 flex-col">
        <span> Images </span>
        <div class="flex">
          <div class="grid grid-cols-4 items-end md:grid-cols-1 gap-y-4 w-full">
            <div
              :key="index + '' + selected_gamme.slug"
              v-for="(image, index) in current_gamme.images"
            >
              <InputImage
                :file="image"
                :selfUploader="true"
                @upload="updateImage($event, index)"
                @deleteImage="deleteImage(index)"
              />
            </div>
            <InputImage @upload="saveImage" />
          </div>
        </div>
        <HighlightMessage
          classes="relative top-5 w-fit"
          :show="current_gamme.images.length === 0 && form.lesGammes.show"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      index_item_to_remove: null,
      gammesName: [],
      selected_gamme: {
        slug: 'bronze',
        name: 'bronze',
        value: null,
        description: null,
      },
    }
  },

  created() {
    if (this.defaultProjetByPromy) {
      this.form.lesGammes.desc_cout_de_contruction = this.getDefaultValue(
        this.form.lesGammes.desc_cout_de_contruction,
        this.defaultProjetByPromy.description_cout_de_contruction,
      )
      this.form.lesGammes.preconisations.nom_architect = this.getDefaultValue(
        this.form.lesGammes.preconisations.nom_architect,
        this.defaultProjetByPromy.nom_architect,
      )
    }
    if (this.defaultGammes.length) {
      this.defaultGammes.forEach((item) => {
        this.form.lesGammes.gammes.forEach((gamme) => {
          if (gamme.slug === item.slug && !gamme.value) {
            gamme.name = item.name
            gamme.value = item.value
            gamme.details.description = item.description
            gamme.details.images = item.images
          }
        })
      })
      this.selected_gamme = this.form.lesGammes.gammes[0]
      this.gammesName = this.form.lesGammes.gammes.map((item) => item.name)
    }
  },

  computed: {
    ...mapGetters({
      defaultGammes: 'dossierpromoteur/getDefaultGammes',
      defaultProjetByPromy: 'dossierpromoteur/getDefaultProjetByPromy',
    }),
    current_gamme() {
      const currentGamme = this.form.lesGammes.gammes.find(
        (gamme) => gamme.slug === this.selected_gamme.slug,
      ).details
      // set default price to Coût De Construction (€/M2)
      currentGamme.prix_cout_construction_m2 =
        currentGamme.prix_cout_construction_m2 === null
          ? this.selected_gamme.value
          : currentGamme.prix_cout_construction_m2
      // set default price to first element
      // in Tableau Cout de construction
      if (currentGamme.t_cout_de_contruction[0].prix === null) {
        currentGamme.t_cout_de_contruction[0].prix =
          currentGamme.prix_cout_construction_m2
      }

      return currentGamme
    },
  },
  watch: {
    'current_gamme.t_cout_de_contruction': {
      deep: true,
      handler() {
        this.autoFillGammesCoutConstructionAndRecettes('t_cout_de_contruction')
      },
    },
    'current_gamme.recettes': {
      deep: true,
      handler() {
        this.autoFillGammesCoutConstructionAndRecettes('recettes')
      },
    },
    'form.lesGammes.preconisations.gamme': {
      handler(new_value, old_value) {
        let default_current_gamme = this.getDefaultGamme(new_value)
        let default_old_gamme = this.getDefaultGamme(old_value)
        if (default_old_gamme) {
          default_old_gamme.description_preconisation = this.getDefaultValue(
            this.form.lesGammes.preconisations.description,
            default_old_gamme.description_preconisation,
          )
        }
        if (default_current_gamme) {
          this.form.lesGammes.preconisations.description =
            default_current_gamme.description_preconisation
        }
      },
    },
  },
  methods: {
    autoFillProgrammes() {
      let keys_to_auto_fill = [
        'logement_accession',
        'logement_social',
        'stationement_accession',
        'stationement_social',
      ]
      this.form.lesGammes.gammes
        .filter((item) => item.name !== this.selected_gamme.name)
        .forEach((gamme) => {
          keys_to_auto_fill.forEach((key) => {
            gamme.details[key] = this.getDefaultValue(
              gamme.details[key],
              this.current_gamme[key],
            )
          })
        })
    },
    autoFillGammesCoutConstructionAndRecettes(table) {
      let gamme_bronze = this.form.lesGammes.gammes.find(
        (item) => item.name === this.selected_gamme.name,
      )
      this.form.lesGammes.gammes
        .filter((item) => item.name !== this.selected_gamme.name)
        .forEach((gamme) => {
          if (this.index_item_to_remove !== null) {
            gamme.details[table].splice(this.index_item_to_remove, 1)
          } else {
            gamme_bronze.details[table].forEach(
              (bronze_t_cout_de_contruction, index) => {
                if (typeof gamme.details[table][index] === 'undefined') {
                  gamme.details[table].push({
                    type: null,
                    prix: table !== 'recettes' ? gamme.value : null,
                    sdp: null,
                  })
                }
                gamme.details[table][index].type =
                  bronze_t_cout_de_contruction.type
                gamme.details[table][index].prix = this.getDefaultValue(
                  gamme.details[table][index].prix,
                  table !== 'recettes' ? gamme.value : null,
                )

                gamme.details[table][index].sdp =
                  bronze_t_cout_de_contruction.sdp
              },
            )
          }
        })
      this.index_item_to_remove = null
    },
    getDefaultGamme(name_gamme) {
      return this.defaultGammes.find((gamme) => gamme.name === name_gamme)
    },
    getCurrentGamme(name_gamme) {
      return this.form.lesGammes.gammes.find(
        (gamme) => gamme.name === name_gamme,
      )
    },
    getDefaultValue(old_value, new_value) {
      return old_value ? old_value : new_value
    },
    autoFillGammeDecription() {
      this.form.lesGammes.gammes.forEach((gamme) => {
        if (this.form.lesGammes[gamme.slug].description === '') {
          this.form.lesGammes[gamme.slug].description = gamme.description
        }
      })
    },
    changeCurrentGamme(value) {
      this.autoFillProgrammes()
      this.selected_gamme = this.getCurrentGamme(value)
    },
    onValueSelect(value) {
      this.form.lesGammes.preconisations.gamme = value
    },
    addNewColumn(value, isCout_construction) {
      isCout_construction
        ? value.push({
            type: null,
            prix: this.current_gamme.prix_cout_construction_m2,
            sdp: null,
          })
        : value.push({})
    },
    deleteAttribut(value, key) {
      this.index_item_to_remove = key
      value.splice(key, 1)
    },
    deleteImage(index) {
      this.current_gamme.images.splice(index, 1)
    },
    updateImage(file, index) {
      this.current_gamme.images.splice(index, 1, file)
    },
    saveImage(file) {
      this.current_gamme.images.push(file)
    },
    isEmptyOrNull(data) {
      return data === '' || data === null || data === undefined
    },
  },
}
</script>
