<template>
  <LayoutParametrage title="Dossiers Promoteurs">
    <template #current_page> Paramètrage des gammes </template>
    <template #title_table> Modifier les gammes </template>

    <template #list_table>
      <div class="flex flex-col">
        <div class="flex flex-col">
          <span
            class="text-promy-gray-625 font-extrabold capitalize sm:text-xl mb-6"
          >
            Les gammes
          </span>
        </div>

        <div class="flex flex-col w-1/2 sm:w-full space-y-2 mb-12">
          <div class="w-fit sm:w-full">
            <pro-input
              name="Nom architecte"
              v-model="projet_by_promy.nom_architect"
              label="Nom architecte"
            />
          </div>

          <div class="text-xs text-gray-600 font-bold font-arial mb-2">
            Description du Coût de construction
          </div>
          <quill-editor
            ref="myQuillEditor"
            class="mb-12 w-10/12 sm:w-full"
            v-model="projet_by_promy.description_cout_de_contruction"
          />
        </div>

        <div class="flex w-full lg:flex-col">
          <div class="flex flex-col w-1/2 lg:w-full">
            <div class="text-promy-gray-300">Choisissez une gamme</div>
            <vue-select
              class="w-50 sm:w-full mb-8"
              :data="getSlugGammes()"
              v-model="selected_gamme"
              v-on:valueSelect="changeCurrentGamme($event)"
            >
            </vue-select>

            <pro-input
              class="w-1/2 sm:w-full"
              requirements="required"
              name="Nom"
              v-model="current_gamme.name"
              label="Nom"
            />
            <pro-input
              class="w-1/2 sm:w-full"
              requirements="required"
              name="Prix"
              type="number"
              v-model="current_gamme.value"
              label="Prix"
              unit="€/m²"
            />
            <pro-textarea
              label="description"
              row="6"
              classTextArea="w-10/12 sm:w-full"
              v-model="current_gamme.description"
            ></pro-textarea>
            <div class="flex flex-col mb-6 mt-4">
              <h2>Préconisation</h2>
              <div class="mt-2 text-xs text-gray-600 font-bold font-arial mb-2">
                Description
              </div>
              <quill-editor
                ref="myQuillEditor"
                class="mb-12 w-10/12 sm:w-full"
                v-model="current_gamme.description_preconisation"
              />
            </div>
          </div>
          <div class="flex w-1/2 lg:w-full">
            <div class="flex mt-10 flex-col w-full">
              <span> Images </span>
              <div class="flex">
                <div
                  class="grid grid-cols-2 sm:grid-cols-1 items-end gap-y-4 w-full"
                >
                  <div
                    v-for="(image, index) in current_gamme.images"
                    :key="index + '' + current_gamme.slug"
                  >
                    <InputImage
                      :file="image"
                      :selfUploader="true"
                      @upload="updateImage($event, index)"
                      @deleteImage="deleteImage(index)"
                    />
                  </div>
                  <InputImage @upload="saveImage" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="$can('edite,dossier_promoteur')"
          type="submit"
          @click="saveGammes()"
          class="green-text-btn my-6"
        >
          <span class="px-12">Sauvegarder</span>
        </button>
      </div>
    </template>
  </LayoutParametrage>
</template>

<script>
export default {
  data() {
    return {
      selected_gamme: 'bronze',
      projet_by_promy: {
        id: null,
        description_cout_de_contruction: null,
        nom_architect: null,
      },

      gammes: [
        {
          slug: 'bronze',
          name: 'bronze',
          value: '',
          description: '',
          images: [],
          description_preconisation: null,
        },
        {
          slug: 'argent',
          name: 'argent',
          value: '',
          description: '',
          images: [],
          description_preconisation: null,
        },
        {
          slug: 'gold',
          name: 'gold',
          value: '',
          description: '',
          images: [],
          description_preconisation: null,
        },
        {
          slug: 'eco-responsable',
          name: 'eco-responsable',
          value: '',
          description: '',
          images: [],
          description_preconisation: null,
        },
      ],
    }
  },
  computed: {
    current_gamme() {
      return this.gammes.find((gamme) => gamme.slug === this.selected_gamme)
    },
  },

  mounted() {
    this.getGammes()
  },
  methods: {
    saveGammes() {
      this.$http
        .put('admin/gammes', {
          gammes: this.gammes,
          projet_by_promy: this.projet_by_promy,
        })
        .then(() => {
          this.$toaster.success('Les gammes sont sauvegardées avec succès')
        })
    },

    getGammes() {
      this.$http.get('admin/gammes').then((response) => {
        let projet_by_promy = response.data.projet_by_promy
        if (projet_by_promy) {
          this.projet_by_promy.id = projet_by_promy.id
          this.projet_by_promy.description_cout_de_contruction =
            projet_by_promy.description_cout_de_contruction
          this.projet_by_promy.nom_architect = projet_by_promy.nom_architect
        }
        if (response.data.gammes.length) {
          response.data.gammes.forEach((item) => {
            this.gammes.forEach((gamme) => {
              if (gamme.slug === item.slug) {
                Object.assign(gamme, item)
                gamme.images = gamme.images ? gamme.images : []
              }
            })
          })
        }
      })
    },
    deleteImage(index) {
      this.current_gamme.images.splice(index, 1)
    },
    updateImage(file, index) {
      this.current_gamme.images.splice(index, 1, file)
    },
    saveImage(file) {
      this.current_gamme.images.push(file)
    },
    getSlugGammes() {
      return this.gammes.map((gamme) => gamme.slug)
    },
    changeCurrentGamme(value) {
      this.selected_gamme = value
    },
  },
}
</script>

<style scoped></style>
