<template>
  <svg
    height="12pt"
    width="12pt"
    viewBox="0 0 839 761"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M654.381 440.447L818 681.645L521.875 737.168C495.977 742.024 469.278 740.384 444.169 732.395L21 597.75V492.882L174.132 471.908L293.658 419.474"
      :class="`stroke-current ${color}`"
      stroke-width="41.9474"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M482.415 58.989C384.715 58.989 305.449 134.433 305.449 227.352C305.449 334.269 423.426 503.701 466.746 562.222C468.544 564.693 470.901 566.703 473.624 568.089C476.347 569.475 479.359 570.197 482.415 570.197C485.47 570.197 488.483 569.475 491.206 568.089C493.929 566.703 496.286 564.693 498.084 562.222C541.403 503.725 659.38 334.355 659.38 227.352C659.38 134.433 580.115 58.989 482.415 58.989Z"
      :class="`stroke-current ${color}`"
      stroke-width="39.3257"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M482.419 294.942C514.998 294.942 541.408 268.532 541.408 235.954C541.408 203.375 514.998 176.965 482.419 176.965C449.841 176.965 423.431 203.375 423.431 235.954C423.431 268.532 449.841 294.942 482.419 294.942Z"
      :class="`stroke-current ${color}`"
      stroke-width="39.3257"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-white',
    },
  },
}
</script>
