<template>
  <div class="section-category">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      parentUrl: '/gestion-des-roles-et-permissions',
      links: [
        {
          name: 'catégories',
          path: 'gestion-des-roles-et-permissions/categories/type',
          componentName: 'liste-des-categories',
        },
        {
          name: 'Rôles',
          path: 'gestion-des-roles-et-permissions/roles/type',
          componentName: 'liste-des-roles',
        },
        {
          name: 'Permissions',
          path: 'gestion-des-roles-et-permissions/permissions/type',
          componentName: 'list-des-permissions-component',
        },
      ],
    }
  },
  created() {
    this.$store.commit('subSidebar/LINKS', this.links)
    this.$store.commit('subSidebar/PARENT_URL', this.parentUrl)
  },
  mounted() {
    this.$store.commit('sidebar/RESIZE_WINDOW', true)
  },
  destroyed() {
    this.$store.commit('sidebar/RESIZE_WINDOW', false)
  },
}
</script>

<style lang="scss">
.section-category {
  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    display: none;
  }
  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label {
    position: relative;
    cursor: pointer;
    color: #273869;
  }
  [type='checkbox']:checked + label:before,
  [type='checkbox']:not(:checked) + label:before {
    margin-top: -12px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #00c9bc;
    background: white;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 0px;
  }
  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    content: '';
    background: #00c9bc;
    margin-top: -12px;

    height: 11px;
    width: 11px;
    position: absolute;
    top: 7px;
    left: 7px;
    border: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='checkbox']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    display: none;
  }
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    cursor: pointer;
    color: #273869;
  }
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    margin-top: -12px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    border-radius: 9999px !important;
    height: 24px;
    border: 1px solid #00c9bc;
    background: white;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 0px;
  }
  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '';
    background: #00c9bc;
    margin-top: -12px;

    height: 11px;
    width: 11px;
    border-radius: 9999px !important;

    position: absolute;
    top: 7px;
    left: 7px;
    border: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
