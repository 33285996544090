function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'

export default [
  {
    path: 'gestion-des-utilisateurs',
    name: 'gestion-des-utilisateurs',
    component: page('dashboard/GestionDesUsers'),
    redirect: 'gestion-des-utilisateurs/teams',
    children: [
      {
        path: 'teams',
        name: 'list-des-teams-component',
        redirect: 'teams/edit',
        component: page('dashboard/GestionDesUsers'),
        children: [
          {
            path: 'edit',
            name: 'list-des-teams',
            meta: {
              middleware: [auth],
            },
            component: page('dashboard/GestionDesUsers/Team/edit'),
          },
          {
            path: 'create',
            name: 'list-des-teams-create',
            meta: {
              middleware: [auth],
              mode: 'create',
            },
            component: page('dashboard/GestionDesUsers/Team/create'),
          },
          {
            path: ':id/edit',
            name: 'list-des-teams-edit',
            meta: {
              middleware: [auth],
              mode: 'edit',
            },
            component: page('dashboard/GestionDesUsers/Team/create'),
          },
          {
            path: ':id/grandfathered',
            name: 'list-des-teams-grandfathered',
            meta: {
              middleware: [auth],
            },
            component: page('dashboard/GestionDesUsers/Team/grandfathered'),
          },
          {
            path: ':id/abonnement-temporaire',
            name: 'list-des-teams-abonnement-temporaire',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesUsers/Team/abonnement-temporaire',
            ),
          },
        ],
      },
      {
        path: 'promoteurs',
        name: 'list-des-promoteurs-component',
        component: page('dashboard/GestionDesUsers'),
        redirect: 'promoteurs/edit',
        children: [
          {
            path: 'edit',
            name: 'list-des-promoteurs',
            meta: {
              middleware: [auth],
            },
            component: page('dashboard/GestionDesUsers/Promoteur/edit'),
          },
          {
            path: 'create',
            name: 'list-des-promoteurs-create',
            meta: {
              middleware: [auth],
              mode: 'create',
            },
            component: page('dashboard/GestionDesUsers/Promoteur/create'),
          },
          {
            path: ':id/edit',
            name: 'list-des-promoteurs-edit',
            meta: {
              middleware: [auth],
              mode: 'edit',
            },
            component: page('dashboard/GestionDesUsers/Promoteur/create'),
          },
        ],
      },
      {
        path: 'admins',
        name: 'list-des-admins-component',
        component: page('dashboard/GestionDesUsers'),
        redirect: 'admins/edit',
        children: [
          {
            path: 'edit',
            name: 'list-des-admins',
            meta: {
              middleware: [auth],
            },
            component: page('dashboard/GestionDesUsers/Admin/edit'),
          },
          {
            path: 'create',
            name: 'list-des-admins-create',
            meta: {
              middleware: [auth],
              mode: 'create',
            },
            component: page('dashboard/GestionDesUsers/Admin/create'),
          },
          {
            path: ':id/edit',
            name: 'list-des-admins-edit',
            meta: {
              middleware: [auth],
              mode: 'edit',
            },
            component: page('dashboard/GestionDesUsers/Admin/create'),
          },
        ],
      },
      {
        path: 'agents-immobilier',
        name: 'list-des-agents-immobilier-component',
        component: page('dashboard/GestionDesUsers'),
        redirect: 'agents-immobilier/edit',
        children: [
          {
            path: 'edit',
            name: 'list-des-agents-immobilier',
            meta: {
              middleware: [auth],
            },
            component: page('dashboard/GestionDesUsers/AgentImmobilier/edit'),
          },
          {
            path: 'create',
            name: 'list-des-agents-immobilier-create',
            meta: {
              middleware: [auth],
              mode: 'create',
            },
            component: page('dashboard/GestionDesUsers/AgentImmobilier/create'),
          },
          {
            path: ':id/edit/user/:userId',
            name: 'list-des-agents-immobilier-edit',
            meta: {
              middleware: [auth],
              mode: 'edit',
            },
            component: page('dashboard/GestionDesUsers/AgentImmobilier/create'),
          },
        ],
      },
      {
        path: 'teams-agents-immobilier',
        name: 'list-des-teams-agents-immobilier-component',
        component: page('dashboard/GestionDesUsers'),
        redirect: 'teams-agents-immobilier/list',
        children: [
          {
            path: 'list',
            name: 'list-des-teams-agents-immobilier',
            meta: {
              middleware: [auth],
            },
            component: page(
              'dashboard/GestionDesUsers/TeamAgentImmobilier/list',
            ),
          },
          {
            path: 'create',
            name: 'list-des-teams-agents-immobilier-create',
            meta: {
              middleware: [auth],
              mode: 'create',
            },
            component: page(
              'dashboard/GestionDesUsers/TeamAgentImmobilier/create',
            ),
          },
          {
            path: ':id/edit',
            name: 'list-des-teams-agents-immobilier-edit',
            meta: {
              middleware: [auth],
              mode: 'edit',
            },
            component: page(
              'dashboard/GestionDesUsers/TeamAgentImmobilier/create',
            ),
          },
        ],
      },

      {
        path: 'grand-public',
        name: 'list-des-grand-public-component',
        component: page('dashboard/GestionDesUsers'),
        redirect: 'grand-public/list',
        children: [
          {
            path: 'list',
            name: 'list-des-grand-public',
            meta: {
              middleware: [auth],
            },
            component: page('dashboard/GestionDesUsers/GrandPublic/list'),
          },
          {
            path: 'create',
            name: 'list-des-grand-public-create',
            meta: {
              middleware: [auth],
              mode: 'create',
            },
            component: page('dashboard/GestionDesUsers/GrandPublic/form'),
          },
          {
            path: 'edit/user/:id',
            name: 'list-des-grand-public-edit',
            meta: {
              middleware: [auth],
              mode: 'edit',
            },
            component: page('dashboard/GestionDesUsers/GrandPublic/form'),
          },
        ],
      },

      {
        path: 'users-log',
        name: 'users-log-component',
        component: page('dashboard/GestionDesUsers'),
        redirect: 'edit',
        children: [
          {
            path: 'edit',
            name: 'users-log-edit',
            meta: {
              middleware: [auth],
            },
            component: page('dashboard/GestionDesUsers/UsersLog'),
          },
        ],
      },
      {
        path: 'occupation',
        component: page('dashboard/Parametrage/Occupation'),
        meta: {
          middleware: [auth],
        },
        name: 'occupation',
        redirect: 'occupation/edit',
        children: [
          {
            path: 'edit',
            name: 'occupation-list',
            component: page('dashboard/Parametrage/Occupation/edit'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: 'form',
            name: 'occupation-form',
            component: page('dashboard/Parametrage/Occupation/form'),
            meta: {
              middleware: [auth],
            },
          },
          {
            path: ':id/edit',
            name: 'occupation-edit',
            meta: {
              mode: 'edit',
              middleware: [auth],
            },
            component: page('dashboard/Parametrage/Occupation/form'),
          },
        ],
      },
    ],
  },
]
