<template>
  <div
    class="grid items-center grid-cols-7 gap-2 px-6 mt-2 mb-2 sm:flex sm:flex-col lg:grid-cols-7"
  >
    <div class="flex justify-center lg:w-full">
      <ButtonSortBy
        title="statut"
        sortKey="status"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center lg:w-full">
      <ButtonSortBy
        title="promoteur"
        sortKey="typeable_id"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center lg:w-full">
      <ButtonSortBy
        title="commune"
        sortKey="commune"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center lg:w-full">
      <ButtonSortBy
        title="adresse"
        sortKey="adresse"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center lg:w-full">
      <ButtonSortBy
        title="Date de demande"
        sortKey="created_at"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
    <div class="flex justify-center lg:w-full">
      <ButtonSortBy
        title="Mise a jour"
        sortKey="updated_at"
        :sortBy="sortingBy"
        @orderBy="orderBy"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sortingBy: {
      type: String,
      default: '',
    },
  },
  methods: {
    orderBy(key) {
      this.$emit('setSortingBy', this.sortingBy === key ? '' : key)
      let order = this.sortingBy === key ? 'asc' : 'desc'
      this.$emit('orderBy', key, order)
    },
  },
}
</script>

<style></style>
