<template>
  <div>
    <slot name="pro-table-th"></slot>
    <div>
      <draggable @update="update" :options="{ disabled: !isDraggable }">
        <pro-card
          :styles="itemsStyle"
          v-for="(item, index) in value"
          :key="index"
        >
          <slot name="pro-table-row" :item="item" :index="index" />
          <slot name="pro-table-row-mobile" :item="item" :index="index" />
        </pro-card>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  props: {
    value: [Array, Object],
    isDraggable: {
      type: Boolean,
      default: true,
    },
    itemsStyle: {
      type: String,
    },
  },
  methods: {
    update({ newIndex, oldIndex }) {
      this.$emit('draggedToNewIndex', {
        newIndex: newIndex,
        oldIndex: oldIndex,
      })
    },
  },
}
</script>
