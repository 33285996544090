<template>
  <svg
    class="w-10 h-auto fill-current"
    :style="`fill :${color}`"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M122.285,17.067c30.883,0,66.317,2.117,95,17.821c18.2,10.369,34.175,24.228,47.008,40.783
				c6.092,7.567,11.333,15.612,16.892,24.129c6.083,9.754,12.763,19.123,20,28.054c12.203,14.449,26.755,26.737,43.042,36.35
				l-16.417,37.146c-1.163,2.64-0.915,5.689,0.66,8.106s4.264,3.875,7.149,3.878l0.267-0.004l108.117-3.35
				c3.231-0.099,6.128-2.016,7.484-4.951s0.936-6.383-1.084-8.907l-68.683-85.879c-1.864-2.33-4.808-3.517-7.766-3.133
				c-2.958,0.384-5.502,2.284-6.709,5.013l-16.077,36.378c-13.932-8.486-26.4-19.168-36.923-31.632
				c-6.79-8.404-13.057-17.216-18.767-26.388c-5.531-8.803-11.505-17.32-17.9-25.517c-14.231-18.308-31.943-33.62-52.117-45.054
				C193.418,2.367,155.327,0,122.285,0c-4.713,0-8.533,3.82-8.533,8.533S117.572,17.067,122.285,17.067z M361.947,166.328
				c1.055-1.126,1.696-2.577,1.819-4.116l13.402-30.329l49.242,61.567l-77.508,2.4L361.947,166.328z"
          />
          <path
            d="M430.085,270.779c-71.117,0-122.217-19.65-156.225-60.071c-6.758-8.376-13-17.157-18.692-26.292
				c-5.549-8.818-11.539-17.35-17.95-25.563c-14.206-18.357-31.93-33.697-52.133-45.125C152.86,96.125,113.027,93.867,81.918,93.867
				c-21.806,0.165-39.363,17.948-39.25,39.754c0.038,14.148,7.51,27.234,19.675,34.458c9.72,4.824,20.683,6.558,31.417,4.971
				l0.108-0.004v309.607c-0.073,16.109,12.908,29.237,29.017,29.346h266.233c16.109-0.109,29.09-13.237,29.017-29.346V350.195
				c4.104,0.06,8.108,0.089,11.95,0.089c21.756-0.278,39.248-17.994,39.248-39.752S451.841,271.057,430.085,270.779z
				 M122.885,494.933c-6.687-0.099-12.033-5.592-11.95-12.279v-21.612c18.907,1.555,37.338,6.729,54.292,15.241
				c10.614,5.53,20.848,11.761,30.634,18.65H122.885z M401.068,482.654c0.083,6.688-5.263,12.181-11.95,12.279H227.983
				c-3.685-1.979-7.463-4.178-11.298-6.654c-3.692-2.388-7.308-4.821-10.917-7.25c-10.514-7.393-21.507-14.079-32.908-20.013
				c-19.321-9.702-40.362-15.508-61.925-17.09v-59.684c18.896,1.557,37.319,6.717,54.275,15.199
				c10.804,5.66,21.226,12.021,31.2,19.042c3.642,2.45,7.283,4.896,11.008,7.313c32.589,19.942,69.13,32.546,107.083,36.937
				c28.692,3.959,57.605,6.11,86.567,6.441V482.654z M401.068,452.105c-28.139-0.325-56.231-2.415-84.108-6.258
				c-35.519-4.056-69.732-15.786-100.267-34.379c-3.642-2.358-7.2-4.75-10.758-7.146c-10.579-7.424-21.631-14.152-33.084-20.142
				c-19.327-9.673-40.362-15.467-61.916-17.053v-92.933c19.403,3.031,37.633,11.23,52.775,23.736
				c11.13,9.329,21.561,19.461,31.208,30.317c3.642,3.9,7.283,7.8,11.008,11.642c29.316,29.95,66.858,50.529,107.875,59.133
				c28.676,6.321,57.908,9.78,87.267,10.328V452.105z M401.068,392.273c-28.06-0.537-55.999-3.845-83.408-9.878
				c-37.811-7.893-72.432-26.823-99.483-54.396c-3.65-3.763-7.217-7.579-10.783-11.404c-10.22-11.465-21.266-22.166-33.05-32.017
				c-18.129-14.963-40.103-24.522-63.409-27.586v-83.358c20.092,4.636,38.244,15.406,51.942,30.819
				c11.402,12.408,21.883,25.632,31.358,39.567c3.625,5.063,7.258,10.121,10.975,15.104c26.495,36.933,64.507,64.019,108.067,77.004
				c28.585,8.157,58.077,12.713,87.792,13.562V392.273z M430.085,333.217c-37.745,0.965-75.43-3.556-111.875-13.421
				c-40.042-11.97-74.975-36.898-99.316-70.871c-3.658-4.9-7.225-9.875-10.792-14.85c-9.961-14.61-20.977-28.474-32.958-41.479
				c-15.725-16.279-40.842-35.887-71.2-36.858l-1.358-0.033c-0.341-0.025-0.642-0.004-0.967-0.004c-2.883,0-5.817,0.15-8.767,0.308
				c-7.376,1.209-14.944,0.3-21.825-2.621c-6.981-4.142-11.27-11.649-11.292-19.767c-0.124-12.384,9.799-22.533,22.183-22.688
				c29.075,0,66.15,2.021,94.983,17.771c18.233,10.365,34.221,24.254,47.033,40.858c6.048,7.771,11.703,15.841,16.942,24.179
				c6.07,9.713,12.724,19.048,19.925,27.954c37.45,44.513,92.817,66.15,169.283,66.15c12.33,0.277,22.182,10.352,22.182,22.685
				S442.415,332.94,430.085,333.217z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#00C9BC',
    },
  },
}
</script>
