<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <div
      class="flex leading-2 text-promy-purple-400 text-promy-2xl font-extrabold font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Dossiers Promoteurs:</span>
      <span class="text-promy-gray-625 ml-2 font-normal sm:text-xl">
        Conditions Suspensives
      </span>
    </div>
    <div
      class="flex sm:flex-col items-center sm:items-start sm:space-y-3 lg:text-base mt-6 xl:text-lg font-main font-extrabold justify-between border-b-2 xl:pb-5 lg:pb-4 xl:border-promy-gray-300"
    >
      <span class="text-promy-gray-650 font-extrabold"
        >Liste des conditions</span
      >
      <span
        v-if="$can('edite,dossier_promoteur')"
        @click="addCondition()"
        class="sm:w-full flex justify-center items-center uppercase cursor-pointer text-white rounded-full border-2 bg-promy-green-300 px-4 py-2 border-promy-green-300 font-main text-sm font-extrabold"
      >
        <span class="">Ajouter une condition </span>
        <i class="fa fa-plus pl-2 text-sm"></i>
      </span>
    </div>

    <div class="flex w-full my-3 pt-4 text-xs">
      <span class="text-sm text-promy-gray-400 font-extrabold"
        >Faites glisser les éléments pour afficher l'ordre d'apparition</span
      >
    </div>
    <draggable
      class="drag-area"
      :list="all_condition_suspensive"
      :options="{ animation: 200, group: 'status' }"
      @change="updateOrder"
    >
      <pro-table-row
        v-for="(item, index) in all_condition_suspensive"
        :key="index"
        class="my-4 py-2 lg:py-0 rounded-lg relative"
      >
        <template v-if="!item.new_item">
          <pro-table-cell class="w-1/3 lg:w-full">
            <span v-if="!item.editText">
              {{ item.nom }}
            </span>
            <input-text
              v-else-if="item.editText"
              class="w-full"
              :rules="'required'"
              v-model="item.nom"
              placeholder="Condition"
              type="text"
            />
          </pro-table-cell>
          <pro-table-cell class="w-1/3 lg:w-full">
            <span v-if="!item.editText">
              {{ item.with_other_condition ? 'Autre condition' : '' }}
            </span>
            <Checkbox
              v-else-if="item.editText"
              :val="item.with_other_condition"
              v-model="item.with_other_condition"
              label="Avec autre condition"
              :id="'checkbox-' + index"
            />
          </pro-table-cell>
          <pro-table-cell :actions_btn="true" class="w-1/3 lg:w-full">
            <div class="w-full flex justify-end items-center space-x-3">
              <div
                v-if="$can('edite,dossier_promoteur')"
                :class="item.editText ? 'white-icon-btn' : 'green-icon-btn'"
                @click="
                  !item.editText
                    ? showUpdate(item, index)
                    : updateCondition(item, index)
                "
              >
                <i class="fas fa-edit" />
              </div>
              <div
                v-if="$can('delete,dossier_promoteur')"
                class="green-icon-btn"
                @click="displayModal(item.id)"
              >
                <i class="fas fa-trash" />
              </div>
            </div>
          </pro-table-cell>
        </template>
        <template v-else>
          <pro-table-cell class="w-1/3 lg:w-full">
            <input-text
              class="w-full"
              :rules="'required'"
              v-model="item.nom"
              placeholder="Condition"
              type="text"
            />
          </pro-table-cell>
          <pro-table-cell class="w-1/3 lg:w-full">
            <Checkbox
              :val="item.with_other_condition"
              v-model="item.with_other_condition"
              label="Avec autre condition"
              :id="'checkbox-' + index"
            />
          </pro-table-cell>
          <pro-table-cell class="w-1/3 lg:w-full">
            <div
              class="w-full flex justify-end lg:justify-center items-center space-x-3"
            >
              <div
                class="bg-promy-green-300 rounded-full py-2 px-4 font-extrabold text-white mr-3 cursor-pointer"
                @click="storeItem(item, index)"
              >
                Ajouter
              </div>
            </div>
          </pro-table-cell>
        </template>
      </pro-table-row>
    </draggable>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteItem()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer cette condition ?</slot
      >
      <slot slot="button_valide">Continuer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      showModal: false,
      current_index: null,
    }
  },
  computed: {
    ...mapGetters({
      all_condition_suspensive:
        'conditionssuspensives/all_condition_suspensive',
    }),
  },
  created() {
    this.$store.dispatch('conditionssuspensives/getAllConditionSuspensive')
  },
  mounted() {},
  methods: {
    updateOrder() {
      this.all_condition_suspensive.map((condition, index) => {
        condition.order = index + 1
      })
      this.$store.dispatch(
        'conditionssuspensives/updateAllConditionOrder',
        this.all_condition_suspensive,
      )
    },
    addCondition() {
      let hasAlreadyNewItem = this.all_condition_suspensive.some(
        (item) => item.new_item,
      )

      if (!hasAlreadyNewItem) {
        this.all_condition_suspensive.forEach(function (element) {
          element.editText = false
          element.new_item = false
        })
        this.$store.commit('conditionssuspensives/UNSHIFT_NEW_CONDITION', {
          nom: '',
          editText: true,
          new_item: true,
        })
      } else {
        this.$store.commit('conditionssuspensives/SHIFT_NEW_CONDITION')
      }
    },
    storeItem(condition, idx) {
      condition.editText = false
      condition.new_item = false
      this.$store.dispatch('conditionssuspensives/saveConditionItem', {
        idx: idx,
        object: condition,
      })
    },
    deleteItem() {
      this.$store.dispatch(
        'conditionssuspensives/deleteConditionItem',
        this.current_index,
      )
      this.showModal = false
    },
    displayModal(idx) {
      this.current_index = idx
      this.showModal = true
    },
    showUpdate(condition, index) {
      condition.editText = true
      this.$store.commit('conditionssuspensives/SHOW_EDIT', {
        idx: index,
        object: condition,
      })
    },
    updateCondition(condition, index) {
      this.$store.dispatch('conditionssuspensives/updateConditionItem', {
        idx: index,
        object: condition,
      })
    },
  },
}
</script>

<style scoped></style>
