<template>
  <div
    class="flex justify-start items-center w-full py-2 text-xs font-bold uppercase text-promy-gray-650 cursor-pointer leading-none"
    @click="orderBy()"
  >
    {{ title }}
    <div class="pl-1">
      <i
        :class="[
          sortKey === sortBy ? 'fas fa-chevron-up' : 'fas fa-chevron-down',
        ]"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    sortKey: {
      type: String,
      require: true,
    },
    sortBy: {
      type: String,
      require: true,
    },
  },

  methods: {
    orderBy() {
      this.$emit('orderBy', this.sortKey)
    },
  },
}
</script>

<style></style>
