<template>
  <div class="w-full sm:w-full md:w-2/3 lg:w-1/3 xl:w-1/3">
    <h2 class="title-components">preconisation</h2>
    <pro-input
      requirements="required"
      name="Nom architecte"
      v-model="form.descriptifProjet.preconisation.nom_architect"
      label="Nom architecte"
    />
    <div>
      <vue-select
        :data="gammeSelection"
        v-model="selectedOption"
        v-on:valueSelect="onValueSelect($event)"
      >
        <template v-slot:SelectLabel>Gamme préconisée </template>
      </vue-select>
    </div>
    <div>
      <pro-textarea
        label="description"
        v-model="form.descriptifProjet.preconisation.gamme_description"
      ></pro-textarea>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: ['form'],
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gammeSelection: ['bronze', 'argent', 'gold', 'responsable'],
      selectedOption: this.form.descriptifProjet.preconisation.gamme,
    }
  },
  methods: {
    onValueSelect(value) {
      this.form.descriptifProjet.preconisation.gamme = value
      this.selectedOption = value
    },
  },
}
</script>
