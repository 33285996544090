<template>
  <div
    class="flex flex-col items-center h-full px-24 bg-gray-100 lg:justify-center xl:py-32 sm:px-8"
  >
    <img src="/images/logo.svg" alt="" />
    <h1
      class="my-12 text-xl font-bold text-center text-gray-800 font-main sm:text-lg"
    >
      Bienvenu sur l'Espace Administrateur
    </h1>
    <div>
      <img src="/images/avatar.png" class="w-48 h-48" alt="" />
    </div>
    <form @submit.prevent="sendResetPassword" class="w-full md:w-full lg:w-1/2">
      <div class="flex flex-col mb-4">
        <label class="mb-1 text-promy-gray-500 text-promy-sm font-bold"
          >Email</label
        >
        <input
          v-model="email"
          :class="errors.email ? 'border-red-400' : 'border-promy-gray-300'"
          class="focus:outline-none focus:bg-white focus:border-promy-green-200 bg-promy-gray-background-300 rounded-full pr-4 pl-8 border-solid border-2 w-full h-12 text-promy-gray-600 text-base sm-ws:h-11"
          type="Email"
        />
        <span
          v-if="errors.email"
          class="rounded-lg w-full py-3 px-3 bg-promy-red-200 text-sm text-promy-red-50 mt-2 text-opacity-50"
          >{{ errors.email[0] }}</span
        >
        <span
          v-if="user_not_found"
          class="rounded-lg w-full py-3 px-3 bg-promy-red-200 text-sm text-promy-red-50 mt-2 text-opacity-50"
          >{{ user_not_found }}</span
        >
      </div>

      <div class="flex flex-col items-center my-8">
        <button
          type="submit"
          class="btn-green h-12 w-50 text-base font-main font-extrabold sm-ws:h-9"
        >
          Réinitialisez
        </button>
        <router-link
          to="login"
          class="btn-white h-12 w-50 text-base font-main font-extrabold mt-4 flex justify-center items-center sm-ws:h-9"
        >
          Connexion
        </router-link>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: null,
      errors: [],
      user_not_found: '',
    }
  },
  methods: {
    sendResetPassword() {
      this.$http
        .post('password/email/admin', {
          email: this.email,
        })
        .then((res) => {
          this.errors = []
          this.user_not_found = ''
          this.$toaster.success(res.data.message)
        })
        .catch((err) => {
          if (err.response.data.error != undefined) {
            this.errors = []
            this.user_not_found = err.response.data.error
          } else {
            this.user_not_found = null
            this.errors = err.response.data.errors
          }
        })
    },
  },
}
</script>
