<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-4 sm-ws:px-10">
    <div
      class="flex font-extrabold leading-2 text-promy-purple-400 text-promy-2xl font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Gestion des délégations</span>
    </div>
    <div
      class="flex justify-between items-center sm:flex-col sm:items-start my-6 lg:text-base xl:text-lg sm:space-y-4"
    >
      <div class="w-full flex justify-between items-center">
        <span class="font-extrabold text-promy-gray-650 font-main">
          Liste des délégations de mandats
        </span>
      </div>
    </div>
    <div class="space-x-2 relative mt-8">
      <PTabs :tabs="tabs" :init_component="current_tab" :with_hr_tabs="true">
        <template v-slot="{ current_component }">
          <component :is="current_component"></component>
        </template>
      </PTabs>
    </div>
    <div></div>
  </div>
</template>

<script>
import InProgress from './/Tabs/InProgress.vue'
import Archives from './/Tabs/Archives.vue'
import PTabs from '../components/Tab/PTabs.vue'

export default {
  components: {
    InProgress,
    Archives,
    PTabs,
  },
  data() {
    return {
      tabs: [
        {
          component: 'InProgress',
          label: 'En cours',
        },
        {
          component: 'Archives',
          label: 'Archivés',
        },
      ],
      current_tab: 'InProgress',
    }
  },
}
</script>
