<template>
  <div>
    <input
      type="radio"
      :id="id"
      :value="val"
      v-model="checked"
      @change="check"
    />
    <label :for="id"></label>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
    },
    val: {
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      proxy: false,
    }
  },
  methods: {
    check(e) {
      this.$emit('input', this.proxy)
    },
  },
  computed: {
    checked: {
      get() {
        return this.value
      },
      set(value) {
        this.proxy = value
      },
    },
  },
}
</script>
<style lang="scss" scoped>
[type='radio']:checked,
[type='radio']:not(:checked) {
  display: none;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  cursor: pointer;
  color: #273869;
}
div.disabled {
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before,
  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    border: 1px solid #e4e4e4;
    background: #e4e4e4;
  }
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid var(--main-color);
  background: white;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 15px;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  background: var(--main-color);
  height: 11px;
  width: 11px;
  position: absolute;
  top: 7px;
  left: 7px;
  border: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 15px;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>
