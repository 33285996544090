export default {
  namespaced: true,
  state: {
    error_wms_cadastre_officiel: false,
    wms_list_layers_names: [],
  },
  getters: {
    error_wms_cadastre_officiel(state) {
      return state.error_wms_cadastre_officiel
    },
    wms_list_layers_names(state) {
      return state.wms_list_layers_names
    },
  },
  mutations: {
    ERROR_WMS_CADASTRE_OFFICIEL(state, payload) {
      state.error_wms_cadastre_officiel = payload
    },
    WMS_LIST_LAYERS_NAMES(state, payload) {
      state.wms_list_layers_names = payload
    },
  },
}
