var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('label',{staticClass:"text-xs flex justify-between mb-1 text-promy-gray-500",attrs:{"for":_vm.uniqueId}},[_vm._v(_vm._s(_vm.label)+" "),(_vm.label_value)?_c('span',[_vm._v(_vm._s(_vm.label_value))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"relative"},[_c('img',{staticClass:"absolute top-2 right-4 cursor-pointer",attrs:{"src":[
        _vm.input_type === 'password'
          ? '/images/icons/eye-off-outline.svg'
          : '/images/icons/eye-outline.svg' ]},on:{"click":function($event){$event.stopPropagation();return _vm.showPassword()}}}),_c('input',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.away),expression:"away"}],ref:_vm.uniqueId,staticClass:"disabled:border-promy-gray-300 disabled:bg-promy-gray-300 focus:outline-none focus:bg-white focus:border-promy-green-200 rounded-full pr-12 text-promy-gray-600 border-solid border w-full border-promy-gray-300",class:[
        _vm.errors[0] ? 'border-promy-red-100' : '',
        !_vm.clearBG ? 'bg-promy-gray-background-300' : 'bg-white',
        _vm.placeholderAnimation ? 'hide-placeholder' : '',
        _vm.$route.name === 'Login' ? 'pl-8' : 'pl-4',
        _vm.classes ],attrs:{"id":_vm.uniqueId,"dir":_vm.dir,"name":_vm.name ? _vm.name : _vm.label,"type":_vm.input_type,"placeholder":_vm.placeholder ? _vm.placeholder : _vm.label,"disabled":_vm.disabled,"autocomplete":"off"},domProps:{"value":_vm.value},on:{"click":function($event){_vm.showRule = true},"input":function($event){return _vm.handleInput($event.target.value)}}}),(_vm.placeholderAnimation)?_c('span',{staticClass:"new-placeholder absolute left-4 top-[9px] text-promy-gray-500 transition duration-200 ease-in-out",on:{"click":function($event){return _vm.autoFocus()}}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e()]),(_vm.with_rule && _vm.showRule && !_vm.password_is_valid)?_c('div',{staticClass:"absolute w-full z-20 mt-1 rounded-md border bg-promy-gray-background-300 px-2 py-3 text-xs"},[_c('p',[_vm._v(" Votre mot de passe doit contenir 8 caractères 1 minuscule 1 majuscule 1 nombre et 1 caractère spécial ")]),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"mt-1"},[_c('i',{staticClass:"fas fa-check-circle",class:_vm.errors.eightcharacter
              ? 'text-promy-gray-570'
              : 'text-promy-green-200'}),_vm._v(" 8 caractères ")]),_c('div',{staticClass:"mt-1"},[_c('i',{staticClass:"fas fa-check-circle",class:_vm.errors.uppercase ? 'text-promy-gray-570' : 'text-promy-green-200'}),_vm._v(" Majuscule ")]),_c('div',{staticClass:"mt-1"},[_c('i',{staticClass:"fas fa-check-circle",class:_vm.errors.lowercase ? 'text-promy-gray-570' : 'text-promy-green-200'}),_vm._v(" Minuscule ")]),_c('div',{staticClass:"mt-1"},[_c('i',{staticClass:"fas fa-check-circle",class:_vm.errors.number ? 'text-promy-gray-570' : 'text-promy-green-200'}),_vm._v(" Nombre ")]),_c('div',{staticClass:"mt-1"},[_c('i',{staticClass:"fas fa-check-circle",class:_vm.errors.specialcase
              ? 'text-promy-gray-570'
              : 'text-promy-green-200'}),_vm._v(" Caractères non alphanumériques ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }