<template>
  <div
    class="flex bg-white border border-promy-gray-300 rounded-lg absolute z-10 switch-buttons cursor-pointer text-promy-gray-600 font-bold shadow-sm sm:text-sm"
  >
    <div
      class="py-2 px-3 rounded-l-lg hover:bg-promy-gray-125 hover:text-promy-gray-800"
      :class="{ 'text-promy-gray-800': !is_street }"
      @click="changeStyle('streets-v11', false)"
    >
      Plan
    </div>
    <div class="border border-promy-gray-300"></div>
    <div
      class="py-2 px-3 rounded-r-lg hover:bg-promy-gray-125 hover:text-promy-gray-800"
      :class="{ 'text-promy-gray-800': is_street }"
      @click="changeStyle('satellite-streets-v11', true)"
    >
      Satellite
    </div>
  </div>
</template>

<script>
import mapbox from '@/mixins/mapbox'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'

export default {
  props: {
    map: {
      required: true,
    },
    moduleStore: {
      type: String,
      required: true,
    },
  },
  computed: {
    is_street() {
      return this.$store.getters[`${this.moduleStore}/is_street`]
    },
  },
  mixins: [mapbox, sourcesAndLayers],
  methods: {
    changeStyle(style, is_street) {
      if (this.is_street !== is_street) {
        this.$store.commit(`${this.moduleStore}/IS_STREET`, is_street)
        this.switchLayer(this.moduleStore, style, this.getAllLayers())
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.switch-buttons {
  top: 20px;
  right: 20px;
  left: auto;
}
</style>
