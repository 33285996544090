<template>
  <LayoutParametrage title="Partenaires">
    <template #current_page>Partenaires Promy</template>
    <template #add_button>
      <router-link
        v-if="$can('edite,partenaire')"
        class="btn-green px-6 py-2 sm:w-full md:mt-8 sm:text-center"
        to="/partenaires/ajouter"
      >
        Ajouter Partenaire
      </router-link>
    </template>
    <template #modal v-if="allPartenaires">
      <div
        class="grid grid-cols-4 gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1"
      >
        <Card
          v-for="partenaire in allPartenaires"
          :key="partenaire.id"
          :partenaire="partenaire"
          @handleClick="showPartner"
          @handleDelete="handleDelete"
        />
        <isVisible :isFetching="isFetching" @isVisible="isVisible" />
      </div>
      <ValidateModal
        v-if="showModal"
        @isCancel="showModal = false"
        @isValide="remove"
      >
        <slot slot="message_body">
          Êtes-vous sûr de vouloir supprimer ce parteanire ?
        </slot>
        <slot slot="button_valide">Confirmer</slot>
        <slot slot="button_cancel">Annuler</slot>
      </ValidateModal>
      <div
        v-if="!allPartenaires.length"
        class="w-full font-main text-2xl text-promy-gray-500 text-center font-extrabold"
      >
        Aucun partenaire
      </div>
    </template>
  </LayoutParametrage>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from './Card.vue'

export default {
  components: {
    Card,
  },
  data() {
    return {
      isFetching: false,
      current_page: 1,
      showModal: false,
      partenaire_id_to_delete: null,
    }
  },

  created() {
    this.$store.commit('partenaire/SET_PARTENAIRES')
    this.$store.dispatch('partenaire/getAllPartenaires', this.current_page)
  },

  methods: {
    showPartner(partenaire) {
      if (!this.$can('edite,partenaire')) return
      this.$store.commit('partenaire/SET_CURRENT_PARTENAIRE', partenaire)
      this.$router.push(`/partenaires/modifier/${partenaire.id}`)
    },

    isVisible(isVisible) {
      if (
        !isVisible ||
        this.pagination.last_page === this.current_page ||
        this.isLoading
      )
        return
      this.current_page++
      this.$store.dispatch('partenaire/getAllPartenaires', this.current_page)
    },
    handleDelete(id) {
      this.showModal = true
      this.partenaire_id_to_delete = id
    },
    async remove() {
      let res = await this.$store.dispatch(
        'partenaire/deletePartenaire',
        this.partenaire_id_to_delete,
      )

      if (res === 'Ok') {
        this.$toaster.success('Le partenaire a bien été supprimé')
      } else {
        this.$toaster.warning(res)
      }

      this.showModal = false
    },
  },

  computed: {
    ...mapGetters({
      allPartenaires: 'partenaire/allPartenaires',
      pagination: 'partenaire/pagination',
      isLoading: 'partenaire/isLoading',
    }),
  },
}
</script>

<style></style>
