var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":_vm.name,"rules":_vm.requirementsRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex flex-col my-2 w-full"},[_c('label',{staticClass:"first-letter:uppercase text-xs text-gray-600 font-bold font-arial",attrs:{"for":_vm.uniqueId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"input-pro relative mt-1"},[(_vm.unit)?_c('div',{staticClass:"absolute inset-y-0 right-0 z-10"},[_c('span',{staticClass:"h-full rounded-r-full flex items-center bg-promy-gray-background-500 text-xs justify-center font-bold text-promy-gray-400",class:_vm.class_unit},[_vm._v(" "+_vm._s(_vm.unit)+" ")])]):_vm._e(),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"rounded-full px-4 py-2 text-gray-600 border-solid border w-full text-sm bg-promy-gray-background-300",class:[
          errors[0]
            ? 'border-red-600'
            : 'focus:border-promy-green-300 border-promy-gray-300',
          _vm.unit ? 'pr-12' : 'pl-4',
          _vm.disabled
            ? 'bg-promy-gray-background-500'
            : 'bg-promy-gray-background-300',
          _vm.hightlightInput ? 'border-[#DDC029]' : '',
          _vm.classesInput ],attrs:{"disabled":_vm.disabled,"id":_vm.uniqueId,"name":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder ? _vm.placeholder : _vm.label,"autocomplete":"off"},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.handleInput($event.target.value)}}})]),(errors[0])?_c('span',{staticClass:"mt-1 text-red-600 text-sm"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),_c('HighlightMessage',{attrs:{"show":_vm.hightlightInput}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }