import axios from 'axios'

export default {
  data() {
    return {
      is_installations_nucleaires_loaded: false,
    }
  },
  methods: {
    setInstallationsNucleaires(risques, NUC) {
      let coords = this.informationsStore.center_parcelle_principale
      NUC.sub_title = []
      this.setDetailRisqueNUC(risques, NUC)

      NUC.sub_title.forEach((sub) => {
        axios
          .get(
            `https://mapsref.brgm.fr/wxs/georisques/rapport?version=1.1.0&service=wfs&request=getfeature&typename=${sub.layer}&propertyname=${sub.properties}&X=${coords[0]}&Y=${coords[1]}&rayon=${sub.rayon}&resultType=results`,
          )
          .then(
            (response) => {
              let data_json = this.xmlToJson(response.data)
              sub.value = data_json['wfs:FeatureCollection']['gml:boundedBy'][
                'gml:null'
              ]
                ? 'Non'
                : 'Oui'
              this.is_installations_nucleaires_loaded = true
            },
            (error) => {
              console.log(error)
              this.is_installations_nucleaires_loaded = true
            },
          )
      })
    },
    setDetailRisqueNUC(risques, NUC) {
      risques.definitions_risques.NUC.alea.forEach((nuc) => {
        this.setObjNUC(NUC, 'alea', nuc)
        this.setObjNUC(NUC, 'alea', nuc, 2)
      })
    },

    setObjNUC(NUC, key, obj, idx = 1) {
      let alea_obj = {
        key: key,
        label: idx == 1 ? obj.label : obj.subLabel,
        rayon: idx == 1 ? obj.rayon : obj.rayon2,
        layer: obj.layer[0],
        properties: obj.properties.toString(),
        value: '',
        descr: idx == 1 ? obj.descr : '',
        displayRayon: false,
      }
      NUC.sub_title.push(alea_obj)
    },
  },
}
