<template>
  <div class="flex justify-start text-sm font-arial font-normal px-1 h-11">
    <ButtonSortBy
      v-if="hasSort"
      :title="title"
      :sortKey="sortKey"
      :sortBy="sortingBy"
      @orderBy="orderBy"
    />
    <div
      v-else
      class="flex justify-start items-center w-full py-2 text-xs font-bold uppercase text-promy-gray-650 leading-none"
    >
      {{ title }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sortingBy: {
      type: String,
      default: '',
    },
    hasSort: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    sortKey: {
      type: String,
    },
  },

  methods: {
    orderBy(key) {
      this.$emit('setSortingBy', this.sortingBy === key ? '' : key)
      let order = this.sortingBy === key ? 'asc' : 'desc'
      this.$emit('orderBy', key, order)
    },
  },
}
</script>
