<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <div class="w-full grid lg:grid-cols-1 grid-cols-2 gap-y-6 gap-x-12">
      <div>
        <pro-input
          name="nom"
          requirements="required"
          v-model="form.nom"
          label="Nom"
        />
        <pro-input
          name="prénom"
          requirements="required"
          v-model="form.prenom"
          label="prénom"
        />
        <pro-input
          :disabled="true"
          name="email"
          type="email"
          requirements="required"
          v-model="form.email"
          label="email"
        />
      </div>
      <div>
        <InputPhoneNumber
          name="Mobile"
          :rules="'required'"
          v-model="form.telephone_mobile"
          placeholder="+33"
          label="Mobile"
        />
        <InputPhoneNumber
          name="Tél Fax"
          v-model="form.telephone_fix"
          placeholder="+33"
          label="Tél Fax"
        />
        <pro-input
          name="Adress"
          requirements="required"
          v-model="form.adress"
          label="Adress"
        />
      </div>
    </div>

    <div class="my-6 flex">
      <button
        @click="handleSubmit(save)"
        class="px-12 py-2 bg-promy-green-300 text-white hover:text-promy-green-300 border transition-colors duration-200 hover:border-promy-green-300 hover:bg-white rounded-full uppercase font-bold font-main text-sm mr-6"
      >
        METTRE A JOUR
      </button>
      <router-link
        :to="{
          name: 'user-profile-password',
        }"
        class="uppercase px-12 py-2 rounded-full border-2 border-promy-green-200 text-promy-green-200 hover:bg-promy-green-200 hover:text-white font-bold font-main text-sm"
      >
        Modifier mon mot de passe
      </router-link>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  watch: {
    user: {
      immediate: true,
      handler(user) {
        this.form = { ...user, ...user.typeable }
        this.form.type = 'admin'
      },
    },
  },
  methods: {
    save() {
      this.$http
        .put('user/updateType', this.form)
        .then((res) => {
          this.$store.commit('auth/SET_USER', res.data.data)
          this.$toaster.success('Votre profil a été modifié avec succès')
          this.$router.push('/')
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors)
        })
    },
  },
}
</script>
