<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <page-title>Suivi de l'activité</page-title>
    <table-title
      nextPath="ajouter"
      class="flex items-center justify-between sm:flex-col sm:items-start sm:space-y-3"
    >
      <template v-slot:title> Liste des activités des utilisateurs </template>
    </table-title>

    <div class="flex items-center mt-8 mb-6">
      <div
        class="relative cursor-pointer mr-6"
        v-for="(tab, i) in tabs"
        :key="i"
        @click="changeCurrentTab(tab.component)"
      >
        <p
          class="uppercase font-main text-base font-extrabold py-2"
          :class="[
            current_tab === tab.component
              ? 'text-promy-gray-700'
              : 'text-promy-gray-500',
          ]"
        >
          {{ tab.label }}
        </p>
        <hr
          v-if="current_tab === tab.component"
          class="absolute bottom-0 right-0 bg-promy-green-300 w-full h-[4px]"
        />
      </div>
    </div>
    <div>
      <component :is="current_tab" />
    </div>
  </div>
</template>

<script>
import UsersLogs from './UsersLogs.vue'
import GrandPublicLogs from './GrandPublicLogs.vue'

export default {
  components: {
    UsersLogs,
    GrandPublicLogs,
  },
  data() {
    return {
      tabs: [
        {
          component: 'UsersLogs',
          label: 'Promoteurs/Admin',
        },
        {
          component: 'GrandPublicLogs',
          label: 'GUICHET UNIQUE UNIFIED',
        },
      ],
      current_tab: 'UsersLogs',
    }
  },
  methods: {
    changeCurrentTab(component) {
      this.current_tab = component
    },
  },
}
</script>
