<template>
  <div
    @click="filterBy(sortBy)"
    class="flex justify-between items-center py-2 px-4 bg-white border rounded-full text-promy-gray-670 capitalize font-bold text-sm max-w-full cursor-pointer"
  >
    {{ text }}
    <i
      class="fas ml-2"
      :class="`fa-chevron-${filter === sortBy ? 'up' : 'down'}`"
    />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
  },

  methods: {
    filterBy(key) {
      this.$emit('applyFilterBy', key)
    },
  },
}
</script>

<style></style>
