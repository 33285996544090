<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar>
      <progress-bar
        section="Le projet by promy"
        :progress_section="progress_section.le_projet_by_promy"
      />
    </template>
    <template #current_page>Le projet by promy</template>
    <template #body>
      <pro-tabs :tabs="tabs" class="mt-8" v-if="!default_data_is_loading">
        <pro-tab slot-scope="{ tabs, selectTab, currentTab }">
          <div class="flex flex-row sm:flex-col">
            <div
              v-for="tab in tabs"
              :key="tab.name"
              class="flex items-center mr-24 text-sm font-bold capitalize text-gray-800 sm:my-2"
              :class="{
                'border-b-tab text-promy-green-300': tab.selected,
              }"
            >
              <a href="#" @click="selectTab(tab)">{{ tab.name }}</a>
              <div
                v-if="tab.with_eye"
                class="pl-2 flex items-center justify-center"
              >
                <img
                  v-if="!form[tab.ref].show"
                  src="/images/eye-off-outline.svg"
                  @click="form[tab.ref].show = true"
                  v-tooltip.top="{
                    content: 'Affichez',
                    classes: classes,
                  }"
                />
                <img
                  v-if="form[tab.ref].show"
                  src="/images/eye-outline.svg"
                  @click="form[tab.ref].show = false"
                  v-tooltip.top="{
                    content: 'Masquez',
                    classes: classes,
                  }"
                />
              </div>
            </div>
          </div>
          <hr class="mt-2" />
          <div>
            <component :is="currentTab" :form="form" :ref="currentTab" />
          </div>
        </pro-tab>
      </pro-tabs>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import { mapGetters } from 'vuex'

import DescriptifProjet from './descriptifProjet'
import ImplantationProjet from './implantation-projet'
import LesGammes from './les-gammes'
import Partenaire from './partenaire'
import Projet3d from './projet-3d'
import progress from '@/mixins/dossierPromoteurs/progress'

var object_gamme = {
  logement_accession: null,
  stationement_accession: null,
  logement_social: null,
  stationement_social: null,
  description: null,
  t_cout_de_contruction: [{ type: null, prix: null, sdp: null }],
  recettes: [{ type: null, prix: null, sdp: null }],
  images: [],
  prix_cout_construction_m2: null,
}

export default {
  mixins: [progress],

  components: {
    DescriptifProjet,
    ImplantationProjet,
    Projet3d,
    LesGammes,
    Partenaire,
  },
  data() {
    return {
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-xs',
        'bg-white',
        'mt-2',
      ],
      default_data_is_loading: true,
      tabs: [
        {
          name: 'descriptif projet',
          ref: 'descriptifProjet',
          selected: true,
          with_eye: false,
        },
        {
          name: 'implantation projet',
          ref: 'implantationProjet',
          selected: false,
          with_eye: false,
        },
        {
          name: 'projet 3D',
          ref: 'projet3d',
          selected: false,
          with_eye: true,
        },
        {
          name: 'partenaire',
          ref: 'partenaire',
          selected: false,
          with_eye: true,
        },
        {
          name: 'les gammes',
          ref: 'lesGammes',
          selected: false,
          with_eye: true,
        },
      ],

      form: {
        descriptifProjet: {
          tableau: [
            {
              etages: null,
              sdp: null,
              surface_interieur: null,
              surface_stationnements: null,
              nombre_logements: null,
              commentaires: null,
            },
          ],
          infosparcelle: [],
          descriptifprojet: {
            nombre_LGT_accession: null,
            nombre_LGT_social: null,
            stationnement_accession: null,
            stationnement_social: null,
            stationnement_visiteur: null,
            total_logements: null,
            total_stationnements: null,
          },
          documents_descriptif: [],
          show_description_remarque: false,
          preconisation: {},
          ratiodivers: {
            show: true,
            hauteur_plafond: null,
            ratio_stationnement: null,
            shab_moyen_logt: null,
            ration_SHAB: null,
            epaisseur_moyenne_bat: null,
            espace_vert: null,
          },
          reglementationurbanisme: {
            ces: null,
            hauteur: null,
            impl_limit: null,
            impl_voie: null,
            impl_bati: null,
            espace_vert: null,
          },
        },
        implantationProjet: {
          image: null,
          images: [],
        },
        projet3d: {
          show: true,
          images: [],
        },
        lesGammes: {
          show: true,
          desc_cout_de_contruction: null,
          gammes: [
            {
              slug: 'bronze',
              name: 'bronze',
              value: null,
              details: _.cloneDeep(object_gamme),
            },
            {
              slug: 'argent',
              name: 'argent',
              value: null,
              details: _.cloneDeep(object_gamme),
            },
            {
              slug: 'gold',
              name: 'gold',
              value: null,
              details: _.cloneDeep(object_gamme),
            },
            {
              slug: 'eco-responsable',
              name: 'eco-responsable',
              value: null,
              details: _.cloneDeep(object_gamme),
            },
          ],
          preconisations: {
            nom_architect: null,
            gamme: null,
            description: null,
          },
        },
        partenaire: {
          show: true,
          nom: null,
          prenom: null,
          studio: null,
          siteweb: null,
          email: null,
          description: null,
          architect_logo: null,
          images: [],
        },
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        ;[
          'ces',
          'espace_vert',
          'hauteur',
          'impl_bati',
          'impl_limit',
          'impl_voie',
        ].forEach((key) => {
          this.excluded_keys.push(
            `descriptifProjet_reglementationurbanisme_${key}`,
          )
        })

        Object.keys(value.descriptifProjet.ratiodivers).forEach((key) => {
          if (!value.descriptifProjet.ratiodivers.show) {
            if (
              !this.excluded_keys.includes(
                `descriptifProjet_ratiodivers_${key}`,
              )
            )
              this.excluded_keys.push(`descriptifProjet_ratiodivers_${key}`)
          } else {
            const index = this.excluded_keys.indexOf(
              `descriptifProjet_ratiodivers_${key}`,
            )
            if (index > -1) {
              this.excluded_keys.splice(index, 1)
            }
          }
        })

        if (!value.descriptifProjet.show_description_remarque) {
          if (
            !this.excluded_keys.includes(
              `descriptifProjet_description_remarque`,
            )
          )
            this.excluded_keys.push(`descriptifProjet_description_remarque`)
        } else {
          const index = this.excluded_keys.indexOf(
            `descriptifProjet_description_remarque`,
          )
          if (index > -1) {
            this.excluded_keys.splice(index, 1)
          }
        }

        this.addExcludedObj(value.lesGammes.show, 'lesGammes')
        this.addExcludedObj(value.partenaire.show, 'partenaire')
        this.addExcludedObj(value.projet3d.show, 'projet3d')

        this.excluded_keys.push('implantationProjet_image')
        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'le_projet_by_promy',
          value: this.progressSection(value),
        })
      },
    },
  },
  computed: {
    ...mapGetters({
      informationsStore: 'dossierpromoteur/getinfos',
      infos_cadastre: 'parcelle/infos_cadastre',
      PBP_Store: 'dossierpromoteur/getProjetByPromy',
      progress_section: 'dossierpromoteur/getProgressSection',
      urbanismeStore: 'dossierpromoteur/getUrbanisme',
    }),
  },
  methods: {
    getSurfaceMesurer(geojson) {
      return Math.round(this.$turf.area(geojson))
    },
    PrepareInfosParcelle() {
      let allParcelles = [
        this.informationsStore.current_parcelle,
        ...this.informationsStore.parcelles_voisines,
      ]
      this.form.descriptifProjet.infosparcelle = []
      allParcelles.forEach((item) => {
        let parcelle_info = {}
        let zones_intersect_parcelle =
          this.urbanismeStore.all_zones_urba.filter((zone) => {
            let intersect = this.$martinez.intersection(
              this.$turf.buffer(item, -0.0003).geometry.coordinates,
              zone.geometry.coordinates,
            )
            return intersect && intersect.length
          })
        parcelle_info.zonage = zones_intersect_parcelle
          .map((zone) => zone.properties.libelle)
          .join(',')

        parcelle_info.adresse = item.properties.adresse

        parcelle_info.section = item.properties.section

        parcelle_info.parcelle = item.properties.numero

        parcelle_info.surface_mesure = this.getSurfaceMesurer(item)

        parcelle_info.surface_cadastrale = item.properties.contenance

        parcelle_info.nb_bati = this.informationsStore.current_batiment.filter(
          (bati) =>
            bati.properties.parcelle_id.includes(
              item.properties.section + item.properties.numero,
            ),
        ).length
        parcelle_info.geojson_parcelle = item
        this.form.descriptifProjet.infosparcelle.push(parcelle_info)
      })
    },
    checkParcellesChanged() {
      let allParcelles = [
        this.informationsStore.current_parcelle,
        ...this.informationsStore.parcelles_voisines,
      ]

      let num_parcelle_locale = this.form.descriptifProjet.infosparcelle.map(
        (item) => item.parcelle,
      )

      let num_parcelle_store = allParcelles.map(
        (item) => item.properties.numero,
      )

      let difference = _.xor(num_parcelle_locale, num_parcelle_store)

      if (difference.length) {
        this.PrepareInfosParcelle()
      }
    },

    saveInfos() {
      if (this.$refs['les-gammes']) {
        this.$refs['les-gammes'].autoFillProgrammes()
      }
      this.$store.commit('dossierpromoteur/setProjetByPromy', this.form)
    },
  },
  created() {
    this.default_data_is_loading = true
    this.$http.get('admin/gammes').then((response) => {
      this.$store.commit(
        'dossierpromoteur/SET_DEFAULT_GAMMES',
        response.data.gammes,
      )
      this.$store.commit(
        'dossierpromoteur/SET_DEFAULT_PORJET_BY_PROMY',
        response.data.projet_by_promy,
      )
      this.default_data_is_loading = false
    })
    this.form = _.merge({}, this.form, this.PBP_Store)

    this.form.lesGammes.gammes.forEach((zone, index) => {
      zone.details.prix_cout_construction_m2 = zone.details
        .prix_cout_construction_m2
        ? zone.details.prix_cout_construction_m2
        : zone.value
    })
  },
  mounted() {
    this.checkParcellesChanged()
  },
  beforeRouteLeave(to, from, next) {
    this.saveInfos()
    next()
  },
}
</script>
<style scoped>
.border-b-tab {
  position: relative;
}
.border-b-tab::after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #00b7ab;
  width: 100%;
  bottom: -0.5rem;
  right: 0px;
}
</style>
