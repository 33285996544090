import auth from '../../middleware/auth'

function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}

export default [
  {
    path: '',
    name: 'TableauBoard',
    meta: {
      middleware: [auth],
    },
    component: page('dashboard/Home'),
  },
]
