<template>
  <div class="mt-12">
    <h2 class="title-components">profil de partenaire</h2>
    <div>
      <div class="flex flex-col xl:flex-row items-center mt-3">
        <div class="md:w-full lg:w-5/12 w-1/4 flex justify-center px-6">
          <div class="flex flex-col items-center">
            <InputImage
              :file="form.partenaire.architect_logo"
              @upload="saveImageArchitect"
              :selfUploader="true"
              @deleteImage="form.partenaire.architect_logo = ''"
              shape="circle"
              class="w-48 h-48"
            />
            <HighlightMessage
              classes="relative top-5"
              :show="
                isEmptyOrNull(form.partenaire.architect_logo) &&
                form.partenaire.show
              "
            />
          </div>
        </div>
        <div class="md:w-full lg:w-7/12 w-3/4 border-l-2 pl-12 md:mt-6">
          <div
            class="grid grid-cols-2 gap-8 md:grid-cols-1 gap-y-4 md:gap-x-12 lg:gap-x-20 xl:gap-x-34"
          >
            <div>
              <pro-input
                shouldBeHighlighted
                requirements="required"
                name="nom"
                v-model="form.partenaire.nom"
                label="nom"
                :showed="form.partenaire.show"
              ></pro-input>
              <pro-input
                shouldBeHighlighted
                requirements="required"
                name="prenom"
                v-model="form.partenaire.prenom"
                label="Prénom"
                :showed="form.partenaire.show"
              ></pro-input>
              <pro-input
                shouldBeHighlighted
                requirements="required|email"
                name="email"
                v-model="form.partenaire.email"
                label="email"
                :showed="form.partenaire.show"
              ></pro-input>
            </div>
            <div>
              <pro-input
                shouldBeHighlighted
                requirements="required"
                name="studio"
                v-model="form.partenaire.studio"
                label="studio"
                :showed="form.partenaire.show"
              ></pro-input>
              <pro-input
                shouldBeHighlighted
                requirements="required"
                name="siteweb"
                v-model="form.partenaire.siteweb"
                label="siteweb"
                :showed="form.partenaire.show"
              ></pro-input>
            </div>
          </div>
          <div>
            <div
              class="text-xs capitalize text-gray-600 font-bold font-arial mt-1 mb-2"
            >
              a propos
            </div>
            <quill-editor
              ref="myQuillEditor"
              class="mb-6 w-1/2"
              v-model="form.partenaire.description"
            />
            <HighlightMessage
              :show="
                isEmptyOrNull(form.partenaire.description) &&
                form.partenaire.show
              "
            />
          </div>
        </div>
      </div>
      <div class="my-6">
        <h2 class="title-components">Images</h2>
        <div class="grid grid-cols-4 items-end md:grid-cols-1 gap-y-4 w-full">
          <div :key="index" v-for="(image, index) in form.partenaire.images">
            <InputImage
              :file="image"
              :selfUploader="true"
              @upload="updateImage($event, index)"
              @deleteImage="deleteImage(index)"
            />
          </div>
          <InputImage @upload="saveImage" />
        </div>
        <HighlightMessage
          classes="relative top-5"
          :show="form.partenaire.images.length === 0 && form.partenaire.show"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteImage(index) {
      this.form.partenaire.images.splice(index, 1)
    },
    updateImage(file, index) {
      this.form.partenaire.images.splice(index, 1, file)
    },
    saveImage(file) {
      this.form.partenaire.images.push(file)
    },
    saveImageArchitect(file) {
      this.form.partenaire.architect_logo = file
    },
    isEmptyOrNull(data) {
      return data === '' || data === null || data === undefined
    },
  },
}
</script>
