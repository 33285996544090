<template>
  <div
    class="flex justify-start items-center text-sm font-arial font-normal h-11 px-1 lg:h-auto"
  >
    <div class="lg:hidden w-full leading-none">
      <slot />
    </div>
    <div
      class="w-full hidden lg:flex lg:items-center px-3"
      :class="[
        is_checkbox
          ? 'lg:flex-row-reverse lg:justify-end'
          : 'lg:flex-row lg:justify-center',
      ]"
    >
      <div
        v-if="label"
        class="w-1/2 py-3 font-bold"
        :class="[is_checkbox ? 'ml-8' : '']"
      >
        {{ label }}
      </div>
      <div
        :class="{
          'w-8': is_checkbox && !label,
          'w-1/2': !is_checkbox && label,
          'w-full': !is_checkbox && !label,
        }"
      >
        <div v-if="actions_btn">
          <div class="absolute top-3 right-4">
            <button
              @click="showActions(show_actions)"
              class="w-2 h-5 border-l-4 border-dotted border-promy-green-300"
            />
            <div
              v-on-clickaway="away"
              v-if="show_actions"
              class="bg-white absolute -top-16 right-0 px-3 py-2 rounded-lg"
            >
              <slot />
            </div>
          </div>
        </div>
        <div v-else class="py-3">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway,
  },

  props: {
    label: {
      type: String,
    },
    is_checkbox: {
      type: Boolean,
      default: false,
    },
    actions_btn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show_actions: false,
    }
  },

  methods: {
    showActions(val) {
      this.show_actions = !val
    },
    away() {
      this.show_actions = false
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-actions {
  @apply flex items-center justify-center w-10 h-10 text-white rounded-full cursor-pointer bg-promy-green-300;
}
</style>
