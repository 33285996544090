<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-7 sm-ws:px-10">
    <PopupDeactivateTeam
      v-if="show_popup_deactive_team"
      @closePopup="close()"
    />
    <page-title>
      <div class="flex justify-between sm:flex-col sm:space-y-4">
        <p>Gestion des promoteurs</p>
        <div
          class="bg-[#bcf4f0] pr-5 pl-12 py-4 rounded-lg text-promy-green-300"
        >
          <p class="text-base text-right font-medium">Volume CA</p>
          <p class="text-3xl text-right">
            {{ ca_potentiel | roundNumber | formatEUR(0) }} H.T.
          </p>
        </div>
      </div>
    </page-title>
    <table-title
      nextPath="ajouter"
      class="flex items-center justify-between lg:flex-col lg:items-start lg:mt-6"
    >
      <template v-slot:title>
        <div class="lg:pb-6">Liste des promoteurs</div>
      </template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          class="lg:w-full flex md:flex-col-reverse items-center space-x-3 sm:space-x-0 sm:space-y-3"
        >
          <div class="w-64 sm:w-full lg:w-1/2 lg:mr-auto">
            <pro-input v-model="search" placeholder="Rechercher" />
          </div>
          <!-- v-if="
              $can('consultation,gestion_utilisateur') ||
              $can('edite,gestion_utilisateur')
            " -->
          <div
            class="flex justify-end space-x-3 sm:space-x-0 sm:space-y-3 sm:flex-col"
          >
            <!-- :class="{'w-full'}" -->
            <button
              @click="abonnementTemporaireRoute"
              class="text-center px-4 py-2 text-sm lg:text-xs font-extrabold text-white uppercase border-2 rounded-full cursor-pointer bg-promy-green-300 border-promy-green-300 font-main sm:w-full"
            >
              Abonnements Temporaires
            </button>
            <button
              @click="downloadCSV"
              class="text-center px-4 py-2 text-sm lg:text-xs font-extrabold text-white uppercase border-2 rounded-full cursor-pointer bg-promy-green-300 border-promy-green-300 font-main sm:w-full"
            >
              Exporter CSV
            </button>
            <div
              v-if="
                $can('edite,gestion_utilisateur') ||
                $can('delete,gestion_utilisateur')
              "
              @click.prevent="nextPath('list-des-teams-create')"
              class="text-center px-4 py-2 text-sm lg:text-xs font-extrabold text-white uppercase border-2 rounded-full cursor-pointer bg-promy-green-300 border-promy-green-300 font-main sm:w-full"
            >
              Ajouter un promoteur
            </div>
            <div
              v-if="
                $can('edite,gestion_utilisateur') ||
                $can('delete,gestion_utilisateur')
              "
            >
              <label
                for="file-upload"
                class="flex justify-center items-center text-center px-4 py-2 text-sm lg:text-xs font-extrabold text-white uppercase border-2 rounded-full cursor-pointer w-full tracking-wide transition-colors duration-200 hover:bg-white hover:text-promy-red font-main"
                :class="[
                  loading
                    ? 'bg-promy-red-100 border-promy-red-100'
                    : 'bg-promy-red border-promy-red',
                ]"
              >
                Résilier des teams
              </label>
              <input
                id="file-upload"
                type="file"
                @change="resilationTeams"
                ref="file"
                accept=".xlsx, .xls, .csv"
                required
                :disabled="loading"
              />
            </div>
          </div>
        </div>
      </template>
    </table-title>

    <hr class="mb-6" />

    <pro-card>
      <pro-table-row :isHeader="true">
        <pro-table-cell class="w-[5%]">
          <Checkbox v-model="checked_all" :val="checked_all" id="all_teams" />
        </pro-table-cell>
        <pro-table-header-cell
          v-for="(sort, index) in sort_list"
          :key="index"
          :class="sort.classes"
          :sortingBy="sorting_by"
          @setSortingBy="setSortingBy"
          @orderBy="orderBy"
          :hasSort="true"
          :title="sort.title"
          :sortKey="sort.sortKey"
        />
      </pro-table-row>
      <InfiniteScrollContainer
        :isLoading="isFetching"
        :containerScroll="false"
        :fetchingAllowed="last_page > current_page"
        @fetch="getTeams()"
      >
        <pro-table-row
          v-for="(item, index) in teams"
          :key="index"
          class="relative"
        >
          <pro-table-cell
            :is_checkbox="true"
            label="Séléctionner"
            class="w-[5%] lg:w-full"
          >
            <Checkbox
              v-model="item.checked"
              :val="item.checked"
              :id="`team_${item.id}`"
            />
          </pro-table-cell>
          <pro-table-cell label="Nom" class="w-[15.2%] lg:w-full">
            {{ item.name }}
          </pro-table-cell>
          <pro-table-cell label="Créateur" class="w-[15.2%] lg:w-full">
            {{ item.creator }}
          </pro-table-cell>
          <pro-table-cell label="Nombre de membres" class="w-[15.2%] lg:w-full">
            {{ item.team_number }}
          </pro-table-cell>
          <pro-table-cell label="Date de création" class="w-[15.2%] lg:w-full">
            {{ item.created_at }}
          </pro-table-cell>
          <pro-table-cell
            label="Date de desactivation"
            class="w-[15.2%] lg:w-full"
          >
            {{ item.deactivated_at }}
          </pro-table-cell>
          <pro-table-cell :actions_btn="true" class="w-[19%] lg:w-full">
            <div class="w-full flex justify-end items-center space-x-3">
              <div
                v-tooltip.top="{
                  content: 'Clear Cache d\'abonnement',
                  classes: classes,
                }"
                class="btn-actions"
                v-if="$can('edite,gestion_utilisateur')"
                @click="clearTeamCache(item)"
              >
                <i class="fa fa-retweet" aria-hidden="true" />
              </div>
              <div
                v-tooltip.top="{
                  content: 'Deactivate team',
                  classes: classes,
                }"
                class="btn-actions"
                v-if="$can('edite,gestion_utilisateur')"
                @click="deactivateTeam(item)"
              >
                <img src="/images/deactivate_team.svg" />
              </div>
              <router-link
                v-if="
                  $can('edite,gestion_utilisateur') ||
                  $can('delete,gestion_utilisateur')
                "
                :to="{
                  name: 'list-des-teams-abonnement-temporaire',
                  params: { id: item.id },
                }"
                v-tooltip.top="{
                  content: 'Abonnements temporaires',
                  classes: classes,
                }"
                class="btn-actions"
              >
                <i class="fas fa-plus" />
              </router-link>
              <div
                v-if="$can('delete,gestion_utilisateur')"
                v-tooltip.top="{
                  content: 'Supprimer',
                  classes: classes,
                }"
                class="btn-actions"
                @click="displayModal(item)"
              >
                <i class="fas fa-trash" />
              </div>
              <router-link
                v-if="$can('edite,gestion_utilisateur')"
                :to="{ name: 'list-des-teams-edit', params: { id: item.id } }"
                v-tooltip.top="{
                  content: 'Editer',
                  classes: classes,
                }"
                class="btn-actions"
              >
                <i class="fas fa-edit" />
              </router-link>
            </div>
          </pro-table-cell>
        </pro-table-row>
      </InfiniteScrollContainer>
    </pro-card>
    <div
      v-if="!teams.length && !isFetching"
      class="w-full flex justify-center mt-24 text-lg font-main font-extrabold text-promy-gray-650"
    >
      Il n'y a pas de promoteur pour "{{ search }}"
    </div>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteTeam()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer cet équipe ?</slot
      >
      <slot slot="button_valide">Confirmer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'
import PopupDeactivateTeam from './components/popupDeactivateTeam'
export default {
  components: {
    PopupDeactivateTeam,
  },
  data() {
    return {
      selectedTeam: [],
      sort_list: [
        {
          title: 'Nom',
          sortKey: 'raison_social',
          classes: 'w-[15.2%]',
        },
        {
          title: 'Créateur',
          sortKey: 'creator',
          classes: 'w-[15.2%]',
        },
        {
          title: 'Nombre de membres',
          sortKey: 'team_number',
          classes: 'w-[15.2%]',
        },
        {
          title: 'Date de création',
          sortKey: 'created_at',
          classes: 'w-[15.2%]',
        },
        {
          title: 'Date de desactivation',
          sortKey: 'created_at',
          classes: 'w-[19%]',
        },
      ],
      sorting_by: '',
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-sm',
        'bg-white',
        'mb-2',
      ],
      sortKey: '',
      sortOrder: '',
      isFetching: false,
      current_item: null,
      showModal: false,
      teams: [],
      last_page: 1,
      current_page: 0,
      search: '',
      checked_all: false,
      loading: false,
      ca_potentiel: 0,
    }
  },
  filters: {
    formatEUR(value, fractionDigits = 2) {
      const euroformatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      })

      return euroformatter.format(value)
    },
  },
  computed: {
    ...mapGetters({
      show_popup_deactive_team: 'deactivateTeam/showPopup',
    }),
  },
  created() {
    this.$store.commit('sortBy/RESET_STATE')
  },
  watch: {
    search: {
      handler() {
        this.searchAfterDebounce()
      },
    },
    checked_all() {
      if (this.checked_all) this.teams.map((team) => (team.checked = true))
      else this.teams.map((team) => (team.checked = false))
    },
  },
  methods: {
    close() {
      this.$store.commit('deactivateTeam/CLOSE_POPUP')
    },
    deactivateTeam(item) {
      this.$store.commit('deactivateTeam/OPEN_POPUP', item)
    },
    async resilationTeams() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('resilationFile', this.$refs.file.files[0])
        await this.$http.post('/admin/status/resilation', formData, {
          'Content-Type': 'multipart/form-data',
        })
        this.loading = false
        this.$toaster.success('Vous avez resilsé des teams avec success')
      } catch (err) {
        this.$toaster.warning('Le fichier doit être de type xlsx, csv ou xls')
        this.loading = false
      }
    },
    searchAfterDebounce: debounce(function () {
      this.teams = []
      this.current_page = 0
      this.getTeams()
    }, 800),

    orderBy(key, order) {
      this.sortKey = key
      this.sortOrder = order
      this.teams = []
      this.current_page = 0
      this.getTeams()
    },

    setSortingBy(val) {
      this.sorting_by = val
    },

    getTeams() {
      this.current_page++
      this.isFetching = true
      this.$http
        .get(`admin/teams`, {
          params: {
            admin: true,
            page: this.current_page,
            search: this.search,
            sortBy: this.sortKey,
            sortOrder: this.sortOrder,
          },
        })
        .then((res) => {
          this.isFetching = false
          this.teams.push(...res.data.data)
          this.last_page = res.data.meta.last_page
          this.current_page = res.data.meta.current_page
          this.ca_potentiel = res.data.ca_potentiel
        })
    },
    deleteTeam() {
      this.$http.delete(`admin/teams/${this.current_item.id}`).then(() => {
        this.teams.splice(this.teams.indexOf(this.current_item), 1)
        this.showModal = false
        this.current_item = null
      })
    },
    clearTeamCache(item) {
      this.$http.post(`admin/teams/${item.id}/cache-clear`).then(() => {
        this.$toaster.success(
          "vous avez vidé le cache d'abonnement avec succès",
        )
      })
    },
    displayModal(item) {
      this.current_item = item
      this.showModal = true
    },
    abonnementTemporaireRoute() {
      let checkedIDS = this.teams
        .map((team) => {
          if (team.checked) return team.id
        })
        .filter(Boolean)
      if (!checkedIDS.length) {
        this.$toaster.warning('Séléctionner un promoteur')
        return
      }

      this.$router.push({
        name: 'list-des-teams-abonnement-temporaire',
        params: { id: checkedIDS.join(',') },
      })
    },
    downloadCSV() {
      let checkedIDS = this.teams
        .map((team) => {
          if (team.checked) return team.id
        })
        .filter(Boolean)
      if (!checkedIDS.length) {
        this.$toaster.warning('Séléctionner un promoteur')
        return
      }
      this.$http
        .get(`admin/teams/export`, {
          params: {
            teams_ids: checkedIDS,
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data])
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob, {
            type: 'text/csv',
          })
          link.download = 'promoteurs.csv'
          link.click()
        })
        .catch((err) => {
          this.$toaster.warning("une erreur s'est produite")
        })
    },
  },
}
</script>

<style scoped>
input[type='file'] {
  display: none;
}
</style>
