import { mapGetters } from 'vuex'
import SortBy from '@/pages/dashboard/GestionDesDelegation/components/SortBy.vue'

export default {
  components: {
    SortBy,
  },
  created() {
    this.resetStates()
    this.getDelegationMandat()
  },
  data() {
    return {
      showModal: false,
      delegationDeleteRequestID: null,
      description: '',
      fieldsMapper: {
        statut: 'status',
        n_de_delegation: 'par_mandat_n',
        date: 'mandat_date',
        date_dexpiration: 'expired_at',
      },
    }
  },
  computed: {
    ...mapGetters({
      delegationMandats: 'delegation/getDelegationMandats',
      isLoading: 'delegation/isLoading',
      currentPage: 'delegation/getCurrentPage',
      lastPage: 'delegation/getLastPage',
      sortDirection: 'sortBy/getSortDirection',
      currentSortedColumn: 'sortBy/getCurrentSortedColumn',
    }),
  },
  methods: {
    getDelegationMandat(sortBy, sortOrder) {
      this.$store.dispatch('delegation/getDelegationMandat', {
        type: this.type,
        sortBy,
        sortOrder,
      })
    },
    resetStates() {
      this.$store.commit('sortBy/RESET_STATE')
      this.$store.commit('delegation/RESET_INFINITE_SCROLL')
    },
    getStatusColor(status) {
      switch (status) {
        case 'en_attente':
          return 'bg-promy-yellow'
        case 'accepté':
          return 'bg-promy-green-100'
        case 'en_attente_supression':
          return 'bg-promy-bleu-100'
        case 'expiré':
          return 'bg-promy-orange-100'
        case 'supprimé':
          return 'bg-promy-red'
        case 'refusé':
          return 'bg-promy-gray-625'
      }
    },
    showMandat(id) {
      this.$router.push({
        name: 'gestion-des-delegation-details',
        params: { id },
      })
    },
    downloadSignedDocument(id) {
      this.$http
        .get(`admin/delegation-mandats/${id}/document_signe/download-file`, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Document_signé_${id}.pdf`
          link.click()
        })
    },
    sortByClicked(sortColumn) {
      this.$store.commit('sortBy/SET_SORT_DIRECTION', sortColumn)
      this.$store.commit('delegation/RESET_INFINITE_SCROLL')

      this.getDelegationMandat(
        this.fieldsMapper[sortColumn],
        this.sortDirection === 'down_arrow' ? 'desc' : 'asc',
      )
    },
    handleInfiniteScroll(isVisible) {
      if (!isVisible || this.lastPage === this.currentPage) return

      this.$store.commit('delegation/SET_CURRENT_PAGE', this.currentPage + 1)

      this.$store.dispatch('delegation/getDelegationMandat', {
        type: this.type,
        sortBy: this.currentSortedColumn,
        sortOrder: this.sortDirection === 'down_arrow' ? 'desc' : 'asc',
      })
    },
  },
}
