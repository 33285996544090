<template>
  <div>
    <div class="w-full sm:w-full md:w-2/3 lg:w-1/3 xl:w-1/3">
      <div class="flex items-center">
        <h2 class="title-components">Descriptif</h2>
        <div class="pl-2 flex items-center justify-center">
          <img
            v-if="!form.descriptifProjet.show_description_remarque"
            src="/images/eye-off-outline.svg"
            @click="form.descriptifProjet.show_description_remarque = true"
            v-tooltip.top="{
              content: 'Affichez',
              classes: classes,
            }"
          />
          <img
            v-if="form.descriptifProjet.show_description_remarque"
            src="/images/eye-outline.svg"
            @click="form.descriptifProjet.show_description_remarque = false"
            v-tooltip.top="{
              content: 'Masquez',
              classes: classes,
            }"
          />
        </div>
      </div>
      <quill-editor
        ref="myQuillEditor"
        class="mb-7 sm:mb-2"
        v-model="form.descriptifProjet.description_remarque"
      />
      <HighlightMessage
        classes="mb-10"
        :show="
          isEmptyOrNull(form.descriptifProjet.description_remarque) &&
          form.descriptifProjet.show_description_remarque
        "
      />
    </div>
    <div class="my-6">
      <table-title>
        <template v-slot:title>
          Les documents de description de projet (image / pdf)
        </template>
      </table-title>
      <div>
        <div
          class="w-50 h-33 relative border border-dashed border-promy-gray-400 rounded flex items-center justify-center mt-2"
        >
          <div class="text-promy-gray-400 text-3xl">
            <i class="fa fa-upload fa-6" aria-hidden="true"></i>
          </div>
          <input
            ref="fileinput"
            @change.prevent="handleFileChange"
            class="w-full absolute opacity-0 h-full inset-0 cursor-pointer"
            type="file"
          />
        </div>
      </div>

      <div class="flex flex-col w-2/3 sm:w-full md:w-2/3 mt-6">
        <div class="flex bg-promy-gray-200 py-2 rounded-t-lg">
          <div class="w-1/4">
            <p class="text-sm text-gray-700 capitalize text-center">
              nom de fichier
            </p>
          </div>
          <div class="w-1/4">
            <p class="text-sm text-gray-700 capitalize text-center">
              Dernière mise à jour
            </p>
          </div>
          <div class="w-1/4">
            <p class="text-sm text-gray-700 capitalize text-center">type</p>
          </div>
        </div>
        <template v-if="form.descriptifProjet.documents_descriptif.length">
          <div
            class="flex items-center bg-white py-3 border-t odd:bg-promy-gray-200"
            v-for="(pieceEcrite, index) in form.descriptifProjet
              .documents_descriptif"
            :key="index"
          >
            <div class="w-1/4 px-2">
              <p
                class="text-sm font-extrabold capitalize text-center cursor-pointer text-promy-green-300 underline"
                v-if="!pieceEcrite.editText"
              >
                <span
                  @click="openPdfNewWindow(pieceEcrite.value)"
                  v-if="pieceEcrite.type === 'pdf'"
                >
                  {{ pieceEcrite.name }}
                </span>
                <span @click="openImagePreview(pieceEcrite.value)" v-else>
                  {{ pieceEcrite.name }}
                </span>
              </p>
              <pro-input
                class=""
                v-else-if="pieceEcrite.editText"
                v-model="pieceEcrite.name"
                requirements="required"
                name="Nom fichier"
              ></pro-input>
            </div>
            <div class="w-1/4 px-2">
              <p
                v-if="!pieceEcrite.editText"
                class="text-sm text-gray-700 capitalize text-center"
              >
                {{ pieceEcrite.date }}
              </p>
              <datepicker
                v-else-if="pieceEcrite.editText"
                class="date-input"
                v-model="pieceEcrite.date"
                valueType="DD-MM-YYYY"
                format="DD-MM-YYYY"
              ></datepicker>
            </div>
            <div class="w-1/4 flex justify-center items-start">
              <p class="text-sm text-gray-700 capitalize text-center">
                {{ pieceEcrite.type }}
              </p>
            </div>
            <div class="w-1/4 flex justify-end pr-4">
              <div
                class="rounded-full p-2 mr-3 cursor-pointer"
                :class="
                  pieceEcrite.editText
                    ? 'bg-white border-promy-green-300 border-2'
                    : 'bg-promy-green-300'
                "
                @click="
                  !pieceEcrite.editText
                    ? showFileUpdate(pieceEcrite, index)
                    : updateFile(pieceEcrite, index)
                "
              >
                <icon-update
                  :color="
                    pieceEcrite.editText ? 'text-promy-green-300' : 'text-white'
                  "
                  class="w-4 h-4"
                />
              </div>
              <div
                v-if="pieceEcrite.isUploaded"
                class="bg-promy-green-300 rounded-full p-2 mr-3 cursor-pointer flex items-center justify-center"
                @click="displayModal(index)"
              >
                <img src="/images/trash.svg" alt="" class="w-4 h-4" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="text-center mt-5 text-base tracking-wide font-main text-gray-800 font-bold"
          >
            La liste est vide
          </div>
        </template>
      </div>
    </div>
    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="deleteFile()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir supprimer ce pdf ?</slot
      >
      <slot slot="button_valide">Continuer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  model: {
    prop: ['form'],
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      current_pdf_idx: null,
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-xs',
        'bg-white',
        'mt-2',
      ],
    }
  },
  methods: {
    isEmptyOrNull(data) {
      return data === '' || data === null || data === undefined
    },
    handleFileChange(file) {
      let vm = this
      const pdf = file.target.files[0]
      let reader = new FileReader()
      reader.onload = (f) => {
        vm.addFiles(f.target.result, pdf)
      }
      reader.readAsDataURL(pdf)
    },
    addFiles(file, pdf) {
      if (
        pdf['type'] === 'application/pdf' ||
        pdf['type'].startsWith('image/')
      ) {
        this.form.descriptifProjet.documents_descriptif.push({
          type: pdf['type'] === 'application/pdf' ? 'pdf' : 'image',
          name: pdf.name,
          value: file,
          isShown: true,
          date: this.customFormatter(pdf.lastModified),
          editText: false,
          isUploaded: true,
        })
      } else {
        this.$toaster.warning('Veuillez importer une image ou un fichier pdf.')
      }
    },
    showFileUpdate(item, index) {
      this.form.descriptifProjet.documents_descriptif[index].editText = true
    },
    updateFile(item, index) {
      this.form.descriptifProjet.documents_descriptif[index].editText = false
    },
    displayModal(idx) {
      this.current_pdf_idx = idx
      this.showModal = true
    },
    deleteFile() {
      this.form.descriptifProjet.documents_descriptif.splice(
        this.current_pdf_idx,
        1,
      )
      this.showModal = false
    },
    customFormatter(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    openPdfNewWindow(url) {
      window.open(url, '_blank')
    },
    openImagePreview(imgLink) {
      window.open(process.env.VUE_APP_BASE_STORAGE + imgLink, '_blank')
    },
  },
}
</script>
