<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <div class="w-full grid lg:grid-cols-1 grid-cols-2 gap-y-6 gap-x-12">
      <div>
        <ul
          class="bg-red-400 rounded px-12 py-4 mb-4 list-disc text-gray-200 text-xs"
          v-if="Object.keys(errors).length > 0"
        >
          <li v-for="(error, index) in errors" :key="index">
            {{ error[0] }}
          </li>
        </ul>
        <pro-input
          name="Ancien mot de passe"
          type="password"
          requirements="required"
          v-model="form.password"
          label="Ancien mot de passe"
        />
        <pro-input
          name="Nouveau mot de passe"
          requirements="required"
          type="password"
          v-model="form.new_password"
          label="Nouveau mot de passe"
        />
        <pro-input
          name="Confirmer le mot de passe"
          type="password"
          requirements="required"
          v-model="form.new_password_confirmation"
          label="Confirmer le mot de passe"
        />
      </div>
    </div>
    <div class="my-6 flex">
      <button
        @click="handleSubmit(resetPassword)"
        class="px-12 py-2 bg-promy-green-300 text-white hover:text-promy-green-300 border transition-colors duration-200 hover:border-promy-green-300 hover:bg-white rounded-full uppercase font-bold font-main text-sm mr-6"
      >
        METTRE A JOUR
      </button>
      <router-link
        :to="{
          name: 'user-profile-password',
        }"
        class="uppercase px-12 py-2 rounded-full border-2 border-promy-green-200 text-promy-green-200 hover:bg-promy-green-200 hover:text-white font-bold font-main text-sm"
      >
        Modifier mon mot de passe
      </router-link>
    </div>
  </ValidationObserver>
</template>
<script>
export default {
  data() {
    return {
      form: {
        password: '',
        new_password: '',
        new_password_confirmation: '',
      },
      errors: [],
    }
  },
  methods: {
    resetPassword() {
      this.$http
        .post('password/user', this.form)
        .then(() => {
          this.$toaster.success('Votre mot de passe a été modifié avec succès')
          this.$router.push('/')
        })
        .catch((err) => {
          'matching' in err.response.data.errors
            ? this.$toaster.warning(err.response.data.errors.matching)
            : (this.errors = err.response.data.errors)
        })
    },
  },
}
</script>
