<template>
  <div
    @click="handleClick"
    class="flex items-center space-x-1 font-arial font-bold text-xs text-promy-gray-650 cursor-pointer"
    :class="classes"
  >
    <p>
      {{ text }}
    </p>
    <img
      class="h-4 ml-6"
      :src="`/images/sort/${sortImage}.svg`"
      alt="sort icon"
    />
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    sortDirection: String,
    currentSortedColumn: String,
    classes: String,
  },
  methods: {
    handleClick() {
      this.$emit('sortByClicked', _.snakeCase(this.text))
    },
  },
  computed: {
    sortImage() {
      return this.currentSortedColumn === _.snakeCase(this.text)
        ? this.sortDirection
        : 'noSort'
    },
  },
}
</script>
