var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"relative border-dashed border-promy-gray-400 mt-4 mr-auto",class:[
      {
        'bg-promy-gray-225 border-promy-gray-600': _vm.dragging,
        'bg-promy-gray-225 ': !_vm.dragging,
        'border-2': !_vm.isUploaded,
      },
      _vm.isCircle
        ? 'rounded-full h-full w-full'
        : 'rounded-large lg:h-42 sm-ws:h-34 xl:h-45 w-full xl:w-6/7' ],attrs:{"novalidate":""},on:{"dragleave":function($event){$event.preventDefault();_vm.dragging = false},"dragover":function($event){$event.preventDefault();_vm.dragging = true}}},[(!_vm.isUploaded)?_c('div',{staticClass:"absolute flex flex-col justify-center items-center inset-0"},[_vm._m(0),_c('div',{staticClass:"uppercase text-promy-green-300 text-xl font-bold font-main"},[_vm._v(" upload ")]),_c('div',{staticClass:"uppercase text-promy-gray-350 text-xl font-main"},[_vm._v(" jpg, jpeg, png ")])]):_vm._e(),(_vm.imageSource)?_c('img',{staticClass:"transition duration-500 ease-in-out w-full h-full object-cover overflow-hidden",class:_vm.isCircle ? 'rounded-full' : 'rounded-large',attrs:{"src":_vm.imageSource}}):_vm._e(),_c('input',{ref:'fileinput',staticClass:"input-file absolute opacity-0 w-full h-full inset-0 cursor-pointer",attrs:{"type":"file","accept":"image/jpeg,\n    image/jpg, image/png"},on:{"change":function($event){$event.preventDefault();return _vm.handleFilesChosen($event)},"click":function (event) {
          event.target.value = null
        }}}),(_vm.isUploaded)?_c('button',{staticClass:"absolute rounded-full bg-promy-green-300 text-white px-2 py-1 text-sm cursor-pointer hover:bg-promy-green-200 top-0 right-0",class:_vm.isCircle ? 'mt-3 mr-3' : '-mt-3 -mr-2',attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteImage()}}},[_c('i',{staticClass:"fa fa-trash-alt",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.withResetImage)?_c('button',{staticClass:"absolute rounded-full bg-promy-green-300 text-white px-2 py-1 text-sm cursor-pointer hover:bg-promy-green-200 top-0 right-0 -mt-3 mr-7 h-7 w-7",attrs:{"type":"button"},on:{"click":function($event){return _vm.resetImage()}}},[_c('svg',{attrs:{"width":"15","height":"17","viewBox":"0 0 15 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.45166 14.4994C6.75122 14.4994 6.05776 14.3615 5.41064 14.0934C4.76352 13.8254 4.17545 13.4324 3.68017 12.9372C3.18489 12.4419 2.79197 11.854 2.52392 11.2068C2.25588 10.5597 2.11795 9.86612 2.11795 9.16568C2.11795 8.46525 2.25588 7.77164 2.52392 7.12453C2.79197 6.47741 3.18489 5.88948 3.68017 5.3942C4.17545 4.89892 4.76352 4.50599 5.41064 4.23795C6.05776 3.96991 6.75122 3.83198 7.45166 3.83198V6.65138L13.1866 3.33714L7.45166 0.0228271V2.30854C6.09544 2.30854 4.7696 2.71069 3.64195 3.46416C2.51429 4.21764 1.63556 5.28861 1.11655 6.54159C0.597554 7.79457 0.461624 9.17328 0.726209 10.5034C0.990793 11.8336 1.64389 13.0554 2.60288 14.0144C3.56187 14.9734 4.78361 15.6265 6.11376 15.8911C7.44392 16.1556 8.82284 16.0199 10.0758 15.5009C11.3288 14.9819 12.3996 14.103 13.1531 12.9753C13.9066 11.8477 14.3088 10.5219 14.3088 9.16568H12.7854C12.7838 10.5798 12.2214 11.9356 11.2215 12.9355C10.2215 13.9354 8.86578 14.4979 7.45166 14.4994Z","fill":"white"}})])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-promy-gray-400 text-3xl"},[_c('i',{staticClass:"fa fa-upload fa-6",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }