<template>
  <svg
    width="28"
    height="35"
    viewBox="0 0 28 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 0C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5V31.5C0 32.4283 0.368749 33.3185 1.02513 33.9749C1.6815 34.6312 2.57174 35 3.5 35H24.5C25.4283 35 26.3185 34.6312 26.9749 33.9749C27.6313 33.3185 28 32.4283 28 31.5V10.5L17.5 0H3.5ZM17.5 12.25H15.75V3.5L24.5 12.25H17.5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#00C9BC',
    },
  },
}
</script>

<style></style>
