<template>
  <div class="w-full px-15 lg:px-7 sm-ws:px-10 flex flex-col mt-6">
    <page-title>Gestion des permissions </page-title>
    <table-title nextPath="ajouter">
      <template v-slot:title>{{ action }} une permission</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('list-des-permissions')"
          class="capitalize text-promy-green-300 text-xs tracking-wide cursor-pointer"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <div class="w-7/10 m-auto">
        <pro-input
          name="permission"
          requirements="required"
          v-model="form.permission"
          label="permission"
        />
        <categorySelect
          name="category"
          requirements="required"
          :items="categories"
          v-model="form.category"
          label="Catégorie"
        />
        <categoryInputTextarea
          requirements="required"
          v-model="form.description"
          placeholder="description"
          label="description"
          name="description"
        />
      </div>

      <div class="my-12 w-7/10 m-auto">
        <button
          :disabled="
            form.permission === '' ||
            form.category === '' ||
            form.description === ''
          "
          @click="handleSubmit(save)"
          class="px-12 py-2 bg-promy-green-300 text-white hover:text-promy-green-300 border transition-colors duration-200 hover:border-promy-green-300 hover:bg-white rounded-full uppercase font-bold font-main text-sm disabled:opacity-50 disabled:hover:bg-promy-green-300 disabled:hover:text-white disabled:hover:border"
        >
          valider
        </button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import categorySelect from './categorySelect.vue'
import categoryInputTextarea from './categoryInputTextarea.vue'

export default {
  components: {
    categorySelect,
    categoryInputTextarea,
  },
  data() {
    return {
      action: 'Ajouter',
      storeURL: 'admin/promoteur/permissions',
      form: {
        permission: '',
        category: '',
        description: '',
      },
      error: [],
    }
  },
  created() {
    this.checkCurrentMode()
    this.loadCategories()
  },
  computed: {
    ...mapGetters({
      categories: 'roleAndPermission/getCategories',
    }),
  },
  methods: {
    /**
     * Check current mode (edit, create)
     */
    checkCurrentMode() {
      if (this.$route.meta.mode !== 'edit') return
      this.storeURL = `admin/promoteur/permissions/${this.$route.params.id}?_method=PUT`
      this.action = 'Editer'
      this.editablePermission(this.$route.params.id)
    },

    /**
     * get updatable promoteur
     * params ID router id
     */
    editablePermission(id) {
      this.$http.get(`admin/promoteur/permissions/${id}`).then((res) => {
        this.form.permission = res.data.name
        this.form.category = res.data.category_id
        this.form.description = res.data.description
      })
    },

    /**
     * Presist current promoteur information
     */
    save() {
      const form = {
        name: this.form.permission,
        category_id: parseInt(this.form.category),
        description: this.form.description,
      }
      this.$http
        .post(this.storeURL, form)
        .then(() => {
          this.$toaster.success(
            this.storeURL.includes('PUT')
              ? "Vous mettez à jour l'élément avec succès"
              : 'Vous avez ajouté une permission avec succès',
          )
          this.$router.push({
            name: 'list-des-permissions-promoteur',
          })
        })
        .catch((err) => {
          this.$refs.form.setErrors(err.response.data.errors)
          this.$toaster.error('Une erreur est survenue')
        })
    },
    loadCategories() {
      this.$store.dispatch('roleAndPermission/getAllcategories', {
        type: 'promoteur',
      })
    },
  },
}
</script>

<style></style>
