<template>
  <div class="mt-24">
    <div class="mt-8">
      <div class="flex items-center my-6">
        <div class="mr-8">
          <input type="checkbox" id="notaire" v-model="form.notaire" />
          <label for="notaire"></label>
        </div>

        <span class="text-sm text-gray-600"> Notaire </span>
      </div>
      <template v-if="form.notaire">
        <pro-input name="Nom" v-model="form.notaire_nom" label="Nom" />
        <pro-input name="Prénom" v-model="form.notaire_prenom" label="Prénom" />
        <pro-input
          name="n° - voie"
          v-model="form.notaire_n_voie"
          label="n° - voie"
        />
        <pro-input
          name="Commune"
          v-model="form.notaire_commune"
          label="Commune"
        />
        <pro-input
          name="Code Postal"
          v-model="form.notaire_code_postal"
          label="Code Postal"
        />
        <pro-input name="Bat" v-model="form.notaire_bat" label="Bat" />
        <pro-input
          name="complément d'adresse"
          v-model="form.notaire_adress"
          label="Complément d'adresse"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'form',
    event: 'update',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style></style>
