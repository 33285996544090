import Vue from 'vue'

// filter global
Vue.filter('closingTime', function (date_end) {
  date_end = new Date(date_end)
  var now = new Date()
  var Difference_In_Time = date_end.getTime() - now.getTime()

  var Difference_In_Hours = Math.floor(Difference_In_Time / (1000 * 3600))

  var Total_days = Math.floor(Difference_In_Hours / 24)

  var Total_hours = Difference_In_Hours % 24

  return Total_days + 'j:' + Total_hours + 'h'
})
