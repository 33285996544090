<template>
  <svg
    class="w-10 h-auto"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.004 512.004"
    style="enable-background: new 0 0 512.004 512.004"
    :style="`fill :${color}`"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M494.346,385.174l-28.504,19c-4.907,3.25-11.313,4.891-17.751,4.891c-6.438,0-12.875-1.641-17.75-4.891l-28.5-19
			c-4.907-3.266-11.313-4.891-17.751-4.891c-6.438,0-12.875,1.625-17.75,4.891l-28.5,19c-4.907,3.25-11.313,4.891-17.751,4.891
			c-6.422,0-12.859-1.641-17.766-4.891l-28.5-19c-4.884-3.266-11.306-4.891-17.743-4.891c-6.422,0-12.859,1.625-17.75,4.891
			l-28.5,19c-4.891,3.25-11.313,4.891-17.75,4.891c-6.422,0-12.859-1.641-17.75-4.891l-28.5-19
			c-4.891-3.266-11.313-4.891-17.75-4.891c-6.422,0-12.859,1.625-17.75,4.891l-28.5,19c-4.891,3.25-11.313,4.891-17.75,4.891
			c-6.422,0-12.859-1.641-17.75-4.891l-28.5-19c-4.891-3.266-11.313-4.891-17.75-4.891c-0.016,0-0.047,0-0.078,0v21.344
			c0.031,0,0.062,0,0.078,0c3.141,0,5.234,0.844,5.922,1.297l28.501,19c8.188,5.453,18.688,8.453,29.578,8.453
			c10.891,0,21.391-3,29.579-8.453l28.5-19c0.688-0.453,2.781-1.297,5.922-1.297c3.141,0,5.234,0.844,5.922,1.297l28.501,19
			c8.188,5.453,18.688,8.453,29.578,8.453c10.891,0,21.391-3,29.579-8.453l28.5-19c0.688-0.453,2.781-1.297,5.922-1.297
			c3.141,0,5.234,0.844,5.922,1.297l28.509,19c8.188,5.453,18.688,8.453,29.578,8.453c10.875,0,21.376-3,29.563-8.453l28.5-19
			c0.688-0.453,2.781-1.297,5.938-1.297c3.125,0,5.219,0.844,5.906,1.297l28.501,19c8.188,5.453,18.688,8.453,29.594,8.453
			c10.875,0,21.376-3,29.563-8.453l28.5-19c0.688-0.453,2.75-1.281,5.844-1.297v-21.344
			C505.596,380.314,499.19,381.939,494.346,385.174z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M494.342,303.563l-28.5,19c-4.907,3.25-11.313,4.875-17.751,4.875c-6.438,0-12.875-1.625-17.75-4.875l-28.5-19
			c-2.188-1.469-4.72-2.594-7.376-3.406l0.375-135.564l19.563,19.555c4.156,4.164,10.906,4.164,15.062,0
			c4.188-4.164,4.188-10.922,0-15.086L263.534,3.129c-4.156-4.172-10.906-4.172-15.078,0L82.532,169.061h-0.016
			c-4.172,4.164-4.172,10.922,0,15.086s10.922,4.164,15.079,0c0,0,0,0,0.016,0l19.641-19.656l-0.375,135.931
			c-2.359,0.781-4.578,1.828-6.547,3.141l-28.5,19c-4.891,3.25-11.313,4.875-17.75,4.875c-6.422,0-12.859-1.625-17.75-4.875
			l-28.5-19c-4.891-3.266-11.313-4.891-17.75-4.891c-0.016,0-0.047,0-0.078,0V320c0.031,0,0.062,0,0.078,0
			c3.141,0,5.234,0.844,5.922,1.312l28.501,19c8.188,5.454,18.688,8.454,29.578,8.454c10.891,0,21.391-3,29.579-8.454l28.5-19
			c0.688-0.469,2.781-1.312,5.922-1.312c3.141,0,5.234,0.844,5.922,1.312l28.501,19c8.188,5.454,18.688,8.454,29.578,8.454
			c10.891,0,21.391-3,29.579-8.454l28.5-19c0.688-0.469,2.781-1.312,5.922-1.312s5.234,0.844,5.922,1.312l28.509,19
			c8.188,5.454,18.688,8.454,29.578,8.454c10.875,0,21.376-3,29.563-8.454l28.5-19c0.688-0.469,2.781-1.312,5.938-1.312
			c3.125,0,5.219,0.844,5.906,1.312l28.501,19c8.188,5.454,18.688,8.454,29.594,8.454c10.875,0,21.376-3,29.563-8.454l28.5-19
			c0.688-0.453,2.75-1.281,5.844-1.312v-21.328C505.592,298.688,499.186,300.313,494.342,303.563z M277.324,305.876l-3.5-2.312
			c-4.884-3.267-11.305-4.892-17.743-4.892c-6.422,0-12.859,1.625-17.75,4.891l-3.672,2.438v-71.329h42.665V305.876z
			 M373.122,300.344c-2.469,0.781-4.75,1.844-6.781,3.219l-28.5,19c-4.907,3.25-11.313,4.875-17.751,4.875
			c-6.422,0-12.859-1.625-17.766-4.875l-3.656-2.453v-96.102c0-5.891-4.781-10.672-10.656-10.672h-64.009
			c-5.906,0-10.672,4.781-10.672,10.672c0,0.258,0.016,0.516,0.047,0.773h-0.047v95.438l-3.5,2.344
			c-4.891,3.25-11.313,4.875-17.75,4.875c-6.422,0-12.859-1.625-17.75-4.875l-28.5-19c-2.266-1.516-4.844-2.656-7.609-3.469
			L138.66,143.1L256.003,25.754L373.56,143.311L373.122,300.344z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M494.346,466.783l-28.504,19.001c-4.907,3.266-11.313,4.891-17.751,4.891c-6.438,0-12.875-1.625-17.75-4.891l-28.5-19.001
			c-4.907-3.25-11.313-4.875-17.751-4.875c-6.438,0-12.875,1.625-17.75,4.875l-28.5,19.001c-4.907,3.266-11.313,4.891-17.751,4.891
			c-6.453,0-12.859-1.625-17.766-4.891l-28.5-19.001c-4.884-3.25-11.306-4.875-17.743-4.875s-12.859,1.625-17.75,4.875l-28.5,19.001
			c-4.891,3.266-11.313,4.891-17.75,4.891c-6.422,0-12.859-1.625-17.75-4.891l-28.5-19.001c-4.891-3.25-11.313-4.875-17.75-4.875
			c-6.422,0-12.859,1.625-17.75,4.875l-28.5,19.001c-4.891,3.266-11.313,4.891-17.75,4.891c-6.422,0-12.859-1.625-17.75-4.891
			l-28.5-19.001c-4.891-3.25-11.313-4.875-17.75-4.875c-0.016,0-0.047,0-0.078,0v21.345c0.031,0,0.047,0,0.078,0
			c3.141,0,5.234,0.844,5.922,1.281l28.501,19.001c8.188,5.469,18.688,8.469,29.578,8.469c10.891,0,21.391-3,29.579-8.469
			l28.5-19.001c0.688-0.438,2.781-1.281,5.922-1.281c3.141,0,5.234,0.844,5.922,1.281l28.501,19.001
			c8.188,5.469,18.688,8.469,29.578,8.469c10.891,0,21.391-3,29.579-8.469l28.5-19.001c0.688-0.438,2.781-1.281,5.922-1.281
			c3.141,0,5.234,0.844,5.922,1.281l28.509,19.001c8.188,5.469,18.688,8.469,29.578,8.469c10.875,0,21.376-3,29.563-8.469
			l28.5-19.001c0.688-0.438,2.781-1.281,5.938-1.281c3.125,0,5.219,0.844,5.906,1.281l28.501,19.001
			c8.188,5.469,18.688,8.469,29.594,8.469c10.875,0,21.376-3,29.563-8.469l28.5-19.001c0.688-0.438,2.75-1.281,5.844-1.281v-21.345
			C505.596,461.924,499.19,463.549,494.346,466.783z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#00C9BC',
    },
  },
}
</script>
