<template>
  <form enctype="multipart/form-data">
    <div class="relative sm-ws:w-30 sm-ws:h-30 w-53 h-53 rounded-full">
      <img
        :src="avatarImage"
        class="transition duration-500 ease-in-out w-full h-full object-cover overflow-hidden rounded-full"
        v-if="avatarImage"
      />
      <input
        class="opacity-0 absolute w-full h-full z-50 inset-0"
        type="file"
        accept="image/jpeg, image/png, image/jpg"
        @change="changeImage"
      />
      <i
        class="fa fa-edit absolute top-0 right-0 mt-6 text-base text-white bg-promy-green-300 sm-ws:w-8 sm-ws:h-8 w-10 h-10 rounded-full flex items-center justify-center"
      ></i>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    avatar: {
      type: String,
    },
  },
  data() {
    return {
      image: '',
      loader: false,
    }
  },
  computed: {
    avatarImage() {
      return this.image || this.avatar
    },
  },
  methods: {
    changeImage(e) {
      this.loader = true
      if (!e.target.files.length) return
      let image = e.target.files[0]

      let reader = new FileReader()

      reader.readAsDataURL(image)

      reader.onload = (e) => {
        this.image = e.target.result
      }

      this.sendAvatar(image)
    },
    sendAvatar(image) {
      let data = new FormData()
      data.append('avatar', image)
      this.$http.post('user/avatar', data).then((res) => {
        this.$store.commit('auth/UPDATE_AVATAR', res.data)
      })
    },
  },
}
</script>
