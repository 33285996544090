<template>
  <div
    @click="handleClick"
    class="flex justify-between items-center max-w-full py-2 pl-4 pr-5 text-sm font-bold capitalize bg-white border rounded-full text-promy-gray-670 cursor-pointer"
    :class="classes"
  >
    <p>
      {{ text }}
    </p>
    <img
      class="h-4 ml-6"
      :src="`/images/sort/${sortImage}.svg`"
      alt="sort icon"
    />
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    sortDirection: String,
    currentSortedColumn: String,
    classes: String,
  },
  methods: {
    handleClick() {
      this.$emit('sortByClicked', _.snakeCase(this.text))
    },
  },
  computed: {
    sortImage() {
      return this.currentSortedColumn === _.snakeCase(this.text)
        ? this.sortDirection
        : 'noSort'
    },
  },
}
</script>
