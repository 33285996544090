function page(path) {
  return () => import(/* webpackChunkName: '' */ `../../pages/${path}`)
}
import auth from '../../middleware/auth'

export default [
  {
    path: 'revelation-potentiel',
    name: 'revelation-potentiel',
    component: page('dashboard/RevelationPotentiel'),
    redirect: 'revelation-potentiel/list',
    children: [
      {
        path: 'list',
        name: 'revelation-list',
        component: page('dashboard/RevelationPotentiel/list'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'details/:id',
        name: 'revelation-details',
        component: page('dashboard/RevelationPotentiel/details'),
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
]
