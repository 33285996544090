<template>
  <LayoutParametrage title="Boîte à outils">
    <template #current_page>Bibliothèque</template>
    <template #add_button>
      <button
        class="btn-green flex items-center justify-center text-xs px-6 py-2 sm:w-full md:mt-8 sm:text-center"
        @click="setAction(true)"
      >
        <p class="mr-3">AJOUTER UN DOSSIER</p>
        <addFolderIcon />
      </button>
    </template>
    <template #modal>
      <div class="flex space-x-4 mb-4">
        <SortBy
          text="Nom"
          sortBy="nom"
          :filter="sort"
          @applyFilterBy="sortBy"
        />
        <SortBy
          text="Fichier"
          sortBy="documents"
          :filter="sort"
          @applyFilterBy="sortBy"
        />
        <SortBy
          text="Récent"
          sortBy="created_at"
          :filter="sort"
          @applyFilterBy="sortBy"
        />
      </div>
      <div
        v-for="(folder, idx) in folders"
        :key="idx"
        class="flex items-center bg-white py-8 rounded-lg mb-4 shadow-md hover:shadow-lg"
        @click="showFolder(folder)"
      >
        <div class="w-26 sm:w-20 flex justify-center">
          <folderIcon />
        </div>
        <div
          class="flex-grow font-arial font-bold text-2xl sm:text-xl text-promy-gray-700 leading-none"
        >
          <p>{{ folder.nom }}</p>
          <span v-if="folder.documents" class="text-sm text-promy-gray-400">
            {{ folder.documents.length }} fichier
          </span>
          <span v-else class="text-sm text-promy-gray-400"> 0 fichier </span>
        </div>
        <div class="w-30 flex justify-center">
          <div
            class="bg-promy-green-300 rounded-full p-2 mr-2 cursor-pointer flex items-center justify-center"
            @click.stop="setAction(true, 'update', folder)"
          >
            <img src="/images/update.svg" class="w-4 h-4" />
          </div>
          <div
            class="bg-promy-green-300 rounded-full p-2 cursor-pointer flex items-center justify-center"
            @click.stop="
              ;(showModal_remove_folder = true), (remember_folder = folder)
            "
          >
            <img src="/images/trash.svg" class="w-4 h-4" />
          </div>
        </div>
        <!-- <isVisible :isFetching="isFetching" @isVisible="isVisible" /> -->
      </div>
      <div
        v-if="!folders.length"
        class="w-full font-main text-2xl text-promy-gray-500 text-center font-extrabold"
      >
        Aucun dossier
      </div>
      <ValidateModal
        v-if="showModal_folder"
        @isCancel="setAction(false)"
        @isValide="action === 'add' ? addFolder() : updateFolder()"
      >
        <slot slot="message_body">
          <div class="w-96">
            <ValidationObserver ref="validationObserver" v-slot="{ invalid }">
              <label class="text-xs text-promy-gray-400">
                Nom du dossier
              </label>
              <input-text
                class="w-5/6"
                :rules="'required'"
                v-model="nom"
                placeholder="Nom du dossier"
                type="text"
              />
            </ValidationObserver>
          </div>
        </slot>
        <slot slot="button_valide">
          <span v-if="action === 'add'">Ajouter</span>
          <span v-else>Modifier</span>
        </slot>
        <slot slot="button_cancel">Annuler</slot>
      </ValidateModal>
      <ValidateModal
        v-if="showModal_remove_folder"
        @isCancel=";(showModal_remove_folder = false), (remember_folder = null)"
        @isValide="removeFolder()"
      >
        <slot slot="message_body">
          <p class="w-96">Êtes-vous sûr de vouloir supprimer ce dossier ?</p>
        </slot>
        <slot slot="button_valide">Supprimer</slot>
        <slot slot="button_cancel">Annuler</slot>
      </ValidateModal>
    </template>
  </LayoutParametrage>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showModal_folder: false,
      showModal_remove_folder: false,
      nom: '',
      remember_folder: null,
      action: 'add',
      sort: '',
    }
  },

  created() {
    this.$store.dispatch('manageDocuments/getAllFolders')
  },

  methods: {
    showFolder(folder) {
      this.$store.commit('manageDocuments/SET_CURRENT_FOLDER', folder)
      this.$router.push(`${folder.id}/documents`)
    },

    setAction(modal, action = 'add', folder = null) {
      this.action = action
      this.remember_folder = folder
      this.showModal_folder = modal
      this.nom = action === 'update' ? folder.nom : ''
    },

    async addFolder() {
      let res = await this.$refs.validationObserver.validate()
      if (!res) {
        this.$toaster.warning('Le nom du dossier est obligatoire.')
        return
      }
      try {
        await this.$store.dispatch('manageDocuments/addFolder', {
          nom: this.nom,
        })
        this.$toaster.success('Le dossier a bien été ajouté.')
        this.setAction(false)
      } catch {
        this.$toaster.warning(
          "Un probleme est survenu lors l'ajoute du dossier.",
        )
      }
    },
    async removeFolder() {
      try {
        await this.$store.dispatch(
          'manageDocuments/deleteFolder',
          this.remember_folder,
        )
        this.$toaster.success('Le dossier a bien été supprimé.')
        this.remember_folder = null
        this.showModal_remove_folder = false
      } catch {
        this.$toaster.warning(
          'Un probleme est survenu lors la suppression du dossier.',
        )
      }
    },

    async updateFolder() {
      let res = await this.$refs.validationObserver.validate()
      if (!res) {
        this.$toaster.warning('Le nom du dossier est obligatoire.')
        return
      }
      try {
        await this.$store.dispatch('manageDocuments/updateFolder', {
          folder: this.remember_folder,
          new_folder: { nom: this.nom },
        })
        this.$toaster.success('Le dossier a bien été modifié.')
        this.setAction(false)
      } catch {
        this.$toaster.warning(
          'Un probleme est survenu lors la modification du dossier.',
        )
      }
    },

    sortBy(key) {
      this.sort = this.sort == key ? '' : key
      let order = this.sort ? 'asc' : 'desc'
      this.$store.dispatch('manageDocuments/getAllFolders', {
        key: key,
        order: order,
      })
    },
  },

  computed: {
    ...mapGetters({
      folders: 'manageDocuments/folders',
    }),
  },
}
</script>

<style></style>
