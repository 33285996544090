<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18.12"
    viewBox="0 0 18 18.12"
  >
    <path
      class="fill-current"
      :class="color"
      d="M1761.46,1049.3a1.159,1.159,0,0,0-1.66,0l-3.46,3.47v-0.66h-12.52v14.94h14.87v-12.58h-0.72l3.49-3.51A1.171,1.171,0,0,0,1761.46,1049.3Zm-3.55,16.96H1744.6V1052.9h11.62l-6.14,6.16v1.7h1.63l6.2-6.23v11.73Zm3-15.85-9.52,9.56h-0.53v-0.59l9.49-9.53A0.4,0.4,0,0,1,1760.91,1050.41Z"
      transform="translate(-1743.81 -1048.94)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-white',
    },
  },
}
</script>

<style></style>
