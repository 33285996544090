<template>
  <LayoutParametrage title="Dossiers Promoteurs">
    <template #current_page> Questions Matching </template>
    <template #title_table> Liste des questions </template>
    <template #add_button>
      <span
        v-if="$can('edite,gestion_utilisateur')"
        @click="addQuestion()"
        class="green-text-btn"
      >
        <span class="">Ajouter une question </span>
        <i class="fa fa-plus pl-2 text-sm"></i>
      </span>
    </template>
    <template #hint_table>
      Faites glisser les éléments pour afficher l'ordre d'apparition
    </template>
    <template #list_table>
      <draggable
        class="drag-area"
        :list="all_questions_matching"
        :options="{ animation: 200, group: 'status' }"
        @change="updateOrder"
      >
        <pro-table-row
          v-for="(item, index) in all_questions_matching"
          :key="index"
          class="my-4 rounded-lg py-2 lg:py-0 relative"
        >
          <pro-table-cell class="w-1/3 lg:w-full">
            <span v-if="!item.editText">
              {{ item.question }}
            </span>
            <input-text
              v-else-if="item.editText"
              class="w-full"
              :rules="'required'"
              v-model="item.question"
              placeholder="Condition"
              type="text"
            />
          </pro-table-cell>
          <pro-table-cell class="w-1/4 lg:w-full">
            <span v-if="!item.editText">
              {{ getTypeProject(item.is_appel_a_projet) }}
            </span>
            <pro-select
              v-else
              class="w-3/4"
              requirements="required"
              :value="getTypeProject(item.is_appel_a_projet)"
              @input="handleSelect($event, item, 'is_appel_a_projet')"
              :selection="projects"
            ></pro-select>
          </pro-table-cell>
          <pro-table-cell class="w-1/4 lg:w-full">
            <span v-if="!item.editText">
              {{ QuestionsType[item.type] }}
            </span>
            <pro-select
              v-else
              class="w-3/4"
              requirements="required"
              :value="QuestionsType[item.type]"
              @input="handleSelect($event, item, 'type')"
              :selection="Object.values(QuestionsType)"
            ></pro-select>
          </pro-table-cell>
          <pro-table-cell class="w-1/4 lg:w-full">
            <template v-if="['0', '2'].includes(item.type)">
              <span v-if="!item.editText">
                {{ item.placeholder }}
              </span>
              <input-text
                v-else-if="item.editText"
                class="w-full"
                v-model="item.placeholder"
                placeholder="Placeholder"
                type="text"
              />
            </template>
          </pro-table-cell>
          <pro-table-cell
            :actions_btn="true"
            class="w-1/4 lg:w-full"
            v-show="!item.new_item"
          >
            <div class="w-full flex justify-end items-center space-x-3">
              <div
                v-if="$can('edite,dossier_promoteur')"
                :class="item.editText ? 'white-icon-btn' : 'green-icon-btn'"
                @click="
                  !item.editText
                    ? showUpdate(item, index)
                    : updateCondition(item, index)
                "
              >
                <i class="fas fa-edit" />
              </div>
              <div
                v-if="$can('edite,dossier_promoteur')"
                class="green-icon-btn"
                @click="requestDelete(item)"
              >
                <i class="fas fa-trash" />
              </div>
            </div>
          </pro-table-cell>
          <pro-table-cell
            position="justify-end items-start"
            v-show="item.new_item"
          >
            <div
              class="bg-promy-green-300 rounded-full py-2 px-4 font-extrabold text-white mr-3 cursor-pointer"
              @click="storeItem(item, index)"
            >
              Ajouter
            </div>
          </pro-table-cell>
        </pro-table-row>
      </draggable>
    </template>
    <template #modal>
      <ValidateModal
        v-if="showModal"
        @isCancel="showModal = false"
        @isValide="deleteItem()"
      >
        <slot slot="message_body"
          >Êtes-vous sûr de vouloir supprimer cette condition ?</slot
        >
        <slot slot="button_valide">Continuer</slot>
        <slot slot="button_cancel">Annuler</slot>
      </ValidateModal>
    </template>
  </LayoutParametrage>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      showModal: false,
      current_index: null,
      projects: ['Tous les projets', 'Appel à projet'],
    }
  },
  computed: {
    ...mapGetters({
      all_questions_matching: 'questionsmatching/all_questions_matching',
      QuestionsType: 'questionsmatching/QuestionsType',
      QuestionsTypeNumber: 'questionsmatching/QuestionsTypeNumber',
    }),
  },
  created() {
    this.$store.dispatch('questionsmatching/getAllQuestionMatching')
  },
  methods: {
    handleSelect($event, item, attribut = 'is_appel_a_projet') {
      if (attribut == 'type') {
        item[attribut] = this.getKeyByValue(this.QuestionsType, $event)
      } else {
        item[attribut] = $event == 'Appel à projet' ? true : false
      }
    },
    getTypeProject(type) {
      if ([0, 1, true, false].includes(type)) {
        return type ? 'Appel à projet' : 'Tous les projets'
      } else {
        return type
      }
    },
    addQuestion() {
      let hasAlreadyNewItem = this.all_questions_matching.some(
        (item) => item.new_item,
      )

      if (!hasAlreadyNewItem) {
        this.all_questions_matching.forEach(function (element) {
          element.editText = false
          element.new_item = false
        })
        this.$store.commit('questionsmatching/UNSHIFT_NEW_QUESTION', {
          question: '',
          editText: true,
          new_item: true,
          is_appel_a_projet: null,
          type: null,
        })
      } else {
        this.$store.commit('questionsmatching/SHIFT_NEW_QUESTION')
      }
    },
    storeItem(item, idx) {
      item.editText = false
      item.new_item = false

      this.$store.dispatch('questionsmatching/saveQuestionItem', {
        idx: idx,
        object: item,
      })
    },
    showUpdate(question, index) {
      question.editText = true
      this.$store.commit('questionsmatching/SHOW_EDIT', {
        idx: index,
        object: question,
      })
    },
    updateCondition(item, index) {
      this.$store.dispatch('questionsmatching/updateQuestionItem', {
        idx: index,
        object: item,
      })
    },
    deleteItem() {
      this.$store.dispatch(
        'questionsmatching/deleteQuestionItem',
        this.current_index,
      )
      this.showModal = false
    },
    updateOrder() {
      this.all_questions_matching.map((question, index) => {
        question.order = index + 1
      })
      this.$store.dispatch(
        'questionsmatching/updateAllQuestionOrder',
        this.all_questions_matching,
      )
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value)
    },
    requestDelete(item) {
      if (item.has_offer) {
        this.$toaster.warning('Vous ne pouvez pas supprimer cette question!')
      } else {
        this.displayModal(item.id)
      }
    },
    displayModal(idx) {
      this.current_index = idx
      this.showModal = true
    },
  },
}
</script>

<style scoped></style>
