var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutParametrage',{scopedSlots:_vm._u([{key:"current_page",fn:function(){return [(_vm.$route.name != 'update-CGU')?_c('span',[_vm._v("Ajouter des CGU")]):_c('span',[_vm._v("Modifier des CGU")])]},proxy:true},{key:"add_button",fn:function(){return [_c('router-link',{staticClass:"text-promy-green-300 cursor-pointer",attrs:{"to":"/parametrage/CGU/liste"}},[_vm._v(" ← Retour ")])]},proxy:true},{key:"modal",fn:function(){return [_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"w-1/3 md:w-2/3 sm:w-full"},[_c('pro-input',{attrs:{"name":"version","requirements":"required","label":"Version","disabled":""},model:{value:(_vm.form.version),callback:function ($$v) {_vm.$set(_vm.form, "version", $$v)},expression:"form.version"}})],1),_c('div',{staticClass:"relative w-2/3 md:w-full mt-4"},[_c('label',{staticClass:"capitalize text-xs text-gray-600 font-bold font-arial"},[_vm._v(" Destinés à la platforme: ")]),(
            _vm.current_cgu &&
            _vm.$route.name === 'update-CGU' &&
            _vm.current_cgu.status != 'Publier'
          )?_c('pro-input',{attrs:{"requirements":"required","disabled":""},model:{value:(_vm.form.platform),callback:function ($$v) {_vm.$set(_vm.form, "platform", $$v)},expression:"form.platform"}}):_c('pro-select',{staticClass:"w-fit",attrs:{"requirements":"required","selection":_vm.platforms,"args":['name']},model:{value:(_vm.form.platform),callback:function ($$v) {_vm.$set(_vm.form, "platform", $$v)},expression:"form.platform"}})],1),_c('div',{staticClass:"relative w-2/3 md:w-full h-64 mt-4"},[_c('label',{staticClass:"capitalize text-xs text-gray-600 font-bold font-arial"},[_vm._v(" Contenu ")]),_c('quill-editor',{ref:"myQuillEditor",staticClass:"mt-2 h-full",model:{value:(_vm.form.context),callback:function ($$v) {_vm.$set(_vm.form, "context", $$v)},expression:"form.context"}})],1),_c('div',{staticClass:"w-full my-8 mt-24"},[(_vm.$route.name === 'update-CGU')?_c('button',{staticClass:"btn-green px-6 py-2 sm:w-full mr-4",on:{"click":function($event){return _vm.update('Brouillon')}}},[_vm._v(" Modifier ")]):_vm._e(),(
            _vm.current_cgu &&
            _vm.$route.name === 'update-CGU' &&
            _vm.current_cgu.status != 'Publier'
          )?_c('button',{staticClass:"font-extrabold text-white uppercase border-2 border-solid rounded-full mr-4 font-main hover:bg-promy-red hover:border-promy-red border-promy-red-100 bg-promy-red-100 px-6 py-2 sm:w-full sm:mt-4",on:{"click":function($event){_vm.show_modal = 'remove'}}},[_vm._v(" Supprimer ")]):_vm._e(),(_vm.$route.name === 'update-CGU')?_c('button',{staticClass:"btn-green px-6 py-2 sm:w-full md:mt-8",on:{"click":function($event){return _vm.update('Publier')}}},[_vm._v(" Publier ")]):_vm._e(),(_vm.$route.name === 'add-CGU')?_c('button',{staticClass:"btn-green px-6 py-2 sm:w-full md:mt-8 mr-4",on:{"click":function($event){return _vm.add('Brouillon')}}},[_vm._v(" Sauvegarder ")]):_vm._e(),(_vm.$route.name === 'add-CGU')?_c('button',{staticClass:"btn-green px-6 py-2 sm:w-full md:mt-8",on:{"click":function($event){return _vm.add('Publier')}}},[_vm._v(" Publier ")]):_vm._e()])]}}])}),(_vm.show_modal)?_c('Modal',[_vm._t("default",[_c('p',{staticClass:"text-promy-gray-700 text-sm font-arial py-4"},[_vm._v(" "+_vm._s(_vm.content_modal[_vm.show_modal].question)+" ")])],{"slot":"body"}),_vm._t("default",[_c('button',{staticClass:"btn-green px-6 py-2 sm:w-full md:mt-8 mr-4",on:{"click":function($event){return _vm.closeAndLeave()}}},[_vm._v(" "+_vm._s(_vm.show_modal === 'remove' ? 'Non' : 'Quitter')+" ")]),(_vm.show_modal === 'remove')?_c('button',{staticClass:"font-extrabold text-white uppercase border-2 border-solid rounded-full font-main hover:bg-promy-red hover:border-promy-red border-promy-red-100 bg-promy-red-100 px-6 py-2 sm:w-full md:mt-8",on:{"click":function($event){return _vm.remove()}}},[_vm._v(" Oui ")]):_c('button',{staticClass:"btn-green px-6 py-2 sm:w-full md:mt-8 mr-4",on:{"click":function($event){_vm.$route.name === 'update-CGU'
              ? _vm.update('Brouillon')
              : _vm.add('Brouillon')}}},[_vm._v(" Sauvegarder ")])],{"slot":"footer"})],2):_vm._e()]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }