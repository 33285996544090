import axios from 'axios'
export default {
  data() {
    return {
      is_canalisations_matieres_dangereuses_loaded: false,
    }
  },
  methods: {
    setCanalisationsMatieresDangereuses(risques, CAN) {
      let coords = this.informationsStore.center_parcelle_principale
      CAN.sub_title = []
      this.setDetailRisqueCAN(risques, CAN)
      CAN.sub_title.forEach((sub) => {
        axios
          .get(
            `https://mapsref.brgm.fr/wxs/georisques/rapport?X=${coords[0]}&Y=${coords[1]}&rayon=${sub.rayon}&service=wfs&version=1.0.0&request=getfeature&typename=${sub.layer}&propertyname=${sub.properties}&resultType=results`,
          )
          .then(
            (response) => {
              let data_json = this.xmlToJson(response.data)
              sub.value = data_json['wfs:FeatureCollection']['gml:boundedBy'][
                'gml:null'
              ]
                ? 'Non'
                : 'Oui'
              this.is_canalisations_matieres_dangereuses_loaded = true
            },

            (error) => {
              console.log(error)
              this.is_canalisations_matieres_dangereuses_loaded = true
            },
          )
      })
    },
    setDetailRisqueCAN(risques, CAN) {
      risques.definitions_risques.CAN.alea.forEach((can) => {
        this.setObjCAN(CAN, 'alea', can)
      })
    },

    setObjCAN(CAN, key, obj) {
      let alea_obj = {
        key: key,
        id: obj.id,
        label: obj.label,
        descr: obj.descr,
        properties: obj.properties,
        layer: obj.layer[0],
        value: '',
        rayon: obj.rayon,
        displayRayon: true,
      }
      CAN.sub_title.push(alea_obj)
    },
  },
}
