import transport from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    risques: null,
    risques_cross_api_is_loading: false,
  },
  getters: {
    risques(state) {
      return state.risques
    },
    risques_cross_api_is_loading(state) {
      return state.risques_cross_api_is_loading
    },
  },
  mutations: {
    RISQUES(state, payload) {
      state.risques = payload
    },
    RISQUES_CROSS_API_IS_LOADING(state, payload) {
      state.risques_cross_api_is_loading = payload
    },
  },
  actions: {
    getRisques({ commit, dispatch, state }, params) {
      return new Promise((resolve, reject) => {
        commit('RISQUES_CROSS_API_IS_LOADING', true)
        transport
          .get(
            `getRisques?&code_insee=${params.code_insee}&lat=${params.lat}&long=${params.long}`,
          )
          .then((response) => {
            commit('RISQUES', response.data)
            commit('RISQUES_CROSS_API_IS_LOADING', false)
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
