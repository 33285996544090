<template>
  <LayoutParametrage title="Guichet Unique">
    <template #current_page> Dossier synthèse du Guichet Unique </template>
    <template #title_table>
      Modifier dossier synthèse du Guichet Unique
    </template>

    <template #list_table>
      <div class="flex flex-col">
        <div class="flex flex-col w-full">
          <ValidationObserver
            v-slot="{ handleSubmit }"
            class="w-full"
            ref="formInformations"
          >
            <form
              @submit.prevent="handleSubmit(saveContextGeneral)"
              class="w-full flex flex-col space-y-20"
            >
              <div class="flex w-full">
                <div class="flex flex-col">
                  <div
                    class="text-promy-gray-650 font-extrabold font-main lg:text-base xl:text-lg mb-4"
                  >
                    Description
                  </div>
                  <div class="flex w-full">
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="form.description"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  v-if="$can('edite,dossier_promoteur')"
                  type="submit"
                  class="px-16 w-max rounded-full text-center sm:w-full border-2 bg-promy-green-300 border-promy-green-300 py-3 text-white uppercase font-main font-extrabold text-base hover:bg-promy-green-200 hover:border-promy-green-200 duration-100 transition-colors mt-6"
                >
                  Sauvegarder
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </LayoutParametrage>
</template>
<script>
export default {
  data() {
    return {
      form: {
        id: '',
        description: '',
      },
    }
  },
  created() {
    this.getDossierSyntheseDossierGU()
  },
  methods: {
    getDossierSyntheseDossierGU() {
      this.$http.get('admin/dossier-synthese-gu').then((response) => {
        this.form = _.isNull(response.data.dossierSyntheseDuGu)
          ? {
              id: '',
              description: '',
            }
          : response.data.dossierSyntheseDuGu
      })
    },
    saveContextGeneral() {
      if (this.form.description === '' || _.isNull(this.form.description)) {
        this.$toaster.warning('la description ne peut pas être vide.')
        return
      }
      this.$http
        .post('admin/dossier-synthese-gu', this.form)
        .then((response) => {
          this.form = response.data.dossierSyntheseDuGu
          this.$toaster.success(
            'dossier synthèse de guichet unique sauvegardée avec succès',
          )
        })
    },
  },
}
</script>
