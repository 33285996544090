<template>
  <div class="mt-2 space-y-3">
    <label class="slugs_input__label">{{ label }}</label>
    <div
      v-for="(slug, index) in slugs"
      :key="index"
      class="flex items-center space-x-2"
    >
      <input class="slugs_input__values" type="text" disabled :value="slug" />
      <div class="slugs_input__remove_cta" @click="remove(index)">
        <svg
          width="14"
          height="2"
          viewBox="0 0 19 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 0.999999L1 1"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>

    <div class="flex items-center space-x-2">
      <input
        class="slugs_input__input"
        type="text"
        placeholder="Ajouter"
        v-model="newSlug"
      />
      <div class="slugs_input__add_cta" @click="add">
        <svg
          width="13"
          height="13"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.5 1V9.5M9.5 9.5V18M9.5 9.5H18M9.5 9.5H1"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slugs: {
      required: true,
    },
    label: {
      type: String,
      default: 'Untitled',
    },
  },
  data() {
    return {
      newSlug: '',
    }
  },
  methods: {
    add() {
      if (!this.newSlug) return
      this.slugs.push(this.newSlug)
      this.newSlug = ''
      this.$emit('input', this.slugs)
    },
    remove(index) {
      this.slugs.splice(index, 1)
      this.$emit('input', this.slugs)
    },
  },
}
</script>

<style scoped>
.slugs_input__label {
  @apply capitalize text-xs text-gray-600 font-bold font-arial;
}
.slugs_input__values {
  @apply w-full pl-8 py-2 border border-gray-300 rounded-full text-gray-600 text-sm;
}
.slugs_input__remove_cta {
  @apply px-3 py-[17px] bg-promy-green-200 rounded-full cursor-pointer;
}
.slugs_input__input {
  @apply w-full pl-8 py-2 border border-gray-300 rounded-full text-gray-600 text-sm;
}
.slugs_input__add_cta {
  @apply px-3 py-[11px] bg-promy-green-200 rounded-full cursor-pointer;
}
</style>
