export default {
  methods: {
    setSeisme(risques, SEISM) {
      SEISM.sub_title = []
      let pprn_info = []
      this.setDetailRisqueSEISM(risques, SEISM)
      SEISM.sub_title.forEach((sub) => {
        if (sub.key === 'alea')
          sub.value = this.notEmptyObject(risques.statut_zone_sismicite.data)
            ? risques.statut_zone_sismicite.data[0].zone_sismicite.toLowerCase()
            : 'Non'

        if (sub.key === 'ppr') {
          if (this.notEmptyObject(risques.pprn)) {
            risques.pprn.forEach((pprn) => {
              pprn.listRisques.forEach((rsq) => {
                if (sub.codes.includes(rsq.numRisque)) {
                  sub.value = 'Oui'
                  pprn_info.push(pprn)
                }
              })
            })
            sub.value = sub.value === 'Oui' ? 'Oui' : 'Non'
            sub.bodyTable = this.setValuesTable(
              pprn_info,
              ['codeNatPpr', 'libellePpr'],
              'listRisques',
              'datePrescription',
              'dateApprobation',
            )
          } else {
            sub.value = 'Non'
          }
        }
      })
    },
    setDetailRisqueSEISM(risques, SEISM) {
      risques.definitions_risques.SEISM.alea.forEach((inst) => {
        if (inst.id === 'SEISM') this.setObjSEISM(SEISM, 'alea', inst)
      })
      this.setObjSEISM(SEISM, 'ppr', risques.definitions_risques.SEISM.ppr)
    },

    setObjSEISM(SEISM, key, obj) {
      let alea_obj = {
        key: key,
        title: obj.title,
        label: obj.label,
        rayon: obj.rayon,
        layer: obj.layer[0],
        properties: obj.properties.toString(),
        value: '',
        descr: obj.descr,
        codes: key === 'ppr' ? obj.codes : [],
      }
      SEISM.sub_title.push(alea_obj)
    },
  },
}
