import transport from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    dossierEnCommercialisation: {
      data: [],
      links: {},
      meta: {},
    },
    dossierEnBrouillon: {
      data: [],
      links: {},
      meta: {},
    },
    offreEnCours: {
      data: [],
      links: {},
      meta: {},
    },
    isSet: false,
    settersTypes: {
      offre: 'SET_OFFRE_EN_COURS',
      enBrouillon: 'SET_DOSSIER_EN_BROUILLON',
      enCommercialisation: 'SET_DOSSIER_PROMOTEURS',
    },
  },
  getters: {
    dossierEnCommercialisation(state) {
      return state.dossierEnCommercialisation
    },
    dossierEnBrouillon(state) {
      return state.dossierEnBrouillon
    },
    offreEnCours(state) {
      return state.offreEnCours
    },
    isSet(state) {
      return state.isSet
    },
  },
  mutations: {
    SET_DOSSIER_PROMOTEURS(state, dossierEnCommercialisation) {
      let allData = [
        ...state.dossierEnCommercialisation.data,
        ...dossierEnCommercialisation.data,
      ]
      let meta = dossierEnCommercialisation.meta
      let links = dossierEnCommercialisation.links

      state.dossierEnCommercialisation = {
        data: allData,
        meta: meta,
        links: links,
      }
    },
    SET_DOSSIER_EN_BROUILLON(state, dossierEnBrouillon) {
      let allData = [
        ...state.dossierEnBrouillon.data,
        ...dossierEnBrouillon.data,
      ]
      let meta = dossierEnBrouillon.meta
      let links = dossierEnBrouillon.links

      state.dossierEnBrouillon = {
        data: allData,
        meta: meta,
        links: links,
      }
    },
    SET_OFFRE_EN_COURS(state, offreEnCours) {
      let allData = [...state.offreEnCours.data, ...offreEnCours.data]
      let meta = offreEnCours.meta
      let links = offreEnCours.links

      state.offreEnCours = {
        data: allData,
        meta: meta,
        links: links,
      }
    },
    SET_IS_SET(state, isSet) {
      return (state.isSet = isSet)
    },
    SET_DEFAULT(state) {
      state.dossierEnBrouillon =
        state.dossierEnCommercialisation =
        state.offreEnCours =
          {
            data: [],
            links: {},
            meta: {},
          }
    },
  },
  actions: {
    async getDashboard({ commit }) {
      const { data } = await transport.get('admin/dashboard')
      commit('auth/SET_USER', data.user, {
        root: true,
      })
      commit('SET_DOSSIER_PROMOTEURS', data.dossierEnCommercialisation)
      commit('SET_DOSSIER_EN_BROUILLON', data.dossierEnBrouillon)
      commit('SET_OFFRE_EN_COURS', data.offreEnCours)
      commit('SET_IS_SET')
    },
    async getList({ commit, state }, { url, type }) {
      if (['enCommercialisation', 'enBrouillon', 'offre'].includes(type)) {
        const { data } = await transport.get(url, { params: { type: type } })
        commit(state.settersTypes[type], data)
      }
    },
  },
}
