import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      excluded_keys: [],
      excluded_objs: [],
    }
  },
  methods: {
    progressDossier(progree_section) {
      let progree_dossier = 0
      let keys_progress_sections_exist = []
      Object.keys(progree_section).filter((key) => {
        if (this.parametrageSections)
          Object.values(this.parametrageSections).forEach((value) => {
            if (key === value.key_progress && value.is_active) {
              keys_progress_sections_exist.push(key)
            }
          })
      })
      keys_progress_sections_exist.forEach((key) => {
        progree_dossier =
          progree_dossier +
          100 /
            keys_progress_sections_exist.length /
            (100 / progree_section[key])
      })
      return parseInt(progree_dossier)
    },

    progressSection(data, ...Tab_keys) {
      let count = 0
      data = this.preparKeys(data)
      if (Tab_keys.length) {
        let new_data = {}
        Object.keys(data).forEach((key) => {
          if (
            Tab_keys.find((name) => {
              return key.includes(name)
            })
          )
            new_data[key] = data[key]
        })
        Tab_keys.forEach((key) => {
          if (
            !Object.keys(new_data).find((name) => {
              return name.includes(key)
            })
          )
            new_data[key] = ''
        })
        data = new_data
      }

      Object.values(data).forEach((val) => {
        if (
          (typeof val != 'undefined' && val != null && val != '') ||
          typeof val === 'boolean' ||
          val instanceof File ||
          val === 0
        )
          count++
      })

      return isNaN(
        parseInt(((count / Object.keys(data).length) * 100).toFixed(0)),
      )
        ? 0
        : parseInt(((count / Object.keys(data).length) * 100).toFixed(0))
    },

    preparKeys(data) {
      let newData = {}
      Object.keys(data)
        .filter((key) => !this.excluded_objs.includes(key))
        .forEach((key) => {
          newData = this.mergeData(key, data[key], newData)
        })
      newData = Object.keys(newData)
        .filter((key) => !this.excluded_keys.includes(key))
        .reduce((obj, key) => {
          obj[key] = newData[key]
          return obj
        }, {})
      return newData
    },

    mergeData(key, value, newData, old_Key = null) {
      if (
        (key === 'parkings_aeriens' || key === 'parkings_souterrains') &&
        old_Key === 'comparatif_des_prix'
      ) {
        if (
          value.find((val) => {
            return !val.show
          })
        )
          return newData
      }
      let new_key = old_Key ? old_Key + '_' + key : key
      if (typeof value != 'object' || value == null || key === 'images') {
        newData[new_key] = value
      } else {
        Object.keys(value).forEach((sub_key) => {
          newData = this.mergeData(sub_key, value[sub_key], newData, new_key)
        })
      }
      return newData
    },

    addExcludedObj(show, key_obj) {
      if (!show) {
        if (!this.excluded_objs.includes(key_obj))
          this.excluded_objs.push(key_obj)
      } else {
        const index = this.excluded_objs.indexOf(key_obj)
        if (index > -1) {
          this.excluded_objs.splice(index, 1)
        }
      }
    },
  },

  watch: {
    progress_section: {
      deep: true,
      handler(value) {
        this.$store.commit(
          'dossierpromoteur/setProgressDossier',
          this.progressDossier(value),
        )
      },
    },
  },

  computed: {
    ...mapGetters({
      parametrageSections: 'dossierpromoteur/getActiveSections',
      progress_section: 'dossierpromoteur/getProgressSection',
    }),
  },
}
